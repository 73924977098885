import { useState, Fragment, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { css } from 'twin.macro';
import { RenderDeductionFields } from './RenderDeductionFields';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { fetchWrapper } from '../../../../../../_helpers';

function prepareDataBeforeSubmit(data) {
    const preparedData = { ...data };
    const additionalFields = preparedData.additional_fields;
    const newData = {};

    newData.employee_contribution = preparedData.employee_contribution;
    newData.employer_contribution = preparedData.employer_contribution;

    if (additionalFields && Object?.keys(additionalFields)?.length > 0) {
        newData.additional_fields = {};

        for (const key in additionalFields) {
            if (Object.prototype.hasOwnProperty.call(additionalFields, key)) {
                if (typeof additionalFields[key] === 'object' && 'value' in additionalFields[key]) {
                    newData.additional_fields[key] = additionalFields[key].value;
                } else {
                    newData.additional_fields[key] = additionalFields[key];
                }
            }
        }
    }
    return newData;
}

const EditDeduction = ({
    deduction,
    index,
    setIsBusy,
    isBusy,
    handleRemoveDeduction,
    handleUpdateDeduction,
    teamId,
}) => {
    const { register, handleSubmit, setValue } = useForm({});

    const updateDeduction = async (data) => {
        try {
            setIsBusy(true);
            const updatedDeduction = prepareDataBeforeSubmit(data);
            const { companyID, employeeCheckID, deductionTemplateID } = deduction;
            const payload = {
                deduction: updatedDeduction,
                deductionTemplateID,
            };
            const response = await fetchWrapper.post(
                `evv/payroll/zeal/employee/${companyID}/${employeeCheckID}/deduction${teamId ? `?team=${teamId}` : ''}`,
                payload
            );
            handleUpdateDeduction(deduction, response);
            toast.success(`Successfully updated deduction`);
        } catch (err) {
            console.log('err', err);
            toast.error(err?.message || 'Failed to update deduction');
        } finally {
            setIsBusy(false);
        }
    };
    const removeDeduction = async () => {
        try {
            setIsBusy(true);
            const { companyID, employeeCheckID, deductionID } = deduction;
            await fetchWrapper.delete(
                `evv/payroll/zeal/employee/${companyID}/${employeeCheckID}/deduction?deductionID=${deductionID}${teamId ? `&team=${teamId}` : ''}`
            );
            handleRemoveDeduction(deductionID);
            toast.success('Deduction removed successfully');
        } catch (err) {
            console.log('err', err);
            toast.error(err?.message || 'Failed to remove deduction');
        } finally {
            setIsBusy(false);
        }
    };

    return (
        <div
            css={css`
                border: 2px solid #17a2b8;
                padding: 2rem;
                margin: 2rem 0;
                position: relative;
            `}
        >
            <Form onSubmit={handleSubmit(updateDeduction)}>
                <Form.Label>
                    {index + 1}.{' '}
                    <span
                        css={css`
                            font-weight: 600;
                        `}
                    >
                        {deduction?.deduction_template_name}
                    </span>
                    <span className="badge badge-warning ml-2">{deduction?.deduction_type}</span>
                </Form.Label>
                <hr />
                {
                    <div
                        css={css`
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                            justify-content: center;
                        `}
                    >
                        <RenderDeductionFields
                            schema={deduction}
                            register={register}
                            setValue={setValue}
                            mode={'edit-deduction'}
                        />
                    </div>
                }
                <div
                    css={css`
                        display: flex;
                        justify-content: right;
                    `}
                >
                    <Button
                        type="button"
                        className="btn btn-danger"
                        css={css`
                            margin-top: 1rem;
                            background-color: #af5454 !important;
                        `}
                        disabled={isBusy}
                        onClick={() => removeDeduction()}
                    >
                        Remove
                    </Button>
                    <Button
                        type="submit"
                        css={css`
                            margin-top: 1rem;
                            margin-left: 1rem;
                        `}
                        disabled={isBusy}
                    >
                        Update
                    </Button>
                </div>
            </Form>
        </div>
    );
};

const EmployeeEditDeductions = ({ prevEmployeeDeductions = [], setEmployeeDeductions, teamId }) => {
    const [isBusy, setIsBusy] = useState(false);

    const handleRemoveDeduction = (removedDeductionId) => {
        setEmployeeDeductions((prev) => (prev || [])?.filter((item) => item?.deductionID !== removedDeductionId));
    };

    const handleUpdateDeduction = (previousDeduction, updatedData) => {
        setEmployeeDeductions((prev) => {
            const tempArr = (prev || [])?.filter((item) => item?.deductionID !== previousDeduction?.deductionID);
            tempArr.push({ ...previousDeduction, ...updatedData });
            return tempArr;
        });
    };

    return (
        <Fragment>
            {/* employee deductions  */}
            {(prevEmployeeDeductions || [])?.map((deduction, index) => (
                <EditDeduction
                    deduction={deduction}
                    key={deduction?.deductionID}
                    index={index}
                    setIsBusy={setIsBusy}
                    isBusy={isBusy}
                    handleRemoveDeduction={handleRemoveDeduction}
                    handleUpdateDeduction={handleUpdateDeduction}
                    teamId={teamId}
                />
            ))}
        </Fragment>
    );
};

export default EmployeeEditDeductions;
