import React from 'react';
import { css } from 'twin.macro';
import { Button, Modal } from 'react-bootstrap';
import PdfRenderer from '../../OctetRenderer/PdfRenderer';
import { toast } from 'react-toastify';

function FileRenderer({ show, file, handleClose, documentSettings }) {
	let isPdf = false;
	let ext = file?.slice(((file.lastIndexOf('.') - 1) >>> 0) + 2);
	if (ext === 'pdf') {
		isPdf = true;
	}
	if (ext === 'csv') {
		toast.error('CSV files are not previewable yet');
	}

	return (
		<Modal
			show={show}
			onHide={handleClose}
			size="lg"
			css={css`
				.modal-dialog {
					width: 90vw;
					max-width: 90vw;
				}
			`}
		>
			<div onClick={(e) => e.stopPropagation()}>
				<Modal.Header closeButton>
					<Modal.Title>Document Viewer</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{documentSettings && (
						<div className="d-flex justify-content-start">
							<a href={file} download className="btn btn-info" target="_blank" rel={'noreferrer'}>
								{' '}
								Download{' '}
							</a>
						</div>
					)}
					<div
						css={css`
							width: 100%;
							height: 80vh;
							overflow: scroll;
						`}
					>
						{isPdf ? (
							<PdfRenderer uri={file} />
						) : (
							<iframe
								title="documentViewer"
								src={`https://view.officeapps.live.com/op/embed.aspx?src=${file}`}
								width="100%"
								height="100%"
								frameBorder="0"
							></iframe>
						)}

						<div
							css={css`
								width: 130px;
								height: 65px;
								position: absolute;
								bottom: 0;
								right: 0;
								cursor: not-allowed;
							`}
						></div>
					</div>
				</Modal.Body>
				<Modal.Footer
					css={css`
						display: flex;
						justify-content: center;
					`}
				>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
				</Modal.Footer>
			</div>
		</Modal>
	);
}

export default FileRenderer;
