import React from 'react';

const Link = (props) => {
	let value;
	let isYoutube = true;
	try {
		if (props.data.data?.url) {
			let url = props.data.data?.url;
			if (url.search(/youtu./i) >= 0) {
				isYoutube = true;
				value = url.split('/')[url.split('/').length - 1];
			} else {
				isYoutube = false;
				value = url.startsWith('http') ? url : 'https://' + url;
			}
		} else if (props.data.data[0]?.url) {
			let url = props.data.data[0]?.url;
			if (url.search(/youtu./i) >= 0) {
				isYoutube = true;
				value = url.split('/')[url.split('/').length - 1];
			} else {
				isYoutube = false;
				value = url.startsWith('http') ? url : 'https://' + url;
			}
		}
	} catch (err) {}

	return (
		<div className="d-flex flex-column w-100">
			<h5 className="warpIt">{props.data.fieldName}</h5>
			<div className="lead">
				<pre className="small text-muted warpIt preStyles">{props.data.instruction}</pre>
			</div>
			{value && (
				<iframe
					width="100%"
					height="350px"
					src={isYoutube ? `https://www.youtube.com/embed/${value}` : value}
					title="YouTube video player"
					frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
				></iframe>
			)}
		</div>
	);
};
export default Link;
