import React, { useState, useEffect, useRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button } from 'react-bootstrap';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import {useDispatch, useSelector } from 'react-redux';
import { updateVisits } from '../../../../_redux/actions/ActionEVV';
//Components and helpers
import { fetchWrapper, getTeamIdFromURL } from '../../../../_helpers';
import { changeVisitLabelFromResponse } from './VisitRow';
import { VscLoading } from 'react-icons/vsc';

const StyledComment = styled.div`
	position: relative;
	textarea {
		padding: 0.5rem;
		height: 150px;
	}
	.loader {
		position: absolute;
		bottom: 12px;
		right: 20px;
		svg {
			font-size: 22px;
			color: #005758;
		}
	}
`;
const commentsContainer = css`
	margin-top: 2rem;
`;
const commentContainer = css`
	padding: 1rem;
	margin-bottom: 0.5rem;
	border-radius: 12px;
	border: 1px solid;
	p {
		margin-bottom: 0.5rem !important;
	}
`;

const commentTitle = css`
	display: flex;
	align-items: center;
	p {
		color: #000000;
		font-weight: bold;
	}
	span {
		display: flex;
		flex-wrap: no-wrap;
		font-size: 12px;
		color: #686868;
		margin-left: 2%;
	}
`;
const commentAction = css`
	color: #686868;
	font-size: 13px;
	span {
		cursor: pointer;
		margin-right: 2%;
	}
`;

const VisitComment = ({ visit }) => {
	const dispatch = useDispatch();
	const self = useSelector((state) => state.user.user);
	const userId = self.id || self._id;
	const [savedComment, setSavedComment] = useState('');
	const [visitComments, setVisitComments] = useState([]);
	const [isEditMode, setIsEditMode] = useState(false);
	const [commentId, setCommentId] = useState();

	const commentRef = useRef('');
	const [isPostingComment, setIsPostingComment] = useState(false);
	const [fetchingComments, setFetchingComments] = useState(false);
 	const teamId = getTeamIdFromURL();

	// useEffect(() => {
	// 	let selectedVisit = visits.filter((visit) => visit.id === show)[0];
	// 	setSavedComment(selectedVisit?.extraInfo?.memo);
	// }, [show, visit?.extraInfo?.memo, visits]);

	// const handleCommentSave = async () => {
	// 	try {
	// 		if (commentRef?.current) {
	// 			let comment = commentRef.current.value;
	// 			setIsCommentLoading(true);
	// 			await fetchWrapper.patch('evv/visit/message', {
	// 				visit: visit.id,
	// 				message: comment,
	// 			});
	// 			dispatch(updateVisits({ ...visit, extraInfo: { ...visit.extraInfo, memo: comment } }, visit.id));
	// 			toast.success('Comment saved successfully');
	// 			setIsCommentLoading(false);
	// 			commentRef.current.focus();
	// 		}
	// 	} catch (err) {
	// 		console.log(err, 'err');
	// 		setIsCommentLoading(false);
	// 		toast.error('Comment save failed');
	// 	}
	// };
	// const handleCommentDelete = async () => {
	// 	try {
	// 		if (commentRef?.current) {
	// 			setIsCommentLoading(true);
	// 			await fetchWrapper.patch('evv/visit/message', {
	// 				visit: visit.id,
	// 				message: savedComment,
	// 			});
	// 			dispatch(updateVisits({ ...visit, extraInfo: { ...visit.extraInfo, memo: savedComment } }, visit.id));
	// 			toast.success('Comment Cancelled successfully');
	// 			setIsCommentLoading(false);
	// 			commentRef.current.focus();
	// 		}
	// 	} catch (err) {
	// 		console.log(err, 'err');
	// 		setIsCommentLoading(false);
	// 		toast.error('Comment cancel failed');
	// 	}
	// }

	const fetchVisitComments = async () => {
		try {
			setFetchingComments(true);
			const response = await fetchWrapper.get(`evv/comment/${visit?._id}?${teamId ? `&team=${teamId}` : ''}`);
			const res = response.results;
			setVisitComments(res);
		} catch (err) {
			console.log(err, 'err');
		}
		finally{
			setFetchingComments(false);
		}
	};

	useEffect(() => {
		if (visit?._id) {
			fetchVisitComments();
		}
	}, [visit]);

	const handleCommentPost = async (e) => {
		e.preventDefault();
		if(savedComment.trim()===""){return;}
		setIsPostingComment(true);
		try {

			if (isEditMode === false) {
				if (commentRef?.current && savedComment?.length) {
					const response = await fetchWrapper.post(`evv/comment?${teamId ? `&team=${teamId}` : ''}`, {
						visit: visit.id,
						comment: savedComment,
						user: userId,
					});
					
					toast.success('Comment Added Successfully');
					if(response?.label){
						dispatch(updateVisits({ ...visit, extraInfo: { ...visit?.extraInfo,label: response?.label } }, visit._id));
					}
					changeVisitLabelFromResponse(response);
				}
			} else {
				if (commentRef?.current) {
					const response = await fetchWrapper.patch(`evv/comment/${commentId}?${teamId ? `&team=${teamId}` : ''}`, {
						comment: savedComment,
					});
					if(response?.label){
						dispatch(updateVisits({ ...visit, extraInfo: { ...visit?.extraInfo,label: response?.label } }, visit._id));
					}
					changeVisitLabelFromResponse(response);
				}
				toast.success('Comment Edited Sucessfully');
			}
			commentRef?.current && (commentRef.current.value = '');
		} catch (err) {
			console.log(err, 'err');
			toast.error('Comment save failed');
		} finally {
			setCommentId(null);
			setIsEditMode(false);
			setSavedComment('');
			setIsPostingComment(false); 
			await fetchVisitComments();
		}
	};

	const handleEditComment = async (_id, comment) => {
		try {
			setCommentId(_id);
			setIsEditMode(true);
			commentRef.current.focus();
			commentRef.current.value = comment;
		} catch (err) {
			console.log(err);
		}
	};

	const handleCancelEdit = () => {
		setCommentId(null);
		setIsEditMode(false);
		commentRef.current && (commentRef.current.value = '');
	};

	// const handleDeleteComment = async (_id) => {
	// 	if(window.confirm("Are you sure to delete")){
	// 		await fetchWrapper.delete(`evv/comment/${_id}`);
	// 	}
	// };

	return (
		<div className="d-flex flex-column w-100">
			<div>
				<b>Comments </b>
			</div>
			{/* <StyledComment>
            <textarea
                disabled={isCommentLoading}
                ref={commentRef}
                className="w-100"
                onChange={() => setSavedComment(savedComment)}
                
            />
            {isCommentLoading && (
                <span className="loader">
                    <VscLoading className="spin" />
                </span>
            )}
        </StyledComment> */}
			{/* <div className="d-flex justify-content-between">
            <Button  variant="danger" onClick={handleCommentDelete}>Cancel </Button>
            <Button  variant="primary" onClick={handleCommentSave} >Save </Button>
        </div> */}
			<StyledComment>
				<textarea ref={commentRef} className="w-100" onChange={(e) => setSavedComment(e.target.value)} />
			</StyledComment>
			<div className="d-flex justify-content-end" style={{ gap: '1rem' }}>
				<Button disabled={isPostingComment} variant="primary" onClick={handleCommentPost}>
					{isEditMode ? 'Edit Comment' : 'Add Comment'}
				</Button>
				{isEditMode ? (
					<Button
						disabled={isPostingComment}
						variant="danger"
						style={{ fontSize: 'medium' }}
						onClick={handleCancelEdit}
					>
						Cancel
					</Button>
				) : null}
			</div>
			{
				fetchingComments ?
				<div>
				<VscLoading className="spin" /> Fetching Comments
				</div>
				 :null
			}
			<div css={commentsContainer}>
				{visitComments.map((comment, i) => {
					if (visit?._id === comment.visit) {
						return (
							<div
								css={commentContainer}
								key={i}
								style={{
									borderColor: commentId === comment._id ? 'var(--primary)' : 'rgba(0,0,0,0.1)',
								}}
							>
								<div css={commentTitle}>
									<p>{comment.user.username} </p>
									<span>{dayjs(comment?.createdAt).format('MM-DD-YYYY h:mm A')} </span>
								</div>
								<p>{comment.comment}</p>
								<div css={commentAction}>
									<Button
										disabled={commentId === comment._id}
										variant="info"
										onClick={() => handleEditComment(comment._id, comment.comment)}
									>
										Edit
									</Button>
									{/* <span  onClick={() => handleReplyComment(comment._id)}>Reply</span> */}
									{/* <span onClick={() => handleDeleteComment(comment._id)}>Delete</span>  */}
								</div>
							</div>
						);
					} else {
						return null;
					}
				})}
			</div>
		</div>
	);
};

export default VisitComment;
