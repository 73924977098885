import styled from '@emotion/styled';

export const SidebarStyled = styled('div')`
	margin: 0px;
	padding: 0px;
	height: 100vh;
	position: fixed;
	width: 8rem;
	z-index: 1;

	.sidebar-content{
		background-color: white;
		list-style: none;
		height: 100%;
		padding: 0px;
	}

	.mid-content {
		width:100%;
		.icons-li {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-decoration: none;
			color:#A3A7AC;
			height:75px;
			background-color: white;
			padding: 0.5rem 0rem;
			position: relative;
			border-right: 3px solid white;
			cursor:pointer;

			span{
					font-size:11px;
					margin-top: 5px;
					color:#757575;
				}

			.link{
				background-color: white;
				border-radius: 10px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				height:60px;
				width:60px;
				transition: background-color 200ms ease-in-out, transform 200ms ease-in-out;
				.icon{
					color:#757575;
				}
				&:hover {
					background-color: #E0F0EF;
					.icon{
						color:#50aeb0;
						transform: scale(1.2);
					}
				}
			}
		}
		.hide-sidebar{
			display:none;
		}
	}

	.profile{
		display:flex;
		justify-content: center;
		align-items: center;
	}
	

	.selected{
		background-color: red ;
		border-right: 3px solid #50aeb0 !important;

		.link{
			background-color: #E0F0EF !important;
			.icon{
				color:#50aeb0 !important;
				transform: scale(1.2);
			}
		}
	}

	.logo {
		height:4.5rem;
		width:4.5rem;
		border-radius: 50%;
	}
	
	.icon{
		color:#A3A7AC;
		font-size: 20px;
	}

	@media only screen and (max-width: 767px) {
		position: fixed;
		bottom: 0;
		width: 100vw;
		margin: 0;
		height: auto;
		z-index: 1;
		overflow-x: scroll;
		&::-webkit-scrollbar {
		display:none;
		}
		.power, .profile{
			background-color: white;
			height:60px;
		}
		.power{
			margin-right: 20px;
		}
		.logo{
			display: none;
		}
		.sidebar-content {
			padding: 0;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items:center;
			.mid-content {
				display: flex;
				justify-content: space-between;
				align-items:center;
				width: 100%;
			}
			.icons-li {
				margin: 0;
				height: 60px;
				width:60px;
				border:none !important;
				padding: 0.5rem;
				span{
					display:none;
				}
			}
			.hide-sidebar{
			width:40px;
			height:60px;
			display:flex;
			justify-content: center;
			align-items: center;
			background-color: #50aeb0;
			color:white;
			font-size:24px;
		}
		}
	}
	
`;
