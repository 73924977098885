import { css } from 'twin.macro';

import React, { useState, Fragment } from 'react';
import { Button, Form, Card, Accordion } from 'react-bootstrap';
import GoogleMap from '../../Home/GoogleMap';

import formInput from '../../../_elements/formInput';
import { VscLoading } from 'react-icons/vsc';

// helpers
import { getLatLngFromAddress, moveMarker } from '../../../_helpers/methods';
import { toast } from 'react-toastify';

const GPSLocationCard = ({
	fieldName,
	locationName,
	latName,
	longName,
	watchLocation,
	register,
	setValue,
	getValues,
	setLocation,
	errors,
	locationIndexes,
	removeLocation,
	location,
	address,
	currentIndex,
}) => {
	const [showConfirm, setShowConfirm] = useState(false);
	const [hideConfirmBtn, setHideConfirmBtn] = useState(false);
	const [isDraggable, setIsDraggable] = useState(true);
	const [isVerifyBtnClicked, setIsVerifyBtnClicked] = useState(false);
	const [isLocationVerified, setIsLocationVerified] = useState(location?.isVerified ? true : false);

	const handleLocationChange = () => {
		setIsLocationVerified(false);
		const latitudeFieldName = `addresses[${currentIndex}].latitude`;
		const longitudeFieldName = `addresses[${currentIndex}].longitude`;
		setValue(latitudeFieldName, '');
		setValue(longitudeFieldName, '');
	};
	return (
		<Card key={fieldName}>
			<fieldset name={fieldName}>
				<Form.Group>
					<div className="d-flex" css={css`gap:8px;`}>
						<Accordion.Toggle as={Button} variant="link" eventKey={fieldName} className="w-100">
							<Form.Control
								css={formInput}
								type="text"
								isInvalid={errors.location}
								placeholder="Eg: 1600 Amphitheatre Parkway, Mountain View, CA"
								name={locationName}
								ref={register}
								defaultValue={address}
								onChange={handleLocationChange}
							/>
						</Accordion.Toggle>
						{locationIndexes && (
							<React.Fragment>
								{(
									<Button
										variant={isLocationVerified ? "primary" : "secondary"}
										className={`mr-1 btn mb-2 mt-2`}
										onClick={async () => {
											setIsVerifyBtnClicked(true);
											const locationResult = await getLatLngFromAddress(
												watchLocation || getValues(locationName),
												latName,
												longName,
												setLocation,
												setValue
											);
											if (locationResult) {
												setShowConfirm(true);
											}
											else {
												toast.warn("Please enter Valid address");
											}
											setIsVerifyBtnClicked(false);
										}}
										disabled={isVerifyBtnClicked || isLocationVerified}
										css={css`font-size:16px;`}
									>
										{isLocationVerified ? "Verified" : "Verify"}
									</Button>
								)}
								<div className="mr-1 btn btn-danger mb-2 mt-2" onClick={removeLocation} css={css`font-size:16px;`}>
									Remove
								</div>
							</React.Fragment>
						)}
					</div>

					<Form.Control
						css={(formInput, { position: 'absolute', opacity: 0, pointerEvents: 'none' })}
						type="text"
						isInvalid={errors.location}
						name={latName}
						ref={register}
						defaultValue={location?.lat}
					/>
					<Form.Control
						css={(formInput, { position: 'absolute', opacity: 0, pointerEvents: 'none' })}
						type="text"
						isInvalid={errors.location}
						name={longName}
						ref={register}
						defaultValue={location?.long}
					/>

					<Form.Text className="text-danger">{errors.location?.message}</Form.Text>
					<Accordion.Collapse eventKey={fieldName}>
						<Card.Body>
							<Fragment>
								{showConfirm && (
									<Fragment>
										{location ? (
											<Fragment>
												{hideConfirmBtn ? (
													<p className="text-success pt-2">Location set Successfully</p>
												) : (
													<p className="text-danger pt-2">Drag marker to change location</p>
												)}
												<p>
													<i className="fas fa-map-marker-alt text-primary px-2" />
													<span>
														Lat: {Number(getValues(latName)) || location.latitude}, Lng: 
														{Number(getValues(longName)) || location.longitude}
													</span>
												</p>
												<div className="row  m-0">
													<div className="col-md-12 p-0 ">
														<div
															key={`${location.latitude}-${location.longitude}-${isDraggable}`}
															css={css`
																width: 600px;
																height: 400px;
																overflow-y: auto;
															`}
														>
															{console.log(location, 'location')}
															<GoogleMap
																lat={
																	Number(getValues(latName)) || location.latitude || 0
																}
																lng={
																	Number(getValues(longName)) ||
																	location.longitude ||
																	0
																}
																draggable={isDraggable}
																onDragend={(t, map, coord) =>
																	moveMarker(
																		t,
																		map,
																		coord,
																		locationName,
																		latName,
																		longName,
																		setValue
																	)
																}
																showPublicChannels={false}
															/>
														</div>
													</div>
												</div>
												{!hideConfirmBtn && (
													<Button
														variant="primary"
														className="mr-1 mt-2"
														onClick={() => {
															getLatLngFromAddress(
																watchLocation,
																latName,
																longName,
																setLocation,
																setValue
															);
															setHideConfirmBtn(true);
															setIsDraggable(false);
														}}
													>
														Confirm
													</Button>
												)}
											</Fragment>
										) : (
											<div
												css={css`
													margin: 0;
													display: flex;
													align-items: center;
													justify-content: center;
													width: 100%;
												`}
											>
												<VscLoading className="spin" />
												<span
													css={css`
														margin-left: 0.5rem;
													`}
												>
													Loading ...
												</span>
											</div>
										)}
									</Fragment>
								)}
							</Fragment>
						</Card.Body>
					</Accordion.Collapse>
				</Form.Group>
			</fieldset>
		</Card>
	);
};

export default GPSLocationCard;