import React, { Fragment, useMemo } from 'react';
import { css } from 'twin.macro';
import { ActionStyles } from '../../reusableStyles';
import { BootstrapToolTip } from '../../../../_elements/ToolTip';
import Avatar from '../../reusableComponents/Avatar';
import { BsEye } from 'react-icons/bs';
import { BiLabel } from 'react-icons/bi';

import { formatDate } from '@fullcalendar/core/index.js';
import { statusLabel } from './index';
import { decimalHoursToHoursAndMinutes } from '../../../../_helpers';

export const getBadgeClass = (status) => {
    switch (status) {
        case 'draft':
            return 'dark';
        case 'payment_pending':
            return 'warning';
        case 'payment_processed':
            return 'success';
        case 'approved':
            return 'primary';
        case 'rejected':
            return 'danger';
        default:
            return '';
    }
};

const PtoRow = ({ formData, currentPage, index, handleShow }) => {
    const status = statusLabel[formData?.submissionType] || formData?.submissionType;
    const requestedTime = useMemo(() => {
        return decimalHoursToHoursAndMinutes(formData?.specialityForm?.data?.requestedBalance);
    }, [formData]);

    const requestedDateRange = useMemo(() => {
        let string = '';
        if (formData?.specialityForm?.data?.startDate) {
            const { startDate, endDate } = formData.specialityForm.data;
            string = `${formatDate(startDate)} - ${formatDate(endDate)}`;
        }
        return string;
    }, [formData]);
    return (
        <Fragment>
            <tr>
                <td>{10 * (currentPage - 1) + index + 1}</td>
                <td
                    className="d-flex align-items-center"
                    css={css`
                        flex: 1;
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                            flex-direction: column;
                            gap: 0.5rem;
                        `}
                    >
                        <div
                            css={css`
                                display: flex;
                                flex-direction: row;
                            `}
                        >
                            <span
                                css={css`
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    max-width: 50rem;
                                `}
                            >
                                <div
                                    className={`${
                                        formData?.userData?.name ? '' : 'text-danger'
                                    } d-flex flex-row align-items-center`}
                                >
                                    <Avatar item={formData?.userData} />
                                    <span className="ml-2">{formData?.userData?.name}</span>
                                </div>
                            </span>
                        </div>
                    </div>
                </td>
                <td className="text-center">{formData?.chatroomData?.title}</td>
                <td className="text-center">{formatDate(formData?.createdAt)}</td>
                <td className="text-center">{`${requestedTime?.hours} hours ${requestedTime?.minutes} minutes`}</td>
                <td className="text-center">{requestedDateRange}</td>
                <td>
                    <div className="d-flex justify-content-center">
                        <div className={`text-center badge badge-pill badge-${getBadgeClass(status)}`}>{status}</div>
                    </div>
                </td>
                <td>
                    <div className="d-flex justify-content-center">
                        <ActionStyles className="mt-2">
                            <BootstrapToolTip text={'View Form'}>
                                <BsEye className="icon" onClick={() => handleShow(formData, 'view')} />
                            </BootstrapToolTip>
                        </ActionStyles>
                        <ActionStyles className="mt-2">
                            <BootstrapToolTip text={'Action'}>
                                <BiLabel className="icon" onClick={() => handleShow(formData, 'data')} />
                            </BootstrapToolTip>
                        </ActionStyles>
                    </div>
                </td>
            </tr>
        </Fragment>
    );
};

export default PtoRow;
