import * as ActionTypes from '../actions/ActionTypes';

export default function Organization (
	state = { isLoading: true, errorMessage: null, organizations: [], totalOrganizations: 0 },
	action
) {
	let index;
	switch (action.type) {
		case ActionTypes.SET_ORGANIZATION:
			return {
				...state,
				isLoading: true,
				errorMessage: null,
				organizations: action?.payload?.results,
				totalOrganizations: action?.payload?.totalResults,
			};
		case ActionTypes.UPDATE_ORGANIZATION:
			index = state.organizations.findIndex((org) => org.id === action.payload.id);
			if (index !== -1) {
				const tempOrg = [...state.organizations];
				tempOrg[index] = action.payload;
				return { ...state, organizations: tempOrg };
			}
			return {
				...state,
				organizations: [action.payload, ...state.organizations],
				totalOrganizations: state.totalOrganizations + 1,
			};
		case ActionTypes.REMOVE_ORGANIZATION:
			const tempOrg = [...state.organizations];
			index = tempOrg.findIndex((org) => org.id === action.payload);
			tempOrg.splice(index, 1);
			return { ...state, organizations: tempOrg, totalOrganizations: state.totalOrganizations - 1 };
		default:
			return state;
	}
}
