import { css } from 'twin.macro';
import { useEffect } from 'react';

// import PropTypes from 'prop-types';

import FormPicker from './FormPicker';
import DynamicPreview from './DynamicPreview';
import { useDispatch } from 'react-redux';
import { CreateFormStyles } from './styles/CreateFormStyles';

import {getTeamIdFromURL , getChannelIdFromSettingsURL} from '../../../_helpers';



//icons

export const CreateForm = () => {
	const dispatch = useDispatch();

	const teamId = getTeamIdFromURL();
	const channelId = getChannelIdFromSettingsURL();
	useEffect(() => {
		dispatch({ type: 'REMOVE_fORM' });
	}, [dispatch]);

	return (
		<CreateFormStyles>
			<div className="my-4">
				<h4
					css={css`
						font-weight: bold;
					`}
				>
					Form Builder
				</h4>
			</div>
			<div className="d-flex justify-content-between position-relative">
				<DynamicPreview teamId={teamId} channelId={channelId} />
				<FormPicker teamId={teamId} openFormBuilder />
			</div>
		</CreateFormStyles>
	);
};

CreateForm.propTypes = {};

export default CreateForm;
