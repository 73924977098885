import { useState, useEffect, Fragment, useCallback, useContext } from 'react';
import { css } from 'twin.macro';
import PropTypes from 'prop-types';

//components
import TeamHeading from './TeamHeading';
import Messages from './Messages';
import ChatInput from '../Common/ChatInput';

//helpers
import { serviceToCounter, getUniqueObjects } from '../../../_helpers';

//redux
import { connect } from 'react-redux';
import { WebSocketContext } from '../../../_redux/actions/ActionSocket';
import { clearSearchMessages } from '../../../_redux/actions/ActionMessage';
import { useContextStore } from '../../../_context/contextStore';

//layout elements
import container from '../../../_elements/container';
import outerContainer from '../../../_elements/outerContainer';
import ClearSearch from '../Common/ClearSearch';
import ChatSkeleton from '../Layouts/ChatSkeleton';
import { useRef } from 'react';
import moment from 'moment';

function TeamChat({
	MessagesFromRedux,
	room,
	position,
	setChatFormId,
	setOpenDetails,
	openDetails,
	setShowChat,
	userRole,
	team,
	teamData,
}) {
	const [oldmessages, setoldmessages] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [reply, setReply] = useState('');
	const [counterArray, setCounterArray] = useState([]);
	const [taskArray, setTaskArray] = useState([])
	const [subChannelArray, setSubChannelArray] = useState([])
	const ws = useContext(WebSocketContext);
	const { onGoingServices } = useContextStore();
	const [_, setOnGoingServicesArr] = onGoingServices;

	const updateSearchText = (txt) => {
		setSearchText(txt);
	};

	const newMessageRef = useRef(null);

	const { isLoading, isSearch, searchFilters, searchMessages, messages } = MessagesFromRedux;

	// const fetchCounterData = async () => {
	// 	const response = await fetchWrapper.get(`checkCounter/${room.id}`);
	// 	response?.results?.forEach((counter) => {
	// 		if (room.id === counter.chatroom && counter.type === 'sos') {
	// 			changeCounterArray(counter);
	// 		}
	// 	});
	// };


	useEffect(() => {
		const getUniqueCounters = (allCounters, counter) =>
			[...allCounters].filter(({ code, username }) => code !== counter.code || username !== counter.username);

		const getUniqueTasks = (allTasks, task) =>
			[...allTasks].filter(({ id }) => id !== task.id);

		if (ws?.socket) {
			ws.socket.on('service:start', (data) => {
				setOnGoingServicesArr((prev) => [...(prev || [])?.filter(x=>x?.serviceCode!==data?.serviceCode||x?.employee!==data?.employee), { ...prev?.find(x=>x?.serviceCode===data?.serviceCode && x?.employee===data?.employee), ...data, roomId: room?.id }])
				const counter = serviceToCounter(data)
				return setCounterArray((prev) => [...getUniqueCounters(prev, counter), counter]);
			});
			ws.socket.on('service:end', (data) => {
				setOnGoingServicesArr((prev) => prev?.filter((service) => (
					service?.roomId === room?.id && service.serviceCode !== data.serviceCode
				)));
				const { serviceCode: code, employee: username } = data;
				return setCounterArray((prev) => getUniqueCounters(prev, { code, username }));
			});
			ws.socket.on('task:start', (data) => {
				if (data?.room && data.room === room?.id) {
					return setTaskArray((prev) => [...getUniqueTasks(prev, data), data]);
				}
			});
			ws.socket.on('task:end', (data) => {
				return setTaskArray((prev) => getUniqueTasks(prev, data));
			});
			ws.socket.on('subChannelTask:start', (data) => {
				if (data?.room && data.room === room?.id) {
					return setSubChannelArray((prev) => [...getUniqueTasks(prev, data), data]);
				}
			});
			ws.socket.on('subChannelTask:end', (data) => {
				return setSubChannelArray((prev) => getUniqueTasks(prev, data));
			});
		}
		return () => {
			if (ws?.socket) {
				ws.socket.off('service:start');
				ws.socket.off('service:end');
				ws.socket.off('task:start');
				ws.socket.off('task:end');
				ws.socket.off('subChannelTask:start');
				ws.socket.off('subChannelTask:end');
			}
		};
	}, [room.id, ws]);

	const validOngoingTask = task => task.startedAt && task.endsAt && moment(task.startedAt).isBefore(moment.now()) && moment(task.endsAt).isAfter(moment.now())

	useEffect(() => {
		setOnGoingServicesArr(room?.ongoingServices?.map(service => ({
			...service,
			roomId: room?.id
		})));
		setCounterArray(room.ongoingServices?.map(serviceToCounter)?.filter(counterData => counterData) || []);
		setTaskArray(room.ongoingTasks?.filter(validOngoingTask) || []);
		setSubChannelArray(room.ongoingSubChannelTasks?.filter(validOngoingTask) || []);
	}, [room.id]);

	const setMessages = useCallback(() => {
		if (messages[room.id]) {
			let tempoldmessages = messages[room.id].message
				.map((history) => ({
					isEdited: history.isEdited,
					visibleTo: history.visibleTo,
					id: history.id,
					file: history.file,
					images: history.images,
					files: history.files,
					data: history.data,
					type: history.type,
					userName: history.userName,
					user: history.user,
					seenBy: history.seenBy,
					roomId: history.room,
					dateAndTime: history.createdAt,
					reaction: history.reaction || [],
					yourReaction: history.yourReaction,
					conferenceData: history.conferenceData,
					conference: history.conference,
				}))
				.reverse();
			setoldmessages(getUniqueObjects(tempoldmessages));
		}
	}, [messages, room.id]);

	let totalMessages, lastSeenMessage;
	if (messages[room.id]) {
		totalMessages = messages[room.id].totalMessages;
		lastSeenMessage = messages[room.id].lastSeen;
	}

	useEffect(() => {
		let isActive = true;
		let tempoldmessages;
		if (!isSearch && isActive) {
			setMessages();
		} else {
			if (searchMessages) {
				tempoldmessages =
					searchMessages &&
					searchMessages
						.map((history) => ({
							isEdited: history.isEdited,
							visibleTo: history.visibleTo,
							id: history.id,
							file: history.file,
							images: history.images,
							files: history.files,
							data: history.data,
							type: history.type,
							user: history.user,
							userName: history.userName,
							seenBy: history.seenBy,
							roomId: history.room,
							dateAndTime: history.createdAt,
							reaction: history.reaction || [],
							yourReaction: history.yourReaction,
						}))
						.reverse();
				setoldmessages(getUniqueObjects(tempoldmessages));
			}
		}
		return () => (isActive = false);
	}, [searchMessages, isSearch, setMessages]);

	const sendMessage = (event, message, permission = null) => {
		event.preventDefault();
		if (message) {
			ws.sendMessage(message, reply, position, { chatroomId: room.id, permissionArr: permission });
			// scroll to bottom on new message sent
			if (newMessageRef?.current) {
				newMessageRef.current.click();
			}
		}
	};

	return (
		<div css={outerContainer}>
			<div css={container}>
				<TeamHeading
					room={room}
					setOpenDetails={setOpenDetails}
					openDetails={openDetails}
					setShowChat={setShowChat}
					updateSearchText={updateSearchText}
					searchText={searchText}
					userRole={userRole}
					counterArray={counterArray}
					taskArray={taskArray}
					subChannelArray={subChannelArray}
				/>

				<ClearSearch updateSearchText={updateSearchText} />
				{isLoading ? (
					<div className="mx-4">
						<ChatSkeleton type="team" />
					</div>
				) : (
					<Fragment>
						{oldmessages.length > 0 && oldmessages[0].roomId === room.id ? (
							<Messages
								setReply={setReply}
								messages={oldmessages}
								isSearch={isSearch}
								searchFilters={searchFilters}
								type="team"
								roomType={room.type}
								setChatFormId={setChatFormId}
								roomId={room.id}
								totalMessages={totalMessages}
								lastSeen={lastSeenMessage}
								userRole={userRole}
								teamData={teamData}
								newMessageRef={newMessageRef}
							/>
						) : (
							<div className="h-100 d-flex text-center flex-column justify-content-center align-items-center">
								<h2>
									<b>No Posts Yet!</b>
								</h2>
								<div
									css={css`
										color: gray;
										font-style: italic;
										width: 30%;
										line-height: 1.2;
										margin-top: 0.5rem;
									`}
								>
									Post your first message.
								</div>{' '}
							</div>
						)}
							{
								(room?.id || room?._id) ?
									<ChatInput
										id={room.id}
										roomType={room.type}
										reply={reply}
										setReply={setReply}
										type="team"
										key={room.id}
										sendMessage={sendMessage}
										userRole={userRole}
										currRoom={room}
										team={team}
									/> : null
							}
					</Fragment>
				)}
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
	MessagesFromRedux: state.messages,
	position: state.user.position,
	user: state.user.user,
});
TeamChat.propTypes = {
	MessagesFromRedux: PropTypes.object.isRequired,
	room: PropTypes.object.isRequired,
	position: PropTypes.object,
	user: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {
	clearSearchMessages,
})(TeamChat);
