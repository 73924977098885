import { css } from 'twin.macro';
import { Card } from 'react-bootstrap';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const chatList = css`
	width: 100%;
	.card {
		padding: 10px;
	}
`;

const SkeletonCardCSS = css`
	margin: auto;
	width: 100%;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	.profileImgDiv {
		margin-right: 1rem;
	}
	.NameChatDiv {
		width: 100%;
		h2 {
			width: 60%;
			padding: 0;
			margin: 0;
		}
		span {
			width: 80%;
		}
	}
`;
const SkeletonTeamCardCSS = css`
	margin: auto;
	width: 100%;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	.profileImgDiv {
		margin-right: 1rem;
	}
	.NameChatDiv {
		width: 100%;
		h2 {
			width: 80%;
			height: 20%;
			padding: 0;
			margin: 0;
		}
	}
`;

const SkeletonCard = ({ type }) => {
	return (
		<Card>
			<SkeletonTheme color="#d3d3d3" highlightColor="rgba(240, 240, 240, 0.5)">
				{type === 'team' ? (
					<div css={SkeletonTeamCardCSS}>
						<div className="profileImgDiv">
							<Skeleton circle={true} height={35} width={35} />
						</div>
						<div className="NameChatDiv">
							<h2>
								<Skeleton height={25} />
							</h2>
						</div>
					</div>
				) : (
					<div css={SkeletonCardCSS}>
						<div className="profileImgDiv">
							<Skeleton circle={true} height={35} width={35} />
						</div>
						<div className="NameChatDiv">
							<h2>
								<Skeleton />
							</h2>
							<Skeleton />
						</div>
					</div>
				)}
			</SkeletonTheme>
		</Card>
	);
};

function ChatListSkeleton({ type }) {
	return (
		<div css={chatList}>
			{type === 'team' ? <Skeleton height={32} /> : null}
			{Array.from({ length: 20 }, (v, index) => (
				<SkeletonCard key={index} type={type} />
			))}
		</div>
	);
}

export default ChatListSkeleton;
