import React, { useEffect, useState } from 'react';
import { css } from 'twin.macro';
import { Button, Form, FormGroup, FormLabel, Tab, Tabs } from 'react-bootstrap';

import Select from 'react-select';
import { FaChevronCircleDown, FaChevronCircleUp } from 'react-icons/fa';
import { StyledLinkBtn } from '../../Tasks/EventModalStyles';
import { HiMinusCircle, HiPlusCircle } from 'react-icons/hi';
import { fetchWrapper } from '../../../../_helpers';

function getFormLabel(name) {
    const [initial, ...other] = (name || 'Unnamed Field')
        .replace(/([a-z0-0]_[a-z0-9])/g, (v) => `${v[0]} ${v[2].toUpperCase()}`)
        .replaceAll('_', ' ')
        .split('');
    return initial.toUpperCase() + other.join('').replaceAll(/([a-z])([A-Z])/g, '$1 $2');
}

function getFieldId() {
    return `${Date.now()}_${Math.round(Math.random() * 100)}`;
}

function getDefaultValues(formType, typeData) {
    const defaultValues = {};
    function getEntries([key, value]) {
        if (Array.isArray(value)) {
            value.forEach((subvalue, index) => getEntries([`${key}[${index}]`, subvalue]));
        } else if (value && typeof value === 'object') {
            Object.entries(value).forEach(([subkey, subvalue]) => getEntries([`${key}.${subkey}`, subvalue]));
        }
        defaultValues[`_${formType}.${typeData?.type || 'optonome'}.${key}`] = value;
    }
    Object.entries(typeData?.data || {}).forEach(getEntries);
    return defaultValues;
}

const getErrorMessage = (errors, name) => {
    try {
        return eval(`errors.${name}`.replaceAll('.', '?.'))?.message;
    } catch (e) {
        console.log('ERROR IN GET ERROR MESSAGE => ', e);
        return '';
    }
};

function EvvFormBuilder({
    formType,
    formStructure,
    isViewMode,
    isEditMode,
    type,
    setTypeFunc,
    typeOptions,
    typeData = {},
    allowedTypes = [],
    register,
    setValue,
    getValues,
    errors,
    setError,
    clearErrors,
    itemData,
    forceExpandAll,
}) {
    useEffect(() => {
        const timeout = setTimeout(() => {
            setTypeFunc(formType, typeData?.type);
        });
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    useEffect(() => {
        const defaultValues = getDefaultValues(formType, typeData);
        const timeout = setTimeout(() => {
            Object.entries(defaultValues).forEach(([name, value]) => setValue(name, value));
        });
        return () => {
            clearTimeout(timeout);
        };
    }, [formType, typeData]);

    return (
        <>
            <FormGroup>
                <FormLabel className="text-capitalize">{formType} Type</FormLabel>

                <Select
                    value={typeOptions[formType][type]}
                    onChange={(option) => {
                        setTypeFunc(formType, option?.value);
                    }}
                    options={Array.from(new Set(allowedTypes?.filter((type) => !!type) || ['optonome'])).map(
                        (type) => typeOptions[formType][type]
                    )}
                    isDisabled={isViewMode}
                />
            </FormGroup>
            {formStructure[type] &&
                Object.keys(typeOptions[formType])
                    .filter((type) => formStructure[type] && (!allowedTypes || allowedTypes.includes(type)))
                    .map((key) => (
                        <div style={type !== key ? { display: 'none' } : {}} key={key}>
                            <Tabs activeKey={key}>
                                <Tab
                                    eventKey={key}
                                    title={
                                        <span
                                            style={{ textTransform: 'capitalize' }}
                                        >{`${type} ${formType} Setup`}</span>
                                    }
                                >
                                    <EvvForm
                                        formStructure={formStructure}
                                        errors={errors}
                                        register={register}
                                        isEditMode={isEditMode}
                                        isViewMode={isViewMode}
                                        setValue={setValue}
                                        disabled={key !== type}
                                        defaultValues={getDefaultValues(formType, typeData)}
                                        setError={setError}
                                        getValues={getValues}
                                        clearErrors={clearErrors}
                                        formType={formType}
                                        type={key}
                                        // isFormSubmitting={isFormSubmitting}
                                        // setIsFormSubmitting={setIsFormSubmitting}
                                        itemData={itemData}
                                        forceExpandAll={forceExpandAll}
                                    />
                                </Tab>
                            </Tabs>
                        </div>
                    ))}
        </>
    );
}

function EvvForm({
    formType,
    formStructure,
    isViewMode,
    isEditMode,
    type = 'optonome',
    register,
    setValue,
    getValues,
    errors,
    setError,
    clearErrors,
    disabled,
    defaultValues,
    // isFormSubmitting,
    // setIsFormSubmitting,
    itemData,
    forceExpandAll,
}) {
    return (
        <>
            {formStructure[type] &&
                formStructure[type]
                    .filter((formData) => !isEditMode || !formData.immutable)
                    .map((formData, idx) => (
                        <EvvFormComponent
                            formData={{
                                ...formData,
                                name: `_${formType}.${type}.${formData.name}`,
                                caption: formData.caption ?? formData.name,
                            }}
                            key={idx}
                            setValue={setValue}
                            register={register}
                            disabled={isViewMode || disabled}
                            defaultValues={defaultValues}
                            errors={errors}
                            setError={setError}
                            getValues={getValues}
                            clearErrors={clearErrors}
                            // isFormSubmitting={isFormSubmitting}
                            // setIsFormSubmitting={setIsFormSubmitting}
                            itemData={itemData}
                            forceExpandAll={forceExpandAll}
                        />
                    ))}
        </>
    );
}

function EvvFormComponent({
    formData,
    setValue,
    register,
    disabled,
    defaultValues,
    errors,
    setError,
    getValues,
    clearErrors,
    // isFormSubmitting,
    // setIsFormSubmitting,
    itemData,
    forceExpandAll,
}) {
    const defaultValue = defaultValues[formData.name];
    const [show, setShow] = useState(!!(disabled || formData?.required));
    const [fields, setFields] = useState();

    const [options, setOptions] = useState();

    useEffect(() => {
        console.log('FORCE EXPAND', forceExpandAll);
        setShow((prev) => prev || forceExpandAll);
    }, [forceExpandAll]);

    useEffect(() => {
        if (!options) {
            try {
                if (!formData?.fetchOptions || !itemData) {
                    throw new Error('');
                }
                const url = formData.fetchOptions.replace(/\{\{[^\}]+\}\}/g, (v) => {
                    const key = v.replace(/[\{\}]+/g, '');
                    const subKeys = key.split('.');
                    let data = itemData;
                    subKeys.forEach((k) => {
                        data = data[k];
                    });
                    return data;
                });
                fetchWrapper
                    .get(url)
                    .then((data) =>
                        setOptions(
                            data.map((option) => ({
                                name: option[formData.mapOptions?.name || 'name'],
                                value: option[formData.mapOptions?.value || 'value'],
                            }))
                        )
                    )
                    .catch((error) => {
                        setOptions(formData?.options || []);
                    });
            } catch (error) {
                setOptions(formData?.options || []);
            }
        }
    }, [itemData, formData, options]);

    useEffect(() => {
        setShow((prev) => prev || !!defaultValue);
        setFields((prev) =>
            formData.type === 'array'
                ? ((!prev || disabled) && Array.isArray(defaultValue) && defaultValue.length
                      ? defaultValue
                      : disabled || !formData?.required
                        ? prev || []
                        : [1]
                  ).map(getFieldId)
                : formData.type === 'object'
                  ? (!prev || disabled) && Object.values(defaultValue || {}).filter((x) => x).length
                      ? formData?.fields
                      : disabled || !formData?.required
                        ? prev || []
                        : formData?.fields
                  : prev || []
        );
    }, [defaultValue, disabled]);

    if (formData.type === 'checkbox') {
        return (
            <Form.Group>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '6px' }}>
                    <Form.Control
                        style={{ width: '24px', cursor: 'pointer' }}
                        name={formData.name}
                        type={'checkbox'}
                        id={formData.name}
                        required={formData.required}
                        defaultValue={defaultValue ?? formData.default}
                        disabled={disabled}
                        readOnly={formData.readonly}
                        ref={register}
                        onWheel={(event) => event.currentTarget.blur()}
                        onChange={(e) => {
                            setValue(formData.name, e.target.checked);
                        }}
                    />
                    <Form.Label>
                        {getFormLabel(formData.caption)}
                        {formData.required && (
                            <span
                                css={css`
                                    color: red;
                                    margin-left: 2px;
                                `}
                            >
                                *
                            </span>
                        )}
                    </Form.Label>
                </div>
                <Form.Text
                    css={css`
                        text-align: justify;
                        opacity: 0.75;
                    `}
                >
                    {formData.hint}
                </Form.Text>
            </Form.Group>
        );
    }

    if (formData.type === 'select') {
        return (
            <Form.Group>
                <Form.Label>
                    {getFormLabel(formData.caption)}
                    {formData.required && (
                        <span
                            css={css`
                                color: red;
                                margin-left: 2px;
                            `}
                        >
                            *
                        </span>
                    )}
                </Form.Label>

                <Form.Control
                    as="select"
                    name={formData.name}
                    onChange={(e) => setValue(formData.name, e.target.value)}
                    ref={register}
                    required={!!formData.default || formData.required}
                    disabled={disabled}
                    defaultValue={defaultValue ?? formData.default}
                >
                    {(options || []).map((option, index) => (
                        <option
                            value={option.value ?? option}
                            key={index}
                            selected={(defaultValue ?? formData.default) === (option?.value ?? option)}
                        >
                            {getFormLabel(option.name ?? option)}
                        </option>
                    ))}
                </Form.Control>
                <Form.Text
                    css={css`
                        text-align: justify;
                        opacity: 0.75;
                    `}
                >
                    {formData.hint}
                </Form.Text>
            </Form.Group>
        );
    }

    if (formData.type === 'object') {
        return (
            <div style={{ marginBottom: '12px' }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignContent: 'center',
                        marginBottom: '6px',
                        cursor: 'pointer',
                        borderBottom: '1px solid #ccc',
                    }}
                    onClick={() => setShow((prev) => !prev)}
                >
                    <span>
                        {getFormLabel((formData.caption || formData.name).split('.').at(-1))}
                        {formData.required && (
                            <span
                                css={css`
                                    color: red;
                                    margin-left: 2px;
                                `}
                            >
                                *
                            </span>
                        )}
                    </span>
                    {show ? (
                        <FaChevronCircleUp className="text-primary" />
                    ) : (
                        <FaChevronCircleDown className="text-primary" />
                    )}
                </div>
                <Form.Text
                    css={css`
                        text-align: justify;
                        opacity: 0.75;
                    `}
                >
                    {formData.hint}
                </Form.Text>
                {fields?.map((fieldData, idx) => (
                    <div
                        style={{
                            marginLeft: '16px',
                            display: show ? 'block' : 'none',
                        }}
                        key={idx}
                    >
                        <EvvFormComponent
                            formData={{
                                ...fieldData,
                                name: `${formData.name}.${fieldData.name}`,
                                caption: fieldData.caption || fieldData.name,
                            }}
                            setValue={setValue}
                            register={register}
                            disabled={disabled}
                            defaultValues={defaultValues}
                            errors={errors}
                            setError={setError}
                            getValues={getValues}
                            clearErrors={clearErrors}
                            itemData={itemData}
                            forceExpandAll={forceExpandAll}
                        />
                    </div>
                ))}
                {!disabled && !formData.required && fields?.length > 0 && (
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'flex-end',
                            marginBottom: '6px',
                        }}
                    >
                        <StyledLinkBtn
                            className="danger"
                            onClick={() => {
                                setFields([]);
                                // setFields({})
                                setShow(false);
                            }}
                        >
                            <HiMinusCircle />
                            Remove {getFormLabel(formData.name.split('.').at(-1))}
                        </StyledLinkBtn>
                    </div>
                )}
                {!disabled && !fields?.length && (
                    <div style={{ position: 'relative' }}>
                        <StyledLinkBtn
                            className="success"
                            onClick={() => {
                                setFields(formData?.fields);
                                setShow(true);
                            }}
                        >
                            <HiPlusCircle />
                            Add {getFormLabel(formData.name.split('.').at(-1))}
                        </StyledLinkBtn>
                    </div>
                )}
            </div>
        );
    }

    if (formData.type === 'array') {
        return (
            <div style={{ marginBottom: '12px' }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignContent: 'center',
                        marginBottom: '6px',
                        cursor: 'pointer',
                        borderBottom: '1px solid #ccc',
                    }}
                    onClick={() => setShow((prev) => !prev)}
                >
                    <div>
                        {getFormLabel((formData.name || formData.caption).split('.').at(-1))} ({fields?.length || 0})
                        {formData.required && (
                            <span
                                css={css`
                                    color: red;
                                    margin-left: 2px;
                                `}
                            >
                                *
                            </span>
                        )}
                    </div>
                    {show ? (
                        <FaChevronCircleUp className="text-primary" />
                    ) : (
                        <FaChevronCircleDown className="text-primary" />
                    )}
                </div>
                <Form.Text
                    css={css`
                        text-align: justify;
                        opacity: 0.75;
                    `}
                >
                    {formData.hint}
                </Form.Text>

                {fields?.map((id, index) => (
                    <React.Fragment key={id}>
                        <div
                            style={{
                                marginLeft: '16px',
                                marginBottom: '12px',
                                background: index % 2 ? '#eee9' : '#fff',
                                border: '1px solid #ccc',
                                padding: '6px',
                                borderRadius: '6px',
                                position: 'relative',
                                display: show ? 'block' : 'none',
                            }}
                        >
                            {formData?.fields?.map((fieldData) => (
                                <EvvFormComponent
                                    key={fieldData.name}
                                    formData={{
                                        ...fieldData,
                                        name: `${formData.name}[${index}].${fieldData.name}`,
                                        caption: fieldData.caption || fieldData.name,
                                    }}
                                    setValue={setValue}
                                    register={register}
                                    disabled={disabled}
                                    defaultValues={defaultValues}
                                    errors={errors}
                                    setError={setError}
                                    getValues={getValues}
                                    clearErrors={clearErrors}
                                    itemData={itemData}
                                    forceExpandAll={forceExpandAll}
                                />
                            ))}
                            {disabled ||
                                ((!formData.required || fields?.length > 1) && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            width: '100%',
                                            justifyContent: 'flex-end',
                                            marginBottom: '6px',
                                        }}
                                    >
                                        <StyledLinkBtn
                                            className="danger"
                                            onClick={() => {
                                                setFields((prev) => prev.filter((field) => field !== id));
                                            }}
                                        >
                                            <HiMinusCircle />
                                            Remove
                                        </StyledLinkBtn>
                                    </div>
                                ))}
                        </div>
                    </React.Fragment>
                ))}

                {disabled || (
                    <div style={{ position: 'relative' }}>
                        <StyledLinkBtn
                            className="success"
                            onClick={() => {
                                setFields((prev) => [...prev, getFieldId()]);
                                setShow(true);
                            }}
                        >
                            <HiPlusCircle />
                            Add {getFormLabel(formData.name.split('.').at(-1))}
                        </StyledLinkBtn>
                    </div>
                )}
            </div>
        );
    }

    return (
        <Form.Group>
            <Form.Label>
                {getFormLabel(formData.caption)}
                {formData.required && (
                    <span
                        css={css`
                            color: red;
                            margin-left: 2px;
                        `}
                    >
                        *
                    </span>
                )}
            </Form.Label>
            <Form.Control
                name={formData.name}
                type={formData.type ? (formData.type === 'datetime' ? 'datetime-local' : formData.type) : 'text'}
                id={formData.name}
                required={formData.required}
                defaultValue={
                    formData.type === 'datetime'
                        ? defaultValue?.concat('T00:00').slice(0, 16)
                        : defaultValue || formData.default
                }
                disabled={disabled}
                maxLength={formData.maxLength}
                minLength={formData.minLength}
                min={formData.min}
                max={formData.max}
                readOnly={formData.readonly}
                step={formData.precision && 10 ** -formData.precision}
                ref={register}
                onWheel={(event) => event.currentTarget.blur()}
                onChange={(e) => {
                    setValue(formData.name, formData.type === 'checkbox' ? e.target.checked : e.target.value);
                    const regexPattern = formData?.regex ? new RegExp(formData?.regex) : null;
                    const currentValue = getValues(formData.name);
                    const isValid = regexPattern ? regexPattern.test(currentValue) : true;
                    clearErrors(formData.name);
                    if (!isValid) {
                        setError(formData.name, {
                            type: 'manual',
                            message: formData?.errorMessage || `${getFormLabel(formData.caption)} is invalid.`,
                        });
                    } else {
                        clearErrors(formData.name);
                    }
                }}
            />
            <Form.Text
                css={css`
                    text-align: justify;
                    opacity: 0.75;
                    color: red;
                `}
            >
                {getErrorMessage(errors, formData?.name)}
            </Form.Text>
            <Form.Text
                css={css`
                    text-align: justify;
                    opacity: 0.75;
                `}
            >
                {formData.hint}
            </Form.Text>
        </Form.Group>
    );
}

export default EvvFormBuilder;
