import React, { Fragment } from 'react';
import { css } from 'twin.macro';
import { Button, FormControl, FormLabel, Modal } from 'react-bootstrap';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { editATeam, editATeamAvatar } from '../../../_redux/actions/ActionOrganization';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { MdEdit } from 'react-icons/md';
import { Body, EditImgStyled, Overlay, StyledForm } from './Styles/EditTeamStyles';
// import EditImage from '../../Features/Common/EditImage';

import { getInitials, getRandomColor } from '../../../_helpers';
import { IMAGESTYPES } from '../../../contants/enums';

const EditTeam = ({ team, showEditModal, handleHideEditModal, editATeam, editATeamAvatar, organizations }) => {
	team.color = getRandomColor(team.id);
	team.initials = getInitials(team.name);
	// modals
	const [file, setFile] = useState(null);
	const { register, handleSubmit } = useForm({
		mode: 'onBlur',

		// resolver: yupResolver(logValidationSchema),
	});
	const handleFile = (e) => {
		const maxFileSize = 20 * 1024 * 1024;
		const imageFile = e.target.files[0];
		if (imageFile && imageFile.size < maxFileSize) {
			setFile(imageFile);
		} else {
			toast.warn('Image Must be less than 20 mb in size!');
		}
	};

	const onSubmit = (data) => {
		if (file) {
			const tempFile = new FormData();
			tempFile.append('avatar', file);
			editATeamAvatar(team.id, organizations[0].id, tempFile);
		}
		if (team.name !== data.team_name) {
			const updatedTeam = {
				name: data.team_name,
				admin: team.admin,
				organizations: team.organizations,
			};
			editATeam(organizations[0].id, team.id, updatedTeam);
		}
		handleHideEditModal();
	};

	const handleClose = () => {
		setFile(null);
		handleHideEditModal();
	};

	return (
		<Fragment>
			<Modal
				dialogClassName="dialog-rounded"
				backdropClassName="modal-backdrop"
				show={showEditModal}
				onHide={handleClose}
				centered
			>
				<Modal.Body className="modal-body">
					<Body>
						<div className="header">
							<h4>
								Edit {`- `}
								{team.name}
							</h4>
							<p className="text-muted" style={{ fontSize: '14px' }}></p>
						</div>

						<div className="imgs d-flex flex-row justify-content-center w-20">
							<label>
								<input
									onChange={(e) => handleFile(e)}
									className="d-none"
									accept={IMAGESTYPES}
									type="file"
									name="avatar"
								/>
								{team.avatar ? (
									<EditImgStyled className="editImage">
										<Overlay>
											<img
												className="teamImg edit-img"
												css={css`
													width: 100%;
													height: 100%;
													object-fit: cover;
												`}
												src={file ? URL.createObjectURL(file) : team.avatar}
												alt="profile"
											/>
											<MdEdit className="fa-edit" />
										</Overlay>
									</EditImgStyled>
								) : (
									<div className="d-flex flex-row justify-content-center align-items-center">
										<Overlay>
											<div
												className="initials"
												css={css`
													background-color: ${team.color};
												`}
											>
												<span
													css={css`
														width: 100%;
														height: 100%;
														display: flex;
														justify-content: center;
														align-items: center;
													`}
												>
													{file ? (
														<img
															className="edit-img"
															src={URL.createObjectURL(file)}
															alt="avatar"
															css={css`
																width: 100%;
																height: 100%;
																object-fit: cover;
															`}
														/>
													) : (
														team.initials
													)}
												</span>
											</div>
											<MdEdit className="fa-edit" />
										</Overlay>
									</div>
								)}
							</label>
						</div>

						<StyledForm onSubmit={handleSubmit(onSubmit)}>
							<FormLabel>Name of the team</FormLabel>
							<FormControl
								type="text"
								defaultValue={team.name}
								name="team_name"
								ref={register}
								required
							></FormControl>
							<div className="d-flex mt-4 flex-row justify-content-between">
								<Button variant="secondary" onClick={handleClose}>
									Cancel
								</Button>
								<Button type="submit" variant="primary" className="mr-2">
									Edit
								</Button>
							</div>
						</StyledForm>
					</Body>
				</Modal.Body>
			</Modal>
		</Fragment>
	);
};
const mapStateToProps = (state) => ({
	teams: state.teams.teams,
	organizations: state.organizations.organizations,
});

export default connect(mapStateToProps, { editATeam, editATeamAvatar })(EditTeam);
