import styled from '@emotion/styled';

export const CustomRendererStyled = styled('div')`
	header {
		position: sticky;
		top: 0;
		left: 0;
		background-color: #ffffff99;
		box-shadow: 0px 2px 3px #00000033;
		padding: 8px;
		z-index: 10;
	}
	header h1 {
		font-size: inherit;
		margin: 0;
	}
	button {
		width: 30px;
		height: 30px;
		background: white;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 0;
		border-radius: 35px;
		box-shadow: 2px 2px 3px #00000033;
		&:hover {
			background-color: #ddd;
		}
	}
	.container {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 10px 0;
		padding: 10px;
	}
	.container__load {
		margin-top: 1em;
		color: white;
	}
	.container__document {
		margin: 1em 0;
	}
	.container__document .react-pdf__Document {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.container__document .react-pdf__Page {
		max-width: calc(100% - 2em);
		box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
		margin: 1em;
	}
	.container__document .react-pdf__Page canvas {
		max-width: 100%;
		height: auto !important;
	}
	.container__document .react-pdf__message {
		padding: 20px;
		color: white;
	}
`;
