import { css } from 'twin.macro';
import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button, Modal, Form } from 'react-bootstrap';
import DateTimePicker from 'react-datetime-picker';
import { fetchWrapper, formatAMPM, formatDate, sortingOptionMethod } from '../../../_helpers';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { editTheTask, editTaskStatus } from '../../../_redux/actions/ActionTasks';
//components
import ChannelSelectFilter from '../ChannelSelectFilter';
import ReactSelectComponent from './ReactSelectComponent';

import { VscLoading } from 'react-icons/vsc';
import { CustFormLabel } from '../../../_elements/formLabel';
import { customCheckbox } from '../../../_elements/CustomCheckbox';
import { useMemo } from 'react';
import { MdCall } from 'react-icons/md';
import dayjs from 'dayjs';
import { createEvent } from 'ics';
import { saveAs } from 'file-saver';
import { BsArrowBarRight, BsChatDots } from 'react-icons/bs';
import { ToolTip } from '../../../_elements/ToolTip';
import { startConference, setConferenceData } from '../../../_redux/actions/ActionConference';

import CusotmRecurrence, { getRecurrenceData, nthDay, days } from '../../Features/Events/CustomRecurrence';
import FormViewAccordion from './FormViewAccordion';
import AvatarUserPanel from '../../Features/Common/AvatarUserPanel';

// const recurringOptions = [
// 	{ label: 'Daily', value: 'Daily' },
// 	{ label: 'Weekly', value: 'Weekly' },
// 	{ label: 'Monthly', value: 'Monthly' },
// ];
const reminderSet = {
    // Hourly: [
    // 	{ label: '15 Minutes', value: '15mins' },
    // 	{ label: '30 Minutes', value: '30mins' },
    // ],
    Daily: [
        { label: '15 Minutes', value: '15mins' },
        { label: '30 Minutes', value: '30mins' },
        { label: '1 hour', value: '1hour' },
    ],
    Weekly: [
        { label: '15 Minutes', value: '15mins' },
        { label: '30 Minutes', value: '30mins' },
        { label: '1 hour', value: '1hour' },
        { label: '1 day', value: '1day' },
    ],
    Monthly: [
        { label: '15 Minutes', value: '15mins' },
        { label: '30 Minutes', value: '30mins' },
        { label: '1 hour', value: '1hour' },
        { label: '1 day', value: '1day' },
        { label: '1 week', value: '7days' },
    ],
    Quarterly: [
        { label: '15 Minutes', value: '15mins' },
        { label: '30 Minutes', value: '30mins' },
        { label: '1 hour', value: '1hour' },
        { label: '1 day', value: '1day' },
        { label: '1 week', value: '7days' },
        { label: '1 month', value: '30days' },
    ],
    Halfyearly: [
        { label: '15 Minutes', value: '15mins' },
        { label: '30 Minutes', value: '30mins' },
        { label: '1 hour', value: '1hour' },
        { label: '1 day', value: '1day' },
        { label: '1 week', value: '7days' },
        { label: '1 month', value: '30days' },
    ],
    Yearly: [
        { label: '15 Minutes', value: '15mins' },
        { label: '30 Minutes', value: '30mins' },
        { label: '1 hour', value: '1hour' },
        { label: '1 day', value: '1day' },
        { label: '1 week', value: '7days' },
        { label: '1 month', value: '30days' },
    ],
    All: [
        { label: '15 Minutes', value: '15mins' },
        { label: '30 Minutes', value: '30mins' },
        { label: '1 hour', value: '1hour' },
        { label: '1 day', value: '1day' },
        { label: '1 week', value: '7days' },
        { label: '1 month', value: '30days' },
    ],
};

// const FormRow = ({ formData, formPackageName, room, index }) => {
// 	const [roomIdsInForm, setFormIdsInChatRoom] = useState(formData.chatroom);
// 	const [isLoading, setIsLoading] = useState(false);

// 	useEffect(() => {
// 		setFormIdsInChatRoom(formData.chatroom);
// 	}, [formData.chatroom]);
// 	const addFormToChatRoom = async (formId, chatroom) => {

// 	};
// 	const removeFormFromChatRoom = async (chatroom, formId) => {

// 	};

// 	const handleFormAddInChatRoom = () => {
// 	};
// 	const formRowCss = css``;
// 	return (
// 		<tr css={formRowCss} key={formData._id}>
// 			<td>{index}</td>
// 			<td>
// 				{formData.name}{' '}
// 				{formData.archived ? <span className="badge badge-pill badge-danger">Archived</span> : null}
// 			</td>
// 			<td>{formPackageName}</td>
// 			<td>
// 				{isLoading ? (
// 					<p>
// 						<VscLoading className="spin" />
// 					</p>
// 				) : roomIdsInForm?.includes(room.id) ? (
// 					<p onClick={() => removeFormFromChatRoom(room, formData._id)}>
// 						<span
// 							css={css`
// 								cursor: pointer;
// 								color: green;
// 								transform: scale(2);
// 							`}
// 						>
// 							Unassign
// 						</span>
// 					</p>
// 				) : (
// 					<p onClick={handleFormAddInChatRoom}>
// 						<span
// 							css={css`
// 								cursor: pointer;
// 								transform: scale(2);
// 							`}
// 						>
// 							Assign
// 						</span>
// 					</p>
// 				)}
// 			</td>
// 		</tr>
// 	);
// };

function PreviewEventModalForm({
    data,
    handleClose,
    editTheTask,
    editTaskStatus,
    startConference,
    setConferenceData,
    currUser,
}) {
    const { assignedChannel, recurringUntil } = data;
    const id = data._id || data.id;
    const teams = useSelector((state) => state.teams?.teams);
    const userId = useSelector((state) => state.user?.user?.id);
    const position = useSelector((state) => state.user.position);
    const assignedChannelInfo = Array.isArray(data?.assignedChannelInfo)
        ? data.assignedChannelInfo[0]
        : data?.assignedChannelInfo;
    const [selectedRoomOption, setSelectedRoomOption] = useState(
        assignedChannelInfo && {
            label: assignedChannelInfo?.title,
            value: assignedChannelInfo?._id,
        }
    );
    const [selectedEventTypeOption, setSelectedEventTypeOption] = useState(data?.eventType);

    const [loadingUsersData, setLoadingUsersData] = useState(true);
    const [currRoom, setCurrRoom] = useState({});
    const [team, setTeam] = useState({});

    const forms = data?.formsData || [];

    const [showRecurring, setShowRecurring] = useState(Boolean(data?.recurring) || false);

    // const [selectedUsers, setSelectedUsers] = useState([]);
    const selectedUsers = data?.assignedUserData || [];
    const [isShowing, setIsShowing] = useState(false);
    const [taskStatus, setTaskStatus] = useState(data?.status);
    const [userStartReminder, setUserStartReminder] = useState(data?.userStartReminders || []);
    const initialStartReminder = data?.userStartReminders || [];
    const [userEndReminder, setUserEndReminder] = useState(data?.userEndReminders || []);
    const initialEndReminder = data?.userEndReminders || [];

    const [selectedCourses, setSelectedCourses] = useState(
        data?.trainings?.map((course) => {
            return {
                label: course.title,
                value: course._id,
                index: course.index,
            };
        }) || []
    );

    const { register, control } = useForm({
        mode: 'onBlur',
    });

    useEffect(() => {
        const fetchUsersData = async () => {
            try {
                let chatroomId;
                if (selectedRoomOption && selectedRoomOption.value) {
                    chatroomId = selectedRoomOption.value;
                } else if (data) {
                    chatroomId = Array.isArray(data.assignedChannel)
                        ? data.assignedChannel[0]
                        : data.assignedChannel?.value || data.assignedChannel;
                }
                if (chatroomId) {
                    const chatroomData = await fetchWrapper.get(`/chatrooms/${chatroomId}`);
                    setCurrRoom(chatroomData);
                    const teamId = chatroomData.team;
                    const teamData = teams.find((team) => (team.id || team._id) === teamId);
                    setTeam(teamData);
                    // const chatroomUrl = chatroomData.type === 'public' ? `organizations/5f3569c2647fb5000c019e42/teams/${chatroomData.team}/members` : `/chatrooms/${chatroomId}/members`
                    // const chatroomsRes = await fetchWrapper.get(chatroomUrl);
                    // setUsersInRoom(chatroomsRes.results);

                    // const formsRes = await fetchWrapper.get(`forms?limit=200&chatroom=${chatroomId}`);
                    // const formOptions = formsRes?.results?.map((form) => ({
                    //     label: form.name,
                    //     value: form._id,
                    //     ...form,
                    // }));
                    // setForms((formOptions || []).sort(sortingOptionMethod));
                }
            } catch (err) {
                console.log(err);
            } finally {
                setLoadingUsersData(false);
            }
        };
        fetchUsersData();
    }, []);

    const [eventResponse, setEventResponse] = useState({});
    const [newEventResponse, setNewEventResponse] = useState();

    const fetchRes = async () => {
        try {
            const res = await fetchWrapper.get(`task/${id}`);
            setEventResponse(res);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (data?.title) {
            setEventResponse(data);
            setUserStartReminder((prev) => data?.userStartReminders || [...prev]);
            setUserEndReminder((prev) => data?.userEndReminders || [...prev]);
        } else if (id) {
            fetchRes();
        }
    }, [id, data]);

    useEffect(() => {
        if (eventResponse) {
            //date in [year, month, day, hour, minute] format
            const isoDate = eventResponse?.latestDatePointer;
            const date = new Date(isoDate);
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            const hour = date.getHours();
            const minute = date.getMinutes();
            const formattedDate = [year, month, day, hour, minute];

            //localtime zone
            // const timeZoneDiff = getTimeZoneDiff(); // assuming this returns '5:45'
            // const [hoursDiff, minutesDiff] = timeZoneDiff.split(':').map(Number);
            // const formattedDate = [year, month, day, hour + hoursDiff, minute + minutesDiff];

            //calculate duration
            const startDateString = eventResponse?.date;
            const endDateString = eventResponse?.dueDate;
            const startDate = new Date(startDateString);
            const endDate = new Date(endDateString);
            const durationInMs = endDate - startDate;
            const durationInMinutes = Math.floor(durationInMs / (1000 * 60));
            const hours = Math.floor(durationInMinutes / 60);
            const minutes = durationInMinutes % 60;
            const duration = { hours: hours, minutes: minutes };

            const newEventResponse = {
                start: formattedDate,
                duration: duration,
                title: eventResponse?.title,
                description: eventResponse?.description,
            };
            setNewEventResponse(newEventResponse);
        }
    }, [eventResponse]);

    const handleSave = () => {
        createEvent(newEventResponse, (error, value) => {
            const blob = new Blob([value], { type: 'text/plain;charset=utf-8' });
            saveAs(blob, 'event-schedule.ics');
        });
    };

    useEffect(() => {
        if (Boolean(data?.recurring)) {
            setShowRecurring(true);
        } else {
            setShowRecurring(false);
        }
    }, [data?.recurring]);

    // useEffect(() => {
    //     if (forms && data?.forms) {
    //         // const formIds = forms.map((form) => form._id || form.id || form);
    //         let newSelectedForms = forms?.filter((form) => data?.forms.includes(form?.value));
    //         setSelectedForm(newSelectedForms);
    //     }
    // }, [data?.forms, forms]);

    const handleUserStartReminderChange = (event) => {
        let reminder = event.target.value;
        let newUserStartReminder = [...userStartReminder];
        let reminderIndex = newUserStartReminder.indexOf(reminder);
        let permissionGiven = Boolean(reminderIndex >= 0);
        if (permissionGiven) {
            newUserStartReminder.splice(reminderIndex, 1);
        } else {
            newUserStartReminder.push(reminder);
        }
        setUserStartReminder(newUserStartReminder);
    };

    const handleUserEndReminderChange = (event) => {
        let reminder = event.target.value;
        let newUserEndReminder = [...userEndReminder];
        let reminderIndex = newUserEndReminder.indexOf(reminder);
        let permissionGiven = Boolean(reminderIndex >= 0);
        if (permissionGiven) {
            newUserEndReminder.splice(reminderIndex, 1);
        } else {
            newUserEndReminder.push(reminder);
        }
        setUserEndReminder(newUserEndReminder);
    };

    // const isUserSelected = useCallback(
    // 	(userId) => {
    // 		let isSelected = data?.assignedUser?.includes(userId);
    // 		if (isSelected) {
    // 			return true;
    // 		}
    // 		return;
    // 	},
    // 	[data?.assignedUser]
    // );

    // useEffect(() => {
    // 	let selectedUsers_let = usersInRoom?.filter((user) => isUserSelected(user._id));
    // 	setSelectedUsers(selectedUsers_let);
    // }, [usersInRoom, isUserSelected]);

    const checkIsShowing = (show) => {
        setIsShowing(show);
    };

    // const canSubmitForm = useMemo(() => {
    // 	let index = selectedUsers?.findIndex((user) => (user.id || user._id) === userId);
    // 	if (index < 0) {
    // 		return false;
    // 	}
    // 	return true;
    // }, [selectedUsers, userId]);

    const isAssigned = useMemo(() => {
        return Boolean(selectedUsers?.find((selectedUser) => selectedUser._id === userId));
    }, [selectedUsers, userId]);

    const isMeetingTime = useMemo(() => {
        const startTime = dayjs(data.date).format('HH:mm');
        const endTime = dayjs(data.dueDate).format('HH:mm');
        const currentTime = dayjs().format('HH:mm');
        const startNumber = Number(startTime.split(':').join(''));
        const endNumber = Number(endTime.split(':').join(''));
        const currenttNumber = Number(currentTime.split(':').join(''));
        if (currenttNumber >= startNumber && currenttNumber <= endNumber) {
            return true;
        } else {
            return false;
        }
    }, [data.date, data.dueDate]);

    const handleCall = async () => {
        try {
            let room;
            if (data?.assignedChannel?.length) {
                room = data?.assignedChannel[0];
                const response = await fetchWrapper.post('/conference/token', { room: room });
                setConferenceData(room, response.id, team?.id || team?._id);
                startConference();
            }
        } catch (e) {}
    };

    const generateTextWithLink = (data) => {
        const urlRegex =
            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
        let dataWithLink = data.replace(urlRegex, function (url) {
            return `<a href="${url}" target="_blank" style="{cursor:pointer;}">${url}</a>`;
        });
        return dataWithLink;
    };

    const handleStatusChange = async (id, status) => {
        const updatedTaskStatus = status === 'done' ? [...taskStatus, id] : taskStatus.filter((value) => value !== id);
        const isEdited = await editTaskStatus(data._id, { status: updatedTaskStatus }, status);
        isEdited && setTaskStatus(updatedTaskStatus);
    };

    const onRoomSelect = (selectedRoom) => {
        setSelectedRoomOption(selectedRoom);
    };

    const handleEditClick = async (id) => {
        const newTasks = {
            title: data?.title,
            date: data?.date,
            dueDate: data?.dueDate,
            status: data?.status,
            eventType: data?.eventType?._id || data?.eventType.id || data?.eventType,
            assignedChannel: [
                Array.isArray(assignedChannel) ? assignedChannel[0] : assignedChannel?._id || assignedChannel?.id,
            ],
            assignedUser: data?.assignedUser,
            userStartReminders: userStartReminder,
            userEndReminders: userEndReminder,
            recurring: data?.recurring,
        };
        const success = await editTheTask(id, newTasks);
        return success;
    };

    const handleEventChannelClick = (userId) => {
        const userSubChannel = userId && data.subChannel?.find(({ owner }) => owner === userId);
        if (userSubChannel) {
            const url = `/event-channel/${userSubChannel._id || userSubChannel.id}`;
            window.open(url, '_blank');
        }
    };

    const [recurringMessage, setRecurringMessage] = useState('');
    useEffect(() => {
        const startDate = new Date(data?.latestDatePointer || data?.date);
        const recurrenceData = data?.recurring ? getRecurrenceData(data.recurring, startDate) : {};

        if (recurrenceData?.num && recurrenceData?.unit) {
            const eventStartDateTime = startDate ? new Date(startDate) : new Date();
            const unitString =
                recurrenceData.num > 1
                    ? recurrenceData.num + ' ' + recurrenceData.unit
                    : recurrenceData.unit?.replace('s', '');
            let message = `The event will reoccur every ${unitString}`;
            if (recurrenceData.unit === 'weeks' || (recurrenceData.unit === 'months' && recurrenceData.week)) {
                message += ` on ${recurrenceData.unit === 'months' ? nthDay[recurrenceData.week] + ' ' : ''}${recurrenceData.days.map((x) => days[x]).join(', ')} at ${formatAMPM(eventStartDateTime)}`;
            } else {
                message += ` at ${recurrenceData.unit === 'days' ? formatAMPM(eventStartDateTime) : formatDate(eventStartDateTime, recurrenceData.unit !== 'months')}`;
            }
            setRecurringMessage(
                `${message}${recurringUntil ? ` until ${formatDate(new Date(recurringUntil))}` : ' forever'}`
            );
        }
    }, [data?.recurring, data?.recurringUntil]);

    const canSeeAllStatus =
        currUser.role === 'admin' ||
        currUser._id === (data.assignedBy?.id || data.assignedBy) ||
        currUser.teamList.some(
            (data) =>
                data.team?._id?.toString() === team?._id?.toString() && ['owner', 'teamManager'].includes(data.role)
        ) ||
        currUser.roomList.some(
            ({ room, role }) => room?._id?.toString() === currRoom?._id?.toString() && role === 'supervisor'
        );

    return (
        <div
            css={css`
                ${isShowing ? 'opacity: 0.3' : ''}
            `}
        >
            <Modal.Header
                className="font-weight-bold d-flex justify-content-center"
                css={css`
                    font-size: 22px;
                `}
            >
                View Event
                {data.eventPackage && (
                    <span
                        className="badge badge-warning"
                        style={{
                            position: 'absolute',
                            right: '6px',
                            top: '-16px',
                            padding: '6px 8px',
                            fontSize: '14px',
                        }}
                    >
                        Plan Event
                    </span>
                )}
            </Modal.Header>
            <Modal.Body style={{ height: '75vh', overflowY: 'scroll', overflowX: 'clip' }}>
                <FormViewAccordion
                    selectedForm={forms}
                    selectedUsers={selectedUsers}
                    subTasks={data.subTasks}
                    canSubmitForm={true}
                    userId={userId}
                    currUser={currUser}
                    currRoom={currRoom}
                    team={team}
                    position={position}
                    checkIsShowing={checkIsShowing}
                    data={data}
                    canSeeAllStatus={canSeeAllStatus}
                />
                <Form className="px-5">
                    <CustFormLabel>Event Type</CustFormLabel>

                    <ReactSelectComponent
                        selectedOption={selectedEventTypeOption}
                        setSelectedOption={setSelectedEventTypeOption}
                        eventType={data && data.eventType}
                        placeholder="Search EventType"
                        disabled={true}
                        mode={'view'}
                    />

                    <CustFormLabel>Name of Event</CustFormLabel>

                    <Form.Control
                        ref={register}
                        type="text"
                        name="title"
                        defaultValue={data && data.title}
                        disabled={true}
                    />
                    <Form.Text className="text-danger"></Form.Text>

                    {loadingUsersData ? (
                        <VscLoading
                            className="spin"
                            css={css`
                                height: 24px;
                                width: 24px;
                            `}
                        />
                    ) : (
                        <>
                            {selectedUsers?.length ? (
                                <>
                                    <CustFormLabel>Status</CustFormLabel>
                                    <div className="d-flex flex-column justify-content-start align-items-start ">
                                        {selectedUsers
                                            ?.filter((user) => user._id === currUser._id || canSeeAllStatus)
                                            ?.map((user) => (
                                                <div
                                                    key={user._id}
                                                    className="w-100"
                                                    css={css`
                                                        padding: 2px 0;
                                                        gap: 6px;
                                                        display: flex;
                                                        justify-content: space-between;
                                                        align-items: end;
                                                    `}
                                                >
                                                    <div
                                                        css={css`
                                                            display: flex;
                                                            flex-direction: row;
                                                            align-items: center;
                                                            gap: 1rem;
                                                        `}
                                                    >
                                                        {' '}
                                                        <AvatarUserPanel item={user} />
                                                        {user?.name}
                                                    </div>
                                                    <div
                                                        css={css`
                                                            display: flex;
                                                            gap: 4px;
                                                            width: 50%;
                                                            justify-content: space-between;
                                                        `}
                                                    >
                                                        <Form.Control
                                                            css={css`
                                                                font-size: 1.5rem !important;
                                                                width: fit-content;
                                                            `}
                                                            ref={register}
                                                            // disabled={true}
                                                            onChange={(e) =>
                                                                handleStatusChange(user._id, e.target.value)
                                                            }
                                                            as="select"
                                                            name="status"
                                                            disabled={!canSeeAllStatus && user._id !== currUser._id}
                                                            value={
                                                                taskStatus?.find((userId) => userId === user._id)
                                                                    ? 'done'
                                                                    : 'incomplete'
                                                            }
                                                        >
                                                            <option value="incomplete">Incompelete</option>
                                                            <option value="done">Complete</option>
                                                        </Form.Control>
                                                        <div
                                                            css={css`
                                                                display: flex;
                                                                flex-direction: column;
                                                                align-items: start;
                                                                gap: 0px;
                                                            `}
                                                        >
                                                            <ToolTip
                                                                text={`Chat with ${currUser?._id === user?._id ? data?.assignedBy?.name : user?.name}`}
                                                                cssStyles={
                                                                    'top:-85% !important; left: -20% !important; min-width: 200px !important;width:fit-content !important;'
                                                                }
                                                            >
                                                                <Button
                                                                    onClick={() => handleEventChannelClick(user?._id)}
                                                                    css={css`
                                                                        width: 200px;
                                                                        text-align: left;
                                                                    `}
                                                                >
                                                                    <BsChatDots
                                                                        className="icon"
                                                                        css={css`
                                                                            margin-right: 8px;
                                                                            margin-bottom: 4px;
                                                                        `}
                                                                    />
                                                                    Chat with{' '}
                                                                    {currUser?._id === user?._id ? 'Creator' : 'User'}
                                                                </Button>
                                                            </ToolTip>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </>
                            ) : null}
                        </>
                    )}

                    <CustFormLabel>Description</CustFormLabel>

                    {data && <div dangerouslySetInnerHTML={{ __html: generateTextWithLink(data.description) }} />}

                    <Form.Text className="text-danger"></Form.Text>

                    <CustFormLabel>Channel</CustFormLabel>
                    <div className="d-flex" style={{ width: '100%', gap: '5px' }}>
                        <div style={{ width: '100%' }}>
                            <ChannelSelectFilter
                                selectedOption={selectedRoomOption}
                                onRoomSelect={onRoomSelect}
                                assignedChannel={data && data.assignedChannel}
                                placeholder="Search channels"
                                disabled={true}
                            />
                        </div>
                        {data && data.assignedChannel && (
                            <ToolTip
                                text="Go to channel"
                                cssStyles={'top:-70% !important;left:-60% !important; min-width: 100px !important;'}
                            >
                                <a href={`/teams/${data?.assignedChannel[0]}`} target="_blank" rel="noreferrer">
                                    <BsArrowBarRight size={40} className="text-primary" />
                                </a>
                            </ToolTip>
                        )}
                    </div>

                    {data?.hasConference && (
                        <div className="mt-4">
                            {isAssigned && (
                                <Fragment>
                                    Conference:
                                    {isMeetingTime ? (
                                        <Button className="d-flex align-items-center" onClick={handleCall}>
                                            <MdCall className="mr-2" /> Join Conference
                                        </Button>
                                    ) : (
                                        <div className="alert alert-info d-flex align-items-center">
                                            <MdCall className="mr-2" /> Conference will begin at{' '}
                                            <b>{dayjs(data.date).format('hh:mm A')}</b>
                                        </div>
                                    )}
                                </Fragment>
                            )}
                        </div>
                    )}

                    <CustFormLabel>Event Mode</CustFormLabel>
                    <Form.Control
                        ref={register}
                        disabled={true}
                        as="select"
                        name="eventMode"
                        defaultValue={data ? data.eventMode : 'live'}
                        style={{ fontSize: '18px' }}
                    >
                        <option value="onDemand">On Demand</option>
                        <option value="live">Live</option>
                    </Form.Control>

                    <CustFormLabel>Event Start</CustFormLabel>

                    <Controller
                        name="date"
                        control={control}
                        defaultValue={(data && data.latestDatePointer) || data.date}
                        render={({ onChange, value }) => {
                            let date;
                            if (value) {
                                date = new Date(value);
                            } else {
                                date = new Date();
                            }
                            return (
                                <DateTimePicker
                                    onChange={onChange}
                                    value={date}
                                    ref={register}
                                    format="MM-dd-y hh:mm a"
                                    defaultValue={data && data.date}
                                    disabled={true}
                                    calendarType={'US'}
                                />
                            );
                        }}
                    />
                    <Form.Text className="text-danger"></Form.Text>

                    <CustFormLabel>Event End</CustFormLabel>
                    {data?.dueDate ? (
                        <Fragment>
                            <Controller
                                name="dueDate"
                                control={control}
                                disabled={true}
                                defaultValue={(data && data.latestDueDatePointer) || data.dueDate}
                                render={({ onChange, value }) => {
                                    let dueDate;
                                    if (value) {
                                        dueDate = new Date(value);
                                    } else {
                                        dueDate = new Date();
                                    }
                                    return (
                                        <DateTimePicker
                                            onChange={onChange}
                                            value={dueDate}
                                            format="MM-dd-y hh:mm a"
                                            ref={register}
                                            defaultValue={data && data.dueDate}
                                            disabled={true}
                                            calendarType={'US'}
                                        />
                                    );
                                }}
                            />
                            <Form.Text className="text-danger"></Form.Text>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <div className="text-muted font-italic small">No due date</div>
                        </Fragment>
                    )}

                    {showRecurring && data?.eventMode === 'live' ? (
                        <CusotmRecurrence
                            eventStartDate={new Date(data?.latestDatePointer || data?.date)}
                            recurrenceData={getRecurrenceData(
                                data?.recurring,
                                new Date(data?.latestDatePointer || data?.date)
                            )}
                            eventRecurringUntil={recurringUntil}
                            recurringMessage={recurringMessage}
                        />
                    ) : null}

                    {/* {showUserStartReminder ? ( */}
                    <Fragment>
                        <CustFormLabel>User Start Reminder </CustFormLabel>

                        <div className="my-2 d-flex">
                            {reminderSet['All'].map((rem) => {
                                return (
                                    <div key={rem.value} className="d-flex align-items-center">
                                        <input
                                            id={`userstart-${rem.value}`}
                                            type="checkbox"
                                            css={customCheckbox}
                                            onChange={handleUserStartReminderChange}
                                            value={rem.value}
                                            checked={userStartReminder.includes(rem.value)}
                                        />{' '}
                                        <label htmlFor={`userstart-${rem.value}`} className="mr-4">
                                            {rem.label}
                                        </label>
                                    </div>
                                );
                            })}
                        </div>
                        {userStartReminder?.length > 0 ? (
                            <Form.Text className="small font-italic text-muted">
                                We will remind you{' '}
                                {userStartReminder.map((reminder, idx) => {
                                    let reminderTxt = '';
                                    if (idx === 0) {
                                        reminderTxt = reminder;
                                    } else {
                                        reminderTxt += `, ${reminder}`;
                                    }
                                    return reminderTxt;
                                })}{' '}
                                before the event starts.
                            </Form.Text>
                        ) : null}

                        <Form.Text className="text-danger"></Form.Text>
                    </Fragment>

                    <Fragment>
                        <CustFormLabel>User End Reminder </CustFormLabel>
                        <div className="my-2 d-flex ">
                            {reminderSet['All'].map((rem) => {
                                return (
                                    <div key={rem.value} className="d-flex align-items-center">
                                        <input
                                            id={`userend-${rem.value}`}
                                            type="checkbox"
                                            css={customCheckbox}
                                            onChange={handleUserEndReminderChange}
                                            value={rem.value}
                                            checked={userEndReminder.includes(rem.value)}
                                        />{' '}
                                        <label htmlFor={`userend-${rem.value}`} className="mr-4">
                                            {rem.label}
                                        </label>
                                    </div>
                                );
                            })}
                        </div>

                        {userEndReminder?.length > 0 ? (
                            <Form.Text className="small font-italic text-muted">
                                We will remind you{' '}
                                {userEndReminder.map((reminder, idx) => {
                                    let reminderTxt = '';
                                    if (idx === 0) {
                                        reminderTxt = reminder;
                                    } else {
                                        reminderTxt += `, ${reminder}`;
                                    }
                                    return reminderTxt;
                                })}{' '}
                                before the event ends.
                            </Form.Text>
                        ) : null}

                        <Form.Text className="text-danger"></Form.Text>
                    </Fragment>

                    {(userStartReminder.length !== initialStartReminder.length ||
                        userEndReminder.length !== initialEndReminder.length ||
                        userStartReminder.some((item) => !initialStartReminder.includes(item)) ||
                        userEndReminder.length !== initialEndReminder.length ||
                        userEndReminder.some((item) => !initialEndReminder.includes(item))) && (
                        <div className="d-flex w-100 justify-content-end">
                            <Button onClick={async () => await handleEditClick(data?._id)}> Edit Reminder </Button>
                        </div>
                    )}
                    {data?.trainings?.length ? (
                        <div>
                            {selectedCourses?.length && (
                                <Fragment>
                                    <CustFormLabel className="text-left text-bold">Courses</CustFormLabel>
                                    <Select
                                        className="basic-single my-2"
                                        classNamePrefix="select"
                                        name="trainings"
                                        value={selectedCourses}
                                        placeholder="Select Courses"
                                        isMulti
                                        isDisabled={true}
                                    />
                                </Fragment>
                            )}
                        </div>
                    ) : null}

                    <Form.Text className="text-danger"></Form.Text>
                    <div className="d-flex justify-content-between align-items-center">
                        {data.assignedBy ? (
                            <div className="d-flex align-items-center mt-4">
                                <div className="font-weight-bold mr-1">Created by: </div>
                                <div>{data.assignedBy.name}</div>
                            </div>
                        ) : null}
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleSave}>Download</Button>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </div>
    );
}
const mapStateToProps = (state) => ({
    tasks: state.tasks,
    currUser: state.user.user,
});
export default connect(mapStateToProps, { editTheTask, editTaskStatus, startConference, setConferenceData })(
    PreviewEventModalForm
);
