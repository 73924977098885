import { getUniqueObjects } from '../../_helpers';
import * as ActionTypes from '../actions/ActionTypes';

export default function Rooms(
	state = {
		isLoading: true,
		errorMessage: null,
		roomsRef: {},
		channelMembers: [],
		teamRooms: {},
		clientRooms: {},
		isAddingRoom: true,
	},
	action
) {
	let newTempRooms;
	switch (action.type) {
		case ActionTypes.SET_ROOMS:
			// reduce {team, ...}[] to {team: {...}[]]}
			const setTeamRooms = action.payload.rooms.reduce((accumulator, current) => {
				const rooms = [...(accumulator[current.team]?.rooms || []).filter((room) => (room.id || room._id) !== (current.id || current._id)), current]
				accumulator[current.team] = { ...accumulator[current.team], rooms, Loaded: true, errorMessage: null }
				return accumulator;
			}, state.teamRooms);
			return { ...state, isLoading: true, errorMessage: null, teamRooms: setTeamRooms, totalRooms: action.payload.totalRooms };
		case ActionTypes.ADD_ROOMS:
			// TODO: Uncomment this code after making sure that the updated data on chatrooms (when performing CRUD) is fetched via websockets
			// or even better if this action is never dispatched unless necessary
			// if (Object.keys(state.teamRooms).includes(action.payload.teamId)) {
			// 	return { ...state, isLoading: true, errorMessage: null };
			// }
			const addTeamRooms = {
				...state.teamRooms,
				...{
					[action.payload.teamId]: {
						rooms: getUniqueObjects(state.teamRooms[action.payload.teamId]?.rooms || [], action.payload.rooms),
						Loaded: true,
						errorMessage: null,
						totalRooms: action.payload.totalRooms
					},
				},
			};
			return {
				...state,
				isLoading: true,
				errorMessage: null,
				teamRooms: addTeamRooms,
				isAddingRoom: false
			};
		case ActionTypes.ADD_CLIENT_ROOMS:
			let newClientRooms = Object.assign({}, state.clientRooms);
			newClientRooms = { ...newClientRooms, ...action.payload };
			return {
				...state,
				clientRooms: newClientRooms,
			};
		case ActionTypes.NOT_SEEN_ROOM:
			const newRefTempRoom = Object.assign([], state.teamRooms);

			Object.keys(newRefTempRoom).forEach((teamId) => {
				const roomIdx = newRefTempRoom[teamId].rooms.findIndex((room) => room.id === action.payload);
				if (roomIdx > -1) {
					newRefTempRoom[teamId].rooms[roomIdx].isSeen = false;
					return;
				}
			});
			return {
				...state,
				teamRooms: newRefTempRoom,
			};
		case ActionTypes.HAS_SEEN_ROOM:
			const newRefTempRoom2 = Object.assign([], state.teamRooms);
			if (newRefTempRoom2[action.payload.teamId]) {
				const roomIdx = newRefTempRoom2[action.payload.teamId].rooms.findIndex(
					(room) => room.id === action.payload.roomId
				);
				if (roomIdx > -1) {
					newRefTempRoom2[action.payload.teamId].rooms[roomIdx].isSeen = true;
				}
			}
			return {
				...state,
				teamRooms: newRefTempRoom2,
			};
		case ActionTypes.SET_NEW_ROOM:
			const setNewTeamRooms = {
				...state.teamRooms,
				...(action?.payload?.team && {
					[action?.payload?.team]: {
						rooms: [...state?.teamRooms[action?.payload?.team]?.rooms || [], action?.payload],
						Loaded: true,
						errorMessage: null,
						totalRooms: state.teamRooms[action?.payload?.team]?.totalRooms || 0 + 1
					}
				}),
			};

			return {
				...state,
				isLoading: true,
				errorMessage: null,
				teamRooms: setNewTeamRooms,
			};
		case ActionTypes.REMOVE_CHANNEL:
			const delRoom = Object.values(state.teamRooms)
				.map((teamRooms) => teamRooms.rooms)
				.flat()
				.find((room) => room._id === action.payload);

			const delNewTeamRooms = delRoom
				? {
					...state.teamRooms,
					...{
						[delRoom.team]: {
							rooms: state.teamRooms[delRoom.team]?.rooms?.filter(
								(room) => room._id !== action.payload
							),
							Loaded: true,
							errorMessage: null,
						},
					},
				}
				: { ...state.teamRooms };

			return {
				...state,
				isLoading: true,
				errorMessage: null,
				teamRooms: delNewTeamRooms,
			};
		case ActionTypes.EDIT_CHANNEL:
			const editRoom = Object.values(state.teamRooms)
				.map((teamRooms) => teamRooms?.rooms)
				.flat()
				.find((room) => room?._id === action.payload?._id);


			const editNewTeamRooms = editRoom
				? {
					...state.teamRooms,
					...{
						[editRoom.team]: {
							rooms: state.teamRooms[editRoom.team]?.rooms?.map((room) =>
								room?._id !== action.payload?._id ? room : action.payload
							),
							Loaded: true,
							errorMessage: null,
						},
					},
				}
				: { ...state.teamRooms };

			return {
				...state,
				isLoading: true,
				errorMessage: null,
				teamRooms: editNewTeamRooms,
			};

		case ActionTypes.SET_CHANNEL_MEMBER:
			return { ...state, channelMembers: [...action.payload.results] };
		// case ActionTypes.UPDATE_CHANNEL_MEMBER:
		// 	return {
		// 		...state, channelMembers: [...action.payload.results]
		// 	}
		case ActionTypes.SET_NEW_CHANNEL_MEMBER:
			return { ...state, channelMembers: [...action.payload] };
		case ActionTypes.REMOVE_CHANNEL_MEMBER:
			const tempMembers = [...state.channelMembers];
			const memberIndex = tempMembers.findIndex((member) => member._id === action.payload);
			tempMembers.splice(memberIndex, 1);
			return { ...state, channelMembers: tempMembers };
		case ActionTypes.UPDATE_CHANNEL_NOTIFICATION:
			//copy of teamRooms
			newTempRooms = Object.assign([], state.teamRooms);
			// get change
			if (newTempRooms[action.payload.teamId]) {
				const roomIndex3 = newTempRooms[action.payload.teamId].rooms.findIndex(
					(room) => room.id === action.payload.roomId
				);

				if (roomIndex3 !== -1) {
					let newRoom = newTempRooms[action.payload.teamId].rooms[roomIndex3];
					newRoom.notification = action.payload.notificationConfig;
					newTempRooms[action.payload.teamId].rooms.splice(roomIndex3, 1, newRoom);
					return {
						...state,
						// rooms: [...newTempRooms[action.payload.teamId].rooms, ...state.rooms],
						isLoading: true,
						errorMessage: null,
						teamRooms: newTempRooms,
					};
				}
			}

			return state;
		case ActionTypes.SET_NEW_ROOM_REF:
			return {
				...state,
				roomsRef: {
					...state.roomsRef,
					[action.payload.roomId]: action.payload.ref,
				},
			};
		case ActionTypes.SET_IS_ADDING_ROOM:
			return {
				...state,
				isAddingRoom: action.payload || false
			};
		default:
			return state;
	}
}
