import { css } from 'twin.macro';
import { Fragment } from 'react';
import { Card } from 'react-bootstrap';
import FormElement from './FormElement';


const StaticPreview = ({ formElements, elementRef, isPreview, errors, onlyView }) => {
	return (
	
				<Card
							className="p-2"
							css={css`
								min-height: 150px;
								height: fit-content;
							`}
						>
							
							<Fragment>
								{formElements.length > 0 &&
									formElements.map((form) => (
										<div
											key={form._id}
											className={`form-element `}
											css={css`
												margin-bottom: 5px;
												padding: 1rem 0.5rem 1rem 1rem;
												overflow-wrap: break-word;
											`}
										>
											<FormElement
												form={form}
												elementRef={elementRef}
												isPreview={isPreview || onlyView}
												errors={errors}
											/>
										</div>
									))}
							</Fragment>
						</Card>

			
		
	);
};

export default StaticPreview;
