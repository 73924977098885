import { css } from 'twin.macro';
import { useState, Fragment, useEffect } from 'react';
import { fetchWrapper, createEventTypeValidation, getUniqueObjects } from '../../../_helpers';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { Button, Modal, Form, Row, Col, Table } from 'react-bootstrap';
// import { FiEdit } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { VscLoading } from 'react-icons/vsc';
//components
import { CreateEventStyle } from './Styles/EventModalStyles';
import { ActionStyles } from '../reusableStyles';
import DeletePopUp from '../../../_elements/DeletePopUp';
import Pagination from '../Pagination';
import { ToolTip } from '../../../_elements/ToolTip';
import { BsPencil } from 'react-icons/bs';

function EventTypeModal({ }) {
	const [show, setShow] = useState(false);
	const [eventTypes, setEventTypes] = useState([]);
	const [createOrEditData, setCreateOrEditData] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [totalEventTypes, setTotalEventTypes] = useState(11);
	const [currentPage, setCurrentPage] = useState(1);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const getEventTypes = async () => {
			try {
				setIsLoading(true);
				let response = await fetchWrapper.get(`/eventType?limit=10&page=${currentPage}`);
				setEventTypes(response.results);
				setTotalEventTypes(response.totalResults);
				setIsLoading(false);
			} catch (error) {
				console.log(error);
			}
		};
		getEventTypes();
	}, [currentPage]);

	const onPageChanged = ({ currentPage }) => {
		if (currentPage < 1) {
			currentPage = 1;
		}
		setCurrentPage(currentPage);
	};

	const handleClose = () => setShow(false);
	const handleShow = () => {
		setShow(true);
		setIsSubmitting(false);
		setCreateOrEditData(false);
	};

	const { register, handleSubmit, errors } = useForm({
		mode: 'onBlur',
		resolver: yupResolver(createEventTypeValidation),
	});
	const onSubmit = (id) => async (data) => {
		const url = `/eventType/${id || ''}`;
		const method = id ? 'put' : 'post';
		if (isSubmitting) {
			return;
		}
		try {
			setIsSubmitting(true);
			const maxFileSize = 2 * 1024 * 1024;
			let imageFile = data.image[0];
			if (!data.title || !data.description) {
				return toast.warn('Title & Description is required');
			}
			const formData = new FormData();
			formData.append('title', data.title);
			formData.append('description', data.description);
			if (imageFile) {
				if (imageFile.size >= maxFileSize) {
					return toast.warn('Image must be less than 2mb');
				}
				formData.append('image', imageFile);
			}
			let res = await fetchWrapper[method](url, formData, 'formData');
			setEventTypes(getUniqueObjects([...eventTypes, res]));
			toast.success(`Event Type ${method === "post" ? "Created" : "Edited"} Succesfully`);
			setCreateOrEditData(false);
		} catch (error) {
			toast.error(error.message || "Something went Wrong")
		} finally {
			setIsSubmitting(false);
		}
	};

	const handleDeleteEventType = async (id) => {
		try {
			await fetchWrapper.delete(`/eventType/${id}`);
			let newEventTypes = Object.assign([], eventTypes);
			newEventTypes = newEventTypes.filter((eventTypes) => eventTypes.id !== id);
			setEventTypes(newEventTypes);
			toast.success('Event Type deleted Successfully!');
		} catch (err) {
			console.log(err);
			toast.error('Event Type failed to delete!');
		}
	};

	return (
		<Fragment>
			<Button variant="primary" className="mr-4" onClick={handleShow}>
				Event Types
			</Button>

			<CreateEventStyle size="lg" centered show={show} onHide={handleClose}>
				<div
					css={css`
						background-color: #3b4b64;
						color: #fff;
						text-align: center;
						padding: 2rem 0;
						font-weight: bold;
						font-size: 2rem;
					`}
				>
					<p className="mb-0">{createOrEditData?.id ? "Edit Event Type" : createOrEditData ? "Create Event Type" : "Event Types"}</p>
				</div>
				<Modal.Body>
					{createOrEditData ? (
						<Fragment>
							<div>
								<Button
									variant="secondary"
									className={`px-5 ${isSubmitting ? 'disableBtn' : ''}`}
									onClick={() => setCreateOrEditData(false)}
								>
									Back
								</Button>
							</div>
							<Form className="p-5" onSubmit={handleSubmit(onSubmit(createOrEditData?.id))}>
								<Form.Row>
									<Form.Label className="text-right" column sm="3">
										Title
									</Form.Label>
									<Col sm="8">
										<Form.Control
											ref={register}
											type="text"
											name="title"
											defaultValue={createOrEditData?.title}
										/>
										<Form.Text className="text-danger">{errors.title?.message}</Form.Text>
									</Col>
								</Form.Row>
								<Form.Row>
									<Form.Label className="text-right" column sm="3">
										Description
									</Form.Label>
									<Col sm="8">
										<Form.Control
											ref={register}
											as="textarea"
											name="description"
											defaultValue={createOrEditData?.description}
										/>
										<Form.Text className="text-danger">{errors.description?.message}</Form.Text>
									</Col>
								</Form.Row>

								<Form.Row>
									<Form.Label className="text-right" column sm="3">
										Icon
									</Form.Label>
									<img src={createOrEditData?.image} width={48} height={48} css={css`display: inline; border-radius: 24px;`} />
									<Col sm="5">
										<input
											css={css`
												width: 100%;
											`}
											type="file"
											ref={register}
											name="image"
										/>
									</Col>
								</Form.Row>

								<Row className="my-5">
									<Col
										sm="3"
										css={css`
											margin-right: 3rem;
										`}
									></Col>
									<Col sm="8" className="d-flex justify-content-between">
										<Button
											variant="secondary"
											style={{ fontSize: '2rem', fontWeight: '400' }}
											className="px-5 "
											onClick={handleClose}
										>
											Cancel
										</Button>
										<Button
											variant="primary"
											className={`px-5 ${isSubmitting ? 'disableBtn' : ''}`}
											type="submit"
										>
											{createOrEditData?.id ? 'Edit' : 'Create'}
										</Button>
									</Col>
								</Row>
							</Form>
						</Fragment>
					) : (
						<Fragment>
							<Button
								variant="primary"
								className={`px-5 py-3 mb-2 ${isSubmitting ? 'disableBtn' : ''}`}
								onClick={() => setCreateOrEditData(true)}
							>
								Create Event Type
							</Button>
							<Table>
								<thead>
									<tr>
										<th>S.N</th>
										<th>Event Name</th>
										<th>Event Description</th>
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>
									{isLoading ? (
										<VscLoading className="spin" />
									) : (
										eventTypes &&
										eventTypes.map((eventType, idx) => {
											return (
												<tr key={eventType.id}>
													<td>{(currentPage - 1) * 10 + (idx + 1)}</td>
													<td>
														<img src={eventType?.image} width={36} height={36} css={css`display: inline; border-radius: 18px; margin: 0 3px 0 0;`} />
														{eventType.title}
													</td>
													<td>{eventType.description}</td>
													<td className="action">
														<ActionStyles className="mt-2 d-flex justify-content-start">
															<ToolTip
																text={'Edit'}
																side={'top'}
																cssStyles={
																	'top:-70% !important; min-width: fit-content !important'
																}
															>
																<BsPencil className="icon edit" onClick={() => setCreateOrEditData(eventType)} />
															</ToolTip>
															<DeletePopUp
																handleDelete={handleDeleteEventType}
																title={eventType.title}
																id={eventType.id}
																type={eventType.title}
															/>
														</ActionStyles>
													</td>
												</tr>
											);
										})
									)}
								</tbody>
							</Table>
							<div className="p-4">
								<Pagination
									totalRecords={totalEventTypes}
									key={totalEventTypes}
									pageLimit={10}
									pageNeighbours={1}
									onPageChanged={onPageChanged}
								/>
							</div>
						</Fragment>
					)}
				</Modal.Body>
			</CreateEventStyle>
		</Fragment>
	);
}

export default EventTypeModal;
