import { styled } from 'twin.macro';

export const CustFormLabel = styled.div`
	font-size: 16px;
	color:black;
	margin:15px 0px 2.5px 0px;
	font-weight:bold;
	text-align:left;
`;

export const formLabel = {
	fontSize: '1.5rem',
};
