import * as ActionTypes from './ActionTypes';

export const setInvitationForConference = (conference) => ({
	type: ActionTypes.SET_CONFERENCE_CALL_INVITATION,
	payload: conference,
});

export const clearInvitationForConference = (conference) => ({
	type: ActionTypes.CLEAR_CONFERENCE_CALL_INVITATION,
});

export const setConferenceData = (roomId, conferenceId, teamId) => ({
	type: ActionTypes.SET_CONFERENCE_DATA,
	payload: {
		roomId,
		conferenceId,
		teamId,
	},
});

export const startConference = () => ({
	type: ActionTypes.START_CONFERENCE,
});
