import { css } from 'twin.macro';
import { useState, useEffect, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import AddPeople from './AddPeople';
import { CSSTransition } from 'react-transition-group';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { getUserData } from '../../../_redux/actions/ActionUser';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
    getTeamMember,
    getChannelMembers,
    deleteUserFromChannel,
    deleteChannelById,
    removeUserChannel,
    removeChannel,
    removeTeam,
    removeUserTeam,
} from '../../../_redux/actions/ActionOrganization';
import { getRole } from '../../../_helpers/roles/get-roles';
//icons
import { RiCloseCircleFill } from 'react-icons/ri';
import { IoExitOutline } from 'react-icons/io5';
import Swal from 'sweetalert2';

// helpers
import { checkPermission, fetchWrapper, getRoomIdFromURL, Permission } from '../../../_helpers';

//components
import { useOutsideClickListener } from '../../../_helpers/hooks/useOutsideClickListener';
import Forms from './Forms';
import Files from './Files';
import Medias from './Medias';
import AvatarUserPanel from '../Common/AvatarUserPanel';
import { actions, detailContainer, detailOptions, MemberOverflow } from './styles/TeamDetailsStyles';
import { FaEdit, FaTrashAlt, FaUserPlus } from 'react-icons/fa';
import ChannelSetting from './ChannelSetting';
import ChannelNotificationsSetting from './ChannelNotificationsSetting';
import EditChannel from './EditChannel';

//icons
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import Members from './components/Members';

const TeamDetails = ({
    room,
    teamMembers,
    rooms,
    channelMembers,
    user,
    userId,
    setOpenDetails,
    teams,
    deleteUserFromChannel,
    deleteChannelById,
    getUserData,
    canDownload,
    removeChannel,
    removeUserChannel,
    removeTeam,
    removeUserTeam,
}) => {
    const [showMember, setShowMember] = useState(false);
    // const [showOrganization, setShowOrganization] = useState(true);
    const currRoom = rooms?.find((r) => r.id === room.id) || room;
    const team = teams.find((team) => room.team === team.id) || {};
    const history = useHistory();
    const [channelRole, setChannelRole] = useState(null);
    const [isBusy, setIsBusy] = useState(false);
    const DetailsRef = useRef(null);

    const showLeaveTeam = (user?.teamList || [])?.find((item) => item?.team?._id === team?._id);
    const showLeaveChannel = showLeaveTeam && (user?.roomList || [])?.find((item) => item?.room?._id === room?.id);

    useEffect(() => {
        const thisRoom = user?.roomList?.filter((item) => item?.room?.id === room.id);
        const role = thisRoom.length > 0 ? thisRoom[0].role : null;

        setChannelRole(role);
    }, [room?.id, user?.roomList]);

    const deleteChannelMemberById = (userId, name) => {
        if (window.confirm(`Are you sure you want to remove "${name}"?`)) {
            deleteUserFromChannel(currRoom.id, userId);
        }
    };

    const deleteChannel = async () => {
        if (window.confirm(`Are you sure you want to delete "${currRoom.title}" Channal?`)) {
            try {
                await deleteChannelById(currRoom.id, history);
                history.push('/teams');
                getUserData();
            } catch (e) {
                toast.error(e.error);
            }
        }
    };

    useOutsideClickListener(DetailsRef, () => {
        setOpenDetails(false);
    });

    const handleLeaveChannel = async () => {
        try {
            setIsBusy(true);
            const result = await Swal.fire({
                title: `Are you sure you want to leave ${room?.title} channel?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, I want to leave!',
            });
            if (result.isConfirmed) {
                await fetchWrapper.delete(`chatrooms/${room?.id}/leave`);
                toast.success('You have successfully left channel');
                removeChannel(room?._id);
                removeUserChannel(room?._id);
                if (getRoomIdFromURL() === room?._id) {
                    history.push('/teams');
                }
            }
        } catch (err) {
            console.log('err', err);
            toast.error(err?.message);
        } finally {
            setIsBusy(false);
        }
    };

    const handleLeaveTeam = async () => {
        try {
            setIsBusy(true);
            const result = await Swal.fire({
                title: `Are you sure you want to leave ${team?.name} team?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, I want to leave!',
            });
            if (result.isConfirmed) {
                await fetchWrapper.delete(`organizations/5f3569c2647fb5000c019e42/teams/${team?._id}/leave`);
                toast.success('You have successfully left team');
                removeTeam(team?._id);
                removeUserTeam(team?._id);
                const currentChannelId = getRoomIdFromURL();
                const teamOfCurrentChannel = (user?.roomList || [])?.find(
                    (item) => item?.room?._id === currentChannelId
                )?.room?.team;
                if (teamOfCurrentChannel && teamOfCurrentChannel === team?._id) {
                    history.replace('/teams');
                }
            }
        } catch (err) {
            console.log('err', err);
            toast.error(err?.message);
        } finally {
            setIsBusy(false);
        }
    };

    return (
        <div css={detailContainer} ref={DetailsRef}>
            <div className="about">
                <span
                    className="channel-title d-flex"
                    css={css`
                        width: 95%;
                        .avatar-img,
                        .rounded-circle {
                            min-width: 3.5rem;
                        }
                    `}
                >
                    {team?.id ? <AvatarUserPanel item={team} /> : null}
                    <div className="ml-4">
                        <div className="h3 font-weight-bold">{currRoom && `#${currRoom.title} `}</div>
                        <div
                            className="text-muted"
                            css={css`
                                word-break: break-all;
                            `}
                        >
                            <strong>Description:</strong> {currRoom.subTitle}
                        </div>
                        <div className="text-muted wrap">
                            <strong>Channel type:</strong> <span className="text-capitalize">{currRoom.type}</span>
                        </div>
                        <div className="text-muted wrap">
                            <strong>Team:</strong> {team.name}
                        </div>
                        <div className="text-muted wrap">
                            <strong>Channel created on:</strong> {new Date(room.createdAt).toLocaleDateString()}
                        </div>
                        <br />
                    </div>
                </span>
                <RiCloseCircleFill className="text-primary pointer" size={24} onClick={() => setOpenDetails(false)} />
            </div>
            <hr />
            <div className="d-flex justify-content-around">
                {currRoom ? (
                    <Fragment>
                        {currRoom.type === 'private' || currRoom.type === 'gps_enabled' ? (
                            <Permission
                                role={getRole(user, team)}
                                perform="invite:user"
                                data={{ userId, teamAdmin: team.admin }}
                                yes={() => (
                                    <AddPeople role={getRole(user, team)} teamId={currRoom.team} roomId={currRoom.id}>
                                        <div css={actions}>
                                            <FaUserPlus />
                                            <span>Add</span>
                                        </div>
                                    </AddPeople>
                                )}
                                no={() => (
                                    <Fragment>
                                        {channelRole && checkPermission(channelRole, 'invite:user') ? (
                                            <AddPeople
                                                role={getRole(user, team)}
                                                teamId={currRoom.team}
                                                roomId={currRoom.id}
                                            >
                                                <div css={actions}>
                                                    <FaUserPlus />
                                                    <span>Add</span>
                                                </div>
                                            </AddPeople>
                                        ) : (
                                            <div className="disabled" css={actions}>
                                                <FaUserPlus />
                                                <span>Add</span>
                                            </div>
                                        )}
                                    </Fragment>
                                )}
                            />
                        ) : (
                            <div className="disabled" css={actions}>
                                <FaUserPlus />
                                <span>Add</span>
                            </div>
                        )}
                        <ChannelNotificationsSetting teamId={team.id} room={currRoom} />
                        <Permission
                            role={getRole(user, team)}
                            perform="update:channal"
                            data={{ userId, teamAdmin: team.admin, userRole: user.role }}
                            yes={() => <ChannelSetting teamId={team.id} room={currRoom} team={team} />}
                            no={() => {
                                if (checkPermission(channelRole, 'update:channal')) {
                                    return <ChannelSetting teamId={team.id} room={currRoom} team={team} />;
                                }
                                return <ChannelSetting teamId={team.id} room={currRoom} team={team} disabled={true} />;
                            }}
                        />
                        <Permission
                            role={getRole(user, team)}
                            perform="update:channal"
                            data={{ userId, teamAdmin: team.admin, userRole: user.role }}
                            yes={() => <EditChannel teamId={team.id} room={currRoom} team={team} />}
                            no={() => {
                                if (checkPermission(channelRole, 'update:channal') && team?.id && currRoom?._id) {
                                    return <EditChannel teamId={team.id} room={currRoom} team={team} />;
                                }
                                return (
                                    <div className="disabled" css={actions}>
                                        <FaEdit />
                                        <span>Edit</span>
                                    </div>
                                );
                            }}
                        />
                        <Permission
                            role={getRole(user, team)}
                            perform="remove:channel"
                            data={{ userId, teamAdmin: team.admin, userRole: user.role }}
                            yes={() => (
                                <div css={actions} onClick={() => deleteChannel()}>
                                    <FaTrashAlt />
                                    <span>Delete</span>
                                </div>
                            )}
                            no={() => (
                                <div className="disabled" css={actions}>
                                    <FaTrashAlt />
                                    <span>Delete</span>
                                </div>
                            )}
                        />
                    </Fragment>
                ) : (
                    <Fragment>
                        <div css={actions}>
                            <FaUserPlus />
                            <span>Add</span>
                        </div>
                        <div css={actions}>
                            <FaEdit />
                            <span>Edit</span>
                        </div>
                    </Fragment>
                )}

                {/* <div className="text-center disabled">
							<i className="fas fa-ellipsis-h" style={{ fontSize: '24px', display: 'block' }}></i>
							<span className="small font-weight-bold">More</span>
						</div> */}
            </div>
            <hr />
            <div css={detailOptions}>
                <div
                    className="options"
                    css={css`
                        background-color: ${showMember ? `#E0F0EF` : `#F2F8F7`};
                    `}
                    onClick={() => setShowMember((prev) => !prev)}
                >
                    <span className=" font-weight-bold m-0">Members</span>
                    <p className=" ml-auto unselect">
                        <CSSTransition in={showMember} timeout={500} classNames="arrow">
                            {!showMember ? <TiArrowSortedUp /> : <TiArrowSortedDown />}
                        </CSSTransition>
                    </p>
                </div>

                {showMember && currRoom && team.id && (
                    <Members
                        currRoom={currRoom}
                        channelMembers={channelMembers}
                        MemberOverflow={MemberOverflow}
                        team={team}
                        teamMembers={teamMembers}
                        user={user}
                        userId={userId}
                    />
                )}
            </div>

            <div css={detailOptions}>
                <Forms currRoom={currRoom} team={team} />
            </div>

            <div css={detailOptions}>
                <Files currRoom={currRoom} team={team} canDownload={canDownload} />
            </div>

            <div css={detailOptions}>
                <Medias currRoom={currRoom} team={team} />
            </div>

            {!showLeaveChannel ? null : (
                <div
                    css={css`
                        padding: 0 1.5rem;
                        display: flex;
                        justify-content: center;
                    `}
                >
                    <Button
                        className="btn btn-danger"
                        css={css`
                            background: white !important;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 1rem;
                            width: 100%;
                            color: red !important;
                        `}
                        onClick={() => handleLeaveChannel()}
                        disabled={isBusy}
                    >
                        <IoExitOutline />
                        Leave Channel
                    </Button>
                </div>
            )}

            {!showLeaveTeam ? null : (
                <div
                    css={css`
                        padding: 0 1.5rem;
                        display: flex;
                        justify-content: center;
                        margin: 12px 0;
                    `}
                >
                    <Button
                        className="btn btn-danger"
                        css={css`
                            background: white !important;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 1rem;
                            width: 100%;
                            color: red !important;
                        `}
                        onClick={() => handleLeaveTeam()}
                        disabled={isBusy}
                    >
                        <IoExitOutline />
                        Leave Team
                    </Button>
                </div>
            )}
        </div>
    );
};
const mapStateToMember = (state) => ({
    // rooms: state.rooms.teamRooms,
    user: state.user.user,
    teams: state.teams.teams,
    role: state.user.user.role,
    userId: state.user.user.id,
    teamMembers: state.teams.teamMembers,
    channelMembers: state.rooms.channelMembers,
    organizations: state.organizations.organizations,
});

TeamDetails.propTypes = {
    room: PropTypes.object.isRequired,
};

export default connect(mapStateToMember, {
    getTeamMember,
    getChannelMembers,
    deleteUserFromChannel,
    deleteChannelById,
    getUserData,
    removeChannel,
    removeUserChannel,
    removeTeam,
    removeUserTeam,
})(TeamDetails);
