import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { css } from 'twin.macro';
import { fetchWrapper } from '../../../../../_helpers';

const ZealReimbursement = ({ companyID, payrollMetadata, teamId }) => {
    const [isBusy, setIsBusy] = useState(false);
    const [rate, setRate] = useState(payrollMetadata?.mileageReimbursementRate || 0);

    const handleSubmit = async () => {
        try {
            setIsBusy(true);
            await fetchWrapper.post(
                `evv/payroll/zeal/provider/${companyID}/mileage-rate${teamId ? `?team=${teamId}` : ''}`,
                {
                    rate,
                }
            );

            toast.success('Successfully added reimbursement amount');
        } catch (err) {
            console.log('err', err);
            toast.error(err?.message);
        } finally {
            setIsBusy(false);
        }
    };
    return (
        <div
            css={css`
                margin-top: 2rem;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            `}
        >
            <div>
                <span className="mr-4">Mileage Reimbursement amount: </span>
                <span
                    className="mr-2"
                    css={css`
                        font-size: 2rem;
                    `}
                >
                    $
                </span>
                <input
                    type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    value={rate}
                    onChange={(e) => setRate(e.target.value)}
                    css={css`
                        padding: 4px;
                    `}
                />
                <span className="ml-2">/ mile.</span>
            </div>
            <div>
                <Button disabled={isBusy} onClick={() => handleSubmit()}>
                    Submit
                </Button>
            </div>
        </div>
    );
};
export default ZealReimbursement;
