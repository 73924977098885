import React from 'react';
import { css } from 'twin.macro';
import { connect } from 'react-redux';
import { getDateTime } from '../../../_helpers';
import { clearSearchMessages } from '../../../_redux';

const clearSearch = css`
	border: 1px solid #50aeb0;
	color: #1b686a;
	padding: 2.5px 5px;
	margin: 5px;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 200ms ease-in;
	&:hover {
		background-color: white;
	}
`;

const ClearSearch = ({ isSearch, searchFilters, updateSearchText, clearSearchMessages }) => {
	if (isSearch && searchFilters !== {}) {
		return (
			<div
				className="border-0 w-100 text-primary d-flex flex-column small align-items-center"
				css={css`
					background-color: #e0f0ef;
				`}
			>
				<div
					className="h4 p-2 text-center text-opto-dark font-weight-bold w-100"
					css={css`
						background-color: #c3e4e2;
					`}
				>
					Searched By
				</div>
				<div className="d-flex align-items-center justify-content-center w-100 border-primary">
					<div className="w-50 p-2 d-flex justify-content-around text-opto-dark">
						{searchFilters?.text?.length > 0 && <span>Text: &nbsp;{searchFilters?.text}</span>}
						{searchFilters?.type?.value && <span>Type: &nbsp;{searchFilters.type.label}</span>}
						<div>
							{searchFilters?.startDate && (
								<span>From: &nbsp;{getDateTime(searchFilters?.startDate).split('T')[0]}</span>
							)}
						</div>
						<div>
							{searchFilters?.endDate && (
								<span>To: &nbsp; {getDateTime(searchFilters?.endDate).split('T')[0]}</span>
							)}
						</div>
					</div>
					<div
						css={clearSearch}
						onClick={() => {
							clearSearchMessages();
							updateSearchText('');
						}}
					>
						{' '}
						Clear Search{' '}
					</div>
				</div>
			</div>
		);
	} else {
		return null;
	}
};

const mapStateToProps = (state) => ({
	isSearch: state.messages.isSearch,
	searchFilters: state.messages.searchFilters,
});

export default connect(mapStateToProps, { clearSearchMessages })(ClearSearch);
