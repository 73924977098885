import React from 'react';
import styled from '@emotion/styled';

const StyledLoadingContainer = styled.div`
	position: absolute;
	background: white;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items:center;
	p{
		margin-top: 0.5em;
	}
`;

export const LoadingContainer = (props) => (
	<StyledLoadingContainer>
		<img src="/images/ball-triangle.svg" alt="loader" />
		<p>{props.text}</p>
	</StyledLoadingContainer>
);
