import { css } from 'twin.macro';
import { Fragment } from 'react';

import { FcFile } from 'react-icons/fc';

export default function ReplyFile({ file }) {
	let ext;
	try{
		ext= file?.slice(((file.lastIndexOf('.') - 1) >>> 0) + 2);
	}
	catch(err) {
		ext = ''
	}


	switch (ext?.toLowerCase()) {
		case 'jpg':
		case 'jpeg':
		case 'png':
		case 'webp':
		case 'gif':
			return (
				<img
					src={file}
					alt="reply"
					css={css`
						height: 5rem;
						width: 5rem;
					`}
				/>
			);

		case 'zip':
			return (
				<Fragment>
					<i
						className="fas fa-file-archive px-3"
						css={css`
							font-size: 24px;
						`}
					></i>
					<span
						className="text-decoration-none"
						css={css`
							font-size: 16px;
						`}
					>
						shared Zip File
					</span>
				</Fragment>
			);
		case 'doc':
		case 'docx':
			return (
				<Fragment>
					<div className="pointer">
						<FcFile fontSize="24px" />

						<span
							className="text-decoration-none"
							css={css`
								font-size: 16px;
							`}
						>
							Shared Doc File
						</span>
					</div>
				</Fragment>
			);
		case 'pdf':
			return (
				<Fragment>
					<div className="pointer">
						<i
							className="fas fa-file-pdf px-3"
							css={css`
								font-size: 24px;
							`}
						></i>
						<span
							className="text-decoration-none"
							css={css`
								font-size: 16px;
							`}
						>
							Shared Pdf File
						</span>
					</div>
				</Fragment>
			);
		case 'mp4':
		case 'mov':

			return (
				<Fragment>
					<FcFile fontSize="24px" />
					<span
						className="text-decoration-none"
						css={css`
							font-size: 16px;
						`}
					>
						Shared Video
					</span>
				</Fragment>
			);
		default:
			return (
				<Fragment>
					<FcFile fontSize="24px" />
					<span
						className="text-decoration-none"
						css={css`
							font-size: 16px;
						`}
					>
						Shared File
					</span>
				</Fragment>
			);
	}
}
