import { css } from 'twin.macro';
import React, { Fragment, useMemo } from 'react';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';

//icons
import { teamSort } from './styles/TeamListStyles';
import { VscLoading } from 'react-icons/vsc';
import ChatListSkeleton from '../Layouts/ChatListSkeleton';
import Team from './Team';
import { canSeePublicTeams, sortingMethod } from '../../../_helpers';

const SpanCss = css`
    font-size: 1.8rem;
    width: 100%;
    text-align: center;
    display: inline-block;
    margin: 2rem 0;
`;

const TeamSearchComponent = ({
    user,
    roomIndex,
    teams,
    handleRoomClick,
    setShowChat,
    search,
    hasMore,
    loadFunc,
    isSearching,
    isTeamsLoading,
    collapse,
    handleCollaspe,
    setOpenDetails,
}) => {
    const getMyTeams = useMemo(() => {
        const sortedTeams = {
            my_teams: [],
            teams_managed_by_me: [],
            invited_teams: [],
            other_teams: [],
        };
        try {
            const myTeamList = (user.teamList.filter((team) => team.team) || []).sort(sortingMethod);
            const [myTeams, managedTeams, invitedTeams] = ['owner', 'teamManager', 'user'].map((role) =>
                myTeamList
                    .filter((team) => team.role === role)
                    .map((team) => team.team?._id || team.team?.id || team.team)
            );
            teams?.forEach((team) => {
                const type = [myTeams, managedTeams, invitedTeams]
                    .map((teamIds, index) => teamIds.includes(team.id || team._id) * (index + 1))
                    .find((x) => x);
                if (type) {
                    sortedTeams[['my_teams', 'teams_managed_by_me', 'invited_teams'][type - 1]].push(team);
                } else {
                    if (canSeePublicTeams(user)) {
                        sortedTeams.other_teams.push(team);
                    }
                }
            });
        } catch (error) {
            console.log(error);
        } finally {
            return sortedTeams;
        }
    }, [teams, user]);

    return (
        <React.Fragment>
            {isSearching || teams === null ? (
                <div
                    css={css`
                        width: 100%;
                        overflow: hidden;
                    `}
                >
                    <ChatListSkeleton type="team" />
                </div>
            ) : (
                <InfiniteScroll
                    pageStart={1}
                    initialLoad={false}
                    loadMore={loadFunc}
                    hasMore={!isSearching && !isTeamsLoading && hasMore && !!search}
                    threshold={50}
                    useWindow={false}
                >
                    <Fragment>
                        {getMyTeams && Object.values(getMyTeams).flat().length ? (
                            <Fragment>
                                {Object.keys(getMyTeams)?.map((sort) => (
                                    <Fragment key={sort}>
                                        {getMyTeams[sort].length > 0 && (
                                            <div className="text-capitalize" css={teamSort}>
                                                {sort.split('_').join(' ')}
                                            </div>
                                        )}
                                        {getMyTeams[sort].length > 0 &&
                                            getMyTeams[sort]?.map((team, idx) => {
                                                return (
                                                    <Team
                                                        team={team}
                                                        key={team?.id || team?._id || idx}
                                                        user={user}
                                                        handleCollaspe={handleCollaspe}
                                                        roomIndex={roomIndex}
                                                        collapse={collapse}
                                                        handleRoomClick={handleRoomClick}
                                                        setOpenDetails={setOpenDetails}
                                                        setShowChat={setShowChat}
                                                    />
                                                );
                                            })}
                                    </Fragment>
                                ))}

                                {hasMore ? null : <span css={SpanCss}>No more Teams</span>}
                            </Fragment>
                        ) : (
                            <span css={SpanCss}>No Team Found</span>
                        )}

                        {!isTeamsLoading ? null : (
                            <div
                                className="loader"
                                key={'loader-infinite-channel-search'}
                                css={css`
                                    height: 100%;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                `}
                            >
                                <VscLoading fontSize={34} className="spin" />
                            </div>
                        )}
                    </Fragment>
                </InfiniteScroll>
            )}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    user: state.user.user,
});

export default connect(mapStateToProps, null)(TeamSearchComponent);
