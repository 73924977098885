const publicVapidKey = process.env.REACT_APP_VAPID_KEY;

let subscription = null;

// Register SW, Register Push, Send Push
export async function swDev() {
	//Register service worker
	let swUrl = `${process.env.PUBLIC_URL}/serviceworker.js`;
	const register = await navigator.serviceWorker.register(swUrl, {
		scope: '/',
	});

	//Register Push
	if (!subscription)
		subscription = await register.pushManager.subscribe({
			userVisibleOnly: true,
			applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
		});
	return JSON.stringify(subscription);
}

function urlBase64ToUint8Array(base64String) {
	const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
	const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);

	for (let i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}
