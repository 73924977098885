import { css } from 'twin.macro';
import styled from '@emotion/styled';

// Common style for the view edit and delete buttons in table rows of all pages
export const ActionStyles = styled('div')`
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: center;
	height: 100%;

	.icon {
		height: 30px;
		width: 30px;
		padding: 5px;
		margin: 5px;
		color: #50aeb0;
		background-color: white;
		border: 1px solid #50aeb0;
		border-radius: 5px;
		box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
		cursor: pointer;
		transition: transform 200ms;
		&:hover {
			transform: scale(1.2);
		}
	}
	.edit {
		border: 1px solid #ea9941;
		color: #ea9941;
	}

	.archiveIn {
		border: 1px solid #b57500;
		color: #b57500;
	}

	.archiveOut {
		border: 1px solid #b57500;
		color: #b57500;
	}

	.view {
		border: 1px solid #24627e;
		color: #24627e;
	}
	.assign {
		border: 1px solid #84e840;
		color: #84e840;
	}
	.purple {
		border: 1px solid purple;
		color: purple;
	}
	.delete {
		border: 1px solid red;
		color: red;
	}
	.disabled {
		border: none;
		opacity: 0.5;
		color: gray;
		pointer-events: none;
		cursor: default !important;
		:hover {
			transform: none !important;
		}
	}
`;

export const ImageEditStyled = styled.img`
	height: 5rem;
	width: 5rem;
	border-radius: 50%;
	object-fit: cover;
	object-position: center center;
`;

// check mark for verified user at user image in admin panel - refer users in admin panel
export const VerifiedUserCheck = css`
	position: absolute;
	top: -0.5rem;
	right: -0.5rem;
	z-index: 1;
	width: 1.5rem;
	height: 1.5rem;
	font-size: 10px;
	background-color: #39c7fa;
	border-radius: 50%;
`;

// check mark for verified user at user image in admin panel - refer users in admin panel
export const ArchivedUser = css`
	position: absolute;
	top: -0.5rem;
	right: -0.5rem;
	z-index: 1;
	width: 2rem;
	height: 2rem;
	font-size: 10px;
	background-color: #fa3939;
	border-radius: 50%;
`;

// common design for tables in admin
export const AdminTable = styled('table')`
	.table-img {
		height: 40px;
		width: 40px;
		border-radius: 10px;
	}
	.tr-head {
		height: 60px;
	}
	th {
		border: none;
		height: 60px;
		background-color: #dddddd;
		padding: 1rem;
	}
	td {
		padding: 1rem;
	}
	tbody tr {
		height: 50px;
		border-bottom: 1px solid #e0e6ed;
		color: #515365;
	}
`;

export const TableStyled = styled('table')`
	thead {
		text-align: center;
	}
	th {
		border: none;
		height: 50px;
		background-color: #50aeb0;
		color: white;
	}
	tbody tr {
		height: 40px;
		border-bottom: 1px solid #e0e6ed;
	}

	td {
		padding-left: 20px;
		vertical-align: middle;
	}
`;
