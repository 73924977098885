import React from 'react';
import { css } from 'twin.macro';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import { EditAProvider } from '../../../../_redux/actions/ActionEVV';
import { connect } from 'react-redux';
import { Body } from './styles';
import ProviderForm from './ProviderForm';
// import {toast} from 'react-toastify';

import { BsPencil } from 'react-icons/bs';
import { ToolTip } from '../../../../_elements/ToolTip';

const EditProvider = ({ EditAProvider, provider, teamId }) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedOption, setSelectedOption] = useState();
    const [selectError, setSelectError] = useState(null);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [teamAlreadyExists, setTeamAlreadyExists] = useState(provider?.team);

    const onSubmit = async (data) => {
        if (teamAlreadyExists) {
            if (selectedTeam) {
                data = { ...data, team: selectedTeam?.value };
            } else {
                return setSelectError('Please select an team');
            }
        }
        try {
            setSubmitLoading(true);
            let result = await EditAProvider(data, provider.id, teamId);
            if (result) {
                setShowModal(false);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setSubmitLoading(false);
        }
    };

    const handleModalShow = () => {
        setShowModal(true);
        setSelectedOption(null);
    };

    return (
        <>
            <ToolTip text={'Edit'} side={'top'} cssStyles={'top:-70% !important; min-width: fit-content !important'}>
                <BsPencil className="icon edit" onClick={handleModalShow} />
            </ToolTip>
            <Modal show={showModal} backdropClassName="bg-dark" onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                    <div className="header">
                        <h4
                            css={css`
                                margin: 0;
                            `}
                        >
                            Edit Provider
                        </h4>
                    </div>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <Body>
                        <ProviderForm
                            onSubmit={onSubmit}
                            formType="Edit"
                            provider={provider}
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}
                            selectedTeam={selectedTeam}
                            setSelectedTeam={setSelectedTeam}
                            teamAlreadyExists={teamAlreadyExists}
                            setTeamAlreadyExists={setTeamAlreadyExists}
                            selectError={selectError}
                            submitLoading={submitLoading}
                            isTeamSettings={!!teamId}
                        />
                    </Body>
                </Modal.Body>
            </Modal>
        </>
    );
};
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { EditAProvider })(EditProvider);
