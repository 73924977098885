import { toast } from 'react-toastify';

function success(message = '', options = {}) {
	toast.success(message, options);
}
function info(message = '', options = {}) {
	toast.info(message, options);
}
function error(message = '', options = {}) {
	if (message !== 'AbortError') {
		toast.error(message, options);
	}
}
function warning(message = '', options = {}) {
	toast.warning(message, options);
}

function warn(message = '', options = {}) {
	toast.warn(message, options);
}

function dark(message = '', options = {}) {
	toast.dark(message, options);
}

export const toastWrapper = {
	success,
	info,
	error,
	warning,
	warn,
	dark
};
