import { css } from 'twin.macro';
import PropTypes from 'prop-types';

function MessageDate({ date }) {
	return (
		<p className="d-flex border-bottom  border-white pt-5  m-0 mb-2 text-muted  justify-content-center ">
			<span className="bg-light px-4 py-2" css={css`border-radius:10px 10px 0px 0px;`}>
				{date}
			</span>
		</p>
	);
}
MessageDate.propTypes = {
	date: PropTypes.string.isRequired,
};

export default MessageDate;
