import React from 'react';
import { css } from 'twin.macro';
import { Form } from 'react-bootstrap';

function getFormLabel(name) {
    const [initial, ...other] = (name || "Unnamed Field").replaceAll("_", " ").split('');
    return initial.toUpperCase() + other.join("").replaceAll(/([a-z])([A-Z])/g, '$1 $2');
}

function WorklioFormBuilder({
    formStructure,
    register,
    setValue,
    errors,
}) {
    return (
        <React.Fragment>
            <Form.Text readOnly>Effective From: {formStructure?.effectiveFrom}</Form.Text>
             <WorklioFormSubGroup
                formStructure={[formStructure?.federal]}
                register={register}
                setValue={setValue}
                errors={errors}
                />
             <WorklioFormSubGroup
                formStructure={formStructure?.state}
                register={register}
                setValue={setValue}
                errors={errors}
                />
                <WorklioFormSubGroup
                formStructure={formStructure?.suta}
                register={register}
                setValue={setValue}
                errors={errors}
                />
        </React.Fragment>

      );
}

function WorklioFormSubGroup({
	setValue,
	register,
	errors,
    formStructure
}) {
    return (
        <React.Fragment>
            {
            (formStructure ?? []).map((keyItem, idx) => (
                <div key={`${keyItem?.name}_${idx}`}>
                <hr/>
                <h5>{keyItem?.name || "No Title"}</h5>
                {keyItem?.parameters?.map((formData,idx) => (
                    <div key={`${formData?.taxId}_${idx}`}>
                    <WorklioFormComponent
                        formData={{
                        ...formData,
                        name: formData?.taxId,
                        caption: formData.caption ?? formData.name,
                        }}
                        defaultValues={formData?.value || formData?.defaultValue || ""}
                        register={register}
                        setValue={setValue}
                        errors={errors}
                    />
                    </div>
                ))}
                </div>
            ))
            }
        </React.Fragment>
      );
    }

function WorklioFormComponent({
	formData,
	setValue,
	register,
	disabled,
	defaultValues,
	errors,
}) {
	// checkbox
	if (formData.parameterType === 1 ) {
		return (
            <Form.Group>
                <div  style={{display:"flex",flexDirection:"row",alignItems:"center", gap: "6px"}}>
                    <Form.Control 
                    style={{width:"24px", cursor:"pointer"}}
                    name={formData.name}
                    type={"checkbox"}
                    id={formData.name}
                    // required={!formData.isOptional}
                    defaultChecked={defaultValues ==="true"}
                    disabled={disabled}
                    readOnly={formData.readonly}
                    ref={register}
                    onWheel={(event) => event.currentTarget.blur()}
                    onChange={(e) => {
                        setValue(formData.name,  e.target.checked );
                    }}
                />
                <Form.Label >
                    {getFormLabel(formData.caption)}
                </Form.Label>
            </div>
			<Form.Text
				css={css`
					text-align: justify;
					opacity: 0.75;
				`}
			>
				{formData?.hint?.split("<br>")?.map(hint=><p>{hint}</p>)}
			</Form.Text>
		</Form.Group>
		);
	}

	// select
	if (formData?.parameterType === 5) {
		return (
			<Form.Group>
				<Form.Label>
					{getFormLabel(formData.caption)}
					{!formData?.isOptional && (
						<span
							css={css`
								color: red;
								margin-left: 2px;
							`}
						>
							*
						</span>
					)}
				</Form.Label>

				<Form.Control
					as="select"
					name={formData.name}
					onChange={(e) => setValue(formData.name, e.target.value)}
					ref={register}
					required={formData?.isOptional ?? !formData.isOptional}
					disabled={disabled}
					defaultValue={defaultValues}
				>
					{(formData.options || []).map((option, index) => (
						<option value={option.value ?? option} key={index}>
							{getFormLabel(option.caption ?? option)}
						</option>
					))}
				</Form.Control>
				<Form.Text
				css={css`
					text-align: justify;
					opacity: 0.75;
				`}
			>
				{formData?.hint?.split("<br>")?.map(hint=><p>{hint}</p>)}
			</Form.Text>
			</Form.Group>
		);
	}
	
	// input
	return (
		<Form.Group>
			<Form.Label>
				{getFormLabel(formData.caption)}
				{!formData?.isOptional && (
					<span
						css={css`
							color: red;
							margin-left: 2px;
						`}
					>
						*
					</span>
				)}
			</Form.Label>
			<Form.Control
				name={formData.name}
				type={"text"}
				id={formData.name}
				required={!formData?.isOptional}
				defaultValue={defaultValues}
				disabled={disabled}
				maxLength={formData.maxlength}
				minLength={formData.minlength}
				min={formData.min}
				max={formData.max}
				readOnly={formData.readonly}
				step={formData.precision && 10 ** -formData.precision}
				ref={register({
					pattern: {
					  value: formData?.regEx ? new RegExp(formData?.regEx) : null,
					  message: formData?.warning || 'Invalid input',
					},
				  })}
				onWheel={(event) => event.currentTarget.blur()}
			/>
			<Form.Text
				css={css`
					text-align: justify;
					opacity: 0.75;
					color: red;
				`}
			>
				{errors[formData?.name] && ( <p style={{ color: 'red', textAlign: 'justify' }}> {errors[formData?.name].message} </p>)}
			</Form.Text>
			<Form.Text
				css={css`
					text-align: justify;
					opacity: 0.75;
				`}
			>
				{formData?.hint?.split("<br>")?.map(hint=><p>{hint}</p>)}
			</Form.Text>
		</Form.Group>
	);
}

export default WorklioFormBuilder;
