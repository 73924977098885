import styled from '@emotion/styled';

export const StyledConfrenceContainer = styled.div`
	form {
		max-width: 300px;
		margin: 0 auto;
	}


	form > div {
		width: 100%;
		margin-bottom: 1em;
	}
	form > div > label {
		display: block;
		margin-bottom: 0.3em;
	}
	form > div > input {
		display: block;
		width: 100%;
		font-size: 16px;
		padding: 0.4em;
		border-radius: 6px;
		border: 1px solid #333e5a;
	}

	.BlueButton {
		background: #333e5a;
		color: #fff;
		font-size: 16px;
		padding: 0.4em;
		border-radius: 6px;
		border: 1px solid transparent;
	}
	.BlueButton:hover {
		filter: brightness(150%);
	}

	.room {
		position: relative;
	}
	.room > h3 {
		text-align: center;
		font-weight: 300;
		margin-bottom: 1em;
	}

	.local-participant {
		text-align: center;
		margin-bottom: 2em;
	}
	.remote-participants {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		padding: 2rem;
		overflow-x: auto;
		margin-bottom: 2rem;
		width: 90vw;
	}
	.participant {
		background-color: #333e5a;
		height: 100%;
		width: 100%;
		position: relative;
		border-radius: 5px;
		min-height: 200px;

		display: flex;
		justify-content: center;
		align-items: center;
		&.dominant {
			border: 4px solid #2e9a9c;
			box-shadow: -1px 1px 13px 4px #42a7a9;
		}
	}
	.participant:last-child {
		margin-right: 0;
	}
	.participant h3 {
		text-align: center;
		padding-bottom: 0.5em;
		color: #fff;
	}

	video {
		width: 100%;
		height: 100%;
		display: block;
		margin: 0 auto;
		border-radius: 6px;
	}
`;
