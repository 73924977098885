import { css } from 'twin.macro';

export const titleBar = css`
	border-bottom: 1px solid #dddddd;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	width: 100%;
	.title {
		font-weight: bold;
		color: #757575;
	}
	.delete {
		background-color: #f8d9d9;
		color: red;
	}
`;
export const subChannelTitleBar = css`
	border-bottom: 1px solid #dddddd;
	display: flex;
	justify-content: start;
	align-items: start;
	padding: 10px;
	width: 100%;
	gap: 16px;
	align-items: center;
`;
export const counterBar = css`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1rem 10px;
	width: 100%;
	position: relative;
`;

export const channelBar = css`
	height: 30px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	background-color: #2e9a9c;
	display: flex;
	align-items: center;
	padding: 2.4rem 10px;
	color: #e0f0ef;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	@media (max-width: 1024px) {
		height: auto;
	}

	.channelNames {
		display: flex;
		flex-direction: row;
		align-items: center;
		font-size: 14px;
		max-width: 75%;
		@media (max-width: 1024px) {
			font-size: 12px;
			flex-direction: column;
		}

		.channel-name {
			font-size: 1.75rem;
			&:hover {
				color: #c3e4e2;
			}
		}
		.separator {
			height: 5px;
			width: 5px;
			border-radius: 2.5px;
			margin: auto 10px;
			background-color: #e0f0ef;
		}
	}

	.channel-actions {
		display: flex;
		.search-chat {
			height: 100%;
			background-color: #c3e4e2;
			border-radius: 5px;
			.icon {
				color: #50aeb0;
			}
		}
		.details-toggle {
			width: 100px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			color: white;
			font-size: 14px;
			border: 1px solid #e0f0ef;
			background-color: #e0f0ef;
			border-radius: 5px;
			transition: border 200ms ease-in-out, background-color 200ms ease-in-out;
			cursor: pointer;
			&:hover {
				background-color: #c3e4e2;
			}
			.icon {
				color: #50aeb0;
				font-size: 25px;
			}
			.details {
				background-color: transparent;
				color: #50aeb0;
				padding: 0px 5px;
				transition: transform 200ms ease-in-out;
			}
		}
	}
`;

export const subChannelBar = css`
	height: 30px;
	width: 100%;
	display: flex;
	justify-content: end;
	background-color: #2e9a9c;
	display: flex;
	align-items: center;
	padding: 2.4rem 20px;
	color: #e0f0ef;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	@media (max-width: 1024px) {
		height: auto;
	}
`;
