import React from 'react';
import { css } from 'twin.macro';
import packageJson from '../../../../package.json';

const Versioning = () => {
	return (
		<div
			css={css`
				position: absolute;
				bottom: 0;
				text-align: center;
				font-size: 1.2rem;
				color: gray;
			`}
		>
			v{packageJson.version}
		</div>
	);
};

export default Versioning;
