import React from 'react';
import { css } from 'twin.macro';
import { VscLoading } from 'react-icons/vsc';

const LoadingSpiner = ({size, color}) => {
	return (
		<div
			css={css`
				display: flex;
				display: flex;
				justify-content: center;
				align-items: center;
			`}
		>
			<VscLoading className="spin" fontSize={size} color={color} />
		</div>
	);
};

export default LoadingSpiner;
