import { Fragment, useEffect, useState } from 'react';
import { RiFolderUserLine } from 'react-icons/ri';

import { VscLoading } from 'react-icons/vsc';
import { connect } from 'react-redux';
import { css } from 'twin.macro';
import { DashStyles } from './styles/DashStyled';
import {
    checkPermission,
    getTeamIdFromURL,
    getChannelIdFromSettingsURL,
    toastWrapper,
    fetchWrapper,
} from '../../../../_helpers';
import { deleteATeamById } from '../../../../_redux/';
import { NavLink } from 'react-router-dom';

import AvatarUserPanel from '../../Common/AvatarUserPanel';
import { FaUsers, FaRegCalendarAlt, FaBook } from 'react-icons/fa';
import { BsCardChecklist, BsFillPeopleFill } from 'react-icons/bs';
import { TbReportMoney } from 'react-icons/tb';
import { Col, Row } from 'react-bootstrap';
import NavigationBar from '../../../Admin/reusableComponents/NavigationBar';
import EditTeam from '../EditTeam';
import EditChannel from '../EditChannel';
import { getRole } from '../../../../_helpers/roles/get-roles';

import TeamSettingsContent from './TeamSettingsContent';
import { ChannelInfoCard } from './InfoCard';

const Dash = ({ teams, currUser, goToTeamPage }) => {
    const [visibleEditTeam, setVisibleEditTeam] = useState(false);
    const [visibleDeleteteam, setVisibleDeleteteam] = useState(false);
    const [canViewEVV, setCanViewEVV] = useState(false);

    let teamId = getTeamIdFromURL();
    let team = teams.filter((team) => team.id === teamId);
    const channelIdFromUrl = getChannelIdFromSettingsURL();
    let role = getRole(currUser, team[0]);

    const [channel, setChannel] = useState(currUser?.roomList?.find((room) => room?.id === channelIdFromUrl) || {});

    useEffect(() => {
        if (checkPermission(role, 'update:team', { userId: currUser?.id, teamAdmin: team[0]?.admin })) {
            setVisibleEditTeam(true);
        }
        if (checkPermission(role, 'remove:team', { userId: currUser?.id, teamAdmin: team[0]?.admin })) {
            setVisibleDeleteteam(true);
        }
        if (role === 'admin' || role === 'teamManager' || role === 'owner') {
            setCanViewEVV(true);
        }
    }, [currUser, role, team]);

    useEffect(() => {
        async function getChannelDetails() {
            try {
                const channelObj = await fetchWrapper.get(`/chatrooms/${channelIdFromUrl}`);
                setChannel({ ...channelObj, label: channelObj?.title, value: channelObj?.id, id: channelIdFromUrl });
            } catch (error) {
                if (error.name === 'AbortError') {
                    return;
                }
                toastWrapper.error(error.message);
                window.location.href = '/not-found';
            }
        }
        if (channelIdFromUrl) {
            getChannelDetails();
        }
    }, [channelIdFromUrl]);

    return (
        <DashStyles>
            {team ? (
                <div className="settings-container">
                    <div className="ses-container">
                        <div
                            className="d-flex justify-content-between"
                            css={css`
                                width: 100%;
                            `}
                        >
                            <NavigationBar
                                mainTabName={team[0]?.name}
                                tab1={channel?.title}
                                goToTeamPage={goToTeamPage}
                            />
                            <div
                                className="d-flex"
                                css={css`
                                    height: 40px;
                                `}
                            >
                                {!channelIdFromUrl && visibleEditTeam && team[0] && (
                                    <EditTeam
                                        key="EditTeam-123"
                                        team={team[0]}
                                        visibleDeleteteam={visibleDeleteteam}
                                        goToTeamPage={goToTeamPage}
                                    />
                                )}

                                {channelIdFromUrl && team[0] && (
                                    <EditChannel
                                        key="EditChannel-123"
                                        team={team[0]}
                                        teamId={teamId}
                                        room={channel}
                                        visibleDeleteteam={visibleDeleteteam}
                                        goToTeamPage={goToTeamPage}
                                        newChannelSettings={true}
                                        setUpdateChannel={setChannel}
                                    />
                                )}
                            </div>
                        </div>

                        <Row className="team-stats">
                            <Col sm={4} className="px-3">
                                <div
                                    css={css`
                                        box-shadow: none !important;
                                        cursor: default !important;
                                    `}
                                    className="settings-cards d-flex justify-content-between"
                                >
                                    {channelIdFromUrl ? (
                                        <ChannelInfoCard team={team} channel={channel} />
                                    ) : (
                                        <div className="info d-flex w-100">
                                            <AvatarUserPanel large={true} item={team[0]} />
                                            <div className="team-infos">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <th>Team Name:</th>
                                                            <td
                                                                css={css`
                                                                    word-break: break-all;
                                                                `}
                                                            >
                                                                &nbsp; {team[0]?.name}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Created on:</th>
                                                            <td>
                                                                &nbsp; {new Date(team[0]?.createdAt).toDateString()}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Owner:</th>
                                                            <td>&nbsp; {team[0]?.adminData?.name}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Last updated:</th>
                                                            <td>
                                                                &nbsp; {new Date(team[0]?.updatedAt).toDateString()}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Total Channels:</th>
                                                            <td>&nbsp;&nbsp;{team[0]?.noOfRooms}</td>
                                                        </tr>

                                                        <tr>
                                                            <th>Total Members:</th>
                                                            <td>&nbsp;&nbsp;{team[0]?.noOfMembers}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Col>
                            <Col sm="8">
                                <Row
                                    className="team-stats"
                                    css={css`
                                        flex-wrap: nowrap;
                                    `}
                                >
                                    <div
                                        css={css`
                                            width: 20%;
                                        `}
                                        className="px-3"
                                    >
                                        <NavLink
                                            css={css`
                                                text-decoration: none !important;
                                            `}
                                            to={'/members/'}
                                            activeClassName="selected-card"
                                            className={`settings-cards d-flex flex-column justify-content-center align-items-center`}
                                        >
                                            <FaUsers size={40} className="text-primary" />
                                            <div className="mt-2">Members</div>
                                        </NavLink>
                                    </div>

                                    {!channelIdFromUrl && (
                                        <Fragment>
                                            <div
                                                css={css`
                                                    width: 20%;
                                                `}
                                                className="px-3"
                                            >
                                                <NavLink
                                                    css={css`
                                                        text-decoration: none !important;
                                                    `}
                                                    to={'/channel/'}
                                                    activeClassName="selected-card"
                                                    className={`settings-cards d-flex flex-column justify-content-center align-items-center`}
                                                >
                                                    <BsFillPeopleFill size={40} className="text-primary" />
                                                    {/* <div className="h2 font-weight-bold">{team[0]?.noOfRooms}</div> */}
                                                    <div className="mt-2">Channels</div>
                                                </NavLink>
                                            </div>
                                        </Fragment>
                                    )}

                                    {((!channelIdFromUrl && team[0]?.provider) ||
                                        (channelIdFromUrl &&
                                            team[0]?.provider &&
                                            channel?.client?.id &&
                                            canViewEVV)) && (
                                        <div
                                            css={css`
                                                width: 20%;
                                            `}
                                            className="px-3"
                                        >
                                            <NavLink
                                                css={css`
                                                    text-decoration: none !important;
                                                `}
                                                to={'/user-evv/'}
                                                activeClassName="selected-card"
                                                className={`settings-cards d-flex flex-column justify-content-center align-items-center`}
                                            >
                                                <BsCardChecklist size={40} className="text-primary" />
                                                <div className="mt-2">EVV</div>
                                            </NavLink>
                                        </div>
                                    )}
                                    {!channelIdFromUrl && team[0]?.provider && (
                                        <div
                                            css={css`
                                                width: 20%;
                                            `}
                                            className="px-3"
                                        >
                                            <NavLink
                                                css={css`
                                                    text-decoration: none !important;
                                                `}
                                                to={'/user-payroll/'}
                                                activeClassName="selected-card"
                                                className={`settings-cards d-flex flex-column justify-content-center align-items-center`}
                                            >
                                                <TbReportMoney size={40} className="text-primary" />
                                                <div className="mt-2">Payroll</div>
                                            </NavLink>
                                        </div>
                                    )}

                                    <div
                                        css={css`
                                            width: 20%;
                                        `}
                                        className="px-3"
                                    >
                                        <NavLink
                                            css={css`
                                                text-decoration: none !important;
                                            `}
                                            to={'/form/'}
                                            activeClassName="selected-card"
                                            className={`settings-cards d-flex flex-column justify-content-center align-items-center`}
                                        >
                                            {/* <div className="h2 font-weight-bold">{team[0]?.noOfRooms}</div> */}
                                            <RiFolderUserLine size={40} className="text-primary" />
                                            <div className="mt-2">Documents</div>
                                        </NavLink>
                                    </div>
                                    <div
                                        css={css`
                                            width: 20%;
                                        `}
                                        className="px-3"
                                    >
                                        <NavLink
                                            css={css`
                                                text-decoration: none !important;
                                            `}
                                            to={'/events/'}
                                            activeClassName="selected-card"
                                            className={`settings-cards d-flex flex-column justify-content-center align-items-center`}
                                        >
                                            {/* <div className="h2 font-weight-bold">{team[0]?.noOfRooms}</div> */}
                                            <FaRegCalendarAlt size={40} className="text-primary" />
                                            <div className="mt-2">Events</div>
                                        </NavLink>
                                    </div>
                                    <div
                                        css={css`
                                            width: 20%;
                                        `}
                                        className="px-3"
                                    >
                                        <NavLink
                                            css={css`
                                                text-decoration: none !important;
                                            `}
                                            to={'/courses'}
                                            activeClassName="selected-card"
                                            className={`settings-cards d-flex flex-column justify-content-center align-items-center`}
                                        >
                                            <FaBook size={40} className="text-primary" />
                                            <div className="mt-2">Courses</div>
                                        </NavLink>
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                        <TeamSettingsContent channelDetails={channel} />
                    </div>
                </div>
            ) : (
                <VscLoading className="spin" />
            )}
        </DashStyles>
    );
};

const mapStateToProps = (state) => ({
    teamRooms: state.rooms.teamRooms,
    teams: state.teams.teams,
    currUser: state.user.user,
    organizations: state.organizations.organizations,
});
export default connect(mapStateToProps, { deleteATeamById })(Dash);
