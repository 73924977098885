import { css } from 'twin.macro';

export const TeamSettingsStyled = css`
	min-height: 100vh;
	width: 100%;
	display: flex;
	margin-right: 24rem;
	@media (max-width: 1200px) {
		margin-right: 5rem;
	}
	.mainSection {
		margin-left: 12rem;
		width: auto;
		padding: 1em;
		@media (max-width: 1200px) {
			margin-left: 5rem;
		}
		.settingsNavDiv {
			margin: 0.5em 1em 0;
			padding: 5px 5px 0;
			display: flex;
			background: white;
			border-radius: 5px;
			.bold {
				font-weight: bold;
			}
			.navBtn {
				cursor: pointer;
				font-size: 35px;
				width: 4%;

				&.closebtn {
					transition: color 200ms, transform 200ms;
					:hover {
						color: red;
						transform: scale(1.2);
					}
					:active {
						transform: scale(0.8);
					}
				}
			}
		}
	}
	.costume-link {
		outline: none;
		width: 100%;
		text-align: left;
		:hover{
			border-color: none !important;
		}
	}
`;