import React, { useEffect } from 'react';
import { css } from 'twin.macro';

import { useAtBottom, useScrollToBottom } from 'react-scroll-to-bottom';
import { connect, useDispatch } from 'react-redux';
import { AiOutlineArrowDown } from 'react-icons/ai';

const NewMessageButton = ({ isNewMessage, newMessageRef }) => {
	const [atBottom] = useAtBottom();
	const scrollToBottom = useScrollToBottom();

	const dispatch = useDispatch();

	useEffect(() => {
		// console.log(atBottom, 'atBottom')
		if (atBottom && isNewMessage) {
			dispatch({ type: 'IS_NEW_MESSAGE', payload: false });
		}
	}, [atBottom, dispatch, isNewMessage]);

	return (
		<div
			ref={newMessageRef}
			css={css`
				:hover {
					.NewMessageBtn {
						transform: translate(-50%, -10%);
					}
				}
			`}
			onClick={scrollToBottom}
		>
			{!atBottom && isNewMessage ? (
				<button
					className="NewMessageBtn"
					css={css`
						padding: 0.5em 1em;
						position: absolute;
						bottom: 0;
						left: 50%;
						transform: translateX(-50%);
						background: #2e9a9c;
						color: white;
						transition: transform 200ms;
						cursor: pointer;
						border: 0;
						overflow: hidden;
						:before {
							content: '';
							background: #9ddfe9;
							display: block;
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							transform: scale(1);
							transform-origin: 50% 100% !important;
							opacity: 0;
							transition: transform 350ms, opacity 350ms;
						}
						:active:before {
							opacity: 1;
							transform: scale(0);
							transition: 0s;
						}
					`}
				>
					<AiOutlineArrowDown />{' '}
					<span
						css={css`
							margin: 0 0.4rem;
						`}
					>
						New Message
					</span>
					<AiOutlineArrowDown />{' '}
				</button>
			) : null}
		</div>
	);
};

const mapStateToProps = (state) => ({
	isNewMessage: state.messages.isNewMessage,
});
export default connect(mapStateToProps, null)(NewMessageButton);
