import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

const Colorbox = styled.div`
	border: 1px solid gray;
	margin: 1rem 10px 1rem 0;
	width: 30px;
	height: 30px;
	&.verifed {
		background: #a7d5d8;
	}
`;
function LabelDetail({ color, label }) {
	return (
		<div className="ml-4 d-flex align-items-center  text-capitalize">
			<Colorbox
				css={css`
					background: ${color};
				`}
			></Colorbox>
			: {label}
		</div>
	);
}

export default LabelDetail;
