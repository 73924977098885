import Select from 'react-select/async';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setTeams } from '../../_redux';
import { fetchWrapper, getUniqueObjects, sortingOptionMethod } from '../../_helpers';

const TeamSelectFilter = ({ teams, totalTeams, setTeams, selectedTeamOption, setSelectedTeamOption = () => { }, disabled = false, ...props }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [search, setSearch] = useState('');
	const [_, setPage] = useState(1);
	const [scrolledToBottom, setScrolledToBottom] = useState(false);

	useEffect(() => {
		if (scrolledToBottom && !isLoading && (teams.length < totalTeams)) {
			setPage((prev) => {
				const newPage = prev + 1;
				fetchTeams(newPage);
				return newPage;
			});
		}
	}, [scrolledToBottom]);

	const onTeamSearchChange = (selectedTeam) => {
		if (Array.isArray(selectedTeam)) {
			setSelectedTeamOption(selectedTeam.length ? selectedTeam : [])
		} else {
			setSelectedTeamOption(selectedTeam)
		}
	};

	const mapTeamsToOptions = (teams) => getUniqueObjects(teams || []).map((team) => ({
		label: team?.name,
		value: team?._id || team.id,
	})).sort(sortingOptionMethod);

	const fetchTeams = async (page = 1, name = search) => {
		try {
			setIsLoading(true);
			if (teams.length >= totalTeams) {
				return []
			}
			const data = await fetchWrapper.get(`/organizations/5f3569c2647fb5000c019e42/teams?page=${page}&limit=100${name ? `&name=${name}` : ''}&sortBy=name:asc`);
			setTeams(data.results, name ? totalTeams : data.totalResults);
			return mapTeamsToOptions(data.results?.filter(({ name: teamName }) => (!name) || new RegExp(name, 'i').test(teamName)))
		} catch (error) {
			return [];
		} finally {
			setIsLoading(false);
			setScrolledToBottom(false);
		}
	};

	let timeout;
	const loadTeamOptions = (search) => {
		return new Promise(resolve => {
			clearTimeout(timeout);
			if (teams.length >= totalTeams) {
				resolve(mapTeamsToOptions(teams.filter(({ name }) => !search || new RegExp(search, 'i').test(name))))
			}
			timeout = setTimeout(() => {
				if (!search) {
					resolve(mapTeamsToOptions(teams));
				}
				fetchTeams(1, search).then(options => {
					setSearch(search);
					resolve(options);
				})
			}, 500)
		})
	}

	return (
		<div
			style={{
				minWidth: '312px',
				gap: '6px',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'flex-start',
			}}
		>
			<div
				style={{
					width: '100%',
					position: 'relative',
					// display: 'flex',
					gap: '6px'
				}}
			>
				<Select
					value={selectedTeamOption}
					defaultOptions={mapTeamsToOptions(teams)}
					loadOptions={loadTeamOptions}
					onChange={onTeamSearchChange}
					isClearable={!props.required && selectedTeamOption?.value}
					placeholder={'Select Team'}
					isDisabled={disabled}
					isLoading={isLoading}
					onMenuScrollToBottom={() => { setScrolledToBottom(true) }}
					styles={{
						option: (baseStyles, state) => ({
							...baseStyles,
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						}),
						control: (baseStyles, state) => ({
							...baseStyles,
							// borderRadius: '12px',
							minHeight: '3.7rem',
						}),
					}}
					theme={(theme) => ({
						...theme,
						colors: {
							...theme.colors,
							primary25: '#c3e4e2',
							primary: '#50aeb0',
						},
					})}
					{...props}
				>
				</Select>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	teams: state.teams.teams,
	totalTeams: state.teams.totalTeams,
});

const mapDispatchToProps = { setTeams };

export default connect(mapStateToProps, mapDispatchToProps)(TeamSelectFilter);
