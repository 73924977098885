import { styled } from 'twin.macro';

export const ButtonGreen = styled.button`
	color: white;
	cursor: pointer;
	background-color: #50aeb0;
	outline: none;
	display: inline-block;
	font-weight: 400;
	text-align: center;
	vertical-align: middle;
	-webkit-user-select: none;
	user-select: none;
	border: 1px solid transparent;
	padding: 0.375rem 0.75rem;
	font-size: 1.6rem;
	line-height: 1.5;
	border-radius: 0.25rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
	padding: 10px 20px;
	&:hover {
		color: white;
		background-color: #49a8aa;
	}
`;

export const StyledLinkBtn = styled.div`
	cursor: pointer;
	color: #50aeb0;
	display: flex;
	align-items: center;
	svg {
		font-size: 24px;
		padding-right: 0.5rem;
	}
	:hover {
		color: #3a7778;
	}

	&.danger {
		color: #da2727;
	}
`;
