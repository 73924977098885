import styled from '@emotion/styled';

export const LobbyContainer = styled.div`
	min-height: 100vh;
	background: #e6eeed;
	display: flex;
	justify-content: center;
	align-items: center;

	.container {
		min-height: 100vh;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
	}
	.main_container {
		width: 50%;
		padding-bottom: 10rem;
	}
	.controlContainer {
		margin-top: 3rem;
		padding: 3rem;
		width: 100%;
		background: #d7d7d7;
	}
	.userInfoDiv {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.controlsDiv {
		display: flex;
		margin: 0;
	}
	.centerContainer {
		text-align: center;
		margin-top: 1rem;
		display: flex;
		justify-content: center;
		button {
			padding: 0.5rem 2rem;
		}
	}
	.infoContainer {
		text-align: center;
		font-size: 2rem;
	}
`;
