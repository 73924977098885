const moment = require("moment");

const recurrenceTypeData = {
    daily: {
        number: 1,
        unit: 'days',
        ifInvalid: (momentDate) => momentDate.endOf('day'),
    },
    weekly: {
        number: 7,
        unit: 'days',
        ifInvalid: (momentDate) => momentDate.endOf('day'),
    },
    monthly: {
        number: 1,
        unit: 'months',
        ifInvalid: (momentDate) => momentDate.endOf('month'),
    },
    quarterly: {
        number: 3,
        unit: 'months',
        ifInvalid: (momentDate) => momentDate.endOf('month'),
    },
    halfyearly: {
        number: 6,
        unit: 'months',
        ifInvalid: (momentDate) => momentDate.endOf('month'),
    },
    yearly: {
        number: 1,
        unit: 'years',
        ifInvalid: (momentDate) => momentDate.endOf('year'),
    },
};

const getRecurrenceData = (recurrenceString, momentDate, toDeduct = false) => {
    const [num, unit, days, weeks] = recurrenceString.split('-');
    const baseData = recurrenceTypeData[{ days: 'daily', weeks: 'weekly', months: 'monthly', years: 'yearly' }[unit]];
    if (['minutes', 'days'].includes(unit) || !days) {
        return {
            unit,
            ifInvalid: (date) => date.endOf('day'),
            ...(baseData || {}),
            number: parseInt(num, 10) * (baseData?.number || 1),
        };
    }

    const currentDay = momentDate.day();
    const daysArr = days ? [...new Set(days.split('').map((x) => parseInt(x, 10) % 7))] : [currentDay];
    const [minDay, maxDay] = [Math.min(...daysArr), Math.max(...daysArr)];

    // eslint-disable-next-line
    const closestDay = toDeduct
        ? currentDay < minDay
            ? maxDay
            : Math.max(...daysArr.filter((x) => x < currentDay))
        : currentDay > maxDay
            ? minDay
            : Math.min(...daysArr.filter((x) => x > currentDay));

    if (baseData.unit === 'days') {
        return {
            unit: 'days',
            number:
                // eslint-disable-next-line
                currentDay === (toDeduct ? minDay : maxDay)
                    ? parseInt(num, 10) * baseData.number - (maxDay - minDay)
                    : toDeduct
                        ? (closestDay < currentDay ? 0 : 7) + currentDay - closestDay
                        : (closestDay > currentDay ? 0 : 7) + closestDay - currentDay,
        };
    }

    const currentDate = momentDate.date();
    const currentWeek = Math.floor(currentDate / 7);
    const weeksArr = weeks ? weeks.split('').map((x) => parseInt(x, 10) % 4) : [currentWeek];
    const startDay = moment(momentDate).startOf('month').day();
    const startDate = 1 + (minDay >= startDay ? minDay - startDay : 7 + minDay - startDay);
    const validDates = weeksArr.map((week) => daysArr.map((day) => week * 7 + startDate + (day - minDay))).flat();
    const [minDate, maxDate] = [Math.min(...validDates), Math.max(...validDates)];

    const closestDate = toDeduct
        ? Math.max(...validDates.filter((x) => x < currentDate))
        : Math.min(...validDates.filter((x) => x > currentDate));

    if (toDeduct ? closestDate >= minDate : closestDate <= maxDate) {
        return {
            unit: 'days',
            number: Math.abs(closestDate - currentDate),
        };
    }

    const nextOrPrevStartMonthDate = moment(momentDate)
        .set('date', 1)
    [toDeduct ? 'subtract' : 'add'](parseInt(num, 10) * baseData.number, 'months');

    const nextOrPrevStartDay = nextOrPrevStartMonthDate.day();
    const minOrMaxWeek = Math[toDeduct ? 'max' : 'min'](weeksArr);
    const nextOrPrevStartDate =
        // eslint-disable-next-line
        7 * minOrMaxWeek + 1 + toDeduct
            ? maxDay <= nextOrPrevStartDay
                ? nextOrPrevStartDay - maxDay
                : 7 + nextOrPrevStartDay - maxDay
            : minDay >= nextOrPrevStartDay
                ? minDay - nextOrPrevStartDay
                : 7 + minDay - nextOrPrevStartDay;

    const nextOrPrevStartMoment = nextOrPrevStartMonthDate.set('date', nextOrPrevStartDate);

    return (
        baseData && {
            unit: 'days',
            number: nextOrPrevStartMoment.diff(momentDate, 'days'),
        }
    );
};

const addOrDeductTimeToMomentDate = (momentDate, compareDate, recurringType, deduct = false) => {
    const recurrenceData =
        recurringType && (recurrenceTypeData[recurringType] || getRecurrenceData(recurringType, momentDate, deduct));

    if (!recurrenceData || !momentDate) {
        return null;
    }

    if (!momentDate.isValid()) {
        recurrenceData.ifInvalid(momentDate);
    }

    while (momentDate[deduct ? 'isAfter' : 'isBefore'](compareDate)) {
        const newRecurrenceData =
            recurringType && (recurrenceTypeData[recurringType] || getRecurrenceData(recurringType, momentDate, deduct));

        momentDate[deduct ? 'subtract' : 'add'](newRecurrenceData.number, newRecurrenceData.unit);

        if (!momentDate.isValid()) {
            newRecurrenceData.ifInvalid(momentDate);
        }
    }
};

const handleDatePointers = (data, compareDate = moment.now(), deduct = false) => {
    if (!data) {
        return {};
    }

    const taskDoc = { ...data };

    const recurringMomentFirst = moment(taskDoc.latestDatePointer || taskDoc.date);
    const dueDate = taskDoc.latestDueDatePointer || taskDoc.dueDate;
    const recurringMomentSecond = dueDate && moment(dueDate);
    const recurringMomentUntil = taskDoc.recurringUntil && moment(taskDoc.recurringUntil);

    const areDateOrdered = recurringMomentFirst.isBefore(recurringMomentSecond);

    const recurringMoment = areDateOrdered ? recurringMomentFirst : recurringMomentSecond;
    const recurringMomentDueDate = areDateOrdered ? recurringMomentSecond : recurringMomentFirst;

    const timeDiff = recurringMomentDueDate && recurringMomentDueDate.diff(recurringMoment);

    const recurringType = taskDoc.recurring && taskDoc.recurring.toLowerCase();

    // const isTaskOngoing = recurringMomentDueDate && recurringMomentDueDate.diff(moment(compareDate)) > 0;
    const shouldTaskRecur = !recurringMomentUntil || recurringMomentUntil.diff(moment(compareDate)) > 0;

    if (deduct || shouldTaskRecur) {
        addOrDeductTimeToMomentDate(recurringMoment, moment(compareDate), recurringType, deduct);
        addOrDeductTimeToMomentDate(recurringMomentDueDate, moment(compareDate).add(timeDiff), recurringType, deduct);
    }

    const isRecurringMomentValid = recurringMoment.isSameOrAfter(taskDoc.date || taskDoc.latestDatePointer);

    if (isRecurringMomentValid && (!recurringMomentUntil || recurringMoment.isBefore(recurringMomentUntil))) {
        taskDoc.latestDatePointer = recurringMoment.toDate();
        taskDoc.latestDueDatePointer = recurringMomentDueDate && recurringMomentDueDate.toDate();
    }

    taskDoc.latestDatePointer = taskDoc.latestDatePointer || taskDoc.date;
    taskDoc.latestDueDatePointer = taskDoc.latestDueDatePointer || taskDoc.dueDate;

    return { latestDatePointer: taskDoc.latestDatePointer, latestDueDatePointer: taskDoc.latestDueDatePointer };
    // return { taskDoc };
};

export const getAllDatePointers = (data) => {
    const maxDate =
        data.recurringUntil && moment(data.maxDate).isAfter(data.recurringUntil)
            ? data.recurringUntil
            : data.maxDate || data.recurringMomentUntil || data.latestDueDatePointer || data.dueDate;

    const minDate =
        data.recurring && moment(data.minDate).isBefore(data.date)
            ? data.date
            : data.minDate || data.date || data.latestDatePointer;

    const tasks = [
        {
            latestDatePointer: new Date(data.latestDatePointer),
            latestDueDatePointer: new Date(data.latestDueDatePointer),
        },
    ];

    while (moment(tasks[0].latestDatePointer).isAfter(minDate)) {
        const lastTask = tasks[0];
        const compareDate = moment(lastTask.latestDatePointer).subtract(1, 'minutes');
        const newTask = handleDatePointers(
            { ...lastTask, recurring: data.recurring, recurringUntil: data.recurringUntil, date: data.date },
            compareDate,
            true
        );
        if (
            moment(newTask.latestDatePointer).isSame(moment(lastTask.latestDatePointer)) ||
            moment(newTask.latestDatePointer).isBefore(minDate)
        ) {
            break;
        }
        tasks.unshift({
            latestDatePointer: newTask.latestDatePointer,
            latestDueDatePointer: newTask.latestDueDatePointer,
        });
    }

    while (moment(tasks[tasks.length - 1].latestDatePointer).isBefore(maxDate)) {
        const lastTask = tasks[tasks.length - 1];
        const compareDate = moment(lastTask.latestDueDatePointer).add(1, 'minutes');
        const newTask = handleDatePointers(
            { ...lastTask, recurring: data.recurring, recurringUntil: data.recurringUntil },
            compareDate
        );
        if (
            moment(newTask.latestDatePointer).isSame(moment(lastTask.latestDatePointer)) ||
            moment(newTask.latestDatePointer).isAfter(maxDate)
        ) {
            break;
        }
        tasks.push({
            latestDatePointer: newTask.latestDatePointer,
            latestDueDatePointer: newTask.latestDueDatePointer,
        });
    }

    return tasks.filter(
        (task) => moment(task.latestDatePointer).isSameOrAfter(minDate) && moment(task.latestDatePointer).isSameOrBefore(maxDate)
    );
};