import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import { Accordion, Button, Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import { fetchWrapper } from '../../../../_helpers';
import { PeopleItem, StyledPeople } from './styles/PeopleStyles';
import { toast } from 'react-toastify';
import { BsCaretDownFill, BsCaretUpFill } from 'react-icons/bs';

function People({
	currUser,
	roomId,
	channelMembers,
	teamMembers,
	rooms,
	conferenceId,
	participants,
	channelInfo,
	handleMuteParticipant,
	isHost,
	handleRemoveParticipant,
}) {
	const [invitableMembers, setInvitableMembers] = useState([]);

	const [inCallMembers, setInCallMembers] = useState([]);

	useEffect(() => {
		let callParticipantArray = Object.values(participants)
			.filter((participant) => (participant.id || participant._id) !== currUser.id)
			.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
		// console.log(callParticipantArray, 'callParticipantArray');
		setInCallMembers(callParticipantArray);

		let callParticipantIDArray = callParticipantArray.map((participant) => participant.id || participant._id);
		callParticipantIDArray = [...callParticipantIDArray, currUser.id];
		let members;
		if (channelInfo?.type === 'public') {
			members = teamMembers;
		} else {
			members = channelMembers;
		}

		setInvitableMembers(members.filter((member) => !callParticipantIDArray.includes(member.id || member._id)));
	}, [channelInfo?.type, channelMembers, currUser.id, participants, teamMembers]);

	const handleInvite = async (channelMember) => {
		let userId = channelMember.id || channelMember._id;
		try {
			await fetchWrapper.post('conference/invite', {
				conferenceId: conferenceId,
				userIds: [userId],
			});
			toast.info('Invitation sent successfully!');
		} catch (e) {}
	};
	const handleInviteAll = async () => {
		try {
			let invitableMembersId = invitableMembers.map((member) => member._id || member.id);
			await fetchWrapper.post('conference/invite', {
				conferenceId: conferenceId,
				userIds: invitableMembersId,
			});
			toast.info('Invitation sent successfully!');
		} catch (e) {
			console.log(e);
		}
	};
	const [isInCallOpen, setIsInCallOpen] = useState(true);
	const [isInviteOpen, setIsInviteOpen] = useState(true);

	const removeParticipant = (participantId) => {
		let tempInCallMembers = [...inCallMembers];
		let removedParticipant;
		tempInCallMembers = tempInCallMembers.filter((participant) => {
			if ((participant.id || participant._id) === participantId) {
				removedParticipant = participant;
				return false;
			}
			return true;
		});
		setInCallMembers(tempInCallMembers);
		setInvitableMembers([...invitableMembers, removedParticipant]);
		handleRemoveParticipant(participantId);
	};
	return (
		<StyledPeople>
			<div>
				<Accordion defaultActiveKey="0">
					<Accordion.Toggle
						as={Card.Header}
						className="cursor-pointer"
						variant="link"
						eventKey="0"
						onClick={() => setIsInCallOpen(!isInCallOpen)}
					>
						<h2 className="text-left d-flex justify-content-between">
							<span>In Call</span>
							<span>{isInCallOpen ? <BsCaretDownFill /> : <BsCaretUpFill />}</span>
						</h2>
					</Accordion.Toggle>

					<Accordion.Collapse eventKey="0">
						<div>
							<div
								css={css`
									max-height: 55vh;
									overflow-y: auto;
									padding: 0 1rem;
								`}
								key={participants}
							>
								{inCallMembers?.map((participant) => {
									return (
										<>
											<PeopleItem
												key={participant.id}
												people={participant}
												isHost={isHost}
												handleRemoveParticipant={removeParticipant}
												handleMuteParticipant={handleMuteParticipant}
											/>
										</>
									);
								})}
							</div>
						</div>
					</Accordion.Collapse>
				</Accordion>
				<Accordion defaultActiveKey="0">
					<Accordion.Toggle
						as={Card.Header}
						className="cursor-pointer"
						variant="link"
						eventKey="0"
						onClick={() => setIsInviteOpen(!isInviteOpen)}
					>
						<h2 className="text-left d-flex justify-content-between">
							<span>Invite</span>
							<span>{isInviteOpen ? <BsCaretDownFill /> : <BsCaretUpFill />}</span>
						</h2>
					</Accordion.Toggle>

					<Accordion.Collapse eventKey="0">
						<div>
							<div
								css={css`
									max-height: 55vh;
									overflow-y: auto;
									padding: 0 1rem;
								`}
							>
								{invitableMembers?.length ? (
									<div
										css={css`
											display: flex;
											justify-content: flex-end;
											margin-top: 1rem;
										`}
									>
										<Button className="BlueButton" onClick={handleInviteAll}>
											Invite all
										</Button>
									</div>
								) : null}

								{invitableMembers
									.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
									.map((channelMember) => (
										<PeopleItem
											key={channelMember.id || channelMember._id}
											people={channelMember}
											handleInvite={handleInvite}
										/>
									))}
								{/* {currRoom && (currRoom.type === 'private' || currRoom.type === 'gps_enabled') ? (
									<div id="style-1" className="option-members-content">
										{channelMembers.length > 0 &&
											channelMembers
												.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
												.map((channelMember) => (
													<div
														className="d-flex align-items-center justify-content-between py-2"
														key={channelMember._id}
													>
														<div
															css={css`
																flex: 1;
																display: flex;
																justify-content: space-between;
															`}
														>
															<div
																css={css`
																	position: relative;
																	width: max-content;
																	height: fit-content;
																`}
															>
																{channelMember && (
																	<AvatarUserPanel item={channelMember} />
																)}
															</div>

															<span
																className="px-2"
																css={css`
																	color: #6c757d;
																`}
															>
																{channelMember.name}
															</span>
														</div>
													</div>
												))}
									</div>
								) : (
									<div id="style-1" className="option-members-content">
										{teamMembers.length > 0 &&
											teamMembers
												.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
												.map((teamMember) => (
													<div
														className="d-flex align-items-center justify-content-between"
														key={teamMember._id}
													>
														<div
															css={css`
																position: relative;
																width: max-content;
																height: fit-content;
															`}
														>
															{teamMember && (
																<AvatarUserPanel item={teamMember} onId={true} />
															)}
														</div>
														<span
															className="px-4"
															css={css`
																color: #6c757d;
															`}
														>
															{teamMember.name}
														</span>
													</div>
												))}
									</div>
								)} */}
							</div>
						</div>
					</Accordion.Collapse>
				</Accordion>
			</div>
		</StyledPeople>
	);
}
const mapStateToProps = (state) => ({
	currUser: state.user.user,
	rooms: state.rooms.teamRooms,
	teamMembers: state.teams.teamMembers,
	channelMembers: state.rooms.channelMembers,
});

export default connect(mapStateToProps)(People);
