import React from 'react';

import AvatarUserPanel from '../Common/AvatarUserPanel';

export const InviteToTeamContent = ({ user }) => {
	return (
		<div className="d-flex flex-column align-items-center">
			<AvatarUserPanel item={user} large={true}/>
			<p className="my-3 font-weight-bold">{user.name}</p>
		</div>
	);
};

export default InviteToTeamContent;
