import { css } from 'twin.macro';
import { useState, useContext } from 'react';
import { connect, useSelector } from 'react-redux';
import StaticPreview from '../../Admin/Form/StaticPreview';
import { useForm } from 'react-hook-form';
import { fetchWrapper, getSequenceNumber, getUserRole, isJson } from '../../../_helpers';
import { updateTaskFormStatus } from '../../../_redux/actions/ActionTasks';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import { WebSocketContext } from '../../../_redux/actions/ActionSocket';

import { VscLoading } from 'react-icons/vsc';
import styled from '@emotion/styled';
import UserInRoom from '../../Admin/Tasks/UserInRoom';
import Swal from 'sweetalert2';

const FormSubmissionModal = ({
	user,
	form,
	roomId,
	currRoom,
	team,
	userId,
	handleClose,
	position,
	formType,
	getAllTasks,
	isSubmitting,
	setIsSubmitting,
	taskId,
	subTasks=[],
	updateTaskFormStatus,
	visitTemplateId
}) => {
	const [userData, setUserData] = useState([]);
	const channelMembers = useSelector((state) => state.rooms.channelMembers);
	const { register, handleSubmit, errors } = useForm({
		mode: 'onBlur',
	});
	const [selectedUserOption, setSelectedUserOption] = useState([]);
	const onSubmit = async (data) => {
		// event.preventDefault();
		setIsSubmitting(true);

		try {
			if (formType === 'sos') {
				const serviceId = JSON.parse(
					data[Object.keys(data)?.find((key) => key.startsWith('ServiceRadio'))] || {}
				).id;
				const availableUnits =
					currRoom?.client?.availableUnits?.find((clientUnitData) => clientUnitData?.service === serviceId)
						?.amount || 0;
				if (availableUnits < 10) {
					const title =
						availableUnits >= 0
							? `Client has only ${availableUnits} units for the selected service. Are you sure you want to continue?`
							: `Client has ${availableUnits * -1
							} credited units for selected service. Are you sure you want to continue?`;
					const result = await Swal.fire({
						title,
						icon: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Yes, continue',
					});
					if (!result.isConfirmed) {
						// setIsSubmitting(false);
						// handleClose();
						return;
					}
				}
				// if (currRoom?.client?.availableUnits < 0) {
				// }
			}
			const keys = Object.keys(data);
			const form_data = new FormData();
			for (let i = 0; i < keys.length; i++) {
				const formInfo = form.data.find((form) => form._id === keys[i].split('-')[1]);

				form_data.append(`fieldName[${i}]`, keys[i]);

				form_data.append(
					`value[${i}]`,
					typeof data[keys[i]] === 'object'
						? keys[i].split('-')[0] === 'Image' ||
							keys[i].split('-')[0] === 'File' ||
							keys[i].split('-')[0] === 'AudioFile'
							? data[keys[i]][0]
							: JSON.stringify(data[keys[i]])
						: formInfo.type === 'date'
							? data[keys[i]].replace(/\//g, '-')
							: data[keys[i]]
				);
				form_data.append(`type[${i}]`, formInfo ? formInfo.type : 'image');
				form_data.append(`label[${i}]`, formInfo ? formInfo.key : 'image');
				// Display the key/value pairs
				// for (var pair of form_data.entries()) {
				// 	global.log("key:" + pair[0] + ', Value: ' + pair[1]);
				// }
			}

			form_data.append(`formType`, formType ? formType : 'normal');
			form_data.append(`form`, form._id);
			form_data.append(`chatroom`, roomId);
			form_data.append(`user`, localStorage.getItem('userId'));
			form_data.append('visibleTo', getPermission());
			if(visitTemplateId){
				form_data.append('template', visitTemplateId);
			}

			if (formType === 'eos' || formType === 'sos') {
				if (!position) {
					toast.error('User Location is required! Please turn on location and refresh.');
					return;
				}
				if (!team.provider?._id) {
					toast.error('Oops! this team is not registered as a Provider Team. Please contact your Supervisor');
					// setIsSubmitting(false);
					return;
				}
				// if (!user.employee) {
				// 	toast.error(
				// 		'You must be Staff to submit Visit forms, Please contact Supervisor to add you as an Staff.'
				// 	);
				// 	setIsSubmitting(false);
				// 	return;
				// }
				if (!currRoom?.client?.id) {
					toast.error(
						'Oops! this channel is not registered as a Client channel. Please contact your Supervisor.'
					);
					// setIsSubmitting(false);
					return;
				}

				form_data.append(`SequenceID`, getSequenceNumber());
				form_data.append(`provider`, team.provider._id);
				form_data.append(`client`, currRoom.client.id);
			} else if (formType === 'multipleSubmission') {
				if (selectedUserOption.length === 0) {
					setIsSubmitting(false);
					return toast.error('Please select atleast one form participants');
				}
				for (var i = 0; i < selectedUserOption.length; i++) {
					form_data.append('submissionRequiredBy[]', selectedUserOption[i]);
				}
			} else if (!form.isLocationOptional) {
				if (!position  || !position?.coords ) {
					// setIsSubmitting(false);
					toast.error('User Location is required! Please turn on location and refresh.');
					return;
				}
			}
			if(position?.coords){
				form_data.append(
					`location`,
					JSON.stringify({ lat: position.coords.latitude, lng: position.coords.longitude })
				);
			}

			const response = await fetchWrapper.post('userFormData', form_data, true);

			setUserData(
				form.data.map((form) => ({
					...form,
					value: response.data.find((f) => form._id === f.fieldName.split('-')[1])?.value,
				}))
			);
			toast.success('Form Successfully submitted');
			if(response?.submissionType === "completed" && taskId && subTasks?.length){
				updateTaskFormStatus({formId:response?.form,userId:response?.user,taskId, subTasks})
			}
			if (getAllTasks) {
				window.location.reload();
			}
		} catch (e) {
			console.log(e, 'FORM SUBMITTED ERROR');
			toast.error(e.message);
		}
		finally {
			setIsSubmitting(false);
			if(handleClose){
				handleClose();
			}
		}
	};

	const getPermission = () => {
		const userRole = getUserRole(user, team._id || team.id, roomId)
		if (userRole === 'user') {
			return ['user']
		}
		let permission = window.localStorage.getItem('messagePermission');
		permission = isJson(permission) ? JSON.parse(permission) : {};
		return permission[roomId] || ['user']
	}

	const handleUserSelect = (userId) => {
		let tempSelectedUserOption;
		if (selectedUserOption.length !== 0) {
			tempSelectedUserOption = [...selectedUserOption];
		} else {
			tempSelectedUserOption = [];
		}
		let indexOfUserId = tempSelectedUserOption.indexOf(userId);
		let isUserPresent = Boolean(indexOfUserId !== -1);
		if (isUserPresent) {
			tempSelectedUserOption.splice(indexOfUserId, 1);
		} else {
			tempSelectedUserOption.push(userId);
		}

		setSelectedUserOption(tempSelectedUserOption);
	};

	return (
		<Form>
			<StaticPreview
				formElements={userData.length ? userData : form.data}
				elementRef={register}
				errors={errors}
			/>
			{formType === 'multipleSubmission' && (
				<div
					css={css`
						padding: 1.5rem 1rem 1.5rem 1.5rem;
					`}
				>
					Form participants
					<ParticipantsContainer className="border p-2 rounded ">
						{channelMembers?.length > 0 ? (
							channelMembers.map((member) => {
								let id = member.id || member._id;
								return (
									<UserInRoom
										key={id}
										user={member}
										onSelect={handleUserSelect}
										isSelected={selectedUserOption?.includes(id)}
									/>
								);
							})
						) : (
							<div>
								<h5>No channel members</h5>
								<p>Invite participants to the channel.</p>
							</div>
						)}
					</ParticipantsContainer>
				</div>
			)}
			<div className="text-center">
				<Button
					type="button"
					css={css`
						font-size: 16px;
						border-radius: 16px;
						margin-top: 1rem;
						padding: 0.5rem 2rem;
						width: 25%;
					`}
					disabled={userData.length || isSubmitting}
					onClick={handleSubmit(onSubmit)}
				>
					{isSubmitting ? <VscLoading className="spin" /> : 'Submit'}
				</Button>
			</div>
		</Form>
	);
};

const mapStateToProps = (state) => ({
	// MessagesFromRedux: state.messages,
	position: state.user.position,
	user: state.user.user,
});

export default connect(mapStateToProps, { updateTaskFormStatus })(FormSubmissionModal);

const ParticipantsContainer = styled.div`
	padding: 0.5rem;
	.userInRoom {
		box-sizing: border-box;
		width: 80%;
	}
`;
