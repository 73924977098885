import { css } from 'twin.macro';

import { Fragment, useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FaWpforms } from 'react-icons/fa';
import { toast } from 'react-toastify';

//components
import FormSubmissionModal from './FormSubmissionModal';
import ServiceCounter from './ServiceCounter';

//helpers
import { serviceToCounter } from '../../../_helpers';

//redux
import { useContextStore } from '../../../_context/contextStore';

const modalStyles = css`
	.modal-content {
		padding: 2rem 1rem;
	}
`;


function SubmissionModal(props) {
	const [show, setShow] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [counterArray,setCounterArray] = useState([])
	const {onGoingServices} = useContextStore();
	const [onGoingServicesArr, _ ] = onGoingServices;

	useEffect(()=>{
		setCounterArray(onGoingServicesArr?.map(serviceToCounter)?.filter(counterData => counterData) || []);
	},[onGoingServicesArr])

	useEffect(() => {
		if (props.checkIsShowing) {
			props.checkIsShowing(show);
		}
	}, [props, show]);
	const handleClose = () => {
		// if (isSubmitting) {
		// 	return null;
		// }
		if (props?.handleFormClosed) {
			props.handleFormClosed();
		}
		setShow(false);
	};
	const handleShow = () => {
		setCounterArray(onGoingServicesArr?.filter((service)=>(service?.roomId===props?.currRoom?._id || service?.roomId===props?.currRoom?.id))?.map(serviceToCounter)?.filter(counterData => counterData) || []);
		if (props.form.isLocationOptional) {
			setShow(true);
			if (props.handleFormOpened) {
				props.handleFormOpened();
			}
		} else {
			if (props.position && props.position?.coords) {
				setShow(true);
				if (props.handleFormOpened) {
					props.handleFormOpened();
				}
			} else {
				toast.error('User Location is required! Please turn on location and refresh');
			}
		}
	};
	return (
		<Fragment>
			<div onClick={handleShow} className="pointer d-flex" css={css`align-items: center; gap: 6px;`}>
				<FaWpforms />
				<span>{props.form.name}</span>
				<span className='badge badge-info'>{{ eos: 'end of service', sos: 'start of service', multipleSubmission: 'multiple submission', normal: 'normal' }[props.form.type]}</span>
			</div>
			<Modal
				show={show}
				onHide={handleClose}
				backdrop="static"
				css={modalStyles}
				aria-labelledby="contained-modal-title-vcenter"
				size="xl"
			>
				<Modal.Header closeButton>
					<h3>{props.form.name}</h3>
				</Modal.Header>
				<Modal.Body className=" position-relative">
					<div className="title mb-0 mr-4 d-flex align-items-center wrap"
					css={css`
					font-size: 1.8rem;
					display: flex;
					width: 100%;
					margin-left: 1.5rem;
					color: #757575;
					font-weight: bold; `} >
						{(counterArray && counterArray?.length > 0) ? "Ongoing Services :" : "" }
					</div>
					<div css={css`
						display: flex;
						${counterArray.length > 1 ? 'justify-content: start;' : 'justify-content: center;'} 
						align-items: center;
						padding: 1rem 10px;
						width: 100%;
						position: relative;
						overflow-x : auto;`}>
							{counterArray && counterArray.map((counter, counterIndex) => {
								let offset = counter?.offset || 0;
								const stopwatchOffset = new Date();
								stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + offset);
								return (
									<ServiceCounter
										key={counterIndex}
										counter={counter}
										offsetTimestamp={stopwatchOffset}
									/>
								);
							})}
					</div>
					<FormSubmissionModal handleClose={handleClose} isSubmitting={isSubmitting} setIsSubmitting={setIsSubmitting} {...props} />
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => {
						if (!isSubmitting) handleClose();
					}} disabled={isSubmitting}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</Fragment>
	);
}

export default SubmissionModal;
