import { Button, Modal } from 'react-bootstrap';
import { css } from 'twin.macro';
import { Fragment, useState } from 'react';
import { ToolTip } from '../../../../../_elements/ToolTip';
import { TbPlusMinus } from 'react-icons/tb';
import { ActionStyles } from '../../../reusableStyles';
import EmployeeEditDeductions from './Deductions/EmployeeEditDeductions';
import EmployeeNewDeductions from './Deductions/EmployeeNewDeductions';

export default function ZealEmployeeDeductionsModal({ companyID, employeeCheck, teamId }) {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const employeeCheckID = employeeCheck?.employeeCheckID;
    const [employeeDeductions, setEmployeeDeductions] = useState(employeeCheck?.deductions || []);

    return (
        <Fragment>
            <ToolTip
                text={'Edit'}
                side={'top'}
                cssStyles={'top:-70% !important; min-width: fit-content !important'}
            ></ToolTip>
            <ToolTip
                text={'Deductions'}
                side="right"
                cssStyles={css`
                    right: 42px !important;
                    top: 0 !important;
                `}
            >
                <ActionStyles>
                    <TbPlusMinus className="icon edit" onClick={handleShow} />
                </ActionStyles>
            </ToolTip>
            <Modal show={Boolean(show)} onHide={handleClose} centered scrollable backdrop="static" size="lg">
                <Modal.Header closeButton>
                    <Modal.Title
                        css={css`
                            width: 100%;
                            padding: 0 12px;
                        `}
                    >
                        <div
                            css={css`
                                display: flex;
                                flex-direction: column;
                            `}
                        >
                            <div
                                css={css`
                                    display: flex;
                                    gap: 12px;
                                    align-items: flex-end;
                                `}
                            >
                                <span
                                    className="badge badge-info badge-sm"
                                    css={css`
                                        font-size: 14px !important;
                                    `}
                                >
                                    Zeal Deductions
                                </span>
                            </div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ height: '80vh' }}>
                    <EmployeeEditDeductions
                        employeeCheckID={employeeCheckID}
                        prevEmployeeDeductions={employeeDeductions}
                        setEmployeeDeductions={setEmployeeDeductions}
                        teamId={teamId}
                    />
                    <EmployeeNewDeductions
                        employeeCheckID={employeeCheckID}
                        companyID={companyID}
                        setEmployeeDeductions={setEmployeeDeductions}
                        show={show}
                        teamId={teamId}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}
