import React, { useState, useEffect, Fragment } from 'react';
import { css } from 'twin.macro';
import { BiArrowBack } from 'react-icons/bi';
import dayjs from 'dayjs';
import PreviewEventModal from '../../Admin/Tasks/PreviewEventModal';
import { Form, Col, Button, Modal } from 'react-bootstrap';

import { AdminTable } from '../../Admin/reusableStyles';
import DateTimePicker from 'react-datetime-picker';
import { fetchWrapper } from '../../../_helpers';
import LoadingSpiner from '../../../_elements/LoadingSpiner';
import { toast } from 'react-toastify';
import { editTaskDate, getPrivateTaskPackage } from '../../../_redux/actions/ActionTasks';
import { connect } from 'react-redux';
// import DateTimePicker from './DateTimePicker';

const OnDemandEventAddModal = ({
	clickedEvent,
	addOrRemoveEvents,
	setShowEventId,
	editTaskDate,
	getPrivateTaskPackage,
}) => {
	const [demandedDateForAP, setDemandedDateForAP] = useState({});
	const [showModal, setShowModal] = useState(false);
	const [clickedEventPackage, setClickedEventPackage] = useState({});
	const [selectedTasks, setSelectedTasks] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	useEffect(() => {
		const fetchEventPackage = async (id) => {
			try {
				setIsLoading(true);
				const data = await getPrivateTaskPackage(id);
				setClickedEventPackage(data)
			} catch (err) {
				console.log(err);
			} finally {
				setIsLoading(false);
			}
		};
		if (clickedEvent?._id || clickedEvent?.id) {
			setClickedEventPackage(clickedEvent)
		} else {
			fetchEventPackage(clickedEvent);
		}
	}, [clickedEvent]);

	useEffect(() => {
		if (clickedEventPackage?.taskData) {
			setDemandedDateForAP(
				Object.fromEntries(
					clickedEventPackage?.taskData.map((task) => [
						task?._id,
						{
							demandDate: task?.demandDate ? new Date(task.demandDate) : new Date(),
							demandDueDate: task.demandDueDate ? new Date(task.demandDueDate) : new Date()
						},
					])
				)
			);
		}
		const refactorSelectedTasks = clickedEventPackage?.taskData?.map((task) => {
			let taskOption = {
				label: task.title,
				value: task._id,
				start: task.date,
				end: task.dueDate,
				...task,
			};
			return taskOption;
		});

		setSelectedTasks(refactorSelectedTasks);
	}, [clickedEventPackage]);

	const handleAddPackageToCalendar = async (id) => {
		try {
			setIsSubmitting(true);
			const dateValues = Object.values(Object.values(demandedDateForAP)).flat();
			dateValues.forEach((date) => {
				if (date === null || date === undefined) {
					throw new Error('The Date Field cannot be empty');
				}
			});
			const res = await fetchWrapper.put(`activityPackages/map/${id}`, { demandDates: demandedDateForAP });
			const demandTaskList = res.filter((task) => task?.eventMode === 'onDemand');

			demandTaskList.forEach((event) => {
				editTaskDate({
					packageData: {
						...event,
					},
					mode: 'add-calendar',
					fromPackage: true,
				});
			});

			setShowEventId('');
		} catch (err) {
			toast.error(err.message || err.error);
			console.log(err);
		} finally {
			setIsSubmitting(false);
		}
	};

	const handleRemovePackageFromCalendar = async (id) => {
		try {
			setIsSubmitting(true);
			const res = await fetchWrapper.put(`activityPackages/map/remove/${id}`);
			const demandTaskList = res.filter((task) => task?.eventMode === 'onDemand');

			demandTaskList.forEach((event) => {
				editTaskDate({
					packageData: {
						...event,
					},
					mode: 'remove-calendar',
					fromPackage: true,
				});
			});
			setShowEventId('');
		} catch (err) {
			toast.error(err.message || err.error);
			console.log(err);
		} finally {
			setIsSubmitting(false);
		}
	};

	const handleDateTimeChange = (eventId, date, dueDate) => {
		setDemandedDateForAP((prevDates) => ({
			...prevDates,
			[eventId]: {
				demandDate: date || prevDates[eventId]?.demandDate || new Date(),
				demandDueDate: dueDate || prevDates[eventId]?.demandDueDate || new Date(),
			},
		}));
	};

	const [fetchedTasks, setFetchedTasks] = useState([]);

	const handleFetchTask = async (id) => {
		if (!id || fetchedTasks.includes(id)) {
			return {};
		}
		const response = await fetchWrapper.get(`/task/${id}`);
		setSelectedTasks(prev => prev.map(task => task._id === response._id ? { ...task, ...response } : task));
		setFetchedTasks(prev => [...prev, id]);
		return response;
	}

	const handleShowEvent = async (id) => {
		setShowModal(id);
		handleFetchTask(id);
	};

	const handleEventClose = () => {
		setShowModal(false);
	};
	return (
		<Fragment>
			<Modal show={true} backdrop="static" keyboard={false} size="xl">
				<Modal.Header className="font-weight-bold d-flex justify-content-center align-items-center" css={css`font-size: 22px;`}>
					<BiArrowBack
						style={{ width: '50px', height: '36px', cursor: 'pointer', position: 'absolute', left: '0' }}
						onClick={() => {
							setShowEventId('');
						}}
						className="icon view"
					/>Events in Plan</Modal.Header>
				<Modal.Body>

					<Form.Group as={Col} md={12}>
						<Form.Label>Plan Name</Form.Label>
						<Form.Control type="text" value={clickedEventPackage?.name} name="packageName" readOnly />
						<Form.Label style={{ marginTop: '1rem' }}>Team Name</Form.Label>
						<Form.Control type="text" value={isLoading ? "Loading..." : (clickedEventPackage?.teamData?.length > 0 ? clickedEventPackage?.teamData[0]?.name : "")} name="teamName" readOnly />
					</Form.Group>
					<Form.Group as={Col} md={12}>
						<AdminTable className="mb-5 w-100">
							<thead>
								<tr>
									<th className='p-2'>S.N.</th>
									<th className='p-2'>Event Name</th>
									<th className='p-2'>Date</th>
									<th className='p-2'>Due Date</th>
								</tr>
							</thead>
							<tbody>
								{selectedTasks?.map((event, index) => {
									return (
										<tr key={event._id}>
											<td className="text-left p-2">{index + 1}</td>
											<td
												className="text-left p-2 pointer"
												style={{ alignItems: 'center', margin: '6px 0 0 0' }}
												onClick={() => handleShowEvent(event._id)}
												css={css`
																&:hover {
																	font-weight: 600;
																	color: #24627e;
																}
															`}
											>
												<span style={{ maxWidth: '312px' }}>{event.title}</span>
												<span className={`badge badge-${event?.eventMode === 'onDemand' ? 'warning' : 'danger'}`}
													style={{
														textTransform: 'lowercase',
														display: 'inline-flex',
														justifyContent: 'center',
														alignItems: 'center',
														height: '18px',
														marginLeft: '8px',
														background: event?.eventMode === 'onDemand' || dayjs(event.latestDueDatePointer || event.dueDate).isSameOrAfter() ? 'auto' : 'gray'
														// textAlign: 'center',
													}}
												>
													{event?.eventMode === 'onDemand' || dayjs(event.latestDueDatePointer || event.dueDate).isSameOrAfter() ? event?.eventMode : 'expired'}
												</span>
											</td>
											<td className="text-left p-2">
												{event?.eventMode === 'live' ? (
													dayjs(event.latestDatePointer || event.date).format('MM-DD-YYYY h:mm A')
												) : (
													<div style={{ fontSize: '14px' }}>
														<DateTimePicker
															minDate={new Date(Math.max(new Date(), new Date(event.latestDatePointer || event.date)))}
															// minTime={new Date(event.latestDatePointer || event.date)}
															// maxTime={new Date(event.latestDueDatePointer || event.dueDate)}
															onChange={(date) =>
																handleDateTimeChange(event._id, date, date && new Date(new Date(date)?.valueOf() + event.duration))
															}
															value={
																demandedDateForAP[event._id]?.demandDate
																	? new Date(demandedDateForAP[event._id]?.demandDate)
																	: null
															}
															disabled={addOrRemoveEvents === 'remove'}
															format="M-dd-y h:mm a"
															disableClock
														/>
													</div>
												)}
											</td>
											<td className="text-left p-2">
												{event?.eventMode === 'live' ? (
													dayjs(event.latestDueDatePointer || event.dueDate).format('MM-DD-YYYY h:mm A')
												) : (
													<div style={{ fontSize: '14px' }}>
														<DateTimePicker
															minDate={new Date(Math.max(new Date(), new Date(event.latestDueDatePointer || event.dueDate)))}
															onChange={(date) =>
																handleDateTimeChange(event._id, null, date)
															}
															value={
																demandedDateForAP[event._id]?.demandDueDate
																	? new Date(demandedDateForAP[event._id]?.demandDueDate)
																	: null
															}
															disabled
															// disabled={addOrRemoveEvents === 'remove'}
															format="M-dd-y h:mm a"
															disableClock
														/>
													</div>
												)}
											</td>
										</tr>
									);
								})}
							</tbody>
						</AdminTable>
						{showModal && selectedTasks.find(t => t._id === showModal) ? (
							<PreviewEventModal
								data={selectedTasks.find(t => t._id === showModal)}
								handleClose={handleEventClose}
							/>
						) : null}
					</Form.Group>

					{isLoading ? <LoadingSpiner /> : null}

					<div className="d-flex justify-content-end ">
						<Button
							disabled={isSubmitting || isLoading}
							onClick={() => {
								addOrRemoveEvents === 'remove'
									? handleRemovePackageFromCalendar(clickedEventPackage?._id)
									: handleAddPackageToCalendar(clickedEventPackage?._id);
							}}
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
							}}
						>
							{isSubmitting || isLoading ? <LoadingSpiner /> : null}
							{({ add: 'Add to Calendar', remove: 'Remove from Calendar', edit: 'Edit Dates in Calendar' })[addOrRemoveEvents]}
						</Button>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShowEventId('')}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</Fragment>
	);
};

// export default OnDemandEventAddModal;
const mapStateToProps = (state) => ({
	CurrUser: state.user.user,
});
export default connect(mapStateToProps, {
	editTaskDate,
	getPrivateTaskPackage
})(OnDemandEventAddModal);
