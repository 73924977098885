import React from 'react';
import { useState } from 'react';
import { CreateAProvider } from '../../../../_redux/actions/ActionEVV';
import { connect } from 'react-redux';
import ProviderForm from './ProviderForm';
import { Button } from 'react-bootstrap';
import EvvCreateModal from '../EvvCreateModal/EvvCreateModal';
import EvvCreateOptions from '../EvvCreateModal/EvvCreateOptions';
import CsvBulkUpload from '../EvvCreateModal/CsvBulkUpload';

const CreateProvider = ({ CreateAProvider }) => {
    const [showModal, setShowModal] = useState(false);
    const [createProviderMode, setCreateProviderMode] = useState(null);

    const [selectedOption, setSelectedOption] = useState();
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [teamAlreadyExists, setTeamAlreadyExists] = useState(false);
    const [selectError, setSelectError] = useState(null);
    const [submitLoading, setSubmitLoading] = useState(false);

    const handleModalShow = () => {
        setShowModal(true);
    };
    const handleHideModal = () => {
        setShowModal(false);
        setCreateProviderMode(null);
    };

    const handleGoBack = () => {
        setCreateProviderMode(null);
    };

    const onSubmit = async (data) => {
        if (teamAlreadyExists) {
            if (selectedTeam) {
                data = { ...data, team: selectedTeam?.value };
            } else {
                return setSelectError('Please select an team');
            }
        } else {
            if (selectedOption) {
                data = { ...data, admin: selectedOption?.id };
            } else {
                return setSelectError('Please select an owner');
            }
        }

        try {
            setSubmitLoading(true);
            let result = await CreateAProvider(data);
            if (result) {
                setShowModal(false);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setSubmitLoading(false);
        }
    };

    let content = null;
    switch (createProviderMode) {
        case 'individual':
            content = (
                <ProviderForm
                    onSubmit={onSubmit}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    selectError={selectError}
                    selectedTeam={selectedTeam}
                    setSelectedTeam={setSelectedTeam}
                    teamAlreadyExists={teamAlreadyExists}
                    setTeamAlreadyExists={setTeamAlreadyExists}
                    submitLoading={submitLoading}
                />
            );
            break;
        case 'bulk':
            content = <CsvBulkUpload type={'provider'} hideModal={handleHideModal} />;
            break;
        default:
            content = <EvvCreateOptions type={'provider'} setCreateProviderMode={setCreateProviderMode} />;
            break;
    }

    return (
        <>
            <Button variant="primary" onClick={handleModalShow}>
                Create New Provider
            </Button>
            {showModal && (
                <EvvCreateModal
                    type={'provider'}
                    showModal={showModal}
                    hideModal={handleHideModal}
                    content={content}
                    goBack={createProviderMode ? handleGoBack : null}
                />
            )}
        </>
    );
};
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { CreateAProvider })(CreateProvider);
