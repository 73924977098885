import * as ActionTypes from './ActionTypes';
import { fetchWrapper } from '../../_helpers';
import { toast } from 'react-toastify';

export const getAllActivity = () => async (dispatch) => {
	try {
		dispatch({ type: ActionTypes.LOADING_UI });
		const response = await fetchWrapper.get('activity?limit=10&page=1');
		dispatch({ type: ActionTypes.SET_ALL_ACTIVITY, payload: response });
		dispatch({ type: ActionTypes.STOP_LOADING_UI });
	} catch (e) {
		global.log(e);
		dispatch({ type: ActionTypes.STOP_LOADING_UI });
	}
};

// export const postActivity = (activity) => async (dispatch) => {
// 	await fetchWrapper.post('activity', activity);
// };

export const setNewActivity = (newActivity) => ({ type: ActionTypes.SET_NEW_ACTIVITY, payload: newActivity });

export const clearActivities = () => async (dispatch) => {
	await fetchWrapper.delete('activity/clear');
	dispatch({ type: ActionTypes.CLEAR_ACTIVITIES });
};
export const markasReadActivity = (id) => async (dispatch) => {
	try {
		await fetchWrapper.patch(`/activity/clear/${id}`, { unRead: false });
		dispatch({ type: ActionTypes.MARK_AS_READ_ACTIVITY, payload: id });
	} catch (err) {
		console.log(err);
		toast.error('Error while deleting');
	}
};
export const clearActivity = (id) => async (dispatch) => {
	try {
		await fetchWrapper.delete(`/activity/clear/${id}`);
		dispatch({ type: ActionTypes.CLEAR_ACTIVITY, payload: id });
		toast.success('Activity cleared Successfully!');
	} catch (err) {
		console.log(err);
		toast.error('Error while deleting');
	}
};
export const markAllAsReadActivities = () => async (dispatch) => {
	fetchWrapper.patch('activity/clear').then(async (res) => {
		const response = await fetchWrapper.get('activity?limit=10&page=1');
		dispatch({ type: ActionTypes.SET_ALL_ACTIVITY, payload: response });
		dispatch({ type: ActionTypes.MARK_ALL_AS_READ_ACTIVITIES });
	});
};

export const setActivityMessage = (newActivityMessage) => ({
	type: ActionTypes.SET_ACTIVITY_MESSAGE,
	payload: newActivityMessage,
});
export const clearActivityMessage = () => ({ type: ActionTypes.CLEAR_ACTIVITY_MESSAGE });
