import { Fragment, useState } from 'react';
import { fetchWrapper } from '../../../../_helpers';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import { VscLoading } from 'react-icons/vsc';
import { css } from 'twin.macro';
import { MdOutlineError } from 'react-icons/md';
import { FaCheckCircle } from 'react-icons/fa';

const SandataVisitStatusCheck = ({ sandataUUID }) => {
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState(null);
    const handleFetchVisitStatus = async () => {
        try {
            if (!sandataUUID) {
                return toast.error('UUID is not present in this visit');
            }
            setLoading(true);
            const res = await fetchWrapper.get(`evv/sandata-status/visit?uuid=${sandataUUID}`);
            const objectRes = Array.isArray(res) ? res[0] : res;
            setResponse(objectRes);
            if (!['failed', 'success']?.includes(res?.status?.toLowerCase())) {
                toast.error('Something went wrong. Please try again later');
            }
        } catch (err) {
            console.log('err', err);
            if (err.name === 'AbortError') return;
            toast.error(err.message || 'Something went wrong');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <div
                className="d-flex flex-row justify-content-between mb-3"
                css={css`
                    align-items: flex-end;
                `}
            >
                <div className="d-flex">
                    <strong>Sandata Status:</strong>
                    {response?.status?.toLowerCase() === 'success' ? (
                        <span className="ml-2">
                            <FaCheckCircle color="green" className="mb-2 mr-1" />
                            {'SUCCESS'}
                        </span>
                    ) : null}
                    {response?.status?.toLowerCase() === 'failed' ? (
                        <span className="ml-2">
                            <MdOutlineError color="red" className="mb-2 mr-1" />
                            {'FAILED'}
                        </span>
                    ) : null}
                </div>

                <div>
                    <Button disabled={loading} onClick={handleFetchVisitStatus}>
                        {loading ? (
                            <VscLoading className="spin" width={24} height={24} style={{ cursor: 'not-allowed' }} />
                        ) : (
                            `Check ${response ? 'Again' : ''}`
                        )}
                    </Button>
                </div>
            </div>
            {!response ? null : (
                <Fragment>
                    <p>
                        <strong className="mr-2">Message Summary:</strong>
                        <span
                            css={css`
                                color: ${response?.status?.toLowerCase() === 'failed' ? '#ff0000' : ''};
                            `}
                        >
                            {response?.messageSummary || '-'}
                        </span>
                    </p>
                    {!(Array.isArray(response?.data) ? response?.data[0]?.reason : response?.data?.reason) ? null : (
                        <p>
                            <strong className="mr-2">Reason:</strong>
                            {(Array.isArray(response?.data) ? response?.data[0]?.reason : response?.data?.reason) || ''}
                        </p>
                    )}
                    {!(Array.isArray(response?.data)
                        ? response?.data[0]?.ErrorMessage
                        : response?.data?.ErrorMessage) ? null : (
                        <p>
                            <strong className="mr-2">Error Message:</strong>
                            <span style={{ color: '#ff0000' }}>
                                {(Array.isArray(response?.data)
                                    ? response?.data[0]?.ErrorMessage
                                    : response?.data?.ErrorMessage) || ''}
                            </span>
                        </p>
                    )}
                </Fragment>
            )}
            <hr />
        </div>
    );
};

export default SandataVisitStatusCheck;
