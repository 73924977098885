import React from 'react';
import { getInitials, getRandomColor } from '../../../_helpers';
// import { Image, Placeholder } from 'cloudinary-react';
import { css } from 'twin.macro';
import OnlineIndicator from '../Chat/OnlineIndicator';

import ArchivedAvatar from '../../../assets/archivedUser.svg';
import { AiOutlineUserDelete } from 'react-icons/ai';
import { Image } from 'react-bootstrap';

const AvatarUserPanel = ({ item, large, imageWidth, indicator, customCss }) => {
	const wrapper = css`
		cursor: pointer;
		border-radius: 50%;
		position: relative;
		object-fit: cover;
		background-color: #e0f0ef;
		width: ${large ? `7rem` : `3.5rem`};
		height: ${large ? `7rem` : `3.5rem`};
		.avatarimg {
			height: 100%;
			background-color: #e0f0ef;
			width: 100%;
			border-radius: 50%;
		}
		${customCss}
	`;

	if (item?.status === 'archive') {
		return (
			<div css={wrapper}>
				<img src={ArchivedAvatar} css={wrapper} alt="profile" />
				<span
					css={css`
						display: inline-block;
						width: 1.8rem;
						height: 1.8rem;
						position: absolute;
						background: #fa3939;
						border-radius: 50%;
						left: 72%;
					`}
				>
					<AiOutlineUserDelete
						color="white"
						css={css`
							position: absolute;
							top: 50%;
							left: 50%;
							width: 1.5rem;
							height: 1.5rem;
							transform: translate(-50%, -50%);
						`}
					/>
				</span>
			</div>
		);
	} else if (!item) {
		return (
			<div css={wrapper}>
				<img src={ArchivedAvatar} css={wrapper} alt="profile" />
			</div>
		);
	} else {
		return item?.avatar ? (
			<div css={wrapper}>
				<img src={item?.avatar} css={wrapper} alt="profile" />
				<div>{indicator ? <OnlineIndicator user={item} /> : null}</div>

			</div>
		) : (
			<div
				css={css`
					position: relative;
					border-radius: 50%;
					height: ${large ? `7rem` : `3.5rem`};
					width: ${large ? `7rem` : `3.5rem`};
					${customCss}
				`}
			>
				<div
					className="rounded-circle"
					css={css`
						display: flex;
						justify-content: center;
						align-items: center;
						border-radius: 50%;
						height: ${large ? `7rem` : `3.5rem`};
						width: ${large ? `7rem` : `3.5rem`};
					`}
					style={{ background: getRandomColor(item?._id ? item?._id : item?.id) }}
				>
					<span className="text-white">{getInitials(item?.name)}</span>
				</div>
				<div>{indicator ? <OnlineIndicator user={item} /> : null}</div>
			</div>
		);
	}
};

export default AvatarUserPanel;
