import React, { useState } from 'react';
import { css } from 'twin.macro';

import { connect } from 'react-redux';
import { Button, Modal, FormControl, FormLabel } from 'react-bootstrap';
import { StyledForm } from './styles';

import Select from 'react-select';

import { toast } from 'react-toastify';

import TeamSelectFilter from '../TeamSelectFilter';
import { fetchWrapper } from '../../../_helpers';

const tagTypes = {
	form: 'Form',
	event: 'Event',
	file: 'File',
	userform: 'Form Submission',
	all: 'all',
};

const getOptionFromData = (data = {}, noun) => ({
	label: data.name || data.title || data.label || 'Select a ' + noun,
	value: data.id || data._id || data.value || '',
});

const TagForm = ({
	mode = 'create',
	tagData = {},
	afterSubmit = async () => {},
	ButtonContent = 'Create Tag',
	buttonVariant = 'primary',
}) => {
	const [data, setData] = useState({
		type: { value: tagData.type || 'all', label: tagTypes[tagData.type || 'all'] },
		team: getOptionFromData(tagData.team, 'Team'),
		name: tagData.name,
	});

	const [isSubmitting, setIsSubmitting] = useState(false);

	const [showModal, setShowModal] = useState(false);

	const handleModalShow = () => {
		setShowModal(true);
	};

	const handleHideModal = () => {
		setShowModal(false);
	};

	const onSubmit = async () => {
		try {
			setIsSubmitting(true);
			if (!data.name) {
				return toast.error('Tag Name is Required');
			}
			const postData = {
				_id: tagData._id || `NEW_TAG_${Date.now()}`,
				...(data.team?.value ? { team: data.team?.value } : {}),
				name: data.name,
				type: data.type?.value || 'all',
			};
			const response = await fetchWrapper.post(`/tags`, [postData]);
			await afterSubmit(response);
			setShowModal(false);
		} catch (error) {
			toast.error(error.message || 'Something went wrong');
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<>
			<Button variant={buttonVariant} onClick={handleModalShow}>
				{ButtonContent}
			</Button>
			{showModal && (
				<Modal show={showModal} onHide={handleHideModal} centered backdrop="static">
					<Modal.Header closeButton>
						<div className="header">
							<h4
								className="d-flex align-items-center "
								css={css`
									margin: 0;
								`}
							>
								{{ create: 'Create', edit: 'Edit' }[mode]} Tag
							</h4>
						</div>
					</Modal.Header>
					<Modal.Body className="modal-body">
						<StyledForm onSubmit={(event) => event.preventDefault()}>
							<FormLabel>Team</FormLabel>
							<TeamSelectFilter
								selectedTeamOption={data.team}
								setSelectedTeamOption={(option) => setData((prev) => ({ ...prev, team: option }))}
								disabled={isSubmitting}
							/>
							<FormLabel>Type</FormLabel>
							<Select
								options={[
									{ label: 'Form', value: 'form' },
									{ label: 'Event', value: 'event' },
									{ label: 'File', value: 'file' },
									{ label: 'Form Submission', value: 'userform' },
									{ label: 'All', value: 'all' },
								]}
								value={data.type}
								onChange={(option) => setData((prev) => ({ ...prev, type: option }))}
								isDisabled={isSubmitting}
							/>
							<FormLabel>Name</FormLabel>
							<FormControl
								value={data.name}
								onChange={(e) => {
									setData((prev) => ({ ...prev, name: e.target.value }));
								}}
								placeholder="eg: TAG123"
								disabled={isSubmitting}
							></FormControl>
						</StyledForm>
						<div
							css={css`
								display: flex;
								padding: 12px 0 0;
								justify-content: flex-end;
							`}
						>
							<Button onClick={onSubmit} disabled={isSubmitting}>
								Submit
							</Button>
						</div>
					</Modal.Body>
				</Modal>
			)}
		</>
	);
};
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(TagForm);
