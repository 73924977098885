import React from 'react';
import Select from 'react-select';
import { stateList } from './_data/states';

function StateSelector({ selectedState, setSelectedState, isDisabled }) {
	const handleSelect = (state) => {
		setSelectedState(state);
	};
	return (
		<Select
			key={selectedState}
			name="provider"
			options={stateList}
			className="basic-multi-select"
			classNamePrefix="select"
			onChange={handleSelect}
			value={selectedState}
			isDisabled={isDisabled}
		/>
	);
}

export default StateSelector;
