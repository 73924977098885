import styled from "@emotion/styled";

export const CalenderStyles = styled('div')`
	--grey-100: #e4e9f0;
	--grey-200: #cfd7e3;
	--grey-300: #b5c0cd;
	--grey-800: #3e4e63;
	--grid-gap: 1px;
	--day-label-size: 20px;
	padding: 1rem;
	display:flex;
	flex-direction: column;
	margin-top:30px;

	ul {
		list-style-type: none;
	}

	.month {
		width: 100%;
		text-align: center;
		margin-bottom:5px;
	}

	.month ul {
		margin: 0;
		padding: 0;
	}

	.month ul li {
		text-transform: uppercase;
		letter-spacing: 3px;
		font-size:16px;
	}

	.month .prev,
	.month .next
	 {
		float: right;
		background: #e6e6e6;
		margin: 0 0 0 1rem;
		height:30px;
		width:30px;
		padding: 0.2rem 0.5rem;
		border-radius: 50%;
		border:1px solid grey;
	}

	.month .today {
		float: right;
		background: #e6e6e6;
		margin: 0 0 0 1rem;
		padding: 0.2rem 0.5rem;
		border-radius: 8px;
		border:1px solid grey;
	}

	.cal-nav-btn {
		transition: 250ms background-color, 250ms color;
		cursor: pointer;
	}
	.cal-nav-btn:hover {
		background-color: #afafaf;
		color: #fff;
	}
	.calender-container {
		padding: 30px 0;
		background: #E6E6E6;
		border:1px solid grey;
		margin: 0;
		border-radius: 16px;
		box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
	}

	.days,
	.weekdays {
		/* 7 equal columns for weekdays and days cells */
		display: grid;
		grid-template-columns: repeat(7, 1fr);
	}

	.weekdays {
		/* Position the weekday label within the cell */
		text-align: center;
		padding-right: 5px;
		width: 100%;
		height: 10%;
		font-weight: 900;
	}
	.days > * {
		/* Position the weekday label within the cell */
		padding-right: 0 2.5px;
		display: grid;
		place-items: center;
	}
	.bgDate {
		width: 2.5em;
		height: 2.5em;
		border-radius: 1000px;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: border-color 250ms, transform 250ms;
		border: 2px solid;
		border-color: transparent;
		cursor: pointer;
		font-size: 2.5vmin;
	}
	@media screen and (max-width: 1920px) {
		.selected-month {
			font-size: 24px;
		}
	}
	.bgDate:hover {
		border-color: #afafaf;
		transform: scale(1.1);
	}
	.days {
		height: 90%;
		gap: 2% 0;
		position: relative;
		/* Show border between the days */
		grid-gap: 10px 0;
	}
	.not-in-this-month {
		color: #aeaeae;
	}
	.date-today {
		color: #fff;
		background-color: #123e53 !important;
	}
	.selected-day {
		color: #123e53;
		background-color: #fff !important;
	}
	#selected-month {
		font-size: 18px;
		font-weight:bold;
		float:left;
		color:#757575;
		margin-left:15px;
	}
	.low-events > span {
		border-bottom: 3px solid #55b617;
	}
	.low-events:hover {
		border-color: #55b617;
	}
	.low-events:hover > span {
		border-color: transparent;
	}
	.medium-events > span {
		border-bottom: 3px solid #eb9636;
	}
	.medium-events:hover {
		border-color: #eb9636;
	}
	.medium-events:hover > span {
		border-color: transparent;
	}
	.high-events > span {
		border-bottom: 3px solid #dc3a3a;
	}
	.high-events:hover {
		border-color: #dc3a3a;
	}
	.high-events:hover > span {
		border-color: transparent;
	}
	.eventsLabel {
		margin-top: 10px;
		font-size: 16px;
		display:flex;
		flex-direction: row;
	}
	.eventsLabel > div {
		display: flex;
		align-items: center;
		padding: 0 10px;
	}
	.eventLabel {
		border-bottom: 5px solid;
		width: 20px;
		margin-right:10px;
	}
	.low_events {
		border-color: #55b617;
	}
	.medium_events {
		border-color: #eb9636;
	}
	.high_events {
		border-color: #dc3a3a;
	}
`;