import React, { useState, useEffect, useRef, Fragment } from 'react';
import { emojiIndex } from 'emoji-mart';
import { ReactTinyLink } from 'react-tiny-link';
import NoImg from '../assets/noImg.svg';
import ViewForm from '../components/Admin/Form/ViewForm';
import { useSelector } from 'react-redux';
import { css } from 'twin.macro';
import { AiOutlineDownCircle, AiOutlineUpCircle } from 'react-icons/ai';

let formId = false;

const checkMentions = (message) => {
	let mentionAllRegex = /(@all)+/g;
	if (mentionAllRegex.test(message)) {
		message = message.replace(mentionAllRegex, `<a class="mention_a" href="#">@all</a>`);
	}
	return message.replace(/\[([^[]+)\]\(([^)]+)\)/g, `<a class="mention_a" href="#/id/$1">@$2</a>`);
};

const checkForm = (message) => {
	let markdownRegex = /###([^ ]+?)## (\$\$(.+)?\$)/;
	return message.replace(markdownRegex, function (markdown) {
		let tempMarkDown;
		if (markdown.trim()) {
			try {
				formId = markdown.split('###')[1].split('##')[0];
			} catch (error) {
				console.log(error);
			}

			tempMarkDown = markdown
				.split('###')
				.join(
					`<i class="fa fa-file-text-o text-primary " style="font-size: 3vmin"></i><a style='font-size: 1.8rem; font-weight: bold' class="form_a" href='#/form/`
				);
			tempMarkDown = tempMarkDown.split('##').join(`'>`);
			tempMarkDown = tempMarkDown.split('$$').join(' ');
			tempMarkDown = tempMarkDown.split('$').join('</a>');
			return tempMarkDown;
		}
	});
};

export const getIdFromMention = (message) => {
	let markdownRegex = /\[([^ ]+?)\]\((.+)?\)/;
	let tempMessage = message.match(markdownRegex);
	if (tempMessage) {
		return tempMessage[1];
	}
};
let TinyURL = null;

function urlify(message) {
	try {
		let tempMessage;
		let formRegex = /###([^ ]+?)## (\$\$(.+)?\$)/;
		if (message.match(formRegex)) {
			tempMessage = checkForm(message);
		} else {
			tempMessage = checkMentions(message);
		}
		let urlRegex =
			/(?:(?:https?|ftp|file|http):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/gim;
		// It does not match email addresses
		// It does match localhost:12345
		// It won't detect something like moo.com without http or www

		// old regex,
		// /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w\-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/;

		return tempMessage.replace(urlRegex, function (url) {
			let urlTemp;
			if (url.trim()) {
				TinyURL = url;
				if (url.trim().substring(0, 4) !== 'http') {
					urlTemp = `http://${url.trim()}`;
					TinyURL = urlTemp;
					return `<div>
								<a class="message-link" href='${urlTemp}' target="_blank" rel="no-refresh" >${url}</a>
							</div>`;
				}
				return `<a class="message-link" href='${url}' target="_blank" rel="no-refresh" >${url}</a>`;
			}
		});
	} catch (err) {
		console.log(err, 'err');
	}
}

export const SanitizeHTML = ({ html, options }) => {
	formId = false;
	const isSearch = useSelector((state) => state.messages.isSearch);
	const [isTruncated, setIsTruncated] = useState(true);
	const [overflowMessage, setOverFlowMessage] = useState(false);

	const contentContainerRef = useRef(null);
	const contentInnerRef = useRef(null);

	useEffect(() => {
		const checkOverflow = () => {
			const contentContainerElement = contentContainerRef?.current;
			const contentInnerElement = contentInnerRef?.current;
			if (contentContainerElement && contentInnerElement) {
				if (contentInnerElement?.scrollHeight > 300) {
					setOverFlowMessage(true);
					setIsTruncated(true);
				} else {
					setOverFlowMessage(false);
					setIsTruncated(false);
				}
			}
		};

		checkOverflow();
		window.addEventListener('resize', checkOverflow);

		return () => {
			window.removeEventListener('resize', checkOverflow);
		};
	}, []);

	if (html) {
		TinyURL = null;
		let string = urlify(html);

		if(string?.startsWith("/quinn")){
			string = string.replace("/quinn", `<span class="badge badge-dark" style="font-size:16px;border-radius:5px;padding:1px 4px;background-color:#343a4082;">Quinn</span>`)
		}
		// else if(string?.startsWith("/summarize")){
		// 	string = string.replace("/summarize", `<span class="badge badge-dark" style="font-size:16px;border-radius:5px;padding:1px 4px;background-color:#343a4082;">Summarize</span>`)
		// }

		const contentStyle = {
			display: '-webkit-box',
			WebkitBoxOrient: 'vertical',
			overflow: overflowMessage && 'hidden',
			textOverflow: overflowMessage && 'ellipsis',
			WebkitLineClamp: overflowMessage && isTruncated ? 12 : 'unset',
		};

		return (
			<span className="tinyCustom">
				<div className="pre-wrap" style={contentStyle} ref={contentContainerRef}>
					<div ref={contentInnerRef}>
						<span dangerouslySetInnerHTML={{ __html: string }} />
					</div>
				</div>
				{isSearch && formId && (
					<div>
						<ViewForm key={formId} formId={formId} showSignature={true} />
					</div>
				)}

				{overflowMessage && (
					<Fragment>
						<br />
						<button
							className="btn btn-light"
							css={css`
								color: #2e9a9c !important;
								border: 1px #2e9a9c !important;
								border-radius: 7px;
								font-size: 1.4rem;
								margin-top: 0.5rem;
							`}
							onClick={() => setIsTruncated((current) => !current)}
						>
							{isTruncated ? (
								<Fragment>
									Show more <AiOutlineDownCircle />
								</Fragment>
							) : (
								<Fragment>
									Show less <AiOutlineUpCircle />
								</Fragment>
							)}
						</button>
					</Fragment>
				)}

				{TinyURL && (
					<ReactTinyLink
						defaultMedia={NoImg}
						proxyUrl="https://cors.bridged.cc"
						cardSize="large"
						showGraphic={true}
						maxLine={2}
						minLine={1}
						url={TinyURL}
						header={TinyURL}
						onError={(err) => {
							console.log(err);
						}}
					/>
				)}
			</span>
		);
	} else {
		return null;
	}
};
