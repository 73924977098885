import styled from '@emotion/styled';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { BsUpload } from 'react-icons/bs';
import { RiFileList3Line } from 'react-icons/ri';

function EvvCreateOptions({ type, setCreateProviderMode }) {
	return (
		<Container>
			<Row className="row">
				<Col sm={12} md={6}>
					<StyledOption>
						<div className="circle-btn" onClick={() => setCreateProviderMode('individual')}>
							<RiFileList3Line />
						</div>
						<div className="btn-label">Create a single {type}</div>
					</StyledOption>
				</Col>
				<Col sm={12} md={6}>
					<StyledOption>
						<div className="circle-btn" onClick={() => setCreateProviderMode('bulk')}>
							<BsUpload />
						</div>
						<div className="btn-label">Create {type} in bulk by uploading a csv file</div>
					</StyledOption>
				</Col>
			</Row>
		</Container>
	);
}

export default EvvCreateOptions;

const StyledOption = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.circle-btn {
		width: 150px;
		height: 150px;
		border: 1px solid #e6e6e6;
		border-radius: 50%;
		background-color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 1rem;
		cursor: pointer;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
		svg {
			font-size: 32px;
			color: #666;
		}
		transition: background-color 220ms;
		:hover {
			background-color: #50aeb0;
			svg {
				color: white;
			}
		}
	}
	.btn-label {
		font-size: 14px;
		color: #292929;
		font-weight: 600;
		text-align: center;
	}
`;
