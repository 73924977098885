import React, { Fragment } from 'react';
import './css/ActivityProgressBar.css';

const convertToHundred = (completedTasks, totalTasks) => {
	return (completedTasks / totalTasks) * 100;
};
const ProgressBar = ({ fraction, totalTasks, userName }) => {
	return (
		<div className="progressbar-item">
			<div className="username">{userName}</div>
			<progress
				id="file"
				value={convertToHundred(fraction, totalTasks)}
				max="100"
				className="progresbar"
			></progress>
			<span>
				({fraction}/{totalTasks})
			</span>
		</div>
	);
};
const ActivityProgressBar = ({ selectedTasks, assignedUsers }) => {
	const fractionOfTaskCompletedByUser = (userId) => {
		if (!selectedTasks) return 0;

		let count = 0;
		selectedTasks.forEach(({ status }) => {
			if (status.includes(userId)) {
				count = count + 1;
			}
		});

		return count;
	};
	const getUserName = (userId) => assignedUsers?.find((userData) => userData?.id === userId)?.name;

	const totalTasks = selectedTasks.length;

	return (
		<Fragment>
			<span style={{ fontSize: '1.6rem' }}>Progress Status</span>
			<hr />
			{selectedTasks?.length > 0 ? (
				<Fragment>
					{selectedTasks[0]?.assignedUser?.map((userId) => (
						<div
							className="progressbar-list"
							key={userId}
						// style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}
						>
							<ProgressBar
								userId={userId}
								userName={getUserName(userId)}
								fraction={fractionOfTaskCompletedByUser(userId)}
								totalTasks={totalTasks}
							/>
						</div>
					))}
				</Fragment>
			) : null}
		</Fragment>
	);
};
export default ActivityProgressBar;
