import React, { useState, useContext, useEffect } from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { css } from 'twin.macro';

//components
import SideBar from '../../../Features/Layouts/SideBar/SideBar';
import { WebSocketContext } from '../../../../_redux/actions/ActionSocket';
import MessageListener from '../../../Features/Common/MessageListener';
import { fetchWrapper } from '../../../../_helpers';
import EventChannelChat from './EventChannelChat';
//redux
import { connect } from 'react-redux';
import {
	getAllSubChannels,
	getSubChannelMessages,
	clearMessages,
	clearSearchMessages,
	getLastSeenSubChannelMessage,
} from '../../../../_redux';

//styles
import {layout} from '../../../../components/Features/Chat/styles/ChatContainerStyles'
import { BsFillCaretLeftFill } from 'react-icons/bs';
import EventChannelList from './EventChannelList';

const EventChannelContainer = ({
	currUser,
	rooms,
	position,
	subChannels,
	MessagesFromRedux,
	getAllSubChannels,
	getSubChannelMessages,
	clearMessages,
	clearSearchMessages,
	match,
	history,
	getLastSeenSubChannelMessage,
	messages,
}) => {
	const [mobileSidebar, setMobileSidebar] = useState(false);
	const subChannelId = match.params.subChannelId;
	const [subChannel, setSubChannel] = useState({});
	const [userRole, setUserRole] = useState();
	const [roomData, setRoomData] = useState();
	const [hideChatList, setHideChatList] = useState(false);

	const ws = useContext(WebSocketContext);
	const userId = currUser?._id || currUser?.id;
	const roomId = subChannel?.assignedChannel;

	useEffect(() => {
		if (ws && ws.socket) {
			ws.socket.on('subChannel', (subChannel) => {
				if (subChannel.event === 'updated') {
					if ((subChannel.result.id || subChannel.result._id) === subChannelId) {
						setSubChannel(subChannel.result);
					}
				}
			});
		}
		return () => {
			if (ws && ws.socket) {
				ws.socket.off('subChannel');
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ws]);

	useEffect(() => {
		const getRoomFromSubChannel = (roomList, roomId) => {
			try {
				if (roomId) {
					let team = roomList.find((room) => room?.room?.id === roomId);
					return team;
				} else {
					return null;
				}
			} catch (err) {
				console.log(err, 'Error getting room');
			}
		};
		let room = getRoomFromSubChannel(currUser.roomList, roomId);
		setRoomData(room);
	}, [currUser.roomList, roomId]);

	useEffect(() => {
		if (currUser) {
			let thisRoom = currUser.subChannelList.find((subChannel) => subChannel?.subChannel?.id === subChannelId);
			if (thisRoom) {
				setUserRole(thisRoom.role);
			} else {
				setUserRole(null);
			}
		}
	}, [currUser, subChannelId]);

	useEffect(() => {
		const joinChat = async (subChannelId) => {
			if (ws && ws.socket) {
				let newSubChannel = subChannels.find((subChannel) => subChannel._id === subChannelId);
				if (!newSubChannel) {
					try {
						newSubChannel = await fetchWrapper.get(`subchannels/${subChannelId}`);
						if (newSubChannel.status === 403) {
							toast.error(newSubChannel.message);
							history.push(`/not-found`);
						}
						newSubChannel.id = newSubChannel._id;
					} catch (e) {
						newSubChannel = null;
					}
				}
				if (newSubChannel) {
					ws.socket.emit('joinSubChannel', subChannelId, (error, socketMessages) => {
						if (error) {
							console.log(error, 'WS socket joining error');
						} else {
							clearSearchMessages();
							setSubChannel(newSubChannel);
							// setOpenDetails(true);
							let keys = Object.keys(messages);
							if (!keys.includes(subChannelId)) {
								getSubChannelMessages(subChannelId, 1);
							}
							const currentUrl = window.location.href;
							if (userId && currentUrl.includes('/event-channel/' + subChannelId) ){
								history.push(`/event-channel/${subChannelId}#/id/${userId}`);
							}
						}
					});
				}
			}
		};

		if (subChannelId && userId) {
			joinChat(subChannelId);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userId, ws, subChannelId, history]);

	return (
		<div css={layout}>
			<MessageListener />
			<SwitchTransition>
				<CSSTransition key={mobileSidebar} timeout={500} classNames={'sidebar-ani-'}>
					<SideBar mobileSidebar={mobileSidebar} setMobileSidebar={setMobileSidebar} />
				</CSSTransition>
			</SwitchTransition>
			<div className={`wrapper-chat wrapper`}>
				<div className={`chatList ${hideChatList ? 'hide-chatList' : ''}`}>
					<button
						className={`hiddenBtn ${hideChatList ? '' : 'partialVisibleBtn'}`}
						css={css`
							position: absolute;
							right: ${hideChatList ? '-22%' : '-4%'};
							top: 32px;
							z-index: 11;
							width: 40px;
							height: 40px;
							background: #e0f0ef;
							color: #28637e;
							outline: none;
							border: none;
							display: flex;
							overflow: hidden;
							justify-content: center;
							align-items: center;
							border-radius: 1000px;
							transition: right 150ms;
							.caretIcon {
								transition: transform 150ms;
								&.turnRight {
									transform: rotateY(180deg);
								}
								&.turnLeft {
									transform: rotateY(0deg);
								}
							}
							@media (max-width: 768px) {
								display: none;
							}
						`}
						onClick={() => setHideChatList(!hideChatList)}
					>
						<BsFillCaretLeftFill className={`caretIcon ${hideChatList ? 'turnRight' : 'turnLeft'}`} />
					</button>
					<EventChannelList
						mobileSidebar={mobileSidebar}
						setMobileSidebar={setMobileSidebar}
					/>
				</div>
				<div className={`chat ${hideChatList ? 'hide-chatList' : ''}`}>
					{subChannelId ? (
						<EventChannelChat
							key={subChannelId}
							subChannel={subChannel}
							subChannelId={subChannelId}
							getLastSeenRoomMessage={getLastSeenSubChannelMessage}
							// setChatFormId={setChatFormId}
							// setOpenDetails={setOpenDetails}
							// openDetails={openDetails}
							// setShowChat={setShowChat}
							// userRole={userRole}
							room={roomData?.room}
							roomData={roomData}
						/>
					) : (
						<h1 className="h4 h-100 d-flex justify-content-center align-items-center ">
							Click on Subchannel to chat
						</h1>
					)}
				</div>
			</div>
		</div>
	);
};
const mapStateToProps = (state) => ({
	currUser: state.user.user,
	position: state.user.position,
	MessagesFromRedux: state.messages,
	rooms: state.rooms.teamRooms,
	subChannels: state.subChannels.subChannels,
	clearMessages: PropTypes.func.isRequired,
	messages: state.messages.messages,
});
EventChannelContainer.propTypes = {
	MessagesFromRedux: PropTypes.object.isRequired,
	position: PropTypes.object,
};
const mapDispatchToProps = {
	getAllSubChannels,
	getSubChannelMessages,
	clearMessages,
	clearSearchMessages,
	getLastSeenSubChannelMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventChannelContainer);
