import React from 'react';
import { css } from 'twin.macro';
import { AiFillCheckCircle } from 'react-icons/ai';
import DraftFormInput from './DraftFormInput';
import { CgSandClock } from 'react-icons/cg';

function MultiSubmissionView({ showSignature, form, userId, hide, id }) {
	const mySignature = (form?.signatures || []).filter((signature) => signature.user || signature._id === userId);
	let icon = '';
	switch (form.submissionType) {
		case 'completed':
			icon = <AiFillCheckCircle size="32" color="#2e9a9c" />;
			break;
		default:
			icon = <CgSandClock size="32" color="#2e9a9c" />;
	}
	return (
		<div>
			{showSignature && form.type === 'multipleSubmission' && (
				<div className="bg-light text-dark p-4">
					<div className="d-block p-2">
						<div className="text-center d-flex align-items-center w-100 justify-content-center">
							{icon}
							<span
								className="text-success ml-2 text-capitalize"
								css={css`
									font-size: 24px;
								`}
							>
								{form.submissionType}
							</span>
						</div>

						{Boolean(form.signatures.length) ? (
							<>
								<h3 className="my-2">Signatures:</h3>
								<div className="d-flex justify-content-center">
									{form.signatures.map((signature) => (
										<div
											key={signature._id}
											css={css`
												padding: 1rem 0rem;
												width: 230px;
												height: 150px;
												margin: 0 0.5rem;
												img {
													width: 100%;
												}
											`}
										>
											<img
												src={signature.signature}
												css={css`
													width: 100%;
													height: 100%;
													border: 1px solid #2b2b2b;
													padding: 10px;
													border-radius: 4px;
												`}
												alt="signature"
											/>
											<div className="text-center">{signature?.name}</div>
										</div>
									))}
								</div>
							</>
						) : (
							<div className="text-center small w-100 my-3">There are no submissions yet.</div>
						)}
					</div>
					{form?.submissionRequiredBy?.includes(userId) && (
						<>{!(mySignature.length > 0) && <DraftFormInput id={id} hide={hide} form={form?.formData} />}</>
					)}
				</div>
			)}
		</div>
	);
}

export default MultiSubmissionView;
