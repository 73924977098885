import Select from 'react-select/async';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setTaskList } from '../../_redux/actions/ActionTasks';
import { getTeamIdFromURL, fetchWrapper, getUniqueObjects, sortingOptionMethod } from '../../_helpers';

const TaskSelectFilter = ({
	teamId,
	tasklist,
	totalTasks,
	selectedOption,
	onTaskSelect = () => { },
	disabled = false,
	setTaskList,
	disableTaskFetch = false,
	defaultTasks = [],
	...props
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [search, setSearch] = useState('');
	const [_, setPage] = useState(1);
	const [scrolledToBottom, setScrolledToBottom] = useState(false);

	//#TODO Decide if better to fetch from URL or from parent component as props, also clearly define weather it is the admin or normal user
	if (!teamId) {
		teamId = getTeamIdFromURL();
	}

	useEffect(() => {
		setSearch('');
		setPage(0);
		setScrolledToBottom(true);
		setTaskList({ results: [], totalResults: 9e9 });
	}, [teamId]);


	useEffect(() => {
		if (scrolledToBottom && !isLoading && (!tasklist.length || (tasklist.length < totalTasks))) {
			setPage((prev) => {
				const newPage = prev + 1;
				fetchTasks(newPage);
				return newPage;
			});
		}
	}, [scrolledToBottom, teamId]);

	const onTaskSearchChange = (selectedTask) => {
		if (Array.isArray(selectedTask)) {
			onTaskSelect(selectedTask.length ? selectedTask : [])
		} else {
			onTaskSelect(selectedTask)
		}
	};

	const mapTasksToOptions = (tasks, title = search, prefetched = defaultTasks) => getUniqueObjects(tasks || [], prefetched || [])
		.map((task) => {
			const assignedChannelInfo = Array.isArray(task.assignedChannelInfo) ? task.assignedChannelInfo[0] : task.assignedChannelInfo || {};
			const taskTeamId = task.team?.id || assignedChannelInfo?.teamData?._id;
			const taskTeamName = task.team?.name || assignedChannelInfo?.teamData?.name;
			const taskOption = {
				...task,
				label: `${task.title}${teamId ? '' : taskTeamName ? ' - ' + taskTeamName : ''}`,
				start: task.date,
				end: task.dueDate,
				team: {
					id: taskTeamId,
					name: taskTeamName
				},
				value: task._id || task.id,
			};
			return taskOption;
		})
		.filter(({ title: taskTitle, team }) => (!teamId || team?.id === teamId) && (!title || new RegExp(title, 'i').test(taskTitle)))
		.sort(sortingOptionMethod);

	const fetchTasks = async (page = 1, title = '') => {
		if(disableTaskFetch) { return; }
		try {
			setIsLoading(true);
			if (tasklist.length && (tasklist.length >= totalTasks)) {
				return []
			}
			const data = await fetchWrapper.get(`/task?page=${page}&limit=100${teamId ? `&team=${teamId}` : ''}${title ? `&title=${title}` : ''}&sortBy=title:asc`);

			setTaskList(data, !title && page === 1);

			const options = mapTasksToOptions(data.results, title, defaultTasks);
			return options;
		} catch (error) {
			return [];
		} finally {
			setIsLoading(false);
			setScrolledToBottom(false);
		}
	};

	let timeout;
	const loadTaskOptions = (search) => {
		return new Promise(resolve => {
			clearTimeout(timeout);
			if (tasklist.length && (tasklist.length >= totalTasks)) {
				resolve(mapTasksToOptions(tasklist, search, defaultTasks))
			}
			timeout = setTimeout(() => {
				if (!search) {
					resolve(mapTasksToOptions(tasklist, '', defaultTasks));
				}
				fetchTasks(1, search).then(options => {
					setSearch(search);
					resolve(options);
				})
			}, 500)
		})
	}

	return (
		<div
			style={{
				minWidth: '312px',
				gap: '6px',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'flex-start',
			}}
		>
			<div
				style={{
					width: '100%',
					position: 'relative',
					// display: 'flex',
					gap: '6px'
				}}
			>
				<Select
					name="channel"
					value={selectedOption}
					defaultOptions={mapTasksToOptions(tasklist, '', defaultTasks)}
					loadOptions={loadTaskOptions}
					onChange={onTaskSearchChange}
					isClearable={!props.required}
					placeholder='Select Task'
					isDisabled={disabled}
					isLoading={isLoading}
					onMenuScrollToBottom={() => { setScrolledToBottom(true) }}
					styles={{
						option: (baseStyles, state) => ({
							...baseStyles,
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						}),
						control: (baseStyles, state) => ({
							...baseStyles,
							// borderRadius: '12px',
							minHeight: '3.7rem',
						}),
					}}
					theme={(theme) => ({
						...theme,
						colors: {
							...theme.colors,
							primary25: '#c3e4e2',
							primary: '#50aeb0',
						},
					})}
					{...props}
				>
				</Select>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	tasklist: state.tasks.tasklist,
	totalTasks: state.tasks.totalTasks
});

const mapDispatchToProps = { setTaskList };

export default connect(mapStateToProps, mapDispatchToProps)(TaskSelectFilter);
