import { css } from 'twin.macro';
import NavigationBar from '../reusableComponents/NavigationBar';
import WelcomeUser from '../reusableComponents/WelcomeUser';
import { Button, Form } from 'react-bootstrap';
import ReactSelect from 'react-select';
import { AiOutlineClose } from 'react-icons/ai';
import TeamSelectFilter from '../TeamSelectFilter';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useState, useEffect } from 'react';
import TagForm from './TagForm';
import { fetchWrapper, formatDate, toastWrapper, useDebouncedEffect } from '../../../_helpers';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { AdminTable } from '../reusableStyles';
import SortedTableHeaderIcon from '../SortedTableHeaderIcon';
import Loader from '../containers/Loader';
import { BsPencil, BsTrash } from 'react-icons/bs';
import { ToolTip } from '../../../_elements/ToolTip';
import Pagination from '../Pagination';

export default function Tags() {
	const [tags, setTags] = useState({ results: [], page: 1, totalResults: 0, limit: 10 });

	const [isLoading, setIsLoading] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);

	const queryPage = useLocation().search.match(/page=([0-9]+)/, '');
	const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);

	const history = useHistory();

	const [query, setQuery] = useState({
		type: { label: 'Select Type', value: '' },
		team: { label: 'Select a Team', value: '' },
		name: '',
	});

	const [search, setSearch] = useState('');

	const getTags = async (data) => {
		try {
			setIsLoading(true);
			const response = await fetchWrapper.get(
				`tags?includeGlobal=false&limit=${tags.limit}&page=${Math.max(1, data.page || 1)}${
					data.team?.value ? `&team=${data.team.value}` : ''
				}${data.type?.value ? `&type=${data.type.value}` : ''}${data.name ? `&name=${data.name}` : ''}
				&sortBy=${data.sortBy || 'createdAt:desc'}`
			);
			setTags(response);
		} catch (err) {
			toastWrapper.error(err.message || err);
		} finally {
			setIsLoading(false);
		}
	};

	const handleDelete = async (tagId) => {
		try {
			setIsDeleting(true);
			const shouldDelete = await Swal.fire('Are you sure?');
			if (!shouldDelete.value) {
				return;
			}
			await fetchWrapper.delete(`tags/${tagId}`);
			await getTags({
				...query,
				page: currentPage,
				sortBy: `${sortConfig.key}:${sortConfig.direction}`,
			});
		} catch (err) {
			toast.error(err.message || err);
		} finally {
			setIsDeleting(false);
		}
	};

	const [sortConfig, setSortConfig] = useState({ key: 'createdAt', direction: 'desc' });

	const handleSort = (key) => {
		if (sortConfig.key === key) {
			return setSortConfig((prev) => ({ ...prev, direction: prev.direction === 'asc' ? 'desc' : 'asc' }));
		}
		setSortConfig({ key, direction: 'desc' });
	};

	const onPageChanged = (data) => {
		if (currentPage !== data.currentPage && data.currentPage > 0) {
			history.push(`/tags?page=${data.currentPage}`);
			getTags({ ...query, page: data.currentPage, sortBy: `${sortConfig.key}:${sortConfig.direction}` });
		}
	};

	useEffect(() => {
		if (!isLoading) {
			getTags({ ...query, page: currentPage, sortBy: `${sortConfig.key}:${sortConfig.direction}` });
		}
	}, [sortConfig, query]);

	useDebouncedEffect(() => {
		setQuery((prev) => ({ ...prev, name: search }));
	}, [search]);

	return (
		<div
			css={css`
				padding: 1rem 2rem;
			`}
		>
			<div className="d-flex justify-content-between mb-4">
				<NavigationBar mainTabName="Tags" />
				<WelcomeUser />
			</div>
			<div>
				<div
					className="d-flex flex-row align-items-center mb-4 flex-wrap"
					css={css`
						gap: 12px;
					`}
				>
					<div style={{ width: '200px', position: 'relative' }}>
						<ReactSelect
							options={[
								{ label: 'Form', value: 'form' },
								{ label: 'Event', value: 'event' },
								{ label: 'File', value: 'file' },
								{ label: 'Form Submission', value: 'userform' },
								{ label: 'All', value: 'all' },
							]}
							value={query.type}
							onChange={(option) => setQuery((prev) => ({ ...prev, type: option }))}
							isDisabled={isLoading}
						/>
						<AiOutlineClose
							style={{
								position: 'absolute',
								cursor: 'pointer',
								top: '12px',
								right: '42px',
								opacity: query.type?.value ? (isLoading ? 0.25 : 0.5) : 0,
							}}
							onClick={() => {
								setQuery((prev) => ({ ...prev, type: { label: 'Select Type' } }));
							}}
						/>
					</div>
					<TeamSelectFilter
						selectedTeamOption={query.team}
						setSelectedTeamOption={(option) =>
							setQuery((prev) => ({
								...prev,
								team: option,
							}))
						}
						disabled={isLoading}
					/>
					<div
						css={css`
							width: 200px;
							position: relative;
						`}
					>
						<Form.Control
							placeholder="Search by Name"
							onChange={(event) => setSearch(event?.target?.value)}
							value={search}
							disabled={isLoading}
						/>
						<AiOutlineClose
							style={{
								position: 'absolute',
								cursor: isLoading ? '' : 'pointer',
								top: '12px',
								right: '12px',
								opacity: query.name ? (isLoading ? 0.25 : 0.5) : 0,
							}}
							onClick={() => {
								isLoading || setSearch('');
							}}
						/>
					</div>

					<TagForm afterSubmit={getTags} />
				</div>
				<div
					className="custom-card card-table d-flex flex-column justify-content-between"
					css={css`
						min-height: 675px;
					`}
				>
					<AdminTable className="w-100 text-dark-grey">
						<thead>
							<tr>
								<th>SN</th>
								<th
									className="text-center"
									onClick={() => handleSort('name')}
									style={{ cursor: 'pointer' }}
								>
									Name
									<SortedTableHeaderIcon sortConfig={sortConfig} fieldName="name" key="name" />
								</th>
								<th className="text-center">Team</th>
								<th className="text-center">Type</th>
								<th
									className="text-center"
									onClick={() => handleSort('createdAt')}
									style={{ cursor: 'pointer' }}
								>
									Created At
									<SortedTableHeaderIcon
										sortConfig={sortConfig}
										fieldName="createdAt"
										key="createdAt"
									/>
								</th>
								<th></th>
							</tr>
						</thead>
						<tbody className="text-light-grey">
							{isLoading ? (
								<tr>
									<td>
										<div className="w-100 h-100 d-flex flex-row justify-content-center align-items-center">
											<Loader size="sm" top={400} />
										</div>
									</td>
								</tr>
							) : tags?.results?.length ? (
								tags.results.map((tag, index) => {
									return (
										<tr key={tag._id || tag.id}>
											<td>{(currentPage - 1) * tags.limit + index + 1}</td>
											<td className="text-center">{tag.name}</td>
											<td className="text-center">{tag.team?.name || '-'}</td>
											<td className="text-center text-capitalize">
												{tag.type === 'userform' ? 'Form Submission' : tag.type}
											</td>
											<td className="text-center">{formatDate(new Date(tag.createdAt || 0))}</td>
											<td>
												<TagForm
													tagData={tag}
													mode="edit"
													ButtonContent={
														<ToolTip
															text={'Edit'}
															side={'top'}
															cssStyles={
																'top:-70% !important; min-width: fit-content !important'
															}
														>
															<BsPencil className="icon edit" />
														</ToolTip>
													}
													buttonVariant="outline-primary"
												/>
												<Button
													variant="outline-danger"
													className="ml-2"
													onClick={() => handleDelete(tag._id)}
													disabled={isDeleting}
												>
													<BsTrash />
												</Button>
											</td>
										</tr>
									);
								})
							) : (
								<tr>
									<td colSpan={9}>
										<div
											className="w-100 h-100 d-flex flex-row justify-content-center align-items-center"
											css={css`
												margin-top: 100px;
												width: 100%;
											`}
										>
											No Tag Found
										</div>
									</td>
								</tr>
							)}
						</tbody>
					</AdminTable>

					{tags.totalResults ? (
						<div className="m-4">
							<Pagination
								pageNo={currentPage}
								totalRecords={tags.totalResults}
								pageLimit={tags.limit}
								pageNeighbours={1}
								onPageChanged={onPageChanged}
							/>
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
}
