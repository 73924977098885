import { css } from 'twin.macro';

export const layout = css`
	overflow: hidden;
	.wrapper-d {
		height: 100vh;
		background-color: #f5f5f5;
		display: flex;
	}

	.teamlist {
		grid-area: teamlist;
		position: relative;
		width: 418px;
		transition: width 200ms;
		&.hide-teamlist {
			width: 82px;
		}

		.hiddenBtn {
			transition: opacity 150ms;
		}
		:hover > .partialVisibleBtn {
			opacity: 1;
		}

		.visibleBtn {
		}

		.partialVisibleBtn {
			opacity: 0;
		}
	}

	.channelchat {
		grid-area: channelchat;
		width: calc(100% - 418px);
		transition: width 200ms;
		&.hide-teamlist {
			width: calc(100% - 82px);
		}
	}

	.details-ani {
		position: fixed;
		left: 100%;
		top: 0px;
		height: 100vh;
		z-index: 50;
		overflow-y: scroll;
		background-color: #f2f8f7;

		&::-webkit-scrollbar {
			width: 10px;
			cursor: pointer;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #c8e7e8;
			border-radius: 5px;
		}
	}

	.details-ani--enter {
		transform: translateX(0%);
	}
	.details-ani--enter-active {
		transform: translateX(-100%);
		transition: transform 0.5s ease-in-out;
	}
	.details-ani--enter-done {
		transform: translateX(-100%);
		transition: transform 0.5s ease-in-out;
	}

	.details-ani--exit {
		transform: translateX(-100%);
	}
	.details-ani--exit-active {
		transform: translateX(0%);
		transition: transform 0.5s ease-in-out;
	}
	.details-ani--exit-done {
		transform: translateX(0%);
		transition: transform 0.5s ease-in-out;
	}

	@media (max-width: 768px) {
		.wrapper-chat {
			width: 100%;
			height: 100vh;
			display: grid;
			grid-template-areas: 'channelchat';
			grid-template-columns: 100vw;
			.channelchat {
				width: 100%;
			}
		}

		.wrapper-team {
			width: 100%;
			height: 100vh;
			display: grid;
			grid-template-areas: 'teamlist';
			grid-template-columns: 100vw;
			.channelchat {
				display: none;
			}
			.teamlist {
				width: 100%;
			}
		}

		.sidebar-ani {
			box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
		}
		.sidebar-ani--enter {
			transform: translateY(80px);
		}
		.sidebar-ani--enter-active {
			transform: translateY(0px);
			transition: transform 0.5s ease-in-out;
		}
		.sidebar-ani--enter-done {
		}

		.sidebar-ani--exit {
		}
		.sidebar-ani--exit-active {
			transform: translateY(80px);
			transition: transform 0.5s ease-in-out;
		}
		.sidebar-ani--exit-done {
		}
	}
`;
