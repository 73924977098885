import React, { useState, useCallback, useEffect, useContext } from 'react';
import Video, { createLocalTracks } from 'twilio-video';
// import { fetchWrapper } from '../../../_helpers';
import { StyledConfrenceContainer } from './styles/ConferenceContainerStyled';
import { WebSocketContext } from '../../../_redux/actions/ActionSocket';

import Lobby from './Lobby';
import Room from './Room';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const VideoChat = ({ match }) => {
    const ws = useContext(WebSocketContext);
    const user = useSelector((state) => state.user.user);

    const [audio, setAudio] = useState(false);
    const [video, setVideo] = useState(false);
    const [room, setRoom] = useState(null);
    const [channelInfo, setChannelInfo] = useState({});
    const [connecting, setConnecting] = useState(false);
    const [participantInfo, setParticipantInfo] = useState({});
    const conferenceId = match?.params?.meetId;
    const [host, setHost] = useState(null);

    // const [isJoinable, setIsJoinable] = useState(false);

    const handleMuteLocalParticipant = useCallback(
        (mutedParticipantId) => {
            let isLocalParticipant = user.id === mutedParticipantId;
            if (isLocalParticipant) {
                room.localParticipant.audioTracks.forEach((track) => {
                    track.track.disable();
                    setAudio(false);
                });
            }
        },
        [room, user.id]
    );
    useEffect(() => {
        if (ws && ws.socket) {
            ws.socket.on('new-participant', (data) => {
                setParticipantInfo((prev) => ({ ...prev, [data._id]: data }));
            });
            ws.socket.on('muted-participant', (data) => {
                setParticipantInfo((prev) => {
                    if (!prev || !prev[data]) {
                        return prev;
                    }
                    return { ...prev, [data]: { ...prev[data], audio: false } };
                });
                handleMuteLocalParticipant(data);
            });
            ws.socket.on('removed-participant', (data) =>
                setParticipantInfo((prev) => {
                    const tempParticipant = { ...prev };
                    delete tempParticipant[data];
                    return tempParticipant;
                })
            );
        }
        return () => {
            ws?.socket?.off('new-participant');
            ws?.socket?.off('removed-participant');
            ws?.socket?.off('muted-participant');
        };
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleMuteLocalParticipant, ws]);

    const handleDisconnect = useCallback(() => {
        setRoom((prevRoom) => {
            if (prevRoom) {
                prevRoom.localParticipant.tracks.forEach((trackPub) => {
                    trackPub.track.stop();
                });
                prevRoom.disconnect();
            }
            return null;
        });
        window.close();
        if (ws && ws.socket) {
            ws.socket.emit('leave-conference', conferenceId, (error, data) => {
                if (error) {
                    console.log(error, 'Leaving');
                }
            });
        }
    }, [ws, conferenceId]);

    const handleEndCall = useCallback(() => {
        if (ws && ws.socket) {
            ws.socket.emit('end-conference', conferenceId, (error, data) => {
                if (error) {
                    console.log(error, 'Leaving');
                }
            });
        }
        window.close();
    }, [ws, conferenceId]);
    const handleRemoveParticipant = useCallback(
        (participantId) => {
            if (ws && ws.socket) {
                ws.socket.emit('remove-participant', conferenceId, participantId, (error, data) => {
                    if (error) {
                        console.llog(error, 'removing');
                    }
                });
            }
        },
        [ws, conferenceId]
    );

    const handleMuteParticipant = useCallback(
        (participantId) => {
            if (ws && ws.socket) {
                ws.socket.emit('mute-participant', conferenceId, participantId, (error, data) => {
                    if (error) {
                        console.log(error, 'Leaving');
                    }
                });
            }
        },
        [ws, conferenceId]
    );

    const startConference = async (conferenceData, conferenceId) => {
        try {
            setConnecting(true);
            // const data = await fetchWrapper.get(`/conference/token/?conferenceId=${conferenceId}`);

            const localTracks = await createLocalTracks({
                audio: true,
                video: video ? { height: 720, frameRate: 24, width: 1280, name: 'camera' } : false,
            });

            const room = await Video.connect(conferenceData.confrenceTokens.jwt, {
                // name: conferenceData.conferenceData.room.id,
                name: conferenceId,
                dominantSpeaker: true,
                networkQuality: true,
                tracks: localTracks,
            });

            setRoom(room);

            if (!audio) {
                room.localParticipant.audioTracks.forEach((track) => {
                    track.track.disable();
                });
            }

            room.on('disconnected', (room, error) => {
                toast.info('Meeting Ended');
                handleDisconnect();
                setRoom(null);
            });
        } catch (error) {
            console.error('ERROR WHILE STARTING CONFERENCE: ', error);
        } finally {
            setConnecting(false);
        }
    };

    // useEffect(() => {
    // 	if (conferenceData && conferenceId) {
    // 		startConference(conferenceData, conferenceId);
    // 	}
    // }, [conferenceData, conferenceId])

    const [seconds, setSeconds] = useState(5);

    const handleJoinConference = async (event) => {
        // event.preventDefault();
        try {
            // const data = await fetchWrapper.get(`/conference/token/?conferenceId=${conferenceId}`);
            if (!conferenceId || !ws || !ws.socket) {
                return null;
            }

            ws.socket.emit('join-conference', { conferenceId, audio, video }, async (err, data) => {
                if (err) {
                    setConnecting(false);
                    toast.error(err.message);
                    return;
                }
                console.log('CONFERENCE DATA => ', data);

                setHost(data?.conferenceData?.host);
                setChannelInfo(data?.conferenceData?.room);
                setParticipantInfo(data?.participantList);

                await startConference(data, conferenceId);
            });
        } catch (e) {
            console.log(e, 'call error');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const [permissionState, setPermissionState] = useState('Checking for permissions ...');

    useEffect(() => {
        (async () => {
            let isMicrophonePermissonDenied =
                (await navigator.permissions.query({ name: 'microphone' })).state === 'denied';
            let isVideoPermissionDenied = (await navigator.permissions.query({ name: 'camera' })).state === 'denied';

            if (isMicrophonePermissonDenied && isVideoPermissionDenied) {
                setPermissionState('Failed! Microphone and Camera permission is denied.');
                return toast.error(
                    'Oops! Microphone and Camera permissions are denied, Please allow Microphone and Camera permissions and Refresh this page'
                );
            }
            if (isMicrophonePermissonDenied) {
                setPermissionState('Failed! Microphone permission is denied.');
                return toast.error(
                    'Oops! Microphone permission denied, Please allow Microphone permission and Refresh this page'
                );
            }
            if (isVideoPermissionDenied) {
                setPermissionState('Failed! Camera permission is denied.');
                return toast.error(
                    'Oops! Camera permission denied, Please allow Camera permission and Refresh this page'
                );
            }
            setPermissionState('granted');
            // setIsJoinable(true)
        })();
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            if (seconds > 0) {
                setSeconds((prev) => prev - 1);
            }
        }, 1000);

        if (seconds <= 0) {
            console.log(seconds, 'seconds use effect for');
            // setIsJoinable(true);
            handleJoinConference();
        }

        return () => {
            clearInterval(timer);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seconds]);

    // useEffect(() => {
    // 	if (room) {
    // 		const tidyUp = (event) => {
    // 			if (event.persisted) {
    // 				return;
    // 			}
    // 			if (room) {
    // 				handleDisconnect();
    // 			}
    // 		};
    // 		window.addEventListener('pagehide', tidyUp);
    // 		window.addEventListener('beforeunload', tidyUp);
    // 		return () => {
    // 			window.removeEventListener('pagehide', tidyUp);
    // 			window.removeEventListener('beforeunload', tidyUp);
    // 		};
    // 	}
    // }, [room, handleDisconnect]);

    useEffect(() => {
        return () => {
            room && handleDisconnect();
        };
    }, []);

    const handleAudio = (e) => {
        setAudio(e.target.checked);
    };

    const handleVideo = (e) => {
        setVideo(e.target.checked);
    };
    let conferenceParticipants = Object.values(participantInfo)
        .filter((participant) => participant.conferenceId === conferenceId)
        .reduce((res, key) => {
            if (typeof res._id === 'string') {
                res[res._id] = res;
                res[key._id] = key;
            } else {
                res = { ...res, [key._id]: key };
            }
            return res;
        }, {});

    let render;
    if (room) {
        render = (
            <Room
                room={room}
                conferenceId={conferenceId}
                handleDisconnect={handleDisconnect}
                audio={audio}
                video={video}
                handleAudio={handleAudio}
                handleVideo={handleVideo}
                participantInfo={conferenceParticipants}
                channelInfo={channelInfo}
                conferenceHostId={host}
                handleEndCall={handleEndCall}
                handleMuteParticipant={handleMuteParticipant}
                handleRemoveParticipant={handleRemoveParticipant}
            />
        );
    } else {
        render = (
            <Lobby
                handleSubmit={handleJoinConference}
                connecting={connecting}
                audio={audio}
                video={video}
                permissionState={permissionState}
                seconds={seconds}
                handleAudio={handleAudio}
                handleVideo={handleVideo}
                // isJoinable={isJoinable}
            />
        );
    }
    return <StyledConfrenceContainer>{render}</StyledConfrenceContainer>;
};

export default VideoChat;
