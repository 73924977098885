import React from 'react';
import { css } from 'twin.macro';
import { Button, Dropdown } from 'react-bootstrap';
import { FaCalendarAlt, FaRegCalendarAlt } from 'react-icons/fa';
import { forwardRef, Fragment, useState } from 'react';
import DebouncedInput from '../../../_helpers/hooks/useDebouncedValue';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { clearSearchMessages } from '../../../_redux/actions/ActionMessage';
import { connect } from 'react-redux';
import { AiOutlineCaretDown } from 'react-icons/ai';
import styled from '@emotion/styled';

const filterTypes = [
	{
		label: 'Text',
		value: 'text',
	},
	{
		label: 'All Files',
		value: 'allfiles',
	},
	{
		label: 'Medias',
		value: 'media',
	},
	{
		label: 'Files',
		value: 'file',
	},
	{
		label: 'Form',
		value: 'form',
	},
];

const searchCss = css`
	display: flex;
	height: 30px;
	background-color: #c3e4e2;
	border-radius: 5px;
	.icon {
		color: #50aeb0;
	}

	.serchconv {
		padding: 2px 10px;
		height: 100% auto;
		outline: none;
		min-width: 200px;
		border: 1px solid #50aeb0;
		border-radius: 5px;
		font-size: 14px;
		background-color: #e0f0ef;
		background-image: url('https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/search-24.png');
		background-position: 97% 50%;
		background-repeat: no-repeat;
		background-size: 20px 20px;
		transition: background-color 200ms ease-in-out;
		&:focus {
			background-color: white;
		}
	}
`;

const calendarView = forwardRef(({ children, onClick }, ref) => (
	<div
		className="invite mr-2"
		ref={ref}
		onClick={(e) => {
			e.preventDefault();
			onClick(e);
		}}
	>
		{children}
	</div>
));

const datePickers = forwardRef(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
	return (
		<div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
			<ul className="list-unstyled">{children}</ul>
		</div>
	);
});

const SearchConversation = ({
	searchChat,
	id,
	clearSearchMessages,
	isDirectChat,
	filters,
	searchText,
	updateSearchText,
	subChannelId,
}) => {
	const [searchConv, setSearchConv] = useState(() => {
		let today = new Date();
		return [
			{
				text: '',
				type: { value: 'text', label: 'Text' },
				endDate: today,
				key: 'selection',
			},
		];
	});

	const handleInputChange = (txt) => {
		updateSearchText(txt);
		setTimeout(() => {
			const tempSearch = {
				...searchConv[0],
				text: txt ? txt : '',
			};
			setSearchConv([tempSearch]);
			if (isDirectChat) {
				// null for roomid,
				searchChat(null, null, 1, tempSearch, id);
			} else if (subChannelId) {
				searchChat(null, subChannelId, 1, tempSearch, null);
			} else {
				// null for userid,
				searchChat(id, null, 1, tempSearch, null);
			}
		}, 200);
	};

	const [showFilterTypes, setShowFilterTypes] = useState(false);

	const toggleFilterShow = () => {
		setShowFilterTypes(!showFilterTypes);
	};

	const handleFilterTypeChange = (event) => {
		const tempSearch = {
			...searchConv[0],
			type: JSON.parse(event.target.getAttribute('data-menu_item')),
		};
		setSearchConv([tempSearch]);

		if (isDirectChat) {
			// null for roomid,
			searchChat(null, null, 1, tempSearch, id);
		} else if (subChannelId) {
			searchChat(null, subChannelId, 1, tempSearch, null);
		} else {
			// null for userid,
			searchChat(id, null, 1, tempSearch, null);
		}
	};

	return (
		<div css={searchCss}>
			<StyledFilterTypeSelector>
				<span className="h-100 d-flex align-items-center cursor-pointer" onClick={toggleFilterShow}>
					<AiOutlineCaretDown className="icon" />
				</span>

				{showFilterTypes && (
					<div className="menu" onChange={handleFilterTypeChange}>
						{filterTypes.map((filter) => {
							return (
								<div className="menu-item">
									<input
										type="radio"
										name="filter"
										id={filter.value}
										value={filter.value}
										defaultChecked={filter.value === searchConv[0].type.value}
										data-menu_item={JSON.stringify(filter)}
									/>
									<label key={filter.value} htmlFor={filter.value}>
										{filter.label}
									</label>
								</div>
							);
						})}
					</div>
				)}
			</StyledFilterTypeSelector>
			<DebouncedInput
				updateStateInput={updateSearchText}
				searchText={searchText}
				onDebouncedValChange={(val) => {
					handleInputChange(val);
				}}
				delay={1000}
			/>
			<Dropdown>
				<Dropdown.Toggle
					as={calendarView}
					id="invite-to-team"
					className="d-flex justify-content-between align-items-center"
				>
					<FaRegCalendarAlt size={20} className="ml-2 icon pointer" color="#1B686A" />
				</Dropdown.Toggle>

				<Dropdown.Menu as={datePickers}>
					<MessageDatePicker
						setSearchConv={setSearchConv}
						searchConv={searchConv}
						clearSearchMessages={clearSearchMessages}
						isDirectChat={isDirectChat}
						searchChat={searchChat}
						searchText={searchText}
						id={id}
						subChannelId={subChannelId}
					/>
				</Dropdown.Menu>
			</Dropdown>
		</div>
	);
};

const MessageDatePicker = ({
	setSearchConv,
	searchConv,
	isDirectChat,
	searchChat,
	searchText,
	id,
	clearSearchMessages,
	subChannelId,
}) => {
	const handleSearch = () => {
		if (isDirectChat) {
			// null for roomid,
			searchChat(null, null, 1, searchConv[0], id);
		} else if (subChannelId) {
			searchChat(null, subChannelId, 1, searchConv[0], id);
		} else {
			// null for userid,
			searchChat(id, null, 1, searchConv[0], null);
		}
	};

	const handleClearSearch = () => {
		// let startDate = new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate());

		const temp = {
			text: '',
			startDate: '',
			endDate: new Date(),
			type: { value: 'text', label: 'Text' },
		};
		setSearchConv([temp]);
		clearSearchMessages();
		if (isDirectChat) {
			// null for roomid,
			searchChat(null, null, 1, searchConv[0], id);
		} else if (subChannelId) {
			searchChat(null, subChannelId, 1, searchConv[0], id);
		} else {
			// null for userid,
			searchChat(id, null, 1, searchConv[0], null);
		}
	};

	const handleTimeChange = (value) => {
		setSearchConv([value.selection || value.range1]);
	};

	return (
		<Fragment>
			<DateRange
				rangeColors={['#50aeb0']}
				maxDate={new Date()}
				editableDateInputs={true}
				onChange={(item) => handleTimeChange(item)}
				moveRangeOnFirstSelection={false}
				ranges={searchConv}
			/>
			<div
				css={css`
					width: 100%;
					display: flex;
					justify-content: space-between;
					padding: 10px 20px;
				`}
			>
				<Button className="btn-secondary ml-2" onClick={() => handleClearSearch()}>
					Clear Search
				</Button>
				<Button type="primary" onClick={() => handleSearch()}>
					Search
				</Button>
			</div>
		</Fragment>
	);
};

const mapStateToProps = (state) => ({
	filters: state.messages.searchFilters,
});

export default connect(mapStateToProps, { clearSearchMessages })(SearchConversation);

const StyledFilterTypeSelector = styled.div`
	position: relative;
	.icon {
		color: #2e9a9c;
		margin-left: 4px;
		margin-right: 2px;
	}

	.menu {
		background-color: #c3e4e2;
		position: absolute;
		top: 29px;
		display: flex;
		flex-direction: column;
		z-index: 5;
		min-width: 150px;
		border-radius: 5px;

		.menu-item {
			display: flex;
			align-items: center;
			color: #333;
			padding: 5px;
			transition: background-color 120ms;
			:hover {
				background-color: #a6dcd8;
			}
			label {
				display: inline-block;
				margin-bottom: 0;
				margin-left: 5px;
				cursor: pointer;
				width: 100%;
			}
		}
	}
`;
