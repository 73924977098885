import React from 'react';
import { css } from 'twin.macro';
import { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import { CustomRendererStyled } from '../Features/Common/styles/FileMsgPDFStyled';

import { MdZoomIn, MdZoomOut, MdNavigateNext, MdNavigateBefore } from 'react-icons/md';
import { FaArrowsAltH, FaArrowsAltV } from 'react-icons/fa';
import { VscLoading } from 'react-icons/vsc';

const options = {
	cMapUrl: 'cmaps/',
	cMapPacked: true,
};

function PdfRenderer({ uri }) {
	const [numPages, setNumPages] = useState(null);
	const [isScroll, setIsScroll] = useState(false);
	const [scale, setScale] = useState(1);

	const [pageNumber, setPageNumber] = useState(1);

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}

	function changeToVerticalView() {
		setIsScroll(true);
	}
	function changeToHorizontalView() {
		setIsScroll(false);
	}
	function handlePageIncrement() {
		if (pageNumber <= numPages) {
			setPageNumber(pageNumber + 1);
		}
	}
	function handlePageDecrement() {
		if (pageNumber > 1) {
			setPageNumber(pageNumber - 1);
		}
	}

	const handleZoomIn = () => {
		if (scale <= 3) {
			setScale(scale + 0.5);
		}
	};
	const handleZoomOut = () => {
		if (scale >= 0.5) {
			setScale(scale - 0.5);
		}
	};
	return (
		<CustomRendererStyled>
			<header className="d-flex justify-content-end">
				<div className="d-flex">
					<span className="d-flex align-items-center mr-4">
						{isScroll ? (
							<i>total pages: {numPages}</i>
						) : (
							<>
								<button type="button" onClick={handlePageDecrement}>
									<MdNavigateBefore />
								</button>
								<span className="mx-2">
									{pageNumber} / {numPages}
								</span>
								<button type="button" onClick={handlePageIncrement}>
									<MdNavigateNext />
								</button>
							</>
						)}
					</span>

					<span className="d-flex">
						<button type="button" className="mr-2" disabled={scale >= 2} onClick={handleZoomIn}>
							<MdZoomIn size="24" />
						</button>
						<button type="button" disabled={scale <= 0.5} onClick={handleZoomOut}>
							<MdZoomOut size="24" />
						</button>
						<span className="ml-4">
							{isScroll ? (
								<button type="button" onClick={changeToHorizontalView}>
									<FaArrowsAltH />
								</button>
							) : (
								<button type="button" onClick={changeToVerticalView}>
									<FaArrowsAltV />
								</button>
							)}
						</span>
					</span>
					<span
						css={css`
							.shareBtn {
								position: relative;
								display: inline;
								opacity: 1;
								background: transparent;
							}
						`}
					>
						{/* <ShareButton url={file} /> */}
					</span>
				</div>
			</header>
			<div className="container mx-auto">
				<div className="container__document">
					<Document
						file={uri}
						onLoadSuccess={onDocumentLoadSuccess}
						options={options}
						loading={
							<div className="d-flex  align-items-center justify-content-center text-dark">
								<VscLoading className="spin mr-4" size="32" />
								<div>loading...</div>
							</div>
						}
						onLoadError={(error) => console.error(error)}
					>
						{isScroll ? (
							Array.from(new Array(numPages), (el, index) => (
								<Page
									key={`page_${index + 1}`}
									pageNumber={index + 1}
									loading={<div>Please wait!</div>}
									css={css`
										transform: scale(${scale});
										transform-origin: top left;
									`}
								/>
							))
						) : (
							<Page
								pageNumber={pageNumber}
								css={css`
									transform: scale(${scale});
									transform-origin: top left;
								`}
								loading={<div>Please wait!</div>}
							/>
						)}
					</Document>
				</div>
			</div>
		</CustomRendererStyled>
	);
}

export default PdfRenderer;
