import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { css } from 'twin.macro';

const ChatComponentCSS = css`
	width: 100%;
	.timeSkeleton {
		display: flex;
		justify-content: center;
		align-items: center;
		border-bottom: 1px solid white;
	}
	.senderDiv {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		.flex-end {
			display: flex;
			justify-content: flex-end;
		}
	}
`;
const TeamChatComponentCSS = css`
	width: 100%;
	.timeSkeleton {
		display: flex;
		justify-content: center;
		align-items: center;
		border-bottom: 1px solid white;
	}

	.profileImgDiv {
		margin-right: 1rem;
	}
	.senderDiv {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		.flex-end {
			display: flex;
			justify-content: flex-end;
		}
	}
`;

const SkeletonComponent = ({ type }) => {
	return (
		<SkeletonTheme color="#d3d3d3" highlightColor="rgba(240, 240, 240, 0.5)">
			{type === 'direct' ? (
				<React.Fragment>
					{Array(2)
						.fill()
						.map((item, index) => {
							return (
								<div css={ChatComponentCSS} key={index}>
									<div className="timeSkeleton">
										<Skeleton width={120} height={35} />
									</div>
									<div className="receiverDiv">
										<div>
											<Skeleton width={120} height={60} />
										</div>
										<div>
											<Skeleton width={240} height={50} />
										</div>
									</div>
									<div className="senderDiv">
										<div>
											<div className="flex-end">
												<Skeleton width={240} height={50} />
											</div>

											<div>
												<Skeleton width={340} height={50} />
											</div>
											<div className="flex-end">
												<Skeleton width={120} height={60} />
											</div>
										</div>
									</div>
								</div>
							);
						})}
				</React.Fragment>
			) : (
				<div css={TeamChatComponentCSS}>
					<div className="timeSkeleton">
						<Skeleton width={120} height={35} />
					</div>
					<div className="receiverDiv">
						<div
							css={css`
								display: flex;
							`}
						>
							<div className="profileImgDiv">
								<Skeleton circle={true} height={40} width={40} />
							</div>
							<div>
								<div>
									<Skeleton width={140} height={20} />
								</div>
								<div>
									<Skeleton width={100} height={50} />
								</div>
							</div>
						</div>
					</div>
					<div className="senderDiv">
						<div>
							<div className="flex-end">
								<Skeleton width={240} height={40} />
							</div>

							<div>
								<Skeleton width={340} height={50} />
							</div>
							<div className="flex-end">
								<Skeleton width={120} height={60} />
							</div>
						</div>
					</div>
					<div className="receiverDiv">
						<div
							css={css`
								display: flex;
							`}
						>
							<div className="profileImgDiv">
								<Skeleton circle={true} height={40} width={40} />
							</div>
							<div>
								<div>
									<Skeleton width={140} height={20} />
								</div>
								<div>
									<Skeleton width={250} height={120} />
								</div>
							</div>
						</div>
					</div>
					<div className="senderDiv">
						<div>
							<div className="flex-end">
								<Skeleton width={240} height={40} />
							</div>

							<div>
								<Skeleton width={280} height={80} />
							</div>

							<div className="flex-end hidden">
								<Skeleton width={120} height={60} />
							</div>
						</div>
					</div>
				</div>
			)}
		</SkeletonTheme>
	);
};

function ChatSkeleton({ type }) {
	return (
		<div>
			<SkeletonComponent type={type} />
		</div>
	);
}

export default ChatSkeleton;
