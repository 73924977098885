import React from 'react';
import { memo } from 'react';
import styled from '@emotion/styled';

export const LoaderStyles = styled('span')`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;

	.loaderImg {
		position: absolute;
		top: ${(props) => (props.top ? `${props.top}px` : '300px')};
		left: 50%;
		z-index: 20;
		height: ${(props) => (props.size === 'lg' ? '70px' : '30px')};
		width: ${(props) => (props.size === 'lg' ? '70px' : '30px')};

		&.notAbs {
			position:static;

		}
	}
`;

const Loader = ({ size, top, className }) => {
	return (
		<LoaderStyles size={size} top={top}>
			<img src="/images/ball-triangle.svg" alt="loader" className={`loaderImg ${className? className: ''}`} />
		</LoaderStyles>
	);
};

export default memo(Loader);
