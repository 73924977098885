import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import FormElement from './FormElement';
import { useForm } from 'react-hook-form';
import { fetchWrapper } from '../../../_helpers/fetchWrapper';
import { toast } from 'react-toastify';
import { isEmpty, isJson } from '../../../_helpers';
import { WebSocketContext } from '../../../_redux/actions/ActionSocket';
import { VscLoading } from 'react-icons/vsc';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

function DraftFormInput({ id, hide, form }) {
	const address = useSelector((state) => state.user.address);
	const position = useSelector((state) => state.user.position);
	const { roomId } = useParams();
	const { register, handleSubmit } = useForm();
	const ws = useContext(WebSocketContext);
	const [loading, setLoading] = useState(false);
	const user = useSelector((state) => state.user.user);

	const multiSubmitElements = [
		{
			key: 'Signature',
			fieldName: 'Signature',
			type: 'signature',
		},
	];

	const sendMessage = async (message) => {
		// event.preventDefault();
		const reply = null;

		let permission = window.localStorage.getItem('messagePermission');
		permission = isJson(permission) ? JSON.parse(permission) : null;
		if (permission) {
			permission = permission[roomId];
		}
		if (message) {
			ws.sendMessage(message, reply, position, { chatroomId: roomId, permissionArr: permission });
		}
	};

	const onSubmit = async (data) => {
		try {
			setLoading(true);
			let location = {};
			if (address) {
				location.address = address;
			}
			if (position?.coords) {
				location.coordinates = [position?.coords.longitude, position?.coords.latitude];
			}
			let formData = {
				signature: data['Signature-undefined'],
				submissionId: id,
			};
			if (!isEmpty(location)) {
				formData.location = location;
			}
			const response = await fetchWrapper.patch('userFormData/signature', formData);
			toast.success('Form submitted succesfully');
			if (typeof hide === 'function') {
				hide(); 
			  }
			let message = ` ###${response.id}## $$${form.name}$ 
			- ${user.name} has Submitted the form
			`;
			sendMessage(message);
			setLoading(false);
		} catch (error) {
			console.log(error);
			toast.error('Something went wrong');
			setLoading(false);
		}
	};

	return (
		<div className="p-2 bg-light">
			<form onSubmit={handleSubmit(onSubmit)}>
				{multiSubmitElements.map((form, idx) => (
					<FormElement form={form} key={idx} elementRef={register} />
				))}
				<div className="text-center">
					<Button type="submit" variant="primary" disabled={loading}>
						{loading ? <VscLoading className="spin" /> : 'Submit'}
					</Button>
				</div>
			</form>
		</div>
	);
}

export default DraftFormInput;
