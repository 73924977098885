import { css } from 'twin.macro';
import { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row } from 'react-bootstrap';
import SideBar from '../Layouts/SideBar/SideBar';
import { getAllRooms } from '../../../_redux';
import { clearActivity, clearActivities } from '../../../_redux/actions/ActionActivity';
import { markAllAsReadActivities } from '../../../_redux/actions/ActionActivity';
import { MdClearAll } from 'react-icons/md';
import { ActivityContainerStyled } from './ActivityContainerStyles';
import MessageListener from '../Common/MessageListener';
import SingleActivity from './SingleActivity';
import { UPrimaryButton } from '../../../_elements/Buttons';
import { getTheDate } from '../../../_helpers';
import Loader from '../../../components/Admin/containers/Loader';
import { HiChevronDoubleUp } from 'react-icons/hi';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { useMemo } from 'react';

const ActivityContainer = ({
	activities,
	getAllRooms,
	rooms,
	clearActivities,
	clearActivity,
	markAllAsReadActivities,
	loading,
}) => {
	const [isClearBtnAnimation, setIsClearBtnAnimation] = useState(false);
	const [isMarkReadBtnAnimation, setIsMarkReadBtnAnimation] = useState(false);
	const [mobileSidebar, setMobileSidebar] = useState(false);
	useEffect(() => {
    if(rooms.length === 0) {
      getAllRooms();
    }
	}, [getAllRooms, rooms]);

	const handleClearBtnClick = () => {
		setIsClearBtnAnimation(true);
		// Handle Api Call to clear the acitivity.
		clearActivities();
	};

	const handleSingleClearBtnClick = (id) => {
		// setIsClearBtnAnimation(true);
		// Handle Api Call to clear the acitivity.
		clearActivity(id);
	};

	const handleMarkReadBtnClick = () => {
		setIsMarkReadBtnAnimation(true);
		markAllAsReadActivities();
	};

	const groups = useMemo(() => {
		return activities.reduce((groups, act) => {
			const date = getTheDate(new Date(act.createdAt));
			if (!groups[date]) {
				groups[date] = [];
			}
			groups[date].push(act);
			return groups;
		}, {});
	}, [activities]);

	return (
		<Row className="m-0 p-0">
			<MessageListener />
			<SwitchTransition>
				<CSSTransition key={mobileSidebar} timeout={500} classNames={'sidebar-ani-'}>
					<SideBar mobileSidebar={mobileSidebar} setMobileSidebar={setMobileSidebar} />
				</CSSTransition>
			</SwitchTransition>
			<ActivityContainerStyled>
				<div className="d-flex w-100 justify-content-between">
					<h1
						css={css`
							color: #757575;
							font-weight: bold;
							margin-left: 10px;
						`}
					>
						Activity
					</h1>

					<div>
						{activities.length > 0 && (
							<div className="d-flex">
								<UPrimaryButton
									className={`mr-4 ${isClearBtnAnimation ? 'animation-scaleUpDown' : ''}`}
									onClick={handleClearBtnClick}
									onAnimationEnd={() => setIsClearBtnAnimation(false)}
								>
									<MdClearAll size="24" />
									<span className="ml-2">Clear all activities</span>
								</UPrimaryButton>

								<UPrimaryButton
									className={`mr-4 ${isMarkReadBtnAnimation ? 'animation-scaleUpDown' : ''}`}
									onClick={handleMarkReadBtnClick}
									onAnimationEnd={() => setIsMarkReadBtnAnimation(false)}
								>
									<i className="fas fa-check"></i>
									<span className="ml-2">Mark all as read</span>
								</UPrimaryButton>
							</div>
						)}
					</div>
				</div>

				<div className="activity">
					{activities.length > 0 ? (
						Object.keys(groups).map((date) => (
							<div key={date}>
								<div className="actDate mt-4">{date}</div>
								<div className="mt-4">
									{groups[date] &&
										groups[date].length > 0 &&
										groups[date].map((act) => (
											<SingleActivity key={act._id} activity={act} handleClear={handleSingleClearBtnClick} />
										))}
								</div>
							</div>
						))
					) : loading ? (
						<Loader size="sm" />
					) : (
						<div className="space-entire align-center">
							<p className="text-dark">No activities at the moment</p>
						</div>
					)}
				</div>
			</ActivityContainerStyled>
			<div className="sidebar-toggle" onClick={() => setMobileSidebar(!mobileSidebar)}>
				{!mobileSidebar && <HiChevronDoubleUp />}
			</div>
		</Row>
	);
};

ActivityContainer.propTypes = {};

const mapStateToProps = (state) => ({
	activities: state.activities.activities,
	rooms: state.rooms.teamRooms,
	loading: state.ui.loading,
});

const mapDispatchToProps = { getAllRooms, clearActivities, clearActivity, markAllAsReadActivities };

export default connect(mapStateToProps, mapDispatchToProps)(ActivityContainer);
