export const prepareEvent = (rawEvent, validKeys) => {
	if (!validKeys || !Array.isArray(validKeys)) {
		validKeys = [
			'_id',
			'title',
			'description',
			'date',
			'dueDate',
			'recurringUntil',
			'status',
			'assignedUser',
			'forms',
			'startReminders',
			'endReminders',
			'userStartReminders',
			'userEndReminders',
			'recurring',
			'assignedChannel',
			'eventType',
			'hasConference',
			'subTasks',
			'eventMode',
			'trainings',
			'eventPackage',
		];
	}
	const event = JSON.parse(JSON.stringify(rawEvent));
	if (typeof event.eventType === 'object') event.eventType = event.eventType.id;
	event.assignedChannel = Array.isArray(event.assignedChannel)
		? event.assignedChannel
		: [event.assignedChannel?.value || event.assignedChannel];

	for (let key of ['startReminders', 'endReminders', 'userStartReminders', 'userEndReminders']) {
		event[key] = Array.isArray(event[key]) ? event[key] : []
	}

	if (event.forms) {
		event.forms = event.forms.map((form) => form._id || form.id || form);
		const getSubTask = (userId) => ({
			assignedUser: userId,
			date: event.date,
			forms: event.forms.map((form) => ({ form, submitted: false })),
		});
		event.subTasks = event.assignedUser?.map(getSubTask);
	}
	for (let key in event) {
		if (!validKeys.includes(key)) {
			delete event[key];
		}
	}
	return event;
};
