import { css } from '@emotion/react';
import dayjs from 'dayjs';
import React, { useState, useEffect, Fragment } from 'react';
import { Button, ListGroup, Modal } from 'react-bootstrap';

//components and helpers
import { getEvvServiceUnit, getEvvStaffUnit, roundDown, timeConvert } from '../../../../_helpers';
import VisitComment from './VisitComment';
import SandataVisitStatusCheck from './SandataVisitStatusCheck';
// import ColorCircle from './Label/ColorCircle';

function VisitModal({ show, visit, handleClose }) {
    const [billable, setBillable] = useState(false);
    const [visitData, setVisitData] = useState({});

    useEffect(() => {
        const billableStatus = (visit?.extraInfo?.BillVisit ?? true).toString() === 'true';
        setBillable(billableStatus);
        // if (commentRef?.current) {
        // 	commentRef.current.value = selectedVisit?.extraInfo?.memo;
        // }
        const visitTime = dayjs(visit?.ScheduleEndTime).diff(dayjs(visit?.ScheduleStartTime), 'm');
        const minutesPerUnit = visit?.extraInfo?.minutesPerUnit || 15;
        const billUnit =
            billableStatus && (visit?.extraInfo?.unitsConsumed || getEvvServiceUnit(visitTime, minutesPerUnit));
        const staffUnit =
            billableStatus && (visit?.extraInfo?.staffUnits || getEvvStaffUnit(visitTime, minutesPerUnit));
        setVisitData({ visitTime, minutesPerUnit, billUnit, staffUnit });
    }, [show, visit]);

    return (
        <Modal show={Boolean(show)} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Visit</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ overflowY: 'auto', height: '80vh' }}>
                {visit?.sandataUUID ? <SandataVisitStatusCheck sandataUUID={visit.sandataUUID} /> : null}
                <ListGroup
                    css={css`
                        .list-group-item {
                            display: flex;
                            justify-content: space-between;
                        }
                    `}
                >
                    <ListGroup.Item>
                        <b>Client Name:</b>{' '}
                        {visit?.client ? visit?.client?.firstName + ` ` + visit?.client?.lastName : 'Deleted Client'}
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <b>Provider:</b>{' '}
                        {visit?.provider ? (
                            visit?.provider.agencyName
                        ) : (
                            <span className="text-danger">Agency deleted</span>
                        )}
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <b>Employee:</b>{' '}
                        {visit?.employee ? (
                            visit?.employee?.user?.name
                        ) : (
                            <span className="text-danger">Employee deleted</span>
                        )}
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <b>Visit Start Time:</b> {dayjs(visit?.ScheduleStartTime).format('MM-DD-YYYY, h:mm A')}
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <b>Visit End Time:</b> {dayjs(visit?.ScheduleEndTime).format('MM-DD-YYYY, h:mm A')}
                    </ListGroup.Item>
                    {visit?.extraInfo && (
                        <ListGroup.Item>
                            <b>Service code:</b> {visit?.extraInfo?.ProcedureCode}{' '}
                            {visit?.extraInfo?.Modifier1 ? visit?.extraInfo?.Modifier1 : ' - '}{' '}
                            {visit?.extraInfo?.Modifier2 ? visit?.extraInfo?.Modifier2 : ' - '}{' '}
                            {visit?.extraInfo?.Modifier3 ? visit?.extraInfo?.Modifier3 : ' - '}{' '}
                            {visit?.extraInfo?.Modifier4 ? visit?.extraInfo?.Modifier4 : ' - '}{' '}
                        </ListGroup.Item>
                    )}
                    <ListGroup.Item>
                        <b>Total Time:</b>{' '}
                        {timeConvert(dayjs(visit?.ScheduleEndTime).diff(dayjs(visit?.ScheduleStartTime), 's'))}
                    </ListGroup.Item>

                    <ListGroup.Item>
                        <b>Billable Time:</b>
                        {billable ? roundDown(visitData?.visitTime, visitData?.minutesPerUnit) : '--:--'}
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <b>Units:</b>
                        {billable ? visitData?.billUnit : 'none'}
                    </ListGroup.Item>
                    {visit?.extraInfo?.serviceRate ? (
                        <Fragment>
                            <ListGroup.Item>
                                <b>Service Rate (Per Unit):</b>$ {visit.extraInfo.serviceRate}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <b>Service Amount:</b>${' '}
                                {parseFloat(visit.extraInfo.serviceRate * visitData?.billUnit).toFixed(2)}
                            </ListGroup.Item>
                        </Fragment>
                    ) : null}

                    {visit?.extraInfo?.staffRate ? (
                        <Fragment>
                            <ListGroup.Item>
                                <b>Staff Time:</b>
                                {timeConvert(visit?.extraInfo?.staffMinutesConsumed * 60)}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <b>Staff Rate (Per Hour):</b>$ {visit.extraInfo.staffRate}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <b>Staff Amount:</b>$ {parseFloat(visit?.extraInfo?.staffAmount || 0)?.toFixed(2)}
                            </ListGroup.Item>
                        </Fragment>
                    ) : null}
                    {visit?.extraInfo?.memo ? (
                        <Fragment>
                            <ListGroup.Item>
                                <div>
                                    <b>Remarks:</b>
                                </div>
                                <p>{visit.extraInfo.memo}</p>
                            </ListGroup.Item>
                        </Fragment>
                    ) : null}
                    {visit?.reason ? (
                        <Fragment>
                            <ListGroup.Item>
                                <div>
                                    <b>Reason:</b>
                                </div>
                                <p>{visit.reason}</p>
                            </ListGroup.Item>
                        </Fragment>
                    ) : null}
                    <hr />
                    <ListGroup.Item>
                        <VisitComment visit={visit} />
                    </ListGroup.Item>
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default VisitModal;
