import React from 'react';
import { connect } from 'react-redux';
import { BiArrowBack } from 'react-icons/bi';

//components
import SearchConversation from '../../../Features/Teams/SearchConversation';
import { searchChat, clearSearchMessages } from '../../../../_redux';

//css
import { subChannelTitleBar, subChannelBar } from '../../../Features/Teams/TeamHeadingStyles';

const EventChannelHeading = ({
	subChannel,
	updateSearchText,
	searchText,
	searchChat,
}) => {

	const formattedSubTitle = subChannel?.subTitle?.replace(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/g,
		d => {
			return new Date(d).
				toLocaleString('en-US', {
					month: 'long',
					day: 'numeric',
					year: 'numeric',
					hour: '2-digit',
					minute: '2-digit',
					hour12: true,
					meridiem: 'AM/PM',
				})
		});

	return (
		<div>
			<div css={subChannelTitleBar}>
				<BiArrowBack size={45} className="pointer" onClick={() => window.history.back()} />
				<div>
					<h1> {subChannel?.title} </h1>
					<h4> {formattedSubTitle} </h4>
				</div>
			</div>
			<div css={subChannelBar}>
				<SearchConversation
					searchChat={searchChat}
					subChannelId={subChannel?.id}
					isDirectChat={false}
					updateSearchText={updateSearchText}
					searchText={searchText}
				/>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	teams: state.teams.teams,
	user: state.user.user,
	organizations: state.organizations.organizations,
	isSearch: state.messages,
	channelMembers: state.rooms.channelMembers,
	roomsRef: state.rooms.roomsRef,
});
export default connect(mapStateToProps, {
	searchChat,
	clearSearchMessages,
})(EventChannelHeading);
