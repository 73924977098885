import React, { useState, Fragment, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { BiLinkExternal } from 'react-icons/bi';
import { connect } from 'react-redux';
import { ToolTip } from '../../../../_elements/ToolTip';

//components
import { AdminTable } from '../../reusableStyles';

//helpers
import { fetchWrapper } from '../../../../_helpers';

//css
import { ActionStyles } from '../../reusableStyles';
import { TbArrowBarRight } from 'react-icons/tb';

const ViewEventChannels = ({ handleEventChannel, eventId, user, teamId }) => {
	const userRole = user?.role;

	const rolecheck = user.teamList.find((team) => team.team && team.team?.id === teamId)?.role;
	const userId = user?.id || user?._id;
	const [show, setShow] = useState(false);
	const [userSubChannels, setUserSubChannels] = useState([]);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const getUserSubChannels = async () => {
		try {
			const response = await fetchWrapper.get(`/subchannels/me?taskId=${eventId}`);

			const access = (userRole === 'admin') || rolecheck === 'teamManager' || rolecheck ===  'owner' || (user?.roomList?.find((room) => (room?.room?._id || room?.room?.id) === response?.results[0]?.assignedChannel)?.role === 'supervisor'
			) 

			if(access){
				setUserSubChannels(response?.results);
			}
			else {
				const subChannels = response?.results?.filter((channel) => channel.owner === userId);
				setUserSubChannels(subChannels || []);
			}

			// else if (response?.results?.map(subchannel => {
			// 	subchannel.assignedUser[0].find(accessId => accessId === userId)
			// })){
			// 	setUserSubChannels(response?.results);
			// } 
			// else {
			// 	setUserSubChannels([]);
			// }
		} catch (e) {
			console.log(e, 'subchannels not found ');
		}
	};

	useEffect(() => {
		if (eventId && show) {
			getUserSubChannels();
		}
	}, [eventId, show]);

	const handleEventChannelClick = async (id) => {
		handleEventChannel(id);
	};

	return (
		<Fragment>
			<ToolTip text="SubChannel" cssStyles={"top:-120% !important; min-width: 97px !important; left: -88px !important"}>
				<TbArrowBarRight size={25} onClick={handleShow} />
			</ToolTip>
			{/* <Button onClick={handleShow}>subChannel</Button> */}
			<Modal show={show} onHide={handleClose} size="xl">
				<Modal.Header className="h2 font-weight-bold d-flex justify-content-center">
					{' '}
					User Sub-Channels{' '}
				</Modal.Header>
				<Modal.Body className="m-2">
					<AdminTable className="mb-5 w-100">
						<thead>
							<tr>
								<th>S.N.</th>
								<th> Sub-Channel Name</th>
								<th>Actions</th>
							</tr>
						</thead>
						<tbody>
							{userSubChannels.length > 0 &&
								userSubChannels
									.sort((a, b) => (a.title > b.title ? 1 : -1))
									.map((subchannel, index) => (
										<tr key={subchannel._id}>
											<td className="text-left p-1">{index + 1}</td>
											<td className=" text-left p-1 pl-4">{subchannel.title}</td>
											<td className=" text-left p-1">
												<ActionStyles>
													<div>
														<ToolTip text={'Redirect to Sub Channel'} cssStyles={"top:-70% !important; left: -150% !important; min-width: 156px !important"}>
															<BiLinkExternal
																onClick={() => handleEventChannelClick(subchannel._id)}
																className="icon view"
															/>
														</ToolTip>
													</div>
												</ActionStyles>
											</td>
										</tr>
									))}
						</tbody>
					</AdminTable>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</Fragment>
	);
};

const mapStateToProps = (state) => ({
	user: state.user.user
});

export default connect(mapStateToProps, {})(ViewEventChannels);
