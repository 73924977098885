import { css } from 'twin.macro';
import { useState, Fragment, useCallback } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
// import { yupResolver } from '@hookform/resolvers';

import noImg from '../../../assets/no-img.png';

import { formLabel } from '../../../_elements/formLabel';
import formInput from '../../../_elements/formInput';
import { connect } from 'react-redux';
import { editATeamAvatar, editATeam } from '../../../_redux/actions/ActionOrganization';
import { Link } from 'react-router-dom';

//icons
import { FaEdit } from 'react-icons/fa';
import { editIcon, editStyle } from './TeamSettings/styles/CreateFirstTeamStyles';
import { getUserData, deleteATeamById } from '../../../_redux';
import { IMAGESTYPES } from '../../../contants/enums';

const editBtn = css`
	img {
		-webkit-filter: grayscale(0) blur(0);
		filter: grayscale(0) blur(0);
		-webkit-transition: 0.3s ease-in-out;
		transition: 0.3s ease-in-out;
	}
	.edit:hover > i {
		opacity: 1 !important;
	}
	.edit:hover > img {
		-webkit-filter: grayscale(100%) blur(3px);
		filter: grayscale(100%) blur(3px);
	}
`;
function EditTeam({ team, organizations, editATeam, editATeamAvatar, type, visibleDeleteteam, goToTeamPage }) {
	const [show, setShow] = useState(false);
	// const [fileName, setFileName] = useState('');
	// const [uploadImage, setUploadImage] = useState(false);
	const dispatch = useDispatch();
	const [file, setFile] = useState(null);
	const handleClose = () => setShow(false);
	const handleShow = () => {
		setShow(true);
		setFile(null);
	};
	const { register, handleSubmit, errors, clearErrors } = useForm({
		mode: 'onBlur',
	});

	const onSubmit = (newTeam) => {
		console.log(newTeam);
		if (file) {
			const tempFile = new FormData();
			tempFile.append('avatar', file);
			editATeamAvatar(team.id, organizations[0].id, tempFile);
			let updatedTeam = {
				admin: team.admin,
				name: team.name,
			};
			if (team.name !== newTeam.name) {
				updatedTeam.name = newTeam.name;
			}
			editATeam(organizations[0].id, team.id, updatedTeam);
		}
		if (team.name !== newTeam.name) {
			const updatedTeam = { admin: team.admin, name: newTeam.name };
			editATeam(organizations[0].id, team.id, updatedTeam);
		}
		setShow(false);
	};

	const handleFile = (e) => {
		const maxFileSize = 20 * 1024 * 1024;
		const imageFile = e.target.files[0];
		if (imageFile && imageFile.size < maxFileSize) {
			setFile(imageFile);
			// setFileName(imageFile.name);
			// setUploadImage(true);
		} else {
			toast.warn('Image Must be less than 20 mb in size!');
		}
	};

	const deleteTeam = useCallback(() => {
		dispatch(deleteATeamById(organizations[0].id, team.id));
		dispatch(getUserData());
		goToTeamPage();
	}, [dispatch, organizations, team.id, goToTeamPage]);

	const handleDeleteAlert = (client) => {
		Swal.fire({
			title: `Are you sure you want to Delete this Team ?`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!',
		}).then((result) => {
			if (result.isConfirmed) {
				deleteTeam();
			}
		});
	};

	return (
		<Fragment>
			{type === 'admin' ? (
				<Link className="px-2" onClick={handleShow}>
					<FaEdit />
				</Link>
			) : (
				<div className="action-btn" onClick={handleShow}>
					<FaEdit />
				</div>
			)}

			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title className="modal-headers">Edit a team</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="text-center">
						<label className="position-relative" css={editBtn}>
							<input
								onChange={(e) => handleFile(e)}
								className="d-none"
								accept={IMAGESTYPES}
								type="file"
								name="avatar"
							/>
							<div className="edit">
								<img
									src={file ? URL.createObjectURL(file) : team?.avatar ? team?.avatar : noImg}
									css={editStyle}
									alt="avatar"
								/>

								{!false ? (
									<i className="fas fa-pen text-primary" css={editIcon}></i>
								) : (
									<div
										css={css`
											position: absolute;
											top: 50%;
											left: 50%;
											transform: translate(-50%, -50%);
										`}
									>
										<div className="spinner-border" role="status">
											<span className="sr-only">Loading...</span>
										</div>
									</div>
								)}
							</div>
						</label>
					</div>
					<Form.Group controlId="formBasicEmail">
						<Form.Label css={formLabel}>Team's Name</Form.Label>
						<Form.Control
							css={formInput}
							type="text"
							name="name"
							defaultValue={team.name}
							// placeholder={team.name}
							ref={register}
							onChange={() => clearErrors()}
						/>
						<Form.Text className="text-danger ">{errors.name?.message}</Form.Text>
					</Form.Group>
					{visibleDeleteteam && (
						<div>
							<Form.Label css={formLabel}>
								<h3 className="text-danger mt-4 mb-0">Delete this Team</h3>
								<span className="d-block small">
									( Once you delete a team, there is no going back. Please be certain. )
								</span>
							</Form.Label>

							<Button
								variant="outline-danger"
								className="d-block"
								css={css`
									font-size: 1.6rem;
								`}
								onClick={handleDeleteAlert}
							>
								Yes, I am sure.
							</Button>
						</div>
					)}

					<div
						className="action-btn delete ml-2"
						// onClick={() => deleteTeam(team[0]?.id, team[0]?.name)}
					></div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
					<Button variant="primary" onClick={handleSubmit(onSubmit)}>
						Update
					</Button>
				</Modal.Footer>
			</Modal>
		</Fragment>
	);
}

const mapStateToProps = (state) => ({
	organizations: state.organizations.organizations,
});

export default connect(mapStateToProps, { editATeam, editATeamAvatar })(EditTeam);
