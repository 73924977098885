import React, { useState, Fragment } from 'react';
import { Modal, Button } from 'react-bootstrap';
import StaticPreview from '../../Admin/Form/StaticPreview';
import { BsEye } from 'react-icons/bs';
import { ToolTip } from '../../../_elements/ToolTip';
import { TagPreviewWithContainer } from '../Common/Tags/TagsPreview';

export default function FormPreviewModal({ form }) {
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	return (
		<Fragment>
			<ToolTip text={'View'} cssStyles={"top:-70% !important; min-width: fit-content !important;"}>
				<BsEye onClick={handleShow} className="icon view" />
			</ToolTip>
			<Modal show={show} onHide={handleClose} size="lg">
				<Modal.Header>
					<div>
						{form?.name}
						<TagPreviewWithContainer tags={form?.tags || []} />
					</div>
				</Modal.Header>
				<Modal.Body>
					<StaticPreview formElements={form.data} isPreview />
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</Fragment>
	);
}
