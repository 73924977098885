import { css } from 'twin.macro';
// import { Image, Placeholder } from 'cloudinary-react';

// Styles
import { VerifiedUserCheck, ArchivedUser } from '../reusableStyles';

// Assets
import ArchivedAvatar from '../../../assets/archivedUser.svg';
import { FaCheck } from 'react-icons/fa';
import { AiOutlineUserDelete } from 'react-icons/ai';
import { Image } from 'react-bootstrap';

const avatarContainer = css`
	position: relative;
	margin: auto;
	width: 3.8rem;
	height: 3.8rem;
	margin: 10px auto;
	.avatar {
		position: relative;
		width: 3.8rem;
		height: 3.8rem;
		border-radius: 12px;
	}
	.noimg-container {
		border: none;
		display: flex;
		margin: auto;
	}
	.noimg-container span {
		margin: auto;
		color: #fff;
		font-size: 10px;
	}
	.noimg-container p {
		margin: auto;
		color: #fff;
		font-size: 14px;
	}
`;

const AdminTablesImg = ({ item }) => {
	let avatar = item?.user?.avatar || item.avatar;
	let name = item?.user?.name || item.name;
	if (item.status === 'archive') {
		return (
			<div css={avatarContainer}>
				<img src={ArchivedAvatar} className="avatar" alt="profile" />
				<span css={ArchivedUser}>
					<AiOutlineUserDelete
						color="white"
						css={css`
							position: absolute;
							top: 50%;
							left: 50%;
							width: 1.5rem;
							height: 1.5rem;
							transform: translate(-50%, -50%);
						`}
					/>
				</span>
			</div>
		);
	} else {
		if (avatar) {
			return (
				<div css={avatarContainer}>
					{/* <img src={item.avatar} className="avatar" alt="profile" /> */}
					{/* {avatar.split('/')[2] === 'res.cloudinary.com' ? ( */}
					<Image
						// cloudName={avatar?.split('/')[3]}
						// loading="lazy"
						// publicId={avatar.split('/')[avatar.split('/').length - 1]}
						// quality="auto"
						// fetchFormat="auto"
						src={avatar}
						className="avatar"
						width="30"
						alt={name}
					>
						{/* <Placeholder type="blur" /> */}
					</Image>
					{/* ) : (
						<Image
							cloudName={avatar?.split('/')[3]}
							loading="lazy"
							publicId={avatar.startsWith('http:') ? avatar.split('http:').join('https:') : avatar}
							quality="auto"
							fetchFormat="auto"
							className="avatar"
							width="56"
							alt={name}
						>
							<Placeholder type="blur" />
						</Image>
					)} */}
					{item.isEmailVerified && (
						<span css={VerifiedUserCheck}>
							<FaCheck
								color="white"
								css={css`
									position: absolute;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
								`}
							/>
						</span>
					)}
				</div>
			);
		} else {
			if (item.color && item.initials) {
				return (
					<div css={avatarContainer}>
						<div
							className="noimg-container avatar"
							style={{
								background: item.color,
							}}
						>
							<p>{item.initials}</p>
							{item.isEmailVerified && (
								<span css={VerifiedUserCheck}>
									<FaCheck
										color="white"
										css={css`
											position: absolute;
											top: 50%;
											left: 50%;
											transform: translate(-50%, -50%);
										`}
									/>
								</span>
							)}
						</div>
					</div>
				);
			} else {
				return null;
			}
		}
	}
};

export default AdminTablesImg;
