import { css } from 'twin.macro';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

//redux
import { connect } from 'react-redux';
import { VscLoading } from 'react-icons/vsc';
import { AiOutlineClose } from 'react-icons/ai';
import { HiChevronDoubleUp } from 'react-icons/hi';
import InfiniteScroll from 'react-infinite-scroller';
import ChatListSkeleton from '../../../Features/Layouts/ChatListSkeleton';
import { ChatListStyled } from '../../../Features/Chat/styles/ChatListStyled';
import { listWrapper, searchInput, topSection } from '../../../Features/Teams/styles/TeamListStyles';
import { avatarContainer } from '../../../Profile/styles/ProfileStyles';

// helpers
import { getAllSubChannels, setLastSeenSubChannel } from '../../../../_redux';
import { fetchWrapper, getUniqueObjects, getSubchannelIdFromURL, getInitials, getRandomColor } from '../../../../_helpers';

const SearchInput = (props) => (
	<input
		className="inputfield border-0 outline-none"
		type="text"
		placeholder={props.placeholder}
		onChange={(e) => props.setSearch(e.target.value)}
		value={props.value}
		css={props.styleProps}
	/>
);

const textEllipsisStyle = css`
	font-size: 15px;
	width:30rem;
	text-overflow:ellipsis;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`

const initialFilteredResponse = {
	totalPages: 0,
	page: 0,
	title: null
}

const EventChannelList = ({
	currUser,
	mobileSidebar,
	setMobileSidebar,
	isLoadingSubchannels,
	subChannelList,
	getAllSubChannels,
	setLastSeenSubChannel,
	totalSubChannels
}) => {

	useEffect(() => {
		if (!subChannelList.length) {
			getAllSubChannels();
		}
	}, []);

	const [search, setSearch] = useState('');
	const [filteredSubchannelEvents, setFilteredSubchannelEvents] = useState(null);
	const [index, setIndex] = useState(getSubchannelIdFromURL());
	const [loading, setLoading] = useState(false);
	const [showSearchResults, setShowSearchResults] = useState(false);
	const [filteredResponse, setFilteredResponse] = useState(initialFilteredResponse);
	const debounceTimeoutRef = useRef(null);

	useEffect(() => {
		(async function (){
			if(search){
				onSearchChanged();
				const debounceFetch = async () => {
					await fetchSubchannels({ title: search });
				};
				clearTimeout(debounceTimeoutRef.current);
				debounceTimeoutRef.current = setTimeout(debounceFetch, 1000);
			}else{
				clearSearch();
			}
		})()
	}, [search]);

	const history = useHistory();
	const handleClick = useCallback(
		(subChannel) => {
			setIndex(subChannel.id);
			setLastSeenSubChannel(subChannel.id)
			history.push(`/event-channel/${subChannel.id}`);
		},
		[history]
	);

	const onSearchChanged = async () => {
		setFilteredResponse(prev=>({...initialFilteredResponse,prevTitle:prev?.prevTitle}));
		setFilteredSubchannelEvents(null);
		setShowSearchResults(search.length > 0)
	}
	const clearSearch = async () => {
		setSearch('');
		onSearchChanged();
	};

	const loadFunc = async (page) => {
		if (loading || search === "") {
			return;
		}
		const debounceFetch = async () => {
			await fetchSubchannels({ title: search, page: (filteredResponse?.prevTitle === search ? filteredResponse.page + 1 : 1) });
		};
		clearTimeout(debounceTimeoutRef?.current);
		debounceTimeoutRef.current = setTimeout(debounceFetch, 1000);
	};

	const loadFuncRedux = async (page) => {
		if (isLoadingSubchannels) {
			return;
		}
		const debounceFetch = async () => {
			await getAllSubChannels(page);
		};
		clearTimeout(debounceTimeoutRef.current);
		debounceTimeoutRef.current = setTimeout(debounceFetch, 1000);
	};

	const fetchSubchannels = async (queryObj) => {
		try {
			const { limit = 20, page = 1, title = null } = queryObj || {};
			if(!title || (filteredResponse?.page > filteredResponse.totalPages) || ((filteredResponse?.title === title) && (filteredResponse?.page === page) )){
				return;
			}
			setLoading(true);
			const response = await fetchWrapper.get(`subchannels?limit=${limit}&page=${page}${title ? `&title=${title}` : ''}`);
			const uniqueSubChannels = getUniqueObjects(filteredResponse?.prevTitle === title ? (filteredSubchannelEvents || []) :  [], response?.results)
			setFilteredResponse(prev=>({
				totalPages: response?.totalPages || 0,
				page: response?.totalPages >= response?.page ? response.page : 0,
				prevTitle:title
			}))
			setFilteredSubchannelEvents(uniqueSubChannels);
		} catch (error) {}
		finally {
			setLoading(false);
		}
	};

	const subChannelAvatarColor = (subChannel) => {
		return getRandomColor(subChannel._id || subChannel.id);
	}

	const subChannelAvatarInitials = (subChannel) => {
		return getInitials(subChannel.title || "");
	}

	return (
		<div css={listWrapper} id="style-1">
			<div>
				<div css={topSection}>
					<div
						className="d-flex flex-row"
						css={css`
							overflow: hidden;
						`}
					>
						<SearchInput
							setSearch={setSearch}
							styleProps={searchInput}
							placeholder="Search Subchannels"
							value={search}
						/>
						{search && (
							<button
								css={css`
									outline: none;
									color: #191919;
									padding: 0 1rem;
									display: flex;
									justify-content: center;
									align-items: center;
									font-size: 24px;
									border: none;
									background: white;
									svg {
										transition: transform 250ms;
									}
									:hover > svg {
										transform: scale(1.2);
									}
								`}
								onClick={() => {
									clearSearch();
								}}
							>
								<AiOutlineClose />
							</button>
						)}
					</div>
				</div>
				{
					!showSearchResults ? null : (<React.Fragment>
						{
							filteredSubchannelEvents === null ? 
							(
								<div css={css`width: 100%;overflow: hidden;`}>
									<ChatListSkeleton />
								</div>
							) : null						
						}
						<div className="d-flex flex-column p-0">
								<ChatListStyled>
									<InfiniteScroll
										pageStart={1}
										loadMore={loadFunc}
										initialLoad={false}
										hasMore={filteredResponse?.totalPages > filteredResponse.page}
										loader={
											<div
												className="loader"
												key={'loader-user-infinite-event-chat-list'}
												css={css`
													height: 100%;
													display: flex;
													justify-content: center;
													align-items: center;
												`}>
												<VscLoading fontSize={34} className="spin" />
											</div>
										}
										useWindow={false}
									>
										{
											(filteredSubchannelEvents?.length === 0 && !loading) ?
												(<div css={css`
														font-size: 1.8rem;
														// width: 100%;
														text-align: center;
														justify-content: center;
														align-items: center;
														margin-top: 2rem;
													`}>
													Subchannel Not Found
												</div>)
												:
												filteredSubchannelEvents?.filter((event) => event?._id)?.map((subChannelEventFiltered, i) => {
													return (
														<div
															key={subChannelEventFiltered.id}
															className={` chat-list-item  ${index === subChannelEventFiltered.id ? 'active' : ''}`}
															onClick={() => {
																if (index !== subChannelEventFiltered.id) {
																	handleClick(subChannelEventFiltered);
																}
															}}
														>
															<div className="avatar-details">
																<div css={avatarContainer}>
																	<div className="replacementImg avatar" style={{ background: subChannelAvatarColor(subChannelEventFiltered) }}>
																		<span
																			css={css`font-size: 18px !important;margin-top: 0 !important;color: white !important;`}>
																			{subChannelAvatarInitials(subChannelEventFiltered)}
																		</span>
																	</div>
																</div>
																<div className="" css={css`margin-left:8px;`}>
																	<div
																		className="font-weight-bold"
																		css={textEllipsisStyle}
																	>
																		{subChannelEventFiltered.title}
																	</div>
																	<div className="d-flex">
																		<div className={`text-muted`} css={textEllipsisStyle}
																		>
																			{subChannelEventFiltered?.roomData?.title}
																		</div>
																	</div>
																</div>
															</div>
														</div>
													);
												})
										}
										<span></span>
									</InfiniteScroll>
								</ChatListStyled>
						</div>
					</React.Fragment>)
				}
				{/* subchannels stored in redux */}
				{
					showSearchResults ? null :
						<div className="d-flex flex-column p-0">
							{isLoadingSubchannels ? (
								<div
									css={css`
								width: 100%;
								overflow: hidden;
							`}
								>
									<ChatListSkeleton />
								</div>
							) : (
								<ChatListStyled>
									<InfiniteScroll
										// key={search}
										pageStart={1}
										loadMore={loadFuncRedux}
										hasMore={totalSubChannels > subChannelList.length}
										loader={
											<div
												className="loader"
												key={'loader-user-infinite-event-list-redux'}
												css={css`
													height: 100%;
													display: flex;
													justify-content: center;
													align-items: center;
												`}
											>
												<VscLoading fontSize={34} className="spin" />
											</div>
										}
										useWindow={false}
									>
										{
											(subChannelList?.length === 0) ?
												(<div css={css`
														font-size: 1.8rem;
														// width: 100%;
														text-align: center;
														justify-content: center;
														align-items: center;
														margin-top: 2rem;
													`}>
													No Sub Channels
												</div>)
												:
												subChannelList?.filter((event) => event?._id)?.map((subChannelEvent, i) => {
													return (
														<div
															key={subChannelEvent.id}
															className={` chat-list-item  ${index === subChannelEvent.id ? 'active' : ''}`}
															onClick={() => {
																if (index !== subChannelEvent.id) {
																	handleClick(subChannelEvent);
																}
															}}
														>
															<div className="avatar-details">
																<div css={avatarContainer}>
																	<div className="replacementImg avatar" style={{ background: subChannelAvatarColor(subChannelEvent) }}>
																		<span
																			css={css`font-size: 18px !important;margin-top: 0 !important;color: white !important;`}>
																			{subChannelAvatarInitials(subChannelEvent)}
																		</span>
																	</div>
																</div>
																<div className="" css={css`margin-left:8px;`}>
																	<div
																		className="font-weight-bold"
																		css={textEllipsisStyle}
																	>
																		{subChannelEvent.title}
																	</div>
																	<div className="d-flex">
																		<div className={`text-muted`} css={textEllipsisStyle}
																		>
																			{subChannelEvent?.roomData?.title}
																		</div>
																	</div>
																</div>
															</div>
														</div>
													);
												})
										}
									</InfiniteScroll>
								</ChatListStyled>
							)}
						</div>
				}

			</div>
			<div className="sidebar-toggle" onClick={() => setMobileSidebar(!mobileSidebar)}>
				{!mobileSidebar && <HiChevronDoubleUp />}
			</div>
		</div>
	);
};
const mapStateToProps = (state) => ({
	currUser: state.user.user,
	subChannelList: state.subChannels.subChannels,
	isLoadingSubchannels: state.subChannels.isLoading,
	totalSubChannels: state.subChannels.totalSubChannels
});

EventChannelList.propTypes = {
};

const mapDispatchToProps = {
	getAllSubChannels,
	setLastSeenSubChannel
};
export default connect(mapStateToProps, mapDispatchToProps)(
	EventChannelList
);
