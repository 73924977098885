import { css } from 'twin.macro';

// import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SideBar from '../Layouts/SideBar/SideBar';
import { getAllRooms } from '../../../_redux';
import Select from 'react-select';
import MessageListener from '../Common/MessageListener';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { HiChevronDoubleUp } from 'react-icons/hi';

const courseStyles = css`
    background-color: #f5f5f5;
    height: 100vh;

    .course-body {
        margin-left: 8rem;
        padding: 10px;
        @media only screen and (max-width: 767px) {
            margin-left: 0;
        }
    }
    .course_div {
        padding-left: 9rem;
        padding-top: 4rem;
        height: 100vh;
    }
    @media only screen and (max-width: 767px) {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .iFrame {
        width: 100%;
        height: calc(100vh - 150px);
    }
`;

const CourseContainer = ({ activities, getAllRooms, rooms, user }) => {
    const [linkId, setLinkId] = useState(null);
    const [mobileSidebar, setMobileSidebar] = useState(false);

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? 'white' : 'grey',
            padding: 10,
            backgroundColor: state.isSelected && '#4FAEB0',
        }),
        control: (provided) => ({
            ...provided,
            width: 400,
            marginBottom: 10,
            boxShadow: 'none',
            border: 'none',
        }),
    };

    const courseOptions = [
        { value: '25', label: '001 Intro to DSP123', id: '001' },
        { value: '26', label: '002 Home and Community Care', id: '002' },
        { value: '28', label: '003 Principles of Intellectual Disabilities', id: '003' },
        { value: '29', label: '004 Supporting Individuals With ASD', id: '004' },
        { value: '31', label: '005 Behavioral Analysis & Support', id: '005' },
        { value: '32', label: '006 HIPAA Compliance', id: '006' },
        { value: '33', label: '007 Incident Management', id: '007' },
        { value: '34', label: '008 Basic CPR & First Aid', id: '008' },
        { value: '35', label: '009 Emergency Preparation & Reporting', id: '009' },
        { value: '36', label: '010 First Responders', id: '010' },
        { value: '37', label: '011 Ethical Boundaries', id: '011' },
        { value: '38', label: '012 General Fire Safety', id: '012' },
        { value: '30', label: '013 Identifying Abuse, Neglect & Exploitation', id: '013' },
        { value: '19', label: '017 Introduction to Medication Administration & Management Part 1', id: '017' },
        { value: '40', label: '020 Smart Goals', id: '020' },
        { value: '41', label: '021 Intro to ISP', id: '021' },
        { value: '42', label: '023 Coping Strategieis', id: '023' },
        { value: '27', label: '024 Empathy', id: '024' },
        {
            value: '20',
            label: '026 Medication Administration and Management Part 2 - How to safely administer medication',
            id: '026',
        },
        { value: '21', label: '027 Medication Administration and Management Part 3', id: '027' },
        { value: '22', label: '028 Medication Administration and Management Part 4', id: '028' },
        { value: '23', label: '028 Medication Administration and Management Part 5 Documentation', id: '028' },
        {
            value: '24',
            label: '030 Medication Admin. & Mgmt. Part 6 Controlled Substance & Medication Errors',
            id: '030',
        },
    ];

    const onSelectChange = (value) => {
        setLinkId(value.value);
    };

    return (
        <div css={courseStyles}>
            <MessageListener />
            <SwitchTransition>
                <CSSTransition key={mobileSidebar} timeout={500} classNames={'sidebar-ani-'}>
                    <SideBar mobileSidebar={mobileSidebar} setMobileSidebar={setMobileSidebar} />
                </CSSTransition>
            </SwitchTransition>
            <div className="course-body">
                <h1
                    css={css`
                        color: #757575;
                        font-weight: bold;
                        margin-left: 10px;
                    `}
                >
                    Courses
                </h1>
                <div className="w-25 my-4">
                    <Select
                        styles={customStyles}
                        options={courseOptions}
                        placeholder="Select Courses"
                        onChange={onSelectChange}
                    />
                </div>
                <div
                    class="holds-the-iframe"
                    css={css`
                        background: url(/images/ball-triangle.svg) center center no-repeat;
                    `}
                >
                    <iframe
                        title="iframe-123"
                        className="iFrame"
                        src={`${process.env.REACT_APP_AMOL_URL}/AMOL/course.php?course_id=${linkId}&sso_secret=6978191bdce4dab98379961c7050886210395241dbbf706ebcf3f6fee224a13d&sso_first_name=${
                            user.name.split(' ')[0]
                        }&sso_last_name=${user.name.split(' ')[user.name.split(' ').length - 1]}&sso_email_address=${
                            user.email
                        }`}
                    ></iframe>
                </div>
            </div>
            <div className="sidebar-toggle" onClick={() => setMobileSidebar(!mobileSidebar)}>
                {!mobileSidebar && <HiChevronDoubleUp />}
            </div>
        </div>
    );
};

CourseContainer.propTypes = {};

const mapStateToProps = (state) => ({
    activities: state.activities.activities,
    rooms: state.rooms.teamRooms,
    user: state.user.user,
});

const mapDispatchToProps = { getAllRooms };

export default connect(mapStateToProps, mapDispatchToProps)(CourseContainer);
