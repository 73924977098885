import { css } from 'twin.macro';
import React, { useState, useEffect, Fragment, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { Map, Marker, GoogleApiWrapper, InfoWindow } from 'google-maps-react';
import { LoadingContainer } from '../../_elements/LoadingContainer';
import { getInitials, getMapCenterAndZoom, getRandomColor } from '../../_helpers';
import MapPointer from '../../assets/icons/map_pointer.png';
import HealthCarePointer from '../../assets/icons/healtcare.jpg';
import RealEstatePointer from '../../assets/icons/realState.png';
// import ShopPointerPointer from '../../assets/icons/shop.png';
import HighlightPointer from '../../assets/icons/pointer.gif';

const mapStyles = {
	position: 'absolute',
	width: '100%',
	height: 'auto',
};

const MARKER = {
	shop: HighlightPointer,
	default: MapPointer,
	'real state': RealEstatePointer,
	'health care': HealthCarePointer,
};
function GoogleMap({
	google,
	lat,
	lng,
	showPublicChannels = true,
	setChannelLatLong,
	isChannelCreateSelected,
	setShowCreateChannelModal,
	sholudUpdatePublicChannels,
	handleChatroomsEvents,
	currentMarker,
	searchedRoomId,
	setIsEvents,
	isEvents = false,
	chatroomEvents = null,
	publicChannels,
	...rest
}) {
	const [center, setCenter] = useState({});
	useEffect(() => {
		setCenter({ lat: lat, lng: lng });
	}, [lat, lng]);
	const [activeMarker, setActiveMarker] = useState({});
	const [selectedPlace, setSelectedPlace] = useState({});
	const [showingInfoWindow, setShowingInfoWindow] = useState(false);
	const [chatroomId, setChatroomId] = useState('');
	const [tempChannels, setTempChannels] = useState([]);

	const mapRef = useRef(null);

	const searchRefs = useRef([]);

	//this getEventsByCategoryChannels function checks if there is chatroomEvents then it sets the public channels to the filtered event eliminating rest of the public channels and only one public channel is seted and seen

	const getEventsByCategoryChannels = async () => {
		if (isEvents && chatroomEvents && chatroomEvents?.length > 0) {
			const filteredArray = publicChannels?.filter((chatroom) =>
				chatroomEvents?.some((event) => chatroom._id === event?.assignedChannel[0])
			);
			setTempChannels(filteredArray);
		} else {
			setTempChannels(publicChannels);
		}
	};

	useEffect(() => {
		getEventsByCategoryChannels();
	}, [chatroomEvents]);

	useEffect(() => {
		const getSelectedChatroomEvents = async () => {
			if (chatroomId) {
				// const res = await fetchWrapper.get(`/task?assignedChannel=${chatroomId}`);
				handleChatroomsEvents(chatroomId);
			}
		};
		getSelectedChatroomEvents();
	}, [chatroomId]);

	useEffect(() => {
		searchRefs.current = searchRefs.current.slice(0, publicChannels?.length);
		setTempChannels(publicChannels);
	}, [publicChannels]);

	const onMarkerClick = (props, marker, e) => {
		window.setTimeout(() => {
			setActiveMarker(marker);
			setSelectedPlace(props);
			setChatroomId(props?.id);
			setShowingInfoWindow(true);
			setCenter(props?.position);
			setIsEvents(true);
		}, 100);
	};

	const onInfoWindowClose = () => {
		setActiveMarker({});
		setShowingInfoWindow(false);
		setChatroomId(null);
		setSelectedPlace({});
		if (isEvents) {
			handleChatroomsEvents(null, chatroomEvents[0]?.eventType?.id);
		} else {
			setIsEvents(false);
		}
	};

	const onMapClicked = async (mapProps, map, clickEvent) => {
		if (showingInfoWindow) {
			onInfoWindowClose(map);
		}
		if (isChannelCreateSelected) {
			let latitude = clickEvent.latLng.lat();
			let longitude = clickEvent.latLng.lng();
			setChannelLatLong({
				latitude: latitude,
				longitude: longitude,
			});
			setShowCreateChannelModal(true);
		}
	};

	useEffect(() => {
		if (searchRefs.current.length > 0) {
			const searchedMarker = searchRefs.current.find((markerRef) => markerRef?.props?.id === searchedRoomId);
			if (searchedMarker) {
				onMarkerClick(searchedMarker.props, searchedMarker.marker);
			}
		}
	}, [searchRefs.current, searchedRoomId]);

	useEffect(() => {
		if (
			isEvents &&
			chatroomEvents?.length > 0 &&
			tempChannels?.length > 0 &&
			mapRef.current
		) {

			const locations = tempChannels.map((channelData) =>
				channelData?.addresses.map((channel) => (
					{ lat: channel.latitude, lng: channel.longitude }))).flat();

			const { center: newCenter, zoom } = getMapCenterAndZoom(google, locations);
			setCenter(newCenter);
			mapRef.current.map.setZoom(zoom);
		}
	}, [chatroomEvents?.length, isEvents, google?.maps?.LatLng, google?.maps?.LatLngBounds, tempChannels]);

	return (
		<Fragment>
			{google && (
				<Map
					google={google}
					onClick={onMapClicked}
					style={mapStyles}
					ref={mapRef}
					zoom={15}
					center={{ lat: center?.lat, lng: center?.lng }}
					streetViewControlOptions={{
						position: google.maps.ControlPosition.RIGHT_TOP,
					}}
					zoomControlOptions={{
						position: google.maps.ControlPosition.RIGHT_TOP,
					}}
				>
					{currentMarker ? (
						<Marker
							{...rest}
							name={'current location'}
							icon={{
								url: currentMarker,
								scaledSize: new google.maps.Size(35, 52),
							}}
						/>
					) : (
						<Marker {...rest} name={'current location'}
							position={{
								lat: lat,
								lng: lng,
							}}
						/>
					)
					}

					{showPublicChannels &&
						tempChannels?.map((channelData, channelIndex) => {
							return channelData?.addresses[0]
								? channelData?.addresses.map((channel, index) => (
									<Marker
										key={`${channelData.id}_${index}`}
										title={channelData.title}
										name={channelData.subTitle}
										id={channelData.id}
										icon={{
											url:
												channelData?.premiumPinCategory?.pin ||
												channelData?.normalPinCategory?.pin ||
												MARKER['default'],
											// url: 'https://png.pngtree.com/png-clipart/20191120/original/pngtree-people-location-pin-icon-png-image_5047013.jpg',
											scaledSize: new google.maps.Size(35, 52),
										}}
										onClick={onMarkerClick}
										position={{
											lat: channel?.latitude,
											lng: channel?.longitude,
										}}
										teamData={channelData.teamData}
										className="mapp"
										ref={(el) => (searchRefs.current[channelIndex] = el)}
									/>
								))
								: null;
						})}
					<InfoWindow
						marker={activeMarker}
						className="bg-dark"
						onClose={onInfoWindowClose}
						visible={showingInfoWindow && isEvents}
					>
						<div
							css={css`
								width: 300px;
								padding: 0.5em;
							`}
						>
							<div
								css={css`
									display: flex;
									flex-direction: column;
									justify-content: space-between;
									align-items: center;
								`}
							>
								<div
									css={css`
										overflow: hidden;
										width: 100px;
										height: 100px;
										border-radius: 1000px;
										border: 2px solid #e3e3e3;
										display: flex;
										align-items: center;
										justify-content: center;
										color: white;
										background-color: ${selectedPlace?.teamData?.avatar
											? 'none'
											: getRandomColor(selectedPlace?.teamData?._id)};
										font-weight: 400;
										font-size: 2.3rem;
										margin-bottom: 0.5rem;
									`}
								>
									{selectedPlace?.teamData?.avatar ? (
										<img
											css={css`
												object-fit: contain;
												max-width: 100% !important;
											`}
											src={selectedPlace?.teamData?.avatar}
											alt={selectedPlace?.teamData?.name}
										/>
									) : (
										getInitials(selectedPlace?.teamData?.name)
									)}
								</div>
								<h3
									className="text-dark"
									css={css`
										overflow: hidden;
										position: relative;
										word-break: break-all;
										flex: 1;
										max-height: 3.6em; /* exactly three lines */
										display: -webkit-box;
										-webkit-line-clamp: 3;
										-webkit-box-orient: vertical;
										margin-bottom: 0.5rem;
									`}
								>
									{selectedPlace?.teamData?.name}
								</h3>
								<div
									css={css`
										display: flex;
										align-items: center;
									`}
								>
									<h4
										className="text-dark mb-0"
										css={css`
											text-overflow: ellipsis;
											overflow: hidden;
											overflow-wrap: break-word;
											width: 200px;
											max-height: 3.6em;
											display: -webkit-box;
											-webkit-line-clamp: 3;
											-webkit-box-orient: vertical;
											font-style: italic;
											text-align: center;
										`}
									>
										<a href={`teams/${selectedPlace.id}`} rel="noreferrer">
											{selectedPlace.title}
										</a>
									</h4>
								</div>
								<p
									className="text-dark mb-0"
									css={css`
										overflow: hidden;
										max-height: calc(5 * 1.2em);
										line-height: 1.2em;
										display: -webkit-box;
										-webkit-line-clamp: 3;
										-webkit-box-orient: vertical;
										width: 100%;
										text-align: center;
										text-overflow: ellipsis;
									`}
								>
									{selectedPlace.name}
								</p>
								<p className="text-dark mb-0 mt-2">
									Go to&nbsp;
									<b>
										<a href={`teams/${selectedPlace.id}`} rel="noreferrer">
											Channel
										</a>
									</b>
								</p>
							</div>
						</div>
					</InfoWindow>
				</Map>
			)}
		</Fragment>
	);
}

GoogleMap.propTypes = {
	google: PropTypes.object.isRequired,
	lat: PropTypes.number.isRequired,
	lng: PropTypes.number.isRequired,
};

export default GoogleApiWrapper({
	apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
	LoadingContainer: LoadingContainer,
})(GoogleMap);
