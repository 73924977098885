import React from 'react';

const SearchInput = React.forwardRef((props, ref) => (
	<input
		ref={ref}
		className="inputfield border-0 outline-none"
		type="text"
		placeholder={props.placeholder}
		onChange={(e) => props.setSearch(e.target.value)}
		css={props.styleProps}
	/>
));

export default SearchInput;
