import { css } from 'twin.macro';

export const EventStyled = css`
	padding: 20px;
	padding-top: 0;

	.top-items {
		margin: 0 0 20px 0;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	.input {
		border-color: #dadada;
		border-style: solid;
		padding: 0.51em;
		width: 35%;
		border-radius: 5px;
		outline: none;
		transition: border-color 300ms;
		:focus {
			border-color: #50aeb0;
		}
	}
`;

export const spin = css`
	animation: spin 2s ease infinite;
	@keyframes spin {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
`;
