import React, { useState, useEffect } from 'react';
import { AdminTable } from '../../Admin/reusableStyles';
import Loader from '../../../_elements/loader';
import {
    fetchWrapper,
    getEvvStaffUnit,
    numberWithCommas,
    roundOffToTwoDecimalDigits,
    timeConvert,
} from '../../../_helpers';
import { connect } from 'react-redux';
import { css } from 'twin.macro';
import { Modal, Badge, Button } from 'react-bootstrap';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import LabelDetail from '../../Admin/EVVContainer/Visit/LabelDetail';
import Pagination from '../../Admin/Pagination';

const PillBadge = styled.span`
    padding: 0.5rem 1rem;
    background: #50aeb0;
    border-radius: 100px;
    margin-left: 1rem;
    color: white;
    cursor: default;
    &.blue {
        background: #f5f5f5;
        color: #111;
    }
`;

const VisitModalEmployee = ({ show, setShow, teamId, teams }) => {
    const [labels, setLabels] = useState({});
    const [visits, setVisits] = useState([]);
    const [billingItem, setBillingItem] = useState(false);
    const [costInfo, setCostInfo] = useState({});
    const [totalVisits, setTotalVisits] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        const getEmployeeVisits = async () => {
            const response = await fetchWrapper.get(`evv/visit?team=${teamId}&page=${currentPage}&limit=10`);
            setVisits(response.results);
            setCostInfo(response.costInfo);
            setTotalVisits(response.totalResults);
        };
        getEmployeeVisits();
    }, [currentPage, teams]);

    useEffect(() => {
        const getLabels = async () => {
            const response = await fetchWrapper.get('label');
            let labels = response.labels.visits;
            setLabels(labels);
        };
        getLabels();
    }, [setLabels]);

    const handleClose = () => {
        setShow(false);
    };

    return (
        <Modal size="xl" show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center justify-content-between w-100">
                    <h3> Visits </h3>
                    <div tw="bg-gray-200 rounded p-5 mx-1">
                        Staff Amount: $ {numberWithCommas(roundOffToTwoDecimalDigits(costInfo?.staffAmount))}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AdminTable className="w-100 text-dark-grey">
                    <thead>
                        <th>Client</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Total Time</th>
                        <th>Staff Time</th>
                        <th>Staff Rate</th>
                        <th>Staff Amount</th>
                    </thead>
                    <tbody className="text-light-grey">
                        {/* {isLoading ? ( */}
                        {false ? (
                            <tr className="w-100 h-100 d-flex flex-row justify-content-center align-items-center">
                                <Loader size="sm" top={400} />
                            </tr>
                        ) : visits && visits.length > 0 ? (
                            visits.map((visit) => {
                                let visitLabel = visit?.extraInfo?.label;
                                const visitTime = dayjs(visit?.ScheduleEndTime).diff(
                                    dayjs(visit?.ScheduleStartTime),
                                    'm'
                                );
                                const minutesPerUnit = visit?.extraInfo?.minutesPerUnit || 15;
                                const staffUnit =
                                    visit?.extraInfo?.staffUnits || getEvvStaffUnit(visitTime, minutesPerUnit);
                                return (
                                    <tr
                                        className={` ${visitLabel ? visitLabel : ''}`}
                                        css={css`
                                            background: ${labels[visitLabel]};
                                        `}
                                        key={visit._id}
                                    >
                                        {/* {visitVerifyMode && (
                    <td>
                      {visit.verified ? (
                        <Button variant="danger" onClick={() => alert('Work on progress')}>
                          <BsShieldFillX fontSize={20} />
                        </Button>
                      ) : (
                        <Form.Check onChange={() => setVerifyVisitsId(visit.id)} />
                      )}
                    </td>
                  )} */}
                                        <td className={`${visit.client ? '' : 'text-danger'}`}>
                                            {visit.client?._id ? (
                                                <div>
                                                    <div>{visit.client?.firstName + ` ` + visit.client?.lastName}</div>
                                                    {visit.client.archieved && <Badge variant="danger">Archived</Badge>}
                                                </div>
                                            ) : (
                                                'Deleted Client'
                                            )}
                                        </td>
                                        <td>{dayjs(visit.ScheduleStartTime).format('MM-DD-YYYY, h:mm A')}</td>
                                        <td>{dayjs(visit.ScheduleEndTime).format('MM-DD-YYYY, h:mm A')}</td>
                                        <td>
                                            {timeConvert(
                                                dayjs(visit.ScheduleEndTime).diff(dayjs(visit.ScheduleStartTime), 's')
                                            )}
                                            {visit.isManual && <PillBadge>Manual</PillBadge>}
                                            {/* {visit.verified ? <BsShieldFillCheck className="ml-2" fontSize={20} color={'#28637e'} /> : null} */}
                                        </td>
                                        {/* <td>
                    { roundDown(dayjs(visit.ScheduleEndTime).diff(dayjs(visit.ScheduleStartTime), 'm'),visit?.extraInfo?.minutesPerUnit)}
                  </td> */}
                                        <td>{timeConvert(visit?.extraInfo?.staffMinutesConsumed * 60)}</td>
                                        <td>{visit?.extraInfo?.staffRate}</td>
                                        <td>{visit?.extraInfo?.staffAmount?.toFixed(2)}</td>
                                        {/* <td>
                    <Select
                      // value={billable}
                      onChange={(e) => setBillingItem([visit, e.target.value])}
                      disabled={!visit?.extraInfo?.BillVisit}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </Select>
                  </td> */}

                                        <Modal show={Boolean(billingItem)} onHide={() => setBillingItem(null)} centered>
                                            <Modal.Header>
                                                <h4>Modify Visit</h4>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <p className="h5 font-weight-lighter">
                                                    {' '}
                                                    Are you sure you want to modify this Visit ?{' '}
                                                </p>
                                            </Modal.Body>
                                            <Modal.Footer className="d-flex justify-content-between">
                                                <Button variant="secondary" onClick={() => setBillingItem(null)}>
                                                    Cancel
                                                </Button>
                                                {/* <Button variant="danger" onClick={handleBillableChange}>
                        {isLoading ? <VscLoading className="spin" /> : 'Yes, I am sure.'}
                      </Button> */}
                                            </Modal.Footer>
                                        </Modal>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan={9}>
                                    <div
                                        className="w-100 h-100 d-flex flex-row justify-content-center align-items-center"
                                        css={css`
                                            margin-top: 100px;
                                            width: 100%;
                                        `}
                                    >
                                        No Visits Found
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </AdminTable>
            </Modal.Body>
            {totalVisits > 0 && (
                <div className="m-4">
                    <Pagination
                        pageNo={currentPage}
                        totalRecords={totalVisits}
                        pageLimit={10}
                        pageNeighbours={1}
                        onPageChanged={({ currentPage }) => setCurrentPage(currentPage)}
                    />
                </div>
            )}
            <Modal.Footer>
                <div className="d-flex justify-content-end">
                    <div className="d-flex justify-content-center align-items-center">
                        {labels &&
                            Object.keys(labels).map((labelKey) => (
                                <LabelDetail key={labelKey} label={labelKey} color={labels[labelKey]} />
                            ))}
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    isLoading: state.evv.isLoading,
    labels: state.evv.labels,
    visits: state.evv.visits.visits,
    costInfo: state.evv.visits.costInfo,
    totalVisits: state.evv.visits.totalVisits,
    teams: state.teams.teams,
    clientRooms: state.rooms.clientRooms,
    organizations: state.organizations.organizations,
});

export default connect(mapStateToProps, {})(VisitModalEmployee);
