import { css } from 'twin.macro';
import NavigationBar from '../../../../../Admin/reusableComponents/NavigationBar';
import { useEffect, useState } from 'react';
import { fetchWrapper } from '../../../../../../_helpers';
import { CourseMetricContainer } from '../Common/CourseMetricContainer';
import LoadingSpiner from '../../../../../../_elements/LoadingSpiner';

const CourseUserListing = ({ course, usersObj, handleBack }) => {
    const [loading, setLoading] = useState(false);
    const [usersWithGrade, setUsersWithGrade] = useState([]);
    const fetchUserWithGrades = async () => {
        try {
            setLoading(true);
            const emails = (Object.keys(usersObj) || []).join(',');
            const response = await fetchWrapper.get(`amol/courses/${course?.course_id}?emails=${emails}`);
            setUsersWithGrade(response?.data?.data || []);
        } catch (err) {
            if (err.name === 'AbortError') {
                return;
            }
            console.log('err', err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUserWithGrades();
    }, []);
    return (
        <div
            css={css`
                padding: 1rem 2rem;
            `}
        >
            <div className="d-flex justify-content-between mb-4">
                <NavigationBar mainTabName={`Courses | ${course?.course_name || ''}`} goToTeamPage={handleBack} />
            </div>
            {loading ? <LoadingSpiner size={42} /> : null}
            {usersWithGrade?.map((userCourse, index) => (
                <CourseMetricContainer
                    key={index}
                    course={{ ...userCourse, course_id: course?.course_id }}
                    user={usersObj[userCourse?.email] || {}}
                    userCourseRow={true}
                />
            ))}
        </div>
    );
};

export default CourseUserListing;
