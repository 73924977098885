import styled from '@emotion/styled';

export const EVVContainerStyles = styled('div')`
	padding: 1rem 2rem;
	
	.EVV_nav {
        .nav-link{
            height: 100%;
			background-color:#C3E4E2;
			color:#506690;
			margin:0px 2px;
			display:flex;
			justify-content: center;
			align-items: center;

			&:hover{
				border:1px solid #506690;
			}
        }
		.active {
			color:#506690;
			border-top: #50aeb0 3px solid !important;
			border-bottom: none !important;
            height: auto;
		}
	}
	.parent-link {
		font-size: 24px;
		font-weight: bold;
	}
	.navigation {
		font-size: 24px;
		margin: 15px 0px;
		font-weight: bold;
		width: auto;
		.icon {
			cursor: pointer;
			margin-right: 10px;
		}
	}
	.search {
		height: 50px;
		width: 40%;
		background-color: white;
		border-radius: 10px;
		.icon {
			font-size: 20px;
			margin-right: 10px;
			color: #0b7da8;
			cursor: pointer;
		}
		input {
			width: 100%;
			height: 100%;
			&:focus {
				outline: none;
			}
		}
	}
`;

export const ContentContainerStyles = styled.div`
	width: 100%;
	border: 1px solid #dee2e6;
	border-top: none;
	flex: 1;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	padding: 1%;
`;
