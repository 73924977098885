import * as ActionTypes from './ActionTypes';
import { fetchWrapper, setToken } from '../../_helpers';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

export const signupUser = (newUser, history) => async (dispatch) => {
    try {
        dispatch(startLoading());
        const response = await fetchWrapper.post('auth/register', newUser, false, false);
        setToken(response.tokens.access, response.tokens.refresh);
        saveId(response.user.id);
        dispatch(setAuthUser(response.user));
        dispatch(clearError());
        dispatch(stopLoading());
        history.push('/');
    } catch (error) {
        dispatch(setError(error));
        dispatch(stopLoading());
    }
};

export const loginUser =
    (user, history, destination = '/') =>
    async (dispatch) => {
        try {
            dispatch(startLoading());
            const response = await fetchWrapper.post('auth/login', user, false, false);
            // handle error for empty user
            if (!response.user.email) {
                throw new Error('Error while Logging in, Please try again');
            }
            setToken(response.tokens.access, response.tokens.refresh);
            saveId(response.user.id);
            dispatch(setAuthUser(response.user));
            dispatch(setAuthLoginUser());
            dispatch(clearError());
            history.push(destination || '/');
            return {};
        } catch (error) {
            const headers = error.headers;
            error.message && dispatch(setError(error));
            toast.error(error.message);
            const remainingAttempts = parseInt(headers?.get('x-ratelimit-remaining')) || 0;
            // convert seconds to milliseconds
            const remainingTime = parseInt(headers?.get('x-ratelimit-reset') || 1) * 1000;
            return { remainingTime, remainingAttempts };
        } finally {
            dispatch(stopLoading());
        }
    };

const handleFailedLogin = () => {
    let email = window.localStorage.getItem('lastUsedEmail');
    let loginAttemptCount = window.localStorage.getItem(`${email}_atm`);
    if (loginAttemptCount) {
        let newLoginAttemptCount = loginAttemptCount - 1;
        window.localStorage.setItem(`${email}_atm`, newLoginAttemptCount);

        if (newLoginAttemptCount < 1) {
            let clearSuspitionTime = window.localStorage.getItem(`${email}_clearSusTime`);
            if (clearSuspitionTime) {
                return;
            } else {
                clearSuspitionTime = new Date(dayjs().add(15, 'm'));
                window.localStorage.setItem(`${email}_clearSusTime`, clearSuspitionTime);
                return;
            }
        } else {
            return;
        }
    } else {
        window.localStorage.setItem(`${email}_atm`, 5);
    }
};

export const reportIssue = (reportData) => async (dispatch) => {
    try {
        dispatch(startLoading());
        await fetchWrapper.post('feedback', reportData, false, false);
        if (reportData.type === 'issue') {
            toast.success('Issue reported!');
        } else {
            toast.success('Feedback dispatched!');
        }
        dispatch(stopLoading());
    } catch (error) {
        dispatch(setError(error));
        toast.error(error.message);
    }
};

export const forgetPasswordUser = (email, history) => async (dispatch) => {
    try {
        dispatch(startLoading());
        await fetchWrapper.post('auth/forgot-password', email, false, false);
        dispatch(clearError());
        dispatch(stopLoading());
        dispatch(setError('success'));
        history.push(`/reset-password?email=${email.email}`);
    } catch (error) {
        dispatch(setError(error));
    }
};

export const resetPasswordUser =
    (token, password, history, redirectTo = '/login') =>
    async (dispatch) => {
        try {
            dispatch(startLoading());
            await fetchWrapper.post(`auth/reset-password?token=${token}`, password, false, false);
            dispatch(clearError());
            dispatch(stopLoading());
            dispatch(setError('success-resetPasssword'));
            redirectTo !== '/login' && dispatch(setNewUserRedirect(redirectTo));
            history.push('/login');
        } catch (error) {
            dispatch(setError(error));
        }
    };

export const forceResetPasswordUser = (tempBody, history) => async (dispatch) => {
    try {
        dispatch(startLoading());
        await fetchWrapper.put('users/me/update-password', tempBody, false, true);
        dispatch(stopLoading());
        dispatch(logoutUser());
        history.push('/login');
    } catch (error) {
        dispatch(stopLoading());
        dispatch(setError(error));
        return {
            error: true,
            message: error.message,
        };
    }
};

export const logoutUser = () => (dispatch) => {
    localStorage.removeItem('user');
    localStorage.removeItem('userRefresh');
    localStorage.removeItem('userId');
    localStorage.removeItem('messagePermission');
    dispatch(setLoggedOut());
};

export const setAuthUser = (user) => ({
    type: ActionTypes.SET_USER,
    payload: user,
});

export const setNewUserRedirect = (redirectTo) => ({
    type: ActionTypes.SET_NEW_USER_REDIRECT,
    payload: redirectTo,
});

export const setAuthLoginUser = () => ({
    type: ActionTypes.SET_LOGGEDIN,
});

const setError = (error) => ({
    type: ActionTypes.SET_ERRORS,
    payload: error,
});

export const clearError = () => ({
    type: ActionTypes.CLEAR_ERRORS,
});

export const startLoading = () => ({
    type: ActionTypes.LOADING_UI,
});

export const stopLoading = () => ({
    type: ActionTypes.STOP_LOADING_UI,
});

const setLoggedOut = () => ({
    type: ActionTypes.SET_LOGGEDOUT,
});

// saving id in local storage
const saveId = (id) => {
    localStorage.setItem('userId', id);
};
