import * as ActionTypes from '../actions/ActionTypes';

const initialState = {
	activities: [],
	totalActivities: 0,
	activityMessage: null,
};

export default function Activity(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.SET_ALL_ACTIVITY:
			return {
				...state,
				activities: [...action?.payload?.results],
				totalActivities: action?.payload?.totalResults,
			};

		case ActionTypes.SET_NEW_ACTIVITY:
			const tempActivity = [...state.activities];
			const newTeamIndex = tempActivity.findIndex((activity) => (activity || {})._id === action.payload._id);
			if (newTeamIndex !== -1) {
				tempActivity[newTeamIndex] = action.payload;
				return { ...state, activities: tempActivity };
			}
			return {
				...state,
				activities: [action.payload, ...tempActivity],
				totalActivities: state.totalActivities + 1,
			};
		case ActionTypes.SET_ACTIVITY_MESSAGE:
			return { ...state, activityMessage: action.payload };
		case ActionTypes.CLEAR_ACTIVITY_MESSAGE:
			return { ...state, activityMessage: null };
		case ActionTypes.CLEAR_ACTIVITIES:
			return {
				...state,
				activities: [],
				totalActivities: 0,
			};
		case ActionTypes.MARK_AS_READ_ACTIVITY:
			let newMarktempActivities = [...state.activities];
			newMarktempActivities = newMarktempActivities.map((activity) => {
				if (activity._id === action.payload) {
					let tempActivity = { ...activity, unRead: false };
					return tempActivity;
				}
				return activity;
			});
			return {
				...state,
				activities: newMarktempActivities,
			};
		case ActionTypes.CLEAR_ACTIVITY:
			let newActivities = [...state.activities];
			newActivities = newActivities.filter((activity) => activity._id !== action.payload);
			return {
				...state,
				activities: newActivities,
				totalActivities: newActivities.length,
			};
		default:
			return state;
	}
}
