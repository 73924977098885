import { css } from 'twin.macro';
import React, { useState } from 'react';
import OnlineIndicator from '../../Chat/OnlineIndicator';
import AvatarUserPanel from '../../Common/AvatarUserPanel';
import { Permission } from '../../../../_helpers';
import { getRole } from '../../../../_helpers/roles/get-roles';
import { deleteUserFromChannel } from '../../../../_redux/actions/ActionOrganization';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import ProfileDrawer from '../../../Drawer/ProfileDrawer';

function Members({ currRoom, channelMembers, MemberOverflow, team, teamMembers, user, userId }) {
	const isPrivateChannel = currRoom.type === 'private' || currRoom.type === 'gps_enabled';
	const dispatch = useDispatch();

	const handleMemberRemove = (channelMemberId) => {
		Swal.fire({
			title: 'Are you sure?',
			text: 'The member will be removed from the team',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#50aeb0',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes',
			cancelButtonText: 'Cancel',
		}).then((result) => {
			if (result.isConfirmed) {
				dispatch(deleteUserFromChannel(currRoom._id, channelMemberId));
			}
		});
	};
	const [memberId, setMemberId] = useState();
	

	return (
		<div>
			{isPrivateChannel ? (
				<div id="style-1" className="option-members-content" css={MemberOverflow}>
					{channelMembers.length > 0 &&
						channelMembers
							.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
							.map((channelMember) => {
								let isOwner = team.admin === channelMember._id;
								let isTeamManager =
									teamMembers?.find((member) => member._id === channelMember._id)?.team[0].role ===
									'teamManager';
								let isTeamMember = Boolean(isOwner || isTeamManager);

								return (
									<div
										className="d-flex align-items-center justify-content-between py-2"
										css={css`
												cursor: default;
											`}
										key={channelMember._id}
									>
										<div
											css={css`
												flex: 1;
												display: flex;
												justify-content: space-between;
											`}
										>
											<div onClick={() => setMemberId(channelMember._id)}
												// to={`#/id/${channelMember._id}/`}
												className="d-flex align-items-center text-decoration-none cursor-pointer"
												css={css`
												&:hover{
													font-weight: bold;
												}
												`}
											>
												{
													memberId === channelMember._id &&
													<ProfileDrawer userId={channelMember._id.split('/')[0]} teamId={team._id} roomId={currRoom.id} roomType={currRoom.type} />
												}
												<div
													css={css`
														position: relative;
														width: max-content;
														height: fit-content;
													`}
												>
													{channelMember && <AvatarUserPanel item={channelMember} />}
													<OnlineIndicator className="team_member" user={channelMember} />
												</div>

												<span
													className="px-2"
													css={css`
														color: #6c757d;
													`}
												>
													{channelMember.name}
												</span>
											</div>
											<div
												css={css`
													display: flex;
													align-items: center;
													padding: 0.5rem;
												`}
											>
												{isTeamMember ? (
													<div>
														{isOwner ? (
															<span className="badge badge-primary">Owner</span>
														) : isTeamManager ? (
															<span className="badge badge-secondary">Team manager</span>
														) : null}
													</div>
												) : (
													<div className="d-flex align-items-center">
														{/* Channel role */}
														{/* Show channel role if the member is not user or owner  */}
														{channelMember?.rooms[0]?.role !== 'user' && !isOwner && (
															<div>
																<span
																	className={`text-capitalize badge badge-secondary mx-2 badge-${channelMember?.rooms[0]?.role}`}
																>
																	{channelMember?.rooms[0]?.role === 'doctor'
																		? 'third party'
																		: channelMember?.rooms[0]?.role}
																</span>
															</div>
														)}
														{!isOwner && channelMember._id !== userId && (
															<Permission
																role={getRole(user, team)}
																perform="remove:user"
																data={{
																	userId,
																	teamAdmin: team.admin,
																	memberTeamRole: teamMembers?.find(
																		(member) => member._id === channelMember._id
																	)?.team[0].role,
																}}
																yes={() => (
																	<i
																		className="fas fa-minus-circle ml-auto text-danger pointer"
																		aria-hidden="true"
																		onClick={() =>
																			handleMemberRemove(channelMember._id)
																		}
																	></i>
																)}
																no={() => {
																	let room = user.roomList
																		.filter((room) => room?.room)
																		.find((room) => room.room._id === currRoom._id);
																	let roomRole = room?.role;
																	if (roomRole === 'supervisor') {
																		return (
																			<i
																				className="fas fa-minus-circle ml-auto text-danger pointer"
																				aria-hidden="true"
																				onClick={() =>
																					handleMemberRemove(
																						channelMember._id
																					)
																				}
																			></i>
																		);
																	} else {
																		return null;
																	}
																}}
															/>
														)}
													</div>
												)}
											</div>
										</div>
									</div>
								);
							})}
				</div>
			) : (
				<div id="style-1" className="option-members-content" css={MemberOverflow}>
					{teamMembers.length > 0 &&
						teamMembers
							.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
							.map((teamMember) => {
								let isOwner = team.admin === teamMember._id;
								let isTeamManager =
									teamMember.team?.find((memberteam) => memberteam.team === team._id)?.role ===
									'teamManager';
								return (
									<div
										className="d-flex align-items-center justify-content-between"
										key={teamMember._id}
									>
										<Link
											to={`#/id/${teamMember._id}`}
											className="d-flex py-2 text-decoration-none d-flex align-items-center"
										>
											<div
												css={css`
													position: relative;
													width: max-content;
													height: fit-content;
												`}
											>
												{teamMember && <AvatarUserPanel item={teamMember} onId={true} />}
												<OnlineIndicator className="team_member" user={teamMember} />
											</div>
											<span
												className="px-4"
												css={css`
													color: #6c757d;
												`}
											>
												{teamMember.name}
											</span>
										</Link>
										<div>
											{isOwner ? (
												<span className="badge badge-primary">Owner</span>
											) : (
												isTeamManager && (
													<span className="badge badge-secondary">Team manager</span>
												)
											)}
										</div>
									</div>
								);
							})}
				</div>
			)}
		</div>
	);
}

export default Members;
