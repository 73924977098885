import { css } from 'twin.macro';
import { useState, Fragment } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';

import noImg from '../../../assets/no-img.png';

import { createTeamValidation } from '../../../_helpers';
import loaderStyles from '../../../_elements/loader';
import { formLabel } from '../../../_elements/formLabel';
import formInput from '../../../_elements/formInput';

import { connect } from 'react-redux';
import { CreateATeam } from '../../../_redux/actions/ActionOrganization';

//icons
import { BsFillPlusCircleFill } from 'react-icons/bs';
import { editBtn, editIcon, editStyle } from './TeamSettings/styles/CreateFirstTeamStyles';
import { ToolTip } from '../../../_elements/ToolTip';
import { IMAGESTYPES } from '../../../contants/enums';

const addBtnCss = css`
	width: 5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #e0f0ef;
	font-size: 2.5rem;
	color: #50aeb0;
	font-weight: bold;
	cursor: pointer;
	.teamAddPlus {
		display: flex;
		justify-content: center;
		align-items: center;
		transition: transform 200ms ease-in-out;
	}
	.teamAddPlus:hover {
		transform: scale(1.05);
	}
`;

function CreateTeam({ ui, user, CreateATeam, organizations }) {
	const [show, setShow] = useState(false);
	// const [fileName, setFileName] = useState('');
	// const [uploadImage, setUploadImage] = useState(false);

	const [file, setFile] = useState(null);

	const handleShow = () => {
		setShow(true);
		setFile(null);
	};

	const handleClose = () => {
		setShow(false);
	};

	const { register, handleSubmit, errors } = useForm({
		mode: 'onBlur',
		defaultValues: {
			name: '',
		},

		resolver: yupResolver(createTeamValidation),
	});

	const onSubmit = (newTeam) => {
		if (file) {
			const tempFile = new FormData();
			tempFile.append('avatar', file);
			createTeam(newTeam, tempFile);
			setShow(false);
		} else {
			createTeam(newTeam, null);
			setShow(false);
		}
	};

	const handleFile = (e) => {
		const maxFileSize = 20 * 1024 * 1024;
		const imageFile = e.target.files[0];
		if (imageFile && imageFile.size < maxFileSize) {
			setFile(imageFile);
			// setFileName(imageFile.name);
			// setUploadImage(true);
		} else {
			toast.warn('Image Must be less than 20 mb in size!');
		}
	};

	const createTeam = async (newTeam, file) => {
		const CuserId = localStorage.getItem('userId');
		newTeam.admin = CuserId;

		CreateATeam('5f3569c2647fb5000c019e42', newTeam, file, user);
	};

	return (
		<Fragment>
			<div css={addBtnCss} onClick={handleShow}>
				<span className="teamAddPlus">
					<ToolTip text="Create a Team" side="bottom">
						<BsFillPlusCircleFill className="mb-2" />
					</ToolTip>
				</span>
			</div>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title className="modal-headers">Create Team</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{ui.loading && (
						<div css={loaderStyles}>
							<img src="/images/loading.gif" alt="loader" />
						</div>
					)}
					<div className="text-center">
						<label className="position-relative" css={editBtn}>
							<input
								onChange={(e) => handleFile(e)}
								className="d-none"
								accept={IMAGESTYPES}
								type="file"
								name="avatar"
							/>
							<div className="edit">
								<img src={(file && URL.createObjectURL(file)) || noImg} css={editStyle} alt="avatar" />

								{!false ? (
									<i className="fas fa-pen text-primary position-absolute" css={editIcon}></i>
								) : (
									<div
										css={css`
											position: absolute;
											top: 50%;
											left: 50%;
											transform: translate(-50%, -50%);
										`}
									>
										<div className="spinner-border" role="status">
											<span className="sr-only">Loading...</span>
										</div>
									</div>
								)}
							</div>
						</label>
					</div>

					<Form.Group controlId="formBasicEmail">
						<Form.Label css={formLabel}>Team's Name</Form.Label>
						<Form.Control css={formInput} type="text" name="name" ref={register} maxLength={80} />
						<Form.Text className="text-danger ">{errors.name?.message}</Form.Text>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
					<Button variant="primary" onClick={handleSubmit(onSubmit)}>
						Create Team
					</Button>
				</Modal.Footer>
			</Modal>
		</Fragment>
	);
}

const mapStateToProps = (state) => ({
	user: state.user.user,
	ui: state.ui,
	organizations: state.organizations.organizations,
});

export default connect(mapStateToProps, { CreateATeam })(CreateTeam);
