import { css } from 'twin.macro';
import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';

import { fetchWrapper, getInitials, getRandomColor, debounce } from '../../../../_helpers';
import { Image } from 'react-bootstrap';
import { scrollToSelectedOption } from './SearchByClient';

const UserImage = css`
    display: flex;
    align-items: center;
    .userImage {
        width: 5rem;
        height: 5rem;
        margin: 5px 8px 5px 0px;
        border-radius: 1000px;
        object-fit: contain;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 14px;
        &.mini {
            width: 3rem;
            height: 3rem;
        }
    }
`;

const SearchByUserEmployee = ({ provider, userEmployee, handleSelect, team, disabled }) => {
    const [loading, setLoading] = useState(false);
    const selectedOptionClass = `employee-${userEmployee?.user?.id}`;
    const fetchData = async (inputValue, callback) => {
        setLoading(true);
        if (disabled) {
            return;
        }
        try {
            const userEmployeeUrl = provider
                ? `evv/employee?limit=20${
                      inputValue ? `&firstName=${inputValue}` : ''
                  }${provider?.value ? `&provider=${provider.value}` : ''}${team ? `&team=${team}` : ''}`
                : `users?sortBy=name:asc&limit=10&${inputValue ? `&name=${inputValue}` : ''}${team ? `&team=${team}` : ''}`;

            const users = await fetchWrapper.get(userEmployeeUrl);

            const tempArray = users?.results?.map((employee) => {
                const user = employee?.user || employee;
                if (!user) {
                    return callback([]);
                }
                return {
                    label: (
                        <div css={UserImage} className={`employee-${user?.id}`}>
                            <div>
                                {user.avatar ? (
                                    <Image src={user.avatar} className="userImage" width="30" alt={user.name}></Image>
                                ) : (
                                    <div className="userImage" style={{ background: getRandomColor(user.id) }}>
                                        {getInitials(user.name)}
                                    </div>
                                )}
                            </div>
                            {user.name}
                        </div>
                    ),
                    value: employee._id || employee.id,
                    user,
                    ...user,
                    ...employee,
                };
            });
            callback(tempArray || []);
            setLoading(false);
        } catch (error) {
            return callback([]);
        }
    };
    const onSearchChange = (selectedOption) => {
        handleSelect(selectedOption);
    };

    useEffect(() => {
        if (!loading) {
            scrollToSelectedOption(selectedOptionClass);
        }
    }, [loading]);

    return (
        <div
            key={provider?.value}
            className="search-container"
            css={css`
                .userImage {
                    width: 3rem !important;
                    height: 3rem !important;
                }
            `}
        >
            <span className="search-title">Select Employee:</span>
            <AsyncSelect
                loadOptions={debounce(fetchData)}
                defaultOptions
                onChange={onSearchChange}
                isClearable={true}
                value={userEmployee}
                isDisabled={disabled}
                onMenuOpen={() => scrollToSelectedOption(selectedOptionClass)}
            />
        </div>
    );
};

export default SearchByUserEmployee;
