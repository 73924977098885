import { Button, Modal } from 'react-bootstrap';
import { css } from 'twin.macro';
import ZealEmployeeCheckForm from './ZealEmployeeCheckForm';
import { useState } from 'react';
import { fetchWrapper } from '../../../../../_helpers';
import { toast } from 'react-toastify';

export default function EmployeeCheckModal({
    employeeCheck,
    companyID,
    show,
    handleClose,
    mode = 'view',
    teamId,
    workLocations,
}) {
    const [isBusy, setIsBusy] = useState(false);

    async function handleSubmit(checkData) {
        try {
            if (mode === 'view') return;
            setIsBusy(true);
            if (mode === 'clone') {
                await fetchWrapper.post(
                    `/evv/payroll/zeal/employee/${companyID}/${employeeCheck?.employeeID}/checks${teamId ? `?team=${teamId}` : ''}`,
                    checkData
                );
                toast.success('Check Cloned and Created successfully!');
            } else {
                const idRouteParam = mode === 'edit' ? employeeCheck.employeeCheckID : employeeCheck.employeeID;
                if (!companyID) {
                    throw new Error('Company ID is missing');
                }
                if (!idRouteParam) {
                    throw new Error(mode === 'edit' ? 'Employee Check ID is missing' : 'Employee ID is missing');
                }
                await fetchWrapper[{ edit: 'put', create: 'post' }[mode]](
                    `/evv/payroll/zeal/employee/${companyID}/${idRouteParam}/checks${teamId ? `?team=${teamId}` : ''}`,
                    checkData
                );
                toast.success(`Check ${{ edit: 'edited', create: 'created' }[mode]} successfully!`);
            }
        } catch (error) {
            toast.error(error.message || `Couldn't ${mode} check!`);
        } finally {
            setIsBusy(false);
        }
    }

    return (
        <Modal show={Boolean(show)} onHide={handleClose} centered scrollable backdrop="static" size="lg">
            <Modal.Header closeButton>
                <Modal.Title
                    css={css`
                        width: 100%;
                        padding: 0 12px;
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                            flex-direction: column;
                        `}
                    >
                        <p
                            css={css`
                                text-transform: capitalize;
                                padding: 0;
                                margin: 0;
                                font-size: larger;
                            `}
                        >
                            {mode} Employee Check
                        </p>
                        <div
                            css={css`
                                display: flex;
                                gap: 12px;
                                align-items: flex-end;
                            `}
                        >
                            <p
                                css={css`
                                    font-size: small;
                                    padding: 0;
                                    margin: 0;
                                `}
                            >
                                {employeeCheck.employeeCheckID}
                            </p>
                            <span
                                className={`badge badge-sm ${employeeCheck?.status === 'pending' ? 'badge-warning' : employeeCheck?.status === 'processed' ? 'badge-success' : employeeCheck?.status === 'failed' ? 'badge-danger' : 'badge-info'}`}
                            >
                                {employeeCheck?.status}
                            </span>
                        </div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ height: '80vh' }}>
                <ZealEmployeeCheckForm
                    companyID={companyID}
                    data={{
                        ...employeeCheck,
                        shifts: employeeCheck?.shifts
                            ?.map((shift) =>
                                Object.entries(shift)
                                    .filter(
                                        ([_, value]) =>
                                            value && typeof value !== 'string' && Object.values(value)?.length
                                    )
                                    .map(([key, value]) => ({ type: key, ...value, ...shift }))
                            )
                            .flat(),
                    }}
                    mode={mode}
                    isBusy={mode === 'view' || isBusy}
                    onSubmit={handleSubmit}
                    teamId={teamId}
                    workLocations={workLocations}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
