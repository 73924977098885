
const addZeroes = (number, digits = 2) => {
  const noOfZeroes = Math.max(0, digits - `${number}`.length);
  const zeroes = Array(noOfZeroes).fill(0).join('');
  return `${zeroes}${number}`;
}

export const getTimeFromDateTime = (datetime, includeSeconds = true) => {
  const datetimeobject = new Date(datetime);
  const rawHours = datetimeobject.getHours();
  const minutes = addZeroes(datetimeobject.getMinutes());
  const seconds = addZeroes(datetimeobject.getSeconds());
  const ampm = rawHours >= 12 ? 'PM' : 'AM';
  const hours = rawHours % 12 || 12;
  return `${hours}:${minutes}${includeSeconds ? `:${seconds}` : ''} ${ampm}`;;
}

export const getDateFromDateTime = (datetime) => {
  const datetimeobject = new Date(datetime);
  const year = datetimeobject.getFullYear();
  const month = addZeroes(datetimeobject.getMonth() + 1);
  const day = addZeroes(datetimeobject.getDate());
  return (month + '/' + day + '/' + year);
}

export const getDateTimeFromDateTime = (datetime, includeSeconds = true) => {
  const date = getDateFromDateTime(datetime);
  const time = getTimeFromDateTime(datetime, includeSeconds);
  return date + ' ' + time;
}

export const getDemandableDateString = (event) => {
  const startDate = new Date(event.latestDatePointer || event.date);
  const endDate = new Date(event.latestDueDatePointer || event.dueDate);
  const minutesDiff = Math.round((endDate - startDate) / (60 * 1000));
  const duration = {
    day: Math.floor(minutesDiff / 1440),
    hour: Math.floor(minutesDiff / 60) % 24,
    minute: minutesDiff % 60
  }
  const durationString = Object.entries(duration)
    .filter(([_, value]) => value)
    .map(([key, value]) => `${value} ${value > 1 ? key + 's' : key}`).join(" ");

  const dateString = getDateFromDateTime(Math.max(Date.now(), startDate));
  const timeString = getTimeFromDateTime(startDate, false);
  return `${dateString} ${timeString} ${durationString}`
}
