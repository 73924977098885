import React, { Fragment, useEffect, useState } from 'react';
import { css } from 'twin.macro';
import { TbAward, TbAwardOff } from 'react-icons/tb';
import { VscLoading } from 'react-icons/vsc';
import CertificateModal from '../../../../../Admin/Courses/CertificateModal';
import Avatar from '../../../../../Admin/reusableComponents/Avatar';
import noImgAvailable from '../../../../../../../src/assets/no-image-available.png';

export function CourseMetricContainer({ course, user, userCourseRow }) {
    if (userCourseRow && !course?.user_registered) {
        // if user is not registered in amfol, then dont show its data
        return null;
    }
    return (
        <div
            className="d-flex w-100 custom-card"
            css={css`
                justify-content: space-between;
                gap: 8px;
                align-items: center;
                margin-top: 8px;
            `}
        >
            {userCourseRow ? <UserInfo user={user} /> : <CourseInfo course={course} />}
            <CourseMetricList course={course} user={user} />
        </div>
    );
}

export function UserInfo({ user }) {
    return (
        <div
            className="d-flex"
            css={css`
                gap: 12px;
                flex-direction: row;
                align-items: center;
            `}
        >
            <div>
                <Avatar item={user} />
            </div>
            <div
                css={css`
                    margin-left: 8px;
                `}
            >
                {user.name}
            </div>
        </div>
    );
}

export function CourseInfo({ course }) {
    return (
        <div
            className="d-flex"
            css={css`
                gap: 12px;
                flex-direction: column;
            `}
        >
            <div>
                <img src={course?.course_icon_url || noImgAvailable} alt="course-icon" height="40" width="40" />
                <span
                    css={css`
                        margin-left: 8px;
                    `}
                >
                    {course.course_name}
                </span>
            </div>
            <span
                css={css`
                    color: #444;
                `}
            >
                {course.course_description}
            </span>
        </div>
    );
}
export function CourseMetricList({ course, user }) {
    const [showCertificate, setShowCertificate] = useState(false);
    const handleClose = () => {
        setShowCertificate(false);
    };
    const showCertificateModal = () => {
        setShowCertificate(true);
    };
    course.has_certificate = course.cgrade >= 80;
    course.tpoints = parseInt(course.tpoints) || 0;
    const metrics = [
        {
            key: 'progress',
            name: 'Progress',
            type:
                course?.course_completion_percentage < 30
                    ? 'danger'
                    : course?.course_completion_percentage < 70
                      ? 'info'
                      : 'success',
            data: course?.course_completion_percentage
                ? `${Math.round(course?.course_completion_percentage || 0)}%`
                : '-',
        },
        {
            key: 'points',
            name: 'Score',
            type: course.tpoints ? (course.cgrade < 30 ? 'danger' : course.cgrade < 70 ? 'info' : 'success') : 'info',
            data: course.tpoints ? `${Math.round(course.cgrade || 0)}%` : '-',
        },
        {
            key: 'grade',
            name: 'Grade',
            type: { A: 'success', F: 'danger' }[course.tpoints ? course?.lgrade : 'C'] || 'info',
            data: (course.tpoints && course?.lgrade) || '-',
        },
        {
            key: 'certificate',
            name: 'Certificate',
            type: course.tpoints ? (course.has_certificate ? 'success' : 'danger') : 'info',
            data:
                course.tpoints && course.has_certificate ? (
                    <React.Fragment>
                        {showCertificate ? (
                            <VscLoading className="spin" />
                        ) : (
                            <TbAward
                                width={32}
                                height={32}
                                onClick={() => {
                                    showCertificateModal();
                                }}
                                css={css`
                                    cursor: pointer;
                                `}
                            />
                        )}
                    </React.Fragment>
                ) : (
                    <TbAwardOff />
                ),
        },
    ];
    return (
        <div
            css={css`
                display: flex;
                align-items: center;
                gap: 4px;
            `}
        >
            {metrics.map((metric) => (
                <CourseMetric key={metric.key} type={metric.type} label={metric.name}>
                    {metric.data}
                </CourseMetric>
            ))}
            {showCertificate ? (
                <CertificateModal
                    show={showCertificate}
                    handleClose={handleClose}
                    user={user}
                    amolUserId={course?.user_id}
                    courseId={course?.course_id}
                />
            ) : null}
        </div>
    );
}

export function CourseMetric({ type, children, label }) {
    const color = type || 'primary';
    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 2px;
            `}
        >
            <span
                className={`border border-${color} d-flex text-${color}`}
                css={css`
                    width: 48px;
                    height: 48px;
                    border-radius: 50%;
                    justify-content: center;
                    align-items: center;
                    gap: 1px;
                    font-weight: bold;
                `}
            >
                {children}
            </span>
            <span
                className={`text-${color}`}
                css={css`
                    font-size: smaller;
                `}
            >
                {label}
            </span>
        </div>
    );
}
