import React, { useState } from 'react';
import { EventStyled } from './styles/EventsStyled';

import { Tab, Tabs } from 'react-bootstrap';
import TeamSettingsActivityPackage from './TeamSettingsActivityPackage';
import TeamSettingsActivityLibrary from './TeamSettingsActivityLibrary';
import { getChannelIdFromSettingsURL } from '../../../../../_helpers';

const TeamEvents = () => {
	const channelIdFromUrl = getChannelIdFromSettingsURL();
	const [key, setKey] = useState(channelIdFromUrl ? 'activity_library' : 'activity_package');
	return (
		<div css={EventStyled}>
			<h1 className="mb-4">Events</h1>
			<Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
				{
					channelIdFromUrl ? null : 
						<Tab eventKey="activity_package" title="Activity Plan">
							{key === 'activity_package' && <TeamSettingsActivityPackage />}
						</Tab> 
				}
				<Tab eventKey="activity_library" title="Activity Library">
					{key === 'activity_library' && <TeamSettingsActivityLibrary />}
				</Tab>
			</Tabs>
		</div>
	);
};

export default TeamEvents;
