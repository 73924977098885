import { css } from 'twin.macro';
import Select from 'react-select';
import { FormLabel, InputGroup } from 'react-bootstrap';
import { Fragment, useState, useCallback, useEffect, useRef } from 'react';
import { fetchWrapper, getTeamIdFromURL, toastWrapper, getChannelIdFromSettingsURL } from '../../../../_helpers';
import { toast } from 'react-toastify';

import { connect } from 'react-redux';
import { DocumentsStyles } from './styles/DocumentsStyled';

//assets
import { VscLoading } from 'react-icons/vsc';
import FormSvg from '../../../../assets/forms.svg';
import { FormPackageTable, ScrollContainer, spin } from './TeamSettingFormPackage';
import DateTimePicker from 'react-datetime-picker/dist/DateTimePicker';
import FileMessage from '../../Common/FileMessage';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import AvatarUserPanel from '../../Common/AvatarUserPanel';
import { UserImage } from '../../../Admin/users/ReactSelectComponent';
import SortedTableHeaderIcon from '../../../Admin/SortedTableHeaderIcon';
import ChannelSelectFilter from '../../../Admin/ChannelSelectFilter';
import Pagination from '../../../Admin/Pagination';
import TagsModal from '../../Common/Tags/TagsModal';
import { TagPreviewWithContainer, AddTagsTooltip } from '../../Common/Tags/TagsPreview';
import TagSelectFilter from '../../Common/Tags/TagSelectFilter';

const Documents = ({ search, channelDetails }) => {
	const scrollRef = useRef(null);

	const teamId = getTeamIdFromURL();
	let fileCounter = 0;

	const organizations = useSelector((state) => state.organizations?.organizations);

	const [endDate, setEndDate] = useState();
	const [members, setMembers] = useState([]);
	const [member, setMember] = useState(null);
	const [channel, setChannel] = useState(channelDetails?.id ? channelDetails : null);
	const [refModel, setRefModel] = useState(null);
	const [startDate, setStartDate] = useState();
	const [filteredFiles, setFilteredFiles] = useState([]);
	const [loadingFiles, setLoadingFiles] = useState(false);
	const [sortBy, setSortBy] = useState({
		key: 'createdAt',
		direction: 'desc',
	});
	const [currentPage, setCurrentPage] = useState(1);
	const [totalResults, setTotalResults] = useState(10);
	const [searchTags, setSearchTags] = useState([]);
	const [showTagsModal, setShowTagsModal] = useState(false);
	const handleShowTagsModal = (id) => setShowTagsModal(id);
	const handleCloseTagsModal = () => setShowTagsModal(false);
	const channelIdFromUrl = getChannelIdFromSettingsURL();


	const updateTagsInDocumentsForms = (updatedResponse) => {
		const updatedForms = filteredFiles.map((form) => ((form?._id || form?.id) === (updatedResponse?._id || updatedResponse?.id)) ? {...form, ...updatedResponse} : form)
		setFilteredFiles(updatedForms);
	}

	const onScroll = () => {
		if (scrollRef.current) {
			const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
			if (scrollTop + clientHeight === scrollHeight) {
				console.log('reached bottom');
			}
		}
	};

	const handleEndDateChange = (date) => {
		if (!date) {
			setEndDate(null);
			return;
		}
		setEndDate(date.toISOString());
	};
	const handleStartDateChange = (date) => {
		if (!date) {
			setStartDate(null);
			return;
		}
		setStartDate(date.toISOString());
	};

	const getTeamFiles = useCallback(
		async (props = {}) => {
			try {
				setLoadingFiles(true);
				let url = `chatrooms/${teamId}/files?sortBy=${sortBy.key}:${sortBy.direction}&limit=10&page=${currentPage}`;
				if (props.fileName) {
					url += `&searchText=${props.fileName}`;
				}
				if (props.userId) {
					url += `&user=${props.userId}`;
				}
				if (props.channel || channelIdFromUrl) {
					url += `&chatroom=${props.channel || channelIdFromUrl}`;
				}
				if (props.refModel) {
					url += `&refModel=${props.refModel}`
				}
				if (props.startDate) {
					url += `&startDate=${props.startDate}`;
				}
				if (props.endDate) {
					url += `&endDate=${props.endDate}`;
				}
				if (props?.searchTags?.length) {
					url += `&tags=${props?.searchTags}`;
				}
				const response = await fetchWrapper.get(url);
				setTotalResults(response?.totalResults);
				setFilteredFiles(response?.results);
				setLoadingFiles(false);
			} catch (error) {
				setLoadingFiles(false);
				toastWrapper.error(error?.message || 'Failed to load Team Plan');
			}
		},
		[teamId, sortBy, currentPage]
	);

	useEffect(() => {
		let timer = setTimeout(async () => {
			if (teamId) {
				getTeamFiles({
					fileName: search,
					startDate: startDate,
					endDate: endDate,
					channel: channel?.value,
					userId: member?.value,
					refModel: refModel?.value,
					searchTags
				});
			}
		}, 250);

		return () => clearTimeout(timer);
	}, [teamId, sortBy, currentPage, search, member, channel, startDate, endDate, refModel, searchTags]);

	useEffect(() => {
		const getTeamMembers = async () => {
			if (organizations[0]) {
				let response;
				if(channelIdFromUrl){
					response = await fetchWrapper.get(`chatrooms/${channelIdFromUrl}/members?limit=1000`);
				}
				else{
					response = await fetchWrapper.get(
						`organizations/${organizations[0].id}/teams/${teamId}/members?limit=1000`
					);
				}
				const tempArray = [];
				response?.results?.forEach((element) => {
					if (element?.status === 'active' || !element?.status) {
						tempArray.push({
							label: (
								<div css={UserImage}>
									<AvatarUserPanel item={element} />
									<span className="ml-2">{element.name}</span>
								</div>
							),
							value: element.id || element._id,
							...element,
						});
					}
				});
				setMembers(tempArray);
			}
		};
		getTeamMembers();
	}, [organizations, teamId]);

	const handleChangeMember = (value) => {
		setMember(value);
	};
	const handleChangeChannel = (value) => {
		setChannel(value);
	};
	const handleChangeRefModel = (value) => {
		setRefModel(value);
	}
	const handleSort = (fieldName) => {
		setSortBy({ direction: sortBy.direction === 'asc' ? 'desc' : 'asc', key: fieldName });
	};
	const handleTagSelect = (values) => {
		setSearchTags(values?.map((tag) => tag?.value))
	}

	return (
		<div css={DocumentsStyles}>
			<div
				css={css`
					position: relative;
					display: flex;
					width: 100%;
					align-items: end;
					justify-content: space-between;
					z-index: 20;
					gap: 5px;
				`}
			>
				<div css={css`width: 200px;`}>
					<InputGroup className="mb-3 flex-column">
						<FormLabel className="mr-2 ">Submitted By</FormLabel>
						<Select
							value={member}
							className="bolder"
							name="member"
							isClearable={true}
							options={members}
							onChange={handleChangeMember}
							placeholder="Enter Member Name"
						/>
					</InputGroup>
				</div>
				<div css={css`width: 214px;`}>
					<InputGroup className='mb-3 flex-column'>
						<FormLabel className="mr-2 ">Channel</FormLabel>
						<ChannelSelectFilter
							disabled={!!channelIdFromUrl}
							selectedOption ={channelDetails?.id ? channelDetails : channel}
							selectedRoom={channelDetails.id}
							onRoomSelect={handleChangeChannel}
							teamIds={[teamId]}
							placeholder="Enter Channel's Name"
							css={css`width: 214px`}
						/>
					</InputGroup>
				</div>
				<div css={css`width: 150px`}>
					<InputGroup className="mb-3 flex-column">
						<FormLabel className=" mr-2">Type</FormLabel>
						<Select
							value={refModel}
							onChange={handleChangeRefModel}
							options={[
								{ label: "Message", value: "Message" },
								{ label: "Form Submission", value: "UserFormData" },
								{ label: "Form", value: "Form" },
							]}
							isClearable
						/>
					</InputGroup>
				</div>
				<div css={css`width: 264px;`}>
					<InputGroup className="mb-3 flex-column">
						<FormLabel className=" mr-2">From</FormLabel>
						<DateTimePicker
							onChange={handleStartDateChange}
							value={startDate ? new Date(startDate) : startDate}
							format="MM-dd-y hh:mm a"
							disableClock={true}
							calendarType={'US'}
						/>
					</InputGroup>
				</div>
				<div css={css`width: 264x;`}>
					<InputGroup className="mb-3 flex-column">
						<FormLabel className="mr-2 ">To</FormLabel>
						<DateTimePicker
							onChange={handleEndDateChange}
							value={endDate ? new Date(endDate) : endDate}
							format="MM-dd-y hh:mm a"
							disableClock={true}
							calendarType={'US'}
						/>
					</InputGroup>
				</div>
				<div css={css`min-width:198px;width: clamp(198px, max-content);`}>
						<InputGroup className="mb-3 mr-2 flex-column">
						<FormLabel className="mr-2 ">Tags: </FormLabel>
							<TagSelectFilter
								className="bolder"
								css={css`width: 198px`}
								handleSelectCallback={handleTagSelect}
								type="file"
								disabled={loadingFiles}
							/>
						</InputGroup>
				</div>
			</div>
			{loadingFiles ? (
				<h4 className="text-center">
					<VscLoading css={spin} fontSize="42" />
				</h4>
			) : filteredFiles?.length ? (
				<div className="custom-card">
					<ScrollContainer ref={scrollRef} onScroll={onScroll}>
						<FormPackageTable className="w-100">
							<thead>
								<tr>
									<th>S.N</th>
									<th>File</th>
									<th>Type</th>
									<th>Sender</th>
									<th>Channel</th>
									<th
										css={css`
											color: inherit;
											cursor: pointer;
										`}
										onClick={() => handleSort('createdAt')}
									> Date{' '}
										<SortedTableHeaderIcon sortConfig={sortBy} fieldName={'createdAt'} />
									</th>

								</tr>
							</thead>
							<tbody>
								<Fragment>
									{filteredFiles?.length > 0 &&
										filteredFiles
											.map((file, index) => {
												fileCounter++;

												return (
													<tr key={`${file._id}_${fileCounter}`}>
														<td>{10 * (currentPage - 1) + index + 1}</td>
														<td>
															<FileMessage
																file={file.url}
																displayMessage={file.name?.split("/").at(-1)}
																documentSettings={true}
															/>
															<div css={css`display:flex;margin-top:4px;gap:4px;`}>
																{<span css={css`width: fit-content;cursor: pointer;margin-left: 10px;`}
																	onClick={() => handleShowTagsModal(file._id || file.id)}
																>
																	<AddTagsTooltip />
																</span>}
																<TagPreviewWithContainer tags={file?.tags || []} style={{ maxWidth: '50rem' }} />
															</div>
															{(showTagsModal === (file?._id || file?.id)) ? <TagsModal show={!!showTagsModal} handleClose={handleCloseTagsModal} type={'file'} tagAction={'assign'} objectId={file?._id || file?.id} prevTags={file?.tags} responseCallback={updateTagsInDocumentsForms} /> : null}
														</td>
														<td>{{ Message: "Message", Form: "Form", UserFormData: "Form Submission" }[file.refModel]}</td>
														<td>{file.user ? file.user.name : '-'}</td>
														<td>
															<div css={css`max-height: 12rem; overflow: auto;`}>
																{file.rooms?.map(room =>
																	<a
																		key={room?.id}
																		css={css`font-size: 16px;display: block;`}
																		href={`/teams/${room?.id}`}
																		className={`${room?.id ? '' : 'disabled'}`}>
																		{room?.title}
																	</a>)}
															</div>
														</td>
														<td>{dayjs(file.createdAt).format('MMM D, YYYY')}</td>
													</tr>
												);
											})}
								</Fragment>
							</tbody>
						</FormPackageTable>
					</ScrollContainer>
				</div>
			) : (
				<div
					css={css`
						background-color: white;
						box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
						border-radius: 5px;
						padding: 15px;
						display: flex;
						justify-content: center;
						align-items: center;
						flex-direction: column;
					`}
				>
					<img
						src={FormSvg}
						alt={'no documents found'}
						css={css`
							width: 350px;
							margin: 1rem 0;
						`}
					/>
					<h2 className="text-center">No matching results</h2>
					<div className="text-center">Try another search, or use search filters to find the document.</div>
				</div>
			)}
			<div className="p-4">
				<Pagination
					totalRecords={totalResults}
					key={totalResults}
					pageLimit={10}
					pageNeighbours={1}
					onPageChanged={(data) => {
						setCurrentPage(Math.max(1, data.currentPage));
					}}
				/>
			</div>
		</div>
	);
};
const mapStateToProps = (state) => ({
	rooms: state.rooms.teamRooms,
});

export default connect(mapStateToProps, {})(Documents);
