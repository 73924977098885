import React, { Fragment, useEffect, useState } from 'react';
import { buildQueryString, fetchWrapper, getStandardDate } from '../../../../../../../_helpers';
import DownloadReport from '../DownloadReport';
import ReportCheckBox from '../ReportCheckBox';

const CustomPayrollJournal = ({ companyID, slug, dateFilters = {} }) => {
    const [downloadLink, setDownloadLink] = useState('');
    const handleDownloadLink = (link) => setDownloadLink(link);
    const [fields, setFields] = useState({
        employee_taxes: false,
        employer_taxes: false,
        deductions: false,
        net_pay: false,
        earnings: false,
        disbursement: false,
        gross_pay: false,
        employer_check_id: false,
    });

    const handleChangeField = (fieldName, newValue) => {
        setFields((prevFields) => ({
            ...prevFields,
            [fieldName]: newValue,
        }));
    };
    const generateLink = async () => {
        const queryString = buildQueryString({
            startDate: getStandardDate(dateFilters.start),
            endDate: getStandardDate(dateFilters.end),
            fields: JSON.stringify(fields),
        });
        const response = await fetchWrapper.get(
            `evv/payroll/zeal/provider/${companyID}/reports/${slug}?${queryString}`
        );
        handleDownloadLink(response.url);
    };

    useEffect(() => {
        handleDownloadLink('');
    }, [dateFilters?.start, dateFilters?.end, fields]);

    return (
        <Fragment>
            <div style={{ fontWeight: '700' }}>Employee check attributes </div>
            {Object.keys(fields).map((field) => (
                <ReportCheckBox
                    key={field}
                    label={field.replace('_', ' ')}
                    value={fields[field]}
                    onChange={(newValue) => handleChangeField(field, newValue)}
                />
            ))}
            <DownloadReport
                disabled={!dateFilters?.end || !dateFilters?.start}
                onGenerateLink={generateLink}
                downloadLink={downloadLink}
                companyID={companyID}
                slug={slug}
            />
        </Fragment>
    );
};

export default CustomPayrollJournal;
