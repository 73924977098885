export function getMapCenterAndZoom(google, locations, maxZoom = 32) {
    const bounds = new google.maps.LatLngBounds();
    const sumCenter = [0, 0];
    let noOfLocations = 0;
    locations.forEach((location) => {
        bounds.extend(new google.maps.LatLng(location.lat, location.lng));
        sumCenter[0] += location.lat;
        sumCenter[1] += location.lng;
        noOfLocations++;
    });
    const center = { lat: sumCenter[0] / noOfLocations, lng: sumCenter[1] / noOfLocations };

    function latRad(lat) {
        var sin = Math.sin((lat * Math.PI) / 180);
        var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    var ne = bounds.getNorthEast();
    var sw = bounds.getSouthWest();

    var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

    var lngDiff = ne.lng() - sw.lng();
    var lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

    var latZoom = maxZoom * (1 - latFraction);
    var lngZoom = maxZoom * (1 - lngFraction);

    const zoom = Math.min(latZoom, lngZoom, maxZoom);
    return { center, zoom }
}