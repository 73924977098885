import { css } from 'twin.macro';

import { useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import { connect } from 'react-redux';
import { getOtherUserData } from '../../../_redux/actions/ActionUser';
import { debounce, fetchWrapper, isEmpty, toastWrapper } from '../../../_helpers';
import AvatarUserPanel from '../../Features/Common/AvatarUserPanel';

export const UserImage = css`
    display: flex;
    margin: 5px;
    align-items: center;
    font-size: 16px;
    color: #646464;
`;
const ReactSelectComponent = ({
    selectedOption = {},
    setSelectedOption,
    placeholder,
    getOtherUserData,
    isDisabled,
    assignedTo,
    teamId,
}) => {
    useEffect(() => {
        const fectUserData = async () => {
            if (!assignedTo) {
                return;
            }
            try {
                let user = await getOtherUserData(assignedTo);
                setSelectedOption({
                    label: (
                        <div css={UserImage}>
                            <AvatarUserPanel item={user} />
                            <span className="ml-2">{user.name}</span>
                        </div>
                    ),
                    value: user.id,
                    ...user,
                });
            } catch (err) {}
        };
        fectUserData();
    }, [getOtherUserData, assignedTo, setSelectedOption]);

    const fetchData = async (inputValue, callback) => {
        if (!inputValue) {
            return callback([]);
        }
        try {
            const user = await fetchWrapper.get(
                `users?sortBy=name:asc&limit=10&${inputValue ? `&name=${inputValue}` : ''}${teamId ? `&team=${teamId}` : ''}`
            );
            const tempArray = [];
            user?.results?.forEach((element) => {
                if (element?.status && element?.status !== 'active') {
                    return;
                }
                tempArray.push({
                    label: (
                        <div css={UserImage}>
                            <AvatarUserPanel item={element} />
                            <span className="ml-2">{element.name}</span>
                        </div>
                    ),
                    value: element.id,
                    ...element,
                });
            });
            callback(tempArray);
        } catch (error) {
            console.log(error, 'ERROR');
            if (error.name === 'AbortError') {
                return;
            }
            toastWrapper.error("Couldn't fetch options: " + error?.message);
        }
    };

    const onSearchChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedOption(selectedOption);
        }
    };

    return (
        <AsyncSelect
            loadOptions={debounce(fetchData, 500)}
            placeholder={placeholder}
            onChange={(e) => {
                onSearchChange(e);
            }}
            defaultOptions={false}
            isDisabled={isDisabled}
            value={isEmpty(selectedOption) ? null : selectedOption}
        />
    );
};

export default connect(null, { getOtherUserData })(ReactSelectComponent);
