import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { css } from 'twin.macro';
import '../Common/Message.css';

//redux
import { connect } from 'react-redux';

import MessageDate from '../Common/MessageDate';

import { deleteChatRoomMessage, editChatRoomMessage } from '../../../_redux/actions/ActionMessage';
import SenderMessage from './SenderMessage';
import ReceiverMessage from './ReceiverMessage';
import { Button } from 'react-bootstrap';
import AvatarStack from '../../../_elements/AvatarStack/AvatarStack';
import OngoingCallTime from '../../../_elements/OngoingCallTime';
import { FaInfoCircle } from 'react-icons/fa';
import { MdCall, MdCallEnd, MdPhone } from 'react-icons/md';
import MessageTime from '../Common/MessageTime';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { setConferenceData, startConference } from '../../../_redux/actions/ActionConference';
import { toastWrapper } from '../../../_helpers';

const Message = ({
    message,
    date,
    currUser,
    rId,
    setReply,
    roomType,
    refs,
    messages,
    lastSeen,
    setChatFormId,
    deleteChatRoomMessage,
    editChatRoomMessage,
    position,
    userRole,
    showMessageReactions,
    teamData,
    startConference,
    setConferenceData,
}) => {
    const isForm = new RegExp('"text":"###*');
    const handleMessageClick = () => {
        // delay getting the formId from window.location
        setTimeout(() => {
            let formId = window.location.href.split('#/form/')[1];
            setChatFormId(formId);
        }, 300);
    };

    //this is for reply
    const handleClick = (id) => {
        if (messages.findIndex((mes) => mes.id === id) !== -1) {
            if (refs[id].current) {
                refs[id].current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'nearest',
                });

                refs[id].current.style.borderRadius = '15px';
                refs[id].current.style.boxShadow = '0 0px 10px 4px #50aeb0';
                refs[id].current.style.transition = 'all 500ms ease-in-out 500ms';
                window.setTimeout(() => {
                    if (refs[id].current) refs[id].current.style.boxShadow = 'none';
                }, 2000);
            }
        } else {
        }
    };

    const handleDeleteMessage = async (messageId) => {
        if (window.confirm('Do you want to delete this message?')) {
            const error = await deleteChatRoomMessage(rId, messageId);
            if (error) {
                toastWrapper.error(error?.message || 'Something went wrong');
            }
        }
    };

    const sendEditedMessage = async (reply, data, edit, position, rId, isEdited, visibleTo) => {
        if (!edit) {
            return;
        }
        const msg = {};
        msg.text = data;

        if (position) {
            msg.location = { latitude: position.coords.latitude, longitude: position.coords.longitude };
        } else {
            msg.location = { latitude: null, longitude: null };
        }

        if (reply) {
            msg.reply = reply;
        }
        try {
            await editChatRoomMessage(rId, edit, { data: JSON.stringify(msg), visibleTo: visibleTo });
        } catch (err) {
            console.log(err, 'ERR');
        }
    };
    const handleUserCall = async (conference) => {
        try {
            setConferenceData(rId, conference, teamData?.team);
            startConference();
        } catch (e) {}
    };

    const [showActions, setShowActions] = useState(false);

    return (
        <Fragment>
            <MessageDate date={date} />
            <Fragment>
                {message.map((m, i) => {
                    const { id, type, user, conference, conferenceData } = m;
                    if (type === 'event') {
                        return null;
                    }
                    if (type === 'conference') {
                        let participant = [];
                        let isConferenceCompleted = conferenceData?.status === 'completed';
                        if (conferenceData) {
                            if (isConferenceCompleted) {
                                participant = conferenceData.allParticipants?.map((participant) => ({
                                    username: participant.name,
                                    id: participant._id || participant.id,
                                    avatar: participant.avatar,
                                }));
                            } else {
                                participant = conferenceData.participants?.map((participant) => ({
                                    username: participant.name,
                                    id: participant._id || participant.id,
                                    avatar: participant.avatar,
                                }));
                            }
                            let alreadyInConference = participant.find(
                                (participant) => participant.id === (currUser.id || currUser._id)
                            );
                            return (
                                <div key={i} className="d-flex justify-content-end">
                                    <div
                                        css={css`
                                            margin: 20px 45px 20px 0;
                                        `}
                                    >
                                        <div
                                            className="d-flex flex-column"
                                            css={css`
                                                border-radius: ${isConferenceCompleted ? '50px' : '20px'};
                                                padding: 15px;
                                                background-color: ${isConferenceCompleted ? '#e7e7e7' : '#50aeb0'};
                                                color: ${isConferenceCompleted ? '#1f1f1f' : '#fff'};
                                                justify-content: space-between;
                                            `}
                                            onClick={() => setShowActions(!showActions)}
                                        >
                                            <div className="d-flex justify-content-between">
                                                {isConferenceCompleted ? (
                                                    <h3 className=" d-flex align-items-center mr-4">
                                                        <span
                                                            css={css`
                                                                width: 40px;
                                                                height: 40px;
                                                                border-radius: 20px;
                                                                background-color: #d3d3d3;
                                                                margin-right: 10px;
                                                                display: flex;
                                                                justify-content: center;
                                                                align-items: center;
                                                            `}
                                                        >
                                                            <MdCallEnd color="#1f1f1f" fontSize="24" />
                                                        </span>
                                                        <span className="mr-2">Meeting ended in </span>
                                                        <b>
                                                            <OngoingCallTime
                                                                startedAt={conferenceData.startedAt}
                                                                endTime={conferenceData.endedAt}
                                                            />{' '}
                                                        </b>
                                                    </h3>
                                                ) : (
                                                    <h1 className="d-flex  mr-4">
                                                        <span
                                                            className="d-flex justify-content-center align-items-center"
                                                            css={css`
                                                                width: 40px;
                                                                height: 40px;
                                                                border-radius: 20px;
                                                                background-color: #5da3a5;
                                                                margin-right: 10px;
                                                            `}
                                                        >
                                                            <MdCall color="#fff" fontSize="24" />
                                                        </span>
                                                        Meeting is ongoing
                                                    </h1>
                                                )}
                                                <div className="d-flex align-items-center">
                                                    <div className="ml-4">
                                                        <AvatarStack stack={participant} />
                                                    </div>
                                                </div>
                                            </div>

                                            {!isConferenceCompleted && (
                                                <div>
                                                    <div className="d-flex justify-content-center">
                                                        <div className="d-flex align-items-center">
                                                            <AiOutlineClockCircle color="#fff" className="mr-3" />

                                                            <OngoingCallTime
                                                                startedAt={conferenceData.startedAt}
                                                                endTime={conferenceData.endedAt}
                                                            />
                                                        </div>
                                                    </div>
                                                    {alreadyInConference ? (
                                                        <div
                                                            className="d-flex mt-3 justify-content-center align-items-center"
                                                            css={css`
                                                                color: white;
                                                                font-size: 16px;
                                                                background-color: #459597;
                                                                width: 80%;
                                                                margin: auto;
                                                                padding: 6px;
                                                                border-radius: 15px;
                                                            `}
                                                        >
                                                            <FaInfoCircle className="mr-2" />
                                                            <span>You are in the Meeting</span>
                                                        </div>
                                                    ) : (
                                                        <div className="d-flex">
                                                            <Button
                                                                className="mt-3 mx-auto"
                                                                onClick={() => {
                                                                    handleUserCall(conference);
                                                                }}
                                                                css={css`
                                                                    width: 80%;
                                                                    border-radius: 25px;
                                                                    background-color: white;
                                                                    color: #50aeb0;
                                                                    font-size: 20px;
                                                                    display: flex;
                                                                    justify-content: center;
                                                                    align-items: center;
                                                                    padding: 2px 0;
                                                                    :hover {
                                                                        background-color: #fff;
                                                                        color: #50aeb0;
                                                                    }
                                                                `}
                                                            >
                                                                <MdPhone className="mr-3" />
                                                                Join
                                                            </Button>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        {showActions && (
                                            <div
                                                className="d-flex justify-content-end lowerDetails"
                                                css={css`
                                                    font-size: 12px;
                                                `}
                                            >
                                                <span className="px-2 text-right text-muted text-small text-uppercase">
                                                    <MessageTime dateAndTime={conferenceData?.startedAt} />
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            );
                        } else {
                            return null;
                        }
                    }
                    return currUser && ((user?.id || user?._id) === currUser.id || user === currUser.id) ? (
                        // CURRENT USER MESSAGE
                        <Fragment key={id}>
                            <SenderMessage
                                m={m}
                                rId={rId}
                                teamData={teamData}
                                setReply={setReply}
                                position={position}
                                refs={refs}
                                handleClick={handleClick}
                                handleMessageClick={handleMessageClick}
                                currUser={currUser}
                                sendEditedMessage={sendEditedMessage}
                                handleDeleteMessage={handleDeleteMessage}
                                isForm={isForm}
                                roomType={roomType}
                                userRole={userRole}
                                showMessageReactions={showMessageReactions}
                            />
                            {lastSeen === id && (
                                <p className="d-flex border-bottom  border-white pt-5  m-0 mb-2 text-muted  justify-content-center ">
                                    <span className="bg-light px-4 py-2" style={{ borderRadius: '10px 10px 0px 0px' }}>
                                        New Messages
                                    </span>
                                </p>
                            )}
                        </Fragment>
                    ) : (
                        // CURRENT USER MESSAGE END

                        // OTHER USERS MESSAGE
                        <Fragment key={id}>
                            <ReceiverMessage
                                m={m}
                                rId={rId}
                                teamData={teamData}
                                setReply={setReply}
                                position={position}
                                refs={refs}
                                handleClick={handleClick}
                                handleMessageClick={handleMessageClick}
                                currUser={currUser}
                                handleDeleteMessage={handleDeleteMessage}
                                roomType={roomType}
                                userRole={userRole}
                                isForm={isForm}
                                showMessageReactions={showMessageReactions}
                            />
                            {/* {lastSeen === id && (
								<p className="d-flex border-bottom  border-white pt-5  m-0 mb-2 text-muted  justify-content-center ">
									<span className="bg-light px-4 py-2" style={{ borderRadius: '10px 10px 0px 0px' }}>
										New Messages
									</span>
								</p>
							)} */}
                        </Fragment>
                    );
                })}
            </Fragment>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    currUser: state.user.user,
    rooms: state.rooms.teamRooms,
    position: state.user.position,
});

Message.propTypes = {
    message: PropTypes.array.isRequired,
    date: PropTypes.string.isRequired,
    currUser: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {
    deleteChatRoomMessage,
    editChatRoomMessage,
    startConference,
    setConferenceData,
})(Message);
