

import { css } from 'twin.macro';

export const detailContainer = css`
	height: fit-content;
	width:400px;
	position:relative;
	min-height: 100vh; 
	background-color: white;
	.text-center {
		color: #50aeb0;
	}
	.text-center:hover {
		color: #0892bd;
	}
	.disabled,
	.disabled:hover {
		color: grey;
	}

	.about{
		padding:20px 10px 0;
		width:100%;
		display:flex;
		justify-content: space-between;
	}

`;
export const detailOptions = css`
	padding:0px 10px;

	.options{
		width:100%;
		display:flex;
		flex-direction: row;
		align-items: center;
		padding:10px;
		justify-content: space-between;
		height:50px;
		transition: background-color 100ms ease-in-out;
		cursor:pointer;
		&:hover{
			background-color: #E0F0EF;
		}
	}

	.option-content{
		width: 100%;
		background: #F2F8F7;
		box-shadow:rgb(204 219 232) 2px 2px 4px 0px inset;
		display: flex;
		justify-content: center;
		padding: 2rem;						
	}

	.option-members-content{
		padding:10px;
		background: #F2F8F7;
		box-shadow:rgb(204 219 232) 2px 2px 4px 0px inset;
	}
`;
export const MemberOverflow = css`
	max-height: 35vh;
	height: auto;
	overflow: auto;
	padding-top:1rem;
	cursor:pointer;
`;

export const actions = css`
	padding: 10px 20px;
	width:70px;
	background-color: #E0F0EF;
	display:flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border:1px solid #1B686A;
	color:#1B686A;
	border-radius: 10px;
	font-size:24px;
	transition: transform 200ms ease-in-out;
	cursor:pointer;
	span{
		font-size: 12px;
		margin-top:2.5px;
	}
	&:hover{
		transform: scale(1.05);
		text-decoration:none;
	}
`;