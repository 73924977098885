import React from 'react';
import { css } from 'twin.macro';
import { useState, useEffect, Fragment, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';

//redux
import { connect } from 'react-redux';
import Messages from './Messages';

//layout elements
import container from '../../../_elements/container';
import outerContainer from '../../../_elements/outerContainer';
import ChatSkeleton from '../Layouts/ChatSkeleton';
import { WebSocketContext } from '../../../_redux/actions/ActionSocket';
import { clearSearchMessages, searchChat } from '../../../_redux/actions/ActionMessage';
import { FaInfoCircle } from 'react-icons/fa';
import ProfileDrawer from '../../Drawer/ProfileDrawer';
import { ToolTip } from '../../../_elements/ToolTip';
import SearchConversation from '../Teams/SearchConversation';
import ClearSearch from '../Common/ClearSearch';
import { BiArrowBack } from 'react-icons/bi';
import { navIcon } from '../../Admin/reusableComponents/NavigationBar';
import ChatInput from '../Common/ChatInput';
import { getUniqueObjects } from '../../../_helpers';

const PrivateChat = ({
	MessagesFromRedux,
	user,
	userName,
	userId,
	position,
	clearSearchMessages,
	searchChat,
	toggleChat,
	showChatInput,

}) => {
	const [oldmessages, setoldmessages] = useState([]);
	const [reply, setReply] = useState(null);
	const [profileDrawer, showProfileDrawer] = useState(false);
	const [searchText, setSearchText] = useState('');
	const updateSearchText = (txt) => {
		setSearchText(txt);
	};

	useEffect(() => {
		updateSearchText('');
	}, [userId]);

	const { isLoading, isSearch, searchMessages, messages } = MessagesFromRedux;
	let totalMessages, lastSeenMessage;
	const ws = useContext(WebSocketContext);

	if (messages[userId]) {
		totalMessages = messages[userId].totalMessages;
		lastSeenMessage = messages[userId].lastSeen;
	}
	const setMessages = useCallback(() => {
		if (messages[userId]) {
			let tempoldmessages = messages[userId].message
				.map((history) => ({
					file: history.file,
					images: history.images,
					files: history.files,
					id: history.id,
					data: history.data,
					type: history.type,
					fromUser: history.fromUser,
					toUser: history.toUser,
					dateAndTime: history.createdAt,
					isEdited: history.isEdited,
				}))
				.reverse();
			setoldmessages(getUniqueObjects(tempoldmessages));
		}
	}, [messages, userId]);

	useEffect(() => {
		let isActive = true;
		let tempoldmessages;
		if (!isSearch && isActive) {
			setMessages();
		} else {
			if (searchMessages) {
				tempoldmessages =
					searchMessages &&
					searchMessages
						.map((history) => ({
							id: history.id,
							file: history.file,
							images: history.images,
							files: history.files,
							data: history.data,
							type: history.type,
							user: history.user,
							userName: history.userName,
							roomId: history.room,
							dateAndTime: history.createdAt,
							fromUser: history.fromUser,
							toUser: history.toUser,
							isEdited: history.isEdited,
						}))
						.reverse();
				setoldmessages(getUniqueObjects(tempoldmessages));
			}
		}
		return () => (isActive = false);
	}, [searchMessages, isSearch, setMessages]);

	const sendMessage = async (event, message) => {
		event.preventDefault();
		if (message) {
			ws.sendMessage(message, reply, position, { userId: userId });
		}
	};

	const showChatList = () => {
		toggleChat(false);
	};

	return (
		<div css={outerContainer}>
			<div css={container}>
				<h1 className="name-title h4">
					<div className="d-flex w-100 justify-content-between">
						<div className="d-flex">
							<BiArrowBack
								css={navIcon}
								size={24}
								className="pointer d-md-none"
								onClick={() => showChatList()}
							/>
							<div onClick={() => showProfileDrawer(!profileDrawer)}>{userName}</div>
						</div>

						<div className="d-flex">
							<SearchConversation
								searchChat={searchChat}
								id={userId}
								clearSearchMessages={clearSearchMessages}
								isDirectChat={true}
								updateSearchText={updateSearchText}
								searchText={searchText}
							/>

							<div
								className="d-flex justify-content-center align-items-center ml-4"
								onClick={() => showProfileDrawer(true)}
							>
								<ToolTip text={'User Details'} side="left" className="infoText small">
									<span
										css={css`
											transition: transform 150ms;
											display: flex;
											justify-content: center;
											align-items: center;
											transform-origin: center;
											:hover {
												transform: scale(1.2) !important;
											}
										`}
									>
										<FaInfoCircle />
									</span>
								</ToolTip>
							</div>
						</div>
					</div>

					{profileDrawer && userId && (
						<ProfileDrawer userId={userId} from="chat" showProfileDrawer={showProfileDrawer} />
					)}
				</h1>
				<ClearSearch updateSearchText={updateSearchText} />

				{!isLoading ? (
					<Fragment>
						{oldmessages.length > 0 ? (
							oldmessages[0].toUser === userId || oldmessages[0].fromUser === userId ? (
								<Messages
									messages={oldmessages}
									totalMessages={totalMessages}
									user={user}
									userName={userName}
									userId={userId}
									setReply={setReply}
									lastSeen={lastSeenMessage}
								/>
							) : (
								<div className="h-100 d-flex text-center flex-column justify-content-center align-items-center">
									<h2>
										<b>No Messages Yet!</b>
									</h2>
									<div
										css={css`
											color: gray;
											font-style: italic;
											width: 30%;
											line-height: 1.2;
											margin-top: 0.5rem;
										`}
									>
										Send a message to start a conversation.
									</div>{' '}
								</div>
							)
						) : (
							<div className="h-100 d-flex text-center flex-column justify-content-center align-items-center">
								<h2>
									<b>No Messages Yet!</b>
								</h2>
								<div
									css={css`
										color: gray;
										font-style: italic;
										width: 30%;
										line-height: 1.2;
										margin-top: 0.5rem;
									`}
								>
									Send a message to start a conversation.
								</div>{' '}
							</div>
						)}
						{showChatInput && (
							<ChatInput
								reply={reply}
								setReply={setReply}
								key={userId}
								id={userId}
								sendMessage={sendMessage}
								type="private"
							/>
						)}
					</Fragment>
				) : (
					<div
						className="mx-4"
					>
						<ChatSkeleton type="direct" />
					</div>
				)}
			</div>
		</div>
	);
};
const mapStateToProps = (state) => ({
	MessagesFromRedux: state.messages,
	position: state.user.position,
});

PrivateChat.propTypes = {
	MessagesFromRedux: PropTypes.object.isRequired,
	position: PropTypes.object.isRequired,
	userName: PropTypes.string.isRequired,
	userId: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, { clearSearchMessages, searchChat })(PrivateChat);
