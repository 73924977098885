import { css } from 'twin.macro';
import React, { useEffect, useState, useRef } from 'react';
import Video, { createLocalVideoTrack } from 'twilio-video';
import { connect, useSelector } from 'react-redux';
import { RoomContainer } from './styles/RoomStyles';
import { MdCallEnd, MdScreenShare, MdStopScreenShare } from 'react-icons/md';
import { AiOutlineAudio, AiOutlineAudioMuted } from 'react-icons/ai';
import { FiCameraOff, FiCamera } from 'react-icons/fi';
import SideMenu from './SideMenu/SideMenu';
import { BsPeopleFill } from 'react-icons/bs';
import logo from '../../../assets/logos/logo-xs.png';
import { getChannelMembers, getTeamMember } from '../../../_redux/actions/ActionOrganization';
import GridLayout from './Layout/GridLayout';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';

const Room = ({
	user,
	room,
	conferenceId,
	handleDisconnect,
	handleEndCall,
	audio,
	video,
	handleAudio,
	handleVideo,
	participantInfo,
	channelInfo,
	getTeamMember,
	getChannelMembers,
	conferenceHostId,
	handleMuteParticipant,
	handleRemoveParticipant,
}) => {
	const organization = useSelector((state) => {
		const organizations = state?.organizations?.organizations;
		return Array.isArray(organizations) && organizations.length > 0
		  ? organizations[0]
		  : null;
	  });
	const [sharingScreen, setSharingScreen] = useState(false);
	const [participants, setParticipants] = useState([]);
	const [dominantSpeaker, setDominantSpeaker] = useState(null);
	const [sideMenuActiveTab, setSideMenuActiveTab] = useState('People');
	const localScreenRef = useRef();
	const localVideoRef = useRef();
	const isHost = (user._id || user.id) === conferenceHostId;

	const handleCloseSideMenu = () => {
		setSideMenuActiveTab(false);
	};

	const handleSideMenuActiveTab = (tab) => {
		if (sideMenuActiveTab === tab) {
			setSideMenuActiveTab(false);
		} else {
			setSideMenuActiveTab(tab);
		}
	};
	useEffect(() => {
		if (channelInfo) {
			if (channelInfo?.type === 'public') {
				getTeamMember(channelInfo?.team?._id, organization.id);
			} else {
				getChannelMembers(channelInfo.id);
			}
		}
	}, [
		getChannelMembers,
		channelInfo,
		channelInfo.id,
		channelInfo?.type,
		channelInfo?.team?._id,
		organization.id,
		getTeamMember,
	]);

	useEffect(() => {
		const participantConnected = (participant) => {
			setParticipants((prevParticipants) => [...prevParticipants, participant]);
		};

		const participantDisconnected = (participant) => {
			setParticipants((prevParticipants) => prevParticipants.filter((p) => p !== participant));
		};

		room.on('participantConnected', participantConnected);
		room.on('participantDisconnected', participantDisconnected);
		room.participants.forEach(participantConnected);
		room.on('dominantSpeakerChanged', (participant) => {
			setDominantSpeaker(participant);
		});
		return () => {
			room.off('participantConnected', participantConnected);
			room.off('participantDisconnected', participantDisconnected);
		};
	}, [room]);

	const handleMute = () => {
		if (room) {
			if (!audio) {
				room.localParticipant.audioTracks.forEach((track) => {
					track.track.enable();
				});
			} else {
				room.localParticipant.audioTracks.forEach((track) => {
					track.track.disable();
				});
			}
			handleAudio({ target: { checked: !audio } });
		}
	};

	const handleCamera = async () => {
		if (room) {
			if (!video) {
				handleVideo({ target: { checked: true } });
				const videoTrack = await createLocalVideoTrack({
					height: 720,
					frameRate: 24,
					width: 1280,
					name: 'camera',
				});
				room.localParticipant.publishTrack(videoTrack);
				videoTrack.attach(localVideoRef.current);
			} else {
				handleVideo({ target: { checked: false } });
				room.localParticipant.videoTracks.forEach((track) => {
					if (track.trackName === 'camera') {
						track.track.stop();
						track.unpublish();
					}
				});
			}
		}
	};

	const handleScreenShare = () => {
		if (room) {
			if (!sharingScreen) {
				navigator.mediaDevices
					.getDisplayMedia({ video: { frameRate: 10 }, audio: false })
					.then(async (stream) => {
						const screenTrack = new Video.LocalVideoTrack(stream.getTracks()[0], { name: 'screenShare' });
						room.localParticipant.publishTrack(screenTrack);
						setSharingScreen(true);
						screenTrack.attach(localScreenRef.current);
						screenTrack.once('stopped', () => {
							room.localParticipant.unpublishTrack(screenTrack);
							setSharingScreen(false);
						});
					})
					.catch((e) => {
						console.log('Could not share the screen.', e);
					});
			} else {
				room.localParticipant.videoTracks.forEach((track) => {
					if (track.trackName === 'screenShare') {
						track.track.stop();
					}
				});
				setSharingScreen(false);
			}
		}
	};

	return (
		<RoomContainer>
			<div className="room">
				<div
					css={css`
						width: ${sideMenuActiveTab ? '80%' : '100%'};
					`}
				>
					<div
						css={css`
							display: flex;
							justify-content: space-between;
							align-items: center;
							padding-top: 1rem;
						`}
					>
						<div
							css={css`
								width: 80px;
							`}
						>
							<img
								src={logo}
								css={css`
									width: 100%;
								`}
								alt="logo"
							/>
						</div>
						<h2>
							Room: <strong>{channelInfo.title}</strong>
						</h2>
						<div></div>
					</div>

					<GridLayout
						participants={participants}
						room={room}
						user={user}
						dominantSpeaker={dominantSpeaker}
						audio={audio}
						video={video}
						sharingScreen={sharingScreen}
						participantInfo={participantInfo}
						localScreenRef={localScreenRef}
						localVideoRef={localVideoRef}
					/>
				</div>
				{sideMenuActiveTab && (
					<SideMenu
						conferenceId={conferenceId}
						activeTab={sideMenuActiveTab}
						handleCloseSideMenu={handleCloseSideMenu}
						participants={participantInfo}
						channelInfo={channelInfo}
						handleMuteParticipant={handleMuteParticipant}
						isHost={isHost}
						handleRemoveParticipant={handleRemoveParticipant}
					/>
				)}
			</div>

			<div className="controllersDiv">
				<div></div>
				<div className="controls">
					<div className="Btn" onClick={handleScreenShare}>
						{!sharingScreen ? <MdScreenShare fontSize="20" /> : <MdStopScreenShare fontSize="20" />}
					</div>
					<div className="Btn" onClick={handleCamera}>
						{video ? <FiCamera fontSize="20" /> : <FiCameraOff fontSize="20" />}
					</div>
					<div className="Btn" onClick={handleMute}>
						{audio ? <AiOutlineAudio fontSize="20" /> : <AiOutlineAudioMuted fontSize="20" />}
					</div>

					{isHost ? (
						<div
							className="Btn"
							css={css`
								.dropup .dropdown-toggle::after {
									content: none;
								}
								button {
									background: transparent;
									border: 0;
									box-shadow: none;
									:focus {
										box-shadow: none;
									}
								}
								.dropdown-item {
									font-size: 14px;
								}
							`}
						>
							<DropdownButton
								as={ButtonGroup}
								id={`dropdown-button-drop-up`}
								drop={'up'}
								variant="link"
								title={<MdCallEnd fontSize="26" color="red" />}
							>
								<Dropdown.Item eventKey="1" onClick={handleDisconnect}>
									Leave
								</Dropdown.Item>
								<Dropdown.Item eventKey="2" onClick={handleEndCall}>
									End Call
								</Dropdown.Item>
							</DropdownButton>
						</div>
					) : (
						<div className="Btn" onClick={handleDisconnect}>
							<MdCallEnd fontSize="26" color="red" />
						</div>
					)}
				</div>
				<div className="controls">
					<div className="Btn" onClick={() => handleSideMenuActiveTab('People')}>
						<BsPeopleFill />
					</div>
				</div>
			</div>
		</RoomContainer>
	);
};

const mapStateToProps = (state) => ({
	user: state.user.user,
});

export default connect(mapStateToProps, { getChannelMembers, getTeamMember })(Room);
