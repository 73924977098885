import { css } from 'twin.macro';
import { Button, Modal } from 'react-bootstrap';
import { useState, useEffect, Fragment } from 'react';
import VisitTemplateAccordionForms from './VisitTemplateAccordionForms';
import { fetchWrapper, getFormDataWithValue } from '../../../../../_helpers';
import { connect } from 'react-redux';
import { ToolTip } from '../../../../../_elements/ToolTip';
import loaderStyles from '../../../../../_elements/loader';
import { FaFileAlt } from "react-icons/fa";
import { BsEye } from 'react-icons/bs';
import { IoDocumentsOutline } from "react-icons/io5";

function VisitTemplateAccordionModal({
  data,
  currUser,
  mode,
  handleFormClosed = () => { },
  handleFormOpened = () => { },
  templateListingMode=false,
  employeeUserName
}) {
  const [show, setShow] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [templateData, setTemplateData] = useState({});
  const [forms, setForms] = useState([]);

  const handleClose = () => {
    setTemplateData({});
    setForms([]);
    setShow(false);
    handleFormClosed();
  }

  const handleShow = () => {
    handleFormOpened();
    setShow(true)
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingData(true);
        const response = await fetchWrapper.get(`visit-templates/${data?._id}`);
        if (mode === 'view') {
          setForms(response?.formsData || [])
        }
        setTemplateData(response);
      } catch (err) {
        console.log("err", err);
      } finally {
        setLoadingData(false);
      }
    }

    if (data?._id && data?.chatroom && show) {
      fetchData();
    }

  }, [show, data?._id,mode])

  useEffect(() => {
    const chatroomData = templateData?.chatroomData || {};
    if (chatroomData?.ongoingServices && templateData?.sosForm && !templateListingMode) {
      const ongoingServiceInCurrentRoom = chatroomData?.ongoingServices?.find((service) => service?.employee === currUser?.username);
      if (ongoingServiceInCurrentRoom) {
        const sosForm = ongoingServiceInCurrentRoom?.forms?.find((form) => form?.type === 'sos');
        let sosFormElements = sosForm?.formData.map(form => ({ ...form }));
        if (!sosForm && !sosFormElements?.length) {
          return;
        } else {
          sosFormElements = getFormDataWithValue(sosFormElements,sosForm?.data);
          let otherSubmittedForms = ongoingServiceInCurrentRoom?.forms?.filter((form) => form?.type !== 'sos');
          const otherForms = templateData?.formsData?.filter((form) => (form?.type !== 'sos' && form?.type !== 'eos'));
          const eosFrom = templateData?.formsData?.find((form) => form?.type === 'eos');

          otherSubmittedForms = otherSubmittedForms?.map((otherForm, index) => {
            let otherFormElement = otherForm?.formData.map(form => ({ ...form }));
            otherFormElement = getFormDataWithValue(otherFormElement,otherForm?.data);
            return {
              ...otherForm,
              formElements: otherFormElement,
              submitted: true
            };

          });
          setForms([{ ...sosForm, submitted: true, formElements: sosFormElements }, ...otherSubmittedForms, ...otherForms, { ...eosFrom }]);
        }
      }

    }

  }, [templateData])

  return (
    <Fragment>
      {
       ( mode === 'view' && !templateListingMode )?
          <ToolTip text={'View'} cssStyles={"top:-70% !important; min-width: fit-content !important;"}>
            <BsEye className="icon view" onClick={handleShow} />
          </ToolTip>
          : null
      }
      {
        (mode === 'view' && templateListingMode) ?
          <span css={css`display:flex;gap:0.5rem;align-items:center;cursor:pointer;`} onClick={handleShow}>
            <IoDocumentsOutline css={css`margin-right:0.5rem;`} />
            {data?.name}
            <span className='badge badge-warning'>visit-template</span>
          </span>
          : null
      }

      {
        (mode === 'submit-forms' && !templateListingMode && (employeeUserName === currUser?.username)) ?
          <ToolTip text={'View Template'} cssStyles={"top:-99% !important; min-width: 112px !important;"}>
            <FaFileAlt onClick={handleShow} className="" css={css`color:#fff; cursor: pointer; width: 24px; height: 24px; padding: 2px 4px; border-radius: 8px;`}/>
          </ToolTip>
          : null
      }
      <Modal show={show} onHide={handleClose} size='xl' backdrop="static" >
      <Modal.Header closeButton>
          <div css={css`display:flex;flex-direction:row;align-items:center;justify-content:center;width:100%;`}>
            <h3 css={css`font-weight:600;margin-right:1rem;`} className='text-capitalize' >{data?.name || templateData?.name}</h3>
            <span className='badge badge-warning mr-2'>visit-template</span>
            {mode === 'submit-forms' ?  <span className='badge badge-info'>Ongoing Service</span> : null}
          </div>
        </Modal.Header>
        <Modal.Body>
          {loadingData ?
            <div css={css`height:300px;`}>
              <div css={loaderStyles} >
                <img src="/images/loading.gif" alt="loader" />
              </div> </div> : null}
          <VisitTemplateAccordionForms
            forms={forms || []}
            currUser={currUser}
            currRoom={templateData?.chatroomData}
            team={templateData?.teamData}
            position={currUser.position}
            handleCloseAccordionModal={handleClose}
            visitTemplateId={data?._id || data?.id}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}


const mapStateToProps = (state) => ({
  currUser: state.user.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(VisitTemplateAccordionModal);
