import React, { useState, useEffect } from 'react';
import { Modal, Form, Col, Button } from 'react-bootstrap';
import { HiOutlineFilter } from 'react-icons/hi';
import { useForm } from 'react-hook-form';
import SelectChannelComponent from './SelectChannelComponent';
import { FormModalStyled, FormFilterModalStyled } from './styles/FormFilterModalStyled';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import ReactSelectComponent from '../users/ReactSelectComponent';

export default function FormFilterModal({
	handleFilterSubmit,
	filterUser,
	filterChannel,
	// filterText,
	filterStartDate,
	filterEndDate,
}) {
	const [show, setShow] = useState(false);
	const [selectedChannelOption, setSelectedChannelOption] = useState(null);
	const [selectedUserOption, setSelectedUserOption] = useState(null);
	const [searchForm, setSearchForm] = useState([
		{
			startDate: '',
			endDate: new Date(),
			key: 'selection',
		},
	]);

	const { register, handleSubmit, setValue } = useForm({
		mode: 'onBlur',
	});

	useEffect(() => {
		if (!filterUser) {
			setSelectedUserOption(null);
		}
	}, [filterUser]);
	useEffect(() => {
		if (!filterChannel) {
			setSelectedChannelOption(null);
		}
	}, [filterChannel]);
	// useEffect(() => {
	// 	if (!filterText) {
	// 		setValue('searchText', null);
	// 	}
	// }, [setValue, filterText]);
	useEffect(() => {
		if (!filterStartDate || !filterEndDate) {
			setSearchForm([
				{
					startDate: '',
					endDate: new Date(),
					key: 'selection',
				},
			]);
		}
	}, [filterStartDate, filterEndDate]);

	const handleClose = () => setShow(false);
	const handleShow = () => {
		setShow(true);
	};
	const onSubmit = (data) => {
		const datawithdates = {
			userValue: data.userValue,
			channelValue: data.channelValue,
			searchText: data.searchText,
			startDateTime: searchForm[0].startDate,
			endDateTime: searchForm[0].endDate,
		};
		handleClose();
		// console.log(startDateTime, endDateTime, "herere")
		handleFilterSubmit(datawithdates);
	};
	return (
		<FormFilterModalStyled>
			<button className="btn button-green d-flex flex-row align-items-center" onClick={handleShow}>
				<HiOutlineFilter size={20} />
				&nbsp;Filter Forms
			</button>
			<FormModalStyled show={show} onHide={handleClose}>
				<Modal.Header> Custom Filters </Modal.Header>
				<Modal.Body>
					<Form className="w-75 m-auto">
						<Form.Row>
							<Form.Group as={Col} md={12}>
								<Form.Label>
									<strong>Submitted TimeFrame</strong>
								</Form.Label>
								<br />
								<DateRange
									rangeColors={['#50aeb0']}
									maxDate={new Date()}
									editableDateInputs={true}
									onChange={(item) => setSearchForm([item.selection])}
									moveRangeOnFirstSelection={false}
									ranges={searchForm}
								/>
							</Form.Group>

							<Form.Group as={Col} md={12}>
								<Form.Label>
									<strong>User </strong>
								</Form.Label>
								<Form.Control
									type="text"
									className="hiddenInput"
									name="userValue"
									readOnly
									ref={register}
									value={
										selectedUserOption
											? `${selectedUserOption.name}-${selectedUserOption.value}`
											: ''
									}
								/>
								<ReactSelectComponent
									selectedOption={selectedUserOption}
									setSelectedOption={setSelectedUserOption}
								/>
							</Form.Group>
							<Form.Group as={Col} md={12}>
								<Form.Label>
									<strong>Channel Name</strong>
								</Form.Label>
								<Form.Control
									type="text"
									className="hiddenInput"
									name="channelValue"
									readOnly
									ref={register}
									value={
										selectedChannelOption
											? `${selectedChannelOption.label}-${selectedChannelOption.value}`
											: ''
									}
								/>
								<SelectChannelComponent
									selectedOption={selectedChannelOption}
									setSelectedOption={setSelectedChannelOption}
								/>
							</Form.Group>
							{/* <Form.Group as={Col} md={12}>
								<Form.Label>
									<strong>Search By Text:</strong>
								</Form.Label>
								<Form.Control type="text" name="searchText" ref={register} />
							</Form.Group> */}

							<Form.Group as={Col} md={12}>
								<Button variant="primary" onClick={handleSubmit(onSubmit)}>
									Filter
								</Button>
							</Form.Group>
						</Form.Row>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
				</Modal.Footer>
			</FormModalStyled>
		</FormFilterModalStyled>
	);
}
