// import { Image } from 'cloudinary-react';
import { SRLWrapper } from 'simple-react-lightbox';
import ShareBtn from '../../ShareBtn/ShareBtn';
import { css } from 'twin.macro';
import { Image } from 'react-bootstrap';

const options = {
	settings: { disableWheelControls: true, disableKeyboardControls: true },
	buttons: { showNextButton: false, showPrevButton: false, showThumbnailsButton: false, showAutoplayButton: false },
	caption: {
		showCaption: true,
	},
	thumbnails: {
		showThumbnails: false,
	},
};

const ImageModal = ({ file, className, noOptimization, documentSettings }) => {
	return (
		<SRLWrapper
			options={{
				...options,
				buttons: {
					...options.buttons,
					showDownloadButton: documentSettings,
				},
			}}
			className={`${className} srlwrapper`}
		>
			<div
				css={css`
					position: relative;
					:hover .shareBtn {
						opacity: 1;
						z-index: 3;
					}
				`}
			>
				<ShareBtn url={file} />
				<a href={file.startsWith('http:') ? file.split('http:').join('https:') : file} className={className}>
					{noOptimization ? (
						<Image
							// cloudName={file?.split('/')[3]}
							// publicId={file.split('/')[file.split('/').length - 1].split('.')[0]}
							// secure="true"
							// loading="lazy"
							// quality="auto"
							src={file}
							className={className}
							style={{ objectFit: 'contain', width: '100%', height: '100%' }}
						/>
					) : (
						<Image
							// cloudName={file?.split('/')[3]}
							// publicId={file.split('/')[file.split('/').length - 1].split('.')[0]}
							// secure="true"
							// loading="lazy"
							// quality="auto"
							// crop="lfill"
							src={file}
							width={documentSettings ? '80' : '250'}
							height={documentSettings ? '80' : '250'}
							className={className}
						// style={{ objectFit: 'contain' }}
						/>
					)}
				</a>
			</div>
		</SRLWrapper>
	);
};

export default ImageModal;
