import React, { useState,useEffect, Fragment } from 'react';
import { connect, useSelector } from 'react-redux';
import { fetchWrapper, getTeamIdFromURL } from '../../../../_helpers';
import { TableCSS } from './styles/MembersStyled';
import dayjs from 'dayjs';
import { css } from 'twin.macro';
import { BiLinkExternal } from 'react-icons/bi';
import { ToolTip } from '../../../../_elements/ToolTip';



const TeamChannels = ({teams}) => {
    const[channels, setChannels] = useState(null);
    const [filteredChannels, setFilteredChannels] = useState();
    const teamId = getTeamIdFromURL();
    let teamArr = teams.filter((team) => team.id === teamId);
    let team = teamArr[0];

    useEffect(() => {
        const getTeamChannels = async () => {
            try {
                if (team?.organization && team?.id) {
                    const response = await fetchWrapper.get(`chatrooms?teamId=${teamId}&limit=2000&page=1`);
                    console.log(response?.results);
                    let results = response?.results;
                    setFilteredChannels(results);
                }
            } catch (error) {
                console.log('ERROR', error)
            }
        };
		if (team) {
			getTeamChannels();
		}
	}, [team, teamId]);
  
console.log(filteredChannels);

  return (
    <div css ={css`padding:20px;`} >
        <h1>Channels</h1>
        <div className="custom-card mt-4">
            <TableCSS>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th >Channel Type</th>
                        <th >Created Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        filteredChannels  && filteredChannels.length > 0 ? (
                            <Fragment>
                                {
                                    filteredChannels
                                        .map((channel, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{channel.title}</td>
                                                    <td>{channel.type}</td>
                                                    <td>{dayjs(channel.updatedAt).format('MMM D, YYYY')}</td>
                                                    <td>
                                                        <ToolTip  side="left" text="Go to channel">
                                                            <a href={`/teams/${channel.id}`}>
                                                                <BiLinkExternal/>
                                                            </a>
                                                        </ToolTip>
                                                    </td>
                                                </tr>
                                            )
                        
                                        })
                                }
                            </Fragment>
                        ) : (
                            <tr>
                                <td colSpan="100">
                                    <h4 className="text-center">Oops! No Channels Found</h4>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </TableCSS>
        </div>
    </div>
    
  )
}

const mapStateToProps = (state) => ({
	teams: state.teams.teams,
	userRole: state.user?.user?.role,
});

export default connect(mapStateToProps, {  })(TeamChannels);