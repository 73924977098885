import * as io from 'socket.io-client';
import { requestHeaders } from './request-headers';

let socket;

export const establishSocketConnection = async () => {
	const ENDPOINT = process.env.REACT_APP_CHAT_API;
	try {
		const headers = await requestHeaders();

		if (!headers.Authorization) {
			throw new Error("Missing Authorization Header")
		}

		if (socket?.connected) {
			return socket;
		}

		socket = io.connect(ENDPOINT, {
			query: { source: "web" },
			extraHeaders: {
				Authorization: headers.Authorization,
			},
			withCredentials: true,
		});

		return socket;
	} catch (e) {
		return;
	}
};
