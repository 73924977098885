import React, { useState } from 'react';
import { css } from 'twin.macro';
import AsyncSelect from 'react-select/async';
import { fetchWrapper } from '../../../../_helpers';
import { toast } from 'react-toastify';
export default function AssignedAccrualPolicySelector({
    selectAccrualPolicy,
    provider,
    employee,
    userId,
    policyCode,
    isDisabled,
    noAstrick,
}) {
    const [selectedAccrualPolicy, setSelectedAccrualPolicy] = useState();
    const handleSelect = (selected) => {
        selectAccrualPolicy(selected.value);
        setSelectedAccrualPolicy(selected);
    };

    const loadOptions = (inputValue, callback) => {
        setTimeout(async () => {
            let tempArray = [];
            try {
                if (!provider || !employee) {
                    return;
                }
                const response = await fetchWrapper.get(
                    `evv/payroll/zeal/employees/accrual-policies?submitted=true&provider=${provider}&employee=${employee}`
                );
                let results = response.accrualPolicies || [];
                let options = results.map((policy) => ({
                    label: (
                        <div>
                            {`${policy.policy_code} - ${policy.policy_name}`}
                            <span className="ml-2 badge badge-primary">{policy.policy_type}</span>
                            <span
                                className={`ml-2 badge badge-${policy.policy_status === 'live' ? 'warning' : 'danger'}`}
                            >
                                {policy.policy_status}
                            </span>
                        </div>
                    ),
                    value: { ...policy },
                }));
                tempArray = options;
                const defaultSelectedPolicy = options.find((policy) => policy?.value?.policy_code === policyCode);
                if (defaultSelectedPolicy && policyCode) {
                    handleSelect(defaultSelectedPolicy);
                }
            } catch (err) {
                if (err?.name === 'AbortError') return;
                console.log(err);
                toast.error(err?.message);
            }
            callback(tempArray);
        }, 0);
    };

    return (
        <div
            css={css`
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 10px;
            `}
        >
            <strong
                css={css`
                    width: 256px;
                    margin-left: 8px;
                `}
            >
                Select Accrual Policy {noAstrick ? null : <span className="text-danger">*</span>}
            </strong>
            <div
                css={css`
                    width: 100%;
                `}
            >
                <AsyncSelect
                    defaultOptions
                    name="provider"
                    loadOptions={loadOptions}
                    classNamePrefix="select"
                    value={selectedAccrualPolicy}
                    onChange={handleSelect}
                    isDisabled={isDisabled}
                />
            </div>
        </div>
    );
}
