import React, { Fragment, useEffect, useState } from 'react';
import { buildQueryString, fetchWrapper, getStandardDate } from '../../../../../../../_helpers';
import DownloadReport from '../DownloadReport';

const DeductionsSummary = ({ companyID, slug, dateFilters = {} }) => {
    const [downloadLink, setDownloadLink] = useState('');
    const handleDownloadLink = (link) => setDownloadLink(link);
    const generateLink = async () => {
        const queryString = buildQueryString({
            startDate: getStandardDate(dateFilters.start),
            endDate: getStandardDate(dateFilters.end),
        });
        const response = await fetchWrapper.get(
            `evv/payroll/zeal/provider/${companyID}/reports/${slug}?${queryString}`
        );
        handleDownloadLink(response.url);
    };

    useEffect(() => {
        handleDownloadLink('');
    }, [dateFilters?.start, dateFilters?.end]);
    return (
        <Fragment>
            <DownloadReport
                disabled={!dateFilters?.end || !dateFilters?.start}
                onGenerateLink={generateLink}
                downloadLink={downloadLink}
                companyID={companyID}
                slug={slug}
                mediaType={'csv'}
            />
        </Fragment>
    );
};

export default DeductionsSummary;
