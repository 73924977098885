import React, { useMemo } from "react";
import SignaturePad from 'react-signature-canvas';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { SignatureStyled } from './styles/SignatureStyles';
import { Button } from "react-bootstrap";


const Signature = ({ data, elementRef, name, isPreview }) => {
	const [trimmedDataURL, setTrimmedDataURL] = useState('');
	const [showDoneBtn, setShowDoneBtn] = useState(true);
	let sigPad = useMemo(()=>({}),[]);

	const clear = (e) => {
		e.preventDefault();
		// clear signature if not empty
		if (!sigPad.isEmpty()) {
			sigPad.clear();
			sigPad.on();
			setTrimmedDataURL('')
			setShowDoneBtn(true);
		}
	};

	const trim = (e) => {
		e.preventDefault();
		if (sigPad.isEmpty()) {
			toast.error('Signature cannot be blank!');
		} else {
			setShowDoneBtn(false);
			sigPad.off();
			setTrimmedDataURL(sigPad.getTrimmedCanvas().toDataURL('image/png'));
		}
	};

	useEffect(() => {
		if(isPreview) {
			sigPad.off();
		}
	}, [isPreview, sigPad])

	return (
		<SignatureStyled>
			<div className={`sigContainer ${showDoneBtn? "" :" doneBorder"}`}>
				<SignaturePad
					canvasProps={{ className: 'sigPad' }}
					ref={(ref) => {
						sigPad = ref;
					}}
				/>
				<input
					type="text"
					ref={elementRef &&
						elementRef({
							required: data.hasOwnProperty('required') ? data.required : true,
						})}
					name={name}
					onChange={setTrimmedDataURL}
					value={trimmedDataURL}
					className="hiddenInput"
				/>

				<div className="w-100 d-flex justify-content-between">
					
					<Button variant="secondary" onClick={clear} disabled={isPreview}>
						Clear
					</Button>
					{showDoneBtn && (
						<Button variant="primary" onClick={trim} disabled={isPreview}>
							Done
						</Button>
					)}
				</div>
			</div>
		</SignatureStyled>
	);
};

export default Signature;
