import * as ActionTypes from './ActionTypes';

import { fetchWrapper } from '../../_helpers';

export const getChatRoomForms =
	(currRoom, setLoading, page = 1) =>
	async (dispatch) => {
		try {
			setLoading(true);
			const formTemplates = await fetchWrapper.get(
				`forms/${currRoom ? `?chatroom=${currRoom.id || currRoom._id}` : ''}&limit=10&page=${page}`
			);
			let currRoomId = currRoom.id || currRoom._id;
			const roomFormTemplates = formTemplates.results;
			console.log(roomFormTemplates, 'roomFormTemplates');
			dispatch({
				type: ActionTypes.SET_CHATROOM_FORMS,
				payload: { roomFormTemplates, chatRoomId: currRoomId },
			});
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log('ERROR GETING Forms: ', error);
		}
	};

export const clearChatRoomForms = (currRoom) => async (dispatch) => {
	try {
		let currRoomId = currRoom.id || currRoom._id;
		dispatch({
			type: ActionTypes.CLEAR_CHATROOM_FORMS,
			payload: { chatRoomId: currRoomId },
		});
	} catch (error) {
		console.log('ERROR Clearing Forms: ', error);
	}
};
export const getChatRoomFiles = (currRoom, setLoading) => async (dispatch) => {
	try {
		setLoading(true);
		let currRoomId = currRoom.id || currRoom._id;
		const response = await fetchWrapper.get(
			`chatrooms/${currRoom ? `${currRoomId}` : ''}/messages?limit=10&type=file`
		);
		const files = response.results;
		dispatch({ type: ActionTypes.SET_CHATROOM_FILES, payload: { roomFiles: files, chatRoomId: currRoomId } });
		setLoading(false);
	} catch (error) {
		setLoading(false);
		console.log('ERROR GETING Files: ', error);
	}
};
export const getChatRoomMedias =
	(currRoom, setLoading, page = 1) =>
	async (dispatch) => {
		try {
			setLoading(true);
			let currRoomId = currRoom?.id || currRoom?._id;
			if (currRoomId) {
				const response = await fetchWrapper.get(
					`chatrooms/${currRoom ? `${currRoomId}` : ''}/messages?limit=10&type=media&page=${page}`
				);
				const medias = response.results;
				dispatch({
					type: ActionTypes.SET_CHATROOM_MEDIAS,
					payload: {
						roomMedias: medias,
						totalMedia: response.totalResults,
						page: response.page,
						chatRoomId: currRoomId,
					},
				});
			}
			else if(currRoom){
				const response = await fetchWrapper.get(`directMessages/${currRoom}?sortBy=createdAt:desc&limit=10&type=media&page=${page}`);
				const medias = response.results;
				dispatch({
					type: ActionTypes.SET_CHATROOM_MEDIAS,
					payload: {
						roomMedias: medias,
						totalMedia: response.totalResults,
						page: response.page,
						chatRoomId: currRoom,
					},
				});
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log('ERROR GETING MEDIA: ', error);
		}
	};
