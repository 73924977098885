import styled from "@emotion/styled";
import { Form } from 'react-bootstrap';

export const Body = styled('div')`
    padding:10px;
    display:flex;
    flex-direction:column;
    border-radius:20px;
    & > .header {
        background-color:#F7F7F7;
        padding:20px 20px 60px 20px;
        display:flex;
        flex-direction:column;
        align-items:center;
        text-align:center;
    }

    & > .editImage {
        height:100px;
        width:100px;
        border-radius:50%;
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;
        align-self:center; 
    }
`;

export const Overlay = styled('div')`
    position:relative;
    height:100px;
    width:100px;
    border-radius:50%;
    background-color:white;
    margin-top:-50px;
    cursor:pointer;

    & > .fa-edit{
    position:absolute;
    display:none;
    top:30px;
    left:30px;
    color:white;
    font-size:40px;
    &:hover{
        display:block;
    }
    }

    & > .teamImg {
        height:100px;
        width:100px;
        border-radius:50%;
        position:absolute;
        top:0;
        left:0;
        -webkit-filter: grayscale(0) blur(0);
		filter: grayscale(0) blur(0);
		-webkit-transition: 0.3s ease-in-out;
		transition: 0.3s ease-in-out;
        opacity:0.5;
        -webkit-filter: grayscale(100%) blur(1px);
        filter: grayscale(100%) blur(1px);
        
        &:hover ~ .fa-edit{
            display:block;  
        }
        
    }
`;

export const DottedIcon = styled('div')`
    height:100px;
    width:100px;
    border-radius:50px;
    border:1px dashed black;
    background-color:white;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin-top:-50px;
    cursor:pointer;

    & > span{
        font-size:12px;
        color:black;
    }
    & > .camIcon {
        font-size:40px;
        color:black;
    }
`;

export const StyledForm = styled(Form)`
    display:flex;
    flex-direction:column;
    .form-label{
        margin-top: 01rem;
    }
    & > .errorText{
        font-size:12px;
        color:red;
        float:left;
    }
`;
