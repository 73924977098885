import React, { Fragment, useEffect, useState } from 'react';
import Select from 'react-select';
import { buildQueryString, fetchWrapper } from '../../../../../../../_helpers';
import DownloadReport from '../DownloadReport';
import ReportCheckBox from '../ReportCheckBox';
const workerTypes = ['employee', 'contractor'];
const WorkerSummary = ({ companyID, slug, dateFilters = {} }) => {
    const [downloadLink, setDownloadLink] = useState('');
    const handleDownloadLink = (link) => setDownloadLink(link);
    const [workerType, setWorkerType] = useState({ label: workerTypes[0], value: workerTypes[0] });
    const [activeWorkers, setActiveWorkers] = useState(true);
    const generateLink = async () => {
        const queryString = buildQueryString({
            workerType: workerType.value,
            activeWorkers,
        });
        const response = await fetchWrapper.get(
            `evv/payroll/zeal/provider/${companyID}/reports/${slug}?${queryString}`
        );
        handleDownloadLink(response.url);
    };

    useEffect(() => {
        handleDownloadLink('');
    }, [workerType.value, activeWorkers]);

    return (
        <Fragment>
            <div style={{ width: '300px' }}>
                <span>Check date: </span>
                <Select
                    value={workerType}
                    onChange={(option) => setWorkerType(option)}
                    options={workerTypes.map((option) => ({ label: option, value: option }))}
                />
            </div>
            <ReportCheckBox
                label={'Exclude workers who are on leave or have been terminated'}
                value={activeWorkers}
                onChange={setActiveWorkers}
            />
            <hr />
            <DownloadReport
                disabled={!workerType.value}
                onGenerateLink={generateLink}
                downloadLink={downloadLink}
                companyID={companyID}
                slug={slug}
            />
        </Fragment>
    );
};

export default WorkerSummary;
