import { css } from 'twin.macro';

export const profileInfoCss = css`
	display: flex;
	flex-direction:column;
	justify-content: center;
	margin-bottom: 10px;

	.avatar {
		width: 10rem;
		height: 10rem;
		border-radius: 5rem;
	}
	.replacementImg {
		border: none;
		display: flex;
		place-items: center;
		place-content: center;
		position: relative;
		.initials {
			color: white;
			font-size: 50px;
			font-weight: lighter;
		}
	}
	.role {
		margin-top:10px;
		.badge {
			width:100px;
		}
	}
`;