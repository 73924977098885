import React, { Fragment, useEffect, useState } from 'react';
import { css } from 'twin.macro';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { fetchWrapper, toastWrapper } from '../../../../../_helpers';
import { VscLoading } from 'react-icons/vsc';
import { RenderFormFields } from './Deductions/RenderFormFields';
import ReactSelect from 'react-select';

const deductionTypeOptions = {
    garnishment: 'Garnishment',
    section_125: 'Section 125',
    hsa: 'HSA',
    '401k': '401K',
    '403b': '403B',
    roth_401k: 'Roth 401K',
    roth_ira: 'Roth IRA',
    simple_ira: 'Simple IRA',
    employer_sponsored_health_coverage: 'Employer Sponsored Health Coverage',
    dependent_care_benefit: 'Dependent Care Benefit',
    miscellaneous: 'Miscellaneous',
};

function DeductionTemplates({ companyID, show, teamId }) {
    const { register, handleSubmit, setValue } = useForm({});
    const [deductionType, setDeductionType] = useState(null);
    const [formData, setFormData] = useState({});
    const [validEmployees, setValidEmployees] = useState();
    const [employees, setEmployees] = useState([]);
    const [isBusy, setIsBusy] = useState(false);
    const [allTemplates, setAllTemplates] = useState([]);

    const getDeductionTemplateDefinition = async (deductionType = '') => {
        try {
            setIsBusy(true);
            const result = await fetchWrapper.get(
                `evv/payroll/zeal/deduction-template?deductionType=${deductionType}${teamId ? `&team=${teamId}` : ''}`
            );
            setFormData({ ...(result || {}) });
        } catch (err) {
            console.log('err', err);
            toastWrapper.error(err?.message || 'Something went wrong while fetching deduction template definition');
            setFormData({});
        } finally {
            setIsBusy(false);
        }
    };

    const getValidEmployeesAndDeductionTemplates = async () => {
        try {
            setIsBusy(true);
            const employeesResult = await fetchWrapper.get(
                `evv/payroll/zeal/provider/${companyID}/employees${teamId ? `?team=${teamId}` : ''}`
            );
            setValidEmployees(employeesResult || []);
            const templatesResult = await fetchWrapper.get(
                `evv/payroll/zeal/provider/${companyID}/deduction-template${teamId ? `?team=${teamId}` : ''}`
            );
            setAllTemplates(templatesResult);
        } catch (err) {
            console.log('err', err);
            toastWrapper.error(err?.message || 'Something went wrong while fetching employees and deduction templates');
        } finally {
            setIsBusy(false);
        }
    };

    useEffect(() => {
        if (deductionType) {
            getDeductionTemplateDefinition(deductionType);
        }
    }, [deductionType]);

    useEffect(() => {
        if (companyID && show) {
            getValidEmployeesAndDeductionTemplates();
        }
    }, [companyID, show]);

    const handleDeductionTypeChange = (e) => {
        setDeductionType(e.target.value);
    };

    const onSubmit = async (data) => {
        try {
            setIsBusy(true);
            const employeeIDs = employees.map(({ value }) => value);
            const createdTemplate = await fetchWrapper.post(
                `evv/payroll/zeal/provider/${companyID}/deduction-template${teamId ? `?team=${teamId}` : ''}`,
                {
                    ...data,
                    employees: employeeIDs,
                    deductionTemplateID: formData?.deductionTemplateID,
                    deduction_type: deductionType || formData?.type,
                }
            );
            setAllTemplates((prev) => [
                ...prev,
                {
                    ...createdTemplate,
                    employees: employeeIDs.map((employeeID) =>
                        validEmployees?.find((employee) => employee.employeeID === employeeID)
                    ),
                },
            ]);
            toastWrapper.success(
                formData?.deductionTemplateID
                    ? 'Employees assigned to template successfully'
                    : 'Deduction template created successfully'
            );
        } catch (err) {
            console.log('err', err);
            toastWrapper.error(err?.message || 'Something went wrong while submitting deduction template');
        } finally {
            setIsBusy(false);
        }
    };

    return (
        <div
            css={css`
                margin-top: 6px;
            `}
        >
            <div
                css={css`
                    border: 2px solid #50aeb0;
                    border-radius: 5px;
                    padding: 1rem;
                    margin-bottom: 12px;
                `}
            >
                <div
                    className="d-flex justify-content-between"
                    css={css`
                        gap: 12px;
                    `}
                >
                    <Form.Group
                        css={css`
                            width: 60%;
                        `}
                    >
                        <Form.Label>Select Deduction Template</Form.Label>
                        <ReactSelect
                            onChange={(option) => {
                                const templateData = allTemplates?.find(
                                    (template) => template.deductionTemplateID === option?.value
                                );
                                setEmployees(
                                    templateData?.employees?.map((employee) => ({
                                        label: `${employee.first_name} ${employee.last_name}`,
                                        value: employee.employeeID,
                                    })) || []
                                );
                                setFormData(
                                    templateData
                                        ? {
                                              properties: {
                                                  custom_name: { default: templateData.custom_name?.const },
                                                  employee_contribution: templateData.employee_contribution
                                                      ? {
                                                            type: 'object',
                                                            properties: {
                                                                contribution_type: {
                                                                    enum: [
                                                                        templateData.employee_contribution?.properties
                                                                            ?.contribution_type?.const,
                                                                    ],
                                                                },
                                                                value: templateData.employee_contribution?.properties
                                                                    ?.value,
                                                            },
                                                        }
                                                      : null,
                                                  employer_contribution: templateData.employer_contribution
                                                      ? {
                                                            type: 'object',
                                                            properties: {
                                                                contribution_type: {
                                                                    enum: [
                                                                        templateData.employer_contribution?.properties
                                                                            ?.contribution_type?.const,
                                                                    ],
                                                                },
                                                                value: templateData.employer_contribution?.properties
                                                                    ?.value,
                                                            },
                                                        }
                                                      : null,
                                                  additional_fields: templateData.additional_fields
                                                      ? {
                                                            type: 'object',
                                                            properties: {
                                                                ...templateData.additional_fields?.properties,
                                                                hsa_type: templateData.additional_fields?.properties
                                                                    ?.hsa_type
                                                                    ? {
                                                                          enum: [
                                                                              templateData.additional_fields?.properties
                                                                                  ?.hsa_type?.const,
                                                                          ],
                                                                      }
                                                                    : null,
                                                            },
                                                        }
                                                      : null,
                                              },
                                              type: templateData?.deduction_type?.const,
                                              readOnly: true,
                                              deductionTemplateID: templateData.deductionTemplateID,
                                          }
                                        : {}
                                );
                            }}
                            disabled={isBusy}
                            isClearable={true}
                            options={allTemplates?.map((data) => ({
                                label: data.custom_name?.const || data.custom_name,
                                value: data.deductionTemplateID,
                            }))}
                            clearable
                            isDisabled={isBusy}
                        />
                    </Form.Group>
                    <Form.Group
                        css={css`
                            width: 40%;
                        `}
                    >
                        <Form.Label>Select Deduction Type</Form.Label>
                        <Form.Control
                            as="select"
                            onChange={handleDeductionTypeChange}
                            disabled={isBusy || formData?.readOnly}
                        >
                            <option value="">-- Select --</option>
                            {Object.entries(deductionTypeOptions)?.map(([objKey, objValue]) => (
                                <option
                                    key={objKey}
                                    value={objKey}
                                    selected={objKey === formData?.type || objKey === deductionType}
                                >
                                    {objValue}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </div>
                <Form.Group>
                    <Form.Label>Select Employees</Form.Label>
                    <ReactSelect
                        options={validEmployees?.map((employee) => ({
                            label: `${employee.first_name} ${employee.last_name}`,
                            value: employee.employeeID,
                        }))}
                        isMulti
                        value={employees}
                        onChange={setEmployees}
                        isDisabled={isBusy}
                    />
                </Form.Group>
            </div>
            {
                <Fragment>
                    {isBusy ? (
                        <div
                            className="loader"
                            key={0}
                            css={css`
                                height: 100%;
                                min-height: 100px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            `}
                        >
                            <VscLoading fontSize={34} className="spin" />
                        </div>
                    ) : null}

                    <Form
                        onSubmit={handleSubmit(onSubmit)}
                        css={css`
                            display: ${isBusy ? 'none' : 'block'};
                        `}
                    >
                        {formData?.type ? (
                            <RenderFormFields schema={formData} register={register} setValue={setValue} />
                        ) : null}
                        <div
                            css={css`
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                position: sticky;
                                bottom: -12px;
                                background: white;
                                padding: 12px 0;
                            `}
                        >
                            <hr
                                css={css`
                                    width: 100%;
                                `}
                            />
                            {formData?.properties ? (
                                <Button
                                    type="submit"
                                    disabled={isBusy}
                                    css={css`
                                        width: 60%;
                                    `}
                                >
                                    {isBusy ? <VscLoading fontSize={34} className="spin" /> : null}
                                    {formData?.readOnly ? 'Assign Employees to Template' : 'Create Deduction Template'}
                                </Button>
                            ) : (
                                <span>Please select Deduction Template or Deduction Type!</span>
                            )}
                        </div>
                    </Form>
                </Fragment>
            }
        </div>
    );
}

export default DeductionTemplates;
