import React, { useEffect } from 'react';
import { useState } from 'react';
import { FormLabel, InputGroup } from 'react-bootstrap';
import LabelDetail from '../Visit/LabelDetail';
import Select from 'react-select';
import { useMemo } from 'react';

function VisitLabelFilter({ labels, visitLabelFilter, handleVisitLabelFilterChange }) {
	const [labelsOptions, setLabelsOptions] = useState([]);
	console.log(visitLabelFilter, 'visitLabelFilter');

	useEffect(() => {
		if (labels) {
			let tempLabelsOptions = [];
			Object.entries(labels).forEach(([label, color]) => {
				tempLabelsOptions.push({
					label: <LabelDetail label={label} color={color} />,
					value: label,
				});
			});
			setLabelsOptions(tempLabelsOptions);
		}
	}, [labels]);
	const handleVisitLabelChange = (selectedData) => {
		handleVisitLabelFilterChange(selectedData.value);
	};

	const VisitLabelValue = useMemo(() => {
		if (labelsOptions.length > 0) {
			return labelsOptions.find((label) => label.value === visitLabelFilter);
		}
	}, [visitLabelFilter, labelsOptions]);
	return (
		<InputGroup>
			<FormLabel htmlFor={'verified'} className=" mr-2">
				Label filter:
			</FormLabel>
			<div className="w-100">
				<Select options={labelsOptions} value={VisitLabelValue} onChange={handleVisitLabelChange} />
			</div>
		</InputGroup>
	);
}

export default VisitLabelFilter;
