import { css } from 'twin.macro';
import React from 'react';
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';
import { StyledLinkBtn } from './EventModalStyles';

const FormsTableViewEvent = ({
    selectedForm,
    selectedUsers,
    subTasks,
}) => {
    const filteredSubTasks = subTasks.filter(({ assignedUser }) => selectedUsers.some(user => user._id === assignedUser));
    const form = selectedForm;
    return (
        <table css={
            css`
                    border:2px solid #dee2e6;
                    width: 100%;
                    td, th { 
                        border: 2px solid #dee2e6;
                        padding: 4px 8px;
                    }
                `}>
            <thead>
                <tr>
                    <th>User</th>
                    <th css={css`width:20px;`}>Submitted</th>
                </tr>
            </thead>

            <tbody>
                <React.Fragment key={form._id}>
                    {filteredSubTasks && filteredSubTasks?.map((userTask, index) => {
                        let userFrom = userTask.forms.find((userform) => userform.form === form.value);
                        let user = selectedUsers.find((user) => user._id === userTask.assignedUser);

                        return (
                            <tr key={index}>
                                <td>{user?.name}</td>
                                <td css={css``}>
                                    <StyledLinkBtn css={css`pointer-events: none; display: flex; justify-content: center; align-items: center;`}>
                                        {
                                            userFrom?.submitted ? <ImCheckboxChecked /> : <ImCheckboxUnchecked />
                                        }
                                    </StyledLinkBtn>
                                </td>
                            </tr>
                        );
                    })}
                </React.Fragment>
            </tbody>

        </table>
    );
};
export default FormsTableViewEvent;