import React, { Fragment, useState, useEffect } from 'react';
import ImageModal from '../../../Features/Teams/ImageModal';
import { Form } from 'react-bootstrap';
import Linkify from 'react-linkify';
import { css } from 'twin.macro';

import Signature from './Signature';
import { marginColor } from './Checkbox';

const SignatureFile = ({ data, elementRef, isPreview, error }) => {
	const [inputMethod, setInputMethod] = useState();

	useEffect(() => {
		setInputMethod('DRAW');
	}, []);
	return (
		<Fragment>
			<Form.Label>
				<h5 className="warpIt">
					{data.fieldName}
					<span css={marginColor}>{(data.hasOwnProperty('required') ? data.required : true) && '*'}</span>
				</h5>
			</Form.Label>

			{data.instruction && (
				<pre className="small text-muted warpIt preStyles">
					<Linkify
						componentDecorator={(decoratedHref, decoratedText, key) => (
							<a target="blank" href={decoratedHref} key={key}>
								{decoratedText}
							</a>
						)}
					>
						{data.instruction}
					</Linkify>
				</pre>
			)}
			{data.value ? (
				<div
					css={css`
						width: 200px;
						height: 200px;
						div {
							width: 100%;
							height: 100%;
						}
					`}
				>
					<ImageModal file={data.value} noOptimization={true} />
				</div>
			) : (
				<Form.Group controlId="formGridFile">
					{inputMethod === 'DRAW' && (
						<Signature
							data={data}
							elementRef={elementRef}
							name={!isPreview ? `Signature-${data._id}` : 'Signature'}
							isPreview={isPreview}
						/>
					)}

					<Form.Text className="text-danger">
						{error && error.type === 'required' && 'This field is required'}
					</Form.Text>
				</Form.Group>
			)}
		</Fragment>
	);
};

export default SignatureFile;
