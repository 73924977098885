import React, { useState } from 'react';
import { css } from '@emotion/react';
import { BsFillUnlockFill } from 'react-icons/bs';
import { FaGlobeAmericas } from 'react-icons/fa';
import AvatarUserPanel from '../../Common/AvatarUserPanel';
import { AiOutlineDownCircle, AiOutlineUpCircle } from 'react-icons/ai';

const styles = {
  card: css`
    border-radius: 5px;
    padding: 10px 10px 0px 10px;
    width:100%;
  `,
  header: css`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  `,
  title: css`
    font-size: 1.6rem;
    font-weight: 600;
    margin-left: 10px;
  `,
  row: css`
    font-size: 1.4rem;
    display: flex;
    flex-direction: row;

  `,
  rowWithShowMore: css`
    font-size: 1.4rem;
    display: flex;
    flex-direction: row;
  `,
  label: css`
    display: inline-block;
  `,
  valueContainer: css`
  align-items: center;
  `,
  icon: css`
  margin-left: 5px;
  `,
  value: css`
  word-break: break-all;
  `,
  showMore: css`
  border:1px solid #50aeb0;
  border-radius: 5px;
  padding:3px 6px;
  display:inline-block;
  cursor:pointer;
  font-size: 1.4rem;
  color: #50aeb0;
  `,
  showMoreContainer: css`
  display:flex;
  justify-content:flex-end;
  `,
};

const InfoCard = ({ data }) => {
  const maxWidth = 100;
  const [expand, setExpand] = useState(false);
  const toggleExpand = () => {
    setExpand(prev => !prev);
  }

  return (
    <div css={styles.card}>
      <div css={styles.header}>
        {data?.avatar ? <AvatarUserPanel large={false} item={data.avatar} /> : null}
        {data?.cardTitle ? <div css={styles.title}>
          # {data.cardTitle}
          {data?.channelType === 'private' && <BsFillUnlockFill size="10" css={styles.icon} />}
          {data?.channelType === 'public' && <FaGlobeAmericas size="10" css={styles.icon} />}
        </div> : null}
      </div>
      {/* {(data?.elements?.slice(0, expand ? data?.elements?.length : 3) || [])?.map((item, index) => ( */}
      {(data?.elements || [])?.map((item, index) => (
        <div key={index} css={styles.row} >
          <strong css={styles.label} style={{ minWidth: maxWidth }}>{item.label}:</strong>
          <div css={styles.valueContainer}>
            <span css={styles.value}>{item?.value}</span>
          </div>
        </div>
      ))}
      {/* <div css={styles.showMoreContainer}>
        {
          expand ?
            <span css={styles.showMore} onClick={toggleExpand}>
               <AiOutlineUpCircle />
            </span>
            :
            <span css={styles.showMore} onClick={toggleExpand}>
             <AiOutlineDownCircle />
            </span>
        }
      </div> */}
    </div>
  );
};

export const ChannelInfoCard = ({ channel, team }) => {
  const channelData = {
    avatar: team[0],
    cardTitle: channel?.title,
    channelType: channel?.type,
    elements: [
      { label: 'Channel Name', value: channel?.title, icon: channel?.type },
      { label: 'Created at', value: new Date(channel?.createdAt).toDateString() },
      { label: 'Team Name', value: team[0]?.name },
      // { label: 'Channel Type', value: channel?.type?.toUpperCase() },
      // { label: 'Last updated', value: new Date(channel?.updatedAt).toDateString() },
      // { label: 'Description', value: channel?.subTitle }
    ]
  };
  return <InfoCard data={channelData} />
}