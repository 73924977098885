import { css } from 'twin.macro';
import { BiArrowBack } from 'react-icons/bi';
import { AiOutlineCloseCircle } from 'react-icons/ai';

export const navIcon = css`
	height: 30px;
	width: 30px;
	padding: 5px;
	border-radius: 5px;
	background-color: #c3e4e2;
	margin-right: 10px;
	color: #50aeb0;
	&:hover {
		border: 1px solid #50aeb0;
		box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
	}
`;

const NavigationBar = ({ mainTabName, goToTeamPage = null, ...subTabs }) => {
	let NavigationButton;
    // goToTeamPage=null;
	if (Boolean(goToTeamPage)) {
		NavigationButton = <BiArrowBack size={34} className="pointer" css={css`color: #50aeb0; margin-right: 1rem;`} onClick={goToTeamPage} />;
	} else {
		NavigationButton = (
			<BiArrowBack css={navIcon} size={24} className="pointer" onClick={() => window.history.back()} />
		);
	}

	return (
		<div className="d-flex flex-row ">
			<div className="mb-3 mt-1 d-flex flex-row justify-content-start align-items-center">
				{NavigationButton}
				<h4 className="pointer pt-1 text-dark-grey">
					<strong>{mainTabName}</strong>
				</h4>
			</div>
			{Object.entries(subTabs).map((tabname, i) => (
				<div key={i} className="pt-3 ml-2 text-dark-grey" style={{ fontSize: '16px' }}>
					/ {tabname[1]}
				</div>
			))}
		</div>
	);
};

export default NavigationBar;
