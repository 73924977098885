import { css } from 'twin.macro';
import { Fragment } from 'react';
import { Form } from 'react-bootstrap';
import Linkify from 'react-linkify';
import { toast } from 'react-toastify';

import { marginColor } from './Checkbox';
const AudioCss = css`
	text-align: center;
`;
const AudioFile = ({ data, elementRef, isPreview, error }) => {
	const handleFile = (e) => {
		const maxFileSize = 50 * 1024 * 1024;
		const audioFile = e.target.files[0];
		console.log(audioFile);
		if (!(audioFile && audioFile.size < maxFileSize)) {
			toast.warn('Image Must be less than 50 mb in size!');
			e.target.value = null;
		}
	};

	return (
		<Fragment>
			{data.value ? (
				<div css={AudioCss}>
					<div
						css={css`
							text-align: left;
						`}
					>
						<h5 className="warpIt">{data.fieldName}</h5>
						{data.instruction && (
							<pre className="small text-muted warpIt preStyles">
								<Linkify
									componentDecorator={(decoratedHref, decoratedText, key) => (
										<a target="blank" href={decoratedHref} key={key}>
											{decoratedText}
										</a>
									)}
								>
									{data.instruction}
								</Linkify>
							</pre>
						)}
					</div>
					<audio id="myAudio" controls>
						<source src={data.value} />
					</audio>
				</div>
			) : (
				<Form.Group controlId="formGridFile">
					<label name={`${!isPreview ? `File-${data._id}` : 'Email'}`}>
						<h5 className="warpIt">
							{data.fieldName}
							<span css={marginColor}>
								{(data.hasOwnProperty('required') ? data.required : true) && '*'}
							</span>
						</h5>

						{data.instruction && <span className="small text-muted warpIt">{data.instruction}</span>}
					</label>
					<br />
					<input
						accept="audio/*"
						type="file"
						name={`${!isPreview ? `AudioFile-${data._id}` : 'AudioFile'}`}
						disabled={isPreview || data.value}
						onChange={handleFile}
						ref={
							elementRef &&
							elementRef({
								required: data.hasOwnProperty('required') ? data.required : true,
							})
						}
					/>
					<Form.Text className="text-danger">
						{error && error.type === 'required' && 'This field is required'}
					</Form.Text>
				</Form.Group>
			)}
		</Fragment>
	);
};

export default AudioFile;
