import { css } from 'twin.macro';

export const layout = css`
	overflow: hidden;
	.wrapper {
		height: 100vh;
		background-color: #f5f5f5;
		display: flex;
	}

	.chatList {
		grid-area: chatList;
		position: relative;
		width: 440px;
		transition: width 200ms;
		&.hide-chatList {
			width: 82px;
		}
	}

	.chat {
		grid-area: chat;
		width: calc(100% - 440px);
		transition: width 200ms;
		&.hide-chatList {
			width: calc(100% - 82px);
		}
	}

	.userDetails {
		grid-area: userDetails;
		background-color: teal;
	}

	.details-ani {
		position: fixed;
		left: 100%;
		top: 0px;
		height: 100vh;
		z-index: 50;
		overflow-y: scroll;
		background-color: #f2f8f7;

		&::-webkit-scrollbar {
			width: 10px;
			cursor: pointer;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #c8e7e8;
			border-radius: 5px;
		}
	}

	.details-ani--enter {
		transform: translateX(0%);
	}
	.details-ani--enter-active {
		transform: translateX(-100%);
		transition: transform 0.5s ease-in-out;
	}
	.details-ani--enter-done {
		transform: translateX(-100%);
		transition: transform 0.5s ease-in-out;
	}

	.details-ani--exit {
		transform: translateX(-100%);
	}
	.details-ani--exit-active {
		transform: translateX(0%);
		transition: transform 0.5s ease-in-out;
	}
	.details-ani--exit-done {
		transform: translateX(0%);
		transition: transform 0.5s ease-in-out;
	}

	@media (max-width: 768px) {
		.wrapper-chat {
			width: 100%;
			height: 100vh;
			display: grid;
			grid-template-areas: 'chat';
			grid-template-columns: 3fr;
			.chatList {
				display: none;
			}
			.chat {
				width: 100%;
			}
		}

		.wrapper-list {
			width: 100%;
			height: 100vh;
			display: grid;
			grid-template-areas: 'chatList';
			grid-template-columns: 3fr;
			.chat {
				display: none;
			}
		}

		.sidebar-ani {
			box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
		}
		.sidebar-ani--enter {
			transform: translateY(80px);
		}
		.sidebar-ani--enter-active {
			transform: translateY(0px);
			transition: transform 0.5s ease-in-out;
		}
		.sidebar-ani--enter-done {
		}

		.sidebar-ani--exit {
		}
		.sidebar-ani--exit-active {
			transform: translateY(80px);
			transition: transform 0.5s ease-in-out;
		}
		.sidebar-ani--exit-done {
		}
	}
`;
