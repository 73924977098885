import { css } from 'twin.macro';

export const profileContainer = css`
	.prof-icon {
		display: none;
	}
`;

export const popup = css`
	padding: 2rem;
	width: 60rem;
	min-height: 45rem;
	word-break: break-word;
	background-color: #fff;
	border-radius: 3px;

	.replacementImg {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 10rem;
		width: 10rem;
		border-radius: 5rem;
		color: white;
		font-size: 60px;
		font-weight: lighter;
		margin-bottom: 10px;
	}

	label {
		font-size: 1.3rem;
	}
	input,
	input:focus,
	textarea {
		border-top-style: hidden;
		border-right-style: hidden;
		border-left-style: hidden;
		border-bottom-style: groove;

		font-size: 1.6rem;
		outline: none !important;
		border: 1px 0px 0px 0px solid black;
	}
	p {
		font-size: 1.6rem;
	}
	.btns-control {
		display: flex;
		justify-content: space-evenly;
	}
	@media only screen and (max-width: 767px) {
		.mobile-profile {
			margin-bottom: 10rem;
		}
	}
`;
export const editProfileBtn = css`
	border: 1px solid #50aeb0;
	color: #50aeb0;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1rem 1.5rem;
	cursor: pointer;
	i {
		font-size: 2.4rem;
		margin-right: 1rem;
	}
	transition: background-color 200ms;
	:hover {
		background-color: #50aeb0;
		color: #fff;
	}
`;

export const bigButtons = css`
	margin: 1rem;
	font-size: 1.6rem;
	border-radius: 16px;
	min-width: 8rem;
	color: white;
	letter-spacing: normal;
	font-weight: 300;
	&.btn-outline-primary {
		color: #50aeb0;
		padding: 8px 20px;
		font-weight: 400;
	}
	&:hover {
		color: #fff;
	}
`;

export const editBtn = css`
	img {
		-webkit-filter: grayscale(0) blur(0);
		filter: grayscale(0) blur(0);
		-webkit-transition: 0.3s ease-in-out;
		transition: 0.3s ease-in-out;
	}
	.edit:hover > i {
		opacity: 1 !important;
	}
	.edit:hover > img {
		-webkit-filter: grayscale(100%) blur(3px);
		filter: grayscale(100%) blur(3px);
	}
`;

export const profileLabel = css`
	word-break: normal;
`;
export const eye = css`
	color: grey;
	z-index: 99999;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 20px;
	width: 20px;
	right: 10px;
	bottom: 10px;
	cursor: pointer;
`;

export const editProfileFormCSS = css`
	.checkbox-div {
		display: flex;
		justify-content: start;
		align-items: center;
		padding: 0 2em;
	}
	.checkbox101 {
		width: 1.5rem;
		height: 1.5rem;
		cursor: pointer;
	}
`;
export const avatarContainer = css`
	display: flex;
	justify-content: center;
	align-items: center;

	.avatar {
		width: 3rem;
		height: 3rem;
		border-radius: 2rem;
	}
	.replacementImg {
		border: none;
		display: flex;
		place-items: center;
		place-content: center;
		position: relative;
		color: white;
		font-weight: lighter;
	}
`;
