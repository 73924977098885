import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { convertDateToISO, fetchWrapper } from '../../../../../_helpers';
import { css } from 'twin.macro';
import DateTimePicker from 'react-datetime-picker';
import { ActionStyles } from '../../../reusableStyles';
import dayjs from 'dayjs';
import { VscLoading } from 'react-icons/vsc';

import { Button } from 'react-bootstrap';
import { ToolTip } from '../../../../../_elements/ToolTip';
import FileSaver from 'file-saver';
import EmployeeCheckModal from './EmployeeCheckModal';
import EmployeeCheckRow from './EmployeeCheckRow';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { BsExclamationTriangle } from 'react-icons/bs';

function ZealCompanyPayroll({ provider, teamId, workLocations }) {
    const [employerChecks, setEmployerChecks] = useState([]);
    const [isBusy, setIsBusy] = useState(false);

    const [showEmployeeCheck, setShowEmployeeCheck] = useState({});
    const [paystubReport, setPaystubReport] = useState('');

    const [dateFilters, setDateFilters] = useState({
        start: new Date(Date.now() - 30 * 86400 * 1000),
        end: new Date(),
    });
    const companyID = provider?.metadata?.companyID;

    async function fetchEmployerChecks(companyID, startDate, endDate) {
        try {
            setIsBusy(true);
            const queryString = `${startDate ? `?startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}${teamId ? `&team=${teamId}` : ''}`;
            const response = await fetchWrapper.get(`/evv/payroll/zeal/provider/${companyID}/checks${queryString}`);
            setEmployerChecks(response);
        } catch (error) {
            if (error.name === 'AbortError') return;
            console.log('error', error);
            toast.error("Couldn't fetch employer checks");
        } finally {
            setIsBusy(false);
        }
    }

    async function fetchPayStubReport() {
        try {
            setIsBusy(true);
            if (paystubReport) {
                return FileSaver.saveAs(
                    paystubReport,
                    `paystub-${companyID}-${dateFilters.start.toISOString().slice(0, 10)}-${dateFilters.end.toISOString().slice(0, 10)}.pdf`
                );
            }
            const queryString = `${dateFilters.start ? `?startDate=${dateFilters.start.toISOString()}` : ''}${dateFilters.end ? `&endDate=${dateFilters.end.toISOString()}` : ''}${teamId ? `&team=${teamId}` : ''}`;
            const response = await fetchWrapper.get(
                `/evv/payroll/zeal/provider/${companyID}/reports/paystubs${queryString}`
            );
            setPaystubReport(response.url);
            toast.success('Please wait for a few seconds before attempting to downlaod report');
        } catch (error) {
            if (error.name === 'AbortError') return;
            toast.error("Couldn't download paystub report");
        } finally {
            setIsBusy(false);
        }
    }

    useEffect(() => {
        if (companyID && dateFilters.start && dateFilters.end) {
            fetchEmployerChecks(
                companyID,
                convertDateToISO(dateFilters?.start, { start: true }),
                convertDateToISO(dateFilters?.end, { end: true })
            );
        }
        setPaystubReport('');
    }, [dateFilters, companyID]);

    return (
        <div
            className="w-100 d-flex flex-column align-items-center"
            css={css`
                overflow-x: hidden;
            `}
        >
            <div
                className="d-flex flex-row justify-content-between mb-4 p-3"
                css={css`
                    gap: 12px;
                    width: 100%;
                    border: 1px solid #ddd;
                `}
            >
                <div>
                    <div
                        css={css`
                            font-size: larger;
                        `}
                    >
                        {provider?.data?.basic?.business_name}
                    </div>
                    <div
                        css={css`
                            font-size: small;
                        `}
                    >
                        {provider?.data?.basic?.business_address} {provider?.data?.basic?.business_city}{' '}
                        {provider?.data?.basic?.business_state}
                    </div>
                    <div>Employer Checks</div>
                </div>
                <div
                    className="d-flex flex-row align-items-end"
                    css={css`
                        gap: 12px;
                    `}
                >
                    <div>
                        <span>Start Date:</span>
                        <br />
                        <DateTimePicker
                            onChange={(start) => {
                                setDateFilters((prev) => ({ ...prev, start }));
                            }}
                            value={dateFilters.start}
                            format="MM-dd-y"
                            disableClock={true}
                            calendarType={'US'}
                            css={css`
                                background: white;
                                height: 36px;
                            `}
                        />
                    </div>
                    <div>
                        <span>End Date:</span>
                        <br />
                        <DateTimePicker
                            onChange={(end) => {
                                setDateFilters((prev) => ({ ...prev, end }));
                            }}
                            value={dateFilters.end}
                            format="MM-dd-y"
                            disableClock={true}
                            calendarType={'US'}
                            css={css`
                                background: white;
                                height: 36px;
                            `}
                        />
                    </div>
                    {/* <Button disabled={isBusy || !dateFilters.start || !dateFilters.end} onClick={fetchPayStubReport}>
                        {paystubReport ? 'Download' : 'Get'} Paystub Report
                    </Button> */}
                </div>
            </div>
            {isBusy ? (
                <VscLoading
                    className="spin"
                    size="24px"
                    css={css`
                        overflow: hidden;
                    `}
                />
            ) : employerChecks?.length ? (
                <>
                    {employerChecks
                        .sort((c1, c2) => new Date(c2?.createdAt || 0) - new Date(c1?.createdAt || 0))
                        .map((employerCheck, index) => (
                            <EmployerCheckView
                                employerCheck={employerCheck}
                                key={index}
                                companyID={companyID}
                                setShowEmployeeCheck={setShowEmployeeCheck}
                                teamId={teamId}
                            />
                        ))}
                </>
            ) : (
                <div>No Checks Found</div>
            )}
            <EmployeeCheckModal
                show={!!showEmployeeCheck?.employeeCheckID}
                handleClose={() => {
                    setShowEmployeeCheck({});
                }}
                companyID={companyID}
                employeeCheck={showEmployeeCheck}
                teamId={teamId}
                workLocations={workLocations}
            />
        </div>
    );
}

function EmployerCheckView({ employerCheck, companyID, setShowEmployeeCheck, teamId }) {
    const data = employerCheck || {};
    const [expanded, setExpanded] = useState(false);

    return (
        <div
            className="d-flex flex-column align-items-center justify-content-between mb-4 p-3"
            css={css`
                gap: 12px;
                width: 99%;
                border: 1px solid #ddd;
                box-shadow: ${expanded ? 'rgba(0, 0, 0, 0.24) 0px 3px 8px' : ''};
            `}
        >
            <div
                className="d-flex flex-row align-items-center justify-content-center"
                css={css`
                    gap: 12px;
                    width: 100%;
                `}
            >
                <div
                    css={css`
                        display: flex;
                        flex-direction: column;
                        gap: 4px;
                    `}
                >
                    <div>{dayjs(employerCheck?.createdAt).format('MM/DD/YYYY HH:mm A')}</div>
                    <div
                        css={css`
                            display: flex;
                            gap: 4px;
                            align-items: center;
                        `}
                    >
                        <span
                            css={css`
                                font-size: small;
                            `}
                        >
                            {employerCheck?.employerCheckID}
                        </span>
                        <span className="badge badge-sm badge-primary">{data.status}</span>
                    </div>
                </div>

                <div
                    css={css`
                        display: flex;
                        gap: 24px;
                        flex-grow: 1;
                        margin: 0 48px;
                    `}
                >
                    {['gross_pay', 'net_pay', 'employee_taxes', 'employer_taxes', 'company_debit'].map((key) => (
                        <React.Fragment key={key}>
                            <PayHighlight amount={(data.totals || {})[key]?.toFixed(2) || '00.00'} label={key} />
                        </React.Fragment>
                    ))}
                </div>
                <ToolTip
                    text={`${expanded ? 'Hide' : 'Show'} Pay Details`}
                    side="right"
                    cssStyles={css`
                        right: 42px !important;
                        top: 0 !important;
                    `}
                >
                    <ActionStyles onClick={() => setExpanded((prev) => !prev)}>
                        {expanded ? <IoIosArrowUp className="icon" /> : <IoIosArrowDown className="icon" />}
                    </ActionStyles>
                </ToolTip>
            </div>
            {expanded &&
                (data?.employerCheckID ? (
                    <div
                        css={css`
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            align-items: flex-start;
                            gap: 12px;
                        `}
                    >
                        <div
                            css={css`
                                border: 1px solid #ddd;
                                padding: 6px 12px;
                            `}
                        >
                            <div>Summary</div>
                            <hr />
                            {Object.entries(data?.totals || {}).map(([key, value], index) => (
                                <div
                                    key={key}
                                    index={index}
                                    css={css`
                                        text-transform: capitalize;
                                    `}
                                >
                                    {key.replace(/([a-z])_([a-z])/g, '$1 $2')}: ${value?.toFixed(2)}
                                </div>
                            ))}
                        </div>
                        <div
                            css={css`
                                flex-grow: 1;
                                display: flex;
                                flex-direction: column;
                                gap: 12px;
                                border: 1px solid #ddd;
                                padding: 6px;
                            `}
                        >
                            <div>Employee Checks</div>
                            {data.employee_checks?.map((employeeCheck, index) => (
                                <EmployeeCheckRow
                                    key={index}
                                    employeeCheck={employeeCheck}
                                    setShowEmployeeCheck={setShowEmployeeCheck}
                                    teamId={teamId}
                                    fromPayroll={true}
                                />
                            ))}
                        </div>
                    </div>
                ) : (
                    <span>Nothing to show</span>
                ))}
        </div>
    );
}

export function PayHighlight({ amount, label, dollars = true, modified = false }) {
    return (
        <div>
            <div
                css={css`
                    font-size: larger;
                `}
            >
                {dollars ? '$' : ''}
                {!modified ? amount : ' -- '}
            </div>
            <div
                css={css`
                    font-size: small;
                    text-transform: capitalize;
                `}
            >
                {label.replaceAll('_', ' ')}
            </div>
        </div>
    );
}

export function CheckError({ error, isModified }) {
    if (isModified) {
        return '-------------------';
    }
    if (!error) {
        return null;
    }
    return (
        <div
            css={css`
                margin-top: 8px;
                margin-left: 8px;
                display: flex;
                flex-direction: row;
                align-items: center;
            `}
        >
            <div>
                <BsExclamationTriangle size={24} color="#ea9941" />
            </div>
            <div
                css={css`
                    margin-left: 8px;
                    color: #e10000;
                `}
            >
                {error}
            </div>
        </div>
    );
}

export default ZealCompanyPayroll;
