import React, { useEffect } from 'react';
import { useState } from 'react';

//redux
import { connect } from 'react-redux';
import { editTheTask } from '../../../_redux/actions/ActionTasks';

//components
import ChannelSelectFilter from '../ChannelSelectFilter';
import { prepareEvent } from '../../../_helpers';

const EventAssignedChannel = ({
	editTheTask,
	event = {},
	disabled,
	teamIds,
	onAssignChanneltoEventCallback,
}) => {
	const [selectedRoomOption, setSelectedRoomOption] = useState(null);

	useEffect(() => {
		const assignedChannel = Array.isArray(event.assignedChannel)
			? event.assignedChannel[0]
			: event.assignedChannel?.value || event.assignedChannel;
		setSelectedRoomOption(assignedChannel);
	}, [event]);


	const editAssignedChannel = true;

	const handleSelectedChannelData = async (assignedChannelInfo) => {
		if (!assignedChannelInfo?._id) {
			return null;
		}
		const updatedEvent = prepareEvent({
			...event,
			assignedChannel: [assignedChannelInfo._id]
		});
		if (onAssignChanneltoEventCallback) {
			return onAssignChanneltoEventCallback(event._id, 
				{
				...updatedEvent, 
				assignedChannelInfo: [assignedChannelInfo],
			} );
		}
		['eventPackage'].forEach((key) => {
			delete updatedEvent[key];
		});
		return editTheTask(event._id, updatedEvent);
	};

	const onRoomSelect = async (selectedRoom) => {

		setSelectedRoomOption(selectedRoom);
		return handleSelectedChannelData(selectedRoom)
	};

	return (
		<>
			<ChannelSelectFilter
				onRoomSelect={onRoomSelect}
				assignedChannel={selectedRoomOption}
				editAssignedChannel={editAssignedChannel}
				disabled={disabled}
				teamIds={teamIds}
			/>
		</>
	);
};

const mapStateToProps = (state) => ({
	rooms: state.rooms.teamRooms,
	CurrUser: state.user.user,
});

export default connect(mapStateToProps, { editTheTask })(EventAssignedChannel);
