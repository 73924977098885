import { useState, useEffect, useCallback } from 'react';

export const useSortData = (dataFetchingFuntion, config = null) => {
	const [sortConfig, setSortConfig] = useState(config);

	const sortedItems = useCallback(
		(sortParams) => {
			dataFetchingFuntion({
				...sortParams,
				sortBy:
					sortConfig && sortConfig.key && sortConfig.direction
						? `${sortConfig.key}:${sortConfig.direction}`
						: null,
			});
		},
		[dataFetchingFuntion, sortConfig]
	);

	useEffect(() => {
		if (sortConfig && sortConfig.shouldFetch) {
			const tempConfig = { ...sortConfig };
			delete tempConfig.shouldFetch;
			delete tempConfig.key;
			delete tempConfig.direction;
			sortedItems({ ...tempConfig });
		}
	}, [sortConfig, sortedItems]);

	const requestSort = ({ searchText = '', key, shouldFetch = true, ...rest }) => {
		let direction = 'asc';
		if (sortConfig && sortConfig.direction === 'asc' && sortConfig.key === key) {
			direction = 'desc';
		}
		setSortConfig({
			...rest,
			searchText,
			key,
			shouldFetch,
			direction,
		});
	};

	return { sortedItems, requestSort, sortConfig };
};
