// import React from 'react';

import CreateForm from '../../../Admin/Form/CreateForm';
import EditForm from '../../../Admin/Form/EditForm';
import CopyForm from '../../../Admin/Form/CopyForm';
import Members from './Members';
import TeamSettingFormPackage from './TeamSettingFormPackage';
import TeamSettingsForm from './TeamSettingsForm';
import Documents from './Documents';
import UserEVVContainer from '../../../Admin/EVVContainer/UserEVVContainer';
import UserPayrollContainer from '../../../Admin/EVVContainer/UserPayrollContainer';
import TeamChannels from './TeamChannels';
import TeamEvents from './events/TeamEvents';
import ActivityActionPackage from '../../../Admin/activity/ActivityActionPackage';
import ActionActivityPackageTemplate from '../../../Admin/activity/ActionActivityPackageTemplate';
import ActionActivityPackageTemplateBuild from '../../../Admin/activity/ActionActivityPackageTemplateBuild';
import TeamCourses from './TeamCourses';
const settingsRoutes = [
    { path: '/courses', exact: true, name: 'Courses', component: TeamCourses },
    { path: '/form/', exact: true, name: 'Form', component: TeamSettingsForm },
    { path: '/channel/', exact: true, name: 'Channel', component: TeamChannels },
    { path: '/events/', exact: true, name: 'Events', component: TeamEvents },
    { path: '/form-template/', exact: true, name: 'Visit Template', component: TeamSettingFormPackage },
    { path: '/form-library/', exact: true, name: 'Form Library', component: TeamSettingFormPackage },
    { path: '/form-library/edit/:id', exact: true, name: 'Edit Form', component: EditForm },
    { path: '/form-library/copy/:id', exact: true, name: 'Copy Form', component: CopyForm },
    { path: '/form-builder/', exact: true, name: 'Form Builder', component: CreateForm },
    { path: '/form-builder/:id', exact: true, name: 'Form Builder', component: CreateForm },
    { path: '/documents', exact: true, name: 'Documents', component: Documents },
    { path: '/members', exact: true, name: 'Members', component: Members },
    { path: '/user-evv', exact: true, name: 'UserEVVContainer', component: UserEVVContainer },
    { path: '/user-payroll', exact: true, name: 'UserPayrollContainer', component: UserPayrollContainer },
    { path: '/', exact: true, name: 'Members', component: Members },
    { path: '/event-planner-builder', exact: true, name: 'Activity Builder', component: ActivityActionPackage },
    {
        path: '/event-planner/:mode/:id',
        exact: true,
        name: 'Edit Plan',
        component: ActivityActionPackage,
    },
    {
        path: '/event-planner-template/:id',
        exact: true,
        name: 'Activity Plan Template Detail',
        component: ActionActivityPackageTemplate,
    },
    {
        path: '/event-planner-template-builder',
        exact: true,
        name: 'Activity Plan Template Builder',
        component: ActionActivityPackageTemplate,
    },
    {
        path: '/event-planner-template/build/:id',
        exact: true,
        name: 'Build Plan from Template',
        component: ActionActivityPackageTemplateBuild,
    },
    {
        path: '/event-planner-template/:mode/:id',
        exact: true,
        name: 'Edit Plan Template',
        component: ActionActivityPackageTemplate,
    },
];

export default settingsRoutes;
