import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';

import ScrollToBottom from 'react-scroll-to-bottom';
import InfiniteScroll from 'react-infinite-scroller';

import '../Common/Messages.css';
import Message from './Message';

//redux
import { connect } from 'react-redux';
import { getDirectMessageHistory, postLastSeenDirectMessage } from '../../../_redux/actions/ActionMessage';
import NewMessageButton from '../Teams/NewMessageButton';
import { getTheDate } from '../../../_helpers';

function Messages({
	messages,
	user,
	userName,
	userId,
	totalMessages,
	getDirectMessageHistory,
	setReply,
	isUploading,
	postLastSeenDirectMessage,
	lastSeen,
}) {
	useEffect(() => {
		var objDiv = document.querySelector('.m-c');
		objDiv.scrollTop = objDiv.scrollHeight;
	}, []);

	useEffect(() => {
		if (lastSeen !== messages[messages.length - 1].id) {
			const lastSeenMessage = {
				fromUser: localStorage.getItem('userId'),
				toUser: userId,
				message: messages[messages.length - 1].id,
			};
			postLastSeenDirectMessage(lastSeenMessage);
		}
	}, [postLastSeenDirectMessage, messages, userId, lastSeen]);

	const groups = messages.reduce((groups, message) => {
		const date = getTheDate(new Date(message.dateAndTime));
		if (!groups[date]) {
			groups[date] = [];
		}
		groups[date].push(message);
		return groups;
	}, {});

	const refs = messages.reduce((acc, value) => {
		acc[value.id] = React.createRef();
		return acc;
	}, {});

	const loadFunc = (pages) => {
		getDirectMessageHistory(userId, pages);
	};
	return (
		<ScrollToBottom className="messages" scrollViewClassName="m-c">
			<InfiniteScroll
				pageStart={1}
				initialLoad={false}
				loadMore={loadFunc}
				hasMore={messages.length < totalMessages}
				loader={
					<div className="loader" key={0}>
						<img src="/images/ball-triangle.svg" style={{ width: '2rem', height: '2rem' }} alt="loading" />
					</div>
				}
				useWindow={false}
				isReverse={true}
				threshold={50}
			>
				{Object.keys(groups).map((date) => (
					<Message
						key={date}
						date={date}
						messages={messages}
						message={groups[date]}
						user={user}
						userName={userName}
						userId={userId}
						setReply={setReply}
						refs={refs}
						lastSeen={
							lastSeen !== messages[messages.length - 1].id &&
							messages[messages.length - 1].fromUser !== localStorage.getItem('userId')
								? lastSeen
								: ''
						}
					/>
				))}
				{isUploading && (
					<Fragment>
						<span className="small text-muted">Uploading</span>{' '}
						<Spinner animation="grow" variant="primary" size="sm" />
					</Fragment>
				)}
			</InfiniteScroll>
			<NewMessageButton />
		</ScrollToBottom>
	);
}

Messages.propTypes = {
	messages: PropTypes.array.isRequired,
	userName: PropTypes.string.isRequired,
	userId: PropTypes.string.isRequired,
	totalMessages: PropTypes.number.isRequired,
	getDirectMessageHistory: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
	isUploading: state.messages.isUploading,
});

export default connect(mapStateToProps, { getDirectMessageHistory, postLastSeenDirectMessage })(Messages);
