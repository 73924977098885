import { requestHeaders } from './request-headers';
let COMMON_URL = process.env.REACT_APP_BACKEND_URL;

// if (process.env.NODE_ENV === 'development') {
// 	COMMON_URL = 'https://1fe9-2400-1a00-b030-b72f-796c-f16a-d0f-2799.ngrok.io/v1/';
// }

let ongoingRequests = {};

function removeQueryParams(url) {
    return new URL(url).pathname
        .replace(/[a-f0-9]{24}/g, ':mongoId')
        .split('/')
        .filter((p) => p)
        .join('/');
}

async function getOrDownload(url, requireAuth) {
    try {
        const lookupUrl = removeQueryParams(COMMON_URL + url);

        if (ongoingRequests[lookupUrl]) {
            const abortError = new Error('Request already pending');
            abortError.name = 'AbortError';
            ongoingRequests[lookupUrl]?.abort(abortError);
        }
        const newAbortController = new AbortController();
        ongoingRequests[lookupUrl] = newAbortController;

        const requestOptions = {
            method: 'GET',
            headers: await requestHeaders(false, requireAuth),
            signal: ongoingRequests[lookupUrl]?.signal,
        };

        let response = await fetch(COMMON_URL + url, requestOptions);
        let error = {};
        let hasError = false;
        if (response.status === 403) {
            hasError = true;
            error.status = 403;
            error.message = 'You are not permitted to access this channel.';
        }
        if (hasError) {
            throw error;
        }
        return response;
    } catch (err) {
        return err;
    }
}

async function get(url, requireAuth = true) {
    const response = await getOrDownload(url, requireAuth);
    if (response.status === 403) {
        return response;
    }
    return handleResponse(response);
}

async function download(url, fileName, requireAuth = true) {
    const response = await getOrDownload(url, requireAuth);
    const blob = await response.blob();
    const href = window.URL.createObjectURL(new Blob([blob]));
    const element = document.createElement('a');
    element.href = href;
    fileName && (element.download = fileName);
    element.click();
    return;
}

async function post(url, body, noJSON, requireAuth = true) {
    const requestOptions = {
        method: 'POST',
        headers: await requestHeaders(noJSON, requireAuth),
        body: noJSON ? body : JSON.stringify(body),
    };
    const response = await fetch(COMMON_URL + url, requestOptions);
    return handleResponse(response);
}

async function put(url, body, noJSON, requireAuth = true) {
    const requestOptions = {
        method: 'PUT',
        headers: await requestHeaders(noJSON, requireAuth),
        body: noJSON ? body : JSON.stringify(body),
    };
    const response = await fetch(COMMON_URL + url, requestOptions);
    return handleResponse(response);
}

async function patch(url, body, noJSON, requireAuth = true) {
    const requestOptions = {
        method: 'PATCH',
        headers: await requestHeaders(noJSON, requireAuth),
        body: noJSON ? body : JSON.stringify(body),
    };
    const response = await fetch(COMMON_URL + url, requestOptions);
    return handleResponse(response);
}

// prefixed with underscored because delete is a reserved word in javascript
async function _delete(url, requireAuth = true) {
    const requestOptions = {
        method: 'DELETE',
        headers: await requestHeaders(false, requireAuth),
    };
    const response = await fetch(COMMON_URL + url, requestOptions);
    return handleResponse(response);
}

// helper functions
async function handleResponse(response) {
    try {
        const text = await response.text();
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = { ...(data || {}), headers: response?.headers || {} };
            return Promise.reject(error);
        }
        return data;
    } catch (e) {
        return Promise.reject(response);
    }
}

export const fetchWrapper = {
    get,
    download,
    post,
    put,
    patch,
    delete: _delete,
};
