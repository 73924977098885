import { ActionStyles } from '../../../../reusableStyles';
import AccrualForm from './AccrualForm';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { css } from 'twin.macro';

const AccrualPolicy = ({ companyID, selectedAccrualPolicy = {}, goBack, handleCreateOrUpdate }) => {
    return (
        <div
            css={css`
                padding: 0 16px;
            `}
        >
            <div
                css={css`
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-top: 16px;
                `}
            >
                <ActionStyles>
                    <IoMdArrowRoundBack className="icon back" onClick={() => goBack()} />
                </ActionStyles>
                <span
                    css={css`
                        margin-left: 8px;
                        text-align: center;
                        font-weight: 600;
                        font-size: 1.8rem;
                    `}
                >
                    {selectedAccrualPolicy?.policy_code ? 'Edit' : 'Create'} Accrual Policy
                </span>
            </div>
            <AccrualForm
                companyID={companyID}
                initialValues={selectedAccrualPolicy}
                handleCreateOrUpdate={handleCreateOrUpdate}
                goBack={goBack}
            />
        </div>
    );
};

export default AccrualPolicy;
