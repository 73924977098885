import React from 'react';

import { getInitials, getRandomColor } from '../../../_helpers';
import styled from '@emotion/styled';
import { Fragment } from 'react';
import ArchivedUser from '../../../assets/archivedUser.svg';
import { Image } from 'react-bootstrap';

const AvatarStyled = styled('div')`
    height: 40px;
    width: 40px;
    border-radius: 20px;
    .initials {
        height: 40px;
        width: 40px;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        color: white;
    }
    .avatar {
        height: 40px;
        width: 40px;
        border-radius: 20px;
    }
`;

const Avatar = ({ item }) => {
    return (
        <AvatarStyled>
            {item ? (
                <Fragment>
                    {item?.avatar ? (
                        <Image src={item.avatar} className="avatar" width="100"></Image>
                    ) : (
                        <div className="initials" style={{ background: getRandomColor(item?.id || item?._id) }}>
                            <span className="text-white">{getInitials(item?.name || item?.username)}</span>
                        </div>
                    )}
                </Fragment>
            ) : (
                <Fragment>
                    <img src={ArchivedUser} className="avatar" alt="deletedUser" />
                </Fragment>
            )}
        </AvatarStyled>
    );
};

export default Avatar;
