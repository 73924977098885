import { css } from 'twin.macro';
import { connect } from 'react-redux';
import { BsFillLockFill, BsFillUnlockFill } from 'react-icons/bs';
import { FaGlobeAmericas } from 'react-icons/fa';
import { MdGpsFixed } from 'react-icons/md';
import { Fragment } from 'react';
import { useMemo } from 'react';
import { createRef } from 'react';
import { useEffect } from 'react';
import { setNewRoomRef } from '../../../_redux';
import { CallButtonWhenRoom } from './TeamHeading';
import { startConference } from '../../../_redux/actions/ActionConference';

export const roomdiv = css`
	&:first-of-type {
		margin-top: 10px;
	}
	&:last-child {
		margin-bottom: 10px;
	}
	.current {
		background-color: #f1f5f5;
		padding: 10px 20px;
		font-size: 14px;
		text-decoration: none;
		width: 100%;
		display: flex;
	}
	.room {
		padding: 10px 20px;
		font-size: 14px;
		text-decoration: none;
		width: 100%;
		display: flex;
		transition: background-color 200ms ease-in-out;
		&:hover {
			background-color: #fafafa;
		}
	}
	.new-badge {
		height: 10px;
		width: 10px;
		border-radius: 5px;
		background-color: #50aeb0;
	}
`;

export const Room = ({
	roomIndex,
	room,
	teamId,
	isSeen,
	handleRoomClick,
	userRoom,
	user,
	setOpenDetails,
	manager,
	admin,
	managerBool,
	setShowChat,
	setNewRoomRef,
	conferenceData,
	startConference
}) => {
	const handleClick = () => {
		if (roomIndex !== room.id) {
			handleRoomClick(room.id, room.type, manager, admin, room.team);
			setShowChat(true);
		}
	};

	const roomRefs = useMemo(
		() => ({
			[room.id]: createRef(),
		}),
		[room.id]
	);

	useEffect(() => {
		setNewRoomRef(room.id, roomRefs[room.id]);
	}, [setNewRoomRef, room.id, roomRefs]);


	const handleUserCall = async () => {
		try {
			startConference();
		} catch (e) {}
	};

	// const handleCall = async () => {
	// 	try {
	// 		const response = await fetchWrapper.post('/conference/token', { room: room.id });
	// 		setConferenceRoom(room.id, response.id)
	// 		startConference(room.id);
	// 	} catch (e) {}
	// };

	return (
		<div ref={roomRefs[room.id]} css={roomdiv} onClick={() => handleClick()}>
			<div
				key={room.id}
				className={`${roomIndex === room.id ? 'current' : 'room'} pointer d-flex justify-content-between`}
			>
				{/* {global.log(room.title,isSeen)} */}
				<div className="d-flex align-items-center justify-content-between w-100">
					<div className="d-flex">
						<a
							key={room.id}
							href={`#0`}
							css={css`
								${!isSeen ? `color:black !important;` : `color:#525C5E !important;`}
							`}
							// href={roomIndex !== room.id ? `/teams/${room.id}` : '#'}
							className={`text-muted m-0 mx-2 text-decoration-none`}
						>
							{room.title}
						</a>
						<div
							css={css`
								${!isSeen ? `color:black !important;` : `color:#525C5E !important;`}
							`}
						>
							{room.type === 'gps_enabled' ? <MdGpsFixed size="10" /> : null}
							{room.type === 'private' || room.type === 'gps_enabled' ? (
								// if user is owner || user is manager of team || user is admin
								(userRoom && userRoom.filter((uRoom) => uRoom.room === room.id)) ||
								managerBool ||
								user.role === 'admin' ? (
									<BsFillUnlockFill
										size="10"
										css={css`
											margin-left: 5px;
											font-size: 16px;
										`}
									/>
								) : (
									<BsFillLockFill
										size="10"
										css={css`
											margin-left: 5px;
											font-size: 16px;
										`}
									/>
								)
							) : (
								<Fragment>
									{/* // check if public channel has valid latitude value , if yes add gps icon else nothing */}
									{room.addresses[0]?.latitude && <MdGpsFixed size="10" className="ml-2" />}
									<FaGlobeAmericas
										size="10"
										css={css`
											margin-left: 5px;
											font-size: 16px;
										`}
									/>
								</Fragment>
							)}
						</div>
					</div>

					{room.type !== 'private' ? null : (
						<div
							css={css`
								margin-right: 5px;
								.callBtn {
									background-color: #50aeb0;
									color: white;
									padding: 5px 10px;
									:hover {
										background-color: #fff;
										color: #50aeb0;
										border-color: #50aeb0;
									}
								}
							`}
						>
							<CallButtonWhenRoom
								conferenceData={conferenceData}
								room={room}
								// handleCall={handleCall}
								handleUserCall={handleUserCall}
							/>
						</div>
					)}
				</div>
				<div className="d-flex align-items-center">{!isSeen && <span className="new-badge"></span>}</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	userRoom: state.user.user.roomList,
	user: state.user.user,
	conferenceData: state.conference.conferenceData,
});

const mapDispatchToProps = { setNewRoomRef, startConference };

export default connect(mapStateToProps, mapDispatchToProps)(Room);
