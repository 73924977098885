import React, { useEffect, useState } from 'react';
import { css } from 'twin.macro';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import SearchByProvider from '../EvvSearchModal/SearchByProvider';
import { fetchWrapper, getTeamIdFromURL } from '../../../../_helpers';
import PtoTable from './PtoTable';

export const statusLabel = {
    draft: 'draft',
    inprogress: 'inprogress',
    completed: 'approved',
    rejected: 'rejected',
    cancelled: 'cancelled',
    payment_pending: 'payment_pending',
    payment_processed: 'payment_processed',
};

const allowedLabels = ['draft', 'approved', 'rejected', 'payment_pending', 'payment_processed'];

const PTO = ({ teams, channelDetails }) => {
    const isTeamSettings = !!useLocation().pathname.match(/user-payroll/);
    const [provider, setProvider] = useState({});
    const [status, setStatus] = useState({});
    const handleProviderSelect = (selectedProvider) => {
        setProvider(selectedProvider);
    };

    const teamId = getTeamIdFromURL();
    useEffect(() => {
        if (!isTeamSettings || !teamId) {
            return null;
        }
        const team = teams.find((team) => team.id === teamId);
        fetchWrapper
            .get(`evv/provider?team=${teamId}`)
            .then(({ results: [response] }) =>
                setProvider({
                    value: response?._id,
                    label: response?.agencyName,
                    providerId: response?._id,
                    teamId: team?.id,
                    agencyName: response?.agencyName,
                    team: response?._id,
                    state: response?.state,
                    payroll: response?.payroll,
                })
            )
            .catch((error) => {
                if (error.name === 'AbortError') {
                    return;
                }
                toast.error("Couldn't fetch provider data");
            });
    }, [teams, isTeamSettings]);

    return (
        <div>
            <div
                className="d-flex flex-row align-items-end justify-content-between mb-4"
                css={css`
                    gap: 12px;
                `}
            >
                <div
                    css={css`
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        gap: 8px;
                    `}
                >
                    <div
                        css={css`
                            min-width: 270px;
                        `}
                    >
                        <SearchByProvider
                            provider={provider}
                            handleSelect={handleProviderSelect}
                            isDisabled={isTeamSettings}
                            team={teamId}
                        />
                    </div>
                    <div
                        key={provider?.value}
                        className="search-container"
                        css={css`
                            .userImage {
                                width: 3rem !important;
                                height: 3rem !important;
                            }
                            width: 256px;
                        `}
                    >
                        <span className="search-title">Select Status:</span>
                        <AsyncSelect
                            css={css`
                                text-transform: capitalize;
                            `}
                            defaultOptions={Object.entries(statusLabel)
                                .filter((label) => allowedLabels.includes(label[1]))
                                .map((item) => ({
                                    value: item[0],
                                    label: item[1].replace('_', ' '),
                                }))}
                            onChange={(e) => setStatus(e)}
                            isClearable={true}
                            value={status?.value ? status : null}
                        />
                    </div>
                </div>
                <div
                    className="d-flex flex-row"
                    css={css`
                        gap: 12px;
                    `}
                >
                    <div
                        css={css`
                            min-width: 270px;
                        `}
                    ></div>
                </div>
                <span
                    className="badge badge-info"
                    css={css`
                        font-size: 16px !important;
                    `}
                >
                    {provider?.payroll?.type}
                </span>
            </div>
            <div
                className="custom-card d-flex flex-col"
                css={css`
                    min-height: 70vh;
                    overflow: auto;
                    position: relative;
                    justify-content: center;
                `}
            >
                {!provider?.value ? (
                    <div>Please select a provider</div>
                ) : (
                    <PtoTable status={status} provider={provider} />
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    teams: state.teams.teams,
});

export default connect(mapStateToProps)(PTO);
