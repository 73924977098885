import React, { useMemo } from 'react';
import { CSSTransition } from 'react-transition-group';
import { MdArrowUpward, MdArrowDownward } from 'react-icons/md';

const SortedTableHeaderIcon = ({ sortConfig, fieldName, size=24 }) => {
	const icon = useMemo(() => {
		if (sortConfig) {
			if (sortConfig.key === fieldName) {
				if (sortConfig.direction === 'asc') {
					return <MdArrowDownward className="txt-optonome-green" size={size} />;
				} else {
					return <MdArrowUpward className="txt-optonome-green" size={size} />;
				}
			}
		}
		return <MdArrowDownward color="silver" size={size} />;
	}, [fieldName, sortConfig]);

	return (
		<CSSTransition
			in={sortConfig.key === fieldName && sortConfig.direction === 'desc'}
			timeout={200}
			classNames="arrow"
		>
			{icon}
		</CSSTransition>
	);
};

export default SortedTableHeaderIcon;
