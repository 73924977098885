import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { formatDate } from '@fullcalendar/core/index.js';

const DateRangesTable = ({ formData }) => {
    const dateRangesData = useMemo(() => {
        const data = formData?.data;
        let dateRanges = (data || []).find((element) =>
            (element?.fieldName || '')?.toLowerCase()?.includes('datetimerangepicker')
        );

        if (dateRanges?.value) {
            dateRanges = JSON.parse(dateRanges.value);
        }

        return (dateRanges || []).map((range) => {
            const start = dayjs(range.startDateTime);
            const end = dayjs(range.endDateTime);

            const diffMs = end.diff(start);

            if (diffMs < 0) {
                return '<p style="color:red;font-size:1.5rem;">End time cannot be before start time.</p>';
            }

            const hours = Math.floor(diffMs / 3600000);
            const minutes = Math.ceil((diffMs % 3600000) / 60000);

            const hoursStr = hours > 1 ? `${hours} hours` : `${hours} hour`;
            const minutesStr = minutes > 1 ? `${minutes} minutes` : `${minutes} minute`;

            return {
                ...range,
                time: `${hoursStr} ${minutesStr}`,
            };
        });
    }, [formData]);
    return (
        <table className="table-auto w-full border-collapse">
            <thead>
                <tr className="bg-gray-200">
                    <th className="px-4 py-2 text-left">Day</th>
                    <th className="px-4 py-2 text-left">Start Time</th>
                    <th className="px-4 py-2 text-left">End Time</th>
                    <th className="px-4 py-2 text-left">Time</th>
                </tr>
            </thead>
            <tbody>
                {(dateRangesData || []).map((range, index) => (
                    <tr key={index} className="bg-white border-b">
                        <td className="px-4 py-2">{formatDate(range.startDateTime)}</td>
                        <td className="px-4 py-2">{dayjs(range.startDateTime).format('h:mm A')}</td>
                        <td className="px-4 py-2">{dayjs(range.endDateTime).format('h:mm A')}</td>
                        <td className="px-4 py-2">{range?.time}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default DateRangesTable;
