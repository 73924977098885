import { css } from 'twin.macro';
import dayjs from 'dayjs';

import { useState } from 'react';
import { Form } from 'react-bootstrap';
import DateTimePicker from 'react-datetime-picker';
import Linkify from 'react-linkify';
import { marginColor } from './Checkbox';

const DateAndTimeComponent = ({ data, elementRef, isPreview, error }) => {
	const [dateTimeValue, setDateTimeValue] = useState(data.value ? new Date(dayjs(data.value)) : new Date());
	const handleDateChange = (value) => {
		setDateTimeValue(new Date(value));
	};
	return (
		<Form.Group controlId="formGridEmail">
			<Form.Label>
				<Form.Label>
					<h5 className="warpIt">
						{data.fieldName}
						<span css={marginColor}>{(data.hasOwnProperty('required') ? data.required : true) && '*'}</span>
					</h5>
				</Form.Label>
			</Form.Label>
			{data.instruction && (
				<pre className="small text-muted warpIt preStyles">
					<Linkify
						componentDecorator={(decoratedHref, decoratedText, key) => (
							<a target="blank" href={decoratedHref} key={key}>
								{decoratedText}
							</a>
						)}
					>
						{data.instruction}
					</Linkify>
				</pre>
			)}
			<DateTimePicker
				value={dateTimeValue}
				onChange={handleDateChange}
				disabled={isPreview || data.value}
				clearIcon={null}
				amPmAriaLabel="Select AM/PM"
				showLeadingZeros={true}
				format="MM-dd-y h:mm a"
				calendarType={'US'}
				/>
			<Form.Control
				type="text"
				name={`${!isPreview ? `DatePicker-${data._id}` : 'DatePicker'}`}
				className="date-picker"
				ref={
					elementRef &&
					elementRef({
						required: data.hasOwnProperty('required') ? data.required : true,
					})
				}
				css={css`
					position: absolute;
					opacity: 0 !important;
					width: 0 !important;
				`}
				disabled={isPreview || data.value}
				value={new Date(dateTimeValue).toISOString()}
				readOnly
			/>
			<Form.Text className="text-danger">
				{error && error.type === 'required' && 'This field is required'}
			</Form.Text>
		</Form.Group>
	);
};

export default DateAndTimeComponent;
