import styled from '@emotion/styled';
import { Form } from 'react-bootstrap';
import { MentionsInput } from 'react-mentions';
import { css } from 'twin.macro';

export const MentionInputStyled = styled(MentionsInput)`
	.mentions--singleLine .mentions__control {
		display: inline-block;
		width: 130px;
	}
	.mentions--singleLine .mentions__highlighter {
		padding: 1px;
		border: 2px inset transparent;
	}
	.mentions--singleLine .mentions__input {
		padding: 1px;
		border: 2px inset;
	}

	.message-input-items {
		display: flex;
		align-items: center;
	}
	.mentions {
		width: 100%;
	}
	.mentions__input {
		position: relative;
		border: none;
		&:focus,
		&:active {
			outline: none;
			border: none;
		}
	}
	.mentions__suggestions__list {
		position: fixed;
		bottom: 50px;
		background-color: #fff;
		border: 1px solid rgba(0, 0, 0, 0.15);
		font-size: 14px;
		max-height: 200px;
		width: 150px;
		overflow: auto;
	}
	.mentions__suggestions__list::-webkit-scrollbar-track {
		display: none;
	}

	.mentions__suggestions__list::-webkit-scrollbar {
		display: none;
	}

	.mentions__suggestions__list::-webkit-scrollbar-thumb {
		display: none;
	}
	.mentions__suggestions__item {
		padding: 5px 15px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}
	.mentions__suggestions__item--focused {
		background-color: #cee4e5;
	}

	.mentions__mention {
		position: relative;
		z-index: 1;
		color: blue;
		text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white;
		text-decoration: underline;
		pointer-events: none;
	}
`;

export const FormStyled = styled(Form)`
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
	width: 95%;
	.emojiDrop > div > div {
		background-color: transparent;
		border: none;
	}
	.input-features {
		display: flex;
		flex-direction:row;
		align-items: flex-end;
		padding: 5px;
		// background-color: #e0f0ef;
		border-radius: 20px 0px 0px 20px;
	}

	.input-features-container{
		display:flex;
		flex-direction:row;
		align-items:center;
	}
	
	.send-btn {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		height: 100%;
		width: 50px;
		border-radius: 50%;
		font-size: 30px;
		// background-color: #e0f0ef;
		padding-left: 5px;
		padding-bottom:3px;
	}
`;

export const chatInputCss = css`
	position: relative;

	.message-inputarea {
		display: flex;
		background-color: white;
		padding: 5px;
		margin: 10px;
		border-radius: 25px;
		flex-direction: row;
		justify-content: flex-start;
		color: #50aeb0;
	}

	.message-input {
		flex: 1;
		padding: 0.5rem 0.8rem;
		align-self: center;
		max-height: 12rem;
		width: 100%;
		border-radius: 15px;
		border: none;
		box-sizing: border-box;
		overflow-y: auto;
	}

	.message-input-items {
		margin: 0 2px;
		color: #2eb165;
	}

	.message-input:focus,
	.message-input:active {
		outline: none;
		border: none;
	}

	.send-btn {
		cursor: pointer;
	}
	.emoji-position {
		position: relative;
	}

	.fa:hover {
		color: #50aeb0;
		cursor: pointer;
	}
`;
