import styled from '@emotion/styled';

export const EventsStyles = styled('div')`
	margin-left:8rem;
	padding: 1rem;
	min-height:100vh;
	background-color:#F5F5F5;

	.eventWrapper{
		display:grid;
		grid-template-columns: 2fr 3fr;

		.evs{
			padding:10px 10px;
			border-radius:10px;
			background-color: #fafafa;
			box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

			@media (max-width:768px){
				margin-top: 20px;
			}
		}
		.calendar{
			padding:0px 10px;
		}

		.todays-evt{
			font-size: 18px;
			margin:10px 0 0 10px;
			color:#757575;
		}

		@media (max-width:768px){
			grid-template-columns: 1fr;
			grid-template-rows: 1fr 2fr;
		}
	}
	.eventsDetail_div {
		padding-left: 9rem;
		padding-top: 4rem;
		min-height: 100vh;
	}
	.chibi_heading {
		font-size: 3vmin;
	}
	.selectedDate {
		font-size: 3vmin;
	}
	.task-list {
		height:calc(100vh - 200px);
		overflow-y: auto;
		display:flex;
		flex-direction: column;
		align-items: center;
		padding: 1%;
	}
	.task-list::-webkit-scrollbar {
		width: 0.3em;
	}
	.task-list::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		border-radius: 0.1em;
	}

	.task-list::-webkit-scrollbar-thumb {
		border-radius: 0.1em;
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
	}
	.noEventMsg {
		color: #aeaeae;
		text-align: center;
		margin-top: 20%;
	}
	.showAllBtn {
		width: 50%;
		height: 60%;
		font-size: 3vmin;
		text-align: center;
	}
	.showBtnDiv {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	@media (max-width:768px){
			margin-left:0px;
	}

`;
export const Title = styled.div`
	color: #3b3b3b;
	font-size: 7vmin;
`;
