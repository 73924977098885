import { css } from 'twin.macro';
import React, { Fragment, useState } from 'react';
import { InputGroup, Button, Modal, Tabs, Tab, FormLabel, Form } from 'react-bootstrap';

//components
import SearchFilter from './SearchFilter';
import SearchByName from './SearchByName';
import SearchByUserEmployee from './SearchByUserEmployee';
import SearchByProvider from './SearchByProvider';

//styles
import { SearchBox } from '../../reusableComponents/AdminSearchBox';

//assets
import { FiSearch } from 'react-icons/fi';
import { IoFilterSharp } from 'react-icons/io5';
import { ToolTip } from '../../../../_elements/ToolTip';
import SearchByClient from './SearchByClient';
import DateTimePicker from 'react-datetime-picker/dist/DateTimePicker';
import VisitLabelFilter from './VisitLabelFilter';

const EvvSearchModalCSS = css``;

export const ModalCSS = css`
	.modal-dialog {
		position: absolute;
		top: 20px;
		left: 20px;
		margin: 0;
		width: 100%;
	}
	.modal-header {
		color: white;
		background: #50aeb0;
		button {
			color: white;
		}
	}
	.modal-body {
		.input-group,
		.search-container {
			margin-bottom: 1rem;
		}
		.form-label,
		.search-title {
			font-weight: bold;
			color: #242424;
		}
		.react-datetime-picker {
			width: 250px;
			.react-datetime-picker__wrapper {
				border: thin solid #cdcdcd;
				padding: 0.2rem 0.5rem;
				border-radius: 3px;
			}
		}
	}
	.tab-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		border: 1px solid #dee2e6 !important;
		border-top: none !important;
		padding: 2rem 1rem !important;
		min-height: 140px;
	}
`;

const EvvSearchModalComponent = ({
	firstName,
	firstNameRef,
	lastName,
	lastNameRef,
	userEmployee,
	provider,
	client,
	startDate,
	endDate,
	handleNameChange,
	handleUserEmployeeSelect,
	handleClientSelect,
	handleProviderSelect,
	handleEndDateChange,
	handleStartDateChange,
	handleStartDateClear,
	handleEndDateClear,
	handleClearAll,
	handleClearIsManual,
	handleClearVisitLabelFilter,
	type,
	labels,
	isTeamSettings,
	isChannelSettings,
	visitLabelFilter,
	handleVisitLabelFilterChange,
	isManual,
	handleManualChange,
	team,
}) => {
	const [showSearchModal, setShowSearchModal] = useState(false);
	const [activeTab, setActiveTab] = useState(type === 'Visit' ? 'User' : 'Name');
	const hideSearchModal = () => {
		setShowSearchModal(false);
	};
	const showSearchModalFunc = () => {
		setShowSearchModal(true);
	};

	return (
		<div css={EvvSearchModalCSS}>
			<div>
				<InputGroup className="mb-3">
					{type === 'Visit' ? (
						<div
							css={css`
								min-width: 270px;
							`}
						>
							<SearchByProvider
								provider={provider}
								handleSelect={handleProviderSelect}
								isDisabled={isTeamSettings}
								team={team}
							/>
						</div>
					) : (
						<SearchBox>
							<input
								disabled={isChannelSettings}
								name="firstName"
								ref={firstNameRef}
								value={firstName}
								onChange={handleNameChange}
								placeholder={`Search by ${type === 'Visit' ? 'Employee' : ''} first name...`}
								className="search-input"
							/>

							<p className="search-area-items">
								<FiSearch size="15" className="mr-2 d-flex justify-content-center align-items-center" />
							</p>
						</SearchBox>
					)}

					<div
						css={css`
							display: flex;
							justify-content: center;
							align-items: flex-end;
							> div {
								width: auto;
							}
							.rightside {
								top: 10% !important;
								left: 110%;
							}
						`}
					>
						<ToolTip text={'Filter'} side={'rightside'}>
							<Button variant="none" onClick={showSearchModalFunc}>
								<IoFilterSharp size="25" color="#50aeb0" />
							</Button>
						</ToolTip>
					</div>
				</InputGroup>
				<SearchFilter
					firstName={firstName}
					lastName={lastName}
					userEmployee={userEmployee}
					client={client}
					handleClientSelect={handleClientSelect}
					provider={provider}
					startDate={startDate}
					endDate={endDate}
					handleStartDateClear={handleStartDateClear}
					handleEndDateClear={handleEndDateClear}
					handleNameChange={handleNameChange}
					handleUserEmployeeSelect={handleUserEmployeeSelect}
					handleProviderSelect={handleProviderSelect}
					handleClearAll={handleClearAll}
					isTeamSettings={isTeamSettings}
					isChannelSettings={isChannelSettings}
					visitLabelFilter={visitLabelFilter}
					isManual={isManual}
					handleClearIsManual={handleClearIsManual}
					handleClearVisitLabelFilter={handleClearVisitLabelFilter}
				/>
			</div>

			<Modal css={ModalCSS} show={showSearchModal} backdropClassName="bg-dark" onHide={hideSearchModal}>
				<Modal.Header closeButton>
					<h4
						css={css`
							margin: 0;
						`}
					>
						Search {type}
					</h4>
				</Modal.Header>
				<Modal.Body className="modal-body">
					{type === 'Visit' ? (
						<div>
							<InputGroup className="mb-3 flex-column">
								<FormLabel className=" mr-2">Start Date and Time: </FormLabel>
								<DateTimePicker
									onChange={handleStartDateChange}
									value={startDate ? new Date(startDate) : startDate}
									format="MM-dd-y hh:mm a"
									disableClock={true}
									calendarType={'US'}
								/>
							</InputGroup>
							<InputGroup className="mb-3 flex-column">
								<FormLabel className="mr-2 ">End Date and Time: </FormLabel>
								<DateTimePicker
									onChange={handleEndDateChange}
									value={endDate ? new Date(endDate) : endDate}
									format="MM-dd-y hh:mm a"
									disableClock={true}
									calendarType={'US'}
								/>
							</InputGroup>
							<VisitLabelFilter
								visitLabelFilter={visitLabelFilter}
								labels={labels}
								handleVisitLabelFilterChange={handleVisitLabelFilterChange}
							/>
							<InputGroup>
								<FormLabel htmlFor={'verified'} className=" mr-2">
									Only Manual Visits:{' '}
								</FormLabel>
								<Form.Check
									type="checkbox"
									id="isManual"
									defaultChecked={isManual}
									onChange={handleManualChange}
								/>
							</InputGroup>
							<SearchByProvider
								provider={provider}
								handleSelect={handleProviderSelect}
								isDisabled={isTeamSettings}
								team={team}
							/>
							{provider ? (
								<div>
									<SearchByUserEmployee
										userEmployee={userEmployee}
										handleSelect={handleUserEmployeeSelect}
										provider={provider}
										team={team}
									/>
									<SearchByClient
										client={client}
										handleSelect={handleClientSelect}
										provider={provider}
										isDisabled={isChannelSettings}
									/>
								</div>
							) : null}
						</div>
					) : (
						<Tabs id="controlled-tab-example" activeKey={activeTab} onSelect={(tab) => setActiveTab(tab)}>
							<Tab eventKey="Name" title="Name">
								{activeTab === 'Name' && (
									<SearchByName
										firstName={firstName}
										firstNameRef={firstNameRef}
										lastName={lastName}
										lastNameRef={lastNameRef}
										handleChange={handleNameChange}
										isChannelSettings={isChannelSettings}
									/>
								)}
							</Tab>
							{type !== 'Client' && (
								<Tab eventKey="User" title="User">
									{activeTab === 'User' && (
										<SearchByUserEmployee
											userEmployee={userEmployee}
											handleSelect={handleUserEmployeeSelect}
											provider={provider}
											team={team}
										/>
									)}
								</Tab>
							)}
							{isTeamSettings ? null : (
								<Tab eventKey="Provider" title="Provider">
									{activeTab === 'Provider' && (
										<SearchByProvider provider={provider} handleSelect={handleProviderSelect} />
									)}
								</Tab>
							)}
						</Tabs>
					)}
					<SearchFilter
						firstName={firstName}
						lastName={lastName}
						userEmployee={userEmployee}
						provider={provider}
						startDate={startDate}
						endDate={endDate}
						client={client}
						handleClientSelect={handleClientSelect}
						handleStartDateClear={handleStartDateClear}
						handleEndDateClear={handleEndDateClear}
						handleNameChange={handleNameChange}
						handleUserEmployeeSelect={handleUserEmployeeSelect}
						handleProviderSelect={handleProviderSelect}
						handleClearAll={handleClearAll}
						isTeamSettings={isTeamSettings}
						isChannelSettings={isChannelSettings}
						visitLabelFilter={visitLabelFilter}
						isManual={isManual}
						handleClearIsManual={handleClearIsManual}
						handleClearVisitLabelFilter={handleClearVisitLabelFilter}
					/>
				</Modal.Body>
				<Modal.Footer></Modal.Footer>
			</Modal>
		</div>
	);
};

export default EvvSearchModalComponent;
