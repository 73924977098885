import React, { useState, useEffect } from 'react';
import { css, styled } from 'twin.macro';
import { connect } from 'react-redux';

const IndicatorDiv = styled.div`
	position: absolute;
	bottom: 0%;
	width: 1.4rem;
	height: 1.4rem;
	overflow: hidden;
	border-radius: 1000px;
	border: 1px solid #efefef;
	right: -5%;
	&.chat_detail {
		bottom: 10%;
		width: 2.5rem;
		height: 2.5rem;
		border: 3px solid #efefef;
		right: 8%;
	}
	&.team_member {
		width: 1.2rem;
		height: 1.2rem;
		right: -12%;
	}
`;

const OnlineIndicator = ({ user, userStatus, className }) => {
	const [onlineStatus, setOnlineStatus] = useState(false);

	useEffect(() => {
		try {
			let userID = user?.user?.id || user?.id;
			if (userStatus[userID]) {
				if (userStatus[userID] === 'online') {
					return setOnlineStatus(true);
				} else {
					return setOnlineStatus(false);
				}
			} else {
				let isOnline = user?.user?.onlineStatus || user?.onlineStatus;
				return setOnlineStatus(isOnline);
			}
		} catch (err) {
			console.log(err, 'OnlineIndicator ERROR');
		}
	}, [user?.user?.id, user?.id, user?.onlineStatus, user?.user?.onlineStatus, userStatus]);

	return (
		<IndicatorDiv
			css={css`
				background: ${onlineStatus ? '#38cc8f' : 'gray'};
			`}
			className={className}
		></IndicatorDiv>
	);
};

const mapStateToProps = (state) => ({
	userStatus: state.userStatus.userStatus,
});

export default connect(mapStateToProps, null)(OnlineIndicator);
