import {css} from 'twin.macro';

export const alertPop = css`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	z-index: 999;
	width: 100%;

	.alert-enter {
		opacity: 0;
		transform: translateY(-100%);
	}
	.alert-enter-active {
		opacity: 1;
		transform: translateY(0);
		transition: opacity 800ms, transform 800ms;
	}
	.alert-exit {
		opacity: 1;
		transform: translateY(0);
	}
	.alert-exit-active {
		opacity: 0;
		transform: translateY(-100%);
		transition: opacity 800ms, transform 800ms;
	}
`;

export const tabStyle = css`
	border: 2px solid #50aeb0;
	border-bottom: none;
	padding: 0.2rem 1rem;
	margin-top: 0.5rem;
	margin-right: 0.2rem;
	cursor: pointer;
	transition: background-color 300ms, color 300ms, border 300ms;
	&.active {
		background-color: #50aeb0;
		color: white;
		span{
			color: #50aeb0;
		}
	}
	&.inactive {
		background-color: #d8d8d8;
		color: #7c7c7c;
		border: 2px solid #d8d8d8;
		span {
			opacity: 0.7;
		}
	}
`;