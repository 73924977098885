import { css } from 'twin.macro';
import { useState, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import GoogleMap from '../../Home/GoogleMap';

export const GoogleMapPopUP = ({ lat, lng }) => {
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	return (
		<Fragment>
			<i className="fas fa-map-marker-alt text-primary" onClick={handleShow} />

			<Modal size="lg" show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title className="modal-headers">Map</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="row  m-0">
						<div className="col-md-12 p-0 ">
							<div
								css={css`
									width: 100px;
									height: 400px;
								`}
							>
								<GoogleMap lat={lat} lng={lng} />
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</Fragment>
	);
};

GoogleMapPopUP.propTypes = {
	lat: PropTypes.number.isRequired,
	lng: PropTypes.number.isRequired,
};
