import React, { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { establishSocketConnection } from '../../_helpers';
import { setNewActivity } from '../actions/ActionActivity';

import { setOnlineStatus, setOfflineStatus } from '../actions/ActionUserStatus';
import { setInvitationForConference } from '../actions/ActionConference';
import { setConferenceEnd, setConferenceJoin, setConferenceLeave } from '../actions/ActionMessage';
import { useLogout } from '../../components/Features/Common/useLogout';

const WebSocketContext = createContext(null);
export { WebSocketContext };

const WebSocketProvider = ({ children }) => {
    const [socket, setSocket] = useState();
    const [ws, setWs] = useState();

    const dispatch = useDispatch();
    const isLogged = useSelector((state) => state.user.isLogged);
    const { logoutFunction } = useLogout();

    const sendMessage = (socket, message, reply, position, { userId, chatroomId, subChannelId, permissionArr }) => {
        let payload;
        const msg = {};
        msg.text = message;
        if (reply) {
            if (chatroomId) {
                msg.reply = {
                    id: reply.id,
                    reply: reply.file ? reply.file : reply.data,
                    replyUser: reply.name.name,
                    type: reply.type,
                    user: reply.user.id,
                };
            } else if (userId) {
                msg.reply = { reply: reply.file ? reply.file : reply.data, type: reply.type, user: reply.fromUser };
            } else if (subChannelId) {
                msg.reply = {
                    id: reply.id,
                    reply: reply.file ? reply.file : reply.data,
                    replyUser: reply.name.name,
                    type: reply.type,
                    user: reply.user.id,
                };
            }
        }
        if (position) {
            msg.location = { latitude: position.coords.latitude, longitude: position.coords.longitude };
        } else {
            msg.location = { latitude: null, longitude: null };
        }

        if (userId) {
            payload = { userId, message: JSON.stringify(msg) };
        } else if (chatroomId) {
            payload = { chatroomId, message: JSON.stringify(msg) };
        } else if (subChannelId) {
            payload = { subChannelId, message: JSON.stringify(msg) };
        }

        let newPermissionArr = [...(permissionArr || [])];
        if (chatroomId) {
            let ValidPermissionValue = ['doctor', 'family', 'user', 'supervisor'];
            newPermissionArr = newPermissionArr.filter((permission) => ValidPermissionValue.includes(permission));
        } else {
            let ValidPermissionValue = ['owner', 'user'];
            newPermissionArr = newPermissionArr.filter((permission) => ValidPermissionValue.includes(permission));
        }

        payload.visibleTo = newPermissionArr;
        socket.emit('message', payload, (error, data) => {
            // TODO: implement optimistic update and 'tap to retry' on messages
            if (error) {
                console.log(error, 'ERROR');
            } else {
                // console.log(data, 'data');
            }
        });
    };

    const joinChatRoom = (socket, roomId) => {
        socket.emit('join', roomId, (error, socketMessages) => {
            if (error) {
            } else {
            }
        });
    };
    const joinSubChannelRoom = (socket, subChannelId) => {
        socket.emit('joinSubChannel', subChannelId, (error, socketMessages) => {
            if (error) {
            } else {
            }
        });
    };

    const disconnectSocket = (socket) => {
        socket.disconnect();
    };

    const updateSocket = async () => {
        const newSocket = await establishSocketConnection();

        if (!newSocket) {
            return;
        }
        newSocket.on('connect', () => {
            setWs((prev) => ({ ...prev, id: newSocket.id }));
        });
        newSocket.on('activity', (activity) => {
            dispatch(
                setNewActivity({
                    ...activity,
                    _id: activity.id || activity?.activity?.id,
                    createdAt: new Date().toISOString(),
                    unRead: true,
                })
            );
        });
        newSocket.on('online_status', (onlineUserId) => {
            dispatch(setOnlineStatus(onlineUserId));
        });
        newSocket.on('offline_status', (offlineUserId) => {
            dispatch(setOfflineStatus(offlineUserId));
        });
        newSocket.on('conferenceEnd', (conference) => {
            dispatch(setConferenceEnd(conference));
        });
        newSocket.on('conferenceJoin', (conference) => {
            dispatch(setConferenceJoin(conference));
        });
        newSocket.on('conferenceLeave', (conference) => {
            dispatch(setConferenceLeave(conference));
        });
        newSocket.on('invitation', (conference) => {
            // indiciate call ui
            dispatch(setInvitationForConference(conference));
        });
        newSocket.on('logout', () => {
            logoutFunction();
        });
        newSocket.on('disconnect', async (reason) => {
            // check if the connection was forcefully closed
            if (!['io server disconnect', 'io client disconnect'].includes(reason)) {
                console.log('DISCONNECTED', reason);
            }
        });

        setWs((prev) => ({
            ...prev,
            socket: newSocket,
            sendMessage: (...args) => sendMessage(newSocket, ...args),
            joinChatRoom: (...args) => joinChatRoom(newSocket, ...args),
            joinSubChannelRoom: (...args) => joinSubChannelRoom(newSocket, ...args),
            disconnectSocket: () => disconnectSocket(newSocket),
        }));

        setSocket(newSocket);
    };

    useEffect(() => {
        if (isLogged) {
            updateSocket();
        }
        return () => {
            if (!isLogged) {
                socket?.disconnect();
            }
        };
    }, [isLogged]);

    return <WebSocketContext.Provider value={ws}>{children}</WebSocketContext.Provider>;
};

export default WebSocketProvider;
