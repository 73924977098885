import tw, { css } from 'twin.macro';
import { ActionStyles } from '../../../../reusableStyles';
import { Fragment, useState } from 'react';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { getFormLabel } from './AccrualForm';

const cardStyle = css`
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 16px;
    margin-bottom: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background: #f7f7f7;
    display: flex;
    flex-direction: column;
    alin-items: center;
    justify-content: space-between;
    width: 100%;
`;
const KeyValue = tw.div`flex justify-between mb-2`;
const Key = tw.span`font-semibold text-gray-700`;
const Value = tw.span`text-gray-600`;
const keysToExclude = ['companyID'];

export const PolicyCard = ({ policy, children, fullView = false }) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <div css={cardStyle}>
            <div
                css={css`
                    margin-bottom: 8px;
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    justify-content: space-between;
                `}
            >
                <div>
                    <span
                        css={css`
                            font-weight: bold;
                            font-size: 2rem;
                        `}
                    >
                        {policy?.policy_name || ''}
                    </span>
                    <span
                        css={css`
                            margin-left: 8px;
                        `}
                    >
                        ({policy?.policy_code})
                    </span>
                    <span className="badge badge-primary ml-4">{policy?.policy_type}</span>
                    <span
                        className={`badge ${policy?.policy_status === 'live' ? 'badge-warning' : 'badge-danger'}   ml-2`}
                    >
                        {policy?.policy_status}
                    </span>
                </div>

                <div>{children}</div>
                {!fullView ? null : (
                    <ActionStyles onClick={() => setExpanded((prev) => !prev)}>
                        {expanded ? <IoIosArrowUp className="icon" /> : <IoIosArrowDown className="icon" />}
                    </ActionStyles>
                )}
            </div>

            {!expanded ? null : (
                <Fragment>
                    {Object.entries(policy)
                        .filter(([key]) => !keysToExclude.includes(key))
                        .map(([key, value]) => (
                            <KeyValue key={key}>
                                <Key>{getFormLabel(key)}:</Key>
                                <Value>{value?.toString()}</Value>
                            </KeyValue>
                        ))}
                </Fragment>
            )}
        </div>
    );
};
