import styled from '@emotion/styled';

export const Body = styled('div')`
    display:flex;
    flex-direction:column;
    border-radius:10px;
    height:auto;
    .title{
        display:flex;
        justify-content:center;
        align-items:center;
        background-color:#50aeb0;
        color:white;
        height:50px;
        font-size:20px;
    }
    .content{
        padding:15px;
        display:flex;
        flex-direction:column;
        justify-content: space-between;
        height:100%;
        .text{
            font-size:18px;
            margin-bottom:10px;
        }
    }
   
    & > .button-enabled{
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        color:black;
        margin-right:5px;
    }
    & > .button-disabled{
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        color:#B0B0B0;
        margin-right:5px;
    }
`;