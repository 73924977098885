import React from 'react';
import { css } from 'twin.macro';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import { connect } from 'react-redux';
import { Body } from './styles';

import StaffForm from './StaffForm';

import { BsEye } from 'react-icons/bs';
import { ToolTip } from '../../../../_elements/ToolTip';

const ViewStaff = ({ employee }) => {
	const [showModal, setShowModal] = useState(false);
	return (
		<div>
			<ToolTip text={'View'} cssStyles={"top:-70% !important; min-width: fit-content !important;"}>
			<BsEye className="icon" onClick={() => setShowModal(true)} />
			</ToolTip>
			<Modal
				show={showModal}
				backdropClassName="bg-dark"
				onHide={() => setShowModal(false)}
				centered
			>
				<Modal.Header closeButton>
					<div className="header">
						<h4
							css={css`
								margin: 0;
							`}
						>
							View Staff
						</h4>
					</div>
				</Modal.Header>
				<Modal.Body className="modal-body">
					<Body>
						<StaffForm setShowModal={setShowModal} employee={employee} formType={'View'} />
					</Body>
				</Modal.Body>
			</Modal>
		</div>
	);
};
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(ViewStaff);
