import { css } from '@emotion/react';
import React, { useState, Fragment, useCallback, useMemo } from 'react';
import { Button, ListGroup, Modal } from 'react-bootstrap';
import { fetchWrapper, decimalHoursToHoursAndMinutes } from '../../../../_helpers';
import { toast } from 'react-toastify';
import ViewForm from '../../Form/ViewForm';
import LoadingSpiner from '../../../../_elements/LoadingSpiner';
import { getBadgeClass } from './PtoRow';
import { statusLabel } from './';
import AssignedAccrualPolicySelector from './AssignedAccrualPolicySelector';
import DateRangesTable from './DateRangesTable';

function PtoModal({ view = false, show, formData, provider, handleClose, handleUpdateForm }) {
    const isProviderOnboarded =
        provider?.payroll?.metadata?.companyID && provider?.payroll?.metadata?.status === 'live';
    const status = statusLabel[formData?.submissionType] || formData?.submissionType;
    const [isBusy, setIsBusy] = useState(false);
    const [selectedAccrualPolicy, setSelectedAccrualPolicy] = useState({});
    const isAuthored = formData?.submissionType !== 'draft';
    const calculatedTime = useMemo(() => {
        return decimalHoursToHoursAndMinutes(formData?.specialityForm?.data?.requestedBalance);
    }, [formData]);

    const selectedPolicyDuringSubmission = useMemo(() => {
        const data = formData.data;
        let policy =
            (data || []).find((element) => (element?.value || '')?.toLowerCase()?.includes('policy_name')) || null;
        if (policy) {
            policy = JSON.parse(policy?.value || null);
        }
        return policy;
    }, [formData]);

    const handleReject = useCallback(async () => {
        try {
            setIsBusy(true);
            const updatedFormData = await fetchWrapper.patch(
                `/userformdata/speciality-form/pto/reject/${formData._id}`,
                {
                    submissionType: 'rejected',
                }
            );
            handleUpdateForm(updatedFormData);
            toast.success('Pto request rejected successfully');
            handleClose();
        } catch (err) {
            console.log('err', err);
            toast.error(err?.message || 'Failed to update');
        } finally {
            setIsBusy(false);
        }
    }, [formData]);

    const handleApprove = useCallback(async () => {
        try {
            setIsBusy(true);
            const policyCode = selectedAccrualPolicy?.policy_code;
            if (!policyCode) {
                return toast.error('policyCode is required');
            }
            const updatedFormData = await fetchWrapper.patch(
                `/userformdata/speciality-form/pto/approve/${formData._id}`,
                {
                    submissionType: 'completed',
                    policyCode,
                    companyID: provider?.payroll?.metadata?.companyID,
                    user: formData.user,
                }
            );
            handleUpdateForm(updatedFormData);
            toast.success('Pto request approved successfully');
            handleClose();
        } catch (err) {
            console.log('err', err);
            toast.error(err?.message || 'Failed to update');
        } finally {
            setIsBusy(false);
        }
    }, [formData, selectedAccrualPolicy, calculatedTime, provider]);

    return (
        <Modal show={Boolean(show)} onHide={handleClose} centered size={view ? 'xl' : 'lg'}>
            <Modal.Header closeButton>
                <Modal.Title>PTO {view ? 'Form' : 'Request'} data</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ overflowY: 'auto', height: '80vh' }}>
                {view ? (
                    <ViewForm formId={formData?._id} show={Boolean(show)} />
                ) : (
                    <Fragment>
                        {!isProviderOnboarded ? (
                            <div>Provider is not onboarded.</div>
                        ) : (
                            <div
                                className="d-flex"
                                css={css`
                                    flex-direction: column;
                                `}
                            >
                                <ListGroup
                                    css={css`
                                        .list-group-item {
                                            display: flex;
                                            justify-content: space-between;
                                        }
                                    `}
                                >
                                    <ListGroup.Item>
                                        <b>Employee:</b>
                                        {formData?.userData?.name}
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <b>Team:</b>
                                        {formData?.teamData?.name}
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <b>Status:</b>
                                        <div className={`text-center badge badge-pill badge-${getBadgeClass(status)}`}>
                                            {status}
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <b>FormType:</b>
                                        {formData?.specialityForm?.type}
                                    </ListGroup.Item>
                                    {isAuthored ? (
                                        <Fragment>
                                            <ListGroup.Item>
                                                <b>Requested Accrual Policy:</b>
                                                {`${formData?.specialityForm?.extraInfo?.policyCode} - ${formData?.specialityForm?.extraInfo?.policyName}`}
                                            </ListGroup.Item>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <ListGroup.Item>
                                                <b>Submitted Accrual Policy:</b>
                                                {`${selectedPolicyDuringSubmission?.policy_code || ''} - ${selectedPolicyDuringSubmission?.policy_name || ''}`}
                                            </ListGroup.Item>
                                            <AssignedAccrualPolicySelector
                                                selectAccrualPolicy={setSelectedAccrualPolicy}
                                                policyCode={selectedPolicyDuringSubmission?.policy_code || ''}
                                                userId={formData.user}
                                                provider={formData?.specialityForm?.data?.provider}
                                                employee={formData?.specialityForm?.data?.employee}
                                            />
                                        </Fragment>
                                    )}
                                    <hr />
                                    {isAuthored ? (
                                        <Fragment>
                                            <ListGroup.Item>
                                                <strong>Days Requested:</strong>
                                                <DateRangesTable formData={formData} />
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <b>Total Requested Hours:</b>
                                                <span className="mr-4">
                                                    {`${calculatedTime?.hours} hours ${calculatedTime?.minutes} minutes`}
                                                </span>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <b>Previous Accrual Balance:</b>
                                                {formData?.specialityForm?.extraInfo?.previousBalance?.toFixed(2)} hours
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <b>Requested Accrual Balance:</b>
                                                {formData?.specialityForm?.extraInfo?.requestedBalance?.toFixed(2)}{' '}
                                                hours
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <b>New Accrual Balance:</b>
                                                {formData?.specialityForm?.extraInfo?.newBalance?.toFixed(2)} hours
                                            </ListGroup.Item>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <ListGroup.Item>
                                                <strong>Days Requested:</strong>
                                                <DateRangesTable formData={formData} />
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <b>Total Requested Hours:</b>
                                                <span className="mr-4">
                                                    {`${calculatedTime?.hours} hours ${calculatedTime?.minutes} minutes`}
                                                </span>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <b>Requested Accrual Balance:</b>(
                                                {`${calculatedTime?.hours} hours ${calculatedTime?.minutes} minutes`}) ={' '}
                                                {(calculatedTime?.balance || 0)?.toFixed(2)} hours
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <b>Current Accrual Balance Of Selected Policy:</b>
                                                {(selectedAccrualPolicy?.accrual_balance || 0)?.toFixed(2)} hours
                                            </ListGroup.Item>
                                        </Fragment>
                                    )}
                                </ListGroup>
                                <div
                                    className="d-flex mt-4"
                                    css={css`
                                        display: flex;
                                        flex-direction: row;
                                        justify-content: flex-end;
                                    `}
                                >
                                    {isBusy && <LoadingSpiner size={42} />}
                                    <div>
                                        <Button variant="danger" onClick={handleReject} disabled={isBusy || isAuthored}>
                                            Reject
                                        </Button>
                                        <Button
                                            variant="primary"
                                            className="ml-2"
                                            onClick={handleApprove}
                                            disabled={!selectedAccrualPolicy?.policy_code || isBusy || isAuthored}
                                        >
                                            Approve
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Fragment>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default PtoModal;
