import { css } from 'twin.macro';
import InfiniteScroll from 'react-infinite-scroller';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { fetchWrapper } from '../../../../_helpers';
//components

// assets
import { VscLoading } from 'react-icons/vsc';

const FormRow = ({ formData, room, index }) => {
	const [roomIdsInForm, setFormIdsInChatRoom] = useState(formData.chatroom);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setFormIdsInChatRoom(formData.chatroom);
	}, [formData.chatroom]);
	const addFormToChatRoom = async (formId, chatroom) => {
		let formbody = {
			forms: [formId],
			rooms: [chatroom._id],
		};
		try {
			setIsLoading(true);
			await fetchWrapper.put('forms/assign', formbody).then((res) => {
				setIsLoading(false);
				toast.success('Form Added');
				setFormIdsInChatRoom([...roomIdsInForm, room.id]);
			});
		} catch (e) {
			setIsLoading(false);
			console.log(e);
		}
	};
	const removeFormFromChatRoom = async (chatroom, formId) => {
		let chatroomId = chatroom._id;
		let formbody = {
			chatroomId: chatroomId,
		};
		try {
			setIsLoading(room._id);
			fetchWrapper.put(`forms/${formId}/unassign`, formbody).then((res) => {
				toast.success('Form Removed Successfully');
				let newAssignedChatroomsArray = Object.assign([], roomIdsInForm);

				newAssignedChatroomsArray = newAssignedChatroomsArray.filter((item) => item !== room._id);
				setFormIdsInChatRoom(newAssignedChatroomsArray);

				setIsLoading(false);
			});
		} catch (e) {
			setIsLoading(false);
			console.log(e);
		}
	};

	const handleFormAddInChatRoom = () => {
		addFormToChatRoom(formData._id, room);
	};
	const formRowCss = css``;
	return (
		<tr css={formRowCss} key={formData._id}>
			<td>{index+1}</td>
			<td>
				{formData.name}
				{formData.archived ? <span className="badge badge-pill badge-danger ml-1">Archived</span> : null}
			</td>
			<td css={css`text-align:center;`}>
				{formData?.formPackage && formData?.formPackage.length ? (
					<div css={css`display: flex; flex-direction: column; gap: 3px; align-items: flex-start`}>
						{
							formData?.formPackage?.map(packageName => <span className='badge badge-warning'>{packageName}</span>)
						}
					</div>
				) : (
					<span>-</span>
				)}
			</td>
			<td css={css`text-align:center;`}>{formData?.noOfSubmission}</td>
			<td>
				{isLoading ? (
					<p>
						<VscLoading className="spin" />
					</p>
				) : roomIdsInForm?.includes(room.id) ? (
					<p onClick={() => removeFormFromChatRoom(room, formData._id)}>
						<span
							css={css`
								cursor: pointer;
								color: red;
								transform: scale(2);
							`}
						>
							Unassign
						</span>
					</p>
				) : (
					<p onClick={handleFormAddInChatRoom}>
						<span
							css={css`
								cursor: pointer;
								color: green;
								transform: scale(2);
							`}
						>
							Assign
						</span>
					</p>
				)}
			</td>
		</tr>
	);
};

const FormsEdit = ({ team, currRoom, archivedForm }) => {
	const [template, setTemplate] = useState([]);
	const [hasMore, setHasMore] = useState(true);
	const [totalPages, setTotalPages] = useState(1000000);

	const loadFunc = async (page) => {
		console.log(page, totalPages);
		if (page <= totalPages) {
			try {
				const formTemplates = await fetchWrapper.get(
					`forms/${team?._id ? `?team=${team.id || team._id}` : ''}&limit=10&page=${page}${archivedForm ? "&archivedForm=true" : ''}${currRoom?._id ? `&chatroom=${currRoom.id || currRoom._id}` : ''}`
				);
				let tempTemplate = [...template, ...formTemplates.results];
				console.log(formTemplates.totalResults > tempTemplate.length);
				setTemplate(tempTemplate);
				setTotalPages(formTemplates.totalPages);
				setHasMore(formTemplates.totalResults > tempTemplate.length);
			} catch (err) {
				console.log(err);
				setHasMore(false);
			}
		}
	};

	return (
		<div className="option-members-content">
			<div
				css={css`
					min-height: 200px;
					max-height: 400px;
					overflow: auto;
					> div {
						height: 100%;
					}
				`}
			>
				<InfiniteScroll
					pageStart={0}
					loadMore={loadFunc}
					hasMore={hasMore}
					loader={
						<div
							className="loader"
							key={0}
							css={css`
								height: 100%;
								display: flex;
								justify-content: center;
								align-items: center;
							`}
						>
							<VscLoading fontSize={34} className="spin" />
						</div>
					}
					useWindow={false}
				>
					{hasMore || (template && template.length > 0) ? (
						<Table>
							<thead css={css`position:sticky;top:0;background:white;padding:2px 0;`}>
								<tr css={css`text-align:center;`}>
									<th>S.N</th>
									<th css={css`text-align:left;`}>Form Name</th>
									<th css={css`text-align:center;`}>Form Plan</th>
									<th css={css`text-align:center;`}>Total Submissions</th>
									<th>Actions</th>
								</tr>
							</thead>
							<tbody>
							{
							template?.map((item, idx) => (
								<FormRow room={currRoom} formData={item} index={idx} key={item?._id || item?.id} />
							))
							}
							</tbody>
						</Table>
					
					) : (
						<div className="p-4 d-flex justify-content-center align-items-center">No Forms</div>
					)}
				</InfiniteScroll>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	userId: state.user.user.id,
	role: state.user.user.role,
	position: state.user.position,
});

export default connect(mapStateToProps, {})(FormsEdit);
