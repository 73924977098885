import { Image } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import { css } from 'twin.macro';
import { getInitials, getRandomColor } from '../../../_helpers/methods';
import { StyledSeenByDiv } from './styles';

export default function SeenByDiv({ seenBy, messageType }) {
	const [seenPeople, setSeenPeople] = useState(seenBy);
	const [luckyFive, setLuckyFive] = useState([]);
	const [restOfThem, setRestOfThem] = useState([]);

	useEffect(() => {
		if (seenBy) {
			let seenByLength = seenBy?.length;
			setSeenPeople([...seenBy]);
			setLuckyFive(seenBy.slice(0, 5));
			if (seenByLength >= 6) {
				setRestOfThem(seenBy.slice(5, seenByLength));
			}
		}
	}, [seenBy, seenPeople?.length]);

	return (
		<StyledSeenByDiv className={`${messageType}`}>
			{luckyFive.map((seer, idx) => {
				return (
					<div
						key={`${seer._id}_${idx}`}
						className={`tooltip`}
						css={css`
							background-color: ${getRandomColor(seer._id)}!important;
							color: white;
						`}
					>
						{seer.avatar ? (
							// seer.avatar.split('/')[2] === 'res.cloudinary.com' ? (
							<Image
								// cloudName={seer?.avatar?.split('/')[3]}
								// loading="lazy"
								// publicId={seer.avatar.split('/')[seer.avatar.split('/').length - 1]}
								src={seer?.avatar}
								// quality="auto"
								// fetchFormat="auto"
								className="avatar"
								width="15"
							></Image>
							// ) : (
							// 	<Image
							// 		cloudName={seer?.avatar?.split('/')[3]}
							// 		loading="lazy"
							// 		publicId={
							// 			seer.avatar.startsWith('http:')
							// 				? seer.avatar.split('http:').join('https:')
							// 				: seer.avatar
							// 		}
							// 		quality="auto"
							// 		fetchFormat="auto"
							// 		className="avatar"
							// 		width="15"
							// 	></Image>
							// )
						) : (
							<span>{getInitials(seer.name)}</span>
						)}
						<span className="tooltiptext">{seer.name}</span>
					</div>
				);
			})}
			{restOfThem.length ? (
				<div
					className={`tooltip ${messageType}`}
					css={css`
						background-color: #4bb165 !important;
						color: white;
						padding: 0.1em;
					`}
				>
					+{restOfThem.length}
					<span className="tooltiptext">
						{restOfThem.map((peps) => (
							<Fragment>
								<div>{peps.name}</div>
							</Fragment>
						))}
					</span>
				</div>
			) : null}
		</StyledSeenByDiv>
	);
}
