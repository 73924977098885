export const areAllArraysUnorderedEqual = (arrays) => {
	if (arrays.length < 2) {
		return { areIdentical: true, intersection: arrays[0] };
	}

	const referenceSet = new Set(arrays[0]);
	const referenceLength = Math.max(...arrays.map((arr) => new Set(arr).size));

	for (let i = 1; i < arrays.length; i++) {
		const currentSet = new Set(arrays[i]);
		referenceSet.forEach((item) => {
			if (!currentSet.has(item)) {
				referenceSet.delete(item);
			}
		});
	}

	return { areIdentical: referenceSet.size === referenceLength, intersection: [...referenceSet] };
};
