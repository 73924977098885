import { useEffect, useState } from 'react';
import { fetchWrapper } from '../../../../../../_helpers';
import AccrualPolicyList from './AccrualPolicyList';
import LoadingSpiner from '../../../../../../_elements/LoadingSpiner';
import { css } from 'twin.macro';

const AccrualPolicyContainer = ({ show, teamId, companyID }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [accrualPolicies, setAccrualPolicies] = useState([]);
    const handleCreateOrUpdate = (policy) => {
        const found = accrualPolicies.find((_policy) => _policy?.policy_code === policy?.policy_code);
        if (found) {
            setAccrualPolicies((prev) =>
                prev.map((_policy) =>
                    _policy?.policy_code === policy?.policy_code ? { ..._policy, ...policy } : _policy
                )
            );
        } else {
            const currentPolicies = accrualPolicies || [];
            currentPolicies.push(policy);
            setAccrualPolicies(currentPolicies);
        }
    };

    const fetchAccrualPolicies = async () => {
        try {
            if (!companyID) {
                return;
            }
            setIsLoading(true);
            const response = await fetchWrapper.get(`evv/payroll/zeal/provider/${companyID}/accrual-policy`);
            setAccrualPolicies(response || []);
        } catch (err) {
            if (err?.name === 'AbortError') return;
            console.log('err', err);
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        if (show) {
            fetchAccrualPolicies();
        }
    }, [show]);
    if (isLoading)
        return (
            <div
                css={css`
                    height: 400px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                `}
            >
                <LoadingSpiner size={42} />
            </div>
        );

    return (
        <AccrualPolicyList
            teamId={teamId}
            companyID={companyID}
            accrualPolicies={accrualPolicies}
            handleCreateOrUpdate={handleCreateOrUpdate}
            isLoading={isLoading}
        />
    );
};
export default AccrualPolicyContainer;
