import { css } from 'twin.macro';
import InfiniteScroll from 'react-infinite-scroller';
import { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import FileMessage from '../Common/FileMessage';
import FilesMessage from '../Common/Messages/FilesMessage';
import { getChatRoomFiles } from '../../../_redux/actions/ActionRoomForms';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import { CSSTransition } from 'react-transition-group';
import { VscLoading } from 'react-icons/vsc';
import { fetchWrapper } from '../../../_helpers';

const Files = ({ currRoom, userId, canDownload }) => {
	const [showFiles, setShowFiles] = useState(false);
	const [roomFiles, setRoomFiles] = useState([]);
	const [hasMore, setHasMore] = useState(true);
	const [totalPages, setTotalPages] = useState(1000000);

	const toggleShowFiles = () => {
		if (showFiles) {
			setHasMore(true);
			setRoomFiles([]);
			setTotalPages(10000);
		}
		setShowFiles((prev) => !prev);
	};

	const loadFunc = async (page) => {
		if (page <= totalPages) {
			if (currRoom) {
				let currRoomId = currRoom.id || currRoom._id;
				if (currRoomId) {
					const response = await fetchWrapper.get(
						`chatrooms/${currRoomId}/messages?limit=10&type=file&page=${page}`
					);
					let tempTemplate = [...roomFiles, ...response.results];
					setRoomFiles(tempTemplate);
					setHasMore(response.totalResults > tempTemplate.length);
					setTotalPages(response.totalPages);
				}
			} else if (userId) {
				const response = await fetchWrapper.get(
					`directMessages/${userId}?sortBy=createdAt:desc&limit=10&type=file&page=${page}`
				);
				let tempTemplate = [...roomFiles, ...response.results];
				setRoomFiles(tempTemplate);
				setHasMore(response.totalResults > tempTemplate.length);
				setTotalPages(response.totalPages);
			}
		}
	};
	return (
		<Fragment>
			<div
				className="options"
				css={css`
					background-color: ${showFiles ? `#E0F0EF` : `#F2F8F7`};
				`}
				onClick={toggleShowFiles}
			>
				<span className=" font-weight-bold m-0">Files</span>
				<p className=" ml-auto unselect">
					<CSSTransition in={showFiles} timeout={500} classNames="arrow">
						{!showFiles ? <TiArrowSortedUp /> : <TiArrowSortedDown />}
					</CSSTransition>
				</p>
			</div>

			{showFiles && (
				<Fragment>
					<div
						className="option-members-content"
						css={css`
							max-height: 400px;
							overflow-y: auto;
							> div {
								height: 100%;
								width: 100%;
								word-break: break-word;
							}
						`}
						key={1}
					>
						<InfiniteScroll
							pageStart={0}
							loadMore={loadFunc}
							initialLoad={true}
							hasMore={hasMore}
							loader={
								<div
									className="loader"
									key={0}
									css={css`
										height: 100%;
										display: flex;
										justify-content: center;
										align-items: center;
									`}
								>
									<VscLoading fontSize={34} className="spin" />
								</div>
							}
							useWindow={false}
						>
							{hasMore || (roomFiles && roomFiles.length > 0) ? (
								roomFiles.map((file, idx) => {
									if (file.file) {
										return (
											<div className="d-flex h5" key={file.createdAt + idx}>
												<FileMessage
													file={file.file}
													teamDetails={true}
													documentSettings={canDownload}
												/>
											</div>
										);
									} else if (file.files) {
										return (
											<div
												className="d-flex h5"
												key={file.createdAt + idx}
												css={css`
													> div {
														width: 100%;
													}
												`}
											>
												<FilesMessage
													files={file.files}
													teamDetails={true}
													canDownload={canDownload}
												/>
											</div>
										);
									}
									return null;
								})
							) : (
								<div className="option-content">No Shared Files</div>
							)}
						</InfiniteScroll>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

const mapStateToProps = (state) => ({
	role: state.user.user.role,
	chatRoomFiles: state.chatroomData.chatRoomFiles,
});

export default connect(mapStateToProps, { getChatRoomFiles })(Files);
