import React, { Fragment } from 'react';
import ImageModal from '../../../Features/Teams/ImageModal';
import { Form } from 'react-bootstrap';
import Linkify from 'react-linkify';
import { marginColor } from './Checkbox';
import { IMAGESTYPES } from '../../../../contants/enums';

const Image = ({ data, elementRef, isPreview, error }) => {
	return (
		<Fragment>
			{data.value ? (
				<Fragment>
					<h5>{data.fieldName}</h5>
					<ImageModal file={data.value} />
				</Fragment>
			) : (
				<Form.Group controlId="formGridFile">
					<label name={`${!isPreview ? `Image-${data._id}` : 'Email'}`}>
						<h5 className="warpIt">
							{data.fieldName}
							<span css={marginColor}>
								{(data.hasOwnProperty('required') ? data.required : true) && '*'}
							</span>
						</h5>

						{data.instruction && (
							<pre className="small text-muted warpIt preStyles">
								<Linkify
									componentDecorator={(decoratedHref, decoratedText, key) => (
										<a target="blank" href={decoratedHref} key={key}>
											{decoratedText}
										</a>
									)}
								>
									{data.instruction}
								</Linkify>
							</pre>
						)}
					</label>
					<br />
					<input
						accept={IMAGESTYPES}
						type="file"
						name={`${!isPreview ? `Image-${data._id}` : 'Email'}`}
						disabled={isPreview || data.value}
						ref={
							elementRef &&
							elementRef({
								required: data.hasOwnProperty('required') ? data.required : true,
							})
						}
					/>
					<Form.Text className="text-danger">
						{error && error.type === 'required' && 'This field is required'}
					</Form.Text>
				</Form.Group>
			)}
		</Fragment>
	);
};

export default Image;
