import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

export const slideAndFade = keyframes`
 	from{
		transform: translateX(0%);
		opacity: 1;
	}
	to{
		transform: translateX(100%);
		opacity: 0;
	}	
`;

export const slideIn = keyframes`
 	from{
		transform: translateX(-100%);
		opacity: 0;
	}
	to{
		transform: translateX(0%);
		opacity: 1;
	}	
`;
export const ActivityContainerStyled = styled('div')`
	padding: 1rem;
	width: 100%;
	margin-left: 8rem;
	background-color: #f5f5f5;
	height: 100vh;

	@media only screen and (max-width: 767px) {
		margin: 0;
		padding-bottom: 2rem;
	}
	.activityHeader {
		font-weight: 700 !important;
	}
	.activity {
		position: relative;
		margin: 10px;
		margin-top: 10px;
		height: calc(100% - 50px);
		border-radius: 5px;
		overflow-y: scroll;

		&::-webkit-scrollbar {
			display: none;
		}

		.actDate {
			height: 40px;
			width: 100%;
			display: flex;
			align-items: center;
			color: #757575;
			background-color: #f5f5f5;
		}

		.icon {
			height: 40px;
			width: 40px;
			display: flex;
			position: relative;
			justify-content: center;
			align-items: center;
			border-radius: 10px;
			border: 1px solid #50aeb0;
			padding: 10px;
			background-color: white;
			box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
		}
		.icon-arrow {
			position: absolute;
			right: -17.5px;
			top: 10px;
			color: #50aeb0;
		}

		.mention {
			.icon {
				border: 1px solid #e89842;
				color: #e89842;
			}
			.icon-arrow {
				color: #e89842;
			}
		}

		.reply {
			.icon {
				border: 1px solid #27637e;
				color: #27637e;
			}
			.icon-arrow {
				color: #27637e;
			}
		}

		.activityBar {
			margin: -10px 25px 20px 25px;
			width: 100%;
			min-height: 80px;
			padding: 10px;
			display: flex;
			justify-content: space-between;
			background-color: white;
			border-radius: 10px;
			border: 1px solid #878484;
			box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
		}

		.act-unread {
			transform: translateY(-5px);
			border: 3px solid #50aeb0;
			background-color: #ffffff;
			box-shadow: 1px 5px 2px 2px #ddd;
			position: relative;
		}
	}

	.BtnsDiv {
		position: absolute;
		display: flex;
		justify-content: center;
		align-content: center;
	}
	.clearBtn {
		border: none;
		background-color: #e0f0ef;
		padding: 10px;

		margin-right: 10px;
		border-radius: 5px;
		color: #50aeb0;
		:hover {
			transform: scale(1.1);
			color: #50aeb0;
			border-color: #50aeb0;
		}
	}
	.animation-sweepBtn {
		animation: animation-sweepBtn 1s forwards;
	}
	@keyframes animation-sweepBtn {
		0% {
			transform: rotate(0deg);
			border-color: #50aeb0;
			color: #50aeb0;
		}
		25% {
			border-color: #50aeb0;
			color: #50aeb0;
			transform: rotate(-90deg);
		}
		50% {
			transform: rotate(0deg);
			border-color: #50aeb0;
			color: #50aeb0;
		}
		75% {
			transform: rotate(-90deg);
			border-color: #50aeb0;
			color: #50aeb0;
		}
		100% {
			transform: rotate(0deg);
			color: #333;
		}
	}
	.animation-spinBtn {
		animation: animation-spinBtn 1s forwards;
	}
	@keyframes animation-spinBtn {
		0% {
			transform: rotate(0deg);
			border-color: #50aeb0;
			color: #50aeb0;
		}
		90% {
			transform: rotate(-360deg);
			border-color: #50aeb0;
			color: #50aeb0;
		}
		100% {
			transform: rotate(-360deg);
			color: #333;
		}
	}
	.animation-scaleUpDown {
		animation: animation-scaleUpDown 1s forwards;
	}
	@keyframes animation-scaleUpDown {
		0% {
			transform: scale(1);
			border-color: #50aeb0;
			color: #50aeb0;
		}
		40% {
			transform: scale(1.2);
			border-color: #50aeb0;
			color: #50aeb0;
		}
		100% {
			transform: scale(1);
			color: #333;
		}
	}
	.activityDetails {
		display: flex;
		justify-content: space-between;
	}
	.activityStatusIndicatorDiv {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	#tooltip-right,
	#tooltip-left {
		font-size: 1.6vmin;
		background-color: rgba(3, 4, 5, 0.4);
	}
	.bolder {
		font-weight: bolder;
	}
	.border-highlight {
		border-color: #50aeb0;
		border-width: 2px;
	}

	.task_element-enter {
		opacity: 0;
		transform: translate3d(0, 0, 0);
	}
	.task_element-enter-active {
		opacity: 1;
		animation: ${slideIn} 1s ease;
	}
	.task_element-exit {
		opacity: 1;
	}
	.task_element-exit-active {
		animation: ${slideAndFade} 1s ease forwards;
	}
`;
