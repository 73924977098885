import { getUniqueObjects } from '../../_helpers';
import * as ActionTypes from '../actions/ActionTypes';

export default function SubChannels(
	state = {
		isLoading: true,
		errorMessage: null,
		subChannels: [],
		subChannnelsRef: {},
		subChannelMembers: [],
		totalSubChannels: 0
		// teamRooms: [],
		// clientRooms: {},
	},
	action
) {
	switch (action.type) {
		case ActionTypes.SET_SUB_CHANNELS:
			return { ...state, isLoading: false, errorMessage: null, subChannels: getUniqueObjects([...state.subChannels, ...(action?.payload?.subChannels || [])]), totalSubChannels: action.payload.totalSubChannels };
		case ActionTypes.SET_SUB_CHANNEL_MEMBER:
			return { ...state, subChannelMembers: [...action.payload.results] };
		case ActionTypes.SET_NEW_SUB_CHANNEL_MEMBER:
			return { ...state, subChannelMembers: [...action.payload] };
		case ActionTypes.REMOVE_SUB_CHANNEL_MEMBER:
			const tempMembers = [...state.subChannelMembers];
			const memberIndex = tempMembers.findIndex((member) => member._id === action.payload);
			tempMembers.splice(memberIndex, 1);
			return { ...state, subChannelMembers: tempMembers };
		default:
			return state;
	}
}
