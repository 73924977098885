export const colorData = [
    '#F8C753b9',
    '#eb3939b9',
    '#9e00b975',
    '#ff5722b2',
    '#cddc39b2',
    '#44D0BFb9',
    '#42b3ffb9',
    '#8bc34a',
    // indirect visit
    '#778dbf99',
];

export const statusData = {
    draft: 'brown',
    complete: 'green',
    verified: 'purple',
    payment_pending: 'blue',
    payment_processed: 'olive',
};
