import React, { useState } from 'react';
import styled from '@emotion/styled';
import Participant from '../Participant';

const StyledGridContainer = styled.div`
	--grid-size: ${(props) => (props.isPinned ? 1 : props.gridCol)};
	--grid-col-size: ${(props) => (props.isPinned ? 1 : props.gridColSize)};
	--grid-row-size: ${(props) => (props.isPinned ? 1 : props.gridRowSize)};
	display: grid;
	grid-template-columns: ${(props) => (props.isPinned ? '1fr 0px' : 'repeat(var(--grid-size), 1fr)')};
	grid-gap: ${(props) => (props.isPinned ? '' : '20px')};
	height: 85vh;
	padding: 10px;
	grid-template-areas: ${(props) => (props.isPinned ? "'pinned_participant none'" : '')};
	@media (max-width: 800px) {
		grid-template-columns: ${(props) => (props.isPinned ? '1fr' : 'repeat(var(--grid-col-size), 1fr)')};
		grid-template-rows: ${(props) => (props.isPinned ? '1fr' : 'repeat(var(--grid-row-size), 1fr)')};
	}
`;

function GridLayout({
	participants,
	room,
	user,
	dominantSpeaker,
	audio,
	video,
	sharingScreen,
	participantInfo,
	localScreenRef,
	localVideoRef,
}) {
	const totalParticipants = 1 + participants?.length;

	let gridCol = totalParticipants === 1 ? 1 : totalParticipants <= 4 ? 2 : 4;
	const gridColSize = totalParticipants <= 4 ? 1 : 2;
	let gridRowSize = totalParticipants <= 4 ? totalParticipants : Math.ceil(totalParticipants / 2);
	const [pinnedParticipant, setPinnedParticipant] = useState('');
	const handlePinnedParticipant = (participantId) => {
		setPinnedParticipant(participantId);
	};

	const remoteParticipants = participants.map((participant) => {
		return (
			<Participant
				key={participant.sid}
				participant={participant}
				dominantSpeaker={dominantSpeaker && dominantSpeaker.sid}
				participantInfo={participantInfo}
				pinnedParticipant={pinnedParticipant}
				handlePinnedParticipant={handlePinnedParticipant}
			/>
		);
	});
	return (
		<StyledGridContainer
			gridCol={gridCol}
			gridColSize={gridColSize}
			gridRowSize={gridRowSize}
			isPinned={pinnedParticipant.length}
		>
			{room ? (
				<Participant
					localUser={user}
					key={room.localParticipant.sid}
					participant={room.localParticipant}
					dominantSpeaker={dominantSpeaker && dominantSpeaker.sid}
					localParticipant={true}
					audio={audio}
					video={video}
					sharingScreen={sharingScreen}
					participantInfo={participantInfo}
					localScreenRef={localScreenRef}
					localVideoRef={localVideoRef}
					pinnedParticipant={pinnedParticipant}
					handlePinnedParticipant={handlePinnedParticipant}
				/>
			) : null}
			{remoteParticipants}
		</StyledGridContainer>
	);
}

export default GridLayout;
