import React, { Fragment, useState } from 'react';
import { css } from 'twin.macro';
import { Form, Image } from 'react-bootstrap';
import { VscLoading } from 'react-icons/vsc';
import ReactSelectComponent from '../../users/ReactSelectComponent';

// assests
import { RiCameraLine } from 'react-icons/ri';
import noImg from '../../../../assets/no-img.png';
// import { Image } from 'cloudinary-react';

function UserSelection({ user, userLoading, formType, selectedOption, setSelectedOption, employeeNameError, teamId }) {
    return (
        <div className={`${formType === 'Edit' ? 'abs_hidden' : ''}`}>
            <Form.Group className="d-flex justify-content-center">
                {userLoading ? (
                    <div
                        css={css`
                            width: 150px;
                            height: 150px;
                            border-radius: 1000px;
                            display: flex;
                            flex-direction: column;
                            border: 3px dashed black;
                            justify-content: center;
                            align-items: center;
                        `}
                    >
                        <span>
                            <VscLoading fontSize="34" className="spin" />
                        </span>
                    </div>
                ) : user ? (
                    user?.avatar ? (
                        // user.avatar.split('/')[2] === 'res.cloudinary.com' ? (
                        <Image
                            // cloudName={user?.avatar?.split('/')[3]}
                            // loading="lazy"
                            // publicId={user.avatar.split('/')[user.avatar.split('/').length - 1]}
                            // crop="fill"
                            // quality="auto"
                            // fetchFormat="auto"
                            src={user.avatar}
                            className="avatar"
                            width="150"
                            css={css`
                                width: 150px;
                                height: 150px;
                                border-radius: 1000px;
                                object-fit: cover;
                            `}
                        ></Image>
                    ) : (
                        // ) : (
                        // 	<Image
                        // 		cloudName={user?.avatar?.split('/')[3]}
                        // 		loading="lazy"
                        // 		publicId={
                        // 			user.avatar.startsWith('http:')
                        // 				? user.avatar.split('http:').join('https:')
                        // 				: user.avatar
                        // 		}
                        // 		css={css`
                        // 			width: 150px;
                        // 			height: 150px;
                        // 			border-radius: 1000px;
                        // 			object-fit: cover;
                        // 		`}
                        // 		quality="auto"
                        // 		fetchFormat="auto"
                        // 		className="avatar"
                        // 		width="150"
                        // 	></Image>
                        // )
                        <img
                            src={noImg}
                            alt={user ? user.name : 'user'}
                            css={css`
                                width: 150px;
                                height: 150px;
                                border-radius: 1000px;
                                object-fit: cover;
                            `}
                        />
                    )
                ) : (
                    <div
                        css={css`
                            width: 150px;
                            height: 150px;
                            border-radius: 1000px;
                            display: flex;
                            flex-direction: column;
                            border: 3px dashed black;
                            justify-content: center;
                            align-items: center;
                        `}
                    >
                        <Fragment>
                            <RiCameraLine className="camIcon" />
                            <span className="text-center">Select Employee Name</span>
                        </Fragment>
                    </div>
                )}
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
                <Form.Label>
                    Employee Name <span className="red">*</span>
                </Form.Label>
                <ReactSelectComponent
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    placeholder="eg: John Doe"
                    assignedTo={
                        selectedOption && selectedOption.value
                        // (selectedOption && selectedOption.value) || (user && (employee.user?.id || employee.user))
                    }
                    isDisabled={formType === 'View'}
                    teamId={teamId}
                />
                <p className="text-danger">{employeeNameError}</p>
            </Form.Group>
        </div>
    );
}

export default UserSelection;
