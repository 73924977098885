import { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { getAllRooms } from '../../../_redux/actions/ActionMessage';
import Select from 'react-select';
import { getTeamIdFromURL } from '../../../_helpers';

const ReactChannelSelectComponent = ({
	selectedOption,
	onRoomSelect,
	assignedChannel,
	getAllRooms,
	rooms,
	teams,
	placeholder,
	disabled = false,
	teamIds,
}) => {
	const [option, setOption] = useState([]);
	//#TODO Decide if better to fetch from URL or from parent component as props, also clearly define weather it is the admin or normal user
	const [defaultValue, setDefaultValue] = useState(selectedOption);

	useEffect(() => {
		if (Object.keys(rooms).length === 0) {
			getAllRooms();
		}
		if (!teamIds) {
			teamIds = [getTeamIdFromURL()];
		}
	}, []);

	useEffect(() => {
		const allRooms = Object.values(rooms)
			.map((teamRoom) => teamRoom.rooms)
			.flat();
		if (allRooms.length && assignedChannel) {
			try {
				if (Array.isArray(assignedChannel)) {
					assignedChannel = assignedChannel[0];
				}
				if (typeof assignedChannel === 'string') {
					let selectedRoom = allRooms.filter((room) => assignedChannel === room._id);
					const groupby = selectedRoom.map((room) => ({
						label: room.title,
						value: room.id,
					}));
					setDefaultValue(groupby);
				} else {
					setDefaultValue({
						label: assignedChannel.label || assignedChannel.title,
						value: assignedChannel.value || assignedChannel.id,
					});
				}
			} catch (err) {
				console.log(err);
			}
		}
	}, [rooms, assignedChannel]);

	useEffect(() => {
		const fetchData = async () => {
			//here grouby first sorts by team name then it filters room with matching team and sorts by title and it maps label set to title of room and value set to room id
			//label gives team name and option gives team specific channels and using map gives overall all team name and team specific channels
			const optionTeams = teamIds?.some((value) => value)
				? teams.filter((team) => teamIds.includes(team._id))
				: [];
			const groupby = optionTeams
				?.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
				.map((team) => ({
					label: team.name,
					options: rooms[team.id]?.rooms
						.sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1))
						.map((room) => ({ label: room.title, value: room.id, type: room.type })),
				}));
			setOption(groupby);
		};
		if (teamIds && rooms) {
			fetchData();
		}
	}, [teamIds, rooms]);

	const onSelectChange = (selectedOption) => {
		if (selectedOption && onRoomSelect) {
			onRoomSelect(selectedOption);
		}
	};

	return (
		<Select
			name="channel"
			value={selectedOption || defaultValue}
			options={option}
			onChange={onSelectChange}
			isDisabled={disabled || option.length === 0}
			// isDisabled={disabled}
			placeholder={placeholder}
		/>
	);
};

const mapStateToProps = (state) => ({
	formElements: state.formData.formElements,
	organizations: state.organizations.organizations,
	teams: state.teams.teams,
	rooms: state.rooms.teamRooms,
});

const mapDispatchToProps = { getAllRooms };

export default connect(mapStateToProps, mapDispatchToProps)(ReactChannelSelectComponent);
