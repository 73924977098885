import React from 'react';
import { css } from 'twin.macro';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import { Body } from '../Provider/styles';
import WorklioForm from './WorklioForm';
import {toast} from 'react-toastify';

import { MdAddCard } from "react-icons/md";
import { ToolTip } from '../../../../_elements/ToolTip';
import { fetchWrapper } from '../../../../_helpers';

const WorklioTaskSetup = ({ companyId, employeeId, type }) => {
	const [showModal, setShowModal] = useState(false);
	const [fetchingForm, setFetchingForm] = useState(false);
	const [isFormSubmitting, setIsFormSubmitting] = useState(false);
	const [worklioFormStructure, setWorklioFormStructure] = useState({});

	const handleModalShow = () => {
		fetchWorklioTaskFormStructure()
		setShowModal(true);
	};

	const fetchWorklioTaskFormStructure = async () => {
		try {
			setFetchingForm(true)
			const response = await fetchWrapper.get(`evv/payroll/worklio-taxsetup/${companyId}${type==="employee" ? `/${employeeId}` : "" }`);
			setWorklioFormStructure(response)
		} catch (e) {
			console.log('get worklio structure',e)
		}
		finally{
			setFetchingForm(false)
		}
	};

    const CreateWorlio = async (newWorklio)  => {
		try {
			const response = await fetchWrapper.post(`evv/payroll/worklio-taxsetup/${companyId}${type==="employee" ? `/${employeeId}` : "" }`, newWorklio);
			console.log("create worklio response",response);
			toast.success("Form Submitted Successfully")
			return true;
		} catch (error) {
			console.log(error);
			toast.error(error)
			return false;
		}
	};

	const onSubmit = async (data) => {
		try {
			setIsFormSubmitting(true)
			await CreateWorlio(data);
			setShowModal(false);
		} catch (err) {
			console.log(err);
		}
		finally{
			setIsFormSubmitting(false)
		}
	};

	return (
		<>
		<ToolTip text={'Worklio Tax Setup'} side={'top'} cssStyles={"top:-70% !important;"}>
			<MdAddCard className="icon edit" onClick={handleModalShow} />
		</ToolTip>
			<Modal show={showModal} backdropClassName="bg-dark" onHide={() => setShowModal(false)} centered>
				<Modal.Header closeButton>
					<div className="header d-flex flex-row">
						<div>
							<h4
								css={css`
								margin: 0;
							`}
							>
								Worklio Tax Setup
							</h4>
						</div>
						<div className='mt-1 ml-4'>
							{worklioFormStructure?.isComplete ? <span className='badge badge-success'>Completed</span> : <span className='badge badge-danger' >Not Completed</span>}</div>
					</div>
				</Modal.Header>
				<Modal.Body className="modal-body">
					<Body>
						<WorklioForm
							onSubmit={onSubmit}
							formType="Create"
							isFormSubmitting={isFormSubmitting}
							formStructure={worklioFormStructure}
							fetchingForm={fetchingForm}
						/>
					</Body>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default WorklioTaskSetup;
