import { css } from 'twin.macro';
import { useEffect, Fragment, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

// helpers
import { formatAMPM } from '../../../_helpers';

//redux
import { connect } from 'react-redux';
import { clearChatableUsers, getChatableUser } from '../../../_redux/actions/ActionUser';

import ChatListSkeleton from '../Layouts/ChatListSkeleton';
import SearchInput from '../Common/SearchInput';
import { ChatListStyled } from './styles/ChatListStyled';
import AvatarUserPanel from '../Common/AvatarUserPanel';
import { HiChevronDoubleUp } from 'react-icons/hi';
import { listWrapper, searchInput, topSection } from '../Teams/styles/TeamListStyles';

import { clearSearchMessages, getRecentChats } from '../../../_redux/actions/ActionMessage';
import { AiOutlineClose } from 'react-icons/ai';
import InfiniteScroll from 'react-infinite-scroller';
import { VscLoading } from 'react-icons/vsc';

const ChatList = ({
	chatableUser,
	totalChatableUser,
	chatUsers,
	getChatableUser,
	clearChatableUsers,
	isLoading,
	currUser,
	userId,
	mobileSidebar,
	setMobileSidebar,
	clearSearchMessages,
	newMsg,
	curChatUsers,
	toggleChat,
	getRecentChats,
}) => {
	const [search, setSearch] = useState('');
	const [filteredUser, setFilteredUser] = useState([]);
	const [index, setIndex] = useState(null);
	const [loading, setLoading] = useState(false);
	// const curChatUser = curChatUsers

	const history = useHistory();

	const handleClick = useCallback(
		(user) => {
			clearSearchMessages();
			setIndex(user.id);
			history.push(`/chat/${user.id}`);
		},
		[history, clearSearchMessages]
	);

	const isFirstRun = useRef(true);
	const inputRef = useRef();

	useEffect(() => {
		if (chatableUser.length === 0 && !search) {
			getChatableUser(null, 1);
		}
	}, [search, chatableUser.length]);

	useEffect(() => {
		let filteredChat = [...chatUsers];

		filteredChat = filteredChat.filter((user) => user.user.name.toLowerCase().indexOf(search.toLowerCase()) !== -1);

		setFilteredUser(filteredChat);

		if (isFirstRun.current) {
			if (!userId) {
				if (chatUsers.length) {
					handleClick(chatUsers[0].user);
					isFirstRun.current = false;
				}
			} else {
				handleClick({ id: userId });
				isFirstRun.current = false;
			}

			return;
		}
	}, [search, chatUsers, handleClick, userId]);

	useEffect(() => {
		const timer = setTimeout(async () => {
			if (search && search === inputRef.current.value) {
				setLoading(true);
				await clearChatableUsers();
				await getChatableUser(search, 1);
				setLoading(false);
			} else {
				await clearChatableUsers();
				await getRecentChats();
			}
		}, 500);

		return () => clearTimeout(timer);
	}, [search, inputRef]);

	const clearSearch = async () => {
		setSearch('');
		await clearChatableUsers();
		inputRef.current.value = '';
	};

	const loadFunc = (page) => {
		if (search && search === inputRef.current.value) {
			getChatableUser(search, page);
		} else {
			getChatableUser(null, page);
		}
	};
	return (
		<div css={listWrapper} id="style-1">
			<div>
				<div css={topSection}>
					<div
						className="d-flex flex-row"
						css={css`
							overflow: hidden;
						`}
					>
						<SearchInput
							ref={inputRef}
							setSearch={setSearch}
							styleProps={searchInput}
							placeholder="Search User"
						/>
						{search && (
							<button
								css={css`
									outline: none;
									color: #191919;
									padding: 0 1rem;
									display: flex;
									justify-content: center;
									align-items: center;
									font-size: 24px;
									border: none;
									background: white;
									svg {
										transition: transform 250ms;
									}
									:hover > svg {
										transform: scale(1.2);
									}
								`}
								onClick={() => {
									clearSearch();
								}}
							>
								<AiOutlineClose />
							</button>
						)}
					</div>
				</div>
				<div className="d-flex flex-column p-0">
					{isLoading ? (
						<div
							css={css`
								width: 100%;
								overflow: hidden;
							`}
						>
							<ChatListSkeleton />
						</div>
					) : (
						<ChatListStyled>
							<InfiniteScroll
								key={search}
								pageStart={1}
								loadMore={loadFunc}
								hasMore={totalChatableUser > chatableUser.length}
								loader={
									<div
										className="loader"
										key={'loader-user-infinite'}
										css={css`
											height: 100%;
											display: flex;
											justify-content: center;
											align-items: center;
										`}
									>
										<VscLoading fontSize={34} className="spin" />
									</div>
								}
								useWindow={false}
							>
								{filteredUser.map((user, i) => {
									let displayText;
									if (user.data) {
										try {
											const { text } = JSON.parse(user.data);
											displayText = text;
										} catch (e) {
											displayText = user.data;
										}
									} else {
										displayText = <i>Contains file </i>;
									}

									const time = formatAMPM(new Date(user.createdAt));
									return (
										<div
											key={user.user.id}
											className={` chat-list-item  ${index === user.user.id ? 'active' : ''}`}
											onClick={() => {
												if (index !== user.user.id) {
													handleClick(user.user);
													toggleChat(true);
												}
											}}
										>
											<div className="avatar-details">
												<AvatarUserPanel item={user.user} indicator={true} />
												<div className="details">
													<div
														className="font-weight-bold"
														css={css`
															font-size: 15px;
														`}
													>
														{user.user.name}
													</div>
													<div className="d-flex">
														{user.sent ? <span className="mr-2">You:</span> : null}

														<div
															className={`${
																user.notSeen && !user.sent
																	? `font-weight-bold`
																	: `text-muted`
															}`}
														>
															{displayText
																? displayText
																: user.type === 'file'
																? 'Shared file'
																: 'Sent a message'}
														</div>
													</div>
												</div>
											</div>
											<span className="chat_date text-muted text-uppercase">
												{time.time}
												<span className="ml-2">{time.type}</span>
											</span>
										</div>
									);
								})}
								{loading ? (
									<div
										className="loader"
										key={'loader-user-chatable'}
										css={css`
											height: 100%;
											display: flex;
											justify-content: center;
											align-items: center;
										`}
									>
										<VscLoading fontSize={34} className="spin" />
									</div>
								) : chatableUser ? (
									<Fragment>
										{chatableUser.length ? (
											<Fragment>
												{chatableUser
													.filter(
														(user) =>
															user.id !== currUser.id &&
															!filteredUser.map((user) => user.user.id).includes(user.id)
													)
													.map((user, chatableIndex) =>
														<div
															key={chatableIndex}
															className={`chat-list-item  ${
																chatableIndex === user.id ? 'active' : ''
															}`}
															onClick={() => chatableIndex !== user.id && handleClick(user)}
														>
															<div className="avatar-details">
																<AvatarUserPanel item={user} indicator={true} />
																<div className="details">
																	<div
																		className="font-weight-bold"
																		css={css`
																			font-size: 15px;
																		`}
																	>
																		{user.name}
																	</div>
																	<div className="d-flex">
																		<div className={`text-muted`}>
																			Start Conversation
																		</div>
																	</div>
																</div>
															</div>
														</div>
													)}
											</Fragment>
										) : (
											<span
												css={css`
													font-size: 1.8rem;
													width: 100%;
													text-align: center;
													display: inline-block;
													margin-top: 2rem;
												`}
											>
												User Not Found
											</span>
										)}
									</Fragment>
								) : null}
							</InfiniteScroll>
						</ChatListStyled>
					)}
				</div>
			</div>
			<div className="sidebar-toggle" onClick={() => setMobileSidebar(!mobileSidebar)}>
				{!mobileSidebar && <HiChevronDoubleUp />}
			</div>
		</div>
	);
};
const mapStateToProps = (state) => ({
	currUser: state.user.user,
	chatableUser: state.user.chatableUser,
	chatUsers: state.chats.recentChats,
	totalChatableUser: state.user.totalChatableUser,
	isLoading: state.chats.isLoading,
});

ChatList.propTypes = {
	chatUsers: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, { getChatableUser, clearChatableUsers, clearSearchMessages, getRecentChats })(
	ChatList
);
