import React, { useState, Fragment } from 'react';
import { Modal, Form, Col, Button } from 'react-bootstrap';
import { BsEye } from 'react-icons/bs';
import { BsGrid, BsList } from 'react-icons/bs';
import Select from 'react-select';
import dayjs from 'dayjs';
import { Tab, Tabs } from 'react-bootstrap';

//components
import PreviewEventModal from '../Tasks/PreviewEventModal';
import { AdminTable } from '../reusableStyles';
import { ActionStyles } from '../reusableStyles';
import ActivityProgressBar from './ActivityProgressBar';

// import { EventPlannerCalendar } from './EventPlannerCalendar';
import EventPlannerCalendar from './EventPlannerCalendar';
import EventAssignedChannel from './EventAssignedChannel';
import { fetchWrapper, getTeamIdFromURL } from '../../../_helpers';

import TaskSelectFilter from '../TaskSelectFilter';
import TeamSelectFilter from '../TeamSelectFilter';

const styles = {
	multiValue: (base, state) => {
		return { ...base, backgroundColor: 'gray' };
	},
	multiValueLabel: (base, state) => {
		return { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 };
	},
	multiValueRemove: (base, state) => {
		return { ...base, display: 'none' };
	},
};
export default function PackagePreviewModal({ packageData, show, handleClose, title = '' }) {
	const [key, setKey] = useState('event_list');

	const [showModal, setShowModal] = useState(null);

	const handleEventClose = () => {
		setShowModal(null);
	};

	const [selectedTasks, setSelectedTasks] = useState(packageData?.taskData?.sort((a,b)=>new Date(a?.latestDatePointer || a?.date) - new Date(b?.latestDatePointer || b?.date))?.map((task, index) => {
		let taskOption = {
			label: task.title,
			value: task._id || task.id || (index + 1),
			start: task.date,
			end: task.dueDate,
			...task,
			_id: task._id || task.id || (index + 1),
		};
		return taskOption;
	}));
	const selectedTeams = packageData?.teamData?.map((team) => {
		let teamOption = {
			label: team.name,
			value: team.id,
		};
		return teamOption;
	});

	const [fetchedTasks, setFetchedTasks] = useState([]);

	const handleShowEvent = async (id) => {
		setShowModal(id);
		if (!id || fetchedTasks.includes(id) || title === 'template') {
			return;
		}
		const response = await fetchWrapper.get(`/task/${id}`);
		setSelectedTasks(prev => prev.map(task => task._id === response._id ? { ...task, ...response } : task));
		setFetchedTasks(prev => [...prev, id]);
	};

	const teamId = getTeamIdFromURL();
	return (
		<Fragment>
			<Modal show={show} onHide={handleClose} size="xl">
				<Modal.Header className="h2 font-weight-bold d-flex justify-content-center">View Plan { title === 'template' ? '( Template )' : null}</Modal.Header>
				<Modal.Body className="m-2">
					<Form className="w-90 m-auto">
						<Form.Row>
							<Form.Group as={Col} md={12}>
								<Form.Label>Plan Name</Form.Label>
								<Form.Control type="text" value={packageData.name} name="packageName" readOnly />
							</Form.Group>

							<Form.Group as={Col} md={12}>
								<Form.Label>Events</Form.Label>
								<TaskSelectFilter disabled={true} disableTaskFetch={true} selectedOption={selectedTasks} isMulti  styles={styles}/>
								<Tabs
									id="controlled-tab-example"
									activeKey={key}
									onSelect={(k) => setKey(k)}
									className="mb-3"
								>
									<Tab eventKey="event_list" title={<BsList />}>
										{key === 'event_list' && (
											<AdminTable className="mb-5 w-100">
												<thead>
													<tr>
														<th>S.N.</th>
														<th>Event Name</th>
														<th>Date</th>
														<th>Channel</th>
														<th>Actions</th>
													</tr>
												</thead>
												<tbody>
													{selectedTasks?.map((event, index) => (
														<tr key={event._id}>
															<td className="text-left p-1">{index + 1}</td>
															<td className=" text-left p-1 pl-4">{event.title}</td>
															<td className="text-left p-1">
																{/* {dayjs(event.date).format('dddd h:mm A')} */}
																{dayjs(event.latestDatePointer || event.date).format(
																	'MMMM D, YYYY h:mm A'
																)}
															</td>
															<td className="text-left p-1">
																{title === 'template' ? '-' : (event?.assignedChannelInfo?.length ? event?.assignedChannelInfo[0]?.title : '--') }
															</td>
															<td className=" text-left p-1">
																<ActionStyles>
																	<div>
																		<BsEye
																			onClick={() => handleShowEvent(event._id)}
																			className="icon view"
																		/>
																	</div>
																</ActionStyles>
															</td>
														</tr>
													))}
												</tbody>
											</AdminTable>
										)}
									</Tab>
									<Tab eventKey="event_grid" title={<BsGrid />}>
										{key === 'event_grid' && (
											<EventPlannerCalendar events={selectedTasks} handleShowEvent={handleShowEvent} />
										)}
									</Tab>
								</Tabs>
							</Form.Group>

							{showModal ? (
								<PreviewEventModal
									data={selectedTasks?.find(({ _id }) => showModal === _id)}
									handleClose={handleEventClose}
								// getAllTasks={getAllTasks}
								/>
							) : null}

							{teamId ? null : (
								<Form.Group as={Col} md={12}>
									<Form.Label>Teams</Form.Label>
									<TeamSelectFilter selectedTeamOption={selectedTeams} isMulti disabled={true} styles={styles}/>
								</Form.Group>
							)}
						</Form.Row>
					</Form>
					<ActivityProgressBar selectedTasks={selectedTasks} assignedUsers={packageData.assignedUser} />
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</Fragment>
	);
}
