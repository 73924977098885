import React from 'react'; // useRef
import { css } from 'twin.macro';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useMemo, useState, useCallback } from 'react';

// helpers
import { getChannelIdFromSettingsURL, getInitials, getRandomColor } from '../../../../_helpers';

// redux
import { connect } from 'react-redux';
import { GetClients } from '../../../../_redux/actions/ActionEVV';
import { getAllUser } from '../../../../_redux/actions/ActionUser';

//costume hooks
import { useSortData } from '../../useSortData';

// components
import ClientList from './UserClientList';
import Pagination from '../../Pagination';
// import CreateClient from './CreateClient';
import EvvSearchModalComponent from '../EvvSearchModal';
import { Button, Nav } from 'react-bootstrap';

import CreateClient from './CreateClient';

const UserClient = ({ getAllUser, GetClients, isLoading, clients, teams, teamId, channelDetails }) => {
    const history = useHistory();
    const location = useLocation();
    const queryPage = useLocation().search.match(/page=([0-9]+)/, '');
    const isTeamSettings = !!useLocation().pathname.match(/user-evv/);
    const isChannelSettings = getChannelIdFromSettingsURL();
    const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
    // const teamId = getTeamIdFromURL();
    const [firstName, setFirstName] = useState(channelDetails?.id ? channelDetails?.client?.firstname : '');
    const [lastName, setLastName] = useState(channelDetails?.id ? channelDetails?.client?.lastname : '');
    const [userEmployee, setUserEmployee] = useState();
    const [provider, setProvider] = useState({});
    const [clientState, setClientState] = useState('active');

    const handleNameChange = (event) => {
        if (event.target.name === 'firstName') {
            setFirstName(event.target.value);
        } else if (event.target.name === 'lastName') {
            setLastName(event.target.value);
        }
    };

    const handleUserEmployeeSelect = (selectedUser) => {
        setUserEmployee(selectedUser);
    };

    const handleProviderSelect = (selectedProvider) => {
        setProvider(selectedProvider);
    };

    const handleSearch = useCallback(() => {
        GetClients({
            firstName,
            lastName,
            user: userEmployee?.id,
            provider: provider?.value,
            state: clientState,
            team: teamId,
        });
        const targetPath = '/user-evv?page=1';

        if (location.pathname + location.search !== targetPath) {
            history.push(targetPath);
        }
    }, [firstName, lastName, userEmployee?.id, provider?.value, teamId]);

    const handleClearAll = () => {
        setFirstName(null);
        setLastName(null);
        setUserEmployee(null);
        setProvider(null);
    };

    const { sortConfig } = useSortData(getAllUser, {
        key: 'name',
        direction: 'asc',
    });
    const onPageChanged = (data) => {
        if (currentPage !== data.currentPage && data.currentPage > 0) {
            history.push(`/user-evv?page=${data.currentPage}`);
            GetClients({
                firstName,
                lastName,
                user: userEmployee?.id,
                provider: provider?.value,
                page: data.currentPage,
                state: clientState,
                team: teamId,
            });
        }
    };

    const clientsList = useMemo(
        () =>
            clients &&
            clients?.clients?.map((client) => {
                client.initials = getInitials(`${client.firstName} ${client.lastName}`);
                client.color = getRandomColor(client.id);
                return client;
            }),
        [clients]
    );
    useEffect(() => {
        if (!isTeamSettings) {
            return null;
        }
        const team = teams.find((team) => team.id === teamId);
        setProvider({
            value: team?.provider?._id,
            label: team?.provider?.agencyName,
            providerId: team?.provider?._id,
            teamId: team?.id,
            agencyName: team?.provider?.agencyName,
            team: team?.provider?._id,
            state: team?.provider?.state,
            evv: team?.provider?.evv,
        });
    }, [teams, isTeamSettings]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    useEffect(() => {
        GetClients({
            firstName,
            lastName,
            user: userEmployee?.id,
            provider: provider?.value,
            page: 1,
            state: clientState,
            team: teamId,
        });
    }, [clientState]);

    return (
        <div>
            <div className="d-flex flex-row align-items-center mb-4 justify-content-between">
                <EvvSearchModalComponent
                    type="Client"
                    isTeamSettings={isTeamSettings}
                    isChannelSettings={isChannelSettings}
                    firstName={firstName}
                    lastName={lastName}
                    userEmployee={userEmployee}
                    provider={provider}
                    handleNameChange={handleNameChange}
                    handleUserEmployeeSelect={handleUserEmployeeSelect}
                    handleProviderSelect={handleProviderSelect}
                    handleClearAll={handleClearAll}
                    team={teamId}
                />
                <Nav
                    variant="pills"
                    className="p-2"
                    css={css`
                        background: #e1e1e1;
                        border-radius: 5px;
                    `}
                >
                    <Nav.Item>
                        <Nav.Link
                            as={Button}
                            variant={clientState === 'active' ? 'primary' : 'link'}
                            onClick={() => setClientState('active')}
                            style={{ fontSize: '1.6rem' }}
                            className={clientState === 'active' ? 'bg-primary text-white' : ''}
                        >
                            Active
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="ml-1">
                        <Nav.Link
                            as={Button}
                            variant={clientState === 'archieved' ? 'primary' : 'link'}
                            style={{ fontSize: '1.6rem' }}
                            className={clientState === 'archieved' ? 'bg-primary text-white' : ''}
                            onClick={() => setClientState('archieved')}
                        >
                            Archived
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                {/* <CreateClient isTeamSettings={isTeamSettings} provider={provider} teamId={teamId} channelId={channelDetails?.id} /> */}
            </div>
            <div
                className="custom-card card-table d-flex flex-column justify-content-between"
                css={css`
                    min-height: 675px;
                `}
            >
                <ClientList
                    list={clientsList}
                    isLoading={isLoading}
                    sortConfig={sortConfig}
                    currentPage={currentPage}
                    clientState={clientState}
                    setClientState={setClientState}
                    team={teamId}
                    isTeamSettings={isTeamSettings}
                />

                {Boolean(clients.totalClients) && (
                    <div className="m-4">
                        <Pagination
                            pageNo={currentPage}
                            totalRecords={clients.totalClients}
                            pageLimit={10}
                            pageNeighbours={1}
                            onPageChanged={onPageChanged}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    isLoading: state.evv.isLoading,
    clients: state.evv.clients,
    teams: state.teams.teams,
});

export default connect(mapStateToProps, { GetClients, getAllUser })(UserClient);
