import React from "react";
import { useForm } from 'react-hook-form';
import { StyledForm } from '../Provider/styles';
import ZealFormBuilder from './ZealFormBuilder';
import { Button } from 'react-bootstrap';
import { css } from 'twin.macro';
import { VscLoading } from 'react-icons/vsc';


const ZealForm = ({
	formStructure,
	onSubmit,
	isFormSubmitting,
	fetchingForm,
	handleCompleteOnboarding
}) => {

	const { register, handleSubmit, setValue, errors, getValues, setError, clearErrors } = useForm({
		mode: 'all'
	});

	const handleOnSubmit = async (data) => {
		try {
			const taxParameters = {
				federalParameters: [],
				stateParameters: []
			}
			Object.entries(data).forEach(([state, data]) => {
				const taxParamKey = state === 'US' ? "federalParameters" : "stateParameters";
				taxParameters[taxParamKey].push(
					...Object.entries(data).map(([code, value]) => ({
						code, value: value?.toString() || "false", jurisdiction: state
					})));
			});
			return onSubmit(taxParameters);
		} catch (e) {
			console.log("err", e)
		}
	};

	return (
		<StyledForm onSubmit={handleSubmit(handleOnSubmit)}>
			{
				fetchingForm ?
					<div
						css={css`
					margin: 0;
					padding-left: 0.8rem;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;
					height:5rem;
				`}
					>
						<VscLoading className="spin" />
						<span className="ml-2">Loading Zeal Form Data...</span>
					</div>
					:
					(
						Object.keys(formStructure)?.length ?
							<div css={css`display: flex; flex-direction: column; height: 500px;`}>
								<div css={css`flex-grow: 1; overflow: auto;`}>
									<ZealFormBuilder
										formStructure={formStructure}
										register={register}
										setValue={setValue}
										getValues={getValues}
										errors={errors}
										setError={setError}
										clearErrors={clearErrors}
									/>
								</div>
								<hr />
								<div className="d-flex justify-content-between">
									{<Button className="btn my-2" onClick={handleCompleteOnboarding} disabled={isFormSubmitting} css={css`width: 256px;`}>Complete Onboarding</Button>}
									<Button className="btn my-2" variant="primary" type="submit" disabled={isFormSubmitting} css={css`width: 128px;`}>
										{isFormSubmitting ? <VscLoading className="spin" /> : <span>Submit</span>}
									</Button>
								</div>
							</div>
							: "Couldn't fetch form !"
					)
			}
		</StyledForm>
	);
}
export default ZealForm;