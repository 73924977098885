import styled from '@emotion/styled';

export const RoomContainer = styled.div`
	min-height: 100vh;

	.room {
		position: relative;
		width: 100%;
		background: #e6eeed;
		min-height: 100vh;
		padding-bottom: 12vh;
		display: flex;

		h2 {
			font-weight: 300;
			text-align: center;
		}
	}
	.controllersDiv {
		position: fixed;
		bottom: 0;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 2rem 1rem 1rem 1rem;

		min-height: 8vh;
		.controls {
			background: white;
			box-shadow: 1px 4px 7px #424242;
			border-radius: 1000px;
			display: flex;
			justify-content: space-evenly;
			align-items: center;
			padding: 1rem 2rem;
		}
		.Btn {
			width: 50px;
			height: 50px;
			border: #e3e3e3 2px solid;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 1000px;
			cursor: pointer;
			transition: background-color 200ms;
			background-color: #f8f8f8;
			margin: 0 1rem;
			:hover {
				background-color: #e3e3e3;
			}
		}
	}
`;
