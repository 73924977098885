import styled from '@emotion/styled';

export const TaskStyles = styled('li')`
	width: 100%;
	border-radius: 10px;
	background-color: #fff;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
	.line {
		background-color: #222222;
		width: 4px;
		height: 90%;
		margin-right: 20px;
		border-radius: 4px;
	}
	.recurring-Monthly {
		background-color: #1abc9cb0;
		color: white;
	}
	.recurring-Weekly {
		background-color: #f39c12b0;
		color: white;
	}
	.recurring-Daily {
		background-color: #297fb9b0;
		color: white;
	}
	.recurring-Quaterly {
		background-color: #1abc9cb0;
		color: white;
	}
	.recurring-Yearly {
		background-color: #ff5722b0;
		color: white;
	}
	.ongoing-task-indicator {
		background-color: #e6e6e6b0;
	}
	.greenline {
		background-color: green;
	}
	.event-content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 85%;
		padding: 1rem 1rem 1rem 0rem;
		height: 100%;
		.titleAndDescrip {
			width: 80%;
			height: 100%;
		}
	}
	.checkbtn {
		cursor: pointer;
		transition: color 350ms;
	}
	.checkbtn:hover {
		color: green;
	}
	.viewBtn:hover {
		color: skyblue;
	}

	.grey {
		color: #6f6f6f;
	}
	.editbtn {
		transition: color 240ms;
		:hover {
			color: #0aa2d0;
		}
	}
	.ellipsis {
		line-height: 2rem;
		position: relative;
		max-height: calc(2rem * 2);
		overflow: hidden;
		padding-right: 1rem;
		word-break: break-all;
	}
	.ellipsis:after {
		content: '';
		text-align: right;
		position: absolute;
		bottom: 0;
		right: 0;
		width: 25%;
		height: 1.2em;
		background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
	}

	.event-act-buttons {
		flex: 1;
		display: flex;
		height: 100%;
		flex-direction: column;
		align-items: flex-end;
		justify-content: center;
		position: relative;
	}

	.dropper {
		position: absolute;
		top: -10px;
		right: -10px;
		visibility: hidden;
		opacity: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		div {
			font-size: 1.3rem;
		}
		&.show {
			visibility: visible !important;
			opacity: 1;
		}
	}

	.dropdown {
		visibility: visible;
		opacity: 1;
		background: transparent;
		border: none;

		.dropdown-menu {
			border-radius: 10px;
			border: none;
			box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
			color: #dddddd;
		}

		&.btn-primary:not(:disabled):not(.disabled):active {
			background: transparent;
			color: black;
			border: none;
		}
		&.btn-primary.dropdown-toggle:focus {
			box-shadow: none;
		}
		:focus,
		:hover,
		:active {
			background: transparent;
			color: black;
			border: none;
			box-shadow: none;
		}
		&.btn-primary.dropdown-toggle {
			background: transparent;
			color: black;
			border: none;
		}
		::after {
			display: none;
		}
	}
`;
