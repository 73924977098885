import React from 'react';
import { css } from 'twin.macro';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import { Body } from './styles';

import ClientForm from './ClientForm';

import { BsEye } from 'react-icons/bs';
import { ToolTip } from '../../../../_elements/ToolTip';

const ViewClient = ({ client, isTeamSettings, team }) => {
	const [showModal, setShowModal] = useState(false);
	return (
		<>
			<ToolTip text={'View'} cssStyles={'top:-70% !important; min-width: fit-content !important;'}>
				<BsEye className="icon" onClick={() => setShowModal(true)} />
			</ToolTip>

			<Modal show={showModal} backdropClassName="bg-dark" onHide={() => setShowModal(false)} centered>
				<Modal.Header closeButton>
					<div className="header">
						<h4
							css={css`
								margin: 0;
							`}
						>
							View Client
						</h4>
					</div>
				</Modal.Header>
				<Modal.Body className="modal-body">
					<Body>
						<ClientForm
							setShowModal={setShowModal}
							client={client}
							formType={'View'}
							isTeamSettings={isTeamSettings}
							team={team}
						/>
					</Body>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default ViewClient;
