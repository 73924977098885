import React, { useEffect, useState } from 'react';
import { css } from 'twin.macro';
import { getOtherUserData } from '../../../../../../_redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchWrapper } from '../../../../../../_helpers';
import { ActionStyles } from '../../../../../Admin/reusableStyles';
import { ToolTip } from '../../../../../../_elements/ToolTip';
import { BsEye } from 'react-icons/bs';
import CourseUserListing from './CourseUserListing';
import LoadingSpiner from '../../../../../../_elements/LoadingSpiner';

function Courses({ usersObj }) {
    const [coursesData, setCoursesData] = useState({});
    const [loading, setLoading] = useState(false);
    const [activeCourse, setActiveCourse] = useState(null);

    const handleDeselectCourse = () => {
        setActiveCourse(null);
    };
    const handleSelectCourse = (course) => {
        setActiveCourse(course);
    };

    async function loadData() {
        try {
            setLoading(true);
            const response = await fetchWrapper.get('amol/courses');
            if (!response.data) {
                throw new Error("Couldn't fetch courses");
            }
            setCoursesData(response?.data?.data);
        } catch (error) {
            if (error.name === 'AbortError') {
                return;
            }
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    if (activeCourse) {
        return <CourseUserListing course={activeCourse} handleBack={handleDeselectCourse} usersObj={usersObj} />;
    }
    return (
        <div
            css={css`
                padding: 1rem 2rem;
            `}
        >
            <div className="d-flex justify-content-between mb-4"></div>
            {loading ? (
                <LoadingSpiner size={42} />
            ) : (
                <>
                    {coursesData?.length ? (
                        <div
                            css={css`
                                display: flex;
                                flex-direction: column;
                                gap: 6px;
                            `}
                        >
                            {coursesData?.map((course, courseIndex) => (
                                <CourseRow
                                    key={course?.course_id || courseIndex}
                                    course={course}
                                    handleSelectCourse={handleSelectCourse}
                                />
                            ))}
                        </div>
                    ) : (
                        <div className="custom-card">No Course Found</div>
                    )}
                </>
            )}
        </div>
    );
}

function CourseRow({ course, handleSelectCourse }) {
    return (
        <div
            className="d-flex w-100 custom-card"
            css={css`
                justify-content: space-between;
                gap: 8px;
                align-items: center;
            `}
        >
            <div
                className="d-flex"
                css={css`
                    gap: 12px;
                    flex-direction: column;
                `}
            >
                <div>
                    <img src={course?.course_icon_url} alt="course-icon" height="40" width="40" />
                    <span
                        css={css`
                            margin-left: 8px;
                        `}
                    >
                        {course.course_name || ''}
                    </span>
                </div>
                <span
                    css={css`
                        color: #444;
                    `}
                >
                    {course.course_description || ''}
                </span>
            </div>
            <div>
                <ActionStyles style={{ justifyContent: 'start' }}>
                    <ToolTip
                        text={'View Users Progress'}
                        cssStyles={'top:-70% !important; min-width: 150px !important;left:-70px !important;'}
                    >
                        <BsEye className="icon" onClick={() => handleSelectCourse(course)} />
                    </ToolTip>
                </ActionStyles>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    CurrUser: state.user.user,
});
export default connect(mapStateToProps, { getOtherUserData })(Courses);
