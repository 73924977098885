import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { css } from 'twin.macro';
import { GoMention } from 'react-icons/go';
import { CSSTransition } from 'react-transition-group';
import { RiReplyAllFill } from 'react-icons/ri';
import { FaBell, FaUserPlus, FaQuoteLeft } from 'react-icons/fa';
import { MdPlayArrow } from 'react-icons/md';
import { SanitizeHTML } from '../../../_helpers';
import { UPrimaryButton } from '../../../_elements/Buttons';
import { BsEyeFill } from 'react-icons/bs';
import { ToolTip } from '../../../_elements/ToolTip';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import DeletePopUp from '../../../_elements/DeletePopUp';
import { MdOutlineEventAvailable } from 'react-icons/md';
// import { setActivityMessage } from '../../../_redux/actions/ActionActivity';
import { MdCall } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { markasReadActivity } from '../../../_redux/actions/ActionActivity';
import { clearInvitationForConference, startConference, setConferenceData  } from '../../../_redux/actions/ActionConference';

const SingleActivity = ({ activity, handleClear, clearInvitationForConference, startConference, setConferenceData }) => {
	const history = useHistory();
	const type = activity.type;
	const activityOf = activity.activityOf;
	const fromUser = activity.user?.name || activity.fromUser?.name;
	const fromUserId = activity.user?.id || activity.user?._id || activity.fromUser?.id || activity.fromUser?._id;
	const fromChannel = activity?.room?.title || activity?.activity?.title;
	const channelId = activity?.room?.id || activity?.room?._id || activity?.activity?.id || activity?.activity?._id;
	const dispatch = useDispatch();
	const handlePickUp = async () => {
		try {
			const roomId = activity?.room?._id;
			const conferenceId = activity?.activity?._id;
			const teamId = activity?.room?.team;
			setConferenceData(roomId, conferenceId, teamId);
			startConference();
			clearInvitationForConference();
		} catch (e) { }
	};

	let icon = null;
	let message = null;

	if (type === 'mention' && activityOf === 'Message') {
		icon = <GoMention />;
		message = (
			<>
				<span className="text-muted mr-2">{`${fromUser} mentioned you in room`}</span>
				<span className="font-weight-bold">{fromChannel}</span>
			</>
		);
	} else if (type === 'reply' && activityOf === 'Message') {
		icon = <RiReplyAllFill />;
		message = (
			<>
				<span className="text-muted mr-2">{`${fromUser} replied to you in room`}</span>
				<span className="font-weight-bold">{fromChannel}</span>
			</>
		);
	} else if (type === 'reply' && activityOf === 'DirectMessage') {
		icon = <RiReplyAllFill />;
		message = (
			<>
				<span className="font-weight-bold">{fromUser}</span>
				<span className="text-muted mr-2">{` replied to you in chat.`}</span>
			</>
		);
	} else if (type === 'invite') {
		icon = <FaUserPlus />;
		message = (
			<>
				<span className="text-muted mr-2">{`${fromUser} invited you in room`}</span>
				<span className="font-weight-bold">{fromChannel}</span>
			</>
		);
	} else if (activityOf === 'Team') {
		let fromTeam = activity?.activity?.name;
		if (type === 'invitedManager') {
			icon = <FaUserPlus />;
			message = (
				<>
					<span className="text-muted mr-2">{`${fromUser} invited you in team`}</span>
					<span className="font-weight-bold">{fromTeam} </span>
					<span className="text-muted ml-2"> as a team manager</span>
				</>
			);
		} else if (type === 'invitedUser') {
			icon = <FaUserPlus />;
			message = (
				<>
					<span className="text-muted mr-2">{`${fromUser} invited you in team`}</span>
					<span className="font-weight-bold">{fromTeam}</span>
				</>
			);
		}
	} else if (type === 'assigned') {
		icon = <MdOutlineEventAvailable />;
		message = (
			<>
				<span className="text-muted mr-2">{`${fromUser} assigned you in an Event in room`}</span>
				<span className="font-weight-bold">{fromChannel}</span>
			</>
		);
	} else if (type === 'invitedConference') {
		icon = <MdCall />;
		message = (
			<>
				<span className="text-muted mr-2">{`${fromUser} wanted you to join in a Meeting in room`}</span>
				<span className="font-weight-bold">{fromChannel}</span>
			</>
		);
	} else if (type === 'inviteduser' && activityOf === 'Room') {
		icon = <FaUserPlus />;
		let fromTeam = activity?.activity?.team?.name;
		message = (
			<>
				<span className="text-muted mr-2">{`${fromUser} invited you to join a channel`}</span>
				<span className="font-weight-bold">{fromChannel}</span>
				{fromTeam && <span>,</span>}
				<span className="font-weight-bold ml-2">{fromTeam}</span>
			</>
		);
	} else if (type === 'invitedsupervisor' && activityOf === 'Room') {
		icon = <FaUserPlus />;
		let fromTeam = activity?.activity?.team?.name;
		message = (
			<>
				<span className="text-muted mr-2">{`${fromUser} invited you to join a channel`}</span>
				<span className="font-weight-bold">{fromChannel}</span>
				{fromTeam && <span>,</span>}
				<span className="font-weight-bold ml-2">{fromTeam} as a supervisor</span>
			</>
		);
	}
	 else {
		icon = <FaBell />;
	}

	const activityId = activity._id || activity?.activity?._id;

	return (
		<CSSTransition key={activityId} timeout={1000} classNames="task_element">
			<div>
				<div className={` ${activity.type} d-flex`}>
					<div className="icon">
						{icon}
						<MdPlayArrow className="icon-arrow" />
					</div>

					<div className={`activityBar ${activity.unRead && `act-unread`}`}>
						<div>
							<div className="d-flex align-items-center">{message}</div>
							<div className="text-muted small">
								{dayjs(activity.createdAt).format('MM/DD/YYYY')}&nbsp;&nbsp;&nbsp;
								{dayjs(activity.createdAt).format('hh:mm A')}
							</div>
							{activity.data && (
								<div className="text-muted mt-2 d-flex">
									<FaQuoteLeft className="mr-3" />
									<SanitizeHTML html={activity.data} />
								</div>
							)}
						</div>
						<div
							className="d-flex"
							css={css`
								height: 40px;
							`}
						>
							<ToolTip side="left" text="View">
								<UPrimaryButton
									className="mr-2"
									onClick={() => {
										dispatch(markasReadActivity(activityId));
										switch (activity.activityOf) {
											case 'Message':
											case 'Room':
												history.push(`/teams/${channelId}`);
												break;
											case 'DirectMessage':
												history.push(`/chat/${fromUserId}`);
												break;
											case 'Conference':
												handlePickUp();
												break;
											case 'Task':
												history.push(`/events`);
												break;
											case 'Team':
												history.push(`/teams`);
												break;
											default:
										}
									}}
								>
									<BsEyeFill size={18} />
								</UPrimaryButton>
							</ToolTip>

							<ToolTip side="left" text="Remove from activity">
								<UPrimaryButton
									css={css`
										margin: 0;
										color: white;
										border: none;
										background: none;
										padding: 0;
										svg {
											padding: 8px !important;
											margin: 0 !important;
											color: red !important;
											background: #ffffff !important;
											border: 1px solid red !important;
											border-radius: 5px !important;
											height: 36px !important;
										}
									`}
								>
									<DeletePopUp
										handleDelete={handleClear}
										title="Activity"
										id={activity._id}
										type="Activity"
									/>
								</UPrimaryButton>
							</ToolTip>
						</div>
					</div>
				</div>
			</div>
		</CSSTransition>
	);
};

export default connect(null, {
	//  setActivityMessage
	clearInvitationForConference,
	startConference,
	setConferenceData
})(SingleActivity);
