import styled from '@emotion/styled';

export const DashStyles = styled('div')`
	min-height: 100vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	.settings-container {
		width: calc(100vw - 8.7rem);
		margin-left: 8rem;
		padding: 10px;
		.ses-container {
			min-height: 96vh;
			padding: 2rem;
			background: #f5f5f5;
			border-radius: 25px;
		}

		.settings-cards {
			background-color: white;
			border-radius: 10px;
			min-height: 130px;
			padding: 10px;
			width: 100%;
			cursor: pointer;
			box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
			&:hover {
				box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
			}
		}

		.selected-card {
			box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
			background-color: #e0f0ef;
		}

		.info {
			font-size: 14px;
		}

		.team-infos {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			margin: 0px 20px;
			line-height: 2.5rem;
			border-radius: 10px;
			width: auto;
			table > tr > td {
				color: grey;
			}
		}

		@media (max-width: 768px) {
			margin-left: 0rem;
			width: 100vw;
		}

		.team-stats {
			margin: 10px;
		}
	}
	.mainSection {
		margin-left: 12rem;
		width: auto;
		padding: 1em;
		@media (max-width: 1200px) {
			margin-left: 5rem;
		}
		.settingsNavDiv {
			margin: 0.5em 1em 0;
			padding: 5px 5px 0;
			display: flex;
			background: white;
			border-radius: 5px;
			.bold {
				font-weight: bold;
			}
			.navBtn {
				cursor: pointer;
				font-size: 35px;
				width: 4%;

				&.closebtn {
					transition: color 200ms, transform 200ms;
					:hover {
						color: red;
						transform: scale(1.2);
					}
					:active {
						transform: scale(0.8);
					}
				}
			}
		}
	}
	.costume-link {
		outline: none;
		width: 100%;
		text-align: left;
		:hover {
			border-color: none !important;
		}
	}

	.action-btn {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #e0f0ef;
		padding: 5px 10px;
		border-radius: 5px;
		color: #50aeb0;
		cursor: pointer;
		transition: transform 200ms ease-in-out;
		&:hover {
			transform: scale(1.1);
		}

		.icon {
			color: #50aeb0;
			font-size: 16px;
		}
		span {
			font-size: 14px;
		}
	}
	.delete {
		background-color: #f8d9d9;
		color: red;
	}
`;
