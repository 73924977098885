import React, { Fragment, useEffect, useState } from 'react';
import { css } from 'twin.macro';
import NavigationBar from '../../../../../Admin/reusableComponents/NavigationBar';
import { getOtherUserData } from '../../../../../../_redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchWrapper } from '../../../../../../_helpers';
import { CourseMetricContainer } from '../Common/CourseMetricContainer';
import LoadingSpiner from '../../../../../../_elements/LoadingSpiner';

function UserCoursesInfo({ handleBack, user }) {
    const [coursesData, setCoursesData] = useState({});
    const [loading, setLoading] = useState(false);

    async function loadData() {
        try {
            if (!user?._id) {
                return;
            }
            setLoading(true);
            const response = await fetchWrapper.get(`amol/${user?._id}`);
            if (!response.data) {
                throw new Error("Couldn't fetch courses data");
            }
            setCoursesData(response?.data?.data);
        } catch (error) {
            if (error.name === 'AbortError') {
                return;
            }
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        loadData();
    }, [user]);

    return (
        <div
            css={css`
                padding: 1rem 2rem;
            `}
        >
            <div className="d-flex justify-content-between mb-4">
                <NavigationBar mainTabName={`Users | ${user?.name || ''}`} goToTeamPage={handleBack} />
            </div>
            {loading ? (
                <LoadingSpiner size={42} />
            ) : (
                <>
                    {coursesData?.length > 0 ? (
                        <div
                            css={css`
                                display: flex;
                                flex-direction: column;
                                gap: 6px;
                            `}
                        >
                            {coursesData.map((course, index) => (
                                <CourseMetricContainer key={index} course={course} user={user} />
                            ))}
                        </div>
                    ) : (
                        <div className="custom-card">No Course Group Assigned</div>
                    )}
                </>
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    CurrUser: state.user.user,
});
export default connect(mapStateToProps, { getOtherUserData })(UserCoursesInfo);
