import { css } from 'twin.macro';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';

//icons
import { BsFillLockFill, BsFillUnlockFill } from 'react-icons/bs';
import { FaGlobeAmericas } from 'react-icons/fa';
import { MdGpsFixed } from 'react-icons/md';
import { teamSort } from './styles/TeamListStyles';
import { roomdiv } from './Room';
import { VscLoading } from 'react-icons/vsc';
import ChatListSkeleton from '../Layouts/ChatListSkeleton';

const SpanCss = css`
	font-size: 1.8rem;
	width: 100%;
	text-align: center;
	display: inline-block;
	margin: 2rem 0;
`
const ChannelSearchComponent = ({
	user,
	userRoom,
	roomIndex,
	setRoomIndex,
	channels,
	handleRoomClick,
	setShowChat,
	search,
	hasMore,
	loadFunc,
	isSearching,
	isChannelsLoading,
}) => {
	const handleClick = (room) => {
		let roomId = room.id || room._id;
		if (roomIndex !== roomId) {
			handleRoomClick(roomId, room.type, null, null, room.team);
			setShowChat(true);
			setRoomIndex(roomId);
		}
	};
	return (
		<React.Fragment>

			<div className="text-center text-capitalize" css={teamSort}>
				Search by Channel
			</div>

			{

				(isSearching || channels === null) ?

					<div css={css`width: 100%;overflow: hidden;`}>
						<ChatListSkeleton type="team" />
					</div> 

					: (
						<InfiniteScroll
							pageStart={1}
							initialLoad={false}
							loadMore={loadFunc}
							hasMore={!isSearching && !isChannelsLoading && hasMore && !!search}
							threshold={50}
							useWindow={false}
						>
							<Fragment>

								<ul>
									{
										channels?.length ?

											(<Fragment>

												{
													channels?.map((room, roomIdx) => (
														<div key={room?.id || room?._id || roomIdx} css={roomdiv} onClick={() => handleClick(room)}>
															<div
																key={room.id}
																css={css`border-top: 1px solid white;`}
																className={`${roomIndex === (room?.id || room?._id) ? 'bg-white current' : 'room'
																	} pointer d-flex justify-content-between`}
															>
																<div className="d-flex">
																	<a
																		href={'#0'}
																		key={room.id}
																		// href={roomIndex !== room.id ? `/teams/${room.id}` : '#'}
																		className={`text-dark m-0 mx-2 text-decoration-none`}
																	>
																		{room?.teamData?.name ? `${room?.teamData?.name}/ ` : null}
																		<b>{room.title}</b>
																	</a>
																	<div
																		css={css`color: #757575 !important;`}
																	>
																		{room.type === 'gps_enabled' ? <MdGpsFixed size="10" /> : null}
																		{room.type === 'private' || room.type === 'gps_enabled' ? (
																			// if user is owner || user is manager of team || user is admin
																			(userRoom && userRoom.filter((uRoom) => uRoom.room === room.id)) ||
																				user.role === 'admin' ? (<BsFillUnlockFill size="10" css={css`margin-left: 5px;font-size: 16px;`} />) :
																				(<BsFillLockFill size="10" css={css`margin-left: 5px;font-size: 16px;`} />)
																		) : (
																			<Fragment>
																				{room.addresses[0]?.latitude && (
																					<MdGpsFixed size="10" className="ml-2" />
																				)}
																				<FaGlobeAmericas size="10" css={css`margin-left: 5px;font-size: 16px;`} />
																			</Fragment>
																		)}
																	</div>
																</div>
															</div>
														</div>
													))
												}

												{hasMore ? null : (<span css={SpanCss}>No more Channels</span>)}

											</Fragment>
											) : (<span css={SpanCss}>No Channel Found</span>)
									}
								</ul>

								{
									(!isChannelsLoading) ? null : <div
										className="loader"
										key={'loader-infinite-channel-search'}
										css={css`height: 100%;
										display: flex;
										justify-content: center;
										align-items: center;
										`}>
										<VscLoading fontSize={34} className="spin" />
									</div>
								}
							</Fragment>
						</InfiniteScroll>
					)
			}
		</React.Fragment>
	);
};

const mapStateToProps = (state) => ({
	userRoom: state.user.user.roomList,
	user: state.user.user,
});

export default connect(mapStateToProps, null)(ChannelSearchComponent);
