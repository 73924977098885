import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';

const SearchByName = ({ firstName, lastName, handleChange, isChannelSettings }) => {
	return (
		<div>
			<Form.Group as={Row} controlId="firstName">
				<Form.Label column sm="3">
					First Name:
				</Form.Label>
				<Col sm="9">
					<Form.Control name="firstName" value={firstName} onChange={handleChange} disabled={isChannelSettings}/>
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="lastName">
				<Form.Label column sm="3">
					Last Name:
				</Form.Label>
				<Col sm="9">
					<Form.Control name="lastName" value={lastName} onChange={handleChange} disabled={isChannelSettings}/>
				</Col>
			</Form.Group>
		</div>
	);
};

export default SearchByName;
