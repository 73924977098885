import { css } from 'twin.macro';
import PropTypes from 'prop-types';
import { Fragment, useState, useEffect, useContext } from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
// import io from 'socket.io-client';
//components
import TeamChat from './TeamChat';
import SideBar from '../Layouts/SideBar/SideBar';
import TeamList from './TeamList';
import TeamDetails from './TeamDetails';
// import ProfileDrawer from '../../Drawer/ProfileDrawer';
// import optonomeSvg from '../../../assets/logos/logo.svg';
//redux
import { connect } from 'react-redux';
import {
    getTeamChat,
    clearMessages,
    clearSearchMessages,
    postLastSeenRoomMessage,
    getNotSeenRooms,
    setNewRoom,
    startMessageLoading,
    stopMessageLoading,
} from '../../../_redux/actions/ActionMessage';
import { removeChannel } from '../../../_redux';
import { setNewTeam } from '../../../_redux/actions/ActionOrganization';
import { WebSocketContext } from '../../../_redux/actions/ActionSocket';
import { fetchWrapper, scrollMeToMyRoom } from '../../../_helpers';
import { getUserData } from '../../../_redux';
import { layout } from './styles/TeamContainerStyles';
import MessageListener from '../Common/MessageListener';
import { BsFillCaretLeftFill } from 'react-icons/bs';
import { useRef } from 'react';
import UserFormModal from './UserFormModal';
import { useMemo } from 'react';
import { toast } from 'react-toastify';

const TeamContainer = ({
    getTeamChat,
    // clearMessages,
    user,
    teams,
    rooms,
    teamRooms,
    clearSearchMessages,
    startMessageLoading,
    stopMessageLoading,
    organizations,
    match,
    history,
    getLastSeenRoomMessage,
    messages,
    getUserData,
    setNewTeam,
    setNewRoom,
    roomsRef,
}) => {
    const [openDetails, setOpenDetails] = useState(false);
    const [showChat, setShowChat] = useState(true);
    const [chatFormId, setChatFormId] = useState(false);
    const [mobileSidebar, setMobileSidebar] = useState(false);
    const [userRole, setUserRole] = useState();
    const [hideTeamList, setHideTeamList] = useState(false);

    const ws = useContext(WebSocketContext);
    const roomId = match.params.roomId;
    const [room, setRoom] = useState(
        Object.values(teamRooms || {})
            .flat()
            .map((teamRoom) => teamRoom.rooms)
            .find((roomL) => roomL?.id === roomId) || {}
    );
    const teamId = room?.team;
    const [team, setTeam] = useState((teams || []).find((teamL) => teamL?.id === teamId) || {});

    useEffect(() => {
        if (roomId && roomId !== (room._id || room.id)) {
            fetchWrapper
                .get(`/chatrooms/${roomId}`)
                .then(async (data) => {
                    if (data?.status >= 400 && data?.status < 500) {
                        history.push('/teams');
                        return toast.error(data?.message || 'Something went wrong');
                    }
                    setNewRoom(data?.team, data);
                    setRoom(data);
                })
                .catch((error) => {
                    if (error?.name === 'AbortError') {
                        return;
                    }
                    console.error('ERROR', error);
                    toast.error(error?.message || 'Something went wrong');
                    if (error?.code >= 400 && error?.code < 500) {
                        history.push('/teams');
                    }
                });
        }
        if (room?.team && room.team !== (team._id || team.id)) {
            fetchWrapper
                .get(`/organizations/5f3569c2647fb5000c019e42/teams/${room.team}`)
                .then(async (data) => {
                    setNewTeam(data);
                    setTeam(data);
                })
                .catch((error) => {
                    console.error('ERROR', error);
                });
        }
    }, [team, room]);

    useEffect(() => {
        if (!user || !user._id) {
            getUserData();
        }
    }, [user]);

    useEffect(() => {
        if (ws && ws.socket) {
            ws.socket.on('room', (room) => {
                if (room.event === 'updated') {
                    if ((room.result.id || room.result._id) === roomId) {
                        setRoom(room.result);
                    }
                }
            });
        }
        return () => {
            if (ws && ws.socket) {
                ws.socket.off('room');
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ws]);

    useEffect(() => {
        let formId = window.location.href.split('#/form/')[1];
        if (formId) {
            setChatFormId(formId);
        }
    }, [setChatFormId]);

    useEffect(() => {
        if (user) {
            const thisRoom = user.roomList.find((room) => room?.room?.id === roomId);
            setUserRole(thisRoom?.role || null);
        }
    }, [user, room, roomId]);

    const hasNotFirstScrolledToTeam = useRef(true);

    useEffect(() => {
        if (hasNotFirstScrolledToTeam.current) {
            let response = scrollMeToMyRoom(roomId, roomsRef);
            if (response) {
                hasNotFirstScrolledToTeam.current = false;
            }
        }
    }, [roomId, roomsRef]);

    useEffect(() => {
        const joinChat = async (roomId) => {
            if (ws && ws.socket) {
                let newRoom = rooms.find((room) => room?._id === roomId);
                if (!newRoom) {
                    startMessageLoading();
                    try {
                        newRoom = await fetchWrapper.get(`chatrooms/${roomId}`);
                        if (newRoom.status === 403) {
                            toast.error(newRoom.message);
                            history.push(`/not-found`);
                        }
                        newRoom.id = newRoom._id;
                    } catch (e) {
                        newRoom = null;
                    }
                }
                if (newRoom) {
                    ws.socket.emit('join', roomId, (error, socketMessages) => {
                        if (error) {
                            console.log(error, 'WS socket joining error');
                        } else {
                            clearSearchMessages();
                            setRoom(newRoom);
                            // setOpenDetails(true);
                            let keys = Object.keys(messages);
                            if (!keys.includes(roomId)) {
                                getTeamChat(roomId, 1);
                            } else {
                                stopMessageLoading();
                            }
                            const currentUrl = window.location.href;
                            if (user._id && currentUrl.includes('/teams/' + roomId)) {
                                history.push(`/teams/${roomId}#/id/${user._id}`);
                            }
                        }
                    });
                }
            }
        };

        if (roomId && user._id) {
            joinChat(roomId);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user._id, ws, roomId, history]);

    const [teamData, setTeamData] = useState(null);

    const getUserTeamFromRoom = (teamList, rooms, roomId) => {
        try {
            let room = Object.values(rooms)
                .map((teamRoom) => teamRoom.rooms)
                .flat()
                .find((room) => room?.id === roomId);
            if (room) {
                let teamId = room.team;
                let team = teamList.find((team) => team?.team?.id === teamId);
                return team;
            } else {
                return null;
            }
        } catch (err) {
            console.log(err, 'Error getting team');
        }
    };
    useEffect(() => {
        let team = getUserTeamFromRoom(user.teamList, teamRooms, roomId);
        setTeamData(team);
    }, [teamRooms, user.teamList, roomId]);

    const canDownload = useMemo(() => {
        if (user?.role === 'admin') {
            return true;
        } else if (['owner', 'teamManager'].includes(teamData?.role)) {
            return true;
        } else {
            return false;
        }
    }, [user, teamData]);

    return (
        <div css={layout}>
            <MessageListener />
            <SwitchTransition>
                <CSSTransition key={mobileSidebar} timeout={500} classNames={'sidebar-ani-'}>
                    <SideBar mobileSidebar={mobileSidebar} setMobileSidebar={setMobileSidebar} />
                </CSSTransition>
            </SwitchTransition>

            <div className={`${showChat ? `wrapper-chat` : `wrapper-team`} wrapper-d`}>
                <div className={`teamlist ${hideTeamList ? 'hide-teamlist' : ''}`}>
                    <button
                        className={`hiddenBtn ${hideTeamList ? '' : 'partialVisibleBtn'}`}
                        css={css`
                            position: absolute;
                            right: ${hideTeamList ? '-22%' : '-4%'};
                            top: 32px;
                            z-index: 11;
                            width: 40px;
                            height: 40px;
                            background: #e0f0ef;
                            color: #28637e;
                            outline: none;
                            border: none;
                            display: flex;
                            overflow: hidden;
                            justify-content: center;
                            align-items: center;
                            border-radius: 1000px;
                            transition: right 150ms;
                            .caretIcon {
                                transition: transform 150ms;
                                &.turnRight {
                                    transform: rotateY(180deg);
                                }
                                &.turnLeft {
                                    transform: rotateY(0deg);
                                }
                            }
                            @media (max-width: 768px) {
                                display: none;
                            }
                        `}
                        onClick={() => setHideTeamList(!hideTeamList)}
                    >
                        <BsFillCaretLeftFill className={`caretIcon ${hideTeamList ? 'turnRight' : 'turnLeft'}`} />
                    </button>
                    <TeamList
                        roomId={roomId}
                        match={match}
                        teamId={teamId}
                        organizations={organizations}
                        setOpenDetails={setOpenDetails}
                        setMobileSidebar={setMobileSidebar}
                        mobileSidebar={mobileSidebar}
                        setShowChat={setShowChat}
                    />
                </div>

                <div className={`channelchat ${hideTeamList ? 'hide-teamlist' : ''}`}>
                    {room &&
                        (team && roomId ? (
                            <Fragment>
                                <TeamChat
                                    key={roomId}
                                    room={roomId === room?.id ? room : {}}
                                    roomId={roomId}
                                    getLastSeenRoomMessage={getLastSeenRoomMessage}
                                    setChatFormId={setChatFormId}
                                    setOpenDetails={setOpenDetails}
                                    openDetails={openDetails}
                                    setShowChat={setShowChat}
                                    userRole={userRole}
                                    team={team}
                                    teamData={teamData}
                                />
                            </Fragment>
                        ) : (
                            <div className="d-flex justify-content-center align-items-center h-100 flex-column">
                                {/* <div className="d-flex align-items-center">
									<img
										src={optonomeSvg}
										alt="optonome"
										css={css`
											width: 100px;
											margin-right: 3rem;
										`}
									/>
									<h2
										css={css`
											font-size: 34px;
											.text-primary {
												font-weight: bold;
											}
										`}
									>
										Welcome to
										<span className="text-primary ml-2">Optonome</span>
									</h2>
								</div> */}
                                <h4 className="mt-4">Go To Teams and click on Channel to join the Channel</h4>
                            </div>
                        ))}
                </div>
                <CSSTransition in={openDetails} timeout={500} classNames={'details-ani-'}>
                    <div
                        className="details-ani"
                        css={css`
                            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                            overflow-y: scroll;
                        `}
                    >
                        {room && roomId && organizations?.length > 0 && team && (
                            <TeamDetails
                                key={room.id}
                                room={room}
                                orgId={organizations[0]?.id}
                                setOpenDetails={setOpenDetails}
                                openDetails={openDetails}
                                teamData={teamData}
                                canDownload={canDownload}
                            />
                        )}
                    </div>
                </CSSTransition>
                {/* {user._id && (
					<ProfileDrawer userId={user._id.split('/')[0]} teamId={teamId} roomId={roomId} roomType={room.type} />
				)} */}

                <UserFormModal
                    roomId={roomId}
                    chatFormId={chatFormId}
                    setChatFormId={setChatFormId}
                    canDownload={canDownload}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    user: state.user.user,
    messages: state.messages.messages,
    teams: state.teams.teams,
    teamRooms: state.rooms.teamRooms,
    rooms: state.user.user.roomList,
    organizations: state.organizations.organizations,
    roomsRef: state.rooms.roomsRef,
});

TeamContainer.propTypes = {
    getTeamChat: PropTypes.func.isRequired,
    clearMessages: PropTypes.func.isRequired,
    rooms: PropTypes.array.isRequired,
    clearSearchMessages: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
    getTeamChat,
    clearMessages,
    clearSearchMessages,
    setNewTeam,
    setNewRoom,
    getNotSeenRooms,
    postLastSeenRoomMessage,
    startMessageLoading,
    stopMessageLoading,
    getUserData,
    removeChannel,
})(TeamContainer);
