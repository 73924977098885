//core imports
import { css } from 'twin.macro';
import { Fragment, useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

//icons and styles

//components
import Room from './Room';

//helpers
import { connect } from 'react-redux';
import { getRole } from '../../../_helpers/roles/get-roles';
import { getRoomsByTeam } from '../../../_redux/actions/ActionMessage';
import { IoMdArrowDropdown, IoMdArrowDropright, IoMdCall } from 'react-icons/io';
import AvatarUserPanel from '../Common/AvatarUserPanel';
import { ToolTip } from '../../../_elements/ToolTip';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import ThreeDots from './ThreeDots';
import { Button } from 'react-bootstrap';
import { VscLoading } from 'react-icons/vsc';
import { canSeePublicTeams } from '../../../_helpers';

const wrapper = css`
    height: 50px;
    display: flex;
    justify-content: start;
    align-items: center;
    position: relative;

    cursor: pointer;
    .ateam {
        padding: 5px;
        color: #777a7d;
        font-weight: lighter;
        display: flex;
        justify-content: center;
        align-items: center;
        @media only screen and (min-width: 768px) and (max-width: 1130px) {
        }
    }
    .team-title {
        font-size: 14px;
        max-width: 20rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .team-dots {
        position: absolute;
        color: #777a7d;
        right: 10px;
        top: 20px;
    }
    /* 
	.show__rooms--enter{transform: scaleX(0)}
	.show__rooms--enter-active{transform: scaleX(1); transition: transform 0.5s ease-in-out;}
	.show__rooms--enter-done{transform: scaleX(1); transition: transform 0.5s ease-in-out;}

	.show__rooms--exit{transform: scaleX(1)}
	.show__rooms--exit-active{transform: scaleX(0); transition: transform 0.5s ease-in-out;}
	.show__rooms--exit-done{transform: scaleX(0); transition: transform 0.5s ease-in-out;} */
`;

const Team = ({
    team,
    handleCollaspe,
    roomIndex,
    collapse,
    user,
    handleRoomClick,
    rooms,
    isFetchingRoom,
    setOpenDetails,
    setShowChat,
    conferenceData,
    getRoomsByTeam,
}) => {
    let role = getRole(user, team);

    const showCallButton = (teamId) => {
        const conferenceId = conferenceData.conferenceId;
        const conferenceTeam = conferenceData.teamId;
        return conferenceId && conferenceId !== 'false' && teamId === conferenceTeam;
    };

    const [roomPage, setRoomPage] = useState(2);

    useEffect(() => {
        setRoomPage(2);
    }, [team.id, team._id]);

    const TeamMarkUp = ({ teamRooms = {} }) => {
        return (
            <Fragment>
                {teamRooms && teamRooms.Loaded ? (
                    (teamRooms.rooms || [])
                        .filter((room) => {
                            if (canSeePublicTeams(user) && room.type !== 'private') {
                                return room;
                            } else {
                                if (role === 'admin' || role === 'teamManager' || role === 'owner') {
                                    return room;
                                } else if (
                                    user?.roomList &&
                                    (user?.roomList?.find((userRoom) => userRoom?.room?.id === room?.id) ||
                                        (user.role === 'manager' &&
                                            Boolean(
                                                user?.teamList?.find(
                                                    (userteam) => userteam?.team?.id === (team?.id || team?._id)
                                                )
                                            )))
                                ) {
                                    return room;
                                } else {
                                    return null;
                                }
                            }
                        })
                        .sort((a, b) => a.title.localeCompare(b.title))
                        .map((room, i) => (
                            <Room
                                room={room}
                                teamId={team.id || team._id}
                                key={room.id}
                                handleRoomClick={handleRoomClick}
                                isSeen={room.isSeen}
                                roomIndex={roomIndex}
                                setOpenDetails={setOpenDetails}
                                manager={team.manager}
                                admin={team.admin}
                                managerBool={team.manager === user.id}
                                setShowChat={setShowChat}
                            />
                        ))
                ) : (
                    <div
                        css={css`
                            padding: 0.5rem 1rem;
                        `}
                    >
                        <div>
                            <SkeletonTheme color="#d3d3d3" highlightColor="rgba(240, 240, 240, 0.5)">
                                <Skeleton count={3} height={35} />
                            </SkeletonTheme>
                        </div>
                    </div>
                )}
                {teamRooms.rooms?.length < teamRooms.totalRooms ? (
                    <Button
                        disabled={isFetchingRoom}
                        onClick={() => {
                            getRoomsByTeam(team.id || team._id, roomPage);
                            setRoomPage((prev) => prev + 1);
                        }}
                        variant="outline-info"
                        style={{ fontSize: '14px', width: 'calc(100% - 16px)', margin: '8px' }}
                    >
                        {isFetchingRoom ? <VscLoading fontSize={20} className="spin" /> : null} Load More Rooms
                    </Button>
                ) : null}
            </Fragment>
        );
    };
    return (
        <div className="d-flex flex-column w-100">
            <div
                css={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom: 1px solid #dddddd;
                    background-color: #f2f8f7;
                    transition: background-color 200ms ease-in-out;
                    :hover {
                        background-color: #fafafa;
                    }
                    :hover .threeDots {
                        display: block;
                    }
                    .show {
                        display: block;
                    }
                `}
            >
                <div css={wrapper} onClick={() => handleCollaspe(team.id || team._id)}>
                    <div className="ateam">
                        <CSSTransition in={collapse.includes(team.id)} timeout={500} classNames="arrow">
                            {collapse.includes(team.id) ? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
                        </CSSTransition>
                        <AvatarUserPanel item={team} />
                    </div>
                    <ToolTip text={team.name} side="top">
                        <div className="team-title">{team.name}</div>
                    </ToolTip>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                    <div>
                        {showCallButton(team.id || team._id) && (
                            <IoMdCall color={'#2e9a9c'} fontSize={20} className="mr-3" />
                        )}
                    </div>
                    <ThreeDots role={role} team={team} user={user} />
                </div>
            </div>
            <CSSTransition in={collapse.includes(team.id || team._id)} classNames={'show__rooms-'} timeout={500}>
                <div
                    className="show__rooms"
                    css={css`
                        background-color: #e6eeed;
                        box-shadow: rgb(204, 219, 232) 2px 2px 4px 0px inset;
                    `}
                >
                    {collapse.includes(team.id || team._id) && <TeamMarkUp teamRooms={rooms[team.id || team._id]} />}
                </div>
            </CSSTransition>
        </div>
    );
};
const mapStateToProps = (state) => ({
    rooms: state.rooms.teamRooms,
    isFetchingRoom: state.rooms.isAddingRoom,
    allTeams: state.teams.teams,
    conferenceData: state.conference.conferenceData,
});
export default connect(mapStateToProps, { getRoomsByTeam })(Team);
