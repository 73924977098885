import React, { useState } from 'react';
import { FaFileAlt } from 'react-icons/fa';
import { textStyles } from './FileMessage';
import FileRenderer from './FileRendererModal';

function FileViewer({ file, ext, fileName, documentSettings }) {
	const [show, setShow] = useState(false);
	const handleShowFileModal = () => {
		setShow(true);
	};
	const handleClose = () => {
		setShow(false);
	};
	return (
		<div>
			{ext?.toLowerCase() === 'pdf' ? (
				<i className="fas fa-file-pdf mx-3"></i>
			) : (
				<FaFileAlt className="mx-3" size={24} />
			)}
			<span className="fileName" css={textStyles} onClick={handleShowFileModal}>
				{fileName?.slice(0, 24)}{fileName.length > 24 ? "..." : ""}
			</span>
			{show && (
				<FileRenderer
					show={show}
					fileName={fileName}
					file={file}
					handleClose={handleClose}
					documentSettings={documentSettings}
				/>
			)}
		</div>
	);
}

export default FileViewer;
