import { css } from 'twin.macro';
import { useState, useEffect, Fragment } from 'react';

import { Button, Modal, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
// import { yupResolver } from '@hookform/resolvers';
import { fetchWrapper } from '../../../_helpers';
import { connect } from 'react-redux';
//icons
import { BiLinkExternal } from 'react-icons/bi';
import { VscLoading } from 'react-icons/vsc';
import { ToolTip } from '../../../_elements/ToolTip';
import Pagination from "../../Admin/Pagination"
import { getRoomsByTeam } from '../../../_redux';
import { addRooms } from '../../../_redux';
import { useDispatch } from 'react-redux';

const ModalCss = css`
	.modal-content {
		/* background: #fff5bd; */
	}
	.spin {
		animation: spin 2s ease infinite;
	}
	@keyframes spin {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
`;

function AssignFormToChannelModal({ rooms, teamId, formName, formId, assignedChatrooms, cssStyles, getFormPackage }) {
	const dispatch = useDispatch();
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const [isLoading, setIsLoading] = useState(false);
	const [loadingRooms, setLoadingRooms] = useState(false);
	const [teamRooms, setTeamRooms] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalResults, setTotalResults] = useState(10);
	const [assignedChatroomsArray, setAssignedChatroomsArray] = useState(assignedChatrooms);
	const handleShow = () => {
		setShow(true);
	};

	useEffect(() => {
		setAssignedChatroomsArray(assignedChatrooms);
	}, [assignedChatrooms]);

	const allRoomsFetched = () => {
		console.log("true==>")
		if(rooms[teamId]?.rooms?.length >= rooms[teamId]?.totalRooms){
			return true;
		}
		return false;
	}
	useEffect(() => {
		const getRooms = async(teamId, page = 1, limit = 10, dispatch) =>{
			try {
				setLoadingRooms(true)
				const response = await fetchWrapper.get(`chatrooms?teamId=${teamId}&limit=${limit}&page=${page}&sortBy=title:asc`);
				dispatch(addRooms(teamId, response.results, response.totalResults));
				setTeamRooms(response?.results)
				setTotalResults(response?.totalResults)
				return true;
			} catch (error) {
				return false;
			}
			finally{
				setLoadingRooms(false)
			}
		};
		if (show && teamId) {
			if(allRoomsFetched()){
				setTeamRooms(rooms[teamId]?.rooms?.slice(((currentPage - 1) * 10),((currentPage - 1) * 10) + 10))
				setTotalResults(rooms[teamId]?.totalRooms)
			}else{
				getRooms(teamId,currentPage, 10, dispatch);
			}
		}
	}, [show, teamId, currentPage]);

	const addFormToChatRoom = async (chatroom) => {
		let formbody = {
			forms: [formId],
			rooms: [chatroom._id],
		};
		try {
			setIsLoading(chatroom._id);
			fetchWrapper.put('forms/assign', formbody).then((res) => {
				toast.success('Form Added');
				setAssignedChatroomsArray([...assignedChatroomsArray, chatroom._id]);
				setIsLoading(false);
			});
		} catch (e) {
			setIsLoading(false);
			console.log(e);
		}
	};

	const removeFormFromChatRoom = async (chatroom, formId) => {
		let formbody = {
			chatroomId: chatroom._id,
		};
		try {
			setIsLoading(chatroom._id);
			fetchWrapper.put(`forms/${formId}/unassign`, formbody).then((res) => {
				toast.success('Form Removed Successfully');
				let newAssignedChatroomsArray = Object.assign([], assignedChatroomsArray);

				newAssignedChatroomsArray = newAssignedChatroomsArray.filter((item) => item !== chatroom._id);
				setAssignedChatroomsArray(newAssignedChatroomsArray);

				setIsLoading(false);
			});
		} catch (e) {
			setIsLoading(false);
			console.log(e);
		}
	};

	return (
		<Fragment>
			<Fragment>
			<ToolTip text={'Assign Channel'} cssStyles={"top:-70% !important; min-width: 105px !important"}>
				<BiLinkExternal onClick={handleShow} css={cssStyles} className="icon assign" />
			</ToolTip>
			</Fragment>

			<Modal show={show} onHide={handleClose} css={ModalCss} centered>
				<Modal.Header closeButton>
					<h1>Team Channels</h1>
				</Modal.Header>

				<Modal.Body>
					<h1>
						Form Name: <b>{formName}</b>
					</h1>
					<div>
						{
							loadingRooms ?  <VscLoading className='spin' css={css`height:50px;width:100%;text-align:center;margin:50px 0;`}/>:
							<Table>
							<thead>
								<tr>
									<th>S.N</th>
									<th>ChatRoom Name</th>
									<th>Actions</th>
								</tr>
							</thead>
							<tbody>
								{ teamRooms?.length > 0 ? (
									teamRooms.map((room, idx) => {
										return (
											<tr key={room?._id}>
													<td>{10 * (currentPage - 1) + idx + 1}</td>
												<td>{room.title}</td>
												<td>
													{isLoading === room?._id ? (
														<p>
															<VscLoading className="spin" />
														</p>
													) : assignedChatroomsArray.includes(room._id) ? (
														<p
															onClick={() => {
																removeFormFromChatRoom(room, formId);
															}}
														>
															<span
																css={css`
																	cursor: pointer;
																	transform: scale(2);
																`}
															>
																Unassign
															</span>
														</p>
													) : (
														<p
															onClick={() => {
																addFormToChatRoom(room);
															}}
														>
															<span
																css={css`
																	cursor: pointer;
																	transform: scale(2);
																	color: green;
																`}
															>
																Assign
															</span>
														</p>
													)}
												</td>
											</tr>
										);
									})
								) : (
									<tr>
										<td>
											<div
												css={css`
													font-size: 2.5rem;
													color: grey;
												`}
											>
												Something isn't Right!
											</div>
										</td>
									</tr>
								)}
							</tbody>
						</Table>
						}
						
						<div className="p-4">
							<Pagination
								totalRecords={totalResults}
								key={totalResults}
								pageLimit={10}
								pageNeighbours={1}
								onPageChanged={(data) => {
									setCurrentPage(Math.max(1, data.currentPage));
								}}
							/>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<div>
						<Button variant="secondary" onClick={handleClose}>
							Close
						</Button>
					</div>
				</Modal.Footer>
			</Modal>
		</Fragment>
	);
}

const mapStateToProps = (state) => ({
	rooms: state.rooms.teamRooms,
});

export default connect(mapStateToProps, {getRoomsByTeam})(AssignFormToChannelModal);
