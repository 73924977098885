import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';

import { fetchWrapper, debounce } from '../../../../_helpers';
import { scrollToSelectedOption } from './SearchByClient';

const SearchByProvider = ({ provider, handleSelect, isDisabled, team }) => {
    const [loading, setLoading] = useState(false);
    const selectedOptionClass = `provider-${provider?.value}`;
    const fetchData = (inputValue, callback) => {
        setTimeout(async () => {
            setLoading(true);
            try {
                if (isDisabled) {
                    return callback([]);
                }
                const provider = await fetchWrapper.get(
                    `evv/provider?sortBy=name:asc&limit=10&${inputValue ? `&agencyName=${inputValue}` : ''}${
                        team ? `&team=${team}` : ''
                    }`
                );
                const tempArray = [];
                provider.results
                    .sort((a, b) => (a.agencyName.toLowerCase() > b.agencyName.toLowerCase() ? 1 : -1))
                    .forEach((element) => {
                        tempArray.push({
                            label: <div className={`provider-${element?.id}`}>{element.agencyName}</div>,
                            value: element.id,
                            ...element,
                        });
                    });
                callback(tempArray);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        }, 250);
    };
    const onSearchChange = (selectedOption) => {
        handleSelect(selectedOption);
    };
    useEffect(() => {
        if (!loading) {
            scrollToSelectedOption(selectedOptionClass);
        }
    }, [loading]);
    return (
        <div className="search-container">
            <span className="search-title">Select Provider:</span>
            <AsyncSelect
                loadOptions={debounce(fetchData)}
                onChange={onSearchChange}
                isClearable={true}
                defaultOptions={true}
                value={provider?.label && provider?.value ? provider : undefined}
                isDisabled={isDisabled}
                onMenuOpen={() => scrollToSelectedOption(selectedOptionClass)}
            />
        </div>
    );
};

export default SearchByProvider;
