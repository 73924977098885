import React, { useEffect, useState } from 'react';
import { Badge, Button, Modal, Form } from 'react-bootstrap';
import { fetchWrapper } from '../../../../../_helpers';
import { toast } from 'react-toastify';
import { styled, css } from 'twin.macro';

import { VscLoading } from 'react-icons/vsc';
function EditPublicGpsCategory({ show, handleClose }) {
	const [categories, setCategories] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [is24hrs, setIs24hrs] = useState(false);

	useEffect(() => {
		if (categories.length) {
			let category = categories.find((category) => category.name === show.activeCategoryName);
			if (category?._id) {
				setSelectedCategory(category);
			}
		}
	}, [categories, show.activeCategoryName]);
	useEffect(() => {
		const getCategories = async () => {
			try {
				const response = await fetchWrapper.get('/pins');
				setCategories(response.results);
			} catch (err) {
				console.log(err);
			}
		};
		getCategories();
	}, []);

	const assignCategoryToChannel = async () => {
		try {
			setIsLoading(true);
			let body = {
				premiumPinCategory: selectedCategory?._id,
			};
			if (is24hrs) {
				body.premiumTimeLimit = '24hours';
			} else {
				body.premiumTimeLimit = 'none';
			}
			await fetchWrapper.put(`/chatrooms/map/${show.id}/pin`, body);
			toast.success("Channel's category assigned successfully");
			setIsLoading(false);
			handleClose(selectedCategory);
		} catch (err) {
			console.log(err);
			setIsLoading(false);
		}
	};
	const handle24hrsChange = (event) => {
		setIs24hrs(event.target.checked);
	};
	return (
		<div>
			<Modal show={Boolean(show)} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Edit Category</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<StyledTimePinDiv>
						<h6
							css={css`
								color: #333;
							`}
						>
							For 24hours:
						</h6>
						<Form.Check type="checkbox" id="timedPin" value={is24hrs} onClick={handle24hrsChange} />
					</StyledTimePinDiv>
					<div>
						<h5
							css={css`
								color: #333;
							`}
						>
							Categories pin:
						</h5>
						<StyledCategoryItemsContainer>
							{categories.map((category) => (
								<StyledCategoryItem
									key={category._id}
									className={`${selectedCategory?._id === category._id ? 'active' : ''}`}
									onClick={() => setSelectedCategory(category)}
									isPremium={category.isPremium}
								>
									<div className="mr-4">
										<img
											src={category.pin}
											alt={category.name}
											width={50}
											height={50}
											style={{ objectFit: 'contain' }}
										/>
									</div>
									<div>{category.name}</div>
									<div>{category.isPremium && <Badge variant="secondary">Premium</Badge>}</div>
								</StyledCategoryItem>
							))}
						</StyledCategoryItemsContainer>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button disabled={isLoading} variant="secondary" onClick={assignCategoryToChannel}>
						{isLoading ? <VscLoading className="spin" /> : 'Assign'}
					</Button>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}

export default EditPublicGpsCategory;

const StyledCategoryItem = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 10px;
	border-radius: 5px;
	background: ${(props) => (props.className === 'active' ? '#50aeb0db' : '#e0f0ef')};
	color: ${(props) => (props.className === 'active' ? '#fff' : '#000')};
	margin-bottom: 5px;
	&:hover {
		background: #50aeb0ff;
		color: #fff;
	}
`;

const StyledTimePinDiv = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 1.3rem;
	input {
		margin-left: 1rem;
		cursor: pointer;
	}
`;

const StyledCategoryItemsContainer = styled.div`
	height: 40vh;
	overflow-y: auto;
`;
