import { styled, css } from 'twin.macro';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const StyledToolTip = styled.div`
    position: relative;

    .tooltipText {
        position: absolute;
        min-width: 125px;
        z-index: 9999999;
        visibility: hidden;
        opacity: 0;
        color: #fff;
        background-color: #000000ad;
        border-radius: 6px;
        font-size: 12px;
        top: -210%;
        padding: 0.4em 1em;
        text-align: center;
        user-select: none;
        transition:
            opacity ease-in-out 150ms,
            visibility ease-in-out 150ms;

        /* Apply custom styles */
        ${(props) =>
            props.cssStyles &&
            css`
                ${props.cssStyles}
            `}
    }

    .top {
        top: -130%;
        left: 30%;
    }

    .bottom {
        top: 50px;
        left: 20px;
        height: auto;
    }

    .left {
        top: 20px;
        left: -130px;
        height: auto;
    }

    .right {
        right: -30px;
    }

    :hover .tooltipText {
        transition-delay: 500ms;
        opacity: 1;
        visibility: visible;
    }
`;

const StyledBootstrapToolTip = styled.div`
    .customPopover > .popover-content {
        background: red !important;
    }

    .customPopover > .arrow {
        visibility: hidden;
    }
`;

export const ToolTip = ({ children, text, side, cssStyles }) => {
    return (
        <StyledToolTip cssStyles={cssStyles}>
            {text ? (
                <span
                    className={`tooltipText ${side ? side : 'top'}`}
                    css={css`
                        ${cssStyles}
                    `}
                >
                    {text}
                </span>
            ) : null}
            {children}
        </StyledToolTip>
    );
};

export const BootstrapToolTip = ({ placement = 'top', text = '', style = {}, children }) => (
    <StyledBootstrapToolTip>
        <OverlayTrigger
            placement={placement || 'top'}
            overlay={
                <Tooltip>
                    <span style={{ fontSize: '1.4rem', ...style }}>{text}</span>
                </Tooltip>
            }
        >
            {children}
        </OverlayTrigger>
    </StyledBootstrapToolTip>
);
