import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { arrows, pagination, paginationActive } from './PaginationStyles';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

const range = (from, to, step = 1) => {
	let i = from;
	const range = [];

	while (i <= to) {
		range.push(i);
		i += step;
	}

	return range;
};

class Pagination extends Component {
	constructor(props) {
		super(props);
		const { totalRecords = null, pageLimit = 30, pageNeighbours = 0, pageNo = 1 } = props;

		this.pageLimit = typeof pageLimit === 'number' ? pageLimit : 30;
		this.totalRecords = typeof totalRecords === 'number' ? totalRecords : 0;
		this.pageNeighbours = typeof pageNeighbours === 'number' ? Math.max(0, Math.min(pageNeighbours, 2)) : 0;

		this.totalPages = Math.ceil(this.totalRecords / this.pageLimit);

		this.state = { currentPage: pageNo };
	}

	componentDidMount() {
		this.gotoPage(1);
	}

	componentDidUpdate(prevProps) {
		this.totalPages = Math.ceil(this.props.totalRecords / this.props.pageLimit);
		this.pageLimit = this.props.pageLimit;
		this.pageNeighbours = this.props.pageNeighbours;

		if (this.props.pageNo && this.state.currentPage !== this.props.pageNo) {
			this.setState({ currentPage: this.props.pageNo });
		}
	}

	gotoPage = (page) => {
		const { onPageChanged = (f) => f } = this.props;

		const currentPage = Math.max(0, Math.min(page, this.totalPages));

		const paginationData = {
			currentPage,
			totalPages: this.totalPages,
			pageLimit: this.pageLimit,
			totalRecords: this.totalRecords,
		};

		this.setState({ currentPage }, () => onPageChanged(paginationData));
	};

	handleClick = (page, evt) => {
		evt.preventDefault();
		this.gotoPage(page);
	};

	handleMoveLeft = (evt) => {
		evt.preventDefault();
		this.gotoPage(this.state.currentPage - this.pageNeighbours);
	};

	handleMoveRight = (evt) => {
		evt.preventDefault();
		this.gotoPage(this.state.currentPage + this.pageNeighbours);
	};

	fetchPageNumbers = () => {
		const totalPages = this.totalPages;
		const currentPage = this.state.currentPage;
		const pageNeighbours = this.pageNeighbours;

		const totalNumbers = this.pageNeighbours * 2 + 1;
		const totalBlocks = totalNumbers + 2;

		if (totalPages > totalBlocks) {
			let pages = [];

			const leftBound = currentPage - pageNeighbours;
			const rightBound = currentPage + pageNeighbours;
			const beforeLastPage = totalPages - 1;

			const startPage = leftBound > 2 ? leftBound : 2;
			const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

			pages = range(startPage, endPage);

			const pagesCount = pages.length;
			const singleSpillOffset = totalNumbers - pagesCount - 1;

			const leftSpill = startPage > 2;
			const rightSpill = endPage < beforeLastPage;

			const leftSpillPage = LEFT_PAGE;
			const rightSpillPage = RIGHT_PAGE;

			if (leftSpill && !rightSpill) {
				const extraPages = range(startPage - singleSpillOffset, startPage - 1);
				pages = [leftSpillPage, ...extraPages, ...pages];
			} else if (!leftSpill && rightSpill) {
				const extraPages = range(endPage + 1, endPage + singleSpillOffset);
				pages = [...pages, ...extraPages, rightSpillPage];
			} else if (leftSpill && rightSpill) {
				pages = [leftSpillPage, ...pages, rightSpillPage];
			}

			return [1, ...pages, totalPages];
		}

		return range(1, totalPages);
	};

	render() {
		if (!this.totalRecords) return null;
		if (this.totalPages === 1) return null;

		const { currentPage } = this.state;
		const pages = this.fetchPageNumbers();

		return (
			<Fragment>
				<nav>
					<ul className="list-unstyled d-flex align-items-center">
						<li>
							<div css={arrows} aria-label="Previous" onClick={this.handleMoveLeft}>
								<BsChevronLeft className="icon" />
							</div>
						</li>
						{pages.map((page, index) => {
							if (page === LEFT_PAGE)
								return (
									<li key={index} className="mx-1">
										<a href="#0" aria-label="Previous" onClick={this.handleMoveLeft}>
											<span aria-hidden="true">...</span>
										</a>
									</li>
								);

							if (page === RIGHT_PAGE)
								return (
									<li key={index} className="mx-1">
										<a href="#0" aria-label="Next" onClick={this.handleMoveRight}>
											<span aria-hidden="true">...</span>
										</a>
									</li>
								);

							return (
								<li
									key={index}
									css={currentPage === page ? paginationActive : pagination}
									onClick={(e) => this.handleClick(page, e)}
								>
									{page}
								</li>
							);
						})}
						<li>
							<div css={arrows} aria-label="Previous" onClick={this.handleMoveRight}>
								<BsChevronRight className="icon" />
							</div>
						</li>
					</ul>
				</nav>
			</Fragment>
		);
	}
}

Pagination.propTypes = {
	totalRecords: PropTypes.number.isRequired,
	pageLimit: PropTypes.number,
	pageNeighbours: PropTypes.number,
	onPageChanged: PropTypes.func,
};

export default Pagination;
