import * as ActionTypes from '../actions/ActionTypes';

const initialState = {
	userStatus: {},
};

export default function UserStatus (state = initialState, action) {
	let tempUserStatus; 
	switch (action.type) {
		case ActionTypes.SET_ONLINE_STATUS:
			tempUserStatus = Object.assign({}, state.userStatus);

			if(tempUserStatus[action.payload]) {
				tempUserStatus[action.payload] = "online";
				return {...state, userStatus: tempUserStatus};
			}

			tempUserStatus[action.payload] = "online";
			return {...state, userStatus: tempUserStatus};
			
		case ActionTypes.SET_OFFLINE_STATUS:
			tempUserStatus = Object.assign({}, state.userStatus);

			if(tempUserStatus[action.payload]) {
				tempUserStatus[action.payload] = "offline";
				return {...state, userStatus: tempUserStatus};
			}

			tempUserStatus[action.payload] = "offline";
			return {...state, userStatus: tempUserStatus};
		default:
			return state;
	}
}
