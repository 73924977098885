import React from 'react';

import { Button, Modal } from 'react-bootstrap';
import { useState } from 'react';
import { BiTransferAlt } from 'react-icons/bi';
import { VscLoading } from 'react-icons/vsc';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { fetchWrapper } from '../../../../_helpers';
import { MdArrowForwardIos } from 'react-icons/md';
import Select from 'react-select';
import { css } from 'twin.macro';
import { UpdateAnEmployee } from '../../../../_redux/actions/ActionEVV';
import { toast } from 'react-toastify';
import { ToolTip } from '../../../../_elements/ToolTip';

function ChangeStaffChannel({ employee }) {
    const [show, setShow] = useState(false);

    return (
        <div>
            <ToolTip text={'Transfer ownership'} cssStyles={'top:-70% !important; min-width: 126px !important'}>
                <BiTransferAlt className="icon purple" onClick={() => setShow(true)} />
            </ToolTip>
            {show && <ChangeStaffChannelModal employee={employee} show={show} setShow={setShow} />}
        </div>
    );
}

export default ChangeStaffChannel;

const ChangeStaffChannelModal = ({ show, setShow, employee }) => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const [channels, setChannels] = useState([]);
    const [selectedChannel, setSelectedChannel] = useState();
    const teamId = employee.provider.team?._id || employee.provider.team?.id || employee.provider.team;
    useEffect(() => {
        const getProviderChatrooms = async () => {
            try {
                const response = await fetchWrapper.get(`/chatrooms?teamId=${teamId}&limit=2000&page=1`);
                let results = response?.results.map((chatroom) => {
                    return {
                        label: chatroom.title,
                        value: chatroom.id,
                    };
                });
                setChannels(results);
            } catch (err) {
                console.log(err);
            }
        };
        if (employee.provider) {
            getProviderChatrooms();
        }
    }, [employee]);

    const handleUpdate = async () => {
        try {
            setIsLoading(true);
            const response = await fetchWrapper.patch(
                `evv/employee/${employee.id || employee._id}/changeChatroom?team=${teamId}`,
                {
                    chatroom: selectedChannel.value,
                }
            );
            const responseClone = Object.assign({}, response);
            delete responseClone.user;
            dispatch(UpdateAnEmployee(responseClone));
            toast.success('Staff channel updated successfully');
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error);
            toast.success('Oops! Something went wrong');
        }
    };
    const handleChange = (channel) => {
        setSelectedChannel(channel);
    };
    return (
        <Modal
            dialogClassName="dialog-rounded"
            backdropClassName="modal-backdrop"
            show={show}
            onHide={() => setShow(false)}
            centered
            size="md"
        >
            <Modal.Header className="h3 d-flex justify-content-center">Change Staff Channel</Modal.Header>
            <Modal.Body className="modal-body">
                <div className="mb-3 d-flex  align-items-center">
                    <div className="d-flex align-items-center">
                        <div
                            className="d-flex align-items-center justify-content-center"
                            css={css`
                                background-color: ${employee.color};
                                color: white;
                                width: 62px;
                                height: 62px;
                                border-radius: 5px;
                                font-size: 2.4rem;
                                overflow: hidden;
                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            `}
                        >
                            {employee.user.avatar ? (
                                <img src={employee.user.avatar} alt="avatar" />
                            ) : (
                                <span>{employee.initials}</span>
                            )}
                        </div>
                        <div
                            css={css`
                                margin-left: 20px;
                            `}
                        >
                            <div
                                css={css`
                                    font-size: 2rem;
                                `}
                            >
                                Staff Name: {employee.firstName} {employee.lastName}
                            </div>
                            {employee.chatroom ? (
                                <div
                                    css={css`
                                        font-size: 1.8rem;
                                    `}
                                >
                                    <span>Channel Name: </span>
                                    <span>{employee.chatroom.title}</span>
                                </div>
                            ) : (
                                <div className="text-center">
                                    <h4>No chatroom found</h4>
                                    <p>Please create a chatroom for this client</p>
                                </div>
                            )}{' '}
                        </div>
                    </div>
                </div>

                {employee.provider ? (
                    <div>
                        <div
                            css={css`
                                font-size: 2rem;
                            `}
                        >
                            Provider:
                            <span className="ml-2">{employee?.provider?.agencyName}</span>
                        </div>
                    </div>
                ) : (
                    <div className="text-center">
                        <h4>No Provider found</h4>
                    </div>
                )}
                <div className="mt-4">Change Staff Channel</div>
                <div className="border rounded p-2 mt-2 ">
                    <div className="d-flex jusity-content-between align-items-center">
                        <div
                            css={css`
                                flex: 1;
                                display: flex;
                                flex-direction: column;
                            `}
                        >
                            <span
                                css={css`
                                    font-size: 1.4rem;
                                    color: #444;
                                `}
                            >
                                Current channel
                            </span>
                            {employee?.chatroom?.title}
                        </div>
                        <div
                            css={css`
                                margin: 0 1rem;
                                font-size: 3rem;
                                color: #50aeb0;
                            `}
                        >
                            <MdArrowForwardIos />
                        </div>
                        <div
                            css={css`
                                flex: 2;
                            `}
                        >
                            <span
                                css={css`
                                    font-size: 1.4rem;
                                    color: #444;
                                `}
                            >
                                New channel
                            </span>
                            <Select options={channels} onChange={handleChange} />
                        </div>
                    </div>
                    {selectedChannel && (
                        <div className="d-flex justify-content-end align-items-center mt-4">
                            <Button variant="primary" onClick={handleUpdate} disabled={isLoading}>
                                {isLoading ? <VscLoading /> : 'Update'}
                            </Button>
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex mt-4 flex-row justify-content-between w-100">
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setShow(false);
                        }}
                    >
                        Cancel
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};
