import { css, styled } from 'twin.macro';
import React, { useEffect, useState } from 'react';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import AvatarUserPanel from '../../Features/Common/AvatarUserPanel';

const UserInRoomStyled = styled.div`
	display: flex;
	width: 42%;
	align-items: center;
	padding: 0.5em;
	margin: 0.4rem 0.8rem;
	border: 1px solid #cecece;
	border-radius: 10px;
	transition: box-shadow 200ms, transform 200ms;
	cursor: pointer;
	&.selected {
		transform: translateY(-3px);
		box-shadow: 0 2px 8px gray;
	}
	&.notSelected:hover {
		transform: translateY(-2px);
		box-shadow: 0 1px 5px gray;
	}

	&.pointer-none {
		pointer-events: none;
	}

	.userImage {
		width: 3rem;
		height: 3rem;
		margin-right: 0.5rem;
		border-radius: 1000px;
		object-fit: contain;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		font-size: 14px;
	}
`;

const UserInRoom = ({ user, onSelect, isSelected = false, disabled, msg }) => {
	const handleSelect = () => {
		onSelect(user._id);
	};

	return (
		<UserInRoomStyled
			onClick={handleSelect}
			className={`userInRoom ${isSelected ? 'selected' : 'notSelected'}  ${
				disabled ? 'pointer-none' : ''
			}`}
			css={css`
				padding: 0.5em 1em;
				${disabled ? 'background-color:#efefef;': ''}
			`}
		>
			{msg ? (
				<span
					css={css`
						margin-right: 1em;
					`}
				>
					{msg}
				</span>
			) : null}
			{isSelected ? (
				<MdCheckBox
					css={css`
						font-size: 3rem;
						margin-right: 0.3rem;
						color: #50aeb0;
						min-width: 3rem;
					`}
				/>
			) : (
				<MdCheckBoxOutlineBlank
					css={css`
						font-size: 3rem;
						margin-right: 0.3rem;
						color: ${disabled ? '#808080': '#50aeb0'};
						min-width: 3rem;
					`}
				/>
			)}
			<div className="holder" css={css``}>
				<AvatarUserPanel item={user} />{' '}
			</div>{' '}
			{user?.name}
		</UserInRoomStyled>
	);
};

export default UserInRoom;
