import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { fetchWrapper, getTeamIdFromURL, debounce } from '../../../../../_helpers';
import PropTypes from 'prop-types';


const FormSelect = ({ teamId, channelId, types = [], isMulti = false, disabled, placeholder = "Select Form", isLoading, defaultOptions = [], handleSelectCallback, forms, setForms }) => {
    const team = teamId || getTeamIdFromURL();
    const [loading, setLoading] = useState(false);

    const fetchData = async (inputValue, callback) => {
        try {
            setLoading(true)
            let tempArray = [];
            const response = await fetchWrapper.get(`forms/?limit=50${channelId ? `&chatroom=${channelId}` : ''}${inputValue ? `&name=${inputValue}` : ''}${types?.length ? `&types=${types}`: ''}`);
            tempArray = response?.results?.filter((filterForm) => types.includes(filterForm?.type))
                .sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1))
                .map((element) => ({
                    ...element,
                    label: element?.name,
                    value: element?._id,
                }));
            callback(tempArray || []);
        }
        catch (error) {
            console.log(error);
        } finally {
            setLoading(false)
        }
    };

    const handleChange = (newValues, actionMeta) => {
        if (isMulti) {
            setForms((prevFormArr) => {
                return [...newValues]
            })
        } else {
            setForms(newValues);
        }
    };

    return (
        <div className="search-container">
            <AsyncSelect
                defaultOptions={defaultOptions?.filter(form => types?.includes(form?.type)) || []}
                loadOptions={debounce(fetchData, 1000)}
                isMulti={isMulti}
                onChange={handleChange}
                value={forms}
                placeholder={placeholder}
                isDisabled={disabled}
                isLoading={isLoading || loading}
            />
        </div>
    );
};

FormSelect.propTypes = {
    teamId: PropTypes.string,
    channelId: PropTypes.string,
    types: PropTypes.array,
    disabled: PropTypes.bool,
    handleSelectCallback: PropTypes.func,
    isMulti: PropTypes.bool,
    defaultOptions: PropTypes.array
}

export default FormSelect;
