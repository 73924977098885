import { css } from 'twin.macro';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
// import { Image } from 'cloudinary-react';
import { Badge, Table, Image } from 'react-bootstrap';

import { connect } from 'react-redux';

// helpers
import { getInitials, getRandomColor } from '../../_helpers';
import { profileInfoCss } from './styles/ProfileInforStyled';
import UserNameComponent from '../Features/Common/UserNameComponent';
import StatusMessage from './StatusMessage';

function ProfileInfo({ user }) {
	const userList = useMemo(() => {
		user.initials = getInitials(user.name);
		user.color = getRandomColor(user.id || user._id);
		return user;
	}, [user]);
	return (
		<div css={profileInfoCss}>
			<div className="d-flex mb-4">
				<div
					css={css`
						position: relative;
					`}
				>
					{user && user.avatar ? (
						<Image
							// cloudName={user?.avatar?.split('/')[3]}
							// loading="lazy"
							// gravity="faces"
							// publicId={user.avatar.split('/')[user.avatar.split('/').length - 1]}
							src={user.avatar}
							width="120"
							height="120"
							className="rounded-circle"
							style={{ objectFit: 'contain' }}
						/>
					) : (
						<div className="replacementImg avatar" style={{ backgroundColor: userList.color }}>
							<span className="initials">{userList.initials}</span>
						</div>
					)}
					<div
						css={css`
							position: absolute;
							bottom: 0%;
							width: 2.8rem;
							height: 2.8rem;
							overflow: hidden;
							background: ${user.status === 'active' ? '#38cc8f' : 'gray'};
							border-radius: 1000px;
							border: 3px solid #efefef;
							right: 8%;
						`}
					></div>
				</div>
				<div className="ml-4">
					<h1 className="font-weight-bold pt-2">{user.name}</h1>
					<UserNameComponent userName={user.username} isCancelable={true} />
					<div className="role">
						<Badge className="badge" variant="secondary">
							<h4 css={{ textTransform: 'capitalize' }}>{user.role}</h4>
						</Badge>
					</div>
				</div>
			</div>
			<StatusMessage />

			<div className="mt-4">
				<h3 className="font-weight-bold">Profile Info</h3>
			</div>
			<Table className="mb-4 mt-1" striped bordered hover>
				<tbody>
					<tr>
						<td>Email: </td>
						<td>{user.email}</td>
					</tr>
					<tr>
						<td>Address: </td>
						<td>{user.address && user.address}</td>
					</tr>
					<tr>
						<td>About: </td>
						<td>{user.about && user.about}</td>
					</tr>
				</tbody>
			</Table>
		</div>
	);
}
const mapStateToProps = (state) => ({
	user: state.user.user,
});

ProfileInfo.propTypes = {
	user: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(ProfileInfo);
