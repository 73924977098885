import { css } from 'twin.macro';

export const formPickerHeader = css`
    background-color: #50aeb0;
    min-width:250px;
    padding: 1rem;
    position: sticky;
    top: 0;
    font-weight: bold;
    margin-bottom:0px;
    color:white;
`;

export const formBuilderClose = css`
    width:2rem;
    height: 2rem;
    padding: .5rem 0.8rem;  
    font-size: 1.5rem;
    font-weight: bold;
    margin-left:1rem;
`;
