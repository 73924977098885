import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React, { Fragment } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import { FaEye } from 'react-icons/fa';
import { css } from 'twin.macro';
import notfound from '../../../../../assets/notfound.svg';
import ViewForm from '../../../../Admin/Form/ViewForm';
import { ActionStyles } from '../../../../Admin/reusableStyles';
import FileMessage from '../../../Common/FileMessage';
import FormPreviewModal from '../../FormPreviewModal';
import { FormPackageTable } from '../TeamSettingFormPackage';
import { ModalCSS } from '../TeamSettingsGroupForms';

function AllDocuments({ search, searchData, setKey }) {
	const totalDocuments = searchData.files?.totalResults;
	const totalSubmittedForms = searchData.submissons?.totalResults;
	const totalForms = searchData.forms?.totalResults;
	const allDocuments = totalDocuments + totalSubmittedForms + totalForms;
	let documentCounter = 0;
	let submissionCounter = 0;
	let formCounter = 0;

	const [submittedFormPreview, setSubmittedFormPreview] = React.useState(null);

	const hideSubmittedFormPreview = () => {
		setSubmittedFormPreview(false);
	};

	const handleOnClick = (form) => {
		setSubmittedFormPreview(form);
	};

	return (
		<div>
			{totalDocuments > 0 && (
				<div
					css={css`
						margin-bottom: 30px;
					`}
				>
					<StickyHeading className="font-weight-bold">Documents ({totalDocuments})</StickyHeading>
					<FormPackageTable className="top-30" striped bordered hover>
						<thead>
							<tr>
								<th>S.N</th>
								<th>Name</th>
								<th>Type</th>
								<th>Sender</th>
								<th>Channel</th>
								<th> Date </th>
							</tr>
						</thead>
						<tbody>
							{searchData.files.results.length > 0 ? (
								searchData.files.results.map((file) => {
									documentCounter++;
									return (
										<tr key={`${file._id || file.id}_${documentCounter}`}>
											<td>{documentCounter}</td>
											<td
												css={css`
													max-width: 300px;
													word-break: break-word;
												`}
											>
												{/* {(file.files?.length > 0 ? file.files : file.images).map((file) => ( */}
												<FileMessage
													key={file.url}
													file={file.url}
													displayMessage={file?.name?.split("/").at(-1)}
													documentSettings={true}
												/>
												{/* ))} */}
											</td>
											<td>{file.refModel.replace(/([a-z0-9])([A-Z])/g, "$1 $2")}</td>
											<td>{file.user ? file.user.name : '-'}</td>
											<td >
												<div css={css`max-height: 12rem; overflow: auto;`}>{file.rooms?.map(room => <a
													css={css`
																	font-size: 16px;
																	display: block;
																`}
													href={`/teams/${room?.id}`}
													className={`${room?.id ? '' : 'disabled'
														} btn btn-link`}
												>
													{room?.title}
												</a>)}
												</div>
											</td>
											<td>{dayjs(file.updatedAt).format('MMM D, YYYY')}</td>
										</tr>
									);
								})
							) : (
								<tr colSpan="200">
									<td>No files found</td>
								</tr>
							)}
						</tbody>
					</FormPackageTable>
					{totalDocuments > searchData.submissons?.results?.length && (
						<div>
							<Button
								css={css`
									font-size: 20px;
								`}
								variant="link"
								onClick={() => setKey('team_forms')}
							>
								{' '}
								See in detail ...
							</Button>
						</div>
					)}
				</div>
			)}

			{totalSubmittedForms > 0 && (
				<div
					css={css`
						margin-bottom: 30px;
					`}
				>
					<StickyHeading className="font-weight-bold">Submitted Forms ({totalSubmittedForms})</StickyHeading>
					<div>
						<FormPackageTable className="top-30" striped bordered hover>
							<thead>
								<tr>
									<th>S.N</th>
									<th>Form name</th>
									<th>Submitted by</th>
									<th>Submitted on</th>
									<th>Channel</th>
								</tr>
							</thead>
							<tbody>
								<Fragment>
									{searchData.submissons?.results?.length > 0 &&
										searchData.submissons.results
											.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
											.map((form) => {
												submissionCounter++;

												return (
													<tr key={`${form.id}_${submissionCounter}`}>
														<td>{submissionCounter}</td>
														<td
															css={css`
																font-size: 16px;
															`}
															className="btn btn-link"
															onClick={() => handleOnClick(form)}
														>
															<FaEye className="mr-3" />
															{form.name}
														</td>
														<td>{form.userData?.name || 'Unknown user'}</td>
														<td>
															{dayjs(form.updatedAt).format('hh:mm:ss A, MMM D, YYYY')}
														</td>
														<td>{form.chatroomData?.title || ''}</td>
													</tr>
												);
											})}
								</Fragment>
							</tbody>
						</FormPackageTable>
						{totalSubmittedForms > searchData.submissons?.results?.length && (
							<div>
								<Button
									css={css`
										font-size: 20px;
									`}
									variant="link"
									onClick={() => setKey('submitted_forms')}
								>
									{' '}
									See in detail ...
								</Button>
							</div>
						)}
					</div>
				</div>
			)}

			{totalForms > 0 && (
				<div
					css={css`
						margin-bottom: 30px;
					`}
				>
					<StickyHeading className="font-weight-bold">Form Plan ({totalForms})</StickyHeading>
					<div>
						<FormPackageTable className="top-30" striped bordered hover>
							<thead>
								<tr>
									<th>S.N</th>
									<th>Form name</th>
									<th>Form type</th>
									<th>Channel</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								<Fragment>
									{searchData.forms.results.length > 0 &&
										searchData.forms.results
											.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
											.map((form) => {
												formCounter++;

												return (
													<tr key={`${form.id}_${formCounter}`}>
														<td>{formCounter}</td>
														<td
															css={css`
																font-size: 16px;
															`}
														>
															{form.name}
														</td>
														<td>{form.type}</td>
														<td>
															{form.chatroom?.map((chatroom) => (
																<span key={chatroom._id}>{chatroom.title}</span>
															)) || ''}
														</td>
														<td>
															<ActionStyles onClick={() => handleOnClick(form)}>
																<FormPreviewModal form={form} />
															</ActionStyles>
														</td>
													</tr>
												);
											})}
								</Fragment>
							</tbody>
						</FormPackageTable>
						{totalForms > searchData.submissons?.results?.length && (
							<div>
								<Button
									css={css`
										font-size: 20px;
									`}
									variant="link"
									onClick={() => setKey('form_package')}
								>
									{' '}
									See in detail ...
								</Button>
							</div>
						)}
					</div>
				</div>
			)}

			{allDocuments === 0 && (
				<div
					css={css`
						display: flex;
						flex-direction: column;
						align-items: center;
						margin: 50px;
					`}
				>
					<img width={300} src={notfound} alt="not found" />
					<h2
						css={css`
							margin-top: 40px;
						`}
						className="font-weight-bold mb-2"
					>
						We couldn't find any results for "{search}"
					</h2>
					<p>Check for spelling or try searching for another term</p>
				</div>
			)}

			{submittedFormPreview && (
				<Modal
					css={ModalCSS}
					show={submittedFormPreview}
					backdropClassName="bg-dark"
					onHide={hideSubmittedFormPreview}
					centered
				>
					<Modal.Header closeButton>
						<h4
							css={css`
								margin: 0;
							`}
						>
							{submittedFormPreview?.name}
						</h4>
					</Modal.Header>
					<Modal.Body className="modal-body">
						<ViewForm formId={submittedFormPreview.id || submittedFormPreview._id} />
					</Modal.Body>
				</Modal>
			)}
		</div>
	);
}

export default AllDocuments;

const StickyHeading = styled.h3`
	position: sticky;
	top: 0;
	z-index: 2;
	height: 25px;

	background: white;
`;
