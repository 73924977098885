import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import Linkify from 'react-linkify';
import { marginColor } from './Checkbox';
import { fetchWrapper, getRoomIdFromURL, SafeJSONParse } from '../../../../_helpers';
import { toast } from 'react-toastify';

const getTeamIdFromTeamRooms = (teamRooms, roomId) => {
    let teamId;
    for (const team of Object.values(teamRooms)) {
        const matchedRoom = (team?.rooms || []).find((room) => room?._id === roomId);
        if (matchedRoom) {
            teamId = matchedRoom.team;
            break;
        }
    }
    return teamId;
};

const DynamicSelect = ({ error, data, isPreview, isDisabled, elementRef, teamRooms }) => {
    const roomId = getRoomIdFromURL();
    const teamId = getTeamIdFromTeamRooms(teamRooms, roomId);
    const viewMode = data?.isFetched || data?.value;
    const selectedValue = SafeJSONParse(data?.value)?.textLabel;
    const [dataList, setDataList] = useState([]);
    const [loading, setIsloading] = useState(false);
    const fetchData = async () => {
        try {
            setIsloading(true);
            let options;
            if (data?.key === 'AccrualPolicySelect') {
                const results = await fetchWrapper.get(
                    `evv/payroll/zeal/employees/accrual-policies?submitted=false&team=${teamId}`
                );
                options = (results?.accrualPolicies || []).map((policy) => {
                    const text = `${policy?.policy_code} - ${policy?.policy_name} [${policy?.policy_status}]`;
                    return {
                        text,
                        label: text,
                        // textLabel is required in dynamic select value
                        value: JSON.stringify({ ...policy, textLabel: text }),
                        id: policy?.policy_code,
                    };
                });
            }
            setDataList(options);
        } catch (err) {
            if (err?.name === 'AbortError') return;
            console.log('err');
            toast.error(err?.message || 'Something went wrong');
        } finally {
            setIsloading(false);
        }
    };
    useEffect(() => {
        if (teamId && !viewMode) {
            fetchData();
        }
    }, [teamId, viewMode]);
    return (
        <Form.Group controlId="formGridEmail">
            <Form.Label>
                <h5 className="warpIt">
                    {data.fieldName}
                    <span css={marginColor}>{(data.hasOwnProperty('required') ? data.required : true) && '*'}</span>
                </h5>
            </Form.Label>

            {data.instruction && (
                <pre className="small text-muted warpIt preStyles">
                    <Linkify
                        componentDecorator={(decoratedHref, decoratedText, key) => (
                            <a target="blank" href={decoratedHref} key={key}>
                                {decoratedText}
                            </a>
                        )}
                    >
                        {data.instruction}
                    </Linkify>
                </pre>
            )}
            {loading ? <span>Loading data ...</span> : null}
            <Form.Control
                as="select"
                name={!isPreview ? data.name || `Select-${data._id}` : 'Select'}
                ref={
                    elementRef &&
                    elementRef({
                        required: data.hasOwnProperty('required') ? data.required : true,
                        ...(data.validation || {}),
                    })
                }
                disabled={data.value || data.isDisabled || isDisabled}
            >
                {(viewMode ? data?.value : dataList?.length) &&
                    (viewMode ? [{ value: selectedValue, text: selectedValue }] : dataList).map((option) => (
                        <option value={option.value} key={option.value}>
                            {option.text}
                        </option>
                    ))}
            </Form.Control>
            <Form.Text className="text-danger">
                {error && error.type === 'required' ? error?.message || 'This field is required' : error?.message}
            </Form.Text>
        </Form.Group>
    );
};
const mapStateToProps = (state) => ({
    teamRooms: state.rooms.teamRooms,
});

export default connect(mapStateToProps, {})(DynamicSelect);
