import styled from '@emotion/styled';
import { AdminTable } from '../../../../Admin/reusableStyles';

export const StyledContainer = styled('div')`
	width: 100%;
	.input {
		border-color: #dadada;
		border-style: solid;
		padding: 0.51em;
		width: 35%;
		border-radius: 5px;
		outline: none;
		transition: border-color 300ms;
		:focus {
			border-color: #50aeb0;
		}
	}
`;

export const TableCSS = styled(AdminTable)`
	width: 100%;
	.owner {
		background-color: #e0f0ef;
		border: 1px solid #50aeb0;
		color: #50aeb0;
		border-radius: 5px;
		width: 150px;
		height: 30px;
		align-self: center;
	}
	.teamManager {
		background-color: #FFD68A;
		border: 1px solid #754C00;
		color: #754C00;
		border-radius: 5px;
		width: 150px;
		height: 30px;
		align-self: center;
	}
	.role-select {
		margin-top: 7.5px;
		width: 150px;
		border-radius: 5px;
		padding: 5px;
		color: #1c1c1c;
		background: white;
		cursor: pointer;
		&:focus {
			outline: none;
			border: 1px solid #50aeb0;
		}
		&:disabled {
			cursor: default;
		}
	}
	thead {
		background-color: #e0f0ef;
		border: none;
		height: 40px;
		padding: 5px;
		border-radius: 0px 5px 5px 0px;

		tr {
			border: none;
			height: 40px;
		}
	}
`;
