import { css } from 'twin.macro';
import React, { Fragment, useEffect } from 'react';
import Switch from 'react-switch';
import { LobbyContainer } from './styles/LobbyStyles';
import { connect } from 'react-redux';
import { getInitials, getRandomColor } from '../../../_helpers';
import { VscLoading } from 'react-icons/vsc';

const Lobby = ({
	user,
	username,
	roomName,
	permissionState,
	seconds,
	handleSubmit,
	connecting,
	handleAudio,
	handleVideo,
	audio,
	video,
	// isJoinable
}) => {	
	return (
		<LobbyContainer>
			<button
				className="BlueButton"
				css={css`
					position: fixed;
					top: 3rem;
					right: 10rem;
					padding: 1rem 2rem !important;
				`}
				onClick={() => {
					window.close();
				}}
			>
				Close
			</button>
			<div className="container">
				<div className="main_container">
					<div className="infoContainer">Choose your audio and video settings for the Meeting.</div>
					<div className="controlContainer">
						<div className="userInfoDiv">
							{user.avatar ? (
								<img
									className="sidebar-avatar"
									src={
										user.avatar.startsWith('http:')
											? user.avatar.split('http:').join('https:')
											: user.avatar || null
									}
									alt="logo"
									css={css`
										width: 12rem;
										height: 12rem;
										border-radius: 50%;
										object-fit: cover;
										object-position: center;
									`}
								/>
							) : (
								<div
									className="replacementImg avatar"
									css={css`
										background: ${getRandomColor(user.id)};
										width: 12rem;
										height: 12rem;
										border-radius: 50%;
										object-fit: cover;
										object-position: center;
										display: flex;
										justify-content: center;
										align-items: center;
									`}
								>
									<span
										css={css`
											font-size: 5rem !important;
											margin-top: 0 !important;
											color: white !important;
										`}
									>
										{getInitials(user.name)}
									</span>
								</div>
							)}
							<div
								className="mt-1 mb-4"
								css={css`
									font-size: 2rem;
									font-weight: bold;
								`}
							>
								{user.name}
							</div>
						</div>

						<form onSubmit={handleSubmit}>
							<div className="centerContainer">
								{permissionState === 'granted' ? (
									<Fragment>
										{connecting ? (
											<p>
												<VscLoading className="spin" /> Joining now ...
											</p>
										) : (
											// isJoinable ? <button type="submit">Join</button>
											// 	:
												<p>
													You will be connecting to the meeting room in <b>{seconds}</b>{' '}
													seconds.
												</p>
										)}
									</Fragment>
								) : (
									<p className="text-danger">{permissionState}</p>
								)}
								{/* <button className="BlueButton" type="submit" disabled={connecting}>
									{connecting ? 'Connecting ...' : 'Join'}
								</button> */}
							</div>
							<div className="controlsDiv">
								<div className="d-flex align-items-center">
									<label htmlFor="audio">Audio:</label>
									<span
										css={css`
											margin: 0 1rem;
										`}
									>
										<Switch
											id="audio"
											onColor="#2e9a9c"
											onChange={(checked) => {
												let event = {
													target: {
														checked: checked,
													},
												};
												if (!connecting) {
													handleAudio(event);
												}
											}}
											checked={audio}
											checkedIcon={false}
											uncheckedIcon={false}
											disabled={connecting}
										/>
									</span>
								</div>
								<div className="d-flex  align-items-center">
									<label htmlFor="video">Video:</label>
									<span
										css={css`
											margin: 0 1rem;
										`}
									>
										<Switch
											id="video"
											onColor="#2e9a9c"
											onChange={(checked) => {
												let event = {
													target: {
														checked: checked,
													},
												};
												if (!connecting) {
													handleVideo(event);
												}
											}}
											checked={video}
											checkedIcon={false}
											uncheckedIcon={false}
											disabled={connecting}
										/>
									</span>
								</div>
							</div>
							<div
								css={css`
									text-align: center;
									font-size: 13px;
									margin-top: 1rem;
								`}
							>
								Note: To join the meeting, make sure you have allowed microphone permission.
							</div>
						</form>
					</div>
				</div>
			</div>
		</LobbyContainer>
	);
};

const mapStateToProps = (state) => ({
	user: state.user.user,
});

export default connect(mapStateToProps)(Lobby);
