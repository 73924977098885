import React,{ useState } from "react";
import { useForm } from 'react-hook-form';
import { StyledForm } from '../Provider/styles';
import formStructure2 from "./formStructure.json"
import WorklioFormBuilder from '../components/WorklioFormBuilder';
import {  Button } from 'react-bootstrap';
import { css } from 'twin.macro';
import { VscLoading } from 'react-icons/vsc';


const WorklioForm = ({
	formStructure,
	onSubmit,
	isFormSubmitting,
	fetchingForm,
	submitLoading,
})=>{

	const { register, handleSubmit, setValue, errors, getValues, setError, clearErrors } = useForm({
		mode:'all'
	});

    const handleOnSubmit =  (data) => {
        try{
            console.log("form",data)
			// return;

			// console.log("errors=>",errors)
			const result = Object.entries(data).map(([taxId, value]) => ({ taxId, value : value.toString() }));
			// console.log(result)
			onSubmit(result)
        }catch(e){
            console.log("err",e)
        }
	};

return (
	<StyledForm onSubmit={handleSubmit(handleOnSubmit)}>
		{
			fetchingForm ? 
				<div
				css={css`
					margin: 0;
					padding-left: 0.8rem;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;
					height:5rem;
				`}
				>   
					<VscLoading className="spin" />
					<span className="ml-2">Loading Worklio FormData...</span> 
				</div>
		:
		(
			formStructure?.effectiveFrom  ? 
				<React.Fragment>
					<WorklioFormBuilder
						formStructure={formStructure}
						register={register}
						setValue={setValue}
						getValues={getValues}
						errors={errors}
						setError={setError}
						clearErrors={clearErrors}
					/>
					<Button className="btn my-2" variant="primary" css={css`width: 100px;`} type="submit" disabled={isFormSubmitting} > 
						{isFormSubmitting ? <VscLoading className="spin" />  : <span>Submit</span>}  
					</Button>
				</React.Fragment>
			: "Couldn't fetch form !"
		)
		}
		{/* <React.Fragment>
			<WorklioFormBuilder
				formStructure={formStructure2}
				// formStructure={formStructure}
				register={register}
				setValue={setValue}
				getValues={getValues}
				errors={errors}
				setError={setError}
				clearErrors={clearErrors}
			/>
			<Button className="btn my-2" variant="primary" css={css`width: 100px;`} type="submit" disabled={isFormSubmitting} > 
				{isFormSubmitting ? <React.Fragment><VscLoading className="spin" /> Submitting </React.Fragment> : "Submit"}  
			</Button>
		</React.Fragment> */}
	</StyledForm>
);	
}
export default WorklioForm;