import React from 'react';
import { css } from 'twin.macro';
import {  Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Body } from './styles';
import { IoMdArrowBack } from 'react-icons/io';

function EvvCreateModal({ type, showModal, hideModal, content, goBack }) {
	return (
		<Modal show={showModal} onHide={hideModal} centered backdrop="static">
			<Modal.Header closeButton>
				<div className="header">
					<h4
						className="d-flex align-items-center "
						css={css`
							margin: 0;
						`}
					>
						{goBack && (
							<div
								className="d-flex justify-content-end align-items-center"
								css={css`
									position: relative;
								`}
							>
								<OverlayTrigger
									placement="top"
									delay={{ show: 150, hide: 50 }}
									overlay={
										<Tooltip id="button-tooltip">
											<div>Go back</div>
										</Tooltip>
									}
								>
									<div
										css={css`
											width: 30px;
											height: 30px;
											display: flex;
											justify-content: center;
											align-items: center;
											border-width: 3px;
											padding: 0;
											border: 1px solid #50aeb0;
											color: #50aeb0;
											border-radius: 5px;
											cursor: pointer;
											margin-right: 0.8rem;
										`}
										onClick={goBack}
									>
										<IoMdArrowBack fontSize="18px" />
									</div>
								</OverlayTrigger>
							</div>
						)}
						Create New <span className="text-capitalize mx-2">{type}</span>
					</h4>
				</div>
			</Modal.Header>
			<Modal.Body className="modal-body">
				<Body>{content}</Body>
			</Modal.Body>
		</Modal>
	);
}

export default EvvCreateModal;
