import styled from '@emotion/styled';
import React, { useState } from 'react';
import { Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { BsDownload, BsUpload } from 'react-icons/bs';
import { VscLoading } from 'react-icons/vsc';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchWrapper } from '../../../../_helpers';

import ProviderSample from './provider_sample.csv';
import ServiceSample from './service_sample.csv';

import { BsCheck2Circle } from 'react-icons/bs';
import { AiOutlineClose } from 'react-icons/ai';
function CsvBulkUpload({ type, hideModal }) {
	const [dragActive, setDragActive] = useState(false);
	const [isUploading, setIsUploading] = useState(false);
	const [hasUploadCompleted, setHasUploadCompleted] = useState(false);
	const organizationId = useSelector((state) => state.organizations?.organizations[0]?.id);
	const [showTips, setShowTips] = useState(false);

	// handle drag events
	const handleDrag = function (e) {
		e.preventDefault();
		e.stopPropagation();
		if (e.type === 'dragenter' || e.type === 'dragover') {
			setDragActive(true);
		} else if (e.type === 'dragleave') {
			setDragActive(false);
		}
	};

	//handle File upload
	const handleFileUpload = async (file) => {
		const ext = file.name.split('.').pop();
		if (ext !== 'csv') {
			return toast.error('Please upload a csv file');
		}
		setIsUploading(true);
		const formData = new FormData();
		formData.append('csv', file);
		formData.append('organization', organizationId);
		let url;
		if (type === 'provider') {
			url = 'evv/provider/csv';
		} else if (type === 'service') {
			url = 'evv/service/csv';
		}
		try {
			const response = await fetchWrapper.post(url, formData, true);
			setIsUploading(false);
			setHasUploadCompleted(response);
		} catch (error) {
			setIsUploading(false);
			console.log(error);
		}
	};
	// triggers when file is dropped
	const handleDrop = function (e) {
		e.preventDefault();
		e.stopPropagation();
		setDragActive(false);
		if (e.dataTransfer.files && e.dataTransfer.files[0]) {
			handleFileUpload(e.dataTransfer.files[0]);
		}
	};

	// triggers when file is selected with click
	const handleChange = function (e) {
		e.preventDefault();
		if (e.target.files && e.target.files[0]) {
			handleFileUpload(e.target.files[0]);
		}
	};

	const providerCsvTips = [
		'Download the sample csv file and fill the fields with the correct values.',
		'State, Agency and Owner are required fields. Missing required fields will cause the provider creation to fail.',
		'State is two letter abbreviation of the state name. For example, "CA" is for California.',
		'Agency is the name of the agency that the provider is associated with. For example, "San Francisco County Health Department". And the agency name must not already be in our system.',
		'Owner is the email of the user already present in the system.',
	];

	const serviceCsvTips = [
		'Download the sample csv file and fill the fields with the correct values.',
		'PayerID, ProgramOffice, HCPCS, Description, and State are required fields. Missing required fields will cause the service creation to fail.',
		'State is two letter abbreviation of the state name. For example, "CA" is for California.',
	];
	let csvSampleFile = '';
	let tips = [];
	switch (type) {
		case 'provider':
			csvSampleFile = ProviderSample;
			tips = providerCsvTips;
			break;

		case 'service':
			csvSampleFile = ServiceSample;
			tips = serviceCsvTips;
			break;
		default:
			csvSampleFile = null;
	}

	const handleToggleTips = () => {
		setShowTips((prev) => !prev);
	};

	if (hasUploadCompleted) {
		const successRecordsCount = hasUploadCompleted?.successData?.length || 0;
		const failedRecordsCount = hasUploadCompleted.rejectedData.length || 0;
		const hasError = failedRecordsCount > 0;
		return (
			<Container>
				<BorderContainer>
					{hasError ? (
						<div className="py-4">
							<h6 className="d-flex align-items-center justify-content-center text-danger">
								<AiOutlineClose className="mr-2 mb-0" color="#c93535" size="18" />
								Error occurred
							</h6>
							<div className="px-3 text-dark">
								<p className="mt-4 mb-0">
									There were
									<span className="mx-2">{failedRecordsCount + successRecordsCount}</span>
									records in the csv file.
								</p>

								<p className="mt-2 mb-0">
									{successRecordsCount > 0 && (
										<span>
											Only{' '}
											<span className="text-success mr-2">
												{successRecordsCount} {type}s
											</span>
											were created successfully.
										</span>
									)}
								</p>
								<p className="mt-1">
									{failedRecordsCount} records failed to create{' '}
									<span className="text-capitalize">{type}</span>.
								</p>
								<StyledTipsContainer>
									<div className="d-flex justify-content-end align-items-center">
										<OverlayTrigger
											key={'123'}
											placement="top"
											delay={{ show: 150, hide: 50 }}
											overlay={
												<Tooltip id="button-tooltip">
													<div>Click to view tips</div>
												</Tooltip>
											}
										>
											<div
												className="relative text-right text-small text-secondary tipsbtn"
												onClick={handleToggleTips}
											>
												Tips?
											</div>
										</OverlayTrigger>
									</div>

									{showTips && <TipsList csvTips={tips} type={type} />}
								</StyledTipsContainer>
								<p>
									Please check the below csv file with the{' '}
									<b className="text-warning mx-2">"Tips?"</b> and try again.
								</p>
								<p>
									<a
										className="text-primary cursor-pointer"
										href={window.URL.createObjectURL(
											new Blob([hasUploadCompleted?.csv], { type: 'text/csv' })
										)}
										download
									>
										{' '}
										Download Failed Records CSV File{' '}
									</a>
								</p>
							</div>
						</div>
					) : (
						<div className="py-4">
							<h6 className="d-flex align-items-center justify-content-center text-primary">
								<BsCheck2Circle className="mr-2 mb-0" color="#50aeb0" />
								<span className="text-capitalize mr-2">{type}s</span> Created Succesfully
							</h6>
							<div className="px-3 text-dark">
								<p className="mt-4">
									All
									<span className="mx-1">{type}s</span> from the csv file is created.
								</p>
								<p>
									Total {type}s created: {successRecordsCount}
								</p>
							</div>
						</div>
					)}
				</BorderContainer>

				{hasError && (
					<BorderContainer className="mt-4" onDragEnter={handleDrag} dragActive={dragActive}>
						<label>
							{dragActive ? <BsDownload /> : <BsUpload />}
							<div className="placeholder">
								Click or drag and drop in this area to upload <b>.csv</b> file and create {type}s
							</div>
							<input type="file" hidden readOnly onChange={handleChange} accept=".csv" />
						</label>
						{dragActive && (
							<div
								id="drag-file-element"
								onDragEnter={handleDrag}
								onDragLeave={handleDrag}
								onDragOver={handleDrag}
								onDrop={handleDrop}
							></div>
						)}
					</BorderContainer>
				)}
			</Container>
		);
	}
	return (
		<Container>
			{!isUploading && (
				<div className="d-flex justify-content-center align-item-center flex-column mb-4">
					<div className="text-center text-dark">
						Download below csv sample file, fill the file and upload it above.
					</div>
					<div className="text-center ">
						<StyledDownload href={csvSampleFile} download>
							<BsDownload className="mr-3" />
							<span className="text-capitalize">{type}</span>
							-sample.csv
						</StyledDownload>
					</div>
					<StyledTipsContainer>
						<div className="d-flex justify-content-end align-items-center">
							<OverlayTrigger
								key={'123'}
								placement="top"
								delay={{ show: 150, hide: 50 }}
								overlay={
									<Tooltip id="button-tooltip">
										<div>Click to view tips</div>
									</Tooltip>
								}
							>
								<div
									className="relative text-right text-small text-secondary tipsbtn"
									onClick={handleToggleTips}
								>
									Tips?
								</div>
							</OverlayTrigger>
						</div>
						{showTips && <TipsList csvTips={tips} type={type} />}
					</StyledTipsContainer>
				</div>
			)}

			<Row>
				{isUploading ? (
					<BorderContainer>
						<label>
							<VscLoading className="spin" color="#50aeb0" size="48" />
							<div className="my-3">{type} are being created ...</div>
							<div className="d-flex flex-column align-items-center justify-content-center">
								<div>Please check back after few minutes to see the results.</div>
								<a href="/admin" target="_blank">
									Continue Optonome in new tab
								</a>
							</div>
						</label>
					</BorderContainer>
				) : (
					<BorderContainer onDragEnter={handleDrag} dragActive={dragActive}>
						<label>
							{dragActive ? <BsDownload /> : <BsUpload />}
							<div className="placeholder">
								Click or drag and drop in this area to upload <b>.csv</b> file and create {type}s
							</div>
							<input type="file" hidden readOnly onChange={handleChange} accept=".csv" />
						</label>
						{dragActive && (
							<div
								id="drag-file-element"
								onDragEnter={handleDrag}
								onDragLeave={handleDrag}
								onDragOver={handleDrag}
								onDrop={handleDrop}
							></div>
						)}
					</BorderContainer>
				)}
			</Row>
		</Container>
	);
}

export default CsvBulkUpload;

const StyledTipsContainer = styled.div`
	.tipsbtn {
		font-size: 16px;
		cursor: pointer;
	}
`;
const StyledDownload = styled.a`
	font-size: 20px;
	font-weight: 500;
`;
const BorderContainer = styled.div`
	border: ${(props) => (props.dragActive ? '3px dashed #50aeb0' : '1px solid #333')};
	border-radius: 5px;
	width: 100%;
	border-style: 'solid';
	position: relative;

	label {
		padding: 30px 0;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		width: 100%;
		height: 100%;
		cursor: pointer;
		.placeholder {
			width: 60%;
			font-size: 14px;
			text-align: center;
			color: #333;
		}
	}
	svg {
		font-size: 32px;
		color: #333;
		color: ${(props) => (props.dragActive ? '#50aeb0' : '#333')};
		margin-bottom: 1rem;
	}
	#drag-file-element {
		position: absolute;
		width: 100%;
		height: 100%;
		border-radius: 1rem;
		top: 0px;
		right: 0px;
		bottom: 0px;
		left: 0px;
	}
`;

const TipsList = ({ csvTips, type }) => {
	return (
		<StyledTipsList>
			<h6>Tips on creating {type} successfully.</h6>
			<ul>
				{csvTips.map((tip) => (
					<li key={tip} className="text-dark">
						{tip}
					</li>
				))}
			</ul>
		</StyledTipsList>
	);
};

const StyledTipsList = styled.div`
	background-color: #f6f6f6;
	padding: 10px 10px 10px 10px;
	border-radius: 10px;
	li {
		list-style-position: inside;
		margin-bottom: 1rem;
		font-size: 14px;
	}
`;
