import { useState, Fragment } from 'react';

//components

//styled
import { CreateEventStyle } from './Styles/EventModalStyles';
import { EventModalForm } from './EventModalForm';
import { BsFillPlusSquareFill, BsPencil } from 'react-icons/bs';
import { Button } from 'react-bootstrap';
import { ToolTip } from '../../../_elements/ToolTip';
import { AiOutlineCopy } from 'react-icons/ai';

function EventModal({ data, channel, handleSubmitTask, shouldEdit = true, teamIds, isPackageModal }) {
	const [show, setShow] = useState(false);
	const handleClose = () => {
		setShow(false);
	};
	const handleShow = () => {
		setShow(true);
	};
	const mode = data ? shouldEdit ? 'edit' : 'copy' : 'create';
	return (
		<Fragment>
			{mode === 'edit' ? (
				<ToolTip text={'Edit'} side={'top'} cssStyles={"top:-70% !important; min-width: fit-content !important"}>
					<BsPencil onClick={handleShow} className="icon view" />
				</ToolTip>
			) : mode === 'copy' ? (
				<ToolTip text={'Copy'} cssStyles={"top:-70% !important; min-width: fit-content !important"}>
					<AiOutlineCopy onClick={handleShow} className="icon" />
				</ToolTip>)
				: (
					<Button variant="primary" onClick={handleShow}>
						<BsFillPlusSquareFill className="mr-2" /> <span>Create New Event</span>
					</Button>
				)}
			<CreateEventStyle size="lg" centered show={show} onHide={handleClose}>
				<EventModalForm
					data={data}
					channel={channel}
					handleSubmitTask={handleSubmitTask}
					handleClose={handleClose}
					teamIds={teamIds}
					isPackageModal={isPackageModal}
					mode={mode}
				/>
			</CreateEventStyle>
		</Fragment>
	);
}
export default EventModal;
