import { styled } from 'twin.macro';

export const StyledDetailsButton = styled.div`
	cursor: pointer;
	height: 40px;
	display: flex;
	align-items: center;
	transition: background-color 300ms;
	padding: 1.3rem;
	border-radius: 10px;
	margin-bottom: 0.3rem;
	:hover {
		background-color: #e6e6e6;
	}
	i {
		font-size: 24px;
		display: block;
		color: #50aeb0;
		margin-right: 0.2em;
	}
`;

export const StyledSeenByDiv = styled.div`
	display: flex;
	padding-right: 2rem;
	margin-bottom: 1.2rem;

	&.other {
		/* justify-content: flex-start; */
		flex-direction: row;

		.tooltiptext {
			left: 0%;
		}
	}
	&.self {
		/* justify-content: flex-end; */

		flex-direction: row-reverse;
		.tooltiptext {
			right: 0%;
		}
	}
	.tooltip .tooltiptext {
		visibility: hidden;
		background-color: #0000008f;
		color: #fff;
		text-align: center;
		width: max-content;
		padding: 5px 8px;
		border-radius: 6px;

		/* Position the tooltip text - see examples below! */
		position: absolute;
		z-index: 999999;
		bottom: 125%;
		font-size: 1.2rem;	
	}
	.tooltip {
		width: 15px;
		height: 15px;
		background: #bbbbbb;
		border-radius: 1000px;
		margin-left: 0.3rem;
		opacity: 1;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 1;

		img {
			width: 100%;
			height: 100%;
			border-radius: 1000px;
			object-fit: cover;
			pointer-events: none;
		}
		:hover .tooltiptext {
			visibility: visible;
		}
	}
`;
