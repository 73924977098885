const Loader =  {
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	placeItems: 'center',
	placeContent: 'center',
	display: 'flex',
	zIndex: '1',
	background: 'rgba(255,255,255, 0.7)',
	'& img': {
		pointerEvents: 'none',
		maxWidth: '100%',
		width: '150px',
	},
};

export default Loader;
