import { css } from 'twin.macro';

import { Fragment } from 'react';
import { SanitizeHTML } from '../../../../_helpers';
import FileMessage from '../FileMessage';

export const textStyles = css`
	font-size: 14px !important;
	text-decoration: none;
	color: #212529;
	display: flex;
	align-items: center;
	&:hover {
		color: #000000;
	}
`;

export default function FilesMessage({ files, displayMessage, className, from, teamDetails, canDownload }) {
	return (
		<Fragment>
			<SanitizeHTML html={displayMessage} />
			<div
				css={css`
					padding: 0.5rem;
					background: ${from === 'current-user' ? '#3f8d8f' : '#dcdcdc'};
					border-radius: 15px;
					cursor: pointer;

					.fileDiv {
						border-radius: 15px;
						padding: 0.5rem;
						transition: background-color 100ms;
						:hover {
							background: ${from === 'current-user' ? '#50aeb0' : '#e7e7e7'};
							color: black;
						}
						a {
							display: inline-block;
							width: 100%;
							height: 100%;
							text-decoration: none;
						}
					}
				`}
			>
				{files.map((file, idx) => <FileMessage file={file} displayMessage={displayMessage} className={className} teamDetails={teamDetails} documentSettings={canDownload} />)}
			</div>
		</Fragment>
	);
}
