import styled from '@emotion/styled';
import { Modal } from 'react-bootstrap';

export const CreateEventStyle = styled(Modal)`
	.react-datetime-picker__inputGroup__leadingZero {
		font-size: 2rem;
		margin-right: -0.65rem;
	}
	.react-datetime-picker__inputGroup__divider {
		margin-right: 0.65rem;
	}
	input,
	input:focus {
		font-size: 2rem;
		padding: 1rem 0.5rem;
	}
	select,
	select:focus {
		font-size: 2rem;
		border-radius: 5px;
		border: 1.5px solid #cccccc;
	}
	.form-row + .form-row {
		margin-top: 2rem;
	}
	.form-label {
		font-size: 2rem;
		font-weight: 400;
		margin-right: 3rem;
	}
	.disableBtn {
		background-color: #cccccc !important;
		color: #666666;
		pointer-events: none;
	}
	.react-datetime-picker {
		border-radius: 5px;
		border: 1.5px solid #cccccc;
		padding: 0.5%;
		width: 100%;
	}
	.react-datetime-picker__wrapper {
		border: none;
	}
	.react-datetime-picker__inputGroup__input {
		width: auto !important;
		height: 50%;
		text-align: center;
	}
`;
