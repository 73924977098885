import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getAllRooms } from '../../../_redux/actions/ActionMessage';
import { GetAllTeams } from '../../../_redux/actions/ActionOrganization';
import Select from 'react-select';

const SelectChannelComponent = ({ selectedOption = null, setSelectedOption, getAllRooms, rooms, teams }) => {
	const [option, setOption] = useState([]);

	useEffect(() => {
    if(Object.keys(rooms).length === 0){
      getAllRooms();
    }
	}, [getAllRooms, rooms]);

	useEffect(() => {
		if (teams.length && Object.keys(rooms).length) {
			const groupby = teams?.map((team) => ({
				label: team?.name,
				options: rooms[team?.id || team?._id]?.rooms
					?.filter((room) => room?.team === team?.id)
					?.map((room) => ({ label: room?.title, value: room?.id })),
			}));
			setOption(groupby);
		}
	}, [rooms, teams]);

	const onSearchChange = (selectedOption) => {
		if (selectedOption) {
			setSelectedOption(selectedOption);
		}
	};

	return <Select defaultValue={selectedOption} options={option} onChange={onSearchChange} isClearable={true} />;
};

const mapStateToProps = (state) => ({
	formElements: state.formData.formElements,
	organizations: state.organizations.organizations,
	teams: state.teams.teams,
	rooms: state.rooms.teamRooms,
});

const mapDispatchToProps = { getAllRooms, GetAllTeams };

export default connect(mapStateToProps, mapDispatchToProps)(SelectChannelComponent);
