import React, { useState, useRef } from 'react';
import { css } from 'twin.macro';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

// helpers

// redux
import { connect } from 'react-redux';
import { Get10Teams, deleteATeamById } from '../../../../_redux/actions/ActionOrganization';
import { GetProvider } from '../../../../_redux/actions/ActionEVV';

//costume hooks
import { useSortData } from '../../useSortData';

// components
import ProviderList from './ProviderList';
import Pagination from '../../Pagination';
import CreateProvider from './CreateProvider';
import AdminSearchBox from '../../reusableComponents/AdminSearchBox';
import { Button, Nav } from 'react-bootstrap';
import { getTeamIdFromURL } from '../../../../_helpers';

const Provider = ({ GetProvider, organizations, isLoading, providers }) => {
    const isTeamSettings = !!useLocation().pathname.match(/user-evv/);
    const teamId = getTeamIdFromURL();
    const evvBasePath = isTeamSettings ? 'user-evv' : 'evv';

    const history = useHistory();
    const [providerState, setProviderState] = useState('active');
    const queryPage = useLocation().search.match(/page=([0-9]+)/, '');
    const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
    const { sortedItems, requestSort, sortConfig } = useSortData(GetProvider, {
        key: 'name',
        direction: 'asc',
        team: teamId,
        page: currentPage,
    });

    const [search, setSearch] = useState('');
    const inputRef = useRef();
    const isFirst = useRef(true);

    useEffect(() => {
        GetProvider({ page: 1, state: providerState, team: teamId });
    }, [GetProvider, providerState]);

    const onPageChanged = (data) => {
        if (currentPage !== data.currentPage && data.currentPage > 0) {
            history.push(`/${evvBasePath}?page=${data.currentPage}`);
            GetProvider({ page: data.currentPage, state: providerState, team: teamId });
        }
    };

    const onSearch = (e) => {
        setSearch(e);
        history.push(`/${evvBasePath}?page=1`);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            if (!isTeamSettings && search === inputRef.current.value) {
                if (search) {
                    sortedItems({ searchText: search, team: teamId });
                } else {
                    sortedItems({ searchText: search, team: teamId });
                    if (isFirst.current) {
                        history.push(`/${evvBasePath}?page=1`);
                        isFirst.current = false;
                        return;
                    }
                }
            }
        }, 500);
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, inputRef, currentPage, history]);

    const handleSort = (sortMethod) => {
        if (organizations.length) {
            if (currentPage === 1) {
                return requestSort({
                    key: sortMethod,
                    shouldFetch: true,
                    organizationId: organizations[0].id,
                });
            } else {
                requestSort({
                    key: sortMethod,
                    shouldFetch: false,
                    organizationId: organizations[0].id,
                });
                return history.push(`/evv?page=1`);
            }
        }
    };

    return (
        <div>
            {!!isTeamSettings || (
                <div
                    className="d-flex flex-row align-items-center mb-4"
                    css={css`
                        justify-content: space-between;
                    `}
                >
                    <AdminSearchBox
                        search={search}
                        onSearch={onSearch}
                        placeholder="Search Provider"
                        inputRef={inputRef}
                    />
                    <Nav
                        variant="pills"
                        className="p-2"
                        css={css`
                            background: #e1e1e1;
                            border-radius: 5px;
                        `}
                    >
                        <Nav.Item>
                            <Nav.Link
                                as={Button}
                                variant={providerState === 'active' ? 'primary' : 'link'}
                                onClick={() => setProviderState('active')}
                                style={{ fontSize: '1.6rem' }}
                                className={providerState === 'active' ? 'bg-primary text-white' : ''}
                            >
                                Active
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="ml-1">
                            <Nav.Link
                                as={Button}
                                variant={providerState === 'archieved' ? 'primary' : 'link'}
                                onClick={() => setProviderState('archieved')}
                                style={{ fontSize: '1.6rem' }}
                                className={providerState === 'archieved' ? 'bg-primary text-white' : ''}
                            >
                                Archived
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <CreateProvider />
                </div>
            )}
            <div
                className="custom-card d-flex flex-column justify-content-between"
                css={css`
                    min-height: 675px;
                `}
            >
                <ProviderList
                    list={providers.providers}
                    handleSort={handleSort}
                    isLoading={isLoading}
                    sortConfig={sortConfig}
                    currentPage={currentPage}
                    providerState={providerState}
                    setProviderState={setProviderState}
                    teamId={teamId}
                />
                {providers.providers.length ? (
                    <div className="m-4">
                        <Pagination
                            totalRecords={providers.totalProviders}
                            pageLimit={10}
                            pageNeighbours={1}
                            pageNo={currentPage}
                            onPageChanged={onPageChanged}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    isLoading: state.evv.isLoading,
    providers: state.evv.providers,
    organizations: state.organizations.organizations,
});

export default connect(mapStateToProps, { GetProvider, Get10Teams, deleteATeamById })(Provider);
