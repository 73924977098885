import { css } from 'twin.macro';
import React, { useState, useEffect, useRef } from 'react';
import { AiOutlineAudio, AiOutlineAudioMuted } from 'react-icons/ai';
import { GrWifiLow, GrWifiMedium, GrWifi } from 'react-icons/gr';
import { BiWifiOff, BiWifi0, BiPin } from 'react-icons/bi';
import ProfileImg from './components/ProfileImg';
import { StyledParticipant } from './styles/ParticipantStyles';

const networkInfo = {
	0: <BiWifiOff color="#e91643" />,
	1: <BiWifi0 color="#d78228" />,
	2: (
		<GrWifiLow
			css={css`
				path {
					stroke: #c5cc33;
				}
			`}
		/>
	),
	3: (
		<GrWifiMedium
			css={css`
				path {
					stroke: #699d62;
				}
			`}
		/>
	),
	4: (
		<GrWifi
			css={css`
				path {
					stroke: #46c03f;
				}
			`}
		/>
	),
	5: (
		<GrWifi
			css={css`
				path {
					stroke: #45cc33;
				}
			`}
		/>
	),
};

const Participant = ({
	localUser,
	participant,
	localParticipant,
	audio,
	video,
	dominantSpeaker,
	participantInfo,
	sharingScreen,
	localScreenRef,
	localVideoRef,
	pinnedParticipant,
	handlePinnedParticipant,
}) => {
	const [videoTracks, setVideoTracks] = useState([]);
	const [audioTracks, setAudioTracks] = useState([]);
	const [isVideoSharing, setIsVideoSharing] = useState(false);
	const [isScreenSharing, setIsScreenSharing] = useState(false);

	const [isAudioEnabled, setIsAudioEnabled] = useState(false);
	const [networkLevel, setNetworkLevel] = useState(localParticipant ? participant.networkQualityLevel : null);
	const screenRef = useRef();
	const videoRef = useRef();
	const audioRef = useRef();

	const participantRef = useRef();

	const trackpubsToTracks = (trackMap) =>
		Array.from(trackMap.values())
			.map((publication) => publication.track)
			.filter((track) => track !== null);

	useEffect(() => {
		setVideoTracks(trackpubsToTracks(participant.videoTracks));
		setAudioTracks(trackpubsToTracks(participant.audioTracks));
		const trackSubscribed = (track) => {
			if (track.kind === 'video') {
				setVideoTracks((videoTracks) => [...videoTracks, track]);
			} else if (track.kind === 'audio') {
				setAudioTracks((audioTracks) => [...audioTracks, track]);
				track.on('disabled', () => {
					setIsAudioEnabled(false);
				});
				track.on('enabled', () => {
					setIsAudioEnabled(true);
				});
			}
		};

		const trackUnsubscribed = (track) => {
			if (track.kind === 'video') {
				setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
			} else if (track.kind === 'audio') {
				setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
			}
		};

		participant.on('trackSubscribed', trackSubscribed);
		participant.on('trackUnsubscribed', trackUnsubscribed);
		if (localParticipant) {
			participant.on('networkQualityLevelChanged', (networkQualityLevel) => {
				setNetworkLevel(networkQualityLevel);
			});
		}

		return () => {
			setVideoTracks([]);
			setAudioTracks([]);
			participant.removeAllListeners();
		};
	}, [localParticipant, participant]);

	useEffect(() => {
		let videoTrack;
		let screenTrack;
		videoTracks.forEach((track, index) => {
			if (track.name === 'camera') {
				videoTrack = track;
			} else if (track.name === 'screenShare') {
				screenTrack = track;
				handlePinnedParticipant(participant.sid);
			} else {
				if (index === 0) {
					videoTrack = track;
				} else {
					screenTrack = track;
					handlePinnedParticipant(participant.sid);
				}
			}
		});
		let hasVideoTrack = Boolean(videoTrack);
		let hasScreenTrack = Boolean(screenTrack);

		if (hasScreenTrack) {
			setIsVideoSharing(false);
			setIsScreenSharing(true);
		} else {
			setIsVideoSharing(hasVideoTrack);
			setIsScreenSharing(false);
		}

		setTimeout(() => {
			if (videoTrack) {
				if (localParticipant) {
					videoTrack.attach(localVideoRef.current);
				} else {
					videoTrack.attach(videoRef.current);
				}
			}
			if (screenTrack) {
				screenTrack.attach(screenRef.current);
				handlePinnedParticipant(participant.sid);
			}
		});

		return () => {
			videoTrack && videoTrack.detach();
			screenTrack && screenTrack.detach();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [videoTracks]);

	useEffect(() => {
		const audioTrack = audioTracks[0];
		if (audioTrack) {
			audioTrack.attach(audioRef.current);
			return () => {
				audioTrack.detach();
			};
		}
	}, [audioTracks]);

	useEffect(() => {
		if (participantRef.current) {
			const { current } = participantRef;
			if (pinnedParticipant?.length) {
				// someone is pinned
				if (pinnedParticipant === participant.sid) {
					current.style.display = 'flex';
				} else {
					current.style.display = 'none';
				}
			} else {
				// noone is not pinned
				current.style.display = 'flex';
			}
		}
	}, [participant.sid, participantRef, pinnedParticipant]);

	return (
		<StyledParticipant
			ref={participantRef}
			isPinned={pinnedParticipant === participant.sid}
			className={`${participant.sid === dominantSpeaker ? 'dominant' : ''} participant`}
		>
			<div
				css={css`
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				`}
			>
				{localParticipant ? (
					video && !sharingScreen ? (
						<video ref={localVideoRef} autoPlay={true} />
					) : null
				) : isVideoSharing ? (
					<video ref={videoRef} autoPlay={true} />
				) : null}

				{localParticipant ? (
					sharingScreen ? (
						<video ref={localScreenRef} autoPlay={true} />
					) : null
				) : isScreenSharing ? (
					<video ref={screenRef} autoPlay={true} />
				) : null}
			</div>

			<div
				css={css`
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
				`}
			>
				<div>
					{localUser ? (
						<div>
							<ProfileImg user={localUser} avatar={localUser?.avatar} />
							<h3 className="text-center mt-2">{`${localUser.name} (You)`}</h3>
						</div>
					) : (
						<div>
							<ProfileImg
								user={participantInfo[participant.identity]}
								avatar={participantInfo[participant.identity]?.avatar}
							/>
							<h3 className="text-center mt-2">{participantInfo[participant.identity]?.name}</h3>
						</div>
					)}
				</div>
			</div>

			{audio && (
				<audio
					ref={audioRef}
					autoPlay={true}
					// muted={true}
				/>
			)}

			<div
				css={css`
					position: absolute;
					top: 10px;
					right: 10px;
					background: #1f1f1f;
					padding: 1rem;
					border-radius: 15px;
					cursor: pointer;
					opacity: 0.4;
					transition: opacity 300ms;
					:hover {
						opacity: 1;
					}
					svg {
						font-size: 1.1em;
						margin: 0 0.5rem;
						color: #fff;
					}
				`}
				onClick={() => {
					if (participant.sid === pinnedParticipant) {
						handlePinnedParticipant('');
					} else {
						handlePinnedParticipant(participant.sid);
					}
				}}
			>
				<BiPin />
			</div>

			<div
				css={css`
					position: absolute;
					bottom: 10px;
					right: 10px;
					background: #1b1b1b;
					padding: 0.5rem;
					border-radius: 10px;
					opacity: 0.4;
					:hover {
						opacity: 1;
					}
					svg {
						font-size: 1.2em;
						margin: 0 0.5rem;
					}
				`}
			>
				{localParticipant ? (
					audio ? (
						<AiOutlineAudio color="white" />
					) : (
						<AiOutlineAudioMuted color="white" />
					)
				) : isAudioEnabled === undefined ? null : isAudioEnabled === false ? (
					<AiOutlineAudioMuted color="white" />
				) : (
					<AiOutlineAudio color="white" />
				)}
				{localParticipant && networkInfo[networkLevel]}
			</div>
		</StyledParticipant>
	);
};

export default Participant;
