import React from 'react';
import { css } from 'twin.macro';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { VscLoading } from 'react-icons/vsc';

function StatusMessage() {
	const user = useSelector((state) => state.user.user);
	const [status, setStatus] = useState(user.statusMessage ? user.statusMessage : '');
	const [isEditing, setIsEditing] = useState(false);

	return (
		<div>
			{status ? (
				<div>
					{isEditing ? (
						<StatusMessageInput
							status={status}
							setStatus={setStatus}
							setIsEditing={setIsEditing}
							user={user}
						/>
					) : (
						<div>
							<div className="d-flex justify-content-end cursor-pointer">
								<span onClick={() => setIsEditing(true)}>Edit Status message</span>
							</div>
							<div
								css={css`
									background-color: #f5f5f5;
								`}
								className="w-100 border p-2 rounded"
							>
								{status}
							</div>
						</div>
					)}
				</div>
			) : (
				<div>
					{isEditing ? (
						<StatusMessageInput
							status={status}
							setStatus={setStatus}
							setIsEditing={setIsEditing}
							user={user}
						/>
					) : (
						<div className="d-flex justify-content-end cursor-pointer">
							<span onClick={() => setIsEditing(true)}>Set Status message</span>
						</div>
					)}
				</div>
			)}
		</div>
	);
}

const StatusMessageInput = ({ status, setStatus, setIsEditing, user }) => {
	const [loading, setLoading] = useState(false);
	const [limit, setLimit] = useState(280);
	const handleChange = (e) => {
		setStatus(e.target.value);
		setLimit(280 - e.target.value.length);
	};
	const handleStatusSave = async () => {
		try {
			setLoading(true);
			let body = {
				name: user.name,
				address: user.address,
				about: user.about,
				showPublicChannel: user.showPublicChannel,
				statusMessage: status,
			};
			body = JSON.stringify(body);
			let userToken = JSON.parse(localStorage.getItem('user'));
			const response = await fetch(process.env.REACT_APP_BACKEND_URL + 'users/me', {
				method: 'PUT',
				headers: {
					Authorization: `bearer ${userToken.token}`,
					'Content-Type': 'application/json',
				},
				body: body,
				credentials: 'same-origin',
			});
			console.log(response, 'response');
			setIsEditing(false);
			setLoading(false);
		} catch (err) {
			setLoading(false);
			console.log(err);
		}
	};
	return (
		<div>
			<div
				css={css`
					position: relative;
				`}
			>
				<textarea
					rows={10}
					css={css`
						padding-bottom: 20px;
						background-color: #f5f5f5;
					`}
					className="w-100 border p-2 rounded"
					value={status}
					onChange={handleChange}
					placeholder="Type your status message here"
					disabled={loading}
				/>
				<span
					css={css`
						bottom: 5px;
						right: 10px;
						position: absolute;
					`}
				>
					{limit}
				</span>
			</div>
			<button type="button" className="btn btn-primary" onClick={handleStatusSave} disabled={loading}>
				{loading ? <VscLoading className="spin" /> : 'Done'}{' '}
			</button>
		</div>
	);
};

export default StatusMessage;
