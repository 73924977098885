import React, { useState } from 'react';
import { BiLogOut } from 'react-icons/bi';
import styled from '@emotion/styled';
import Avatar from './Avatar';
import { connect } from 'react-redux';
import { useLogout } from '../../Features/Common/useLogout';

const WelcomePanelStyled = styled('div')`
    .toggle-options{
		position:absolute;
		top:60px;
		right:20px;
		border-radius:5px;
		color:#50aeb0;
		display:flex;
		padding:10px 0px;
		justify-content:center;
		background-color:white;
		.option{
			padding:10px;
			display:flex;
			justify-content:center;
			align-items:center;
			width:100%;
			height:40px;
            cursor:pointer;
			&:hover{
				background-color:#E0F0EF;
			}
		}
	}
    .wlc:hover{
        color:#50aeb0;
    }
`;

const WelcomeUser = ({ user }) => {
    const [profileToggle, setProfileToggle] = useState(false);
	const {logoutFunction} = useLogout();
    return (
        <WelcomePanelStyled>
            <div className="d-flex align-items-center pointer position-relative" onClick={()=>setProfileToggle(!profileToggle)}>
					<span className="wlc text-dark-grey mr-4">Welcome, {user.name}!</span>
					<Avatar item={user}/>
				</div>
				{profileToggle && (
					<div className="toggle-options">
						<div className="option d-flex justify-content-center align-items-center" onClick={() => logoutFunction()}>
							<BiLogOut/><span>Log Out</span>
						</div>
					</div>
				)}
        </WelcomePanelStyled>
    )
}
const mapStateToProps = (state) => ({
	user:state.user.user,
});

export default connect(mapStateToProps, {})(WelcomeUser);
