import { css } from 'twin.macro';
import React, { useState, Fragment, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import dayjs from 'dayjs';

import { fetchWrapper, getSequenceNumber, getTeamIdFromURL } from '../../../../_helpers';
import ProviderSelector from '../ProviderSelector';
import DateTimePicker from 'react-datetime-picker/dist/DateTimePicker';
import ServicesElement from '../../Form/ServicesElement';

function VistForm({
    provider,
    client,
    isTeamSettings,
    isChannelSettings,
    handleShowLoading,
    handleHideLoading,
    visitResult,
    team,
}) {
    const [providerId, setProviderId] = useState(() => {
        if (provider) {
            return provider.providerId;
        } else {
            return null;
        }
    });
    const [options, setOptions] = useState();
    const [providerState, setProviderState] = useState(null);
    const [selectedEmployeeOption, setSelectedEmployeeOption] = useState([]);
    const [selectedClientOption, setSelectedClientOption] = useState(
        client?.id ? { label: client?.label, value: { client: client?.id || client?.value } } : null
    );
    const [reason, setReason] = useState('Connection Issue');

    const { register, handleSubmit, control } = useForm({
        mode: 'onBlur',
    });

    const handleSelectProvider = (provider) => {
        setProviderState(provider?.state);
        setProviderId(provider.providerId);
    };

    const onSubmit = async (data) => {
        let SequenceID = getSequenceNumber();
        if (!providerId) {
            return toast.error('Provider must be selected');
        }
        if (!selectedClientOption?.value?.client) {
            return toast.error('Client must be selected');
        }
        if (!selectedEmployeeOption?.value?.id) {
            return toast.error('Employee must be selected');
        }
        if (!options?.id) {
            return toast.error('Service must be selected');
        }
        if (!data?.date) {
            return toast.error('Start Date and Time must be set');
        }
        if (!data?.enddate) {
            return toast.error('End Date and Time must be set');
        }
        if (dayjs(data?.date).isSame(dayjs(data?.enddate)) || dayjs(data?.date).isAfter(dayjs(data?.enddate))) {
            return toast.error('Start Date must be less than End Date.');
        }
        if (dayjs(data?.enddate).diff(dayjs(data?.date), 'hour') > 24) {
            return toast.error(
                <div>
                    <b>Ops! Billing hour exceed 24 hours.</b>
                    <br />
                    <br />
                    <span>
                        Difference of <b>start dateTime</b> and <b>end dateTime</b> must be less than 24hrs
                    </span>
                </div>,
                {
                    autoClose: 10000,
                }
            );
        }
        if (!reason) {
            return toast.error('Reason must be selected');
        }
        try {
            let body = {
                provider: providerId,
                client: selectedClientOption.value.client,
                employee: selectedEmployeeOption.value.id,
                SequenceID: SequenceID,
                service: options.id,
                serviceCreatedAt: data.date.toISOString(),
                serviceEndAt: data.enddate.toISOString(),
                VisitCancelledIndicator: 'false',
                reason,
            };
            handleShowLoading();
            await fetchWrapper.post(
                `evv/visit?${team ? `&team=${team}` : ''}
				`,
                body
            );
            handleHideLoading();

            toast.success('Visit submitted Successfully!');
            visitResult(true);
        } catch (err) {
            handleHideLoading();
            toast.error(err.message);
            visitResult(false);
        }
    };

    const handleEmployeeChange = (selectedOption) => {
        setSelectedEmployeeOption(selectedOption);
    };

    const loadEmployeeOptions = (inputValue, callback) => {
        setTimeout(async () => {
            let employeeArray = [];
            try {
                let response;
                if (providerId) {
                    response = await fetchWrapper.get(
                        `evv/employee?limit=20&page=1${
                            inputValue ? `&firstName=${inputValue}` : ''
                        }${providerId ? `&provider=${providerId}` : ''}${team ? `&team=${team}` : ''}
						`
                    );
                    let results = response.results;
                    let options = results
                        .filter((results) => results.user && results.firstName)
                        .map((employee) => {
                            return {
                                label: `${employee.firstName} ${employee.lastName}`,
                                value: {
                                    id: employee.id,
                                    user: employee.user.id,
                                },
                            };
                        });
                    employeeArray = options;
                }
            } catch (err) {
                console.log(err);
            }
            callback(employeeArray);
        });
    };

    const handleClientChange = (selectedOption) => {
        setSelectedClientOption(selectedOption);
    };

    const loadClientOptions = (inputValue, callback) => {
        setTimeout(async () => {
            let ClientArray = [];
            const teamId = getTeamIdFromURL();

            try {
                let response;
                if (providerId) {
                    response = await fetchWrapper.get(
                        `evv/client?limit=20&page=1${
                            inputValue ? `&firstName=${inputValue}` : ''
                        }${providerId ? `&provider=${providerId}` : ''}${teamId ? `&team=${teamId}` : ''}`
                    );
                    let results = response.results;
                    let options = results
                        .filter((results) => results.firstName)
                        .map((client) => ({
                            label: `${client.firstName} ${client.lastName}`,
                            value: {
                                client: client.id,
                            },
                        }));
                    ClientArray = options;
                }
            } catch (err) {
                console.log(err);
            }
            callback(ClientArray);
        });
    };
    return (
        <Fragment>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body className="modal-body" key={0}>
                    <p className="text-lead text-secondary">All fields are required.</p>
                    <div>
                        <Form.Group controlId="formReason" css={css``}>
                            <Form.Label>Reason For Manual Visit</Form.Label>
                            <Select
                                value={{ label: reason, value: reason }}
                                onChange={(option) => setReason(option.value)}
                                options={[
                                    'Participant Refusal',
                                    'Participant Unavailable',
                                    'Direct Care Worker Error',
                                    'Mobile Device Issue',
                                    'System Related Issue',
                                    'Telephony Issue',
                                    'Connection Issue',
                                    'Service Rendered Outside the Home',
                                    'Billing System Limitation',
                                ].map((option) => ({ label: option, value: option }))}
                            />
                        </Form.Group>
                        <ProviderSelector
                            provider={provider}
                            selectProvider={handleSelectProvider}
                            noAstrick
                            isDisabled={isTeamSettings}
                            team={team}
                        />
                        <Form.Group controlId="formClient">
                            <Form.Label>Client</Form.Label>
                            {providerId ? (
                                <Fragment key={providerId}>
                                    <AsyncSelect
                                        cacheOptions={true}
                                        loadOptions={loadClientOptions}
                                        defaultOptions={true}
                                        onChange={handleClientChange}
                                        value={selectedClientOption}
                                        isDisabled={isChannelSettings}
                                    />
                                </Fragment>
                            ) : (
                                <p
                                    css={css`
                                        color: #ea9941;
                                    `}
                                >
                                    Please select Provider
                                </p>
                            )}
                        </Form.Group>
                        <Form.Group controlId="formEmployee">
                            <Form.Label>Employee</Form.Label>
                            {providerId ? (
                                <Fragment key={providerId}>
                                    <AsyncSelect
                                        cacheOptions={true}
                                        loadOptions={loadEmployeeOptions}
                                        defaultOptions={true}
                                        onChange={handleEmployeeChange}
                                    />
                                </Fragment>
                            ) : (
                                <p
                                    css={css`
                                        color: #ea9941;
                                    `}
                                >
                                    Please select Provider
                                </p>
                            )}
                        </Form.Group>
                        <ServicesElement
                            stateValue={providerState}
                            hideStateSelector={true}
                            setOptions={setOptions}
                            options={options}
                            isMulti={false}
                            team={team}
                        />

                        <Form.Group
                            controlId="formSOS"
                            css={css`
                                display: flex;
                                justify-content: space-between;
                            `}
                        >
                            <Form.Label>Start Date and Time:</Form.Label>
                            <Controller
                                name="date"
                                control={control}
                                defaultValue={null}
                                render={({ onChange, value }) => {
                                    let date;
                                    if (value) {
                                        date = new Date(value);
                                    } else {
                                        date = null;
                                    }
                                    return (
                                        <DateTimePicker
                                            css={css`
                                                display: inline-block;
                                                width: 60%;
                                            `}
                                            onChange={onChange}
                                            maxDate={new Date()}
                                            defaultValue={date}
                                            value={date}
                                            ref={register}
                                            format="MM-dd-y hh:mm a"
                                            disableClock={true}
                                            calendarType={'US'}
                                        />
                                    );
                                }}
                            />
                        </Form.Group>

                        <Form.Group
                            controlId="formEOS"
                            css={css`
                                display: flex;
                                justify-content: space-between;
                            `}
                        >
                            <Form.Label>End Date and Time:</Form.Label>

                            <Controller
                                name="enddate"
                                control={control}
                                defaultValue={null}
                                render={({ onChange, value }) => {
                                    let date;
                                    if (value) {
                                        date = new Date(value);
                                    } else {
                                        date = null;
                                    }
                                    return (
                                        <DateTimePicker
                                            css={css`
                                                display: inline-block;
                                                width: 60%;
                                            `}
                                            onChange={onChange}
                                            defaultValue={date}
                                            maxDate={new Date()}
                                            value={date}
                                            ref={register}
                                            format="MM-dd-y hh:mm a"
                                            disableClock={true}
                                            calendarType={'US'}
                                        />
                                    );
                                }}
                            />
                        </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-end w-100">
                        <Button
                            type="submit"
                            className="btn"
                            variant="primary"
                            css={css`
                                width: 20%;
                            `}
                        >
                            Submit
                        </Button>
                    </div>
                </Modal.Footer>
            </Form>
        </Fragment>
    );
}

export default VistForm;
