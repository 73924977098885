import * as ActionTypes from '../actions/ActionTypes';

export default function RecentChats(
	state = { isLoading: false, errorMessage: null, recentChats: [], totalChats: 0 },
	action
) {
	switch (action.type) {
		case ActionTypes.SET_CHAT_USERS:
			return {
				...state,
				isLoading: true,
				errorMessage: null,
				recentChats: action.payload.results,
				totalChats: action.payload.totalResults,
			};
		case ActionTypes.SET_SOCKET_CHAT_USERS:
			let recentChats = [...state.recentChats];
			let arr = recentChats.findIndex((obj) => action.payload[0].user.id === obj.user.id);
			if (arr === -1) {
				recentChats.unshift(action.payload[0]);
			} else {
				recentChats[arr].data = action.payload[0].data;
				recentChats[arr].createdAt = action.payload[0].createdAt;
				recentChats[arr].sent = action.payload[0].sent;
				recentChats[arr].notSeen = action.payload[0].notSeen;
				recentChats.unshift(recentChats.splice(arr, 1)[0]);
			}

			return {
				...state,
				recentChats: recentChats,
			};
		case ActionTypes.SET_LOADING_CHAT:
			return {
				...state,
				isLoading: true,
			};
		case ActionTypes.STOP_LOADING_CHAT:
			return {
				...state,
				isLoading: false,
			};
		default:
			return state;
	}
}
