import { css } from 'twin.macro';
import {connect} from 'react-redux';

// helpers
import { DetailsStyled } from './styles/ChatDetailsUserInfoStyles';
import OnlineIndicator from './OnlineIndicator';
import { BsChatDotsFill } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import AvatarUserPanel from '../Common/AvatarUserPanel';

const ChatDetailsUserInfo = ({ user, memberRole, from, roomType, currUser }) => {
	let history = useHistory();

	return (
		<DetailsStyled>
			<div className="position-relative">
				<AvatarUserPanel item={user} large={true} />
				<div className="mt-4">
					<OnlineIndicator className="chat_detail mt-2" user={user} />
				</div>
			</div>
			{memberRole && (
				<div className={`role-badge badge-${memberRole}`}>{memberRole === 'doctor' ? 'Third Party' : memberRole}</div>
			)}
			<h1
				css={css`
					color: #757575;
				`}
			>
				{user.name}
			</h1>
			{user?.address && <p className="text-muted small h4">{user?.address}</p>}
			{user?.email && (roomType === 'private' || roomType === 'gps_enabled') && (
				<span className="text-primary small">{user?.email}</span>
			)}
			{user?.about && <span className="small text-muted">{user.about}</span>}
			
			{from !== 'chat' && currUser?.id !== user.id  && (
				<div
					className="pointer"
					css={css`
						display: flex;
						justify-content: flex-start;
						align-items: center;
						margin: 0.8rem 0;
						padding: 1rem 2rem;
						border-radius: 8px;
						font-weight: bold;
						background-color: #e0f0ef;
						transition: 250ms background-color;
						:hover {
							background-color: #efefef;
						}
					`}
					onClick={() => history.push(`/chat/${user.id}`)}
				>
					<BsChatDotsFill className="text-primary" fontSize="24" />
					<span
						className="mx-3"
						css={css`
							font-weight: bold;
						`}
					>
						Open conversation
					</span>
				</div>
			)}
		</DetailsStyled>
	);
};

const mapStateToProps = (state) => ({
	currUser: state.user.user,
})
export default connect(mapStateToProps, null)(ChatDetailsUserInfo);
