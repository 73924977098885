import { css } from 'twin.macro';
import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import AsyncSelect from 'react-select/async';
import { fetchWrapper } from '../../../../_helpers';
import { RequiredStar } from '../Payroll/ZealComponents/Deductions/RenderDeductionFields';
import { VscLoading } from 'react-icons/vsc';

function DoNotBillModal({ show, visit, handleClose }) {
    const [isBusy, setIsBusy] = useState(false);
    const [selectedReasonOption, setSelectedReasonOption] = useState({});
    const [selectedResolutionOption, setSelectedResolutionOption] = useState({});
    const [reasonMemo, setReasonMemo] = useState('');
    const {
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: 'onBlur',
    });
    const onSubmit = async () => {
        if (!visit?.provider?._id) {
            return toast.error('Provider must be selected');
        }
        if (!selectedReasonOption.value) {
            return toast.error('Reason must be selected');
        }
        if (!selectedResolutionOption.value) {
            return toast.error('Resolution must be selected');
        }
        if (!reasonMemo) {
            return toast.error('Reason memo is required');
        }
        if (typeof visit?.NewBillVisit !== 'boolean') {
            return toast.error('Bill Visit Status is required');
        }
        if (!visit?._id) {
            return toast.error('Visit must be selected');
        }
        try {
            setIsBusy(true);
            const body = {
                ReasonCode: selectedReasonOption.value,
                ResolutionCode: selectedResolutionOption.value,
                ChangeReasonMemo: reasonMemo,
                BillVisit: visit?.NewBillVisit,
                visit: visit?._id,
            };
            await fetchWrapper.put(`evv/visit`, body);
            toast.success('Visit updated successfully!');
            visit.handleBillableSuccess(visit?.NewBillVisit.toString());
            handleClose();
        } catch (err) {
            toast.error(err.message);
        } finally {
            setIsBusy(false);
        }
    };

    const handleReasonChange = (selectedOption) => {
        setSelectedReasonOption(selectedOption);
    };

    const handleResolutionChange = (selectedOption) => {
        setSelectedResolutionOption(selectedOption);
    };

    const loadReasons = (inputValue, callback) => {
        setTimeout(async () => {
            let reasons = [];
            try {
                const response = await fetchWrapper.get(`evv/visit/codes/reasons?provider=${visit?.provider?._id}`);
                reasons = (response?.reasons || []).map((reason) => {
                    const value = Object.keys(reason)[0];
                    return {
                        label: reason[value],
                        value,
                    };
                });
            } catch (err) {
                console.error(err);
            }
            callback(reasons);
        });
    };

    const loadResolutions = (inputValue, callback) => {
        setTimeout(async () => {
            let resolutions = [];
            try {
                const response = await fetchWrapper.get(`evv/visit/codes/resolutions?provider=${visit?.provider?._id}`);
                resolutions = (response?.resolutions || []).map((reason) => {
                    const value = Object.keys(reason)[0];
                    return {
                        label: reason[value],
                        value,
                    };
                });
            } catch (err) {
                console.error(err);
            }
            callback(resolutions);
        });
    };

    return (
        <Modal show={Boolean(show)} onHide={handleClose} backdrop="static">
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body className="modal-body">
                    <p>
                        Change Bill Status to{' '}
                        <span className="badge badge-info">{visit?.NewBillVisit?.toString()}</span>
                    </p>
                    <p className="text-lead text-secondary">All fields are required.</p>
                    <div>
                        <Form.Group controlId="formReason">
                            <Form.Label>
                                Reason
                                <RequiredStar />
                            </Form.Label>
                            <AsyncSelect
                                cacheOptions
                                defaultOptions
                                loadOptions={loadReasons}
                                onChange={handleReasonChange}
                                value={selectedReasonOption}
                            />
                        </Form.Group>

                        <Form.Group controlId="formResolution">
                            <Form.Label>
                                Resolution
                                <RequiredStar />
                            </Form.Label>
                            <AsyncSelect
                                cacheOptions
                                defaultOptions
                                loadOptions={loadResolutions}
                                onChange={handleResolutionChange}
                                value={selectedResolutionOption}
                            />
                        </Form.Group>

                        <Form.Group controlId="formReasonMemo">
                            <Form.Label>
                                Change Reason Memo
                                <RequiredStar />
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={reasonMemo}
                                onChange={(e) => setReasonMemo(e.target.value)}
                                placeholder="e.g. Duplicate visit, should not be billed"
                                required
                            />
                            {errors.reasonMemo && (
                                <Form.Text className="text-danger">{errors.reasonMemo.message}</Form.Text>
                            )}
                        </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-end w-100">
                        <Button variant="secondary" onClick={handleClose} disabled={isBusy}>
                            Close
                        </Button>
                        <Button
                            disabled={isBusy}
                            type="submit"
                            className="btn"
                            variant="primary"
                            css={css`
                                width: 20%;
                                margin-left: 18px;
                            `}
                        >
                            Submit {isBusy && <VscLoading className="spin" />}
                        </Button>
                    </div>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export default DoNotBillModal;
