import styled from "@emotion/styled";

export const SignatureStyled = styled('div')`
	.sigPad {
		width: 100%;
		height: 10em;
		background: #eaeaea;
	}
	.sigContainer {
		/* width: 80%; */
		height: 80%;
		margin: 0 auto;
		background-color: #fff;
		border: 5px solid #fff;
		border-radius: 5px;
		transition: border-color 800ms;
	}
	.doneBorder{
		border-color:#07ff3f;
	}
	.hiddenInput {
		opacity: 0;
		position: absolute;
		user-select: none !important;
		z-index: -1;
	}
	.disableBtn {
		pointer-events: none;
	}
	.btnHolder {
		display: flex;
		justify-content: space-around;
	}
	.btns {
		min-width: 20%;
		font-size: 1.5rem;
	}
`;