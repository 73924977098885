import React, { useState, useEffect } from 'react';
import { BsTrash } from 'react-icons/bs';
import { Button, Modal } from 'react-bootstrap';
import { Body } from './DeletePopUpStyles';
import { customCheckbox } from './CustomCheckbox';
import { ToolTip } from './ToolTip';

const DeletePopUp = ({ handleDelete, title, id, type }) => {
	const [deleteModal, setDeleteModal] = useState(false);
	const [agree, setAgree] = useState(false);
	const [error, setError] = useState('');

	const handelDelete = () => {
		if (agree) {
			handleDelete(id);
			setDeleteModal(false);
		} else {
			setError('Please read and sign the checkbox');
		}
	};

	useEffect(() => {
		if (agree) {
			setError('');
		}
	}, [agree]);

	return (
		<>
		<ToolTip text={'Delete'} side={'top'} cssStyles={"top:-70% !important; min-width: fit-content !important"}>
			<BsTrash
				className="icon delete"
				onClick={() => {
					setDeleteModal(true);
					setAgree(false);
				}}
			/>
			</ToolTip>
			<Modal show={deleteModal} onHide={() => setDeleteModal(false)} centered>
				<Modal.Body className="p-0">
					<Body>
						<div className="title">
							<strong>Delete {type}</strong>
						</div>
						<div className="content d-flex flex-column justify-content-between">
							<div>
								<span className="text text-dark-grey">Are you sure you want to delete "{title}"?</span>
								<div className="d-flex flex-row align-items-center mt-4">
									<input
										type="checkbox"
										css={customCheckbox}
										id="agree"
										className="inline"
										onChange={() => setAgree(!agree)}
										checked={agree}
									/>
									<label htmlFor="agree" className="d-inline m-0 ml-2 text-light-grey">
										I understand that "{title}" will be permanently removed.
									</label>
								</div>
								<div className="text-danger">
									<small>{error}</small>
								</div>
							</div>
							<div className="w-100 d-flex justify-content-between mt-4">
								<Button className="btn" variant="secondary"
									onClick={() => setDeleteModal(false)}
								>
									Cancel
								</Button>
								<Button
									className="btn bg-danger" variant="primary"
									onClick={() => handelDelete()}
								>
									Delete
								</Button>
							</div>
						</div>
					</Body>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default DeletePopUp;
