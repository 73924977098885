import dayjs from "dayjs";

export const modifiedActivityPackageData = (activityPackageData) => {
    // Handle the case when activityPackageData is not present or empty
    if (!activityPackageData) {
        return null; 
    }

		const updatedTask = JSON.parse(JSON.stringify(activityPackageData));
		updatedTask.createdAt = dayjs().toISOString();

		const eventDates = [];
		updatedTask.taskData.forEach(task => {
			eventDates.push({ [task._id]: task.date });
		})

		//for sorting dates
		const compareDates = (dateA, dateB) => {
			const valueA = Object.values(dateA)[0];
			const valueB = Object.values(dateB)[0];
			return new Date(valueA) - new Date(valueB);
		};
		eventDates.sort(compareDates);
		const EventStartingDate = dayjs(Object.values(eventDates[0])[0]);

		// Calculate the difference in days for each date in the array
		const dateDifferences = eventDates.map(date => {
			const key = Object.keys(date)[0];
			const currentDate = dayjs(Object.values(date)[0]).startOf('day');
			const diffInDays = currentDate.diff(EventStartingDate.startOf('day'), 'day');
			return { [key]: diffInDays };
		});

		updatedTask.taskData.forEach(task => {
			if (Object.keys(eventDates[0])[0] === task._id) {
				const startDate = dayjs(task.date);
				const endDate = dayjs(task.dueDate);
				const duration = endDate.diff(startDate);
				const tomorrow = dayjs().add(1, 'day').format('YYYY-MM-DD');
				const currentDate = dayjs(task?.date);
				const tomorrowWithSameTime = dayjs(tomorrow).set('hour', currentDate.hour()).set('minute', currentDate.minute()).set('second', currentDate.second());
				task.date = tomorrowWithSameTime.toISOString();
				task.dueDate = dayjs(task.date).add(duration).toISOString();
			}

			dateDifferences.map(diff => {
				if (Object.keys(diff)[0] === task._id) {
					const startDate = dayjs(task.date);
					const endDate = dayjs(task.dueDate);
					const duration = endDate.diff(startDate);
					const tomorrow = dayjs().add(1, 'day').format('YYYY-MM-DD');
					const currentDate = dayjs(task?.date);
					const tomorrowWithSameTime = dayjs(tomorrow).set('hour', currentDate.hour()).set('minute', currentDate.minute()).set('second', currentDate.second());
					task.date = dayjs(tomorrowWithSameTime).add(Object.values(diff)[0], 'day').toISOString();
					task.dueDate = dayjs(task.date).add(duration).toISOString();
				}
				return null;
			});

			if (task.recurringUntil) {
				const startDate = dayjs(task.date);
				const recurringUntil = dayjs(task.recurringUntil);
				const recurringUntilDuration = recurringUntil.diff(startDate);
				task.recurringUntil = dayjs(task.date).add(recurringUntilDuration).toISOString();
			}
		});
		return updatedTask;
}