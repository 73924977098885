import React from 'react';
import { css } from 'twin.macro';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import { Body } from './styles';

import ClientForm from './ClientForm';

const CreateClient = ({ isTeamSettings, provider, teamId, channelId }) => {
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <button type="button" className="btn button-green" onClick={() => setShowModal(true)}>
                Add Client
            </button>
            <Modal
                show={showModal}
                backdropClassName="bg-dark"
                onHide={() => setShowModal(false)}
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <div className="header">
                        <h4
                            css={css`
                                margin: 0;
                            `}
                        >
                            Add New Client
                        </h4>
                    </div>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <Body>
                        <ClientForm
                            setShowModal={setShowModal}
                            isTeamSettings={isTeamSettings}
                            userClientProvider={provider}
                            team={teamId}
                            channel={channelId}
                        />
                    </Body>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default CreateClient;
