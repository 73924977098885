import { css } from 'twin.macro';
import { Fragment, useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { Dropdown, Modal, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { Link } from 'react-router-dom';
import ReactSelectComponent from '../../Admin/users/ReactSelectComponent';

//redux stuff
import { connect } from 'react-redux';
import { AssignManagerToTeam } from '../../../_redux/actions/ActionOrganization';
import { startLoading, stopLoading } from '../../../_redux/actions/ActionAuthentication';
import { getOtherUserData } from '../../../_redux/actions/ActionUser';

//styles
import { formLabel } from '../../../_elements/formLabel';
import formInput from '../../../_elements/formInput';
import loaderStyles from '../../../_elements/loader';

//componentss
import { InviteToTeamContent } from './InviteToTeamContent';

//helpers
import { fetchWrapper, onlyEmailValidation } from '../../../_helpers';

const checkAlreadyInTeam = (teamMembers, email) => {
    let memberIndex = teamMembers.findIndex((member) => member.email === email);
    let isMemberPresent = memberIndex > -1;
    return isMemberPresent;
};
const InviteManager = ({
    ui,
    teamMembers,
    teamId,
    orgId,
    startLoading,
    stopLoading,
    AssignManagerToTeam,
    getOtherUserData,
    type,
}) => {
    const [show, setShow] = useState(false);
    const [selectedOption, setSelectedOption] = useState();
    const [addByEmail, setAddByEmail] = useState(false);
    const [user, setUser] = useState(null);
    const [step, setStep] = useState('1');

    //Get the selected options values in user state
    useEffect(() => {
        if (selectedOption?.value) {
            const fectUserData = async () => {
                console.log(getOtherUserData, 'getOtherUserData');
                let user = await getOtherUserData(selectedOption.value);
                return setUser(user);
            };
            fectUserData();
        }
    }, [selectedOption, getOtherUserData]);

    const { register, getValues, errors, setError, clearErrors } = useForm({
        mode: 'onBlur',
        defaultValues: {
            email: '',
        },
        resolver: yupResolver(onlyEmailValidation),
    });

    const handleClose = () => {
        setShow(false);
    };
    const handleShow = () => {
        setShow(true);
        setAddByEmail(false);
        setUser(null);
        setSelectedOption({});
        setStep('1');
    };

    const handleInvite = async () => {
        startLoading();
        const data = getValues('name');
        const email = getValues('email');

        if (!user && !data) {
            stopLoading();
            return setError('name', {
                type: 'manual',
                message: 'Name is required',
            });
        }

        const userData = user ? await fetchWrapper.get(`/users/${user.email}/email`) : null;

        const tempData = {
            name: user?.name || data,
            email: user?.email || email,
            ...(userData
                ? {
                      avatar: userData.avatar,
                      role: userData.role,
                      _id: userData.id,
                      team: [{ role: 'teamManager', _id: '', team: teamId }],
                  }
                : {}),
        };

        const { response } = await AssignManagerToTeam(
            orgId,
            teamId,
            tempData,
            checkAlreadyInTeam(teamMembers, tempData.email)
        );

        stopLoading();

        if (!response) {
            return;
        }

        setShow(false);
        setUser(null);
        setSelectedOption(null);
    };

    const handleBack = () => {
        setStep('1');
        setUser(null);
    };
    const handleNext = async () => {
        startLoading();
        const email = getValues('email');
        if (user) {
            stopLoading();
            return setStep('2');
        } else if (email) {
            stopLoading();
            return setStep('2');
        } else {
            stopLoading();
            return setError('email', {
                type: 'manual',
                message: 'Name or Email is required',
            });
        }
    };

    return (
        <Fragment>
            {type === 'admin' ? (
                <Link onClick={handleShow}>Invite Manager</Link>
            ) : (
                <Dropdown.Item onClick={handleShow}>Invite Manager</Dropdown.Item>
            )}
            {show && (
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title className="modal-headers">Invite Manager in This Team</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {ui?.loading && (
                            <div css={loaderStyles}>
                                <img src="/images/loading.gif" alt="loader" />
                            </div>
                        )}
                        {step === '1' ? (
                            addByEmail ? (
                                <Fragment>
                                    <Form.Group>
                                        <Form.Label css={formLabel}>Email address</Form.Label>
                                        <Form.Control
                                            css={formInput}
                                            type="email"
                                            isInvalid={errors.email}
                                            placeholder="Enter email"
                                            name="email"
                                            ref={register}
                                        />
                                        <Form.Text className="text-danger">{errors.email?.message}</Form.Text>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label css={formLabel}>Name</Form.Label>
                                        <Form.Control
                                            css={formInput}
                                            type="text"
                                            isInvalid={errors.name}
                                            onChange={() => clearErrors('name')}
                                            placeholder="Enter name"
                                            name="name"
                                            ref={register}
                                        />
                                        {errors.firstName && 'First name is required'}
                                        <Form.Text className="text-danger">{errors.name?.message}</Form.Text>
                                    </Form.Group>
                                    <div
                                        css={css`
                                            font-size: 1.5vmin;
                                            padding-top: 60px;
                                        `}
                                    >
                                        Add by{' '}
                                        <Link
                                            onClick={() => {
                                                setAddByEmail(false);
                                            }}
                                        >
                                            Name
                                        </Link>
                                        ?
                                    </div>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label css={formLabel}>Name</Form.Label>
                                        <ReactSelectComponent
                                            selectedOption={selectedOption}
                                            setSelectedOption={setSelectedOption}
                                            placeholder="Search user"
                                            assignedTo={selectedOption && selectedOption.value}
                                        />
                                    </Form.Group>
                                    <div>
                                        User not in the system? Add by{' '}
                                        <Link
                                            onClick={() => {
                                                setAddByEmail(true);
                                            }}
                                        >
                                            Email
                                        </Link>
                                    </div>
                                </Fragment>
                            )
                        ) : (
                            <Fragment>
                                {user ? (
                                    <InviteToTeamContent teamId={null} setShow={setShow} user={user} />
                                ) : (
                                    <Fragment>
                                        <Form.Group>
                                            <Form.Label css={formLabel}>Email address</Form.Label>
                                            <Form.Control
                                                css={formInput}
                                                type="email"
                                                defaultValue={getValues('email')}
                                                disabled
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label css={formLabel}>Name</Form.Label>
                                            <Form.Control
                                                css={formInput}
                                                type="text"
                                                placeholder="Enter name"
                                                defaultValue={getValues('name')}
                                                disabled
                                            />
                                        </Form.Group>
                                    </Fragment>
                                )}
                            </Fragment>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        {step === '1' ? (
                            <div className="w-100 d-flex justify-content-between">
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={handleNext}>
                                    Next
                                </Button>
                            </div>
                        ) : (
                            <div className="w-100 d-flex justify-content-between">
                                <Button variant="secondary" onClick={handleBack}>
                                    Back
                                </Button>
                                <Button variant="primary" onClick={handleInvite}>
                                    Invite
                                </Button>
                            </div>
                        )}
                    </Modal.Footer>
                </Modal>
            )}
        </Fragment>
    );
};

InviteManager.propTypes = {};

const mapStateToProps = (state) => ({
    ui: state.ui,
    teamMembers: state.teams.teamMembers,
});

const mapDispatchToProps = { startLoading, stopLoading, AssignManagerToTeam, getOtherUserData };

export default connect(mapStateToProps, mapDispatchToProps)(InviteManager);
