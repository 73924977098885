import { css } from 'twin.macro'

import { Col, Row, Container } from 'react-bootstrap';


const footer = css`
	padding-bottom: 0;
	padding-bottom: env(safe-area-inset-bottom, 0);
`;
function Footer() {
	return (
		<footer css={footer}>
			<Container>
				<Row>
					<Col md="12">
						<div className=""></div>
					</Col>
				</Row>
			</Container>
		</footer>
	);
}

export default Footer;
