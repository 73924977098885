import { css } from 'twin.macro';
// import PropTypes from 'prop-types';
import React, { useState, Fragment, useEffect } from 'react';
import { Modal, Button, Form, Accordion, Table, Card, Nav, Alert } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { createChannelValidation } from '../../../_helpers';
import { toast } from 'react-toastify';

import loaderStyles from '../../../_elements/loader';
import { formLabel } from '../../../_elements/formLabel';
import formInput from '../../../_elements/formInput';
import { fetchWrapper } from '../../../_helpers';
import { connect } from 'react-redux';

import { VscLoading } from 'react-icons/vsc';
import LocationComponent from './LocationComponent';
import { FaEdit } from 'react-icons/fa';
import { actions } from './styles/TeamDetailsStyles';
import FormsEdit from './EditChannel/FormsEdit';
import { ToolTip } from '../../../_elements/ToolTip';
import EditPublicGpsCategory from '../../../components/Admin/Settings/PublicGps/components/EditPublicGpsCategory';

const EditChannelModal = ({ show, room, team, teamId, handleClose, setUpdateChannel }) => {
	const [loading, setLoading] = useState(false);
	const [location, setLocation] = useState(null);
	const [formPage, setFormPage] = useState('none');
	const [showEditCategory, setShowEditCategory] = useState(false);
	const [channelCategory, setChannelCategory] = useState(() => {
		if (room?.premiumPinData?.pin) {
			return {
				pin: room.premiumPinData.pin,
				name: room.premiumPinData.name,
			};
		}
		if (room?.normalPinData?.pin) {
			return {
				pin: room.normalPinData.pin,
				name: room.normalPinData.name,
			};
		}
		return {
			pin: '',
			name: '',
		};
	});

	const { register, watch, setError, handleSubmit, setValue, getValues, errors } = useForm({
		mode: 'onBlur',
		resolver: yupResolver(createChannelValidation),
	});
	const watchType = watch('type', room.type);
	// const watchLocation = watch('location', room.location);

	const onSubmit = async (data) => {
		setLoading(true);
		let tempData = data;
		try {
			if (!data.title || !data.subTitle) {
				return setError('title', {
					type: 'manual',
					message: 'Title and description are required',
				});
			}
			if (data.type === 'gps_enabled') {
				if (!location) {
					setLoading(false);
					return setError('location', {
						type: 'manual',
						message: 'Must Verify a location',
					});
				} else {
					tempData = { ...data, ...location };
				}
			} else if (data.type === 'public' && data.addresses && data.addresses.length) {
				for (let i = 0; i < data.addresses.length; i++) {
					if (data.addresses[i]?.latitude === '') {
					  if (room.addresses.length > 0) {
						setLoading(false);
						return toast.warning(`Please Verify the location of ${data.addresses[i]?.location}`);
					  } else {
						delete tempData.addresses;
					  }
					}
				  }
			}
			const updatedChannel = await fetchWrapper.put(`chatrooms/${room._id || room.id}`, tempData);
			setUpdateChannel((prev)=>({...prev, ...updatedChannel, client: prev?.client}));

			// Edit is handled by server using socket,
			handleClose();
			toast.success('Channel edited successfully');
		} catch (e) {
			handleClose();
			toast.error(e.message);
		} finally {
			setLoading(false);
		}
	};

	const setSelectedPage = (activePage) => {
		setFormPage(activePage);
	};
	return (
		<Modal show={show} onHide={handleClose} size={"lg"}>
			<Modal.Header closeButton>
				<Modal.Title className="modal-headers">Edit A Channel</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{loading && (
					<div css={loaderStyles}>
						<img src="/images/loading.gif" alt="loader" />
					</div>
				)}

				<Form.Group controlId="formBasicTitle">
					<Form.Label css={formLabel}>Enter Channel Name</Form.Label>
					<Form.Control
						css={formInput}
						type="text"
						isInvalid={errors.title}
						defaultValue={room.title}
						placeholder="Name of Channel"
						name="title"
						ref={register}
					/>
					<Form.Text className="text-danger">{errors.title?.message}</Form.Text>
				</Form.Group>
				{/* <Form.Group controlId="exampleForm.ControlSelect1">
						<Form.Label css={formLabel}>Is this a Rollup Channel?</Form.Label>
						<Form.Control css={formInput} as="select">
							<option>Yes, Please!</option>
							<option>No, Thanks!</option>
						</Form.Control>
					</Form.Group> */}
				<Form.Group controlId="formBasicSubTitle">
					<Form.Label css={formLabel}>Description</Form.Label>
					<Form.Control
						css={formInput}
						as="textarea"
						isInvalid={errors.subTitle}
						placeholder="What is your channel about"
						defaultValue={room.subTitle}
						name="subTitle"
						ref={register}
					/>
					<Form.Text className="text-danger">{errors.subTitle?.message}</Form.Text>
				</Form.Group>
				{/* <Form.Group controlId="exampleForm.ControlSelect2">
						<Form.Label css={formLabel}>Choose Channel Type</Form.Label>
						<Form.Control css={formInput} as="select">
							<option>HIPAA Compliant</option>
							<option>World (Public without GPS)</option>
							<option>Invite Only</option>
							<option>World (Public with GPS)</option>
						</Form.Control>
					</Form.Group>
					<Form.Group controlId="formBasicAddress">
						<Form.Label css={formLabel}>Address</Form.Label>
						<Form.Control css={formInput} type="text" placeholder="1234 Example ST" />
					</Form.Group> */}
				<Form.Group controlId="exampleForm.ControlSelect2">
					<Form.Label css={formLabel}>Choose Channel Type</Form.Label>
					<Form.Control css={formInput} as="select" defaultValue={room.type} name="type" ref={register}>
						{/* <option>HIPAA Compliant</option> */}
						{/* <option>World (Public without GPS)</option>
							<option>Invite Only</option>
							<option>World (Public with GPS)</option> */}
						<option value="public">Public</option>
						<option value="private">Private</option>
						<option value="gps_enabled">GPS Enabled</option>
					</Form.Control>
				</Form.Group>

				{(watchType === 'gps_enabled' || watchType === 'public') && (
					<>
						<Form.Group controlId="exampleForm.ControlSelect2">
							<Form.Label css={formLabel}>Channel Category</Form.Label>
							<>
								<div className="mr-4 category d-flex align-items-center">
									{channelCategory?.name ? (
										<ToolTip text={channelCategory?.name}>
											<div className="d-flex flex-column align-items-center justify-content-center">
												<img
													src={channelCategory.pin}
													alt={channelCategory.name}
													style={{ objectFit: 'contain' }}
													width={50}
													height={50}
												/>
											</div>
										</ToolTip>
									) : (
										<Alert variant="warning"> Please update your channel category</Alert>
									)}
									<Button
										className="ml-2"
										onClick={() =>
											setShowEditCategory({
												id: room.id,
												activeCategoryName:
													room?.premiumPinData?.name || room?.normalPinData?.name || null,
											})
										}
									>
										{channelCategory?.name ? 'Update' : 'Edit'}
									</Button>
								</div>
								<EditPublicGpsCategory
									show={showEditCategory}
									handleClose={(selectedCategory) => {
										setChannelCategory(selectedCategory);
										setShowEditCategory(false);
									}}
								/>
							</>
						</Form.Group>
						<LocationComponent
							room={room}
							formLabel={formLabel}
							watch={watch}
							register={register}
							getValues={getValues}
							setValue={setValue}
							errors={errors}
							location={location}
							setLocation={setLocation}
						/>
					</>
				)}

				<Fragment>
					<div>
						<Accordion>
							<Card>
								<Accordion.Toggle as={Button} variant="link" eventKey="0">
									<Card.Header>
										<h1 className="text-left">Forms</h1>
									</Card.Header>
								</Accordion.Toggle>
								<Accordion.Collapse eventKey="0">
									<Card.Body>
										<div>
											<Nav
												className=""
												variant="tabs"
												defaultActiveKey="none"
												onSelect={(selectedKey) => setSelectedPage(selectedKey)}
												css={css`
													padding-bottom: 1rem;
												`}
											>
												<Nav.Item>
													<Nav.Link eventKey="forms">Active forms</Nav.Link>
												</Nav.Item>
												<Nav.Item>
													<Nav.Link eventKey="archived">Archived forms</Nav.Link>
												</Nav.Item>
											</Nav>
										</div>
										{formPage === 'forms' && <FormsEdit team={team} currRoom={room} />}
										{formPage === 'archived' && <FormsEdit team={team} currRoom={room} archivedForm={true}/>}
									</Card.Body>
								</Accordion.Collapse>
							</Card>
						</Accordion>
					</div>
				</Fragment>
			</Modal.Body>
			<Modal.Footer className="d-flex justify-content-between align-items-center">
				<Button variant="secondary" onClick={handleClose}>
					Close
				</Button>
				<Button variant="primary" onClick={handleSubmit(onSubmit)}>
					Update
				</Button>
			</Modal.Footer>
		</Modal>
	);
};
const EditChannel = ({ room, team, teamId, newChannelSettings, setUpdateChannel=()=>{} }) => {
	const [show, setShow] = useState(false);

	const handleClose = () => {
		setShow(false);
	};
	const handleShow = () => {
		setShow(true);
	};

	return (
		<Fragment>
			{
			newChannelSettings ?
				<div className="action-btn" onClick={handleShow}>
					<FaEdit />
				</div>
			:
			<div css={actions} onClick={handleShow}>
				<FaEdit />
				<span>Edit</span>
			</div>
			}

			{show && <EditChannelModal show={show} handleClose={handleClose} room={room} team={team} teamId={teamId} setUpdateChannel={setUpdateChannel}/>}
		</Fragment>
	);
};

const MapStateToProps = (state) => ({
	user: state.user.user,
});

export default connect(MapStateToProps, null)(EditChannel);
