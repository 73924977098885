import styled from "@emotion/styled";

export const ChatListStyled = styled('div')`
	height:calc(100vh - 50px);
	overflow-y:scroll;

	&::-webkit-scrollbar {
		width:5px;
		cursor:pointer;
	}
	
	&::-webkit-scrollbar-thumb {
		background-color:#C3E4E2;
		border-radius: 2.5px;
	}

	
	.active {
		background-color: #E6EEED !important;
	}

	.chat-list-item{
		padding:10px;
		background-color: #f7ffff; //teamlist-color
		height:60px;
		display:flex;
		flex-direction:row;
		border-bottom: 1px solid #dddddd;
		justify-content: space-between;
		cursor:pointer;
	}
	.avatar-details{
		display:flex;
		flex-direction: row;
		align-items: center;
	}
	.details{
		margin-left:10px;
		font-size: 14px;
		width: 90%;
		max-width: 20rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.chat_date{
		font-size: 12px;
	}
	.no-list{
		height:100%; 
		display:flex; 
		align-items:center; 
		justify-content:center;
	}

	@media only screen and (max-width: 767px) {
		padding-bottom: 2rem;
	}

`;

export const TopSection = styled('div')`
	width: 100%;
	background-color: #f0f0f0;
	position: sticky;
	top: 0;
	z-index: 1;
	height: 14rem;
	@media only screen and (max-width: 767px) {
		display: none;
	}
`;

export const SearchInputStyled = styled('div')`
	width: 100%;
	margin-top: 2rem;
	border: 0px;
	border-radius: 21px;
	background-color: #ffffff;
	font-size: 1.3rem;
	background-image: url('https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/search-24.png');
	background-position:97% 50%;
	background-repeat: no-repeat;
	padding: 1rem 4rem 1rem 2rem;
	opacity: 0.9;

	.inputfield{
		&:focus{
			outline:none;
		}
	}

`;