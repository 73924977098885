import React, { useState, Fragment, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { fetchWrapper, getTeamIdFromURL, getUniqueObjects } from '../../../../_helpers';
import { useContextStore } from '../../../../_context/contextStore';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

function TagsModal({ isModal = true, canSubmit = false, placeholder = "Enter Tags", teamId, show, handleClose, type, tagAction, objectId, prevTags = [], responseCallback, currentStateCallback }) {
    const team = teamId || getTeamIdFromURL();
    const { refetchTagsOnSubmit } = useContextStore();
    const [_, setToggleRefetchFilterTags] = refetchTagsOnSubmit;
    const [loading, setLoading] = useState(false);
    const [responseResults, setResponseResults] = useState({ results: [], totalResults: 9e9 });
    const [selectedValues, setSelectedValues] = useState(() => prevTags.map((tag) => ({ label: tag?.name?.toUpperCase(), value: tag?._id })));
    const handleChange = (newValues, actionMeta) => {
        setSelectedValues(() => newValues.map((tag) => ({ ...tag, label: tag?.label?.trim()?.replace(/\s+/g, '-')?.replace(/[^a-zA-Z0-9-]/g, '')?.toUpperCase() })));
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            const payload = selectedValues.map((tag) => ({ _id: `${tag?.__isNew__ ? `NEW_TAG_${Date.now()}` : tag?.value}`, name: tag?.label, type: tag?.__isNew__ ? 'all' : undefined, team: tag?.__isNew__ ? team : undefined }));
            let endpoint = "";
            if (!tagAction) {
                return toast.error("Tag action not passed")
            }
            if (tagAction === "store") {
                endpoint = "tags";
            }
            else if (tagAction === "assign") {
                if (!team) {
                    return toast.error("Team Id not passed");
                }
                if (!type) {
                    return toast.err("Type not passed");
                }
                if (!objectId) {
                    return toast.error(`${type} Id not passed`);
                }
                endpoint = `tags/${type}/${objectId}${team ? `?team=${team}` : ''}`
            }

            const response = await fetchWrapper.post(endpoint, payload)
            if (responseCallback) { responseCallback(response); }
            if (handleClose) { handleClose(); }
            const hasNewTags = selectedValues.some(tag => tag?.__isNew__);
            if (hasNewTags) { setToggleRefetchFilterTags(true) }
        }
        catch (err) {
            toast.error(err?.message || "Something went wrong. Please try again!")
        }
        finally {
            setLoading(false);
        }
    }
    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => func(...args), delay);
        };
    };

    const fetchData = async (inputValue, callback) => {
        try {
            let tempArray = [];
            if ((responseResults?.results?.length < responseResults?.totalResults)) {
                const response = await fetchWrapper.get(
                    `tags?limit=100${team ? `&team=${team}` : ''}${type ? `&type=${type}` : ''}${inputValue ? `&name=${inputValue}` : ''}`
                );
                setResponseResults(prev => {
                    const newResults = getUniqueObjects((prev?.results || []), (response?.results || []));
                    return { ...response, results: newResults, totalResults: inputValue.toString() ? (prev?.totalResults || 9e9) : response?.totalResults }
                })
                tempArray = response?.results?.map((element) => ({
                    label: element?.name,
                    value: element?._id,
                }));
            }
            if (!tempArray?.length) {
                tempArray = responseResults?.results?.filter((tag) => tag?.name && tag?.name?.toUpperCase()?.includes(inputValue?.toUpperCase())
                )?.map((filteredTag) => ({ label: filteredTag?.name, value: filteredTag?._id }));
            }
            callback(tempArray?.sort((a, b) => (a?.label?.toUpperCase() > b?.label?.toUpperCase() ? 1 : -1)));
        } catch (error) {
            console.log(error);
            toast.error(error?.message || "Something went wrong!")
        }
    };
    const fetchDataDebounced = debounce(fetchData, 1000);
    useEffect(() => { if (currentStateCallback) { currentStateCallback(selectedValues) } }, [selectedValues])

    return (
        <Fragment>
            {
                !isModal ? (
                    <Fragment>
                        <div>
                            <AsyncCreatableSelect
                                cacheOptions
                                defaultOptions
                                loadOptions={fetchDataDebounced}
                                isMulti
                                onChange={handleChange}
                                value={selectedValues}
                                placeholder={placeholder}
                            />
                            {
                                !canSubmit ? null :
                                    <Button variant="primary" onClick={handleSubmit} disabled={loading} style={{ padding: '5px 10px', height: '40px' }}>
                                        Confirm
                                    </Button>
                            }

                        </div>
                    </Fragment>
                ) : (<Fragment>
                    <Modal show={show} onHide={handleClose}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton style={{ textAlign: 'center', fontWeight: 'bold' }}>
                            Add Tags {type ? ` in ${type}` : null}
                        </Modal.Header>
                        <Modal.Body>
                            <AsyncCreatableSelect
                                cacheOptions
                                defaultOptions
                                loadOptions={fetchDataDebounced}
                                isMulti
                                onChange={handleChange}
                                value={selectedValues}
                                placeholder={placeholder}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose} disabled={loading}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={handleSubmit} disabled={loading} style={{ padding: '5px 10px', height: '40px' }}>
                                Confirm
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Fragment>
                )

            }

        </Fragment>
    );
}

TagsModal.propTypes = {
    isModal: PropTypes.bool,
    show: PropTypes.bool,
    placeholder: PropTypes.string,
    canSubmit: PropTypes.bool,
    handleClose: PropTypes.func,
    type: PropTypes.string,
    tagAction: PropTypes.string,
    objectId: PropTypes.string,
    prevTags: PropTypes.array,
    responseCallback: PropTypes.func,
    stateCallback: PropTypes.func
}

export default TagsModal;