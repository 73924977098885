import React from 'react';
import {
	Row,
	Container,
	// , Navbar, Nav
} from 'react-bootstrap';

import logo from '../../assets/logos/logo.svg';
import {
	LogoStyle,
	NavGroup,
	// , NavLinkStyled
} from './HeaderStyles';

function Header() {
	return (
		<div className="bg-white">
			<Container className="h-100">
				<Row className="h-100">
					<NavGroup expand="md" className="w-100">
						{/* <LogoText className="navbar-brand" to="/">
						</LogoText> */}
						<a
							className="text-primary text-decoration-none"
							href="https://www.optonome.com/"
							target="_blank"
							rel="noreferrer"
							style={{
								padding: '1rem',
								fontSize: '2.3rem',
								fontWeight: 500,
								letterSpacing: 0,
								textTransform: 'uppercase',
							}}
						>
							<LogoStyle src={logo} alt="logo" />
							{' '}
							Optonome
						</a>

						{/* <Navbar.Toggle aria-controls="main-nav" />
						<Navbar.Collapse id="main-nav" className=" justify-content-end">
							<Nav>
								<NavLinkStyled className="nav-link" to="/register">
									Join Optonome
								</NavLinkStyled>
								<NavLinkStyled className="nav-link" to="/register">
									Blog
								</NavLinkStyled>
								<NavLinkStyled className="nav-link " to="/register">
									What We Do
								</NavLinkStyled>
								<NavLinkStyled className="nav-link" to="/register">
									About Us
								</NavLinkStyled>
							</Nav>
						</Navbar.Collapse> */}
					</NavGroup>
				</Row>
			</Container>
		</div>
	);
}

export default Header;
