import React, { Fragment } from 'react';
import Select from 'react-select';

const zealReportTypes = [
    {
        label: 'Cash Requirements',
        value: 'cash-requirements',
        description: 'Company debit, extra cash requirements, total cash requirements.',
    },
    {
        label: 'Custom Payroll Journal',
        value: 'custom-payroll-journal',
        date: true,
        description: 'Create a custom report of your past employee checks',
    },
    {
        label: 'Payroll Journal',
        value: 'payroll-journal',
        date: true,
        description: 'Download a summary of all employee checks for a payroll run or employer check.',
    },
    {
        label: 'Labor allocation',
        value: 'labor-allocation',
        date: true,
        description: 'View distribution of labor within a check date range.',
    },
    {
        label: 'Paystubs',
        value: 'paystubs',
        date: true,
        description: 'Create a collection of paystubs for a specific payroll run or employer check.',
    },
    {
        label: 'Worker Summary',
        value: 'worker-summary',
        description: 'Generate a report with all the values for employees or contractors.',
    },
    {
        label: 'Payment Summary',
        value: 'payment-summary',
        date: true,
        description: 'Generate a payment summary report.',
    },
    {
        label: 'KYC Summary',
        value: 'kyc-summary',
        description: 'Download a summary of all workers and their KYC status.',
    },
    {
        label: 'Deductions Summary',
        value: 'deductions-summary',
        date: true,
        description: 'Download a summary of all deductions for a payroll run.',
    },
];
const ZealReportSelect = ({ providerPayroll = {}, selectedReportOption, handleSelectedReport }) => {
    const isProviderOnboarded = providerPayroll?.metadata?.companyID && providerPayroll?.metadata?.status === 'live';

    return (
        <Fragment>
            <span>Select Report:</span>
            <br />
            <Select
                value={selectedReportOption?.value ? selectedReportOption : null}
                onChange={(option) => handleSelectedReport(option)}
                options={zealReportTypes.map((option) => ({
                    label: option?.label,
                    value: option?.value,
                    ...option,
                }))}
                isDisabled={!isProviderOnboarded}
            />
        </Fragment>
    );
};

export default ZealReportSelect;
