import { Fragment, useEffect, useState } from 'react';
import { ActionStyles } from '../../../../reusableStyles';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { css } from 'twin.macro';
import { fetchWrapper, mapArrayToObject } from '../../../../../../_helpers';
import ReactSelect from 'react-select';
import { Button, Form } from 'react-bootstrap';
import tw from 'twin.macro';
import LoadingSpiner from '../../../../../../_elements/LoadingSpiner';
import { PolicyCard } from './PolicyDetailCard';
import { VscLoading } from 'react-icons/vsc';
import { toast } from 'react-toastify';

const TableHeader = tw.div`flex flex-row justify-between items-center p-4 bg-gray-200 font-bold mt-4`;
const TableRow = tw.div`flex  flex-row justify-between items-center p-4 `;

const AddOrRemoveEmployees = ({ teamId, companyID, selectedAccrualPolicy = {}, goBack }) => {
    const [employees, setEmployees] = useState([]);
    const [validEmployees, setValidEmployees] = useState([]);
    const [employeesObj, setEmployeesObj] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isBusy, setIsBusy] = useState(false);

    const getEmployeeName = (employeeID, tempEmployeeObj) => {
        try {
            let employeeDetails = '';
            if (tempEmployeeObj) {
                employeeDetails = tempEmployeeObj[employeeID];
            } else {
                employeeDetails = employeesObj[employeeID];
            }
            return `${employeeDetails?.first_name || ''} ${employeeDetails?.last_name || ''}`;
        } catch (err) {
            return 'No name';
        }
    };

    const getEmployees = async () => {
        try {
            if (!selectedAccrualPolicy) {
                return;
            }
            setIsLoading(true);
            let policyEmployeesResult = [];
            if (selectedAccrualPolicy?.policy_code) {
                const policyEmployeesResponse = await fetchWrapper.get(
                    `evv/payroll/zeal/provider/${companyID}/accrual-policy/employees?policy_code=${selectedAccrualPolicy?.policy_code}`
                );
                policyEmployeesResult = policyEmployeesResponse?.accrualPolicies || [];
            }
            const employeesResult = await fetchWrapper.get(
                `evv/payroll/zeal/provider/${companyID}/employees${teamId ? `?team=${teamId}` : ''}`
            );

            const tempPolicyEmployeesObj = mapArrayToObject(policyEmployeesResult || [], 'employeeID');
            const tempEmployeesObj = mapArrayToObject(employeesResult, 'employeeID');
            setEmployeesObj(tempEmployeesObj);
            setValidEmployees(
                (employeesResult.filter((item) => item?.employeeID) || []).map((employee) => ({
                    ...employee,
                    ...(tempPolicyEmployeesObj[employee?.employeeID] || {}),
                    accrual_balance: employee?.accrual_balance || selectedAccrualPolicy?.immediate_balance || 0,
                }))
            );
            setEmployees(
                (policyEmployeesResult || []).map((policyEmployee) => ({
                    label: getEmployeeName(policyEmployee.employeeID, tempEmployeesObj),
                    value: policyEmployee.employeeID,
                    accrual_balance: policyEmployee?.accrual_balance || 0,
                    ...policyEmployee,
                }))
            );
        } catch (err) {
            if (err?.name === 'AbortError') {
                return;
            }
            console.log('err', err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAccrualBalanceChange = (employeeID, value) => {
        setEmployees((prev) =>
            prev.map((_employee) =>
                _employee?.employeeID === employeeID ? { ..._employee, accrual_balance: parseFloat(value) } : _employee
            )
        );
    };

    const handleSubmit = async () => {
        try {
            if (!selectedAccrualPolicy?.policy_code) {
                return toast.error('Policy Code is missing');
            }
            setIsBusy(true);
            const response = await fetchWrapper.patch(
                `evv/payroll/zeal/provider/${companyID}/accrual-policy/employees?policy_code=${selectedAccrualPolicy?.policy_code}`,
                employees
            );
            (response.message || []).forEach((msg) => toast.success(msg));
            getEmployees();
        } catch (err) {
            console.log('err', err);
            toast.error(err?.message || 'Something went wrong');
        } finally {
            setIsBusy(false);
        }
    };

    useEffect(() => {
        getEmployees();
    }, [selectedAccrualPolicy]);

    const handleEmployeeChange = (selectedOptions) => {
        setEmployees(selectedOptions);
    };

    return (
        <Fragment>
            <div
                css={css`
                    padding: 0 16px;
                `}
            >
                <div
                    css={css`
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin-top: 16px;
                    `}
                >
                    <ActionStyles>
                        <IoMdArrowRoundBack className="icon back" onClick={() => goBack()} />
                    </ActionStyles>
                    <span
                        css={css`
                            margin-left: 8px;
                        `}
                    >
                        Add/Remove Employees
                    </span>
                </div>
                <div
                    css={css`
                        margin-top: 16px;
                    `}
                >
                    <PolicyCard policy={selectedAccrualPolicy} fullView={true} />
                </div>
                <div
                    css={css`
                        margin-top: 8px;
                    `}
                >
                    <p>Select Employees</p>
                    <ReactSelect
                        options={validEmployees?.map((employee) => ({
                            label: getEmployeeName(employee.employeeID),
                            value: employee.employeeID,
                            ...employee,
                        }))}
                        isMulti
                        value={!selectedAccrualPolicy || isBusy ? [] : employees}
                        onChange={handleEmployeeChange}
                        isDisabled={isBusy || !selectedAccrualPolicy || isLoading}
                        isLoading={isBusy}
                    />
                </div>
                {isLoading ? <LoadingSpiner size={42} /> : null}
                <div
                    css={css`
                        display: flex;
                        flex-direction: column;
                    `}
                >
                    {employees?.length ? (
                        <Fragment>
                            <TableHeader>
                                <div>Employee</div>
                                <div>Accrual Amount </div>
                            </TableHeader>
                            {employees.map((employee) => (
                                <TableRow
                                    key={employee.value}
                                    css={css`
                                        border-bottom: 1px solid #e5e7eb;
                                    `}
                                >
                                    <div>
                                        <span>{getEmployeeName(employee.value)}</span>
                                    </div>
                                    <div>
                                        <Form.Control
                                            disabled={isBusy || isLoading}
                                            as="input"
                                            type="number"
                                            value={employee.accrual_balance || 0}
                                            onChange={(e) => handleAccrualBalanceChange(employee.value, e.target.value)}
                                            placeholder="Accrual Balance"
                                            onWheelCapture={(e) => e.target.blur()}
                                            css={css`
                                                width: 150px;
                                            `}
                                        ></Form.Control>
                                    </div>
                                </TableRow>
                            ))}
                        </Fragment>
                    ) : null}
                </div>
                <div
                    css={css`
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        position: sticky;
                        bottom: -12px;
                        background: white;
                        padding: 12px 0;
                    `}
                >
                    <hr
                        css={css`
                            width: 100%;
                        `}
                    />
                    <Button onClick={handleSubmit} disabled={isLoading || isBusy}>
                        {isBusy && <VscLoading className="spin mr-2" />}
                        Update Employees/Accrual Balance
                    </Button>
                </div>
            </div>
        </Fragment>
    );
};

export default AddOrRemoveEmployees;
