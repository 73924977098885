import { css } from '@emotion/react';
import React, { Fragment, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { checkPermission } from '../../../_helpers';
import CreateChannelModal from './CreateChannelModal';
import InviteManager from './InviteManager';
import InviteToTeam from './InviteToTeam';

function ThreeDots({ user, role, team }) {
    const [visibleDropDowns, setVisibleDropDowns] = useState([]);
    const [visibleCreateChannel, setVisibleCreateChannel] = useState(false);
    const [visibleSettings, setVisibleSettings] = useState(false);
    const [showThreeDots, setShowThreeDots] = useState(false);
    const [show, setShow] = useState(false);
    const teamId = team.id || team._id;
    useEffect(() => {
        const Components = [
            {
                component: <InviteToTeam key="inviteToTeam-123" teamId={teamId} orgId={team.organization} />,
                action: 'invite:user',
                data: {
                    userId: user.id,
                    teamAdmin: team.admin,
                },
            },
            {
                component: <InviteManager key="inviteManager-123" teamId={teamId} orgId={team.organization} />,
                action: 'invite:manager',
                data: {
                    userId: user.id,
                    teamAdmin: team.admin,
                    user: user,
                },
            },
        ];
        let presentComponents = [];
        for (let component of Components) {
            if (checkPermission(role, component.action, component.data)) {
                presentComponents.push(component.component);
            }
        }
        setVisibleDropDowns(presentComponents);
    }, [user, role, team]);

    useEffect(() => {
        if (checkPermission(role, 'create:channal', { userId: user.id, teamAdmin: team.admin })) {
            setShowThreeDots(true);
            setVisibleCreateChannel(true);
        }
    }, [user, role, team]);

    useEffect(() => {
        if (checkPermission(role, 'update:team', { userId: user?.id, teamAdmin: team[0]?.admin })) {
            setShowThreeDots(true);
            setVisibleSettings(true);
        }
    }, [user, role, team]);
    return (
        <Fragment>
            {showThreeDots ? (
                <Dropdown
                    className="threeDots"
                    css={css`
                        display: none;
                    `}
                >
                    <Dropdown.Toggle
                        className="mr-1 "
                        id="dropdown-autoclose-true"
                        css={css`
                            width: 35px;
                            height: 35px;
                            background: white;
                            color: black;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border: 1px solid #c5c1c1;
                            border-radius: 100%;
                            cursor: pointer;
                            :hover {
                                background-color: #e3e3e3;
                                border-color: #c5c1c1;
                                color: black;
                            }
                        `}
                    >
                        <BsThreeDotsVertical fontSize="30" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                        css={css`
                            .dropdown-item {
                                font-size: 1.3rem;
                            }
                        `}
                    >
                        {visibleDropDowns.length !== 0 && (
                            <Fragment>{visibleDropDowns.map((visibleDropDown) => visibleDropDown)}</Fragment>
                        )}
                        {visibleCreateChannel && (
                            <Dropdown.Item
                                href="#"
                                css={css`
                                    .invite {
                                        border: none;
                                        background: transparent;
                                        min-height: 0px;
                                        color: inherit;
                                        padding: initial;
                                        :hover {
                                            transform: none;
                                        }
                                        svg {
                                            display: none;
                                        }
                                        span {
                                            font-size: 1.3rem;
                                        }
                                    }
                                `}
                            >
                                <span onClick={() => setShow(true)}>Create Channel</span>
                            </Dropdown.Item>
                        )}
                        {visibleSettings && (
                            <Link
                                to={`/teams/${teamId}/settings`}
                                className="mr-2"
                                css={css`
                                    display: block;
                                    width: 100%;
                                    padding: 0.25rem 1.5rem;
                                    clear: both;
                                    font-weight: 400;
                                    color: #212529;
                                    text-align: inherit;
                                    white-space: nowrap;
                                    background-color: transparent;
                                    border: 0;
                                    font-size: 1.3rem;
                                    :hover {
                                        text-decoration: none;
                                        color: #212529;
                                        background-color: #e9ecef;
                                    }
                                `}
                            >
                                Settings
                            </Link>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            ) : null}
            <CreateChannelModal show={show} setShow={setShow} teamId={teamId} />
        </Fragment>
    );
}

export default ThreeDots;
