import React from 'react';
import { useState } from 'react';
// import { Link } from 'react-router-dom';
// import { toast } from 'react-toastify';

import { deleteATeamById } from '../../../_redux/actions/ActionOrganization';
import { getAllUser } from '../../../_redux/actions/ActionUser';
import { connect } from 'react-redux';

// styles
import { Button, Dropdown, Modal } from 'react-bootstrap';
import { Body } from '../Teams/Styles/DeleteTeamStyles';
import { toast } from 'react-toastify';
import { customCheckbox } from '../../../_elements/CustomCheckbox';

const DeleteTask = ({ title, deleteTheTask, id }) => {
	const [deleteModal, setDeleteModal] = useState(false);
	const [agree, setAgree] = useState(false);
	const [error, setError] = useState('');

    const handleDeleteTask = () => {
        if (agree) {
			deleteTheTask(id);
			setDeleteModal(false);
            toast.success('Task deleted successfully!');
		} else {
			setError('Please read and sign the checkbox');
		}
	};

    const handleAgree =(check) => {
        if(check){
            setAgree(true)
        }else{
            setAgree(false)
        }
    }

	return (
		<>  
            <Dropdown.Item onClick={()=>setDeleteModal(true)}>
                <span>Delete</span>
            </Dropdown.Item>
			<Modal show={deleteModal} onHide={() => setDeleteModal(false)} centered>
				<Modal.Body className="p-0">
					<Body>
						<div className="title">
							<strong>Delete Task</strong>
						</div>
                        <div className="content d-flex flex-column justify-content-between">
                            <div>
                                <span className="text text-dark-grey">
                                    Are you sure you want to delete the task<span className="text-danger">{' '}{title}</span>?
                                </span>
                                <div className="d-flex flex-row align-items-center mt-2">
                                    <input type="checkbox" css={customCheckbox} id="agree1" className="inline" onChange={(e) => handleAgree(e.target.checked)} />
                                    <label htmlFor="agree1" className="d-inline m-0 ml-2 text-light-grey">
                                        I understand that the task will be permanently deleted
                                    </label>
                                </div>
                                <div className="text-danger">
                                    <small>{error}</small>
                                </div>
                            </div>
                            <div className="w-100 mt-4 d-flex flex-row justify-content-between align-items-center">
                                <Button
                                    variant="secondary"
                                    onClick={() => setDeleteModal(false)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                   variant="primary"
                                    onClick={() => handleDeleteTask()}
                                >
                                    Delete
                                </Button>
                            </div>
                        </div>
					</Body>
				</Modal.Body>
			</Modal>
		</>
	);
};

// export default DeleteTeam
const mapStateToProps = (state) => ({
	teams: state.teams.teams,
	isLoading: state.teams.isLoading,
    // allUsers: state.user,
});

export default connect(mapStateToProps, { deleteATeamById, getAllUser })(DeleteTask);
