import React from 'react';
import { css } from '@emotion/react';
import { SideMenuStyled } from './styles/SideMenuStyles';
import People from './People';
import { AiOutlineClose } from 'react-icons/ai';

function SideMenu({
	participants,
	activeTab,
	handleCloseSideMenu,
	conferenceId,
	channelInfo,
	handleMuteParticipant,
	isHost,
	handleRemoveParticipant,
}) {
	return (
		<SideMenuStyled>
			<div className="sideMenu">
				<div
					css={css`
						display: flex;
						justify-content: space-between;
						margin: 0.5rem 0 1rem 0;
					`}
				>
					<h1 className="title">{activeTab}</h1>
					<div
						onClick={handleCloseSideMenu}
						css={css`
							cursor: pointer;
						`}
					>
						<AiOutlineClose color={'white'} fontSize="24" />
					</div>
				</div>
				<hr
					css={css`
						height: 2px;
						background: white;
						border-radius: 25px;
						width: 100%;
						margin: 0 auto 2rem;
					`}
				/>
				<People
					conferenceId={conferenceId}
					participants={participants}
					channelInfo={channelInfo}
					handleMuteParticipant={handleMuteParticipant}
					isHost={isHost}
					handleRemoveParticipant={handleRemoveParticipant}
				/>
			</div>
		</SideMenuStyled>
	);
}

export default SideMenu;
