import { css } from 'twin.macro';
// import PropTypes from 'prop-types';
import { useState, Fragment, useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { fetchWrapper } from '../../../_helpers';
import { connect } from 'react-redux';
import { updateChannelNotification } from '../../../_redux/actions/ActionOrganization';

import { FiSettings } from 'react-icons/fi';
import { IoMdNotifications } from "react-icons/io";
import { VscLoading } from 'react-icons/vsc';
import { actions } from './styles/TeamDetailsStyles';
import { customCheckbox } from '../../../_elements/CustomCheckbox';

const NotificationSettings = ({ room, updateChannelNotification, handleClose }) => {
	const [loading, setLoading] = useState(false);
	const { register, handleSubmit, setValue } = useForm({
		mode: 'onBlur',
	});

	useEffect(() => {
		const getNotification = async () => {
			try {
				setLoading(true);
				if (room.id) {
					let notificationConfig = await fetchWrapper.get(`/chatrooms/notifications/${room.id}`);
					setLoading(false);

					if (notificationConfig) {
						setValue('pushReply', notificationConfig.pushMessageNotification.reply);
						setValue('pushMentions', notificationConfig.pushMessageNotification.mention);
						setValue('pushMessages', notificationConfig.pushMessageNotification.message);
						setValue('pushForm', notificationConfig.pushFormNotification);
						setValue('emailMessage', notificationConfig.emailMessageNotification);
						setValue('emailForm', notificationConfig.emailFormNotification);
						setValue('smsMessage', notificationConfig.smsMessageNotification);
						setValue('smsForm', notificationConfig.smsFormNotification);
					}
				}
			} catch (error) {
				setLoading(false);
				console.log(error);
			}
		};

		try {
			let roomNotification = room.notification;
			if (roomNotification) {
				setValue('pushMessages', roomNotification.pushMessages);
				setValue('pushReply', roomNotification.pushReply);
				setValue('pushMentions', roomNotification.pushMentions);
				setValue('pushForm', roomNotification.pushForm);
				setValue('emailMessage', roomNotification.emailMessage);
				setValue('emailForm', roomNotification.emailForm);
				setValue('smsForm', roomNotification.smsForm);
				setValue('smsMessage', roomNotification.smsMessage);
			} else {
				getNotification();
			}
		} catch (err) {
			setLoading(false);

			console.log(err);
		}
	}, [room, setValue]);

	const onSubmit = ({
		pushReply,
		pushMentions,
		pushMessages,
		pushForm,
		emailMessage,
		emailForm,
		smsMessage,
		smsForm,
	}) => {
		const postNotificationSetting = async () => {
			try {
				let pushMessageNotificationObj = {
					message: pushMessages,
					mention: pushMentions,
					reply: pushReply,
				};

				let formBody = {
					pushMessageNotification: pushMessageNotificationObj,
					pushFormNotification: pushForm,
					emailMessageNotification: emailMessage,
					emailFormNotification: emailForm,
					smsMessageNotification: smsMessage,
					smsFormNotification: smsForm,
				};

				await fetchWrapper.put(`/chatrooms/notifications/${room.id}`, formBody);
				updateChannelNotification(room.id, room.teamData?.id || room.team, {
					pushMessages,
					pushReply,
					pushMentions,
					pushForm,
					emailMessage,
					emailForm,
					smsMessage,
					smsForm,
				});
				toast.success(' Settings updated Successfully');
			} catch (err) {
				console.log(err);
				toast.error('Notification Settings failed to update');
			}
		};
		postNotificationSetting();
	};

	const NotificationDivCSS = css`
		.notificationOptionsDiv {
			position: relative;
		}
		.notificationLoading {
			position: absolute;
			width: 100%;
			height: 100%;
			background: rgba(255, 255, 255, 0.7);
			display: flex;
			justify-content: center;
			align-items: center;
			z-index: 5;
		}
		.notificationSec {
			margin-left: 2em;
			.checkinput {
				margin: 0 0.2em 0 1em;
			}
		}
	`;

	return (
		<div css={NotificationDivCSS}>
			<h3 className="font-weight-bold text-dark">Notifications</h3>
			<div className="notificationOptionsDiv">
				{loading && (
					<div className="notificationLoading">
						<VscLoading className="spin" fontSize={50} color="#50aeb0" />
					</div>
				)}
				<form key={2} onSubmit={handleSubmit(onSubmit)}>
					<div className="notificationSec">
						<Form.Group>
							<h4 className="font-weight-bold text-dark">Push Notification:</h4>

							<input
								className="checkinput"
								css={customCheckbox}
								id="message"
								type="checkbox"
								ref={register}
								name="pushMessages"
							/>
							<label htmlFor="message" className="mr-4">
								Messages
							</label>

							<input
								className="checkinput"
								css={customCheckbox}
								id="reply"
								type="checkbox"
								ref={register}
								name="pushReply"
							/>
							<label htmlFor="reply" className="mr-4">
								Reply
							</label>

							<input
								className="checkinput"
								css={customCheckbox}
								id="mentions"
								type="checkbox"
								ref={register}
								name="pushMentions"
							/>
							<label htmlFor="mentions" className="mr-4">
								Mentions
							</label>

							<input
								className="checkinput"
								css={customCheckbox}
								id="form"
								type="checkbox"
								ref={register}
								name="pushForm"
							/>
							<label htmlFor="form" className="mr-4">
								Form Submissions
							</label>
						</Form.Group>

						<Form.Group>
							<h4 className="font-weight-bold text-dark">Email notifications:</h4>

							<input
								className="checkinput"
								css={customCheckbox}
								id="emailmessage"
								type="checkbox"
								ref={register}
								name="emailMessage"
							/>
							<label htmlFor="emailmessage" className="mr-4">
								Messages
							</label>

							<input
								className="checkinput"
								css={customCheckbox}
								id="emailform"
								type="checkbox"
								ref={register}
								name="emailForm"
							/>
							<label htmlFor="emailform" className="mr-4">
								Form Submissions
							</label>
						</Form.Group>
						<Form.Group>
							<h4 className="font-weight-bold text-dark">SMS notifications:</h4>

							<input
								className="checkinput"
								css={customCheckbox}
								id="smsMessage"
								type="checkbox"
								ref={register}
								name="smsMessage"
							/>
							<label htmlFor="smsMessage" className="mr-4">
								Messages
							</label>

							<input
								className="checkinput"
								css={customCheckbox}
								id="smsForm"
								type="checkbox"
								ref={register}
								name="smsForm"
							/>
							<label htmlFor="smsForm" className="mr-4">
								Form Submissions
							</label>
						</Form.Group>
					</div>
					<div
						className="mt-4 pt-4 d-flex justify-content-between"
						css={css`
							border-top: 2px solid #e9e9e9;
						`}
					>
						<Button variant="secondary" onClick={handleClose}>
							Close
						</Button>
						<Button variant="primary" type="submit">
							Update Notification
						</Button>
					</div>
				</form>
			</div>
		</div>
	);
};

const ChannelNotificationsSetting = ({ room, updateChannelNotification }) => {
	const [show, setShow] = useState(false);
	const handleClose = () => {
		setShow(false);
	};
	const handleShow = () => {
		setShow(true);
	};
	return (
		<Fragment>
			<div css={actions} onClick={handleShow}>
				<IoMdNotifications />
				<span>Notification</span>
			</div>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title className="modal-headers">Channel Notification Settings</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<NotificationSettings
						room={room}
						updateChannelNotification={updateChannelNotification}
						handleClose={handleClose}
					/>
					{/* <hr width="80%" /> */}
				</Modal.Body>
			</Modal>
		</Fragment>
	);
};

const MapStateToProps = (state) => ({
	user: state.user.user,
});

export default connect(MapStateToProps, { updateChannelNotification })(ChannelNotificationsSetting);
