import { css } from 'twin.macro';
import { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import FileMessage from '../Common/FileMessage';
import { getChatRoomMedias } from '../../../_redux/actions/ActionRoomForms';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import { CSSTransition } from 'react-transition-group';
import InfiniteScroll from 'react-infinite-scroller';

import { VscLoading } from 'react-icons/vsc';
import { FaExpandArrowsAlt } from 'react-icons/fa';
import { Modal } from 'react-bootstrap';
import { SRLWrapper } from 'simple-react-lightbox';

const Medias = ({ currRoom, userId, chatRoomMedias, getChatRoomMedias }) => {
	const [showMedias, setShowMedias] = useState(false);
	const [showMediaPopUp, setShowMediaPopUp] = useState(false);
	const [loading, setLoading] = useState(false);
	const [roomMedias, setRoomMedias] = useState();

	const handleClose = () => {
		setShowMediaPopUp(false);
	};
	let messagerId;
	if (currRoom) {
		messagerId = currRoom.id || currRoom._id;
	} else {
		messagerId = userId;
	}

	useEffect(() => {
		setRoomMedias(chatRoomMedias[messagerId]?.medias);
	}, [chatRoomMedias, messagerId]);

	const loadFunc = (page) => {
		if (currRoom) {
			getChatRoomMedias(currRoom, setLoading, page);
		} else {
			getChatRoomMedias(userId, setLoading, page);
		}
	};
	const handleShowMedias = () => {
		if (!showMedias) {
			loadFunc(1);
		}
		setShowMedias((prev) => !prev);
	};

	const MediaContent =
		chatRoomMedias && roomMedias && roomMedias.length > 0 ? (
			<SRLWrapper>
				<div className="mediaBox option-members-content">
					<InfiniteScroll
						pageStart={1}
						loadMore={loadFunc}
						hasMore={!loading && roomMedias.length < chatRoomMedias[messagerId]?.totalMedias}
						useWindow={false}
						loader={
							<div
								className="loader"
								key={222}
								css={css`
									justify-content: center;
									align-items: center;
								`}
							>
								<img
									src="/images/ball-triangle.svg"
									css={css`
										width: 4rem;
										height: 4rem;
									`}
									alt="loading"
								/>
							</div>
						}
						isReverse={false}
						threshold={5}
					>
						{roomMedias.map((file, idx) => {
							if (file.file) {
								return (
									<div className="d-flex h5" key={file.createdAt + idx}>
										<FileMessage file={file.file} teamDetails={true} />
									</div>
								);
							} else if (file.images) {
								return file.images.map((image) => {
									return (
										<div className="d-flex h5" key={file.createdAt + idx}>
											<FileMessage file={image} teamDetails={true} />
										</div>
									);
								});
							}
							return null;
						})}
					</InfiniteScroll>
				</div>
			</SRLWrapper>
		) : (
			<div className="option-content">
				{loading ? <VscLoading className="spin" /> : 'No Shared Photos and Videos'}
			</div>
		);

	return (
		<Fragment>
			<div
				className="options"
				css={css`
					background-color: ${showMedias ? `#E0F0EF` : `#F2F8F7`};
				`}
				onClick={() => handleShowMedias()}
			>
				<span className=" font-weight-bold m-0">Medias</span>
				<div className=" ml-auto unselect d-flex align-item-center">
					<CSSTransition in={showMedias} timeout={500} classNames="arrow">
						{!showMedias ? <TiArrowSortedUp /> : <TiArrowSortedDown />}
					</CSSTransition>
				</div>
				{chatRoomMedias && roomMedias && roomMedias.length > 0 ? (
					<div className="ml-1">
						<div
							css={css`
								transition: transform 180ms;
								:hover {
									transform: scale(1.1);
								}
							`}
							onClick={() => setShowMediaPopUp(true)}
						>
							<FaExpandArrowsAlt />
						</div>
					</div>
				) : null}
			</div>
			{showMedias && <Fragment>{MediaContent}</Fragment>}
			<hr />
			{showMediaPopUp && (
				<Modal size="xl" show={showMediaPopUp} onHide={handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>
							<h2>Medias</h2>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body
						css={css`
							.mediaBox {
								height: 820px;
								div {
									height: 400px;
									video {
										height: 100%;
										width: 100%;
									}
								}
							}
						`}
					>
						{MediaContent}
					</Modal.Body>
					<Modal.Footer></Modal.Footer>
				</Modal>
			)}
		</Fragment>
	);
};

const mapStateToProps = (state) => ({
	role: state.user.user.role,
	chatRoomMedias: state.chatroomData.chatRoomMedias,
});

export default connect(mapStateToProps, { getChatRoomMedias })(Medias);
