import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useState, useEffect, useCallback } from 'react';
import { CalenderStyles } from './Styles/CalenderStyles';
import { getTasksOfTheDay } from '../../../_helpers';

const weekday = require('dayjs/plugin/weekday');
const weekOfYear = require('dayjs/plugin/weekOfYear');

dayjs.extend(weekday);
dayjs.extend(weekOfYear);

const WEEKDAYS = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
const INITIAL_YEAR = dayjs().format('YYYY');
const INITIAL_MONTH = dayjs().format('M');
const TODAY = dayjs().format('YYYY-MM-DD');

function getNumberOfDaysInMonth(year, month) {
	return dayjs(`${year}-${month}-01`).daysInMonth();
}

function getWeekday(date) {
	return dayjs(date).weekday();
}
function Calender({ tasks, dateSelected, handleDateSelect, handleMonthChange }) {
	const [calendar, setCalendar] = useState({
		year: dayjs().format('YYYY'),
		month: dayjs().format('M'),
		days: [],
		monthYear: '',
		buckets: [0, 0, 0, 0]
	});

	const [selectedDate, setSelectedDate] = useState(dateSelected);

	useEffect(() => setSelectedDate(dateSelected), [dateSelected]);

	const createDaysForCurrentMonth = useCallback(
		(year, month) => {
			return [...Array(getNumberOfDaysInMonth(year, month))].map((day, index) => {
				let calendarDate = dayjs(`${year}-${month}-${index + 1}`).format('YYYY-MM-DD');

				if (tasks?.length !== 0) {
					let dayEvents = getTasksOfTheDay(tasks, calendarDate);

					return {
						date: calendarDate,
						dayOfMonth: index + 1,
						isCurrentMonth: true,
						events: dayEvents,
						noOfEvents: dayEvents.length,
					};
				} else {
					return {
						date: calendarDate,
						dayOfMonth: index + 1,
						isCurrentMonth: true,
					};
				}
			});
		},
		[tasks]
	);

	const createDaysForPreviousMonth = useCallback((year, month, currentMonthDays) => {
		const firstDayOfTheMonthWeekday = getWeekday(currentMonthDays.date);

		const previousMonth = dayjs(`${year}-${month}-01`).subtract(1, 'month');

		const visibleNumberOfDaysFromPreviousMonth = firstDayOfTheMonthWeekday;
		const previousMonthLastMondayDayOfMonth = dayjs(currentMonthDays.date)
			.subtract(visibleNumberOfDaysFromPreviousMonth, 'day')
			.date();

		return [...Array(visibleNumberOfDaysFromPreviousMonth)].map((day, index) => {
			return {
				date: dayjs(
					`${previousMonth.year()}-${previousMonth.month() + 1}-${previousMonthLastMondayDayOfMonth + index}`
				).format('YYYY-MM-DD'),
				dayOfMonth: previousMonthLastMondayDayOfMonth + index,
				isSelected: false,
				isCurrentMonth: false,
			};
		});
	}, []);

	const createDaysForNextMonth = useCallback((year, month, currentMonthDays) => {
		const lastDayOfTheMonthWeekday = getWeekday(`${year}-${month}-${currentMonthDays.length}`);
		const visibleNumberOfDaysFromNextMonth = lastDayOfTheMonthWeekday ? 6 - lastDayOfTheMonthWeekday : 6;

		return [...Array(visibleNumberOfDaysFromNextMonth)].map((day, index) => {
			return {
				date: dayjs(`${year}-${Number(month) + 1}-${index + 1}`).format('YYYY-MM-DD'),
				dayOfMonth: index + 1,
				isCurrentMonth: false,
			};
		});
	}, []);
	useEffect(() => {
		let currentMonthDays = createDaysForCurrentMonth(calendar.year, calendar.month);
		let previousMonthDays = createDaysForPreviousMonth(calendar.year, calendar.month, currentMonthDays[0]);
		let nextMonthDays = createDaysForNextMonth(calendar.year, calendar.month, currentMonthDays);

		let currentMonth = new Date(currentMonthDays[0].date).getMonth();
		let days = [...previousMonthDays, ...currentMonthDays, ...nextMonthDays];
		const allNumbers = days.map(day => day.noOfEvents).filter(n => n);
		const minEvents = Math.min(...allNumbers);
		const maxEvents = Math.max(...allNumbers);
		const maxDiff = maxEvents - minEvents;
		days.forEach(day => {
			day.diff = maxDiff ? (day.noOfEvents - minEvents) / (maxDiff) : 0;
		})
		let monthYear = dayjs(new Date(calendar.year, calendar.month - 1)).format('MMMM YYYY');
		setCalendar((prevState) => ({
			...prevState,
			currentMonth: currentMonth,
			days: days,
			monthYear: monthYear,
			buckets: [0, 1 / 3, 2 / 3, 1].map(fraction => Math.round(minEvents + fraction * maxDiff))
		}));
	}, [
		calendar.month,
		calendar.year,
		createDaysForCurrentMonth,
		createDaysForPreviousMonth,
		createDaysForNextMonth,
		tasks,
	]);

	const goToNextMonth = () => {
		let newYear = calendar.year;
		if (calendar.month === '12') {
			newYear = dayjs(calendar.year).add(1, 'year').format('YYYY');
		}
		let newMonth = dayjs(calendar.month).add(1, 'month').format('MM');
		handleMonthChange(`${newYear}-${newMonth}`);
		setCalendar({
			...calendar,
			month: newMonth,
			year: newYear,
		});
	};
	const goToPrevMonth = () => {
		let newYear = calendar.year;
		if (calendar.month === '1') {
			newYear = dayjs(calendar.year).subtract(1, 'year').format('YYYY');
		}
		let newMonth = dayjs(calendar.month).subtract(1, 'month').format('MM');
		handleMonthChange(`${newYear}-${newMonth}`);
		setCalendar({
			...calendar,
			month: newMonth,
			year: newYear,
		});
	};
	const goToCurrentMonth = () => {
		setCalendar({
			...calendar,
			month: INITIAL_MONTH,
			year: INITIAL_YEAR,
		});
		handleMonthChange(`${INITIAL_YEAR}-${INITIAL_MONTH}`, true);
	};

	const handleDaySelect = (date) => {
		setSelectedDate(date);
		handleDateSelect(date);
	};

	return (
		<CalenderStyles>
			<div className="month">
				<ul>
					<li className="cal-nav-btn next" onClick={goToNextMonth}>
						&#10095;
					</li>
					<li className="cal-nav-btn today" onClick={goToCurrentMonth}>
						Today
					</li>
					<li className="cal-nav-btn prev" onClick={goToPrevMonth}>
						&#10094;
					</li>
					<li id="selected-month">{calendar.monthYear}</li>
				</ul>
			</div>

			<div className="calender-container">
				{/* calendar grid header */}
				<ul className="weekdays">
					{WEEKDAYS.map((weekday, index) => (
						<li key={index}>{weekday}</li>
					))}
				</ul>
				{/* calendar grid */}
				<ul className="days">
					{calendar.days.map((day) => {
						let noOfEventsClass = '';
						if (day.noOfEvents > 0) {
							if (day.diff > 2 / 3) {
								noOfEventsClass = 'high-events';
							} else if (day.diff > 1 / 3) {
								noOfEventsClass = 'medium-events';
							} else {
								noOfEventsClass = 'low-events';
							}
						}
						return (
							<li key={day.date}>
								<div
									className={`bgDate 
										${!day.isCurrentMonth ? 'not-in-this-month' : 'this-month-day'} 
										${day.date === TODAY && 'date-today'} 
										${day.date === selectedDate && 'selected-day'} 
										${noOfEventsClass}
										`}
									onClick={() => {
										handleDaySelect(day.date);
									}}
								>
									<span>{day.dayOfMonth}</span>
								</div>
							</li>
						);
					})}
				</ul>
			</div>
			<div className="eventsLabel">
				<div>
					<span>No. of Events: </span>
				</div>
				<div>
					<span className="eventLabel low_events"></span>
					<span>{calendar.buckets[0] || '-'} - {calendar.buckets[1] || '-'} </span>
				</div>
				<div>
					<span className="eventLabel medium_events"></span>
					<span>{calendar.buckets[1] || "-"} - {calendar.buckets[2] || "-"}</span>
				</div>
				<div>
					<span className="eventLabel high_events"></span>
					<span>{calendar.buckets[2] || '-'}-{calendar.buckets[3] || "-"}</span>
				</div>
			</div>
		</CalenderStyles>
	);
}

Calender.protoType = {
	tasks: PropTypes.array.isRequired,
	handleDateSelect: PropTypes.func.isRequired,
	handleMonthChange: PropTypes.func.isRequired,
};

export default Calender;
