import { css } from 'twin.macro';
import { useState, useEffect, Fragment } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { getTeamIdFromURL, fetchWrapper, toastWrapper } from '../../../../../_helpers';
import TeamSelectFilter from '../../../../Admin/TeamSelectFilter';
import ChannelSelectFilter from '../../../../Admin/ChannelSelectFilter';
import FormSelect from './FormSelect';
import { BsEye } from 'react-icons/bs';
import { BsPencil, BsFillPlusSquareFill } from 'react-icons/bs';
import { ToolTip } from '../../../../../_elements/ToolTip';
import loaderStyles from '../../../../../_elements/loader';
const modalStyles = css`
    .modal-content {
        padding: 2rem 1rem;
    }
`;

function VisitTemplateModal({ mode, channelId, data = null, setFetchToggle = () => {}, handleUpdate = () => {} }) {
    const [show, setShow] = useState(false);
    const [error, setError] = useState({});
    const [name, setName] = useState('');
    const teamId = getTeamIdFromURL();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedTeamOptions, setSelectedTeamOptions] = useState(null);
    const [selectedRoomOptions, setSelectedRoomOptions] = useState(null);
    const [sosForm, setSosForm] = useState(null);
    const [eosForm, setEosForm] = useState(null);
    const [otherForms, setOtherForms] = useState([]);
    const [defaultOptions, setDefaultOptions] = useState([]);
    const [isLoadingDefault, setIsLoadingDefault] = useState(false);
    const disabledForm = mode === 'View';

    useEffect(() => {
        const fetchVisitTemplateData = async () => {
            try {
                setIsLoadingDefault(true);
                const response = await fetchWrapper.get(`visit-templates/${data?._id}`);
                setName(response?.name);
                const formsData = response?.formsData || [];
                setSosForm(() => {
                    const tempSos = formsData?.find((form) => form.type === 'sos');
                    return { value: tempSos?._id, label: tempSos?.name };
                });
                setEosForm(() => {
                    const tempEos = formsData?.find((form) => form.type === 'eos');
                    return { value: tempEos?._id, label: tempEos?.name };
                });

                setOtherForms(() => {
                    const tempOther = formsData?.filter((form) => form.type !== 'sos' && form.type !== 'eos');
                    return tempOther.map((form) => {
                        return { ...form, value: form?._id, label: form?.name };
                    });
                });

                setSelectedRoomOptions(() => {
                    const chatroomData = response?.chatroomData;
                    return {
                        label: chatroomData?.title,
                        value: chatroomData?._id || chatroomData?.id,
                    };
                });
            } catch (err) {
                console.log('VisitTemplateModal::', err);
            } finally {
                setIsLoadingDefault(false);
            }
        };
        if (data?._id && show) {
            fetchVisitTemplateData();
        }
    }, [show]);

    useEffect(() => {
        const fetchDefaultForms = async () => {
            try {
                setIsLoadingDefault(true);
                const response = await fetchWrapper.get(
                    `forms/?limit=100&chatroom=${selectedRoomOptions?.value || channelId}`
                );
                const tempArray = response?.results
                    .sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1))
                    .map((element) => ({
                        ...element,
                        label: element?.name,
                        value: element?._id,
                    }));
                setDefaultOptions(tempArray || []);
            } catch (err) {
                console.log('err', err);
            } finally {
                setIsLoadingDefault(false);
            }
        };
        if ((selectedRoomOptions?.value || channelId) && show) {
            fetchDefaultForms();
            if (selectedRoomOptions?.value !== data?.chatroom) {
                setSosForm(null);
                setEosForm(null);
                setOtherForms([]);
            }
        }
    }, [show, selectedRoomOptions?.value]);

    const handleShow = () => setShow(true);
    const handleClose = () => {
        setName('');
        setSelectedRoomOptions(null);
        setSosForm(null);
        setEosForm(null);
        setOtherForms([]);
        setError({});
        setIsLoadingDefault(false);
        setIsSubmitting(false);
        setShow(false);
    };

    const handleSubmit = async () => {
        try {
            let hasError = false;
            if (!name) {
                return setError({ name: 'Name is required' });
            }
            if (!selectedRoomOptions?.value) {
                if (!channelId) {
                    return setError({ room: 'Channel is required' });
                }
            }
            if (!sosForm?.value) {
                return setError({ sos: 'SOS Form is required' });
            }
            if (!eosForm?.value) {
                return setError({ eos: 'EOS Form is required' });
            }

            if (hasError) {
                return;
            } else {
                setError({});
                if (mode === 'Edit') {
                    await editTemplate();
                } else if (mode === 'Create') {
                    await createNewTemplate();
                }
            }
        } catch (err) {
            toastWrapper.error(err?.message);
        }
    };

    const createNewTemplate = async () => {
        try {
            setIsSubmitting(true);
            const payload = {
                name,
                team: teamId,
                chatroom: selectedRoomOptions?.value || channelId,
                sosForm: sosForm?.value,
                eosForm: eosForm?.value,
                otherForms: otherForms?.map((form) => form?.value || form?._id) || [],
            };
            await fetchWrapper.post('visit-templates', payload);
            toastWrapper.success('Visit Template Created Successfully');
            await setFetchToggle((prev) => !prev);
            if (setFetchToggle) {
                await setFetchToggle((prev) => !prev);
            }
            handleClose();
        } catch (err) {
            toastWrapper.error(err?.message);
        } finally {
            setIsSubmitting(false);
        }
    };

    const editTemplate = async () => {
        try {
            setIsSubmitting(true);
            const payload = {
                name,
                team: teamId,
                chatroom: selectedRoomOptions?.value || channelId,
                sosForm: sosForm?.value,
                eosForm: eosForm?.value,
                otherForms: otherForms?.map((form) => form?.value || form?._id) || [],
            };
            const response = await fetchWrapper.put(`visit-templates/${data?._id}`, payload);
            handleUpdate(response);
            toastWrapper.success('Visit Template Edited Successfully');
            handleClose();
        } catch (err) {
            toastWrapper.error(err?.message);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Fragment>
            {mode === 'Create' ? (
                <Button
                    variant="primary"
                    onClick={handleShow}
                    className="btn px-4 py-3  "
                    css={css`
                        width: 200px;
                    `}
                >
                    <BsFillPlusSquareFill className="mr-2" /> <span>Visit Template</span>
                </Button>
            ) : null}
            {mode === 'Edit' ? (
                <ToolTip text={'View'} cssStyles={'top:-70% !important; min-width: fit-content !important;'}>
                    <BsPencil onClick={() => handleShow()} className="icon view" />
                </ToolTip>
            ) : null}
            {mode === 'View' ? (
                <ToolTip text={'View'} cssStyles={'top:-70% !important; min-width: fit-content !important;'}>
                    <BsEye onClick={handleShow} className="icon view" />
                </ToolTip>
            ) : null}
            <Modal show={show} onHide={handleClose} backdrop="static" css={modalStyles} size="lg">
                <Modal.Header
                    css={css`
                        text-align: center;
                    `}
                    closeButton
                >
                    <h3
                        css={css`
                            font-weight: 600;
                            width: 100%;
                        `}
                        className="text-capitalize"
                    >
                        {mode} Visit Template
                    </h3>
                </Modal.Header>
                <Modal.Body
                    css={css`
                        min-height: 300px;
                    `}
                >
                    {isSubmitting || isLoadingDefault ? (
                        <div css={loaderStyles}>
                            <img src="/images/loading.gif" alt="loader" />
                        </div>
                    ) : (
                        <Fragment>
                            <Form className=" mb-4 mt-4">
                                <Form.Row>
                                    <Form.Label>Template Name:</Form.Label>
                                    <span className="text-danger">*</span>
                                    <Form.Control
                                        type="text"
                                        value={name || ''}
                                        disabled={disabledForm}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    <Form.Text className="text-danger">{error.name}</Form.Text>
                                </Form.Row>
                                {teamId ? null : (
                                    <Form.Row>
                                        <div className="w-100 mt-4">
                                            <Form.Label>Team</Form.Label>
                                            <TeamSelectFilter
                                                disabled={!!teamId | disabledForm}
                                                selectedTeamOption={selectedTeamOptions}
                                                setSelectedTeamOption={setSelectedTeamOptions}
                                            />
                                            <Form.Text className="text-danger">{error.team}</Form.Text>
                                        </div>
                                    </Form.Row>
                                )}

                                {
                                    <Form.Row>
                                        <div className="w-100 mt-4">
                                            <Form.Label>Chatroom:</Form.Label>
                                            <span className="text-danger">*</span>
                                            <ChannelSelectFilter
                                                teamIds={selectedTeamOptions?.map((option) => option?.value)}
                                                disabled={!!channelId || disabledForm}
                                                assignedChannel={channelId}
                                                selectedOption={selectedRoomOptions}
                                                onRoomSelect={setSelectedRoomOptions}
                                            />
                                            <Form.Text className="text-danger">{error.room}</Form.Text>
                                        </div>
                                    </Form.Row>
                                }

                                <Form.Row>
                                    <div className="w-100 mt-4">
                                        <Form.Label>SOS Form:</Form.Label>
                                        <span className="text-danger">*</span>
                                        <FormSelect
                                            types={['sos']}
                                            defaultOptions={defaultOptions}
                                            disabled={isLoadingDefault || (!channelId && !selectedRoomOptions?.value)}
                                            isLoading={isLoadingDefault}
                                            forms={sosForm}
                                            setForms={setSosForm}
                                            channelId={selectedRoomOptions?.value || channelId}
                                        />
                                        <Form.Text className="text-danger">{error.sos}</Form.Text>
                                    </div>
                                </Form.Row>
                                <Form.Row>
                                    <div className="w-100 mt-4">
                                        <Form.Label>EOS Form:</Form.Label>
                                        <span className="text-danger">*</span>
                                        <FormSelect
                                            types={['eos']}
                                            defaultOptions={defaultOptions}
                                            disabled={isLoadingDefault || (!channelId && !selectedRoomOptions?.value)}
                                            isLoading={isLoadingDefault}
                                            forms={eosForm}
                                            setForms={setEosForm}
                                            channelId={selectedRoomOptions?.value || channelId}
                                        />
                                        <Form.Text className="text-danger">{error.eos}</Form.Text>
                                    </div>
                                </Form.Row>
                                <Form.Row>
                                    <div className="w-100 mt-4">
                                        <Form.Label>Other Forms:</Form.Label>
                                        <FormSelect
                                            isMulti={true}
                                            types={['normal', 'multiple']}
                                            disabled={isLoadingDefault || (!channelId && !selectedRoomOptions?.value)}
                                            defaultOptions={defaultOptions}
                                            isLoading={isLoadingDefault}
                                            forms={otherForms}
                                            setForms={setOtherForms}
                                            channelId={selectedRoomOptions?.value || channelId}
                                        />
                                    </div>
                                </Form.Row>
                            </Form>
                        </Fragment>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            if (!isSubmitting) handleClose();
                        }}
                        disabled={isSubmitting}
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        css={css`
                            padding: 0.8rem;
                        `}
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                    >
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}

export default VisitTemplateModal;
