import { useState, useEffect, Fragment, useCallback, useContext } from 'react';
import { css } from 'twin.macro';
import PropTypes from 'prop-types';
//components
import Messages from './Messages';
import ChatInput from '../../../Features/Common/ChatInput';
import EventChannelHeading from './EventChannelHeading';
//redux
import { connect } from 'react-redux';
import { WebSocketContext } from '../../../../_redux/actions/ActionSocket';
import { clearSearchMessages, getSubChannelMembers } from '../../../../_redux';
//layout elements
import ClearSearch from '../../../Features/Common/ClearSearch';
import ChatSkeleton from '../../../Features/Layouts/ChatSkeleton';
import container from '../../../../_elements/container';
import outerContainer from '../../../../_elements/outerContainer';
import { getUniqueObjects } from '../../../../_helpers';

const EventChannelChat = ({
    MessagesFromRedux,
    subChannelId,
    subChannel,
    clearSearchMessages,
    position,
    userRole,
    room,
    roomData,
    getSubChannelMembers,
}) => {
    const [oldmessages, setoldmessages] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [reply, setReply] = useState('');

    const updateSearchText = (txt) => {
        setSearchText(txt);
    };
    const { isLoading, isSearch, searchFilters, searchMessages, messages } = MessagesFromRedux;

    const setMessages = useCallback(() => {
        if (messages[subChannelId]) {
            let tempoldmessages = messages[subChannelId].message
                .map((history, i) => ({
                    key: i,
                    isEdited: history.isEdited,
                    visibleTo: history.visibleTo,
                    id: history.id,
                    file: history.file,
                    images: history.images,
                    files: history.files,
                    data: history.data,
                    type: history.type,
                    user: history.user,
                    seenBy: history.seenBy,
                    subChannelId: history.subChannel,
                    dateAndTime: history.createdAt,
                    reaction: history.reaction || [],
                    yourReaction: history.yourReaction,
                }))
                .reverse();
            setoldmessages(getUniqueObjects(tempoldmessages));
        }
    }, [messages, subChannelId]);

    let totalMessages, lastSeenMessage;
    if (messages[subChannelId]) {
        totalMessages = messages[subChannelId]?.totalMessages;
        lastSeenMessage = messages[subChannelId]?.lastSeen;
    }
    const ws = useContext(WebSocketContext);

    useEffect(() => {
        let isActive = true;
        let tempoldmessages;
        if (!isSearch && isActive) {
            setMessages();
        } else {
            if (searchMessages) {
                tempoldmessages =
                    searchMessages &&
                    searchMessages
                        .map((history) => ({
                            isEdited: history.isEdited,
                            visibleTo: history.visibleTo,
                            id: history.id,
                            file: history.file,
                            images: history.images,
                            files: history.files,
                            data: history.data,
                            type: history.type,
                            user: history.user,
                            seenBy: history.seenBy,
                            subChannelId: history.subChannel,
                            dateAndTime: history.createdAt,
                            reaction: history.reaction || [],
                            yourReaction: history.yourReaction,
                        }))
                        .reverse();
                setoldmessages(getUniqueObjects(tempoldmessages));
            }
        }
        return () => (isActive = false);
    }, [searchMessages, isSearch, setMessages]);

    const sendMessage = async (event, message, permission = null) => {
        event.preventDefault();
        if (message) {
            ws.sendMessage(message, reply, position, { subChannelId: subChannelId, permissionArr: permission });
        }
    };
    useEffect(() => {
        getSubChannelMembers(subChannelId);
    }, [getSubChannelMembers, subChannelId]);
    return (
        <div css={outerContainer}>
            <div css={container}>
                <EventChannelHeading
                    subChannel={subChannel}
                    updateSearchText={updateSearchText}
                    searchText={searchText}
                    userRole={userRole}
                />
                <ClearSearch updateSearchText={updateSearchText} />
                {!isLoading ? (
                    <Fragment>
                        {oldmessages ? (
                            <Messages
                                setReply={setReply}
                                messages={oldmessages}
                                subChannelId={subChannelId}
                                isSearch={isSearch}
                                searchFilters={searchFilters}
                                type="team"
                                userRole={userRole}
                                subChannelType={subChannel?.type}
                                totalMessages={totalMessages}
                                lastSeen={lastSeenMessage}
                                roomData={roomData}
                            />
                        ) : (
                            <div className="h-100 d-flex text-center flex-column justify-content-center align-items-center">
                                <h2>
                                    <b>No Posts Yet!</b>
                                </h2>
                                <div
                                    css={css`
                                        color: gray;
                                        font-style: italic;
                                        width: 30%;
                                        line-height: 1.2;
                                        margin-top: 0.5rem;
                                    `}
                                >
                                    Post your first message.
                                </div>{' '}
                            </div>
                        )}
                        <ChatInput
                            id={subChannelId}
                            subChannelType={subChannel?.type}
                            reply={reply}
                            setReply={setReply}
                            type="subChannel"
                            key={subChannelId}
                            sendMessage={sendMessage}
                            userRole={userRole}
                            room={roomData?.room}
                            subChannelId={subChannelId}
                        />
                    </Fragment>
                ) : (
                    <div className="mx-4">
                        <ChatSkeleton type="direct" />
                    </div>
                )}
            </div>
        </div>
    );
};
const mapStateToProps = (state) => ({
    MessagesFromRedux: state.messages,
    position: state.user.position,
});
EventChannelChat.propTypes = {
    MessagesFromRedux: PropTypes.object.isRequired,
    subChannel: PropTypes.object.isRequired,
    position: PropTypes.object,
};
export default connect(mapStateToProps, {
    clearSearchMessages,
    getSubChannelMembers,
})(EventChannelChat);
