import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

function MessageTime({ dateAndTime }) {
	return (
		<Fragment>
			{dayjs(dateAndTime).format("MM/DD/YYYY, hh:mm A")}
		</Fragment>
	);
}

MessageTime.propTypes = {
	dateAndTime: PropTypes.string.isRequired,
};

export default MessageTime;
