import React, { Fragment, useState } from 'react';
import { css } from 'twin.macro';
import { Form } from 'react-bootstrap';

function getFormLabel(name) {
    const [initial, ...other] = (name || 'Unnamed Field')
        .replace(/([a-z0-0]_[a-z0-9])/g, (v) => `${v[0]} ${v[2].toUpperCase()}`)
        .replaceAll('_', ' ')
        .split('');
    return initial.toUpperCase() + other.join('').replaceAll(/([a-z])([A-Z])/g, '$1 $2');
}
function RequiredStar() {
    return (
        <span
            css={css`
                color: red;
                margin-left: 3px;
            `}
        >
            *
        </span>
    );
}
export const RenderFormFields = ({ schema, parentKey = '', register }) => {
    const [fieldValues, setFieldValues] = useState({});
    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFieldValues({ ...fieldValues, [name]: type === 'checkbox' ? checked : value });
    };

    return Object?.keys(schema?.properties)?.map((key) => {
        if (key === 'required_template_fields') {
            // Docs:: With the exception of required_template_fields (more on this later), all keys of a properties field directly translate to fields that may be included in the body of your POST request to the Create a Deduction Template endpoint.
            return null;
        }

        const property = schema?.properties[key];
        if (!property) {
            return null;
        }
        const fullKey = parentKey ? `${parentKey}.${key}` : key;
        const required = true;
        const readOnly = property?.const || key === 'override_type';
        const dataType = property?.type;
        // Docs::  employer_sponsored_health_coverage needs both the employer and employee contributions. It's expected that the employee contributions will be set to 0 through the API
        const defaultContributionValue = property?.default || (key === 'value' ? 0 : null);

        if (property?.type === 'object') {
            return (
                <div
                    css={css`
                        font-size: 1.8rem;
                        border: ${key ? '2px' : '0px'} solid #50aeb0;
                        border-radius: 1px;
                        padding: 1rem;
                        margin: 3rem 0;
                    `}
                    id={key}
                    key={fullKey}
                >
                    <span
                        css={css`
                            font-size: 1.8rem;
                            margin: 1rem 0;
                            font-weight: 600;
                        `}
                    >
                        {key ? getFormLabel(key) : null}
                        {required ? <RequiredStar /> : null}
                    </span>
                    {key ? (
                        <hr
                            css={css`
                                border: none;
                                height: 1px;
                                background-color: #ced4da;
                            `}
                        />
                    ) : null}
                    <RenderFormFields schema={property} parentKey={fullKey} register={register} />
                </div>
            );
        }
        if (dataType === 'boolean') {
            return (
                <Form.Group
                    key={fullKey}
                    css={css`
                        display: ${readOnly ? 'none' : 'flex; gap: 6px;'};
                    `}
                >
                    <Form.Check
                        ref={register}
                        name={fullKey}
                        readOnly={schema?.readOnly}
                        checked={fieldValues?.hasOwnProperty(fullKey) && fieldValues[fullKey]}
                        onChange={handleChange}
                    />
                    <Form.Label>{getFormLabel(key)}</Form.Label>
                </Form.Group>
            );
        }
        if (property?.enum) {
            return (
                <Form.Group
                    key={fullKey}
                    css={css`
                        display: ${readOnly ? 'none' : 'block'};
                    `}
                >
                    <Form.Label>
                        {getFormLabel(key)}
                        {required ? <RequiredStar /> : null}
                    </Form.Label>
                    <Form.Control
                        as="select"
                        ref={register}
                        name={fullKey}
                        required={required}
                        readOnly={schema?.readOnly}
                    >
                        {key === 'override_type' ? (
                            <option value="overridable">Overridable</option>
                        ) : (
                            <Fragment>
                                {(property.enum || []).map((_type, index) => (
                                    <option key={index} value={_type}>
                                        {getFormLabel(_type)}
                                    </option>
                                ))}
                            </Fragment>
                        )}
                    </Form.Control>
                </Form.Group>
            );
        }

        return (
            <Form.Group
                key={fullKey}
                css={css`
                    display: ${readOnly ? 'none' : 'block'};
                `}
            >
                <Form.Label>
                    {getFormLabel(key)}
                    {required ? <RequiredStar /> : null}
                </Form.Label>
                <Form.Control
                    required={required}
                    onChange={handleChange}
                    value={
                        readOnly
                            ? readOnly
                            : fieldValues?.hasOwnProperty(fullKey)
                              ? fieldValues[fullKey]
                              : defaultContributionValue
                    }
                    type={dataType}
                    name={fullKey}
                    ref={register}
                    readOnly={readOnly || schema?.readOnly}
                    onWheel={(event) => event.currentTarget.blur()}
                />
            </Form.Group>
        );
    });
};
