import styled from "@emotion/styled";

export const CopyFormStyled = styled('div')`
	padding: 1rem 2rem;
	.form-element {
		overflow-wrap: break-word;
		transition: all 1s ease-in;
	}
	.form-element:hover {
		border: 1px solid #000;
	}
	.form-element-control {
		display: none;
	}
	.form-element:hover * .form-element-control {
		display: block;
	}
`;