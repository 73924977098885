// import styled from '@emotion/styled';
import { css } from 'twin.macro';

export const msgContainer = css`
	margin-bottom: 2rem;

	.messageContainer {
		display: flex;
		justify-content: flex-end;
		padding: 0 1rem;
		.msg {
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;

			.dropper {
				visibility: hidden;
				opacity: 0;
				display: flex;
				justify-content: center;
				align-items: flex-end;
				div {
					font-size: 1.3rem;
				}
				&.show {
					visibility: visible !important;
					opacity: 1;
				}
			}

			:hover .dropper {
				visibility: visible;
				opacity: 1;
			}

			.dropdown {
				background: transparent;
				border: none;

				.dropdown-menu {
					border-radius: 10px;
					border: none;
					box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
					color: #dddddd;
				}

				&.btn-primary:not(:disabled):not(.disabled):active {
					background: transparent;
					color: black;
					border: none;
				}
				&.btn-primary.dropdown-toggle:focus {
					box-shadow: none;
				}
				:focus,
				:hover,
				:active {
					background: transparent;
					color: black;
					border: none;
					box-shadow: none;
				}
				&.btn-primary.dropdown-toggle {
					background: transparent;
					color: black;
					border: none;
				}
				::after {
					display: none;
				}
			}
		}
		.lowerDetails {
			font-size: 12px;
			margin-bottom: 5px;
			margin-top: -3px;
		}
	}
	.chatMessages {
		padding: 0 1rem;
	}
	.msgbtn {
		cursor: pointer;
		transition: color 150ms;
	}
	.msgbtn:hover {
		color: #50aeb0;
	}

	.messageText {
		position: relative;
		border-radius: 10px;
		padding: 0.8rem;
		font-size: 1.4rem;
		font-weight: 400;
		width: 100%;
		margin: 0;
		letter-spacing: 0;
		word-wrap: break-word;
	}

	.messageText img {
		vertical-align: middle;
	}

	.sentText {
		width: 100%;
		padding: 5px 0px;
		font-size: 0.85rem;
		font-weight: 400;
		display: flex;
		justify-content: flex-end;
		color: #828282;
		letter-spacing: 0.3px;
	}

	.hidden {
		visibility: hidden;
		opacity: 0;
	}
	.re-rp:hover > .hidden {
		visibility: visible;
		opacity: 1;
	}

	.justifyStart {
		justify-content: flex-start;
	}

	.justifyEnd {
		justify-content: flex-end;
	}

	.colorWhite {
		color: white !important;
	}

	.colorDark {
		color: #5c5d5d;
	}

	.backgroundBlue {
		background: #50aeb0;
	}

	.backgroundLight {
		background: #e7e7e7;
	}
	.message-link {
		color: #50aeb0;
	}

	.sent-msg {
		padding: 5px;
		background-color: #50aeb0;
		color: white;
		z-index: 5;
		line-height: 20px;
		font-size: 16px;
		border-radius: 15px 0px 15px 15px;
		max-width: 560px;

		.edited-label {
			font-size: 1.1rem;
			display: flex;
			padding: 0 10px !important;
			font-style: italic;
			justify-content: flex-end;
		}

		.sent-reply-msg {
			font-size: 1.5rem;
			text-align: left;
			overflow-wrap: anywhere;
			background-color: #f2f8f7;
			color: #757575;
			padding: 10px;
			border-radius: 10px;
			.message-link {
				color: #50aeb0 !important;
			}
			.form_a {
				color: #50aeb0 !important;
			}
			div .mention_a {
				color: #50aeb0 !important;
			}
		}

		div a {
			color: white !important;
		}

		div div .react_tinylink_card_content_wrapper {
			color: black !important;
		}
	}

	.received-msg {
		background-color: #e7e7e7;
		padding: 5px;
		border-radius: 0px 15px 15px 15px;
		font-size: 16px;
		overflow-wrap: 'anywhere';
		color: #686868;
		word-break: break-word;
		max-width: 500px;
		.received-reply-msg {
			background-color: #fafafa;
			padding: 5px 10px;
			border-radius: 10px;
			font-size: 14px;
			font-weight: bold;
			color: #757575;
			word-break: break-word;
		}
	}
	.dropcss-sender {
		height: 30px;
		width: 30px;
		border-radius: 50%;
		background-color: #797979;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.dropcss-receiver {
		height: 30px;
		width: 30px;
		border-radius: 50%;
		background-color: #797979;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;
