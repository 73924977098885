import { css } from 'twin.macro';
import InfiniteScroll from 'react-infinite-scroller';
import { Fragment, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { connect } from 'react-redux';
import { fetchWrapper, Permission } from '../../../_helpers';

//components
import ManagerFormView from './ManagerFormView';
import SubmissionModal from './SubmissionModal';

// assets
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import { VscLoading } from 'react-icons/vsc';

const Forms = ({ team, currRoom, userId, position, role }) => {
    const [template, setTemplate] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [showForm, setShowForm] = useState(false);
    const [totalPages, setTotalPages] = useState(1000000);

    const toggleShowForms = () => {
        if (showForm) {
            setHasMore(true);
            setTemplate([]);
        }
        setShowForm((prev) => !prev);
    };

    const loadFunc = async (page) => {
        if (page <= totalPages) {
            const formTemplates = await fetchWrapper.get(
                `forms/${currRoom ? `?chatroom=${currRoom.id || currRoom._id}` : ''}&limit=10&page=${page}`
            );
            let tempTemplate = [...template, ...(formTemplates?.results || [])];
            setTemplate(tempTemplate);
            setHasMore(formTemplates.totalResults > tempTemplate.length);
            setTotalPages(formTemplates.totalPages);
        }
    };

    return (
        <Fragment>
            <div
                className="options"
                css={css`
                    background-color: ${showForm ? `#E0F0EF` : `#F2F8F7`};
                `}
                onClick={toggleShowForms}
            >
                <span className=" font-weight-bold m-0">Forms</span>
                <p className=" ml-auto unselect">
                    <CSSTransition in={showForm} timeout={500} classNames="arrow">
                        {!showForm ? <TiArrowSortedUp /> : <TiArrowSortedDown />}
                    </CSSTransition>
                </p>
            </div>
            <div>
                {showForm && (
                    <Fragment>
                        <div className="option-members-content">
                            <div
                                css={css`
                                    max-height: 400px;
                                    overflow: auto;
                                    > div {
                                        height: 100%;
                                    }
                                `}
                                key={0}
                            >
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={loadFunc}
                                    initialLoad={true}
                                    hasMore={hasMore}
                                    loader={
                                        <div
                                            className="loader"
                                            key={0}
                                            css={css`
                                                height: 100%;
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;
                                            `}
                                        >
                                            <VscLoading fontSize={34} className="spin" />
                                        </div>
                                    }
                                    useWindow={false}
                                >
                                    {hasMore || (template && template.length > 0) ? (
                                        template
                                            .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
                                            .map((tem, idx) => (
                                                <div className="d-flex" key={tem.createdAt + idx}>
                                                    <SubmissionModal
                                                        form={tem}
                                                        roomId={currRoom && (currRoom.id || currRoom._id)}
                                                        userId={userId}
                                                        currRoom={currRoom}
                                                        formType={tem.type}
                                                        position={position}
                                                        team={team}
                                                    />

                                                    <Permission
                                                        role={
                                                            userId === team.admin
                                                                ? 'owner'
                                                                : userId === team.manager
                                                                  ? 'teamManager'
                                                                  : role === 'admin' || role === 'manager'
                                                                    ? role
                                                                    : 'user'
                                                        }
                                                        perform="view:form"
                                                        yes={() => (
                                                            <Fragment>
                                                                <ManagerFormView
                                                                    roomId={currRoom && (currRoom.id || currRoom._id)}
                                                                    form={tem}
                                                                />
                                                            </Fragment>
                                                        )}
                                                        no={() => null}
                                                    />
                                                </div>
                                            ))
                                    ) : (
                                        <div className="p-4 d-flex justify-content-center align-items-center">
                                            No Forms
                                        </div>
                                    )}
                                </InfiniteScroll>
                            </div>
                        </div>
                    </Fragment>
                )}
            </div>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    userId: state.user.user.id,
    role: state.user.user.role,
    position: state.user.position,
});

export default connect(mapStateToProps, {})(Forms);
