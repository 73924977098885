import { Fragment } from 'react';
import { connect } from 'react-redux';
import { updateChannelNotification } from '../../../_redux/actions/ActionOrganization';

import { FiSettings } from 'react-icons/fi';
import { actions } from './styles/TeamDetailsStyles';
import { Link } from 'react-router-dom';

const ChannelSetting = ({ room, team, disabled = false }) => {
    return (
        <Fragment>
            {disabled || !team.id || !room.id ? (
                <div css={actions} className="disabled">
                    <FiSettings />
                    <span>Settings</span>
                </div>
            ) : (
                <Link css={actions} to={`/teams/${team.id}/channels/${room.id}/settings`}>
                    <FiSettings />
                    <span>Settings</span>
                </Link>
            )}
        </Fragment>
    );
};

const MapStateToProps = (state) => ({
    user: state.user.user,
});

export default connect(MapStateToProps, { updateChannelNotification })(ChannelSetting);
