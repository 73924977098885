import { css } from 'twin.macro';
import { CSSTransition } from 'react-transition-group';
import React, { useRef, useEffect, useState, Fragment } from 'react';
import ChatDetailsUserInfo from '../Features/Chat/ChatDetailsUserInfo';
import { withRouter } from 'react-router-dom';
import { getOtherUserData } from '../../_redux/actions/ActionUser';
import { getChannelMembers, changeRoleInRoom } from '../../_redux/actions/ActionOrganization';
import { connect } from 'react-redux';

// icons
import { Button, FormCheck } from 'react-bootstrap';
import { checkPermission } from '../../_helpers';
import { RiCloseCircleFill } from 'react-icons/ri';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useOutsideClickListener } from '../../_helpers/hooks/useOutsideClickListener';
import { detailOptions } from '../Features/Teams/styles/TeamDetailsStyles';
import Files from '../Features/Teams/Files';
import Medias from '../Features/Teams/Medias';

const roles = [
	{ label: 'User', value: 'user' },
	{ label: 'Family', value: 'family' },
	{ label: 'Third Party', value: 'doctor' },
	{ label: 'Supervisor', value: 'supervisor' },
];

const ProfileDrawerStyle = css`
	.profile-container {
		z-index: 9999;
		width: 100%;
		height: 100vh;
		position: fixed;
		display: flex;
		justify-content: center;
		background-color: white;
		padding-bottom: 5rem;
		overflow-y: auto;
		border-left: 2px solid #efefef;
		.inner {
			cursor: pointer;
			margin-left: auto;
			width: 100%;
		}
	}

	.profilewrapper {
		padding: 2.3rem;
		height: 100%;
		width: 100%;
	}
`;

const changeRole = css`
	width: 100%;
	background-color: #e0f0ef;
	border-radius: 5px;
	padding: 10px;
	.selectlabel {
		margin-bottom: 10px;
		color: #757575;
	}
`;

const ProfileDrawer = ({
	history,
	userId,
	roomId,
	roomType,
	teamId,
	from,
	drawer_ref,
	getOtherUserData,
	channelMembers,
	currUser,
	changeRoleInRoom,
	getChannelMembers,
	showProfileDrawer,
}) => {
	const [open, setOpen] = useState(false);
	const [user, setUser] = useState({});
	const [selectedRole, setSelectedRole] = useState();
	const [memberRole, setMemberRole] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [currUserRole, setCurrUserRole] = useState(null);
	const [selectedUserTeamRole, setSelectedUserTeamRole] = useState(null);

	const profileDrawerRef = useRef(null);
	useOutsideClickListener(profileDrawerRef, () => {
		if (showProfileDrawer) {
			showProfileDrawer(false);
		}
	});

	useEffect(() => {
		let channelmemberArr = channelMembers?.filter((memb) => memb._id === userId);
		if (channelmemberArr.length) {
			let channelMember = channelmemberArr[0];
			let memberRoom = channelMember.rooms?.filter((room) => room.room === roomId);
			let memberRole = roles.filter((role) => role.value === memberRoom[0]?.role);
			if (memberRole[0]) {
				setMemberRole(memberRole[0].value);
				setSelectedRole(memberRole[0].value);
			}
		}
	}, [channelMembers, userId, roomId]);
	useEffect(() => {
		if (currUser) {
			if (currUser.role === 'admin') {
				setCurrUserRole('admin');
			} else {
				const thisTeam = currUser.teamList.find((team) => team?.team?.id === teamId);
				const thisRoom = currUser.roomList.find((room) => room?.room?.id === roomId);
				setCurrUserRole(thisTeam?.role === "user" ? thisRoom?.role : thisTeam?.role);
			}
		}
	}, [currUser, roomId, teamId]);

	useEffect(() => {
		if (userId) {
			const fetchUser = async () => {
				const user = await getOtherUserData(userId);
				if (user) {
					const thisTeam = user.teamList.find((team) => team?.team === teamId);
					setSelectedUserTeamRole(thisTeam?.role || null);
					setUser(user);
					setOpen(true);
				}
			};
			fetchUser();
		}
	}, [userId, getOtherUserData, teamId]);

	const handleChangeRole = async () => {
		const data = {
			user: userId,
			role: selectedRole,
		};
		setIsLoading(true);
		try {
			await changeRoleInRoom(data, roomId);
			await getChannelMembers(roomId);
			setIsLoading(false);
			toast.success('Role has been updated Successfully');
		} catch (err) {
			setIsLoading(false);
			toast.error(err.message);
		}
	};

	return (
		<CSSTransition in={open} timeout={500} classNames={'details-ani-'}>
			<div
				className="details-ani"
				css={css`
					width: 100%;
					box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
					overflow-y: scroll;
				`}
				ref={profileDrawerRef}
			>
				<div id="style-1" css={ProfileDrawerStyle}>
					<div className={`profile-container ${open ? 'open' : ''}`}>
						<RiCloseCircleFill
							className="text-primary pointer"
							css={css`
								position: absolute;
								right: 20px;
								top: 20px;
							`}
							size={24}
							onClick={() => {
								window.setTimeout(() => {
									if (from !== 'chat') {
										history.push(`/teams/${roomId}`);
									} else {
										history.push(`/chat/${userId}`);
										showProfileDrawer(false);
									}
								}, 200);
								setOpen(false);
							}}
						/>
						{user && (
							<Fragment>
								<div className="bg-white profilewrapper">
									<ChatDetailsUserInfo
										user={user}
										memberRole={memberRole}
										roomType={roomType}
										from={from}
									/>

									{from === 'chat' ? (
										<div
											className="mt-5 pb-5"
											css={css`
												color: black;
											`}
										>
											<div css={detailOptions}>
												<Files userId={userId} />
											</div>

											<div css={detailOptions}>
												<Medias userId={userId} />
											</div>
										</div>
									) : (
										<Fragment>
											{roomType !== 'public' && (
												<div className="mt-5 pb-5">
													{checkPermission(currUserRole, 'assign:channalRole') && (
														<div css={changeRole}>
															{selectedUserTeamRole === 'owner' ||
																selectedUserTeamRole === 'teamManager' ? (
																<div className="d-flex align-items-center">
																	<div className="selectlabel m-0 mr-3">
																		User's Role
																	</div>
																	{selectedUserTeamRole === 'owner' ? (
																		<span className="badge badge-primary">
																			Owner
																		</span>
																	) : (
																		<span className="badge badge-secondary">
																			Team manager
																		</span>
																	)}
																</div>
															) : (
																<div>
																	<div className="selectlabel">
																		User's Role for the Channel
																	</div>
																	<div className="row">
																		{roles.map((role) => (
																			<div
																				className="col-6"
																				key={role.value}
																				onChange={(e) =>
																					setSelectedRole(e.target.value)
																				}
																			>
																				<FormCheck
																					type="radio"
																					value={role.value}
																					label={role.label}
																					id={role.value}
																					name={'role'}
																					defaultChecked={
																						selectedRole === role.value
																					}
																				/>
																			</div>
																		))}
																	</div>
																	<Button
																		className="mt-4"
																		onClick={() => handleChangeRole()}
																		disabled={isLoading}
																	>
																		<span className="h4">
																			{isLoading
																				? 'Processing ...'
																				: 'Change Role'}
																		</span>
																	</Button>
																</div>
															)}
														</div>
													)}
												</div>
											)}
										</Fragment>
									)}
								</div>
							</Fragment>
						)}
					</div>
				</div>
			</div>
		</CSSTransition>
	);
};

const mapStateToProps = (state) => ({
	channelMembers: state.rooms.channelMembers,
	currUser: state.user.user,
	teamMembers: state.teams.teamMembers,
});

export default connect(mapStateToProps, { getOtherUserData, changeRoleInRoom, getChannelMembers })(
	withRouter(ProfileDrawer)
);
