import { css } from 'twin.macro';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import '../Common/Message.css';
//redux
import { connect } from 'react-redux';
import MessageDate from '../Common/MessageDate';
import { deleteDirectChatRoomMessage, editDirectChatRoomMessage } from '../../../_redux/actions/ActionMessage';
import SenderMessage from '../Teams/SenderMessage';
import ReceiverMessage from '../Teams/ReceiverMessage';
import { isJson } from '../../../_helpers';

const Message = ({
    message,
    messages,
    refs,
    date,
    user,
    userName,
    userId,
    currUser,
    setReply,
    lastSeen,
    deleteDirectChatRoomMessage,
    editDirectChatRoomMessage,
    position,
}) => {
    const setReplyMessage = (reply) => {
        const teampReply = { ...reply };
        if (teampReply.file) {
            setReply(teampReply);
        } else {
            const { text } = isJson(teampReply.data) ? JSON.parse(teampReply.data) : { text: teampReply.data };
            if (text) {
                teampReply.data = text;
                setReply(teampReply);
            }
        }
    };

    const isSentByCurrentUser = (userId) => {
        if (userId === currUser.id) return true;
        return false;
    };

    const handleDeleteMessage = (messageId) => {
        if (window.confirm('Do you want to delete this message?')) {
            deleteDirectChatRoomMessage(userId, messageId);
        }
    };

    const sendEditedMessage = (reply, data, edit, position) => {
        if (edit) {
            const msg = {};
            msg.text = data;

            if (position) {
                msg.location = { latitude: position.coords.latitude, longitude: position.coords.longitude };
            } else {
                msg.location = { latitude: null, longitude: null };
            }
            if (reply) {
                msg.reply = reply;
            }
            editDirectChatRoomMessage(userId, edit, { data: JSON.stringify(msg) });
        }
    };

    //this is for reply
    const handleClick = (id) => {
        if (messages.findIndex((mes) => mes.id === id) !== -1) {
            if (refs[id].current) {
                refs[id].current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'nearest',
                });

                refs[id].current.style.borderRadius = '15px';
                refs[id].current.style.boxShadow = '0 0px 10px 4px #50aeb0';
                refs[id].current.style.transition = 'all 500ms ease-in-out 500ms';
                window.setTimeout(() => {
                    if (refs[id].current) refs[id].current.style.boxShadow = 'none';
                }, 2000);
            }
        } else {
        }
    };

    const handleMessageClick = () => {};

    return (
        <Fragment>
            <MessageDate date={date} />
            <Fragment>
                {message.map((m, i) => {
                    const { id, type, fromUser } = m;
                    if (type === 'event') {
                        return null;
                    }

                    return currUser && isSentByCurrentUser(fromUser) ? (
                        // CURRENT USER MESSAGE
                        <Fragment key={i}>
                            <SenderMessage
                                m={m}
                                setReply={setReplyMessage}
                                position={position}
                                currUser={currUser}
                                refs={refs}
                                sendEditedMessage={sendEditedMessage}
                                handleDeleteMessage={handleDeleteMessage}
                                handleClick={handleClick}
                                handleMessageClick={handleMessageClick}
                            />
                            {lastSeen === id && (
                                <p className="d-flex border-bottom  border-white pt-5  m-0 mb-2 text-muted  justify-content-center ">
                                    <span
                                        className="bg-light px-4 py-2"
                                        css={css`
                                            border-radius: 10px 10px 0px 0px;
                                        `}
                                    >
                                        New Messages
                                    </span>
                                </p>
                            )}
                        </Fragment>
                    ) : (
                        // CURRENT USER MESSAGE END
                        // OTHER USERS MESSAGE

                        <Fragment key={i}>
                            <ReceiverMessage
                                m={m}
                                directUser={user}
                                setReply={setReply}
                                position={position}
                                refs={refs}
                                handleClick={handleClick}
                                handleMessageClick={handleMessageClick}
                                currUser={currUser}
                                handleDeleteMessage={handleDeleteMessage}
                            />
                            {lastSeen === id && (
                                <p className="d-flex border-bottom  border-white pt-5  m-0 mb-2 text-muted  justify-content-center ">
                                    <span
                                        className="bg-light px-4 py-2"
                                        css={css`
                                            border-radius: 10px 10px 0px 0px;
                                        `}
                                    >
                                        New Messages
                                    </span>
                                </p>
                            )}
                        </Fragment>
                    );
                })}
            </Fragment>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    currUser: state.user.user,
    position: state.user.position,
});

Message.prototype = {
    message: PropTypes.object.isRequired,
    date: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    currUser: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { deleteDirectChatRoomMessage, editDirectChatRoomMessage })(Message);
