//core imports
import { useState, Fragment, useEffect } from 'react';
import { Modal, Button, Dropdown, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';

//redux stuff
import { connect } from 'react-redux';
import { AssignUserToTeam, AssignNewMemberToTeam } from '../../../_redux/actions/ActionOrganization';
import { startLoading, stopLoading } from '../../../_redux/actions/ActionAuthentication';

//componentss
import ReactSelectComponent from '../../Admin/users/ReactSelectComponent';
import { InviteToTeamContent } from './InviteToTeamContent';

//helpers
import { onlyEmailValidation } from '../../../_helpers';

//styles
import loaderStyles from '../../../_elements/loader';
import { formLabel } from '../../../_elements/formLabel';
import formInput from '../../../_elements/formInput';
import { Link } from 'react-router-dom';
import { getOtherUserData } from '../../../_redux/actions/ActionUser';
import { FaUserPlus } from 'react-icons/fa';

function InviteToTeam({
	teamMembers,
	AssignUserToTeam,
	AssignNewMemberToTeam,
	teamId,
	orgId,
	getOtherUserData,
	startLoading,
	ui,
	stopLoading,
	type,
}) {
	const [show, setShow] = useState(false);
	const [selectedOption, setSelectedOption] = useState();
	const [addByEmail, setAddByEmail] = useState(false);
	const [user, setUser] = useState(null);
	const [step, setStep] = useState('1');

	//Get the selected options values in user state
	useEffect(() => {
		if (selectedOption) {
			const fectUserData = async () => {
				let user = await getOtherUserData(selectedOption.value);
				return setUser(user);
			};
			fectUserData();
		}
	}, [selectedOption, getOtherUserData]);

	const { register, getValues, errors, setError, clearErrors } = useForm({
		mode: 'onBlur',
		resolver: yupResolver(onlyEmailValidation),
	});

	const handleClose = () => {
		setShow(false);
	};
	const handleShow = () => {
		setShow(true);
		setAddByEmail(false);
		setUser(null);
		setSelectedOption({});
		setStep('1');
	};

	const handleInvite = async () => {
		startLoading();
		const data = getValues('name');
		const email = getValues('email');
		let response;
		if (user) {
			try {
				let tempData = {
					email: user.email,
					name: user.name,
					onlineStatus: user.onlineStatus,
					role: user.role,
					team: [...(user.team || []), { role: 'user', team: teamId }],
					_id: user.id || user.id,
				};
				if (user.avatar) {
					tempData.avatar = user.avatar;
				}
				response = await AssignNewMemberToTeam(orgId, teamId, tempData);
			} catch (err) {
				console.log(err);
			}
		} else if (data) {
			const tempData = {
				name: data,
				email,
			};
			try {
				response = await AssignNewMemberToTeam(orgId, teamId, tempData);
				stopLoading();
			} catch (err) {
				console.log(err);
				stopLoading();
			}
		} else {
			stopLoading();
			setError('name', {
				type: 'manual',
				message: 'Name is required',
			});
		}
		if (response) {
			setShow(false);
			setUser(null);
			setSelectedOption(null);
		}
		stopLoading();
	};

	const handleBack = () => {
		setUser(null);
		setStep('1');
	};
	const handleNext = async () => {
		startLoading();
		const email = getValues('email');
		if (user) {
			const teamIndex = teamMembers.findIndex((member) => member.email === user.email);
			const isPresent = Boolean(teamIndex >= 0);
			if (isPresent) {
				let teamMemberTeam = teamMembers[teamIndex].team;
				if (teamMemberTeam.find((team) => team.team === teamId)) {
					toast.error('User is already in team');
					stopLoading();
					return setError('email', {
						type: 'manual',
						message: 'User is already in the team',
					});
				}
				stopLoading();
				return setStep('2');
			} else {
				try {
					// const userInTeam = teamMembers.find((member) => member._id === user.id || user._id);
					// if (userInTeam) {
					// 	toast.error('User is already in team', {
					// 		position: 'top-right',
					// 		autoClose: 3000,
					// 		hideProgressBar: false,
					// 		closeOnClick: true,
					// 		pauseOnHover: true,
					// 		draggable: true,
					// 		progress: undefined,
					// 	});
					// 	stopLoading();
					// 	return setError('email', {
					// 		type: 'manual',
					// 		message: 'User is already in the team',
					// 	});
					// }
					setUser(user);
					stopLoading();
					return setStep('2');
				} catch (e) {
					console.log(e, 'err');
					setUser(null);
					return stopLoading();
				}
			}
		} else if (email) {
			const teamIndex = teamMembers.findIndex((member) => member.email === email);
			const isPresent = Boolean(teamIndex >= 0);
			setUser(null);
			if (isPresent) {
				let teamMemberTeam = teamMembers[teamIndex].team;
				if (teamMemberTeam.find((team) => team.team === teamId)) {
					toast.error('User is already in team');
					stopLoading();
					return setError('email', {
						type: 'manual',
						message: 'User is already in the team',
					});
				}
				return stopLoading();
			} else {
				stopLoading();
				return setStep('2');
			}
		} else {
			console.log('ERROR');
			stopLoading();
			return setError('email', {
				type: 'manual',
				message: 'Email is required',
			});
		}
	};

	return (
		<Fragment>
			{type === 'admin' ? (
				<Dropdown.Item onClick={handleShow} style={{borderRadius:'4px'}}><FaUserPlus/></Dropdown.Item>
			) : (
				<Dropdown.Item onClick={handleShow} style={{borderRadius:'4px'}}>Invite to team</Dropdown.Item>
			)}

			{show && (
				<Modal show={show} onHide={handleClose}>
					<Modal.Header closeButton>
						<Modal.Title className="modal-headers">Invite People in This Team</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						{ui.loading && (
							<div css={loaderStyles}>
								<img src="/images/loading.gif" alt="loader" />
							</div>
						)}
						{step === '1' ? (
							addByEmail ? (
								<Fragment>
									<Form.Group>
										<Form.Label css={formLabel}>Email address</Form.Label>
										<Form.Control
											css={formInput}
											type="email"
											isInvalid={errors.email}
											placeholder="Enter email"
											name="email"
											ref={register}
										/>
										<Form.Text className="text-danger">{errors.email?.message}</Form.Text>
									</Form.Group>
									<Form.Group>
										<Form.Label css={formLabel}>Name</Form.Label>
										<Form.Control
											css={formInput}
											type="text"
											isInvalid={errors.name}
											onChange={() => clearErrors('name')}
											placeholder="Enter name"
											name="name"
											ref={register}
										/>
										{errors.firstName && 'First name is required'}
										<Form.Text className="text-danger">{errors.name?.message}</Form.Text>
									</Form.Group>
									<div className="text-infos">
										Add by{' '}
										<Link
											onClick={() => {
												setAddByEmail(false);
											}}
										>
											Name
										</Link>
										?
									</div>
								</Fragment>
							) : (
								<Fragment>
									<Form.Group controlId="formBasicEmail">
										<Form.Label css={formLabel}>Name</Form.Label>
										<ReactSelectComponent
											css={formInput}
											selectedOption={selectedOption}
											setSelectedOption={setSelectedOption}
											placeholder="Search user"
											assignedTo={selectedOption && selectedOption.value}
										/>
									</Form.Group>
									<div className="text-infos">
										User not in the system? Add by{' '}
										<Link
											onClick={() => {
												setAddByEmail(true);
											}}
										>
											Email
										</Link>
									</div>
								</Fragment>
							)
						) : (
							<Fragment>
								{user ? (
									<InviteToTeamContent teamId={null} setShow={setShow} user={user} />
								) : (
									<Fragment>
										<Form.Group>
											<Form.Label css={formLabel}>Email address</Form.Label>
											<Form.Control
												css={formInput}
												type="email"
												defaultValue={getValues('email')}
												disabled
											/>
										</Form.Group>
										<Form.Group>
											<Form.Label css={formLabel}>Name</Form.Label>
											<Form.Control
												css={formInput}
												type="text"
												placeholder="Enter name"
												defaultValue={getValues('name')}
												disabled
											/>
										</Form.Group>
									</Fragment>
								)}
							</Fragment>
						)}
					</Modal.Body>

					<Modal.Footer>
						{step === '1' ? (
							<div className="w-100 d-flex justify-content-between">
								<Button variant="secondary" onClick={handleClose}>
									Close
								</Button>
								<Button variant="primary" onClick={handleNext}>
									Next
								</Button>
							</div>
						) : (
							<div className="w-100 d-flex justify-content-between">
								<Button variant="secondary" onClick={handleBack}>
									Back
								</Button>
								<Button variant="primary" onClick={handleInvite}>
									Invite
								</Button>
							</div>
						)}
					</Modal.Footer>
				</Modal>
			)}
		</Fragment>
	);
}
const mapStateToProps = (state) => ({
	ui: state.ui,
	teamMembers: state.teams.teamMembers,
});

export default connect(mapStateToProps, {
	getOtherUserData,
	AssignUserToTeam,
	startLoading,
	stopLoading,
	AssignNewMemberToTeam,
})(InviteToTeam);
