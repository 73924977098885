import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { WebSocketContext } from '../../../_redux/actions/ActionSocket';
import { logoutUser } from '../../../_redux/actions/ActionAuthentication';

export const useLogout = () => {
	const ws = useContext(WebSocketContext);
	const dispatch = useDispatch();

	const logoutFunction = () => {
		if (ws && ws.socket) {
			ws.disconnectSocket();
		}
		dispatch(logoutUser());
	};

	return { logoutFunction };
};
