import { css } from 'twin.macro';

export const customCheckbox = css`
    position: absolute; // take it out of document flow
    opacity: 0; // hide it

    & + label {
        position: relative;
        cursor: pointer;
        color: #000;
        padding: 0;
    }

    // Box.
    & + label:before {
        content: '';
        margin-right: 5px;
        margin-top: 2px;
        display: inline-block;
        vertical-align: text-top;
        width: 17px;
        height: 17px;
        border: 2px solid #50aeb0;
        border-radius: 2.5px;
        background: white;
        transition: background-color 200ms;
    }

    // Box hover
    &:hover + label:before {
        background: #50aeb0;
    }

    // Box focus
    &:focus + label:before {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    // Box checked
    &:checked + label:before {
        background: #50aeb0;
    }

    // Disabled state label.
    &:disabled + label {
        // color: #b8b8b8;
        opacity: 0.8;
        cursor: auto;
    }

    // Disabled box.
    &:disabled + label:before {
        box-shadow: none;
        // background: #ddd;
        opacity: 0.8;
        cursor: not-allowed;
    }

    // Checkmark. Could be replaced with an image
    &:checked + label:after {
        content: '';
        position: absolute;
        left: 4px;
        top: 12px;
        background: white;
        width: 2px;
        height: 2px;
        box-shadow:
            2px 0 0 white,
            4px 0 0 white,
            4px -2px 0 white,
            4px -4px 0 white,
            4px -6px 0 white,
            4px -8px 0 white;
        transform: rotate(45deg);
    }
`;
