import styled from '@emotion/styled';

export const SideMenuStyled = styled.div`
	width: 20%;

	padding: 0.8rem;
	.sideMenu {
		background-color: #41578f;
        color: white;
		height: 100%;
		border-radius: 10px;
    padding: 1.3rem 1.8rem;
	}
`;
