import React from 'react';
import { css } from 'twin.macro';
import {  Modal } from 'react-bootstrap';
import { useState } from 'react';
import { connect } from 'react-redux';
import { Body } from './styles';


import StaffForm from './StaffForm';

import {BsPencil} from 'react-icons/bs';
import { ToolTip } from '../../../../_elements/ToolTip';


const EditStaff = ({employee}) => {
	const [showModal, setShowModal] = useState(false);
	return (
		<div>
			<ToolTip text={'Edit'} side={'top'} cssStyles={"top:-70% !important; min-width: fit-content !important"}>
				<BsPencil className="icon edit" onClick={()=>setShowModal(true)}/>
			</ToolTip>
			<Modal
				show={showModal}
				backdropClassName="bg-dark"
				onHide={() => setShowModal(false)}
				centered
				backdrop="static"
			>
				<Modal.Header closeButton>
					<div className="header">
						<h4
							css={css`
								margin: 0;
							`}
						>
							Edit Staff
						</h4>
					</div>
				</Modal.Header>
				<Modal.Body className="modal-body">
					<Body>
						<StaffForm setShowModal={setShowModal} employee={employee} formType={"Edit"} />
					</Body>
				</Modal.Body>
			</Modal>
		</div>
	);
};
const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps,{})(EditStaff);
