import React, { useState, Fragment, useEffect, useRef } from 'react';
import { Modal, Form, Col, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { css } from 'twin.macro';

//components

import ChannelSelectFilter from '../ChannelSelectFilter';
import { fetchWrapper } from '../../../_helpers';
import { setMessage } from '../../../_redux';

const AssignChannelToPackageModal = ({
	setMessage,
	CurrUser,
	event,
	show,
	handleClose,
	handleSelectedChannelData,
	selectedRoomOption,
	setSelectedRoomOption,
	isGlobal,
	packageData,
	isCopyMode,
	handleSelectedChannelCopy,
	onAssignChanneltoEventCallback,
	teamIds,
}) => {
	const { handleSubmit } = useForm({
		mode: 'onBlur',
	});

	const packageId = packageData?.id || packageData?._id;
	let selectedTasks = packageData?.taskData?.map((task) => {
		let taskOption = {
			label: task.title,
			value: task._id,
		};
		return taskOption;
	});
	let selectedTeams = packageData?.teamData?.map((team) => {
		let teamOption = {
			label: team.name,
			value: team.id,
		};
		return teamOption;
	});

	const onSubmit = async () => {
		try {
			if (!selectedRoomOption) {
				throw new Error('Please select a channel');
			}
			if (!isGlobal) {
				await handleSelectedChannelData(selectedRoomOption);
				return handleClose();
			}
				let selectedTeamsId = selectedTeams?.map((team) => {
					return team.value;
				});
				let selectedTasksId = selectedTasks?.map((task) => {
					return task.value;
				});
				let updatedPackageData = {
					name: packageData?.name,
					teams: selectedTeamsId,
					tasks: selectedTasksId,
					channel: selectedRoomOption,
				};
				if (onAssignChanneltoEventCallback) {
					await onAssignChanneltoEventCallback(packageId, updatedPackageData);
				} else {
					await fetchWrapper.put(`activityPackages/${packageId}`, updatedPackageData);
				}
			handleClose();
		} catch (e) {
			console.log(e);
			setMessage({ error: true, message: `Error: ${e.message}` });
		}
	};

	const roomNotFoundChecker = useRef({});
	useEffect(() => {
		roomNotFoundChecker.current.notFound = true;
	}, []);


	const onRoomSelect = (selectedRoom) => {
		setSelectedRoomOption(selectedRoom);
	};

	return (
		<Fragment>
			{/* <BiLinkExternal className="icon" onClick={handleShow} /> */}
			<Modal show={show} onHide={handleClose} size="xl">
				<Modal.Header className="h2 font-weight-bold d-flex justify-content-center">
					Assign Channel To Event
				</Modal.Header>
				<Modal.Body className="m-2">
					<Form className="w-90 m-auto" onSubmit={handleSubmit(onSubmit)}>
						<Form.Row>
							<Form.Group as={Col} md={12}>
								<Form.Label>Select Channel </Form.Label>
								<ChannelSelectFilter
									onRoomSelect={onRoomSelect}
									assignedChannel={selectedRoomOption}
									placeholder="Search channels"
									teams={
										selectedTeams && selectedTeams.length > 0
											? selectedTeams.map((selectedTeam) => selectedTeam.value)
											: []
									}
									teamIds={teamIds}
								/>
							</Form.Group>
							<Form.Group as={Col} md={12}>
								<div
									css={css`
										padding: 2rem 0 0 0;
										display: flex;
										width: 100%;
										justify-content: space-between;
									`}
								>
									<Button variant="secondary" onClick={handleClose}>
										Close
									</Button>
									<Button variant="primary" type="button" onClick={handleSubmit(onSubmit)}>
										{isGlobal ? 'Assign Globally' : 'Assign Channel'}
									</Button>
								</div>
							</Form.Group>
						</Form.Row>
					</Form>
				</Modal.Body>
			</Modal>
		</Fragment>
	);
};
const mapStateToProps = (state) => ({
	CurrUser: state.user.user,
});
const mapDispatchToProps = { setMessage };

export default connect(mapStateToProps, mapDispatchToProps)(AssignChannelToPackageModal);
