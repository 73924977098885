import React, { useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import { CreateEventStyle } from '../Tasks/Styles/EventModalStyles';
import { EventModalForm } from '../Tasks/EventModalForm';

const EventPlannerCalendar = ({
	events,
	eventModalProps = {},
	handleCreateEvent,
	handleShowEvent,
	handleEditEvent,
}) => {
	const [eventModalData, setEventModalData] = useState({ show: false });

	const handleCloseEventModal = () => {
		setEventModalData({ show: false })
	}

	const handleEventClick = async ({ event }) => {
		console.log(event)
		return handleShowEvent && handleShowEvent(event?.extendedProps?._id)
	};

	const handleDateSelect = async (selectInfo) => {
		if (!handleCreateEvent) {
			return;
		}
		setEventModalData({
			show: true,
			mode: 'create',
			data: {
				date: selectInfo.start,
				dueDate: selectInfo.end
			},
			handler: handleCreateEvent
		});
	};

	const eventCallback = async ({ event }) => {
		if (!handleEditEvent) {
			return;
		}
		const updatedTask = {
			title: event.extendedProps?.label,
			_id: event.extendedProps?.value,
			...event.extendedProps,
			latestDatePointer: event?.start,
			latestDueDatePointer: event?.end,
			date: event?.start,
			dueDate: event?.end
		};
		handleEditEvent(updatedTask._id, updatedTask);
		setEventModalData({
			show: true,
			mode: 'edit',
			data: updatedTask,
			handler: (event) => handleEditEvent(updatedTask._id, event)
		})
	};

	const renderEventContent = (eventInfo) => {
		return (
			<div
				style={{
					backgroundColor: '#50aeb0',
					color: '#FFFFFF',
					overflow: 'hidden',
					maxWidth: '100%',
				}}
			>
				<b>{eventInfo.timeText}</b>
				<i>{eventInfo.event.title}</i>
			</div>
		);
	};

	return (
		<div className="demo-app" style={{ width: '100%', height: '100%' }}>
			<div className="demo-app-main">
				<FullCalendar
					plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
					headerToolbar={{
						left: 'prev,next',
						center: 'title',
						right: 'dayGridMonth,timeGridWeek,timeGridDay',
					}}
					initialView="dayGridMonth"
					editable={!!handleEditEvent}
					selectable={!!handleCreateEvent}
					selectMirror={true}
					dayMaxEvents={3}
					events={events.map(event => (
						{
							...event,
							start: event.start || event.latestDatePointer || event.date,
							end: event.end || event.latestDueDatePointer || event.dueDate
						}
					))}
					select={handleDateSelect}
					eventDrop={eventCallback}
					eventResize={eventCallback}
					eventContent={renderEventContent} // custom render function
					eventClick={handleEventClick}
				/>
			</div>
			<CreateEventStyle size="lg" centered show={eventModalData.show} onHide={handleCloseEventModal}>
				<EventModalForm
					data={eventModalData.data}
					handleSubmitTask={eventModalData.handler}
					handleClose={handleCloseEventModal}
					mode={eventModalData.mode}
					{...eventModalProps}
				/>
			</CreateEventStyle>
		</div>
	);
};

export default EventPlannerCalendar
