import { css } from 'twin.macro';

import { Fragment, useState } from 'react';
import ImageModal from '../Teams/ImageModal';
import { FaFileAudio, FaFileDownload } from 'react-icons/fa';
import { Modal } from 'react-bootstrap';
import ShareBtn from '../../ShareBtn/ShareBtn';
import FileViewer from './FileViewer';
import { removeMongoidAndTimestamp } from '../../../_helpers';
import mimetypes from '../../../_helpers/mimetypes';

export const textStyles = css`
	font-size: 14px !important;
	text-decoration: none;
	color: #212529;
	margin-bottom: 10px;

	&:hover {
		color: #525c5e;
	}
`;

export default function FileMessage({ file, displayMessage, className, teamDetails, documentSettings }) {
	const ext = file?.slice(((file.lastIndexOf('.') - 1) >>> 0) + 2);
	const mimetype = mimetypes[`.${ext}`] || "application/octet-stream";
	const [showModal, setShowModal] = useState(false);
	let fileName;
	try {
		fileName = file?.split('/')[file?.split('/').length - 1];
		fileName = removeMongoidAndTimestamp(fileName);
	} catch (err) {
		console.log(err);
		fileName = null;
	}
	switch (mimetype?.split("/")[0]) {
		case 'image':
			return (
				<div
					className={`cursor-pointer ${teamDetails ? 'teamDetailMedia' : ''}`}
					css={css`
						overflow: hidden;
						text-overflow: ellipsis;
						max-width: 256px;
						&.teamDetailMedia {
							background: white;
							div > a > img {
								width: 100%;
								height: 100%;
								object-fit: contain;
							}
						}
						:hover .shareBtn {
							opacity: 1;
							z-index: 3;
						}
					`}
				>
					<span>{removeMongoidAndTimestamp(displayMessage)}</span>
					<ImageModal file={file} className={className} documentSettings={documentSettings} />
				</div>
			);
		case 'video':
			return (
				<div
					css={css`
						display: flex;
						flex-direction: column;

						:hover .shareBtn {
							z-index 10;
							opacity: 1;
						}
					`}
					className="cursor-pointer "
				>
					<ShareBtn url={file} />
					{removeMongoidAndTimestamp(displayMessage)}
					<div
						css={css`
							margin-top: 1rem;
						`}
					>
						<video
							width={`${teamDetails ? '115' : '400'}`}
							controls
							className={className}
							crossOrigin="anonymous"
							controlsList="nodownload"
						>
							<source src={file} type={mimetype} />
							Your browser does not support HTML video.
						</video>
					</div>
				</div>
			);
		case 'audio':
			return (
				<Fragment>
					{teamDetails ? (
						<Fragment>
							<div
								className="cursor-pointer "
								onClick={() => setShowModal(true)}
								css={css`
									width: 100%;
									display: flex;
									justify-content: center;
									align-items: center;								
									:hover .shareBtn {
										z-index 10;
										opacity: 1;
									}
								`}
							>
								<ShareBtn url={file} />
								<FaFileAudio fontSize="48" color="#50aeb0" />
							</div>
							<Modal key={0} show={showModal} onHide={() => setShowModal(false)} centered>
								<Modal.Header closeButton>
									<div
										css={css`
											margin-top: 1rem;
											display: flex;
											justify-content: center;
											align-items: center;
											flex: 1;
										`}
									>
										<audio controls className={className} controlsList="nodownload">
											<source src={file} type={mimetype} />
											Your browser does not support HTML audio.
										</audio>
									</div>
								</Modal.Header>
							</Modal>
						</Fragment>
					) : (
						<div
							css={css`
								display: flex;
								flex-direction: column;
							`}
						>
							{removeMongoidAndTimestamp(displayMessage)}

							<div
								css={css`
									margin-top: 1rem;
									position: ${documentSettings ? 'relative' : 'inherit'};
									:hover .shareBtn {
										z-index 10;
										opacity: 1;
									}
								`}
							>
								{documentSettings && <ShareBtn url={file} />}
								<audio controls className={className}>
									<source src={file} type={mimetype} />
									Your browser does not support HTML audio.
								</audio>
							</div>
						</div>
					)}
				</Fragment>
			);
		default:
			switch (ext) {
				case 'zip':
					return (
						<div
							css={css`
						display: flex;
						flex-direction: column;
					
						:hover .shareBtn {
										z-index 10;
										opacity: 1;
									}
								`}
						>
							<ShareBtn url={file} />

							{removeMongoidAndTimestamp(displayMessage)}

							<div
								css={css`
							margin-top: 1rem;
						`}
							>
								<a href={file} className="text-white">
									<FaFileDownload className="mx-3" size={24} />
									{fileName ? fileName : 'Download'}
								</a>
							</div>
						</div>
					);

				case 'pdf':
					return (
						<div
							className="cursor-pointer "
							css={css`
							
							margin-top: 1rem;
							position: ${documentSettings ? 'relative' : 'inherit'};
							:hover .shareBtn {
									z-index 10;
									opacity: 1;
								}
							`}
						>
							{documentSettings && <ShareBtn url={file} />}
							<FileViewer file={file} ext={ext} fileName={fileName} documentSettings={documentSettings} />
						</div>
					);

				default:
					return (
						<div
							className="cursor-pointer "
							css={css`
						display: flex;
						flex-direction: column;
					`}
						>
							{!documentSettings && removeMongoidAndTimestamp(displayMessage)}
							<div
								css={css`
							margin-top: 1rem;
							position: ${documentSettings ? 'relative' : 'inherit'};
						
							:hover .shareBtn {
										z-index 10;
										opacity: 1;
							}
						`}
							>
								<FileViewer file={file} ext={ext} fileName={removeMongoidAndTimestamp(fileName)} documentSettings={documentSettings} />
								{documentSettings && <ShareBtn url={file} />}
							</div>
						</div>
					);
			}
	}
}
