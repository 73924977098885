import { FaCheckCircle } from "react-icons/fa";
import { IoWarning } from "react-icons/io5";
import { MdOutlineError } from "react-icons/md";
import { Button } from "react-bootstrap"
import { VscLoading } from "react-icons/vsc";
import { fetchWrapper, formatDate, toastWrapper } from "../../../../_helpers";
import React, { useState } from "react";
import { updateClient, updateEmployee } from "../../../../_redux/actions/ActionEVV";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

export const SandataStatusCheck = ({ evv, type, id, isTeamSettings }) => {
    const statusCheck = evv?.metadata?.statusCheck
    const status = evv?.metadata?.statusCheck?.status;
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const checkAgain = async () => {
        try {
            setLoading(true);
            const response = await fetchWrapper.get(`/evv/sandata-status/${type}?uuid=${evv?.metadata?.uuid}`);
            if (type === 'client') {
                dispatch(updateClient({ id, evv: { ...evv, metadata: response } }));
            } else if (type === 'employee') {
                dispatch(updateEmployee({ id, evv: { ...evv, metadata: response } }));
            }
        } catch (err) {
            console.log('sandata-status-error', err)
            toastWrapper.error(err?.message || "Something went wrong")
        }
        finally {
            setLoading(false);
        }
    }
    return (
        <React.Fragment>
            <div className="d-flex flex-row justify-content-between mb-4">
                {
                    (!status) ?
                        (<div style={{ padding: '4px 0' }}>
                            <span>Sandata Status:</span>
                            <span className="ml-2">
                                <IoWarning color="orange" className="mb-2" /> Not Verified Yet
                            </span>
                        </div>)
                        :
                        (<div>
                            {
                                <div className="d-flex flex-column" style={{ gap: '2px', padding: '4px 0' }}>
                                    <div>
                                        <span>Sandata Status:</span>
                                        {
                                            status?.toLowerCase() === "success" ? <span className="ml-2"><FaCheckCircle color="green" className="mb-2 mr-1" />{status}</span> : null
                                        }
                                        {
                                            status?.toLowerCase() === "failed" ? <span className="ml-2"><MdOutlineError color="red" className="mb-2 mr-1" />{status}</span> : null
                                        }
                                    </div>
                                    <div>
                                        <span>Last Checked: </span>
                                        <span className="ml-2">{statusCheck?.date ? formatDate(new Date(statusCheck?.date)) : null}</span>
                                    </div>
                                </div>
                            }
                        </div>)
                }
                {
                    isTeamSettings ? null :
                    <div>
                    <Button disabled={loading || isTeamSettings } onClick={checkAgain}>
                        {
                            loading ?
                                <VscLoading className="spin" width={24} height={24} style={{ cursor: "not-allowed" }} />
                                : "Check Again"
                        }
                    </Button>
                </div>
                }

            </div>
            <hr />
        </React.Fragment>
    )
}