import * as ActionTypes from './ActionTypes';
import { fetchWrapper } from '../../_helpers';

// export const GetProvider = ({ organizationId, page = 1, searchText = '', sortBy = 'name:asc' }) => async (dispatch) => {
// 	try {
// 		dispatch(startTeamLoading());
// 		const response = await fetchWrapper.get(
// 			`organizations/${organizationId}/teams?${sortBy ? `sortBy=${sortBy}&` : ''}&limit=10&page=${page}${
// 				searchText ? `&name=${searchText}` : ''
// 			}`
// 		);
// 		dispatch(set10Teams(response));
// 		dispatch(stopTeamLoading());
// 	} catch (error) {
// 		dispatch(stopTeamLoading());
// 	}
// };

export const getAllProvider = () => async (dispatch) => {
    try {
        const res = await fetchWrapper.get(`evv/provider?limit=50`);
        dispatch(setAllProviders(res.results));
    } catch (error) {
        console.log(error);
    }
};
export const GetProvider =
    ({ searchText, page, state, team }) =>
    async (dispatch) => {
        try {
            dispatch(startEVVLoading());
            if (!page) {
                page = 1;
            }
            const response = await fetchWrapper.get(
                `evv/provider?limit=10&page=${page}${searchText ? `&agencyName=${searchText}` : ''}${team ? `&team=${team}` : ''}${
                    state === 'archieved' ? `&archieved=true` : ''
                }`
            );
            if (response) {
                dispatch(setProviders(response));
                dispatch(stopEVVLoading());
            }
        } catch (err) {
            if (err.name === 'AbortError') {
                return;
            }
            dispatch(stopEVVLoading());
            console.log(err);
        }
    };

export const CreateAProvider = (newProvider, file, admin) => async (dispatch) => {
    try {
        dispatch(startEVVLoading());
        const response = await fetchWrapper.post(`evv/provider`, newProvider);
        // if (file) {
        // 	dispatch(postAvatarTeam(response.id, organizationId, file));
        // } else {
        // 	dispatch(stopEVVLoading());
        // }
        console.log(response, 'respinse');
        dispatch(setNewProvider(response));
        dispatch(setMessage({ error: false, message: 'Provider Created Succesfully' }));
        dispatch(stopEVVLoading());
        return true;
    } catch (error) {
        console.log(error);
        dispatch(stopEVVLoading());
        dispatch(setMessage({ error: true, message: error.message || 'Something went wrong' }));
        return false;
    }
};
export const EditAProvider = (newProvider, id, team) => async (dispatch) => {
    try {
        const response = await fetchWrapper.put(`evv/provider/${id}${team ? `?team=${team}` : ''}`, newProvider);
        // if (file) {
        // 	dispatch(postAvatarTeam(response.id, organizationId, file));
        // } else {
        // 	dispatch(stopEVVLoading());
        // }
        dispatch(setNewProvider(response));
        dispatch(stopEVVLoading());
        dispatch(setMessage({ error: false, message: 'Provider Edited Succesfully' }));
        return true;
    } catch (error) {
        console.log(error);
        dispatch(stopEVVLoading());
        dispatch(setMessage({ error: true, message: error.message || 'Something went wrong' }));
        return false;
    }
};
export const ArchivedProvider = (id, page) => async (dispatch) => {
    try {
        await fetchWrapper.delete(`evv/provider/${id}`);
        dispatch(GetProvider({ page: page }));
        dispatch(setMessage({ error: false, message: 'Provider archived Succesfully' }));
        return true;
    } catch (error) {
        console.log(error);
        dispatch(stopEVVLoading());
        dispatch(setMessage({ error: true, message: error.message || 'Something went wrong' }));
        return false;
    }
};

export const UnarchivedProvider = (id, page) => async (dispatch) => {
    try {
        await fetchWrapper.patch(`evv/provider/${id}`, { archieved: false });
        dispatch(GetProvider({ page: page }));
        dispatch(setMessage({ error: false, message: 'Provider unarchived Succesfully' }));
        return true;
    } catch (error) {
        console.log(error);
        dispatch(stopEVVLoading());
        dispatch(setMessage({ error: true, message: error.message || 'Something went wrong' }));
        return false;
    }
};
export const getAllEmployees = () => async (dispatch) => {
    try {
        const res = await fetchWrapper.get(`evv/employee?limit=1000`);
        dispatch(setAllEmployees(res.results));
    } catch (error) {
        console.log(error);
    }
};

export const GetEmployees =
    ({ firstName, lastName, user, provider, page, state, team }) =>
    async (dispatch) => {
        try {
            dispatch(startEVVLoading());
            if (!page) {
                page = 1;
            }
            const response = await fetchWrapper.get(
                `evv/employee?limit=10&page=${page}${firstName ? `&firstName=${firstName}` : ''}${
                    lastName ? `&lastName=${lastName}` : ''
                }${user ? `&user=${user}` : ''}${provider ? `&provider=${provider}` : ''}${team ? `&team=${team}` : ''}${
                    state === 'archieved' ? `&archieved=true` : ''
                }`
            );
            if (response) {
                dispatch(setEmployees(response));
            }
            dispatch(stopEVVLoading());
        } catch (err) {
            if (err.name === 'AbortError') {
                return;
            }
            dispatch(stopEVVLoading());
            console.log(err);
        }
    };

export const CreateAEmployee = (newEmployee, team) => async (dispatch) => {
    try {
        dispatch(startEVVLoading());
        const response = await fetchWrapper.post(`evv/employee${team ? `?team=${team}` : ''}`, newEmployee);

        dispatch(setMessage({ error: false, message: 'Employee Created Succesfully' }));

        // if (file) {
        // 	dispatch(postAvatarTeam(response.id, organizationId, file));
        // } else {
        // 	dispatch(stopEVVLoading());
        // }
        dispatch(setNewEmployee(response));
        dispatch(stopEVVLoading());
        return true;
    } catch (error) {
        console.log(error);
        dispatch(stopEVVLoading());
        dispatch(setMessage({ error: true, message: error.message || 'Something went wrong' }));
        return false;
    }
};
export const UpdateAnEmployee = (updatedEmployeeData, id) => async (dispatch) => {
    try {
        dispatch(startEVVLoading());
        dispatch(setNewEmployee(updatedEmployeeData));
        dispatch(stopEVVLoading());
        return true;
    } catch (error) {
        console.log(error);
        dispatch(stopEVVLoading());
        dispatch(setMessage({ error: true, message: error.message || 'Something went wrong' }));
        return false;
    }
};

export const EditAEmployee = (newEmployee, id, team) => async (dispatch) => {
    try {
        const response = await fetchWrapper.put(`evv/employee/${id}${team ? `?team=${team}` : ''}`, newEmployee);

        dispatch(setMessage({ error: false, message: 'Employee Edited Succesfully' }));

        // if (file) {
        // 	dispatch(postAvatarTeam(response.id, organizationId, file));
        // } else {
        // 	dispatch(stopEVVLoading());
        // }
        dispatch(setNewEmployee(response));
        dispatch(stopEVVLoading());
        return true;
    } catch (error) {
        console.log(error);
        dispatch(stopEVVLoading());
        dispatch(setMessage({ error: true, message: error.message || 'Something went wrong' }));
        return false;
    }
};

export const ArchiveAStaff = (id, page, team) => async (dispatch) => {
    try {
        await fetchWrapper.delete(`evv/employee/${id}${team ? `?team=${team}` : ''}`);
        dispatch(GetEmployees({ page: page }));
        dispatch(setMessage({ error: false, message: 'Employee archived Succesfully' }));
        return true;
    } catch (error) {
        console.log(error);
        dispatch(stopEVVLoading());
        dispatch(setMessage({ error: true, message: error.message || 'Something went wrong' }));
        return false;
    }
};
export const UnarchiveAStaff = (id, page, team) => async (dispatch) => {
    try {
        await fetchWrapper.patch(`evv/employee/${id}${team ? `?team=${team}` : ''}`, { archieved: false });
        dispatch(GetEmployees({ page: page }));
        dispatch(setMessage({ error: false, message: 'Employee unarchived Succesfully' }));
        return true;
    } catch (error) {
        console.log(error);
        dispatch(stopEVVLoading());
        dispatch(setMessage({ error: true, message: error.message || 'Something went wrong' }));
        return false;
    }
};

export const getAllClients = () => async (dispatch) => {
    try {
        const res = await fetchWrapper.get(`evv/client?limit=1000`);
        dispatch(setAllClients(res.results));
    } catch (error) {
        console.log(error);
    }
};

export const GetClients =
    ({ firstName, lastName, provider, page, state, team }) =>
    async (dispatch) => {
        try {
            dispatch(startEVVLoading());
            if (!page) {
                page = 1;
            }
            // const archieved = state === 'archieved' ? true : state ? false : '';
            const response = await fetchWrapper.get(
                `evv/client?limit=10&page=${page}${firstName ? `&firstName=${firstName}` : ''}${
                    lastName ? `&lastName=${lastName}` : ''
                }${provider ? `&provider=${provider}` : ''}
				${team ? `&team=${team}` : ''}
				${state === 'archieved' ? '&archieved=true' : ''}`
            );
            if (response) {
                dispatch(setClients(response));
            }
            dispatch(stopEVVLoading());
        } catch (err) {
            if (err.name === 'AbortError') {
                return;
            }
            dispatch(stopEVVLoading());
            console.log(err);
        }
    };

export const CreateAClient = (newClient, team) => async (dispatch) => {
    try {
        dispatch(startEVVLoading());
        const response = await fetchWrapper.post(`evv/client${team ? `?team=${team}` : ''}`, newClient);

        dispatch(setMessage({ error: false, message: 'Client Created Succesfully' }));

        // if (file) {
        // 	dispatch(postAvatarTeam(response.id, organizationId, file));
        // } else {
        // 	dispatch(stopEVVLoading());
        // }
        dispatch(setNewClient(response));
        dispatch(stopEVVLoading());
        return true;
    } catch (error) {
        console.log(error);
        dispatch(stopEVVLoading());
        dispatch(setMessage({ error: true, message: error.message || 'Something went wrong' }));
        return false;
    }
};

export const EditAClient = (newClient, id, team) => async (dispatch) => {
    try {
        dispatch(startEVVLoading());
        const response = await fetchWrapper.put(`evv/client/${id}?${team ? `&team=${team}` : ''}`, newClient);
        dispatch(setMessage({ error: false, message: 'Client Edited Succesfully' }));

        // if (file) {
        // 	dispatch(postAvatarTeam(response.id, organizationId, file));
        // } else {
        // 	dispatch(stopEVVLoading());
        // }
        dispatch(setNewClient(response));
        dispatch(stopEVVLoading());
        return true;
    } catch (error) {
        console.log(error);
        dispatch(stopEVVLoading());
        dispatch(setMessage({ error: true, message: error.message || 'Something went wrong' }));
        return false;
    }
};
export const UpdateAClient = (updatedClientData, id) => async (dispatch) => {
    try {
        dispatch(startEVVLoading());
        dispatch(setNewClient(updatedClientData));
        dispatch(stopEVVLoading());
        return true;
    } catch (error) {
        console.log(error);
        dispatch(stopEVVLoading());
        dispatch(setMessage({ error: true, message: error.message || 'Something went wrong' }));
        return false;
    }
};

export const ArchiveAClient = (id, page, team) => async (dispatch) => {
    try {
        await fetchWrapper.delete(`evv/client/${id}`);
        dispatch(GetClients({ page: page, team }));
        dispatch(setMessage({ error: false, message: 'Client Archived Succesfully' }));
        return true;
    } catch (error) {
        console.log(error);
        dispatch(stopEVVLoading());
        dispatch(setMessage({ error: true, message: error.message || 'Something went wrong' }));
        return false;
    }
};
export const UnarchiveAClient = (id, page, team) => async (dispatch) => {
    try {
        await fetchWrapper.patch(`evv/client/${id}`, { archieved: false });
        dispatch(GetClients({ page: page, team }));
        dispatch(setMessage({ error: false, message: 'Client unarchived Succesfully' }));
        return true;
    } catch (error) {
        console.log(error);
        dispatch(stopEVVLoading());
        dispatch(setMessage({ error: true, message: error.message || 'Something went wrong' }));
        return false;
    }
};

export const getAllServices = () => async (dispatch) => {
    try {
        const res = await fetchWrapper.get(`evv/service?limit=50`);
        dispatch(setAllServices(res.results));
    } catch (error) {
        console.log(error);
    }
};
export const GetServices =
    ({ searchText = '', page = 1, sortBy = 'name:asc' }) =>
    async (dispatch) => {
        try {
            dispatch(startEVVLoading());
            if (page < 1) {
                page = 1;
            }
            const response = await fetchWrapper.get(
                `evv/service?limit=10&page=${page}${searchText !== '' ? `&HCPCS=${searchText}` : ''}`
            );
            dispatch(setServices(response));
            dispatch(stopEVVLoading());
        } catch (err) {
            console.log(err);
        }
    };

export const CreateAService = (newService, file, admin) => async (dispatch) => {
    try {
        dispatch(startEVVLoading());
        const response = await fetchWrapper.post(`evv/service`, newService);
        dispatch(setNewServices(response));
        dispatch(setMessage({ error: false, message: 'Service Created Succesfully' }));
        dispatch(stopEVVLoading());
        return true;
    } catch (error) {
        console.log(error);
        dispatch(stopEVVLoading());
        dispatch(setMessage({ error: true, message: error.message || 'Something went wrong' }));
        return false;
    }
};
export const EditAService = (newService, id, file, admin) => async (dispatch) => {
    try {
        const response = await fetchWrapper.put(`evv/service/${id}`, newService);
        dispatch(setNewServices(response));
        dispatch(setMessage({ error: false, message: 'Service Edited Succesfully' }));
        return true;
    } catch (error) {
        console.log(error);
        dispatch(stopEVVLoading());
        dispatch(setMessage({ error: true, message: error.message || 'Something went wrong' }));
        return false;
    }
};
export const DeleteAService = (id, page) => async (dispatch) => {
    try {
        await fetchWrapper.delete(`evv/service/${id}`);
        dispatch(GetServices({ page: page }));
        dispatch(setMessage({ error: false, message: 'Service Deleted Succesfully' }));
        return true;
    } catch (error) {
        console.log(error);
        dispatch(stopEVVLoading());
        dispatch(setMessage({ error: true, message: error.message || 'Something went wrong' }));
        return false;
    }
};

export const getAllVisits = () => async (dispatch) => {
    try {
        const res = await fetchWrapper.get(`evv/visit`);
        dispatch(setAllVisits(res));
        return true;
    } catch (error) {
        console.log(error);
        dispatch(stopEVVLoading());
        dispatch(setMessage({ error: true, message: error.message || 'Something went wrong' }));
        return false;
    }
};

export const getVisits =
    ({
        client,
        employee,
        provider,
        page = 1,
        endDate,
        startDate,
        sortBy = null,
        verified,
        visitLabelFilter,
        isManual,
        team,
        isTeamSettings = false,
    }) =>
    async (dispatch) => {
        try {
            dispatch(startEVVLoading());
            const res = await fetchWrapper.get(
                `evv/visit?page=${page}${client ? `&client=${client}` : ''}
				${team ? `&team=${team}` : ''}${employee ? `&employee=${employee}` : ''}${provider ? `&provider=${provider}` : ''}${
                    sortBy ? `&sortBy=${sortBy}` : ''
                }${endDate ? `&serviceEndAt=${endDate}` : ''}${startDate ? `&serviceCreatedAt=${startDate}` : ''}${
                    verified ? '&verified=true' : ''
                }${visitLabelFilter ? `&label=${visitLabelFilter}` : ''}${isManual ? '&manual=true' : ''}&limit=10`
            );
            if (isTeamSettings) {
                res.costInfo = null;
            }
            dispatch(setVisits(res));
            dispatch(stopEVVLoading());
            return true;
        } catch (error) {
            if (error.name === 'AbortError') {
                return;
            }
            dispatch(stopEVVLoading());
            dispatch(setMessage({ error: true, message: error.message || 'Something went wrong' }));
            return false;
        }
    };

export const getCostInfo =
    ({ client, employee, provider, endDate, startDate, verified, visitLabelFilter, isManual, team }) =>
    async (dispatch) => {
        try {
            dispatch(startEVVLoading());
            const res = await fetchWrapper.get(
                `evv/visit/costInfo?${client ? `&client=${client}` : ''}
				${team ? `&team=${team}` : ''}${employee ? `&employee=${employee}` : ''}${provider ? `&provider=${provider}` : ''}${
                    endDate ? `&serviceEndAt=${endDate}` : ''
                }${startDate ? `&serviceCreatedAt=${startDate}` : ''}${verified ? '&verified=true' : ''}${
                    visitLabelFilter ? `&label=${visitLabelFilter}` : ''
                }${isManual ? '&manual=true' : ''}`
            );
            dispatch(setVisitsCostInfo(res));
            dispatch(stopEVVLoading());
            return true;
        } catch (error) {
            if (error.name === 'AbortError') {
                return;
            }
            dispatch(stopEVVLoading());
            dispatch(setMessage({ error: true, message: error.message || 'Something went wrong' }));
            return false;
        }
    };

const setAllProviders = (allProviders) => ({
    type: ActionTypes.SET_ALL_PROVIDERS,
    payload: allProviders,
});

const setProviders = (providers) => ({
    type: ActionTypes.SET_PROVIDERS,
    payload: providers,
});

export const setNewProvider = (provider) => ({
    type: ActionTypes.SET_NEW_PROVIDER,
    payload: provider,
});

const setAllEmployees = (allEmployees) => ({
    type: ActionTypes.SET_ALL_EMPLOYEES,
    payload: allEmployees,
});

const setEmployees = (employees) => ({
    type: ActionTypes.SET_EMPLOYEES,
    payload: employees,
});

export const setNewEmployee = (employee) => ({
    type: ActionTypes.SET_NEW_EMPLOYEE,
    payload: employee,
});

export const updateEmployee = (employee) => ({
    type: ActionTypes.UPDATE_EMPLOYEE,
    payload: employee,
});

const setAllClients = (allClients) => ({
    type: ActionTypes.SET_ALL_CLIENTS,
    payload: allClients,
});

const setClients = (clients) => ({
    type: ActionTypes.SET_CLIENTS,
    payload: clients,
});

export const setNewClient = (client) => ({
    type: ActionTypes.SET_NEW_CLIENT,
    payload: client,
});

export const updateClient = (client) => ({
    type: ActionTypes.UPDATE_CLIENT,
    payload: client,
});

const setAllServices = (allServices) => ({
    type: ActionTypes.SET_ALL_SERVICES,
    payload: allServices,
});

const setServices = (service) => ({
    type: ActionTypes.SET_SERVICE,
    payload: service,
});

export const setNewServices = (service) => ({
    type: ActionTypes.SET_NEW_SERVICE,
    payload: service,
});

const startEVVLoading = () => ({
    type: ActionTypes.SET_EVV_LOADING,
});

const stopEVVLoading = () => ({
    type: ActionTypes.CLEAR_EVV_LOADING,
});

export const setMessage = (message) => ({
    type: ActionTypes.SET_SHOW_MESSAGE,
    payload: message,
});

const setAllVisits = (allVisits) => ({
    type: ActionTypes.SET_ALL_VISITS,
    payload: allVisits,
});

const setVisits = (visits) => ({
    type: ActionTypes.SET_VISITS,
    payload: visits,
});

const setVisitsCostInfo = (visitsCostInfo) => ({
    type: ActionTypes.SET_VISITS_COST_INFO,
    payload: visitsCostInfo,
});

export const updateVisits = (visit, id) => ({
    type: ActionTypes.UPDATE_VISIT,
    payload: { visit: visit, id: id },
});

export const setLabels = (labels) => ({
    type: ActionTypes.SET_LABELS,
    payload: labels,
});
