import { useState, Fragment, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { css } from 'twin.macro';
import { RenderDeductionFields } from './RenderDeductionFields';
import { useForm } from 'react-hook-form';
import { MdCancel } from 'react-icons/md';
import { BsFillPlusSquareFill } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { fetchWrapper } from '../../../../../../_helpers';

function getRandomId() {
    return `NEW_DEDUCTION_${Math.round(Math.random() * 1000)}`;
}

function prepareDataBeforeSubmit(data) {
    const preparedData = { ...data };
    const additionalFields = preparedData.additional_fields;
    const newData = {};

    newData.employee_contribution = preparedData.employee_contribution;
    newData.employer_contribution = preparedData.employer_contribution;

    if (additionalFields && Object?.keys(additionalFields)?.length > 0) {
        newData.additional_fields = {};

        for (const key in additionalFields) {
            if (Object.prototype.hasOwnProperty.call(additionalFields, key)) {
                if (typeof additionalFields[key] === 'object' && 'value' in additionalFields[key]) {
                    newData.additional_fields[key] = additionalFields[key].value;
                } else {
                    newData.additional_fields[key] = additionalFields[key];
                }
            }
        }
    }
    return newData;
}

const EmployeeNewDeductions = ({ employeeCheckID, companyID, setEmployeeDeductions, show, teamId }) => {
    const { register, handleSubmit, setValue } = useForm({});
    const [isBusy, setIsBusy] = useState(false);
    const [deductionTemplates, setDeductionTemplates] = useState([]);

    async function fetchDeductionTemplate(companyID) {
        try {
            setIsBusy(true);
            const reponse = await fetchWrapper.get(
                `evv/payroll/zeal/provider/${companyID}/deduction-template${teamId ? `?team=${teamId}` : ''}`
            );
            setDeductionTemplates(reponse || []);
        } catch (error) {
            if (error?.name === 'AbortError') return;
            console.log(error);
            toast.error(error?.message || 'Something went wrong while fetching deduction template');
        } finally {
            setIsBusy(false);
        }
    }
    useEffect(() => {
        if (companyID && show) {
            fetchDeductionTemplate(companyID);
        }
    }, [show, companyID]);

    const [deductions, setDeductions] = useState(null);
    const handleDeductionTypeChange = (deductionTemplateID) => {
        setDeductions(() => {
            const deductionFound = deductionTemplates?.find(
                (temp) => temp?.deductionTemplateID === deductionTemplateID
            );
            if (deductionFound) {
                return {
                    id: getRandomId(),
                    properties: { ...(deductionFound || {}) },
                };
            }
        });
    };

    const handleAddDeduction = () => {
        if (deductionTemplates?.length) {
            setDeductions({ id: getRandomId(), properties: deductionTemplates[0] });
        }
    };

    const handleRemoveDeduction = () => {
        setDeductions(null);
    };
    console.log('deductionTemplates', deductionTemplates);

    const onSubmit = async (data) => {
        try {
            setIsBusy(true);
            const deduction = prepareDataBeforeSubmit(data);
            const { companyID, deductionTemplateID } = deductions?.properties;
            const payload = {
                deductionTemplateID,
                deduction,
            };
            if (!companyID) {
                return toast.error('companyID is not provided.');
            }
            if (!deductionTemplateID) {
                return toast.error('deductionTemplateID is not provided.');
            }
            if (!employeeCheckID) {
                return toast.error('employeeCheckID is not provided.');
            }
            const response = await fetchWrapper.post(
                `evv/payroll/zeal/employee/${companyID}/${employeeCheckID}/deduction${teamId ? `?team=${teamId}` : ''}`,
                payload
            );
            toast.success(`Successfully created deduction for ${data?.custom_name}`);
            const customTemplateName = deductions?.properties?.custom_name?.const;
            if (response?.deductionID) {
                setEmployeeDeductions((prev) => [
                    ...prev,
                    { ...(response || {}), deduction_template_name: customTemplateName },
                ]);
            }
            setDeductions(null);
        } catch (err) {
            console.log('err', err);
            toast.error(err?.message || 'Something went wrong while creating deduction');
        } finally {
            setIsBusy(false);
        }
    };

    return (
        <Fragment>
            {deductions?.properties ? (
                <div
                    css={css`
                        border: 2px solid #17a2b8;
                        padding: 2rem;
                        margin: 2rem 0;
                        position: relative;
                    `}
                >
                    <span
                        css={css`
                            position: absolute;
                            left: 0;
                            top: 0;
                        `}
                        className="badge badge-warning"
                    >
                        New Deduction
                    </span>
                    <div
                        css={css`
                            display: flex;
                            justify-content: flex-end;
                            position: absolute;
                            right: 0;
                            top: 0;
                        `}
                    >
                        <MdCancel
                            css={css`
                                cursor: pointer;
                            `}
                            color="brown"
                            size="28px"
                            onClick={() => {
                                handleRemoveDeduction();
                            }}
                        />
                    </div>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Label>Select Custom Deduction:</Form.Label>
                        <Form.Control
                            as="select"
                            onChange={(e) => handleDeductionTypeChange(e.target.value)}
                            disabled={false}
                            css={css`
                                margin-bottom: 16px;
                            `}
                        >
                            {(deductionTemplates || [])?.map((temp, idx) => (
                                <option key={idx} value={temp?.deductionTemplateID}>
                                    {temp?.custom_name?.const}
                                </option>
                            ))}
                        </Form.Control>
                        {
                            <div
                                css={css`
                                    display: flex;
                                    flex-direction: column;
                                    width: 100%;
                                    justify-content: center;
                                `}
                                key={deductions?.id}
                            >
                                <RenderDeductionFields
                                    schema={deductions}
                                    register={register}
                                    setValue={setValue}
                                    mode="create-deduction"
                                />
                            </div>
                        }
                        <div
                            css={css`
                                display: flex;
                                justify-content: right;
                            `}
                        >
                            <Button
                                type="submit"
                                disabled={isBusy || !deductions?.properties?.deductionTemplateID}
                                css={css`
                                    margin-top: 1rem;
                                `}
                            >
                                Create
                            </Button>
                        </div>
                    </Form>
                </div>
            ) : (
                <Button type="button" onClick={() => handleAddDeduction()} disabled={isBusy}>
                    <BsFillPlusSquareFill className="mr-2" />
                    <span>Add Deduction</span>
                </Button>
            )}
        </Fragment>
    );
};

export default EmployeeNewDeductions;
