import { css } from 'twin.macro';
import { styled } from 'twin.macro';

export const PrimaryButton = styled.button`
    font-size: 1.6rem;
    color: white;
    background-color: #ea9941;
    padding: 10px 20px;
    :hover {
        color: white;
        background-color: #da8a33;
    }
`;

export const UPrimaryButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e0f0ef;
    padding: 5px 10px;
    border-radius: 5px;
    min-height: 35px;
    border: 1px solid #1b686a;
    color: #1b686a;
    cursor: pointer;
    transition: transform 200ms ease-in-out;
    &:hover {
        transform: scale(1.1);
    }

    .icon {
        color: #1b686a;
        font-size: 16px;
    }
    span {
        font-size: 14px;
    }
`;

export const UPrimaryButtonDisabled = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #efeded;
    padding: 5px 10px;
    border-radius: 5px;
    min-height: 35px;
    border: 1px solid #6c757d;
    color: #6c757d;
    cursor: default !important;

    .icon {
        color: #6c757d;
        font-size: 16px;
    }
    span {
        font-size: 14px;
    }
`;

export const bigButtons = css`
    margin: 1rem;
    font-size: 1.5rem;
    border-radius: 16px;
    width: 10rem;
    height: 4.5rem;
    border-radius: 10px;
    font-size: 2.25rem;
    letter-spacing: normal;
    font-weight: 300;
`;

export const linkButtons = css`
    margin: 1rem;
    letter-spacing: normal;
    font-size: 2rem;
    background: transparent;
    outline: none;
    border: 0;
    color: #50aeb0;
    font-weight: 400;
`;
