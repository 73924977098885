import * as ActionTypes from '../actions/ActionTypes';
import ID from '../../_helpers/uuid';

const initialState = {
    defaultSchema: {
        title: 'A registration form',
        description: 'A simple form example.',
        type: 'object',
        required: true,
        properties: {
            firstName: {
                type: 'string',
                title: 'First name',
                default: 'Chuck',
            },
            lastName: {
                type: 'string',
                title: 'Last name',
            },
        },
    },
    formElements: [],
    defaultEvvFormElements: [
        {
            fieldName: 'Header Text',
            id: ID.uuid(),
            instruction: 'Header Text',
            key: 'Header',
            type: 'textbox',
            required: true,
        },
        {
            data: [],
            fieldName: 'Services',
            id: ID.uuid(),
            instruction: 'Add Services from edit',
            key: 'ServiceRadio',
            type: 'radio',
            required: true,
        },
        {
            fieldName: 'Signature',
            id: ID.uuid(),
            key: 'Signature',
            type: 'signature',
            required: true,
        },
    ],
    defaultPtoFormElements: [
        {
            data: [],
            fieldName: 'Accrual Policy',
            id: ID.uuid(),
            key: 'AccrualPolicySelect',
            type: 'select',
            required: true,
        },
        {
            fieldName: 'Datetime Range',
            id: ID.uuid(),
            key: 'DateTimeRangePicker',
            type: 'datetimerange',
            required: true,
        },
        {
            fieldName: 'Signature',
            id: ID.uuid(),
            key: 'Signature',
            type: 'signature',
            required: true,
        },
    ],
};

export default function FormData(state = initialState, action) {
    let tempForm, index;
    switch (action.type) {
        case ActionTypes.REORDER_FORM:
            return { ...state, formElements: [...action.payload] };
        case ActionTypes.ADD_FORM_DATA:
            index = state.formElements.findIndex((form) => form.id === action.payload.id);
            if (index !== -1) {
                tempForm = [...state.formElements];
                tempForm[index] = action.payload;
                return { ...state, formElements: tempForm };
            }
            return { ...state, formElements: [...state.formElements, action.payload] };
        case ActionTypes.DELETE_FORM_ELEMENT:
            tempForm = [...state.formElements];
            index = tempForm.findIndex((form) => form.id === action.payload);
            tempForm.splice(index, 1);
            return { ...state, formElements: tempForm };
        case ActionTypes.REMOVE_fORM:
            return { ...state, formElements: [] };
        case ActionTypes.SET_EVV_DEFAULT:
            return { ...state, formElements: state.defaultEvvFormElements };
        case ActionTypes.SET_PTO_DEFAULT:
            return { ...state, formElements: state.defaultPtoFormElements };

        default:
            return state;
    }
}
