import * as ActionTypes from '../actions/ActionTypes';

const initialState = {
	conferenceCallInvitation: null,
	conferenceData: {}
};

export default function conference(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.SET_CONFERENCE_CALL_INVITATION:
			return { ...state, conferenceCallInvitation: action.payload };
		case ActionTypes.CLEAR_CONFERENCE_CALL_INVITATION:
			return { ...state, conferenceCallInvitation: null };
		// case ActionTypes.SET_CONFERENCE_ROOM:
		// 	tempConferenceRooms = { ...state.conferenceData };
		// 	tempConferenceRooms[action.payload.room_id] = {
		// 		...tempConferenceRooms[action.payload.room_id],
		// 		conference_id: action.payload.conference_id,
		// 	};
		// 	if (action.payload.window) {
		// 		tempConferenceRooms[action.payload.room_id].window = action.payload.window;
		// 	}
		// 	return { ...state, conferenceData: tempConferenceRooms };
		// case ActionTypes.SET_CONFERENCE_TEAM:
		// 	let tempConferenceTeam = { ...state.conference_teams };
		// 	tempConferenceTeam[action.payload.team_id] = action.payload.conference_state;
		// 	return { ...state, conference_teams: tempConferenceTeam };
		case ActionTypes.SET_CONFERENCE_DATA:
			return {
				...state, conferenceData: { ...action.payload, window: state.conferenceData?.window || action.payload.window }
			}
		case ActionTypes.START_CONFERENCE:
			const conferenceData = state.conferenceData;
			if (!conferenceData) {
				return state;
			}
			if (conferenceData.window && !conferenceData.window.closed) {
				conferenceData.window.focus();
				return state;
			}
			const newWindow = window.open(`/meet/${conferenceData.conferenceId}`);
			return {
				...state, conferenceData: {
					...state.conferenceData,
					window: newWindow
				}
			};
		default:
			return state;
	}
}
