import React, { Fragment, useState } from 'react';
import { css } from 'twin.macro';
import { Form } from 'react-bootstrap';

const FIELDS_TO_HIDE = ['companyID', 'deductionTemplateID', 'employees'];
function isEmptyObj(obj) {
    if (!obj) {
        return false;
    }
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
            return false;
        }
    }
    return true;
}

function getFormLabel(name) {
    const [initial, ...other] = (name || 'Unnamed Field')
        .replace(/([a-z0-0]_[a-z0-9])/g, (v) => `${v[0]} ${v[2].toUpperCase()}`)
        .replaceAll('_', ' ')
        .split('');
    return initial.toUpperCase() + other.join('').replaceAll(/([a-z])([A-Z])/g, '$1 $2');
}

export function RequiredStar() {
    return (
        <span
            css={css`
                color: red;
                margin-left: 3px;
            `}
        >
            *
        </span>
    );
}

function mapWordToSymbol(word) {
    switch (word) {
        case 'dollars':
            return '$';
        case 'percentage':
            return '%';
        default:
            return null;
    }
}

const RenderFields = ({
    caption = '',
    value,
    dataType = 'string',
    fieldValues,
    register,
    hide,
    handleChange,
    symbolOnRight,
    fullKey,
}) => {
    return (
        <Form.Group
            key={fullKey}
            css={css`
                display: ${hide ? 'none' : 'block'};
            `}
        >
            <Form.Label>
                {getFormLabel(caption)}
                <RequiredStar />
            </Form.Label>
            <div
                css={css`
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                `}
            >
                <Form.Control
                    required={true}
                    onChange={handleChange}
                    value={fieldValues?.hasOwnProperty(fullKey) ? fieldValues[fullKey] : value}
                    type={dataType}
                    name={fullKey}
                    ref={register}
                    onWheel={(event) => event.currentTarget.blur()}
                />
                {symbolOnRight ? (
                    <span
                        css={css`
                            padding: 0 16px;
                            font-size: 24px;
                            background-color: whitesmoke;
                            border: 1px solid gray;
                        `}
                    >
                        {symbolOnRight}
                    </span>
                ) : null}
            </div>
        </Form.Group>
    );
};

export const RenderDeductionFields = ({
    schema,
    parentKey = '',
    register,
    setValue,
    parentFieldKey,
    parentProperties,
    mode,
}) => {
    const [fieldValues, setFieldValues] = useState({});
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFieldValues({ ...fieldValues, [name]: value });
    };

    if (!schema) {
        return null;
    }
    if (mode === 'edit-deduction') {
        const fieldsToRender = [];
        const employee_contribution = schema?.employee_contribution;
        const employer_contribution = schema?.employer_contribution;
        const additional_fields = schema?.additional_fields;

        if (!isEmptyObj(employee_contribution)) {
            fieldsToRender.push(
                <RenderFields
                    key={'employee_contribution_value'}
                    caption={'employee_contribution_value'}
                    fullKey={'employee_contribution.value'}
                    register={register}
                    value={employee_contribution?.value}
                    dataType={'number'}
                    handleChange={handleChange}
                    fieldValues={fieldValues}
                    symbolOnRight={mapWordToSymbol(employee_contribution?.contribution_type)}
                />
            );
        }
        if (!isEmptyObj(employer_contribution)) {
            fieldsToRender.push(
                <RenderFields
                    key={'employer_contribution_value'}
                    caption={'employer_contribution_value'}
                    fullKey={'employer_contribution.value'}
                    register={register}
                    value={employer_contribution?.value}
                    dataType={'number'}
                    handleChange={handleChange}
                    fieldValues={fieldValues}
                    symbolOnRight={mapWordToSymbol(employer_contribution?.contribution_type)}
                />
            );
        }
        if (!isEmptyObj(additional_fields)) {
            const order_number = additional_fields?.order_number;
            const case_id = additional_fields?.case_id;
            const agency = additional_fields?.agency;
            const hsaType = additional_fields?.hsa_type;
            if (order_number?.toString()) {
                fieldsToRender.push(
                    <RenderFields
                        key={'order_number'}
                        caption={'order_number'}
                        fullKey={'additional_fields.order_number'}
                        register={register}
                        value={order_number?.value}
                        handleChange={handleChange}
                        fieldValues={fieldValues}
                    />
                );
            }
            if (case_id?.toString()) {
                fieldsToRender.push(
                    <RenderFields
                        key={'case_id'}
                        caption={'case_id'}
                        fullKey={'additional_fields.case_id'}
                        register={register}
                        value={case_id?.value}
                        handleChange={handleChange}
                        fieldValues={fieldValues}
                    />
                );
            }
            if (agency) {
                const address = agency?.address;
                const name = agency?.name;
                fieldsToRender.push(
                    <RenderFields
                        key={'agency_address'}
                        caption={'agency_address'}
                        fullKey={'additional_fields.agency.address'}
                        register={register}
                        value={address}
                        handleChange={handleChange}
                        fieldValues={fieldValues}
                    />
                );
                fieldsToRender.push(
                    <RenderFields
                        key={'agency_name'}
                        caption={'agency_name'}
                        fullKey={'additional_fields.agency.name'}
                        register={register}
                        value={name}
                        handleChange={handleChange}
                        fieldValues={fieldValues}
                    />
                );
            }
            if (hsaType) {
                fieldsToRender.push(
                    <div
                        key={'hsa-type'}
                        disabled={true}
                        css={css`
                            border: 1px solid gray;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            background-color: whitesmoke;
                        `}
                    >
                        <span
                            css={css`
                                margin: 0 1rem;
                            `}
                        >
                            HSA Type
                        </span>
                        <span
                            css={css`
                                padding: 0 16px;
                                font-size: 24px;
                                background-color: whitesmoke;
                                border: 1px solid gray;
                            `}
                        >
                            {hsaType}
                        </span>
                    </div>
                );
            }
        }
        return fieldsToRender;
    }

    return Object?.keys(schema?.properties)?.map((key) => {
        const property = schema?.properties[key];
        const fullKey = parentKey ? `${parentKey}.${key}` : key;
        const required = true;
        const readOnly = property?.const;
        const dataType = property?.type;
        const hideChildrenFields = FIELDS_TO_HIDE.includes(fullKey?.split('.')[0]);
        const symbolOnRight = mapWordToSymbol(parentProperties?.properties?.contribution_type?.const);

        if (key === 'required_template_fields' || key === 'override_type') {
            // this field should not be posted to zeal, so hide
            // Docs:: With the exception of required_template_fields (more on this later), all keys of a properties field directly translate to fields that may be included in the body of your POST request to the Create a Deduction Template endpoint.
            return null;
        }

        if (hideChildrenFields) {
            // hide this field but keep the data
            return (
                <Form.Group
                    key={fullKey}
                    css={css`
                        display: none;
                    `}
                >
                    <Form.Control
                        value={schema?.properties[key]}
                        type={dataType}
                        name={fullKey}
                        ref={register}
                        readOnly={true}
                    />
                </Form.Group>
            );
        }

        if (property?.type === 'object') {
            return (
                <div
                    css={css`
                        display: ${hideChildrenFields ? 'none' : 'block'};
                    `}
                    key={fullKey}
                >
                    <RenderDeductionFields
                        schema={property}
                        parentKey={fullKey}
                        register={register}
                        parentFieldKey={key}
                        parentProperties={property}
                        mode={mode}
                    />
                </div>
            );
        }

        return (
            <Form.Group
                key={fullKey}
                css={css`
                    display: ${readOnly || hideChildrenFields ? 'none' : 'block'};
                `}
            >
                <Form.Label>
                    {parentFieldKey ? getFormLabel(parentFieldKey) : null} {getFormLabel(key)}
                    {required ? <RequiredStar /> : null}
                </Form.Label>
                <div
                    css={css`
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                    `}
                >
                    <Form.Control
                        required={required}
                        onChange={handleChange}
                        value={
                            readOnly
                                ? readOnly
                                : fieldValues?.hasOwnProperty(fullKey)
                                  ? fieldValues[fullKey]
                                  : property?.default
                        }
                        type={dataType}
                        name={fullKey}
                        ref={register}
                        readOnly={readOnly}
                        onWheel={(event) => event.currentTarget.blur()}
                    />
                    {symbolOnRight ? (
                        <span
                            css={css`
                                padding: 0 16px;
                                font-size: 24px;
                                background-color: whitesmoke;
                                border: 1px solid gray;
                            `}
                        >
                            {symbolOnRight}
                        </span>
                    ) : null}
                </div>
            </Form.Group>
        );
    });
};
