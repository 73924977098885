import { css } from 'twin.macro'
export const pagination = css`
	background-color: #E0F0EF;
	text-align: center;
	cursor: pointer;
	min-width: 40px;
	min-height:40px;
	padding: 2px;
	border: 1px solid #50AEB0;
    border-radius:50%;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	margin: 1.5px;
    color: #50AEB0;
	display: flex;
	align-items: center;
	justify-content: center;
	&:hover {
		background-color: white;
	}
	a {
		color: #50AEB0;
		&:hover {
			text-decoration: none;
		}
	}
`;

export const arrows = css`
	background-color: #E0F0EF;
	text-align: center;
	min-width: 40px;
	min-height:40px;
	padding: 2px;
	border: 1px solid #50AEB0;
    border-radius:50%;
	box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
	margin: 1.5px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	min-height: 40px;
	&:hover {
		background-color: white;
	}
	.icon {
		color: #50AEB0;
	}
`;

export const paginationActive = css`
	background-color: #50AEB0;
	color: white;
	cursor: pointer;
	text-align: center;
	min-width: 40px;
	min-height:40px;
	padding: 2px;
	border: 1px solid #50AEB0;
    border-radius:50%;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	margin: 1px;

	display: flex;
	align-items: center;
	justify-content: center;
	a {
		color: white;
		&:hover {
			text-decoration: none;
		}
	}
`;