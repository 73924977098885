import styled from '@emotion/styled';
import { Form } from 'react-bootstrap';

export const Overlay = styled('div')`
	position: relative;
	height: 100px;
	width: 100px;
	border-radius: 50%;
	background-color: grey;
	cursor: pointer;
	overflow: hidden;
	:hover .initials {
		filter: brightness(0.5);
	}
	& > .fa-edit {
		position: absolute;
		display: none;
		top: 30px;
		left: 30px;
		color: white;
		font-size: 40px;
	}

	:hover .fa-edit {
		display: block;
	}

	& > .teamImg {
		height: 100px;
		width: 100px;
		border-radius: 50%;
		position: absolute;
		top: 0;
		left: 0;
	}
	& > .initials {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		height: 100px;
		width: 100px;
		border-radius: 50%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		align-self: center;
		color: white;
		font-size: 30px;
		font-weight: lighter;
	}
`;

export const Body = styled('div')`
	padding: 10px;
	display: flex;
	flex-direction: column;
	border-radius: 20px;
	& > .header {
		background-color: #c3e4e2;
		padding: 20px 20px 60px 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
	}
	& > .imgs {
		margin-top: -50px;
	}
`;

export const StyledForm = styled(Form)`
	display: flex;
	flex-direction: column;
`;

export const EditImgStyled = styled('div')`
	height: 100px;
	width: 100px;
	border-radius: 50%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	align-self: center;
    object-fit: cover;
`;
