const backendUrl = process.env.REACT_APP_BACKEND_URL;

const nonAuthRoutes = ["register", "login", "report_issues", "reset-password", "force-password-change", "force-username", "force-phonenumber", "create-your-team"];

export async function jwtCheck(reloadOnError = false) {
	try {
		const user = JSON.parse(localStorage.getItem('user'));

		const d = new Date();
		if (user?.expires > d.toISOString()) return user.token;

		let userRefresh = JSON.parse(localStorage.getItem('userRefresh'));

		if (userRefresh?.expires <= d.toISOString()) {
			throw new Error("Token Expired")
		}

		if (!userRefresh?.token) {
			throw new Error("Refresh Token not Found")
		}
		//fetch new token
		const accessToken = await getToken({
			refreshToken: userRefresh?.token,
		});

		return accessToken?.token;
	} catch (error) {
		console.log("JWT ERROR: ", error)
		if (!reloadOnError) {
			return;
		}
		const path = new URL(window.location.href).pathname?.replaceAll("/", "");
		if (path && !nonAuthRoutes.includes(path)) {
			localStorage.clear();
			window.location.reload(true);
		}
	}
}

const getToken = async (refreshToken) => {
	let response = await fetch(backendUrl + '/auth/refresh-tokens', {
		method: 'POST',
		body: JSON.stringify(refreshToken),
		headers: {
			'Content-Type': 'application/json',
		},
		credentials: 'same-origin',
	});
	if (!response.ok) {
		var error = new Error('Error ' + response.status + ': ' + response.statusText);
		error.response = response;
		throw error;
	}
	let tokens = await response.json();
	setToken(tokens.access, tokens.refresh);
	return tokens.access;
};

export const setToken = (accessToken, refreshToken) => {
	localStorage.setItem('user', JSON.stringify(accessToken));
	localStorage.setItem('userRefresh', JSON.stringify(refreshToken));
};
