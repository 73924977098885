import { css } from '@emotion/react';
import { GoogleApiWrapper, InfoWindow, Map, Marker, Polyline } from 'google-maps-react';
import { formatDate, getMapCenterAndZoom, toastWrapper } from '../../../../_helpers';
import { useEffect, useState, useRef } from 'react';
import { FaCar, FaClock, FaHourglassEnd, FaWpforms } from 'react-icons/fa';
import redMarker from '../../../../../src/assets/markers/red.png';
import yellowMarker from '../../../../../src/assets/markers/yellow.png';
import greenMarker from '../../../../../src/assets/markers/green.png';

function VisitMap({ forms, activeFormInMarker, mileageData, ...props }) {
    const formsRef = useRef([]);
    const directionsService = new props.google.maps.DirectionsService();
    const locations = forms
        .map((form) => {
            const [lng, lat] = form.location?.coordinates || [];
            return { lat, lng, form };
        })
        ?.filter(({ lat, lng }) => lat?.toString() && lng?.toString());

    const { center, zoom } = getMapCenterAndZoom(props.google, locations, 12);

    const [directions, setDirections] = useState([]);

    const [activeMarker, setActiveMarker] = useState();

    useEffect(() => {
        if (locations.length > 1 && !mileageData?.polyline) {
            getAndSetDirections(locations);
        }
    }, []);

    useEffect(() => {
        const desiredForm = (forms || [])?.find((form) => form?._id === activeFormInMarker);
        const formMarker = (formsRef.current || [])?.find(
            (markerRef) => markerRef?.props?.form?._id === activeFormInMarker
        );
        if (formMarker?.marker && desiredForm?.location?.coordinates?.length) {
            const [lng, lat] = desiredForm?.location?.coordinates || [];
            handleMarkerToggle(formMarker.marker, { lat, lng });
        } else {
            setActiveMarker();
        }
    }, [activeFormInMarker, forms]);

    async function getAndSetDirections(locations) {
        try {
            const points = Array.from(new Set(locations.map(({ lat, lng }) => `${lat},${lng}`)));
            if (points?.length < 2) {
                return;
            }

            directionsService.route(
                {
                    origin: points[0],
                    destination: points.at(-1),
                    ...(points.length > 2 ? { waypoints: points.slice(0, -1).map((location) => ({ location })) } : {}),
                    travelMode: props.google.maps.TravelMode.DRIVING,
                    unitSystem: props.google.maps.UnitSystem.IMPERIAL,
                },
                function (response, status) {
                    if (status !== 'OK') {
                        return toastWrapper.error('Directions request failed due to ' + status);
                    }
                    const directions = response.routes[0].legs.map((leg) => ({
                        distance: leg.distance,
                        duration: leg.duration,
                        locations: [leg.start_location, ...leg.steps.map((step) => step.end_location)],
                        points: leg.steps.map((step) => step.path).flat(),
                    }));
                    setDirections(directions);
                }
            );
        } catch (error) {
            console.log('ERROR', error);
            toastWrapper.error("Couldn't fetch directions");
        }
    }

    const handleMarkerToggle = (marker, pos = {}) => {
        setActiveMarker({
            marker,
            pos,
        });
    };

    function getMarker(form) {
        try {
            return form?.type === 'sos' ? greenMarker : form?.type === 'eos' ? redMarker : yellowMarker;
        } catch (error) {
            return undefined;
        }
    }

    return (
        <div
            css={css`
                position: relative;
                height: 400px;
                width: 100%;
                padding: 3rem 0;
            `}
        >
            <div
                className="d-flex p-2"
                css={css`
                    gap: 12px;
                    margin: 1rem 0;
                    justify-content: center;
                `}
            >
                <span
                    className="d-flex align-items-center badge badge-primary"
                    css={css`
                        gap: 8px;
                        font-size: 16px;
                    `}
                >
                    <FaCar />
                    {(
                        (mileageData?.distance ||
                            directions?.map(({ distance = {} }) => distance?.value || 0)?.reduce((a, b) => a + b, 0)) /
                        1609.34
                    ).toFixed(2)}{' '}
                    Miles
                </span>
                <span
                    className="d-flex align-items-center badge badge-primary"
                    css={css`
                        gap: 8px;
                        font-size: 16px;
                    `}
                >
                    <FaHourglassEnd />
                    {Math.round(
                        (mileageData?.duration ||
                            directions?.map(({ duration = {} }) => duration?.value || 0)?.reduce((a, b) => a + b, 0)) /
                            60
                    )}{' '}
                    Minutes
                </span>
            </div>
            <Map {...props} initialCenter={center} zoom={zoom}>
                {locations.map(({ lat, lng, form }, index) => (
                    <Marker
                        position={{ lat, lng }}
                        key={index}
                        form={form}
                        onClick={(_, marker) => {
                            handleMarkerToggle(marker, { lat, lng });
                        }}
                        ref={(el) => (formsRef.current[index] = el)}
                        icon={{
                            url: getMarker(form),
                            scaledSize: new props.google.maps.Size(45, 45),
                        }}
                    ></Marker>
                ))}
                {mileageData?.polyline ? (
                    <Polyline
                        path={props.google?.maps?.geometry?.encoding?.decodePath(mileageData.polyline)}
                        // path={direction.points}
                        // strokeColor={getRandomColor("3d9".at(index % 3))}
                        strokeColor={'#0f53ff'}
                        strokeOpacity={0.8}
                        strokeWeight={6}
                    />
                ) : (
                    directions?.map((direction, index) => (
                        <Polyline
                            key={index}
                            path={direction.points}
                            // strokeColor={getRandomColor("3d9".at(index % 3))}
                            strokeColor={'#0f53ff'}
                            strokeOpacity={0.8}
                            strokeWeight={6}
                        />
                    ))
                )}
                <InfoWindow
                    visible={!!activeMarker?.marker}
                    position={activeMarker?.pos}
                    options={{ pixelOffset: new window.google.maps.Size(0, -40) }}
                >
                    <div className="p-2">
                        <p
                            className="d-flex align-items-center"
                            css={css`
                                gap: 8px;
                                font-size: 16px;
                            `}
                        >
                            <FaWpforms className="text-info" /> {activeMarker?.marker?.form?.name || 'Unnamed Form'}{' '}
                            <span className="badge badge-sm badge-info">{activeMarker?.marker?.form?.type}</span>
                        </p>
                        <p
                            className="d-flex align-items-center"
                            css={css`
                                gap: 8px;
                                font-size: 16px;
                            `}
                        >
                            <FaClock className="text-info" />{' '}
                            {formatDate(new Date(activeMarker?.marker?.form?.createdAt || 0), true, true)}
                        </p>
                    </div>
                </InfoWindow>
            </Map>
        </div>
    );
}

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    // LoadingContainer: LoadingContainer,
})(VisitMap);
