import { css } from 'twin.macro';
import Select from 'react-select';
import React, { Fragment, useEffect, useState } from 'react';
import { fetchWrapper, getStandardDate } from '../../../../../../../_helpers';
import DownloadReport from '../DownloadReport';
import { VscLoading } from 'react-icons/vsc';

const CashRequirements = ({ companyID, slug }) => {
    const [downloadLink, setDownloadLink] = useState('');
    const handleDownloadLink = (link) => setDownloadLink(link);
    const [isBusy, setIsBusy] = useState(false);
    const [checkDates, setCheckDates] = useState([]);
    const [selectedCheckDate, setSelectedCheckDate] = useState({});
    const fetchCheckDates = async () => {
        try {
            setIsBusy(true);
            const response = await fetchWrapper.get(
                `evv/payroll/zeal/provider/${companyID}/reports/cash-requirements/check-dates`
            );
            setCheckDates((response || []).map((item) => ({ ...item, label: getStandardDate(item?.label) })));
        } catch (err) {
            if (err.name === 'AbortError') {
                return;
            }
            console.log(err);
        } finally {
            setIsBusy(false);
        }
    };

    const generateLink = async () => {
        const response = await fetchWrapper.get(
            `evv/payroll/zeal/provider/${companyID}/reports/${slug}?checkDate=${selectedCheckDate.value}`
        );
        handleDownloadLink(response.url);
    };

    useEffect(() => {
        if (companyID) {
            fetchCheckDates();
        }
    }, []);

    useEffect(() => {
        handleDownloadLink('');
    }, [selectedCheckDate.value]);

    return (
        <Fragment>
            <div
                css={css`
                    width: 300px;
                `}
            >
                <span>Check date: </span>
                {isBusy ? (
                    <p>
                        <VscLoading className="spin" /> Loading CheckDates...
                    </p>
                ) : (
                    <Select
                        isDisabled={isBusy}
                        value={selectedCheckDate?.value ? selectedCheckDate : null}
                        onChange={(option) => setSelectedCheckDate(option)}
                        options={checkDates.map((option) => ({ label: option?.label, value: option?.label }))}
                    />
                )}
            </div>
            <hr />
            <DownloadReport
                disabled={!selectedCheckDate.value}
                onGenerateLink={generateLink}
                downloadLink={downloadLink}
                companyID={companyID}
                slug={slug}
            />
        </Fragment>
    );
};

export default CashRequirements;
