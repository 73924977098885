import React from 'react';
import { css } from 'twin.macro';

// styles
import { ActionStyles, AdminTable } from '../../reusableStyles';
import AdminTablesImg from '../../reusableComponents/AdminTablesImg';
import Loader from '../../containers/Loader';
import { connect } from 'react-redux';

import { getAllUser } from '../../../../_redux/actions/ActionUser';
import { ArchiveAClient, UnarchiveAClient } from '../../../../_redux/actions/ActionEVV';

import EditClient from './EditClient';
import ViewClient from './ViewClient';

const UserClientList = ({
    allUsers,
    list,
    isLoading,
    handleSort,
    currentPage,
    search,
    setClientState,
    ArchiveAClient,
    UnarchiveAClient,
    clientState,
    team,
    isTeamSettings,
}) => {
    return (
        <>
            <AdminTable className="w-100 text-dark-grey">
                <thead>
                    <tr>
                        <th>Avatar</th>
                        <th className="text-center">Name</th>
                        <th className="text-center">Provider</th>
                        <th
                            onClick={() => handleSort('createdAt')}
                            className="pointer costume-tooltip"
                            data-tooltip="sort by created date"
                        >
                            Created At
                        </th>
                        <th className="text-center">Units</th>
                        <th className="text-center">Action</th>
                    </tr>
                </thead>
                <tbody className="text-light-grey">
                    {isLoading ? (
                        <tr
                            css={css`
                                border: none !important;
                            `}
                        >
                            <td colSpan={5}>
                                <Loader size="sm" top={400} className="notAbs" />
                            </td>
                        </tr>
                    ) : list && list.length ? (
                        list.map((client) => {
                            let createdDate = client.createdAt.split('T')[0].split('-');
                            let mmddyyyyFormatedDate = createdDate[1] + '/' + createdDate[2] + '/' + createdDate[0];
                            return (
                                <tr key={client.id}>
                                    <td style={{ width: '100px' }}>
                                        <AdminTablesImg item={client} />
                                    </td>
                                    <td className="text-center">
                                        {client.firstName} {client.lastName}
                                    </td>
                                    <td className="text-center">
                                        {client?.provider?.agencyName ? client?.provider?.agencyName : 'none'}
                                    </td>
                                    <td>{mmddyyyyFormatedDate}</td>
                                    <td className="text-center">{client?.availableUnits ?? '-'}</td>
                                    <td className="action">
                                        <ActionStyles>
                                            <ViewClient client={client} isTeamSettings={isTeamSettings} team={team} />
                                            {clientState !== 'archieved' ? (
                                                <EditClient
                                                    client={client}
                                                    isTeamSettings={isTeamSettings}
                                                    team={team}
                                                />
                                            ) : null}
                                        </ActionStyles>
                                    </td>
                                    <td className="verification-status"></td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan={6}>
                                <div
                                    className="w-100 h-100 d-flex flex-row justify-content-center align-items-center"
                                    css={css`
                                        margin-top: 50px;
                                        width: 100%;
                                    `}
                                >
                                    No Client Found
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </AdminTable>
        </>
    );
};

const mapStateToProps = (state) => ({
    allUsers: state.user,
});
export default connect(mapStateToProps, { getAllUser, ArchiveAClient, UnarchiveAClient })(UserClientList);
