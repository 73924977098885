import * as ActionTypes from '../actions/ActionTypes';
import { jwtCheck } from '../../_helpers';

const initialState = {
	isLogged: jwtCheck() ? true : false,
	isLoading: false,
	user: null,
	allUser: [],
	chatableUser: [],
	totalChatableUser: 0,
	totalUsers: 0,
	position: null,
};

export default function User(state = initialState, action) {
	let index;
	switch (action.type) {
		case ActionTypes.SET_ALL_USER:
			return { ...state, allUser: action.payload.results, totalUsers: action.payload.totalResults };
		case ActionTypes.SET_CHATABLE_USER:
			const existingUserIds = new Set(state?.chatableUser?.map(user => user?.id || user?._id))
			const uniqueNewUsers = action?.payload?.results?.filter(user => !existingUserIds.has(user?.id || user?._id))
			return {
				...state,
				chatableUser: [...state.chatableUser, ...uniqueNewUsers],
				totalChatableUser: action.payload.totalResults,
			};
		case ActionTypes.CLEAR_CHATABLE_USER:
			return { ...state, chatableUser: [], totalChatableUser: 0 };
		case ActionTypes.SET_NEW_USER:
			index = state.allUser.findIndex((user) => user.id === action.payload.id);
			if (index !== -1) {
				const tempUser = [...state.allUser];
				tempUser[index] = { ...tempUser[index], ...action.payload };
				return { ...state, allUser: tempUser };
			}
			return { ...state, allUser: [action.payload, ...state.allUser] };
		case ActionTypes.ARCHIVE_USER:
			index = state.allUser.findIndex((user) => user.id === action.payload);
			const tempUser = [...state.allUser];
			tempUser[index] = {
				...tempUser[index],
				status: 'archive',
			};
			return { ...state, allUser: tempUser, totalUsers: state.totalUsers - 1 };
		case ActionTypes.SET_USER_LOADING:
			return { ...state, isLoading: true };
		case ActionTypes.CLEAR_USER_LOADING:
			return { ...state, isLoading: false };
		case ActionTypes.SET_USER:
			let newTempUser = { ...state.user, ...action.payload };
			return {
				...state,
				user: newTempUser,
				isLogged: true,
			};
		case ActionTypes.SET_USER_ROOMS:
			const ourTempUser = Object.assign({}, state.user);
			ourTempUser.rooms = ourTempUser.rooms.concat(action.payload);
			return { ...state, user: ourTempUser };
		case ActionTypes.SET_LOGGEDIN:
			return {
				...state,
				isLogged: true,
			};
		case ActionTypes.SET_LOGGEDOUT:
			return { ...state, isLogged: false, user: null, allUser: [] };
		case ActionTypes.SET_POSITION:
			return { ...state, position: action.payload };
		case ActionTypes.SET_ADDRESS:
			return { ...state, address: action.payload };
		case ActionTypes.SET_POS_ERROR:
			return { ...state, errorMessage: action.payload };
		case ActionTypes.SET_LAST_SEEN_CHANNEL:
			let newUser = Object.assign({}, state.user);
			newUser.lastSeenChannel = action.payload;
			return { ...state, user: newUser };
		case ActionTypes.SET_LAST_SEEN_SUB_CHANNEL:
			let updatedUserSubChannelState = Object.assign({}, state.user);
			updatedUserSubChannelState.lastSeenSubChannel = action.payload;
			return { ...state, user: updatedUserSubChannelState };
		case ActionTypes.CLEAR_ACTIVITIES:
		case ActionTypes.MARK_ALL_AS_READ_ACTIVITIES:
			let newclearActivitiesTempUser = { ...state.user, unreadActivity: 0 };
			return {
				...state,
				user: newclearActivitiesTempUser,
			};
		case ActionTypes.MARK_AS_READ_ACTIVITY:
			let tempUnreadActivity = state.user.unreadActivity;
			if (tempUnreadActivity > 0) {
				tempUnreadActivity--;
			}
			let newreadActivityTempUser = { ...state.user, unreadActivity: tempUnreadActivity };
			return {
				...state,
				user: newreadActivityTempUser,
			};
		case ActionTypes.SET_NEW_ACTIVITY:
			let newsetActivityUser = { ...state.user, unreadActivity: (state?.user?.unreadActivity || 0) + 1 };
			return {
				...state,
				user: newsetActivityUser,
			};
		case ActionTypes.REMOVE_USER_CHANNEL:
			console.log('REMOVE_USER_CHANNEL::',state)
			// return {...state}
			return {
				...state,
				user:{
					...state?.user,
					roomList:state?.user?.roomList.filter((item)=>item?.room?._id !== action?.payload)
				}
			}
		case ActionTypes.REMOVE_USER_TEAM:
			return {
				...state,
				user:{
					...state?.user,
					teamList:state?.user?.teamList.filter((item)=>item?.team?._id !== action?.payload)
				}
			}
		default:
			return state;
	}
}
