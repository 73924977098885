export const getRole = (user, team) => {
    try {
        if (user.role === 'admin' || user.role === 'manager') {
            return user.role;
        } else if (
            user.id === team?.admin ||
            user?.teamList?.find((userTeam) => userTeam.team?.id === team.id)?.role === 'owner'
        ) {
            return 'owner';
        } else if (user?.teamList?.find((userTeam) => userTeam.team?.id === team.id)?.role === 'teamManager') {
            return 'teamManager';
        } else {
            return 'user';
        }
    } catch (error) {
        // console.log(error);
    }
};
