import { css } from 'twin.macro';
export const createYourTeamDiv = css`
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	.TeamDiv {
		padding: 5%;
		background: #e6e6e6;
		border-radius: 15px;
		width: 50%;
	}
	.text-lead {
		font-size: 1.6vmin;
		color: #5a5a5a;
		font-style: italic;
	}
`;

export const editBtn = css`
	img {
		-webkit-filter: grayscale(0) blur(0);
		filter: grayscale(0) blur(0);
		-webkit-transition: 0.3s ease-in-out;
		transition: 0.3s ease-in-out;
		cursor: pointer;
	}
	.edit:hover > i {
		opacity: 1 !important;
	}
	.edit:hover > img {
		-webkit-filter: grayscale(100%) blur(3px);
		filter: grayscale(100%) blur(3px);
	}
`;

export const editStyle = css`
        width:12rem;
        height:12rem;
        border-radius:50%;
        object-fit:cover;
        object-position:center;
    `

export const editIcon = css`
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%, -50%);
        z-index:1;
        font-size:3rem;
        opacity:0;
        cursor:pointer;
    `