import * as ActionTypes from '../actions/ActionTypes';
import { getUniqueObjects } from '../../_helpers';

export default function Tasks(state = { tasklist: [], totalTasks: 0, isLoading: false }, action) {
	let newTaskList = [];
	switch (action.type) {
		case ActionTypes.START_TASK_LOADING:
			return { ...state, isLoading: true }
		case ActionTypes.STOP_TASK_LOADING:
			return { ...state, isLoading: false }
		case ActionTypes.SET_TASKLIST:
			newTaskList = action.payload.replaceOld ? action.payload.results || [] : getUniqueObjects(state.tasklist, action.payload.results);
			return {
				...state,
				tasklist: newTaskList,
				totalTasks: action.payload.totalResults || newTaskList.length,
			};
		case ActionTypes.SET_NEW_TASK:
		case ActionTypes.SET_EDIT_TASK:
			const id = action.payload.id || action.payload._id;
			newTaskList = getUniqueObjects([action.payload || {}], state.tasklist || []);
			return {
				...state,
				tasklist: newTaskList.map((task) => (task._id === id ? { ...task, ...action.payload } : task)),
				totalTasks: state.totalTasks + !state.tasklist?.some((task) => task._id === id)
			};
		case ActionTypes.DELETE_THE_TASK:
			return { ...state, tasklist: state.tasklist.filter((task) => (task._id) !== action.payload), totalTasks: state.totalResults - 1 };
		default:
			return state;
	}
}
