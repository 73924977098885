import React, { useState } from 'react';
import { css } from 'twin.macro';
import { Form } from 'react-bootstrap';
import { FaChevronCircleDown, FaChevronCircleUp } from 'react-icons/fa';

function getFormLabel(name) {
    const [initial, ...other] = (name || 'Unnamed Field').replaceAll('_', ' ').split('');
    return initial.toUpperCase() + other.join('').replaceAll(/([a-z])([A-Z])/g, '$1 $2');
}

function ZealFormBuilder({ formStructure, register, setValue, errors }) {
    return (
        <React.Fragment>
            {Object.entries(formStructure).map(([key, formData], index) => (
                <div>
                    <ZealFormSubGroup
                        state={key}
                        key={key}
                        formStructure={formData}
                        register={register}
                        setValue={setValue}
                        errors={errors}
                    />
                </div>
            ))}
        </React.Fragment>
    );
}

function ZealFormSubGroup({ state, setValue, register, errors, formStructure }) {
    const [show, setShow] = useState(false);

    return (
        <React.Fragment>
            <div
                css={css`
                    display: flex;
                    margin: 18px 0 0;
                    justify-content: space-between;
                    align-items: center;
                    cursor: pointer;
                `}
                onClick={() => {
                    setShow((prev) => !prev);
                }}
            >
                <div
                    css={css`
                        font-size: large;
                    `}
                >
                    Jurisdiction: {state}
                </div>
                {show ? (
                    <FaChevronCircleUp className="text-primary" />
                ) : (
                    <FaChevronCircleDown className="text-primary" />
                )}
            </div>
            <hr />
            {(formStructure ?? []).map((formData, idx) => (
                <div key={`${formData?.code}_${idx}`} style={{ display: show ? 'block' : 'none' }}>
                    <ZealFormComponent
                        formData={{
                            ...formData,
                            ...(formData.constraints || [])[0],
                            name: `${state}.${formData?.code}`,
                            caption: formData.label ?? formData.code,
                        }}
                        defaultValues={(formData?.defaults || [])[0]?.value}
                        register={register}
                        setValue={setValue}
                        errors={errors}
                    />
                </div>
            ))}
        </React.Fragment>
    );
}

function ZealFormComponent({ formData, setValue, register, disabled, defaultValues, errors }) {
    // checkbox
    if (formData.type === 'boolean') {
        return (
            <Form.Group>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '6px' }}>
                    <Form.Control
                        style={{ width: '24px', cursor: 'pointer' }}
                        name={formData.name}
                        type={'checkbox'}
                        id={formData.name}
                        // required={!formData.isOptional}
                        defaultChecked={defaultValues}
                        disabled={disabled}
                        readOnly={formData.readonly}
                        ref={register}
                        onWheel={(event) => event.currentTarget.blur()}
                        onChange={(e) => {
                            setValue(formData.name, e.target.checked);
                        }}
                    />
                    <Form.Label>{getFormLabel(formData.caption)}</Form.Label>
                </div>
                <Form.Text
                    css={css`
                        text-align: justify;
                        opacity: 0.75;
                    `}
                >
                    {formData?.hint?.split('<br>')?.map((hint) => (
                        <p>{hint}</p>
                    ))}
                </Form.Text>
            </Form.Group>
        );
    }

    // select
    if (formData?.type === 'options') {
        return (
            <Form.Group>
                <Form.Label>
                    {getFormLabel(formData.caption)}
                    {!formData?.isOptional && (
                        <span
                            css={css`
                                color: red;
                                margin-left: 2px;
                            `}
                        >
                            *
                        </span>
                    )}
                </Form.Label>

                <Form.Control
                    as="select"
                    name={formData.name}
                    onChange={(e) => setValue(formData.name, e.target.value)}
                    ref={register}
                    required={formData?.isOptional ?? !formData.isOptional}
                    disabled={disabled}
                    defaultValue={defaultValues}
                >
                    {(formData.options || []).map((option, index) => (
                        <option value={option.code ?? option} key={index}>
                            {getFormLabel(option.name ?? option)}
                        </option>
                    ))}
                </Form.Control>
                <Form.Text
                    css={css`
                        text-align: justify;
                        opacity: 0.75;
                    `}
                >
                    {formData?.hint?.split('<br>')?.map((hint) => (
                        <p>{hint}</p>
                    ))}
                </Form.Text>
            </Form.Group>
        );
    }

    // input
    return (
        <Form.Group>
            <Form.Label>
                {getFormLabel(formData.caption)}
                {!formData?.isOptional && (
                    <span
                        css={css`
                            color: red;
                            margin-left: 2px;
                        `}
                    >
                        *
                    </span>
                )}
            </Form.Label>
            <Form.Control
                name={formData.name}
                type={formData.type === 'dollars' ? 'number' : 'text'}
                id={formData.name}
                required={!formData?.isOptional}
                defaultValue={defaultValues}
                disabled={disabled}
                maxLength={formData.maxlength}
                minLength={formData.minlength}
                min={formData.min}
                max={formData.max}
                readOnly={formData.readonly}
                step={0.01}
                ref={register({
                    pattern: {
                        value: formData?.regEx ? new RegExp(formData?.regEx) : null,
                        message: formData?.warning || 'Invalid input',
                    },
                })}
                onWheel={(event) => event.currentTarget.blur()}
            />
            <Form.Text
                css={css`
                    text-align: justify;
                    opacity: 0.75;
                    color: red;
                `}
            >
                {errors[formData?.name] && (
                    <p style={{ color: 'red', textAlign: 'justify' }}> {errors[formData?.name].message} </p>
                )}
            </Form.Text>
            <Form.Text
                css={css`
                    text-align: justify;
                    opacity: 0.75;
                `}
            >
                {formData?.hint?.split('<br>')?.map((hint) => (
                    <p>{hint}</p>
                ))}
            </Form.Text>
        </Form.Group>
    );
}

export default ZealFormBuilder;
