import { useEffect } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';

function useMobileRedirecter() {
	useEffect(() => {
        if (isAndroid) {
            // const host = window.location.host;
            // const url = `intent://${host}/#Intent;scheme=Optonome;package=org.intelladapt.optonome;end`;
            const url = 'intent://chat.optono.me/#Intent;scheme=Optonome;package=org.intelladapt.optonome;end';

			window.location.replace(url);
		} else if (isIOS) {
			window.location.replace('Optonome://');

			setTimeout(() => {
				window.location.replace('https://apps.apple.com/us/app/optonome/id1523086139');
			}, 10000);
		}
	}, []);
	return;
}

export default useMobileRedirecter;
