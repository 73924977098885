import React from 'react';
import { connect } from 'react-redux';

function AccrualPolicyElement() {
    return (
        <React.Fragment>
            <i>* Accrual Policies will be loaded dynamically when loading PTO form.</i>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    teams: state.teams.teams,
});

export default connect(mapStateToProps)(AccrualPolicyElement);
