import { css } from '@emotion/react';
import React from 'react';
import { getInitials, getRandomColor } from '../../../../_helpers';

function ProfileImg({ user, avatar }) {
	return (
		<div
			css={css`
				display: flex;
				justify-content: center;
			`}
		>
			{avatar ? (
				<img
					className="sidebar-avatar"
					src={avatar.startsWith('http:') ? avatar.split('http:').join('https:') : avatar || null}
					alt="logo"
					css={css`
						width: 12rem;
						height: 12rem;
						border-radius: 50%;
						object-fit: cover;
						object-position: center;
					`}
				/>
			) : (
				<div
					className="replacementImg avatar"
					css={css`
						background: ${getRandomColor(user?.id || user?._id)};
						width: 12rem;
						height: 12rem;
						border-radius: 50%;
						object-fit: cover;
						object-position: center;
						display: flex;
						justify-content: center;
						align-items: center;
					`}
				>
					<span
						css={css`
							font-size: 5rem !important;
							margin-top: 0 !important;
							color: white !important;
						`}
					>
						{getInitials(user?.name)}
					</span>
				</div>
			)}
		</div>
	);
}

export default ProfileImg;
