import { css } from 'twin.macro';
import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';

//icons
import { AiOutlineForm } from 'react-icons/ai';

//helpers
import ChatInputFormModal from './ChatInputFormModal';
import { Link } from 'react-router-dom';
import { Permission } from '../../../_helpers';

function ChatInputForm({ currRoom, team, userId, position, channelRole }) {
    const [show, setShow] = useState(false);
    const [isFormOpened, setIsFormOpened] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFormOpened = () => {
        setIsFormOpened(true);
    };

    const handleFormClosed = () => {
        setShow(false);
        setIsFormOpened(false);
    };

    return (
        <Fragment>
            <div
                css={css`
                    display: flex;
                    cursor: pointer;
                `}
                onClick={handleShow}
                className="forms-btn"
            >
                <AiOutlineForm
                    fontSize="26px"
                    css={css`
                        margin: 3px 3px 0 3px;
                    `}
                />
            </div>

            <Modal
                key={0}
                show={show}
                onHide={handleClose}
                centered
                size={'lg'}
                css={css`
                    opacity: ${isFormOpened ? 0 : 1};
                    z-index: ${isFormOpened ? -1 : 9999};
                `}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h1>Forms</h1>
                    </Modal.Title>
                    {
                        <Permission
                            role={channelRole}
                            perform="create:channelForm"
                            yes={() => (
                                <div
                                    css={css`
                                        width: 100%;
                                        display: flex;
                                        justify-content: end;
                                    `}
                                >
                                    <Link
                                        to={`/teams/${team.id}/channels/${currRoom.id || currRoom._id}/settings#/form-builder/`}
                                    >
                                        <Button>Build New Form</Button>
                                    </Link>
                                </div>
                            )}
                            no={() => null}
                        />
                    }
                </Modal.Header>
                <Modal.Body>
                    <ChatInputFormModal
                        currRoom={currRoom}
                        team={team}
                        userId={userId}
                        position={position}
                        channelRole={channelRole}
                        handleFormOpened={handleFormOpened}
                        handleFormClosed={handleFormClosed}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}
const mapStateToProps = (state) => ({
    userId: state.user.user.id,
    role: state.user.user.role,
    position: state.user.position,
});

export default connect(mapStateToProps, {})(ChatInputForm);
