import { useState,createContext,useContext} from "react";

export const StoreContext = createContext({});

export const useContextStore = () => {
  return useContext(StoreContext);
}

export default function StoreProvider({ children }) {

  const [onGoingServicesArr,setOnGoingServicesArr] = useState([]);
  const [toggleRefetchFilterTags, setToggleRefetchFilterTags] = useState(false);

  const contextStore = {
    onGoingServices:[onGoingServicesArr,setOnGoingServicesArr],
    refetchTagsOnSubmit : [toggleRefetchFilterTags, setToggleRefetchFilterTags]
  };

  return (
    <StoreContext.Provider value={contextStore}>
        {children}
    </StoreContext.Provider>
  );
};