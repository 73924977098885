import { css } from 'twin.macro';
import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { Form } from 'react-bootstrap';

//helpers
import { fetchWrapper, userNameValidation } from '../../../_helpers';
import { setUser } from '../../../_redux/actions/ActionUser';
//assets
// import { BiPencil } from 'react-icons/bi';
import { toast } from 'react-toastify';
// import dayjs from 'dayjs';

const UserNameCss = css`
	&.editUsernameDiv {
		width: 100%;
		min-height: 32px;
		:hover > .editBtn {
			visibility: visible;
			user-select: auto;
			position: static;
		}
	}
	.userName_input {
		padding: 0.4rem;
		border: none;
		border-bottom: 1px solid black;
		outline: none;
	}
	.disabled {
		pointer-events: none;
	}
	.Btn {
		border: none;
		margin-left: 0.8rem;
		padding: 0.2rem 1rem;
		border-radius: 10px;
		outline: none;
	}

	.updateBtn {
		background: #50aeb0;
		color: #fff;
	}
	.editBtn {
		color: #50aeb0;
		border: #50aeb0 2px solid;
		border-radius: 1000px;
		background: transparent;
		padding: 0;
		width: 30px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: background-color 300ms, color 300ms;
		visibility: hidden;
		user-select: none;
		position: absolute;
		:hover {
			color: white;
			background: #50aeb0;
		}
	}
`;
//
const UserNameComponent = ({ userName, isCancelable, handleHideUserName, setUser }) => {
	// const allowUsernameChange = useCallback(() => {
	// 	if (!lastUsernameChangedAt) {
	// 		return true;
	// 	}
	// 	let changeUsernameDays = 7;
	// 	let allowUsernameChangeDate = dayjs(lastUsernameChangedAt).add(changeUsernameDays, 'day').format('MM-DD-YYYY');
	// 	let todayDate = dayjs().format('MM-DD-YYYY');
	// 	let daysBeforeUserNameChange = dayjs(allowUsernameChangeDate).diff(dayjs(todayDate)) / (1000 * 60 * 60 * 24);
	// 	//'daysBeforeUserNameChange is greater than 0 then donot allow to change user name'
	// 	if (daysBeforeUserNameChange) {
	// 		return false;
	// 	} else {
	// 		return true;
	// 	}
	// },[lastUsernameChangedAt]);

	const [newUserName, setNewUserName] = useState(userName || '');
	const [editUserName, setEditUserName] = useState(userName);
	const [isProcessing, setIsProcessing] = useState(false);
	const { register, handleSubmit, errors } = useForm({
		mode: 'onBlur',
		resolver: yupResolver(userNameValidation),
	});

	const onSubmit = async (data) => {
		try {
			setIsProcessing(true);
			let response = await fetchWrapper.put('users/me', { username: data.userName });
			setUser({
				username: response.username,
				lastUserNameChangedAt: response.lastUserNameChangedAt || new Date().toISOString(),
			});
			setIsProcessing(false);
			setEditUserName(true);
			toast.success('Username updated Successfully!');
			handleHideUserName && handleHideUserName();
		} catch (err) {
			setIsProcessing(false);
			toast.error(err.message || 'Something went wrong');
		}
	};

	// const handleEditClick = () => {
	// 	setEditUserName(false);
	// };

	const handleCancelClick = () => {
		setEditUserName(true);
		setNewUserName(userName);
	};

	return (
		<Fragment>
			{editUserName ? (
				<div css={UserNameCss} className="editUsernameDiv">
					<h3 className="text-muted">@{newUserName}</h3>
					{/* {allowUsernameChange() && (
						<button className="Btn editBtn" onClick={handleEditClick}>
							<BiPencil fontSize={22} />
						</button>
					)} */}
				</div>
			) : (
				<div css={UserNameCss} className="userName">
					<div
						className="d-flex justify-content-start"
						css={css`
							span {
								font-size: 1.6rem;
							}
						`}
					>
						<label>
							<span>your username:</span>
						</label>
					</div>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<div>
							<input
								className={`userName_input ${isProcessing ? 'disabled' : ''}`}
								type="text"
								name="userName"
								ref={register}
								value={newUserName}
								maxLength={15}
								placeholder=" john_doe"
								onChange={(e) => {
									setNewUserName(e.target.value?.toLowerCase());
								}}
							/>
							{isProcessing ? (
								<div>Processing...</div>
							) : (
								<Fragment>
									<button className="Btn updateBtn" type="submit">
										Update
									</button>
									{isCancelable && (
										<button className="Btn btn-secondary" type="button" onClick={handleCancelClick}>
											Cancel
										</button>
									)}
								</Fragment>
							)}
						</div>
						<Form.Text className="text-danger">{errors.userName?.message}</Form.Text>
					</Form>
				</div>
			)}
		</Fragment>
	);
};

export default connect(null, { setUser })(UserNameComponent);
