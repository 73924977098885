import { css } from 'twin.macro';
import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';

import { fetchWrapper, getInitials, getRandomColor, getTeamIdFromURL } from '../../../../_helpers';
import { Image } from 'react-bootstrap';

export const scrollToSelectedOption = (className) => {
    setTimeout(() => {
        const selectors = [`.modal-body div[tabindex="-1"] .${className}`, `div[tabindex="-1"] .${className}`];

        let elements;
        for (const selector of selectors) {
            elements = document.querySelectorAll(selector);
            if (elements.length > 0) break;
        }
        if (elements.length > 0) {
            const lastElement = elements[elements.length - 1];
            lastElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, 100);
};

const UserImage = css`
    display: flex;
    align-items: center;
    .userImage {
        width: 5rem;
        height: 5rem;
        margin: 5px 8px 5px 0px;
        border-radius: 1000px;
        object-fit: contain;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 14px;
        &.mini {
            width: 3rem;
            height: 3rem;
        }
    }
`;

const SearchByClient = ({ provider, client, handleSelect, isDisabled }) => {
    const teamId = getTeamIdFromURL();
    const [loading, setLoading] = useState(false);
    const selectedOptionClass = `client-${client?.id}`;

    const fetchData = (inputValue, callback) => {
        setTimeout(async () => {
            setLoading(true);
            const tempArray = [];
            if (provider) {
                const clients = await fetchWrapper.get(
                    `evv/client?limit=100${inputValue ? `&firstName=${inputValue}` : ''}${provider.value ? `&provider=${provider.value}` : ''}${teamId ? `&team=${teamId}` : ''}`
                );
                clients.results.forEach((element) => {
                    element.name = `${element.firstName} ${element.lastName}`;
                    tempArray.push({
                        label: (
                            <div css={UserImage} className={`client-${element.id}`}>
                                <div>
                                    {element.avatar ? (
                                        <Image
                                            src={element.avatar}
                                            className="userImage"
                                            width="30"
                                            alt={element.name}
                                        />
                                    ) : (
                                        <div className="userImage" style={{ background: getRandomColor(element.id) }}>
                                            {getInitials(element.name)}
                                        </div>
                                    )}
                                </div>
                                {element.name}
                            </div>
                        ),
                        value: element.id,
                        ...element,
                    });
                });
            }
            callback(tempArray);
            setLoading(false);
        }, 250);
    };

    const onSearchChange = (selectedOption) => {
        handleSelect(selectedOption);
    };

    useEffect(() => {
        if (!loading) {
            scrollToSelectedOption(selectedOptionClass);
        }
    }, [loading]);

    return (
        <div
            className="search-container"
            key={provider?.value}
            css={css`
                .userImage {
                    width: 3rem !important;
                    height: 3rem !important;
                }
            `}
        >
            <span className="search-title">Select Client:</span>
            <AsyncSelect
                loadOptions={fetchData}
                defaultOptions
                onChange={onSearchChange}
                isClearable={true}
                value={client}
                isDisabled={isDisabled}
                onMenuOpen={() => scrollToSelectedOption(selectedOptionClass)}
            />
        </div>
    );
};
export default SearchByClient;
