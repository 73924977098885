import { css } from 'twin.macro';
import { ActionStyles } from '../../../../reusableStyles';
import { Button } from 'react-bootstrap';
import { BsFillPlusSquareFill, BsPencil } from 'react-icons/bs';
import { IoIosPeople } from 'react-icons/io';
import { BootstrapToolTip } from '../../../../../../_elements/ToolTip';
import { Fragment, useState } from 'react';
import AccrualPolicy from './AccrualPolicy';
import AddOrRemoveEmployees from './AddOrRemoveEmployees';
import { PolicyCard } from './PolicyDetailCard';
import LoadingSpiner from '../../../../../../_elements/LoadingSpiner';

const AccrualPolicyList = ({ teamId, companyID, accrualPolicies, handleCreateOrUpdate, isLoading }) => {
    const [showAccrualPolicy, setShowAccrualPolicy] = useState(false);
    const [selectedAccrualPolicy, setSelectedAccrualPolicy] = useState({});
    const [showAddOrRemoveEmployees, setShowAddOrRemoveEmployees] = useState(null);
    const handleShowAccrualPolicy = () => setShowAccrualPolicy(true);
    const handleHideAccrualPolicy = () => setShowAccrualPolicy(false);
    const handleSelectAccrualPolicy = (policy) => {
        setSelectedAccrualPolicy(policy);
        handleShowAccrualPolicy();
    };
    const handleUnselectAccrualPolicy = () => {
        setSelectedAccrualPolicy({});
        handleHideAccrualPolicy();
    };

    const handleSelectAddOrRemoveEmpolyees = (policy) => {
        setSelectedAccrualPolicy(policy);
        setShowAddOrRemoveEmployees(policy);
    };
    const handleUnselectAddOrRemoveEmpolyees = () => {
        setSelectedAccrualPolicy({});
        setShowAddOrRemoveEmployees(null);
    };

    if (showAccrualPolicy) {
        return (
            <AccrualPolicy
                companyID={companyID}
                selectedAccrualPolicy={selectedAccrualPolicy}
                goBack={handleUnselectAccrualPolicy}
                handleCreateOrUpdate={handleCreateOrUpdate}
            />
        );
    }
    if (showAddOrRemoveEmployees) {
        return (
            <AddOrRemoveEmployees
                teamId={teamId}
                companyID={companyID}
                selectedAccrualPolicy={selectedAccrualPolicy}
                goBack={handleUnselectAddOrRemoveEmpolyees}
            />
        );
    }

    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                margin-top: 8px;
            `}
        >
            <div
                css={css`
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-end;
                    margin: 16px 0;
                `}
            >
                <Button
                    disabled={isLoading}
                    variant="primary"
                    onClick={handleShowAccrualPolicy}
                    className="btn px-4 py-3  "
                    css={css`
                        width: 200px;
                    `}
                >
                    <BsFillPlusSquareFill className="mr-2" /> <span>New Accrual Policy</span>
                </Button>
            </div>
            {isLoading ? (
                <LoadingSpiner size={42} />
            ) : (
                <Fragment>
                    {accrualPolicies.map((policy, index) => (
                        <PolicyCard key={index} policy={policy}>
                            <div
                                css={css`
                                    display: flex;
                                    flex-direction: row;
                                    align-items: flex-start;
                                `}
                            >
                                <div>
                                    <ActionStyles>
                                        <BootstrapToolTip text="Edit Accrual Policy">
                                            <BsPencil
                                                className="icon edit"
                                                onClick={() => handleSelectAccrualPolicy(policy)}
                                            />
                                        </BootstrapToolTip>
                                        <BootstrapToolTip text="Add/Remove Employees to Accrual Policy">
                                            <IoIosPeople
                                                className="icon people"
                                                onClick={() => handleSelectAddOrRemoveEmpolyees(policy)}
                                            />
                                        </BootstrapToolTip>
                                    </ActionStyles>
                                </div>
                            </div>
                        </PolicyCard>
                    ))}
                </Fragment>
            )}
        </div>
    );
};

export default AccrualPolicyList;
