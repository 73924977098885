import { css } from 'twin.macro';
import React, { useEffect, useState, useRef } from 'react';
import { BsEye } from 'react-icons/bs';
import { FaUserPlus, FaRedo } from 'react-icons/fa';
import { VscLoading } from 'react-icons/vsc';
import AdminSearchBox from '../../../../../Admin/reusableComponents/AdminSearchBox';
import { AdminTable } from '../../../../../Admin/reusableStyles';
import { ActionStyles } from '../../../../../Admin/reusableStyles';
import { ToolTip } from '../../../../../../_elements/ToolTip';
import { fetchWrapper } from '../../../../../../_helpers';
import { toast } from 'react-toastify';
import Avatar from '../../../../../Admin/reusableComponents/Avatar';
import UserCoursesInfo from './UserCoursesInfo';
import LoadingSpiner from '../../../../../../_elements/LoadingSpiner';

const TeamUsers = ({ users, loading, handleAmolUserRegister }) => {
    const searchRef = useRef('');
    const [activeUser, setActiveUser] = useState(null);
    const [isRegistering, setIsRegistering] = useState();
    const [filteredUsers, setFilteredUsers] = useState(users || []);
    const handleRegisterAmol = async (userId) => {
        try {
            setIsRegistering(userId);
            await fetchWrapper.post(`amol/${userId}`);
            handleAmolUserRegister(userId);
        } catch (error) {
            if (error.name === 'AbortError') {
                return;
            }
            toast.error(error.message);
        } finally {
            setIsRegistering();
        }
    };
    const onSearch = (value) => {
        setFilteredUsers(
            (users || []).filter(
                (user) =>
                    user?.name?.toLowerCase().includes(value.toLowerCase()) ||
                    user?.email?.toLowerCase().includes(value.toLowerCase())
            )
        );
    };
    const handleSelectUser = (user) => {
        setActiveUser(user);
    };
    const handleBack = () => {
        setActiveUser(null);
    };

    const getTeamOrChannelRole = (user) => {
        try {
            if (user?.rooms) {
                return user?.rooms[0]?.role;
            }
            if (user?.team) {
                return user?.team[0]?.role;
            }
            return user?.role;
        } catch (err) {
            return 'user';
        }
    };

    useEffect(() => {
        setFilteredUsers(users || []);
    }, [users]);

    if (activeUser) {
        return <UserCoursesInfo handleBack={handleBack} user={activeUser} />;
    }
    if (loading) {
        return <LoadingSpiner size={42} />;
    }
    return (
        <div
            css={css`
                padding: 1rem 2rem;
            `}
        >
            <div
                className="mb-4 d-flex flex-row align-items-center"
                css={css`
                    gap: 1rem;
                `}
            >
                <AdminSearchBox onSearch={onSearch} placeholder="Search Users" inputRef={searchRef} />
            </div>

            <div className="custom-card card-table d-flex flex-column justify-content-between">
                <AdminTable className="w-100 text-dark-grey">
                    <thead>
                        <tr>
                            <th></th>
                            <th className="text-left">User</th>
                            <th className="text-left">Email</th>
                            <th className="text-left">Role</th>
                            <th className="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody className="text-light-grey">
                        {loading ? (
                            <tr>
                                <td colSpan={4}></td>
                            </tr>
                        ) : (filteredUsers || []).length ? (
                            filteredUsers?.map((user, userIndex) => {
                                const RegisterIcon = user.isAmolRegistered ? FaRedo : FaUserPlus;
                                return (
                                    <tr key={user?.id || userIndex}>
                                        <td style={{ width: '3.8rem' }}>
                                            <Avatar item={user} />
                                        </td>
                                        <td className="text-left">{user.name}</td>
                                        <td className="text-left">{user.email}</td>
                                        <td
                                            className="text-left"
                                            css={css`
                                                text-transform: capitalize;
                                            `}
                                        >
                                            {getTeamOrChannelRole(user)}
                                        </td>
                                        <td className="action">
                                            <ActionStyles style={{ justifyContent: 'center' }}>
                                                <ToolTip
                                                    text={'View Course Progress'}
                                                    cssStyles={
                                                        'top:-40px !important; min-width: 150px !important;left:-70px !important;'
                                                    }
                                                >
                                                    <BsEye className="icon" onClick={() => handleSelectUser(user)} />
                                                </ToolTip>
                                                <div
                                                    css={css`
                                                        padding: 3px 6px 4px !important;
                                                        ${isRegistering === user?._id
                                                            ? 'border: 1px solid #ccc !important; color: #ccc !important'
                                                            : ''}
                                                    `}
                                                    className="icon"
                                                >
                                                    {isRegistering === user?._id ? (
                                                        <VscLoading
                                                            className="spin"
                                                            width={24}
                                                            height={24}
                                                            css={css`
                                                                cursor: not-allowed;
                                                            `}
                                                        />
                                                    ) : (
                                                        <ToolTip
                                                            text={'Register to Course'}
                                                            cssStyles={
                                                                'top:-40px !important; min-width: 150px !important;left:-70px !important;'
                                                            }
                                                        >
                                                            <RegisterIcon
                                                                onClick={() =>
                                                                    handleRegisterAmol(user?.id || user?._id)
                                                                }
                                                            />
                                                        </ToolTip>
                                                    )}
                                                </div>
                                                {/* )} */}
                                            </ActionStyles>
                                        </td>
                                        <td className="verification-status"></td>
                                    </tr>
                                );
                            })
                        ) : (
                            <td colSpan={4}>
                                <div
                                    className="w-100 h-100 d-flex flex-row justify-content-center align-items-center"
                                    css={css`
                                        margin-top: 50px;
                                        width: 100%;
                                    `}
                                >
                                    No Users Found
                                </div>
                            </td>
                        )}
                    </tbody>
                </AdminTable>
            </div>
        </div>
    );
};

export default TeamUsers;
