import { css } from 'twin.macro';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import { Button, Modal, Tab, Tabs } from 'react-bootstrap';
import Documents from './Documents';
import TeamSettingFormPackage from './TeamSettingFormPackage';
import TeamSettingsVisitTemplate from './VisitTemplate/TeamSettingsVisitTemplate';
import TeamSettingsGroupForms, { ModalCSS } from './TeamSettingsGroupForms';
import { DocumentsStyles } from './styles/DocumentsStyled';
import { useEffect } from 'react';
import { fetchWrapper, getTeamIdFromURL, getChannelIdFromSettingsURL} from '../../../../_helpers';
import AllDocuments from './AllDocuments/AllDocuments';

function TeamSettingsForm({teamRooms, currUser, channelDetails}) {
	const [key, setKey] = useState('form_package');
	const [showSearchModal, setShowSearchModal] = useState(false);
	const [search, setSearch] = useState('');
	const [searchData, setSearchData] = useState({});
	const teamId = getTeamIdFromURL();
	const channelIdFromUrl = getChannelIdFromSettingsURL();

	const onSearch = (val) => {
		setSearch(val);
		if (val === '') {
			if (key === 'all') {
				setKey('form_package');
			}
		}
	};

	const hideSearchModel = () => {
		setShowSearchModal(false);
	};

	useEffect(() => {
		async function fetchDocuments() {
			try {
				const response = await fetchWrapper.get(
					`chatrooms/${teamId}/files/search/${search ? `?searchText=${search}` : ''}${channelIdFromUrl ? `&chatroom=${channelIdFromUrl}` : ''}`
				);
				setSearchData(response);
			} catch (error) {
				console.log(error);
			}
		}
		if(search){
			fetchDocuments();
		}
	}, [search, teamId]);


	return (
		<div css={DocumentsStyles}>
			<h1 className="mb-4">Documents</h1>
			<div className="top-items">
				<input
					onChange={(e) => onSearch(e.target.value)}
					value={search}
					placeholder="Search For documents"
					className="input search-area-items"
				/>
			</div>
			<Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
				{search && (
					<Tab eventKey="all" title="All">
						{key === 'all' && (
							<AllDocuments search={search} searchData={searchData} teamId={teamId} setKey={setKey} channelDetails={channelDetails}/>
						)}
					</Tab>
				)}
				<Tab eventKey="form_package" title="Form Library">
					{key === 'form_package' && <TeamSettingFormPackage search={search} channelDetails={channelDetails}/>}
				</Tab>
				<Tab eventKey="submitted_forms" title="Submitted Forms">
					{key === 'submitted_forms' && <TeamSettingsGroupForms search={search} channelDetails={channelDetails} />}
				</Tab>
				<Tab eventKey="team_forms" title="Documents">
					{key === 'team_forms' && <Documents search={search} channelDetails={channelDetails} />}
				</Tab>
				<Tab eventKey="form_template" title="Visit Template">
					{key === 'form_template' && <TeamSettingsVisitTemplate search={search} />}
				</Tab>
			</Tabs>

			<Modal css={ModalCSS} show={showSearchModal} backdropClassName="bg-dark" onHide={hideSearchModel} centered>
				<Modal.Header closeButton>
					<h4
						css={css`
							margin: 0;
						`}
					>
						Search All Documents
					</h4>
				</Modal.Header>
				{/* <Modal.Body className="modal-body">
					<InputGroup className="mb-3 flex-column">
						<FormLabel className="mr-2 ">Submitted by: </FormLabel>
						<Select
							value={member}
							className="bolder"
							name="member"
							isClearable={true}
							options={members}
							onChange={handleChangeMember}
						/>
					</InputGroup>
					<InputGroup className="mb-3 flex-column w-100">
						<FormLabel className="mr-2 ">Channel: </FormLabel>
						<Select
							value={channel}
							className="bolder"
							name="channel"
							isClearable={true}
							options={channels}
							onChange={handleChangeChannel}
						/>
					</InputGroup>
					<InputGroup className="mb-3 flex-column">
						<FormLabel className=" mr-2">From: </FormLabel>
						<DateTimePicker
							onChange={handleStartDateChange}
							value={startDate ? new Date(startDate) : startDate}
							format="MM-dd-y hh:mm a"
							disableClock={true}
						/>
					</InputGroup>
					<InputGroup className="mb-3 flex-column">
						<FormLabel className="mr-2 ">To: </FormLabel>
						<DateTimePicker
							onChange={handleEndDateChange}
							value={endDate ? new Date(endDate) : endDate}
							format="MM-dd-y hh:mm a"
							disableClock={true}
						/>
					</InputGroup>

					<div>
						{(member?.name || channel?.label || startDate || endDate) && (
							<div css={SearchFilterCSS}>
								<h4>Search Filters:</h4>
								<div>
									{member && (
										<div className="filterRow">
											{' '}
											Submitted by:
											<div>
												<b className="text-primary">{member?.name}</b>
												<button
													className="btn"
													onClick={() => {
														handleChangeMember({});
													}}
												>
													<AiOutlineMinusCircle fontSize="24px" color="f30e00" />
												</button>
											</div>
										</div>
									)}
									{channel && (
										<div className="filterRow">
											{' '}
											Channel:
											<div>
												<b className="text-primary">{channel.label}</b>
												<button
													className="btn"
													onClick={() => {
														handleChangeChannel({});
													}}
												>
													<AiOutlineMinusCircle fontSize="24px" color="f30e00" />
												</button>
											</div>
										</div>
									)}

									{startDate && (
										<div className="filterRow">
											From:
											<div>
												<b className="text-primary">
													{dayjs(startDate).format('MM-DD-YYYY, h:mm A')}
												</b>
												<button
													className="btn"
													onClick={() => {
														handleStartDateClear();
													}}
												>
													<AiOutlineMinusCircle fontSize="24px" color="f30e00" />
												</button>
											</div>
										</div>
									)}
									{endDate && (
										<div className="filterRow">
											To:
											<div>
												<b className="text-primary">
													{dayjs(endDate).format('MM-DD-YYYY, h:mm A')}
												</b>
												<button
													className="btn"
													onClick={() => {
														handleEndDateClear();
													}}
												>
													<AiOutlineClear fontSize="24px" color="f30e00" />
												</button>
											</div>
										</div>
									)}
								</div>
								<button className="btn btn-secondary" onClick={handleClearAll}>
									Clear all
								</button>
							</div>
						)}
					</div>
				</Modal.Body> */}
			</Modal>
		</div>
	);
}

const mapStateToProps = (state) => ({
	teamRooms: state.rooms.teamRooms,
	currUser: state.user.user,
});

export default connect(mapStateToProps, { })(TeamSettingsForm);
