import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { css } from 'twin.macro';
import { Button, Dropdown } from 'react-bootstrap';

//redux
import { editChatRoomMessage, editSubChannelMessage } from '../../../_redux/actions/ActionMessage';
import { isJson } from '../../../_helpers';
import { toast } from 'react-toastify';
import { VscLoading } from 'react-icons/vsc';

const PermissionMessage = ({ message, rId, subChannelId, editChatRoomMessage, editSubChannelMessage }) => {
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    // const parsedMessage = isJson(message.data) ? JSON.parse(message.data) : { text: message?.data };
    const [messagePermission, setMessagePermission] = useState(() => {
        let newVisibleTo = [];
        if (message?.visibleTo) {
            newVisibleTo = message?.visibleTo;
        } else if (subChannelId) {
            newVisibleTo = ['user'];
        } else {
            newVisibleTo = ['family', 'user', 'doctor', 'supervisor'];
        }

        return newVisibleTo;
    });
    // const showSupervisorCheckBox = () => {
    //     const userRole = getUserRole(currUser, teamData?.team?._id, rId);
    //     return userRole !== 'supervisor';
    // };
    useEffect(() => {
        if (message?.visibleTo) {
            setMessagePermission(message?.visibleTo);
        } else if (subChannelId) {
            setMessagePermission(['user', 'owner']);
        } else {
            setMessagePermission(['family', 'user', 'doctor', 'supervisor']);
        }
    }, [message.visibleTo, subChannelId]);

    const handlePermissionChange = (event) => {
        const role = event.target.value;
        const newPermissions = messagePermission?.includes(role)
            ? [...messagePermission].filter((permission) => permission !== role)
            : [...messagePermission, role];
        setMessagePermission(newPermissions);
    };

    const handlePermissionUpdate = async () => {
        const parsedMessage = isJson(message.data) ? JSON.parse(message.data) : { text: message?.data };
        const msg = { ...parsedMessage };
        let newVisibleTo = messagePermission;
        try {
            setLoading(true);
            const body = {
                data: JSON.stringify(msg),
                visibleTo: newVisibleTo,
            };
            const response = subChannelId
                ? await editSubChannelMessage(subChannelId, message.id, body)
                : await editChatRoomMessage(rId, message.id, body);
            if (response) {
                throw new Error(response?.message || 'Response is empty');
            }
            toast.success('Permission updated Successfully!');
        } catch (err) {
            console.log(err, 'ERR');
            toast.error(err?.message || 'Ops! Something went wrong.');
            setMessagePermission(message?.visibleTo || []);
        } finally {
            setShowModal(false);
            setLoading(false);
        }
    };

    return (
        <React.Fragment key={0}>
            <Dropdown.Item className="text-dark" onClick={() => setShowModal(true)}>
                <span
                    css={css`
                        margin-right: 1rem;
                    `}
                >
                    Edit Permission
                </span>
            </Dropdown.Item>
            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                {loading && (
                    <div
                        css={css`
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            z-index: 2;
                            background: rgba(255, 255, 255, 0.8);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            transition: background-color 150ms;
                        `}
                    >
                        <VscLoading className="spin" fontSize="48" />
                    </div>
                )}
                <Modal.Header closeButton>Permission</Modal.Header>
                <Modal.Body className="p-0">
                    <div
                        css={css`
                            min-height: 15vh;
                            display: flex;
                            justify-content: space-evenly;
                            flex-direction: column;
                        `}
                    >
                        <div
                            css={css`
                                min-height: 13vh;
                                width: 95%;
                                margin: 0 auto;
                                padding-top: 1.5rem;
                                display: flex;
                                justify-content: flex-start;
                                align-items: flex-start;
                                flex-direction: column;
                            `}
                        >
                            <div>
                                <h3>Visible To:</h3>
                            </div>
                            {subChannelId ? (
                                <div
                                    css={css`
                                        margin-left: 2rem;
                                        label + label {
                                            margin-left: 1.5rem;
                                        }
                                    `}
                                >
                                    <label htmlFor="user">
                                        <input
                                            id="user"
                                            type="checkbox"
                                            onChange={handlePermissionChange}
                                            value="user"
                                            checked={messagePermission.includes('user')}
                                        />{' '}
                                        User
                                    </label>
                                    <label htmlFor="owner">
                                        <input
                                            id="owner"
                                            type="checkbox"
                                            onChange={handlePermissionChange}
                                            value="owner"
                                            checked={messagePermission.includes('owner')}
                                        />{' '}
                                        Owner
                                    </label>
                                </div>
                            ) : (
                                <div
                                    css={css`
                                        margin-left: 2rem;
                                        label + label {
                                            margin-left: 1.5rem;
                                        }
                                    `}
                                >
                                    <label htmlFor="thirdparty">
                                        <input
                                            id="thirdparty"
                                            type="checkbox"
                                            onChange={handlePermissionChange}
                                            value="doctor"
                                            checked={messagePermission.includes('doctor')}
                                        />{' '}
                                        Third Party
                                    </label>
                                    <label htmlFor="family">
                                        <input
                                            id="family"
                                            type="checkbox"
                                            onChange={handlePermissionChange}
                                            value="family"
                                            checked={messagePermission.includes('family')}
                                        />{' '}
                                        Family
                                    </label>
                                    <label htmlFor="supervisor">
                                        <input
                                            id="supervisor"
                                            type="checkbox"
                                            onChange={handlePermissionChange}
                                            value="supervisor"
                                            checked={messagePermission.includes('supervisor')}
                                        />{' '}
                                        Supervisor
                                    </label>
                                    <label htmlFor="user">
                                        <input
                                            id="user"
                                            type="checkbox"
                                            onChange={handlePermissionChange}
                                            value="user"
                                            checked={messagePermission.includes('user')}
                                        />{' '}
                                        User
                                    </label>
                                </div>
                            )}
                        </div>
                        <div
                            css={css`
                                min-height: 5rem;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            `}
                        >
                            <Button varient="primary" onClick={handlePermissionUpdate}>
                                Save
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};

export default connect(null, { editChatRoomMessage, editSubChannelMessage })(PermissionMessage);
