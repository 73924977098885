import { css } from 'twin.macro';
import React, { Fragment, useState, useEffect } from 'react';
import { VscLoading } from 'react-icons/vsc';
import { toast } from 'react-toastify';
import { fetchWrapper, getTeamIdFromURL } from '../../../../_helpers';
import { connect } from 'react-redux';
import FormSvg from '../../../../assets/forms.svg';
import Pagination from '../../Pagination';
import { spin } from '../../../Features/Teams/TeamSettings/TeamSettingFormPackage';
import PtoRow from './PtoRow';
import { AdminTable } from '../../reusableStyles';
import PtoModal from './PtoModal';

const TeamSettingPtoForm = ({ search, provider, status }) => {
    const [forms, setForms] = useState([]);
    const [loading, setLoading] = useState(false);
    const teamId = getTeamIdFromURL();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalResults, setTotalResults] = useState(10);
    const [showModal, setShowModal] = useState(null);

    const getForms = async () => {
        try {
            let team = provider?.team?._id || provider?.teamId;
            if (!team) {
                return;
            }
            setLoading(true);
            const res = await fetchWrapper.get(
                `userFormData?sortBy=createdAt:desc&limit=10&specialityForm=timeOff${`&page=${currentPage || 1}`}${team ? `&team=${team}` : ''}${status?.value ? `&submissionType=${status?.value}` : ''}`
            );
            setForms(res.results);
            setTotalResults(res.totalResults);
        } catch (error) {
            if (error.name === 'AbortError') {
                return;
            }
            toast.error(error?.message || 'Failed to load PTO forms');
        } finally {
            setLoading(false);
        }
    };

    const handleUpdateForm = (updatedFormData = {}) => {
        setForms((prev) =>
            prev.map((prevFormData) =>
                prevFormData?._id === updatedFormData?._id ? { ...prevFormData, ...updatedFormData } : prevFormData
            )
        );
    };

    useEffect(() => {
        let timer;
        if (provider?.value) {
            clearTimeout(timer);
            timer = setTimeout(getForms, 1000);
        }
        return () => clearTimeout(timer);
    }, [teamId, search, currentPage, status?.value, provider]);

    const handleClose = () => {
        setShowModal();
    };
    const handleShow = (formData, type) => {
        setShowModal({ formData, type });
    };

    return (
        <div
            css={css`
                width: 100%;
            `}
        >
            {loading ? (
                <h4 className="text-center">
                    <VscLoading css={spin} fontSize="42" />
                </h4>
            ) : forms.length > 0 ? (
                <div>
                    <AdminTable className="w-100">
                        <thead>
                            <tr>
                                <th>S.N</th>
                                <th>Requested By</th>
                                <th className="text-center">Channel</th>
                                <th className="text-center">Submitted At</th>
                                <th className="text-center">Requested Amount</th>
                                <th className="text-center">Requested Date</th>
                                <th className="text-center">Status</th>
                                <th className="text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {forms.length > 0 ? (
                                <Fragment>
                                    {(forms || []).map((formData, index) => (
                                        <PtoRow
                                            key={`${formData?._id}_${index}`}
                                            formData={formData}
                                            index={index}
                                            currentPage={currentPage}
                                            handleShow={handleShow}
                                        />
                                    ))}
                                </Fragment>
                            ) : (
                                <tr>
                                    <td colSpan="100">
                                        <h4 className="text-center">No PTO forms Found</h4>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </AdminTable>
                </div>
            ) : (
                <div
                    css={css`
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                    `}
                >
                    <img
                        src={FormSvg}
                        alt={'no forms found'}
                        css={css`
                            width: 350px;
                            margin: 1rem 0;
                        `}
                    />
                    <h2 className="text-center">No PTO forms</h2>
                </div>
            )}
            {showModal?.type === 'view' && (
                <PtoModal view={true} show={!!showModal} handleClose={handleClose} formData={showModal?.formData} />
            )}
            {showModal?.type === 'data' && (
                <PtoModal
                    show={!!showModal}
                    handleClose={handleClose}
                    formData={showModal?.formData}
                    provider={provider}
                    handleUpdateForm={handleUpdateForm}
                />
            )}
            <div className="p-4">
                <Pagination
                    totalRecords={totalResults}
                    key={totalResults}
                    pageLimit={10}
                    pageNeighbours={1}
                    onPageChanged={(data) => {
                        setCurrentPage(Math.max(1, data.currentPage));
                    }}
                />
            </div>
        </div>
    );
};
const mapStateToProps = (state) => ({
    rooms: state.rooms.teamRooms,
});

export default connect(mapStateToProps, {})(TeamSettingPtoForm);
