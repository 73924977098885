import styled from '@emotion/styled';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Badge, Button, Dropdown, Form } from 'react-bootstrap';
import { BsEye } from 'react-icons/bs';
import { BiLabel } from 'react-icons/bi';
import { VscLoading } from 'react-icons/vsc';
import { toast } from 'react-toastify';
import { ToolTip } from '../../../../_elements/ToolTip';
import { fetchWrapper, roundDown, timeConvert } from '../../../../_helpers';
import { ActionStyles } from '../../reusableStyles';
import { BsShieldFillX, BsArrowBarRight } from 'react-icons/bs';
import { FaUndoAlt, FaWpforms } from 'react-icons/fa';
import { HiCheck } from 'react-icons/hi';
import Swal from 'sweetalert2';
import { statusData } from './Label/_colorData';

export const changeVisitLabelFromResponse = (response) => {
    if (response?.label) {
        toast.success(`Label changed to ${response.label}`);
    }
    if (response?.newAvailableUnits) {
        toast.success(`Client units has been deducted. New available units is ${response?.newAvailableUnits}`);
    } else if (response?.addedUnitsEntry && response?.removedUnitsEntry) {
        if (response?.addedUnitsEntry?.expiry === response?.removedUnitsEntry?.expiry) {
            toast.success(
                `Client units ${response?.removedUnitsEntry?.amount} has been updated to ${
                    response?.addedUnitsEntry?.amount
                } & expires on ${dayjs(response?.addedUnitsEntry?.expiry).format('MMM DD YYYY')}`
            );
        }
    } else if (response?.addedUnitsEntry) {
        toast.success(
            `client units ${response?.addedUnitsEntry?.amount} which expires on  ${dayjs(
                response?.addedUnitsEntry?.expiry
            ).format('MMM DD YYYY')} has been added.`
        );
    }
};

const PillBadge = styled.span`
    padding: 0.5rem 1rem;
    background: #50aeb0;
    border-radius: 100px;
    margin-left: 1rem;
    color: white;
    cursor: default;
    &.blue {
        background: #f5f5f5;
        color: #111;
    }
`;

const ActionTD = styled.td`
    display: flex;
    justify-content: end;
    gap: 0;
    min-width: 80px;
`;

function VisitRow({ visit, handleShow, visitVerifyMode, setVerifyVisitsId, labels, clientRooms = {}, team }) {
    const channelId = clientRooms[visit?.client?.id] || visit?.extraInfo?.chatroom;
    const formId = visit?.extraInfo?.formId;
    const [billable, setBillable] = useState(visit?.extraInfo?.BillVisit ?? false);
    const [visitData, setVisitData] = useState(visit);

    useEffect(() => {
        setVisitData({ ...visitData, label: visit?.extraInfo?.label, status: visit?.status || 'complete' });
    }, [visit]);

    const [isLabelChanging, setIsLabelChanging] = useState(false);
    const handleBillableSuccess = (billStatus) => {
        setBillable(JSON.parse(billStatus));
    };

    const handleLabelChange = (newLabel) => {
        const modifyVisit = async () => {
            try {
                setIsLabelChanging(true);
                const body = {
                    visit: visitData.id || visitData._id,
                    label: newLabel,
                };
                const response = await fetchWrapper.patch(`evv/visit/label?${team ? `&team=${team}` : ''}`, body);
                setVisitData((prev) => ({
                    ...prev,
                    label: response?.label || newLabel,
                    status: response?.status || prev?.status,
                }));
                changeVisitLabelFromResponse(response);
            } catch (err) {
                console.log(err);
                toast.error(err?.message || 'Something went wrong');
            } finally {
                setIsLabelChanging(false);
            }
        };
        modifyVisit();
    };

    const handleCompleteVisit = () => {
        const modifyVisit = async () => {
            try {
                setIsLabelChanging(true);
                const body = {
                    visit: visitData.id || visitData._id,
                };
                const response = await fetchWrapper.patch(`evv/visit/complete?${team ? `&team=${team}` : ''}`, body);
                setVisitData((prev) => ({ ...prev, ...response }));
                toast.success('Visit completed successfully');
            } catch (err) {
                console.log(err);
                toast.error(err.message || 'Something went wrong');
            } finally {
                setIsLabelChanging(false);
            }
        };
        const message =
            visitData?.status === 'draft'
                ? 'Completing a visit will automatically send your information to external EVV systems like Sandata and Therap.'
                : 'Reverting payment status will not update already created checks with this visit.';
        Swal.fire({
            title: 'Are you sure?',
            html: `<div style="font-size:16px;color:brown;text-align:justify;width:100%;">${message}</div>`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#50aeb0',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                modifyVisit();
            }
        });
    };

    return (
        <tr
            className={` ${visitData?.label ? visitData?.label : ''}`}
            css={css`
                background: ${labels[visitData?.label]};
            `}
        >
            {visitVerifyMode && (
                <td>
                    {visitData.verified ? (
                        <Button variant="danger" onClick={() => alert('Work on progress')}>
                            <BsShieldFillX fontSize={20} />
                        </Button>
                    ) : (
                        <Form.Check onChange={() => setVerifyVisitsId(visitData.id)} />
                    )}
                </td>
            )}
            <td className={`${visitData.client ? '' : 'text-danger'}`}>
                {visitData.client?._id ? (
                    <div>
                        <div>{visitData.client?.firstName + ` ` + visitData.client?.lastName}</div>
                        {visitData.client.archieved && <Badge variant="danger">Archived</Badge>}
                    </div>
                ) : (
                    'Deleted Client'
                )}
            </td>
            <td>
                {visitData.provider?._id ? (
                    <div>
                        <div>{visitData.provider.agencyName}</div>
                        <div>{visitData.provider.archieved && <Badge variant="danger">Archived</Badge>}</div>
                    </div>
                ) : (
                    <span className="text-danger">Agency deleted</span>
                )}
            </td>
            <td>
                {visitData.employee?._id ? (
                    <ToolTip text={visitData.employee?.firstName + ` ` + visitData.employee?.lastName} side="top">
                        <div>{visitData.employee?.user?.name}</div>

                        {visitData.employee.archieved && <Badge variant="danger">Archived</Badge>}
                    </ToolTip>
                ) : (
                    <span className="text-danger">Employee deleted</span>
                )}
            </td>
            <td>{dayjs(visitData.ScheduleStartTime).format('MM-DD-YYYY, h:mm A')}</td>
            <td>{dayjs(visitData.ScheduleEndTime).format('MM-DD-YYYY, h:mm A')}</td>
            <td>
                {timeConvert(dayjs(visitData.ScheduleEndTime).diff(dayjs(visitData.ScheduleStartTime), 's'))}
                {visitData.isManual && <PillBadge>Manual</PillBadge>}
                {/* {visit.verified ? <BsShieldFillCheck className="ml-2" fontSize={20} color={'#28637e'} /> : null} */}
            </td>
            <td>
                {roundDown(
                    dayjs(visitData.ScheduleEndTime).diff(dayjs(visitData.ScheduleStartTime), 'm'),
                    visit?.extraInfo?.minutesPerUnit
                )}
            </td>
            <td>
                <input
                    css={css`
                        width: 20px;
                        height: 20px;
                    `}
                    type="checkbox"
                    checked={billable}
                    onChange={(e) => {
                        handleShow({ ...visit, NewBillVisit: !billable, handleBillableSuccess }, 'donotbill');
                    }}
                    disabled={!(visit?.extraInfo?.sendTo === 'sandata') || !visit?.extraInfo?.SequenceID}
                />
            </td>
            <td>
                <div
                    className="d-flex align-items-center"
                    css={css`
                        gap: 6px;
                    `}
                >
                    <span
                        className="badge badge-sm"
                        css={css`
                            background: ${statusData[visitData?.status]};
                            color: white;
                            opacity: ${isLabelChanging ? 0.5 : 1};
                            font-size: 12px !important;
                        `}
                    >
                        {visitData?.status?.replaceAll('_', ' ') || 'complete'}
                    </span>
                    {['payment_pending', 'draft'].includes(visitData?.status) && (
                        <ToolTip
                            text="Complete Visit"
                            side="right"
                            cssStyles={`top:-70% !important; min-width: 126px!important;`}
                        >
                            <ActionStyles>
                                {isLabelChanging ? (
                                    <span className="icon edit d-flex justify-content-center align-items-center ">
                                        <VscLoading className=" spin" />
                                    </span>
                                ) : visitData?.status === 'draft' ? (
                                    <HiCheck className="icon" onClick={handleCompleteVisit} />
                                ) : (
                                    <FaUndoAlt className="icon" onClick={handleCompleteVisit} />
                                )}
                            </ActionStyles>
                        </ToolTip>
                    )}
                </div>
            </td>
            <ActionTD className="action">
                <ToolTip
                    text={visit?.extraInfo?.latestComment || visit?.extraInfo?.memo || 'View'}
                    side="left"
                    cssStyles={`top:-95% !important; ${visit?.extraInfo?.latestComment?.length || visit?.extraInfo?.memo?.length ? '' : 'min-width: 126px!important; left: -55px !important;'}`}
                >
                    <ActionStyles className="view-icon">
                        <BsEye className="icon" onClick={() => handleShow(visit, 'data')} />
                    </ActionStyles>
                </ToolTip>
                {visit?.forms?.length ? (
                    <ToolTip
                        text="Form Submissions"
                        side="left"
                        cssStyles={`top:-70% !important; min-width: 126px !important; left: -15px !important;`}
                    >
                        <ActionStyles className="view-icon">
                            <FaWpforms className="icon" onClick={() => handleShow(visit, 'forms')} />
                        </ActionStyles>
                    </ToolTip>
                ) : null}
                <ToolTip
                    text={'Change Label'}
                    cssStyles={'top:-70% !important; min-width: 126px !important; left: -15px !important;'}
                >
                    <ActionStyles>
                        {isLabelChanging ? (
                            <span className="icon edit d-flex justify-content-center align-items-center ">
                                <VscLoading className=" spin" />
                            </span>
                        ) : (
                            <StyledDropdown>
                                <Dropdown.Toggle
                                    variant="link"
                                    id="dropdown-basic"
                                    css={css`
                                        margin: 0 !important;
                                        padding: 0 !important;
                                    `}
                                >
                                    <BiLabel className="icon edit" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {Object.keys(labels).map((label) => (
                                        <Dropdown.Item key={label} onClick={() => handleLabelChange(label)}>
                                            <span
                                                css={css`
                                                    color: ${labels[label]};
                                                `}
                                            >
                                                {label}
                                            </span>
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </StyledDropdown>
                        )}
                    </ActionStyles>
                </ToolTip>

                <ToolTip
                    text={'Redirect to channel'}
                    cssStyles={'top:-70% !important; min-width: 126px !important; left: -15px !important;'}
                >
                    <ActionStyles>
                        <a
                            href={`/teams/${channelId}${formId ? `/#/form/${formId}` : ''}`}
                            className={`${channelId ? '' : 'disabled'} btn btn-link`}
                            css={css`
                                margin: 0 !important;
                                padding: 0 !important;
                            `}
                        >
                            <BsArrowBarRight className="icon edit" />
                        </a>
                    </ActionStyles>
                </ToolTip>
            </ActionTD>
        </tr>
    );
}

export default VisitRow;

const StyledDropdown = styled(Dropdown)`
    button {
        background: transparent;
        border: none;
        :hover {
            background: transparent;
        }
        &.show > .btn-success.dropdown-toggle {
            background-color: transparent;
        }
    }
`;
