
const formInput ={
	width: '100%',
	height: '4rem',
	borderRadius: '6px',
	boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
	backgroundColor: '#ffffff',
	fontSize: '1.6rem',
	outline: 'none',
};
export default formInput;
