import { css } from 'twin.macro';
import { Fragment, useState, useEffect } from 'react';
import FormSubmissionModal from '../../Features/Teams/FormSubmissionModal';
import ServiceCounter from '../../Features/Teams/ServiceCounter';
import { serviceToCounter } from '../../../_helpers';
import { useContextStore } from '../../../_context/contextStore';

function AccordionSubmissionModal(props) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [counterArray, setCounterArray] = useState([])
    const { onGoingServices } = useContextStore();
    const [onGoingServicesArr, _] = onGoingServices;

    useEffect(() => {
        setCounterArray(onGoingServicesArr?.map(serviceToCounter)?.filter(counterData => counterData) || []);
    }, [onGoingServicesArr])

    return (
        <Fragment>
            <div className="title mb-0 mr-4 d-flex align-items-center wrap"
                css={css`
					font-size: 1.8rem;
					display: flex;
					width: 100%;
					margin-left: 1.5rem;
					color: #757575;
					font-weight: bold; `} >
                {(counterArray && counterArray?.length > 0) ? "Ongoing Services :" : ""}
            </div>
            <div css={css`
						display: flex;
						${counterArray.length > 1 ? 'justify-content: start;' : 'justify-content: center;'} 
						align-items: center;
						padding: 1rem 10px;
						width: 100%;
						position: relative;
						overflow-x : auto;`}>
                {counterArray && counterArray.map((counter, counterIndex) => {
                    let offset = counter?.offset || 0;
                    const stopwatchOffset = new Date();
                    stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + offset);
                    return (
                        <ServiceCounter
                            key={counterIndex}
                            counter={counter}
                            offsetTimestamp={stopwatchOffset}
                        />
                    );
                })}
            </div>
            <FormSubmissionModal isSubmitting={isSubmitting} setIsSubmitting={setIsSubmitting} {...props} />
        </Fragment>
    );
}

export default AccordionSubmissionModal;
