import * as ActionTypes from '../actions/ActionTypes';

export default function EVV(
	state = {
		isLoading: false,
		message: null,
		allEmployees: [],
		allClients: [],
		allProviders: [],
		allServices: [],
		allVisits: [],
		evvState: {},
		evvForms: {},
		providers: {
			providers: [],
			totalProviders: 0,
		},
		employees: {
			employees: [],
			totalEmployees: 0,
		},
		clients: {
			clients: [],
			totalClients: 0,
		},
		services: {
			services: [],
			totalServices: 0,
		},
		visits: {
			visits: [],
			totalVisits: 0,
			costInfo: null,
		},
		labels: {},
	},
	action
) {
	let tempProviders, newTempProProvider_arr;
	let tempEmployees, newTempEmpEmployees_arr;
	let tempClients, newTempCliClients_arr;
	let tempServices, newTempSerServices_arr;
	let tempVisits, newTempVisits_arr;

	switch (action.type) {
		case ActionTypes.SET_ALL_PROVIDERS:
			return { ...state, allProviders: action.payload };
		case ActionTypes.SET_PROVIDERS:
			tempProviders = Object.assign({}, state.providers);
			newTempProProvider_arr = action.payload.results;
			tempProviders.providers = newTempProProvider_arr;
			tempProviders.totalProviders = action.payload.totalResults;
			return { ...state, providers: tempProviders };
		case ActionTypes.SET_NEW_PROVIDER:
			tempProviders = Object.assign({}, state.providers);
			newTempProProvider_arr = [...tempProviders.providers];
			let prov_index = state.providers.providers.findIndex((prov) => prov.id === action.payload.id);
			if (prov_index !== -1) {
				newTempProProvider_arr[prov_index] = action.payload;
				tempProviders.providers = newTempProProvider_arr;
				return { ...state, providers: tempProviders };
			}
			newTempProProvider_arr = [action.payload, ...newTempProProvider_arr];
			tempProviders.providers = newTempProProvider_arr;
			return { ...state, providers: tempProviders };

		// case ActionTypes.REMOVE_PROVIDER:
		// 	tempProviders = Object.assign({}, state.providers);
		// 	newTempProProvider_arr = [...tempProviders.providers];
		// 	let remove_index = newTempProProvider_arr.findIndex((prov) => prov.id === action.payload);
		// 	if (remove_index !== -1) {
		// 		newTempProProvider_arr.splice(remove_index, 1);
		// 		tempProviders.providers = newTempProProvider_arr;
		// 		return { ...state, providers: tempProviders};
		// 	}
		// 	return state;

		case ActionTypes.SET_ALL_EMPLOYEES:
			return { ...state, allEmployees: action.payload };
		case ActionTypes.SET_EMPLOYEES:
			tempEmployees = Object.assign({}, state.employees);
			newTempEmpEmployees_arr = Object.assign([], tempEmployees.employees);
			newTempEmpEmployees_arr = action.payload.results;
			tempEmployees.employees = newTempEmpEmployees_arr;
			tempEmployees.totalEmployees = action.payload.totalResults;
			return { ...state, employees: tempEmployees };
		case ActionTypes.SET_NEW_EMPLOYEE:
			tempEmployees = Object.assign({}, state.employees);
			newTempEmpEmployees_arr = [...tempEmployees.employees];
			let index = newTempEmpEmployees_arr.findIndex((emp) => emp.id === action.payload.id);
			if (index !== -1) {
				newTempEmpEmployees_arr[index] = {
					...newTempEmpEmployees_arr[index],
					...action.payload,
				};
				tempEmployees.employees = newTempEmpEmployees_arr;
				return { ...state, employees: tempEmployees };
			}
			newTempEmpEmployees_arr = [action.payload, ...newTempEmpEmployees_arr];
			tempEmployees.employees = newTempEmpEmployees_arr;
			return { ...state, employees: tempEmployees };

		case ActionTypes.UPDATE_EMPLOYEE:
			const updatedEmployees = state?.employees?.employees?.map((employee)=>employee?.id === action.payload?.id ? {...employee, ...action.payload} : employee)
			return { ...state, employees: {...state.employees, employees: updatedEmployees} };

		case ActionTypes.SET_ALL_CLIENTS:
			return { ...state, allClients: action.payload };
		case ActionTypes.SET_CLIENTS:
			tempClients = Object.assign({}, state.clients);
			newTempCliClients_arr = [...tempClients.clients];
			newTempCliClients_arr = action.payload.results;
			tempClients.clients = newTempCliClients_arr;
			tempClients.totalClients = action.payload.totalResults;
			return { ...state, clients: tempClients };
		case ActionTypes.SET_NEW_CLIENT:
			tempClients = Object.assign({}, state.clients);
			newTempCliClients_arr = [...tempClients.clients];
			let client_index = state.clients.clients.findIndex((client) => client.id === action.payload.id);
			if (client_index !== -1) {
				newTempCliClients_arr[client_index] = action.payload;
				tempClients.clients = newTempCliClients_arr;
				return { ...state, clients: tempClients };
			}
			newTempCliClients_arr = [action.payload, ...newTempCliClients_arr];
			tempClients.clients = newTempCliClients_arr;
			return { ...state, clients: tempClients };

		case ActionTypes.UPDATE_CLIENT:
			const updatedClients = state?.clients?.clients?.map((client)=>client?.id === action.payload?.id ? {...client, ...action.payload} : client)
			return { ...state, clients: {...state.clients,clients: updatedClients} };

		case ActionTypes.SET_ALL_SERVICES:
			return { ...state, allServices: action.payload };
		case ActionTypes.SET_SERVICE:
			tempServices = Object.assign({}, state.services);
			newTempSerServices_arr = action.payload.results;
			tempServices.services = newTempSerServices_arr;
			tempServices.totalServices = action.payload.totalResults;
			return { ...state, services: tempServices };
		case ActionTypes.SET_NEW_SERVICE:
			tempServices = Object.assign({}, state.services);
			newTempSerServices_arr = [...tempServices.services];
			let service_index = state.services.services.findIndex(
				(service) => (service.id || service._id) === action.payload.id
			);
			let doesServiceAlreadyExists = service_index >= 0;
			if (doesServiceAlreadyExists) {
				newTempSerServices_arr[service_index] = action.payload;
				tempServices.services = newTempSerServices_arr;
				return { ...state, services: tempServices };
			}
			newTempSerServices_arr = [action.payload, ...newTempSerServices_arr];
			tempServices.services = newTempSerServices_arr;
			return { ...state, services: tempServices };

		case ActionTypes.SET_ALL_VISITS:
			console.log(action.payload, 'payload');
			return { ...state, allVisits: action.payload.results };

		case ActionTypes.SET_VISITS:
			// console.log(action.payload, 'payload');
			tempVisits = Object.assign({}, state.visits);
			newTempVisits_arr = action.payload?.results;
			tempVisits.visits = newTempVisits_arr;
			tempVisits.totalVisits = action.payload.totalResults;
			tempVisits.costInfo = action.payload.costInfo || state.visits?.costInfo;
			return { ...state, visits: tempVisits };

		case ActionTypes.SET_VISITS_COST_INFO:
			return {
				...state, visits: {
					...state.visits,
					costInfo: action.payload
				}
			}

		case ActionTypes.UPDATE_VISIT:
			tempVisits = Object.assign({}, state.visits);
			let newVisit = [...tempVisits.visits];

			let update_visit_index = newVisit.findIndex((visit) => visit.id === action.payload.id);

			newVisit[update_visit_index] = action.payload.visit;
			tempVisits.visits = newVisit;
			return { ...state, visits: tempVisits };

		case ActionTypes.SET_NEW_VISIT:
			tempVisits = Object.assign({}, state.Visits);
			newTempVisits_arr = [...tempVisits.Visits];
			let visit_index = state.allVisits.findIndex((visit) => (visit.id || visit._id) === action.payload.id);
			let doesVisitAlreadyExists = visit_index >= 0;
			if (doesVisitAlreadyExists) {
				newTempVisits_arr[visit_index] = action.payload;
				tempVisits.visits = newTempVisits_arr;
				return { ...state, visits: tempVisits };
			}
			newTempVisits_arr = [action.payload, ...newTempVisits_arr];
			tempVisits.visits = newTempVisits_arr;
			return { ...state, visits: tempVisits };

		case ActionTypes.SET_EVVSTATE:
			return { ...state, evvState: action.payload };

		case ActionTypes.SET_EVVFORMS:
			return { ...state, evvForms: action.payload };

		case ActionTypes.SET_EVV_LOADING:
			return {
				...state,
				isLoading: true,
			};
		case ActionTypes.CLEAR_EVV_LOADING:
			return {
				...state,
				isLoading: false,
			};

		case ActionTypes.SET_LABELS:
			return {
				...state,
				labels: action.payload,
			};
		default:
			return state;
	}
}
