import React from 'react';
import Linkify from 'react-linkify';

const Header = (props) => (
	<div>
		<h1>{props.data.fieldName}</h1>
		<div>
			<pre className="small text-muted warpIt preStyles">
				<Linkify
					componentDecorator={(decoratedHref, decoratedText, key) => (
						<a target="blank" href={decoratedHref} key={key}>
							{decoratedText}
						</a>
					)}
				>
					{props.data.instruction}
				</Linkify>
			</pre>
		</div>
	</div>
);

export default Header;
