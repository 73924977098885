import styled from '@emotion/styled';

export const EditFormStyles = styled('div')`
	padding: 1rem 2rem;
	.form-element {
		transition: all 1s ease-in;
		overflow-wrap: break-word;
	}
	.form-element:hover {
		border: 1px solid #000;
	}
	.form-element-control {
		display: none;
	}
	.form-element:hover * .form-element-control {
		display: block;
	}
`;
