import React from "react";
import { SRLWrapper } from 'simple-react-lightbox';
import Linkify from 'react-linkify';
import { PhotoDivStyled } from './styles/PhotoStyles';

const Photo = (props) => {
	let value;

	try {
		if(props.data.data){
			if (props.data.data?.file) {
				value = props.data.data?.file;
			} else if (props.data.data[0]?.file) {
				value = props.data.data[0]?.file;
			}
		}
		
	} catch (err) {
		console.log(err, 'cant get photo');
	}

	return (
		<PhotoDivStyled className="d-flex flex-column w-100">
			<h5>{props.data.fieldName}</h5>
			<div>
				<pre className="small text-muted warpIt preStyles">
					<Linkify
						componentDecorator={(decoratedHref, decoratedText, key) => (
							<a target="blank" href={decoratedHref} key={key}>
								{decoratedText}
							</a>
						)}
					>
						{props.data.instruction}
					</Linkify>
				</pre>
			</div>
			{value && (
				<div className="photo">
					<SRLWrapper>
						<img src={value} alt={props.data.fieldName} />
					</SRLWrapper>
				</div>
			)}
		</PhotoDivStyled>
	);
};
export default Photo;
