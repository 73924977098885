import React, { useState } from 'react';

import { css } from 'twin.macro';

import { Button, Modal, Tabs, Tab } from 'react-bootstrap';
import { ToolTip } from '../../../../../_elements/ToolTip';
import { MdAddCard } from 'react-icons/md';
import ZealCompanyOnboarding from './ZealCompanyOnboarding';
import DeductionTemplates from './DeductionTemplates';
import ZealReimbursement from './ZealReimbursement';
import AccrualPolicyContainer from './AccrualPolicy';

function ZealCompanyTabsModal({ provider, companyID, payrollMetadata, teamId }) {
    const [tabKey, setTabKey] = useState('zeal-onboarding');
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    };
    const handleModalShow = () => {
        setShow(true);
    };

    return (
        <>
            <ToolTip text={'Zeal Onboarding'} side={'top'} cssStyles={'top:-70% !important;'}>
                <MdAddCard className="icon edit" onClick={handleModalShow} />
            </ToolTip>
            <Modal backdrop="static" size="lg" show={Boolean(show)} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div
                            css={css`
                                display: flex;
                                gap: 12px;
                                align-items: center;
                            `}
                        >
                            <span>Zeal Onboarding</span>
                            <span className="badge badge-warning" style={{ padding: '6px 8px', fontSize: '14px' }}>
                                {provider?.payroll?.metadata?.status?.replaceAll('_', ' ')}
                            </span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ overflowY: 'auto', overflowX: 'disable', height: '80vh', padding: '12px' }}>
                    <Tabs id="zeal-controlled-tab" activeKey={tabKey} onSelect={(k) => setTabKey(k)}>
                        <Tab eventKey="zeal-onboarding" title="Zeal Onboarding">
                            <ZealCompanyOnboarding
                                provider={provider}
                                companyID={companyID}
                                show={tabKey === 'zeal-onboarding'}
                                teamId={teamId}
                            />
                        </Tab>
                        <Tab eventKey="zeal-deduction" title="Deduction Template">
                            <DeductionTemplates
                                companyID={companyID}
                                show={tabKey === 'zeal-deduction'}
                                teamId={teamId}
                            />
                        </Tab>
                        <Tab eventKey="zeal-reimbursement" title="Reimbursement">
                            <ZealReimbursement
                                companyID={companyID}
                                payrollMetadata={payrollMetadata}
                                show={tabKey === 'zeal-reimbursement'}
                                teamId={teamId}
                            />
                        </Tab>
                        <Tab eventKey="zeal-accrual-policy" title="Accrual Policy">
                            <AccrualPolicyContainer
                                companyID={companyID}
                                payrollMetadata={payrollMetadata}
                                show={tabKey === 'zeal-accrual-policy'}
                                teamId={teamId}
                            />
                        </Tab>
                    </Tabs>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ZealCompanyTabsModal;
