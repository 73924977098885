import { css } from 'twin.macro';
import React from 'react';
import { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import settingsRoutes from './settingRoutes';

const loading = 'loading';

const TeamSettingsContent = ({ channelDetails }) => {
	return (
		<div
			css={css`
				margin: 20px;
				background: white;
				min-height: 60vh;
				padding: 2rem;
				border-radius: 15px;
			`}
		>
			<Suspense fallback={loading}>
				<Switch>
					{settingsRoutes.map((route, idx) => {
						return (
							route.component && (
								<Route
									key={idx}
									path={route.path}
									exact={route.exact}
									name={route.name}
									render={(props) => <route.component {...props} channelDetails={channelDetails} />}
								/>
							)
						);
					})}
				</Switch>
			</Suspense>
		</div>
	);
};
export default TeamSettingsContent;
