import React from 'react';
import { css } from 'twin.macro';
import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import MessageDate from '../../../Features/Common/MessageDate';
import SenderMessage from '../../../Features/Teams/SenderMessage';
import ReceiverMessage from '../../../Features/Teams/ReceiverMessage';
import { editSubChannelMessage, deleteSubChannelMessage } from '../../../../_redux';

const Message = ({
	message,
	date,
	currUser,
	subChannelId,
	setReply,
	subChannelType,
	messages,
	refs,
	lastSeen,
	deleteSubChannelMessage,
	editSubChannelMessage,
	position,
	userRole,
	roomData,
	showMessageReactions,
}) => {
	const handleDeleteMessage = (messageId) => {
		if (window.confirm('Do you want to delete this message?')) {
			deleteSubChannelMessage(subChannelId, messageId);
		}
	};
	const sendEditedMessage = (reply, data, edit, position) => {
		if (edit) {
			const msg = {};
			msg.text = data;

			if (position) {
				msg.location = { latitude: position.coords.latitude, longitude: position.coords.longitude };
			} else {
				msg.location = { latitude: null, longitude: null };
			}
			if (reply) {
				msg.reply = reply;
			}
			editSubChannelMessage(subChannelId, edit, { data: JSON.stringify(msg) });
		}
	};

	const handleClick = (id) => {
		if (messages.findIndex((mes) => mes.id === id) !== -1) {
			if (refs[id].current) {
				refs[id].current.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
					inline: 'nearest',
				});

				refs[id].current.style.borderRadius = '15px';
				refs[id].current.style.boxShadow = '0 0px 10px 4px #50aeb0';
				refs[id].current.style.transition = 'all 500ms ease-in-out 500ms';
				window.setTimeout(() => {
					if (refs[id].current) refs[id].current.style.boxShadow = 'none';
				}, 2000);
			}
		} else {
		}
	};
	const handleMessageClick = () => {};

	return (
		<Fragment>
			<MessageDate date={date} />
			<Fragment>
				{message.map((m, i) => {
					const { id, type, user } = m;

					if (type === 'event') {
						return null;
					}

					return currUser && ((user?.id || user?._id) === currUser._id || user === currUser._id) ? (
						// CURRENT USER MESSAGE
						<Fragment key={i}>
							<SenderMessage
								m={m}
								subChannelId={subChannelId}
								roomData={roomData}
								setReply={setReply}
								position={position}
								refs={refs}
								handleClick={handleClick}
								handleMessageClick={handleMessageClick}
								currUser={currUser}
								sendEditedMessage={sendEditedMessage}
								handleDeleteMessage={handleDeleteMessage}
								subChannelType={subChannelType}
								userRole={userRole}
								showMessageReactions={showMessageReactions}
							/>
							{lastSeen === id && (
								<p className="d-flex border-bottom  border-white pt-5  m-0 mb-2 text-muted  justify-content-center ">
									<span
										className="bg-light px-4 py-2"
										css={css`
											border-radius: 10px 10px 0px 0px;
										`}
									>
										New Messages
									</span>
								</p>
							)}
						</Fragment>
					) : (
						// OTHER USERS MESSAGE
						<Fragment key={id}>
							<ReceiverMessage
								m={m}
								subChannelId={subChannelId}
								roomData={roomData}
								setReply={setReply}
								position={position}
								refs={refs}
								handleClick={handleClick}
								handleMessageClick={handleMessageClick}
								currUser={currUser}
								handleDeleteMessage={handleDeleteMessage}
								subChannelType={subChannelType}
								userRole={userRole}
								showMessageReactions={showMessageReactions}
							/>
						</Fragment>
					);
				})}
			</Fragment>
		</Fragment>
	);
};

const mapStateToProps = (state) => ({
	currUser: state.user.user,
	position: state.user.position,
});

Message.propTypes = {
	message: PropTypes.array.isRequired,
	date: PropTypes.string.isRequired,
	currUser: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { deleteSubChannelMessage, editSubChannelMessage })(Message);
