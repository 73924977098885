import { css } from 'twin.macro';
import React from 'react';
import { useEffect } from 'react';
import { fetchWrapper, getRandomColor, debounce } from '../../../_helpers';
import { useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { BiRadioCircle, BiRadioCircleMarked } from 'react-icons/bi';
import styled from '@emotion/styled';
import { VscLoading } from 'react-icons/vsc';

const UserImage = css`
	display: flex;
	align-items: center;
	.userImage {
		width: 5rem;
		height: 5rem;
		margin: 0 1rem;
		border-radius: 1000px;
		object-fit: contain;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		font-size: 14px;
		&.mini {
			width: 3rem;
			height: 3rem;
		}
	}
`;

const getEventTypeOption = (eventTypeData) => ({
	label: (
		<div css={UserImage}>
			<div className='py-2'>
				{eventTypeData.image ? (
					<img className="userImage" src={eventTypeData.image} alt={eventTypeData.name} />
				) : (
					<div
						className="userImage"
						style={{ background: getRandomColor(eventTypeData.id || eventTypeData._id || eventTypeData.value) }}
					></div>
				)}
			</div>
			{eventTypeData.title}
		</div>
	),
	value: eventTypeData.id || eventTypeData._id || eventTypeData.value,
	id: eventTypeData.id || eventTypeData._id || eventTypeData.value,
	...eventTypeData,
});

const ReactSelectComponent = ({
	selectedOption = null,
	setSelectedOption,
	eventType,
	disabled = false,
	mode = 'create',
}) => {
	const [options, setOptions] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const fetchData = async (inputValue) => {
		try {
			setIsLoading(true);
			if (mode === 'view' || (mode === 'edit' && eventType)) {
				eventType?.title && setOptions([getEventTypeOption(eventType)]);
			} else {
				const eventTypes = await fetchWrapper.get(`eventType?sortBy=title:asc&limit=50&${inputValue ? `&title=${inputValue}` : ''}`);
				const eventTypeOptions = eventTypes?.results.map(getEventTypeOption);
				setOptions(eventTypeOptions);
			}
		} catch (err) {
			console.log(err);
		}
		finally{
			setIsLoading(false);
		}
	};
	const debouncedFetchData = debounce(fetchData);

	useEffect(() => {
		const setEventTypeOption = async () => {
			const eventTypeData = eventType?.title ? eventType : eventType ? await fetchWrapper.get(`eventType/${eventType}`) : {};
			if (!eventTypeData?._id) {
				return debouncedFetchData();
			}
			const eventTypeOption = getEventTypeOption(eventTypeData);
			setSelectedOption(eventTypeOption);
			setOptions([eventTypeOption]);
		}

		if (!selectedOption?.value) {
			setEventTypeOption();
		}
	}, [eventType, selectedOption]);

	const handleSelect = (option) => {
		if (disabled) {
			return;
		}
		setSelectedOption(option);
	};
	return (
		<div className={`border rounded p-2 `}>
			{mode === 'create' ? (
				<div
					className="mb-4"
					css={css`
						position: relative;
					`}
				>
					<input
						className="p-2  w-100"
						type="text"
						onChange={(e) => debouncedFetchData(e?.target?.value || '')}
						placeholder="Search Event type..."
					/>
					<FaSearch
						css={css`
							position: absolute;
							top: 14px;
							right: 15px;
						`}
					/>
				</div>
			) : null}
			{ isLoading ? 
				(<div css={css`display:flex;justify-content:center;`}>
					<VscLoading className="spin" style={{ fontSize: '5rem', textAlign: 'center' }} />
				</div>)
				 :
			(<div
				className="container"
				css={css`
					max-height: 400px;
					overflow: auto;
				`}
			>
				<div className="row">
					{options.length ? (
						options.map((option) => (
							<div
								className={`col-6 p-2`}
								key={option.id}
								data-value={option.value}
								onClick={(e) => handleSelect(option)}
							>
								<StyledRadioOption
									className={` 
									d-flex
									align-items-center
								 ${option.id === selectedOption?.id ? 'selected' : 'not-selected'}
								 ${disabled && 'disabled'}`}
								>
									{option.id === selectedOption?.id ? <BiRadioCircleMarked /> : <BiRadioCircle />}
									{option.label}
								</StyledRadioOption>
							</div>
						))
					) : (
						<div className="text-center my-3 w-100">No event types </div>
					)}
				</div>
			</div>)
			}
		</div>
	);
};

export default ReactSelectComponent;

const StyledRadioOption = styled.div`
	border-radius: 0.25rem;
	&.not-selected {
		cursor: pointer;
		border: 1px solid #dee2e6 !important;
	}
	&.selected {
		cursor: default;
		border: 2px solid #50aeb0;
	}
	&.disabled {
		cursor: default;
	}
	svg {
		font-size: 34px;
		color: #50aeb0;
	}
`;
