import React from 'react';
import draftToHtml from 'draftjs-to-html';

const Paragraph = (props) => {
	let bodyValue;
	let isHtml = false;
	try {
		bodyValue = JSON.parse(props.data.instruction);
		bodyValue = draftToHtml(bodyValue);
		isHtml = true;
	} catch (err) {
		bodyValue = props.data.instruction;
		isHtml = false;
	}
	return (
		<div>
			<label>
				<h5 className="warpIt">{props.data.fieldName}</h5>
			</label>
			<pre className="small warpIt preStyles">
				{isHtml ? <div dangerouslySetInnerHTML={{ __html: bodyValue }} /> : <div>{bodyValue}</div>}
			</pre>
		</div>
	);
};
export default Paragraph;
