import { css } from 'twin.macro';
import Select from 'react-select';
import React, { Fragment, useEffect, useState } from 'react';
import { buildQueryString, fetchWrapper } from '../../../../../../../_helpers';
import DownloadReport from '../DownloadReport';
const employementStatusTypes = ['live', 'leave', 'terminated'];
const kycStatusTypes = ['all', 'failed', 'approved'];
const KYCSummary = ({ companyID, slug }) => {
    const [downloadLink, setDownloadLink] = useState('');
    const handleDownloadLink = (link) => setDownloadLink(link);
    const [employmentStatus, setEmploymentStatus] = useState({});
    const [kycStatus, setKycStatus] = useState({});

    const generateLink = async () => {
        const queryString = buildQueryString({
            employmentStatus: employmentStatus.value,
            kycStatus: kycStatus.value,
        });
        const response = await fetchWrapper.get(
            `evv/payroll/zeal/provider/${companyID}/reports/${slug}?${queryString}`
        );
        handleDownloadLink(response.url);
    };

    useEffect(() => {
        handleDownloadLink('');
    }, [kycStatus.value, employmentStatus.value]);

    return (
        <Fragment>
            <div
                css={css`
                    width: 300px;
                `}
            >
                <span>Employment status: </span>
                <Select
                    value={employmentStatus}
                    onChange={(option) => setEmploymentStatus(option)}
                    options={employementStatusTypes.map((option) => ({ label: option, value: option }))}
                />
            </div>
            <div
                css={css`
                    width: 300px;
                    margin-top: 8px;
                `}
            >
                <span>KYC status: </span>
                <Select
                    value={kycStatus}
                    onChange={(option) => setKycStatus(option)}
                    options={kycStatusTypes.map((option) => ({ label: option, value: option }))}
                />
            </div>
            <hr />
            <DownloadReport
                disabled={!employmentStatus.value || !kycStatus.value}
                onGenerateLink={generateLink}
                downloadLink={downloadLink}
                companyID={companyID}
                slug={slug}
                mediaType="csv"
            />
        </Fragment>
    );
};

export default KYCSummary;
