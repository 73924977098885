import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { css } from 'twin.macro';

function OurAccordionMultiple({ title, children, titleTag = 'h4', eventKey, isOpen }) {
	return (
		<React.Fragment>
			<Accordion.Toggle
				as={Card.Header}
				variant="link"
				eventKey={eventKey}
				className="d-flex align-items-center justify-content-between"
				css={css`cursor: pointer;border-top:1px solid gray;`}
			>
				{title}
				<div>{isOpen ? <FaCaretUp size="24" /> : <FaCaretDown size="24" />}</div>
			</Accordion.Toggle>
			<Accordion.Collapse eventKey={eventKey}>
				<Card.Body>{children}</Card.Body>
			</Accordion.Collapse>
		</React.Fragment>
	);
}

export default OurAccordionMultiple;
