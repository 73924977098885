import React from 'react';
import { css } from 'twin.macro';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import { Body } from './styles';

import ClientForm from './ClientForm';

import { BsPencil } from 'react-icons/bs';
import { ToolTip } from '../../../../_elements/ToolTip';

const EditClient = ({ client, isTeamSettings, team }) => {
	const [showModal, setShowModal] = useState(false);
	return (
		<>
			<ToolTip text={'Edit'} side={'top'} cssStyles={'top:-70% !important; min-width: fit-content !important'}>
				<BsPencil className="icon edit" onClick={() => setShowModal(true)} />
			</ToolTip>

			<Modal
				show={showModal}
				backdropClassName="bg-dark"
				onHide={() => setShowModal(false)}
				centered
				backdrop="static"
				// scrollable={true}
				// size="lg"
			>
				<Modal.Header closeButton>
					<div className="header">
						<h4
							css={css`
								margin: 0;
							`}
						>
							Edit Client
						</h4>
					</div>
				</Modal.Header>
				<Modal.Body className="modal-body">
					<Body>
						<ClientForm
							setShowModal={setShowModal}
							client={client}
							formType="Edit"
							isTeamSettings={isTeamSettings}
							team={team}
						/>
					</Body>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default EditClient;
