import styled from "@emotion/styled";
import { Modal } from "react-bootstrap";

export const FormFilterModalStyled = styled('div')`

	.buildNewForm_Btn {
		color: #50aeb0;
		width: 100%;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		padding: 4%;
		border: 3px solid #50aeb0;
		border-radius: 10px;
		font-size: 2rem;
		transition: color 350ms, background-color 350ms;
		cursor: pointer;
		flex-direction: row;
		:hover {
			color: white;
			background: #50aeb0;
		}
	}
	.filter-form {
		background-color: #3B4B64;
		color: white;
		height: 40px;
	}
`;

export const FormModalStyled = styled(Modal)`
	.hiddenInput {
		position: absolute !important;
		opacity: 0 !important;
		pointer-events: none !important;
	}
	.SubmittedTimeFrameCss {
		background: #ebebeb;
		padding: 1em;
		border-radius: 15px;
	}
`;