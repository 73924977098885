import React, { useState, useEffect, Fragment } from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { BsEye } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { Tab, Tabs } from 'react-bootstrap';
import { BsGrid, BsList } from 'react-icons/bs';

import { ActivityActionPackageStyled } from './styles/ActivityActionPackageStyled';
import NavigationBar from '../reusableComponents/NavigationBar';
import Loader from '../containers/Loader';
import { getAllRooms, getRoomsByTeam } from '../../../_redux';
import { GetAllTeams } from '../../../_redux';
import { setMessage } from '../../../_redux';
import { editTheTask, postATask } from '../../../_redux/actions/ActionTasks';
import { fetchWrapper, sortingOptionMethod, areAllArraysUnorderedEqual } from '../../../_helpers';
import { AdminTable } from '../reusableStyles';
import { ActionStyles } from '../reusableStyles';
import PreviewEventModal from '../Tasks/PreviewEventModal';
import EventModal from '../Tasks/EventModal';
import { getTeamIdFromURL, prepareEvent } from '../../../_helpers';
import EventPlannerCalendar from './EventPlannerCalendar';
import EventAssignedChannel from './EventAssignedChannel';
import AssignChannelToPackageModal from './AssignChannelToPackageModal';
import ValidateUserAssignedToEntirePackageModal from './ValidateUserAssignedToEntirePackageModal';
import { toast } from 'react-toastify';
import TaskSelectFilter from '../TaskSelectFilter';
import TeamSelectFilter from '../TeamSelectFilter';

const getUniqueTasks = (prevTasks, newTasks) => {
	prevTasks = prevTasks.filter((x) => x);
	newTasks = newTasks.filter((x) => x);
	const _ids = [...new Set([...prevTasks.map((t) => t._id || t.id), ...newTasks.map((t) => t._id || t.id)])];
	return _ids.map((_id) => [...prevTasks, ...newTasks].find((t) => (t._id || t.id) === _id));
};

const ActionActivityPackageTemplate = ({
	chatroom = null,
	organizations,
	getAllRooms,
	rooms,
	GetAllTeams,
	teams,
	match,
}) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [actionMode, setActionMode] = useState(null);
	const [packageName, setPackageName] = useState(null);
	const [loading, setLoading] = useState(false);
	const [selectedTeamOption, setSelectedTeamOption] = useState([]);
	const [allTasks, setAllTasks] = useState([]);
	const [selectedTasks, setSelectedTasks] = useState([]);
	const [packageData, setPackageData] = useState(null);
	const { register, handleSubmit } = useForm({
		mode: 'onBlur',
	});
	const [showModal, setShowModal] = useState(null);
	const [channel, setChannel] = useState(null);
	const { channelId } = useParams();
	const teamId = getTeamIdFromURL();

	const [key, setKey] = useState('event_list');
	const [globalChannel, setGlobalChannel] = useState(null);
	const [showValidateModel, setShowValidateModel] = useState(false);
	const [teamsPrivatePublic, setTeamsPrivatePublic] = useState('');
	const [intersectionOfAssignes, setIntersectionOfAssignes] = useState([]);
	const [finalizedAssignedUsers, setFinalizedAssignedUsers] = useState([]);
	const [confirmAssignSubmisstion, setConfirmAssignSubmisstion] = useState(false);
	const [formPostData, setFormPostData] = useState({});

	const [isSubmitting, setIsSubmitting] = useState(false);

	useEffect(() => {
		if (match.params.id) {
			fetchWrapper.get(`/activityPackageTemplates/${match.params.id}`).then((res) => {
				if (res[0]) {
					res[0].taskData.forEach((task, index) => {
						delete task.assignedChannel;
						task._id = index;
					});
					setPackageData(res[0]);
				}
			});
			setActionMode(match.params.mode);
		}
	}, []);

	useEffect(() => {
		if (organizations.length && Object.keys(rooms).length === 0) {
			setLoading(true);
			getAllRooms();
			GetAllTeams(organizations[0].id);
			setLoading(false);
		}
	}, [GetAllTeams, getAllRooms, organizations, rooms]);

	useEffect(() => {
		setSelectedTeamOption(chatroom);
	}, [chatroom]);

	useEffect(() => {
		setLoading(true);
		if (teamId) {
			const selectedTeam = teams.filter((team) => team.id === teamId);
			let teamsOptions = selectedTeam.map((team) => {
				let teamData = {
					label: team.name,
					value: team._id,
				};
				return teamData;
			});
			if (teamsOptions) {
				setSelectedTeamOption(teamsOptions[0]);
			}
		}
		setLoading(false);
	}, [rooms, teams, teamId]);


	const onTaskSearchChange = (allSelectedTasks) => {
		setSelectedTasks((prev) => {
			const oldValues = prev.map((task) => task.value);
			const newSelectedTask = allSelectedTasks.find((task) => !oldValues.includes(task.value));
			if (newSelectedTask && (selectedTeamOption?.value || teamId) !== newSelectedTask?.team?.id) {
				toast.error('This is a borrowed event. Please assign a new channel.');
			}
			return allSelectedTasks || [];
		});
	};

	const handleShowEvent = (id) => {
		setShowModal(id);
	};

	const handleClose = () => {
		setShowModal(null);
	};

	useEffect(() => {
		if (packageData) {
			// PackageName
			if (actionMode === 'edit' || actionMode === 'build' || !actionMode) {
				let packageName = packageData?.name || '';
				setPackageName(packageName);
			}
			// teams
			let teamsOptions = (packageData?.teamData || []).map((team) => {
				let teamOption = {
					value: team.id,
					label: team.name,
				};
				return teamOption;
			});
			if (!teamId) {
				setSelectedTeamOption(teamsOptions[0]);
			}

			// task
			let tasksOptions = (packageData?.taskData || []).map((task) => {
				const _id = `NEW_TASK_${Date.now()}_${Math.round(Math.random() * 100)}`;
				let taskOption = {
					value: _id,
					label: task.title,
					...task,
					_id,
				};
				return taskOption;
			});
			setSelectedTasks(tasksOptions);
			setAllTasks((prev) => getUniqueTasks(prev, tasksOptions));
		}
	}, [actionMode, setPackageName, setSelectedTeamOption, packageData]);

	const handleEditClick = (id, newTask) => {
		return onTaskUpdateCallback(newTask, 'edit', id);
	};

	const onTaskUpdateCallback = (task, mode = 'create', id) => {
		//patch allTask & selectedTasks
		if (mode === 'create') {
			task._id = `NEW_TASK_${Date.now()}_${Math.round(Math.random() * 100)}`;
			setAllTasks((prev) => [...prev, { ...task, label: task.title, value: task._id }]);
			setSelectedTasks((prev) => [...prev, { ...task, label: task.title, value: task._id }]);
		} else {
			setAllTasks((prev) =>
				prev.map((exTask) =>
					exTask._id === id ? { ...task, _id: id, label: task.title, value: id } : { ...exTask }
				)
			);
			setSelectedTasks((prev) =>
				prev.map((exTask) =>
					exTask._id === id ? { ...task, _id: id, label: task.title, value: id } : { ...exTask }
				)
			);
		}
		return task;
	};

	useEffect(() => {
		if (channelId) {
			fetchWrapper.get(`chatrooms/${channelId}`).then((res) => setChannel(res));
		}
	}, [channelId]);

	useEffect(() => {
		const completeSubmission = async (postPackageData) => {
			setIsSubmitting(true);
			try {
				const updatedPostPackageData = {
					...postPackageData,
					assignedUser: finalizedAssignedUsers,
				};

				await fetchWrapper.post(`activityPackages`, updatedPostPackageData);
				dispatch(setMessage({ error: false, message: 'Plan created successfully' }));
				history.goBack();
			} catch (error) {
				dispatch(setMessage({ error: true, message: error.message || "Something went wrong" }));
			} finally {
				setIsSubmitting(false);
				setConfirmAssignSubmisstion(false);
			}
		};
		if (confirmAssignSubmisstion) {
			completeSubmission(formPostData);
		}
	}, [confirmAssignSubmisstion]);

	const onSubmit = async (data) => {
		setTeamsPrivatePublic('');
		if (!data) {
			return null;
		}
		try {
			const selectedTeamId = selectedTeamOption.value;
			const postPackageData = {
				name: data.packageName,
				team: selectedTeamId || teamId,
				events: selectedTasks.map(prepareEvent),
				assignedUser: selectedTasks[0]?.assignedUser,
			};
			setFormPostData(postPackageData);
			if (!postPackageData.events.length) {
				throw new Error('Please select at least one event');
			}

			if (!postPackageData.team) {
				throw new Error('Please assign team to the plan');
			}
			postPackageData.events.map((event) => {
				const room =
					event.assignedChannel &&
					rooms[postPackageData.team].rooms.find((room) => room._id === event.assignedChannel[0]);
				if (!room) {
					throw new Error(
						`Room assigned to event ${event.title} is not in team ${selectedTeamOption?.label}.`
					);
				}
			});

			const allEventAssignedUsers = postPackageData.events.map((event) => event.assignedUser);
			const { intersection } = areAllArraysUnorderedEqual(allEventAssignedUsers);
			setIntersectionOfAssignes(intersection);
			let privateChannels = [];
			let publicChannels = [];
			selectedTasks?.forEach(({ assignedChannelInfo }) => {
				const channelInfo = Array.isArray(assignedChannelInfo) ? assignedChannelInfo[0] : assignedChannelInfo;
				const channelId = channelInfo?._id || channelInfo?.id || channelInfo?.value;
				channelId && (channelInfo?.type === 'private' ? privateChannels : publicChannels).push(channelId);
			});
			setShowValidateModel(true);
			setTeamsPrivatePublic(
				`${privateChannels?.length ? `&private=${privateChannels}` : ''}${publicChannels?.length ? `&public=${publicChannels}` : ''
				}`
			);
			setTeamsPrivatePublic('');
			return;
		} catch (e) {
			console.log(e);
			dispatch(setMessage({ error: true, message: `Error ${e.error || e.message}` }));
		}
	};

	// const [selectedRoomOption, setSelectedRoomOption] = useState(null);

	const [showGlobal, setShowGlobal] = useState(false);

	const GlobalhandleClose = () => setShowGlobal(false);
	const GlobalhandleShow = () => setShowGlobal(true);

	const [isGlobal, setIsGlobal] = useState(false);
	const handleGlobalAssign = () => {
		setIsGlobal(true);
		GlobalhandleShow();
	};

	const onAssignChannelToEvent = (_id, event) => {
		onTaskUpdateCallback(event, 'edit', _id);
	};

	const onAssignChannelToAllEvents = (_, data) => {
		if (!data) {
			return null;
		}
		const { channel, assignedUsers: assignedUser } = data;
		const assignedChannel = [channel?.value];
		const assignedChannelInfo = [channel];
		setAllTasks((prev) => prev.map((task) => ({ ...task, assignedChannel, assignedChannelInfo, assignedUser })));
		setSelectedTasks((prev) => prev.map((task) => ({ ...task, assignedChannel, assignedChannelInfo, assignedUser })));
	};

	return (
		<ActivityActionPackageStyled>
			<div className="d-flex justify-content-between mb-4">
				<NavigationBar mainTabName="Master Event Planner" tab1="Build Plan from Template" />
			</div>

			{loading ? (
				<Loader size="sm" />
			) : (
				<div className="d-flex flex-column align-items-center justify-content-between position-relative pt-5">
					<div className="top-items flex justify-content-end">
						<EventModal
							channel={channel}
							handleSubmitTask={onTaskUpdateCallback}
						// teamIds={[]}
						/>
					</div>

					<Form className="custom-card w-75 m-auto" onSubmit={handleSubmit(onSubmit)}>
						<Form.Row>
							<Form.Group as={Col} md={12}>
								<Form.Label>Plan Name</Form.Label>
								<Form.Control
									type="text"
									value={packageName || ''}
									onChange={(e) => setPackageName(e.target.value)}
									name="packageName"
									required
									ref={register}
								/>
								{/* <Form.Text className="text-danger">{error.name}</Form.Text> */}
							</Form.Group>
							{teamId ? null : (
								<Form.Group as={Col} md={12}>
									<Form.Label>Teams</Form.Label>
									<TeamSelectFilter
										selectedTeamOption={selectedTeamOption}
										onChange={setSelectedTeamOption}
										className="bolder"
										required
										name="team"
									/>
									{/* <Form.Text className="text-danger">{error.room}</Form.Text> */}
								</Form.Group>
							)}
							<Form.Group as={Col} md={12}>
								<Form.Label>Events</Form.Label>
								<TaskSelectFilter
									selectedOption={selectedTasks?.length ? selectedTasks : []}
									name="tasks"
									defaultTasks={allTasks}
									onTaskSelect={onTaskSearchChange}
									required
									isMulti
									teamId={teamId}
								/>
								<Tabs
									id="controlled-tab-example"
									activeKey={key}
									onSelect={(k) => setKey(k)}
									className="mb-3"
								>
									<Tab eventKey="event_list" title={<BsList />}>
										{key === 'event_list' && (
											<AdminTable className="mb-5 mt-5 w-100">
												<thead>
													<tr>
														<th>S.N.</th>
														<th>Event Name</th>
														<th>Date</th>
														<th>Channel</th>
														<th>Actions</th>
													</tr>
												</thead>
												<tbody>
													{selectedTasks?.sort(
														(a, b) => new Date(a.latestDatePointer || a.date) - new Date(b.latestDatePointer || b.date)
													)?.map((event, index) => (
														<tr key={event._id}>
															<td className="text-left p-1">{index + 1}</td>
															<td className=" text-left p-1">{event.title}</td>
															<td className="text-left p-1">
																{dayjs(event.latestDatePointer || event.date).format('MMMM D, YYYY h:mm A')}
															</td>
															<td className="text-left p-1">
																<EventAssignedChannel
																	event={event}
																	onAssignChanneltoEventCallback={
																		onAssignChannelToEvent
																	}
																	teamIds={[selectedTeamOption?.value]}
																/>
															</td>
															<td className=" text-left p-1">
																<ActionStyles>
																	<div>
																		<BsEye
																			onClick={() => handleShowEvent(event._id)}
																			className="icon view"
																		/>
																		{showModal === event._id ? (
																			<PreviewEventModal
																				data={event}
																				handleClose={handleClose}

																			// getAllTasks={getAllTasks}
																			/>
																		) : null}
																	</div>
																	<EventModal
																		data={event}
																		handleSubmitTask={(newTasks) =>
																			handleEditClick(event?._id, newTasks)
																		}
																		teamIds={[selectedTeamOption?.value]}
																	/>
																	{/* <BiLinkExternal className="icon assign" /> */}
																</ActionStyles>
															</td>
														</tr>
													))}
												</tbody>
											</AdminTable>
										)}
									</Tab>
									<Tab eventKey="event_grid" title={<BsGrid />}>
										{key === 'event_grid' && (
											<EventPlannerCalendar
												handleEditEvent={handleEditClick}
												handleCreateEvent={onTaskUpdateCallback}
												events={selectedTasks}
												eventModalProps={{ channel, teamIds: [selectedTeamOption?.value] }}
												handleShowEvent={handleShowEvent}
											/>
										)}
									</Tab>
								</Tabs>

								{/* <Form.Text className="text-danger">{error.room}</Form.Text> */}
							</Form.Group>
							<Form.Group as={Col} md={12}>
								<Fragment>
									<div>
										<Button variant="primary" onClick={handleGlobalAssign}>
											Assign Channel Globally
										</Button>

										{isGlobal && (
											<AssignChannelToPackageModal
												show={showGlobal}
												handleClose={GlobalhandleClose}
												onAssignChanneltoEventCallback={onAssignChannelToAllEvents}
												isGlobal={isGlobal}
												selectedRoomOption={globalChannel}
												setSelectedRoomOption={(room) => {
													setGlobalChannel(room);
												}}
												teamIds={[selectedTeamOption?.value]}
											/>
										)}
									</div>
								</Fragment>
							</Form.Group>

							<Button variant="primary" type="submit" disabled={isSubmitting}>
								Build Plan
							</Button>
							<ValidateUserAssignedToEntirePackageModal
								showValidateModel={showValidateModel}
								handleClose={GlobalhandleClose}
								onAssignChanneltoEventCallback={onAssignChannelToAllEvents}
								isGlobal={isGlobal}
								selectedRoomOption={globalChannel}
								setSelectedRoomOption={(room) => {
									setGlobalChannel(room);
								}}
								teamIds={[selectedTeamOption?.value]}
								setShowValidateModel={setShowValidateModel}
								teamId={selectedTeamOption?.value || teamId}
								teamsPrivatePublic={teamsPrivatePublic}
								intersectionOfAssignes={intersectionOfAssignes}
								setFinalizedAssignedUsers={setFinalizedAssignedUsers}
								finalizedAssignedUsers={finalizedAssignedUsers}
								setConfirmAssignSubmisstion={setConfirmAssignSubmisstion}
								confirmAssignSubmisstion={confirmAssignSubmisstion}
								actionMode={actionMode}
							/>
						</Form.Row>
					</Form>
				</div>
			)}
		</ActivityActionPackageStyled>
	);
};
ActionActivityPackageTemplate.propTypes = {};

const mapStateToProps = (state) => ({
	organizations: state.organizations.organizations,
	teams: state.teams.teams,
	rooms: state.rooms.teamRooms,
	CurrUser: state.user.user,
});
const mapDispatchToProps = { getAllRooms, getRoomsByTeam, GetAllTeams, setMessage, editTheTask, postATask };

export default connect(mapStateToProps, mapDispatchToProps)(ActionActivityPackageTemplate);
