import React, { Fragment, useEffect, useState } from 'react';
import { buildQueryString, fetchWrapper, getStandardDate } from '../../../../../../../_helpers';
import DownloadReport from '../DownloadReport';
import ReportCheckBox from '../ReportCheckBox';

const PaymentSummary = ({ companyID, slug, dateFilters = {} }) => {
    const [downloadLink, setDownloadLink] = useState('');
    const handleDownloadLink = (link) => setDownloadLink(link);
    const [includeIntlContractors, setIncludeIntlContractors] = useState(false);
    const generateLink = async () => {
        const queryString = buildQueryString({
            startDate: getStandardDate(dateFilters.start),
            endDate: getStandardDate(dateFilters.end),
            includeIntlContractors,
        });
        const response = await fetchWrapper.get(
            `evv/payroll/zeal/provider/${companyID}/reports/${slug}?${queryString}`
        );
        handleDownloadLink(response.url);
    };

    useEffect(() => {
        handleDownloadLink('');
    }, [dateFilters?.start, dateFilters?.end, includeIntlContractors]);

    return (
        <Fragment>
            <ReportCheckBox
                label={'Include international contractors'}
                value={includeIntlContractors}
                onChange={setIncludeIntlContractors}
            />
            <DownloadReport
                disabled={!dateFilters?.end || !dateFilters?.start}
                onGenerateLink={generateLink}
                downloadLink={downloadLink}
                companyID={companyID}
                slug={slug}
            />
        </Fragment>
    );
};

export default PaymentSummary;
