import styled from '@emotion/styled';

export const Tagstyled = styled.div`
    display:flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    height:20px;
    background-color: ${props => props.light ? "#F2F8F7;" : "#E0F0EF;"};
    border: 1px solid #50aeb0;
    padding:${props => props.small ? "2px 5px" : "5px 5px"};
    border-radius: 15px;
    font-size:12px;
    color: #1b686a;
`;

export const TagstyledCounter = styled.div`
    display:flex;
    flex-direction : column;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.light ? "#F2F8F7;" : "#E0F0EF;"};
    border: 1px solid #50aeb0;
    padding:${props => props.small ? "2px 5px" : "5px 5px"};
    border-radius: 10px;
    padding: 5px 10px;
    color: #1b686a;
`;

