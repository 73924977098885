import React, { useEffect, useRef, useState } from 'react';

import { css } from 'twin.macro';

import { Button, Form } from 'react-bootstrap';

import { fetchWrapper, toastWrapper } from '../../../../../_helpers';

import PdfRenderer from '../../../../OctetRenderer/PdfRenderer';
import OurAccordion from '../../../../../_elements/OurAccordion';
import { HiOutlineCheckCircle, HiOutlineExclamationCircle } from 'react-icons/hi';
import { VscLoading } from 'react-icons/vsc';

import Signature from '../../../Form/FormElements/Signature';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { MdLocationPin } from 'react-icons/md';

function ZealCompanyOnboarding({ provider, companyID, show, teamId }) {
    const [isBusy, setIsBusy] = useState(false);
    const [canTrigger, setCanTrigger] = useState(
        !['payment_permission_pending', 'live', 'offboarded'].includes(provider?.payroll?.metadata?.status)
    );

    const [deposits, setDeposits] = useState(['00.00', '00.00']);

    const [documentKey, setDocumentKey] = useState('all');
    const [documents, setDocuments] = useState([]);

    const signatureRef = useRef();

    const [taxPageWindow, setTaxPageWidow] = useState();

    useEffect(() => {
        if (companyID && show) {
            setIsBusy(true);
            fetchWrapper
                .get(`/evv/payroll/zeal/provider/${companyID}/documents${teamId ? `?team=${teamId}` : ''}`)
                .then((response) => {
                    if (!response?.length) {
                        throw new Error('No Response');
                    }
                    setDocuments(response);
                })
                .catch((error) => {
                    console.log('ERROR', error);
                    if (error.name === 'AbortError') {
                        return;
                    }
                    toastWrapper.error(error?.message || "Couldn't fetch authorization documents");
                })
                .finally(() => {
                    setIsBusy(false);
                });
        }
    }, [companyID, show]);

    function checkDepositValidity(deposit) {
        return parseFloat(deposit) !== 0 && /^[0-9]+.[0-9]{2}$/.test(deposit);
    }

    const handleDepositAction = async (action = 'verify') => {
        try {
            setIsBusy(true);
            await fetchWrapper.post(
                `evv/payroll/zeal/provider/${companyID}/deposits${teamId ? `?team=${teamId}` : ''}`,
                {
                    action,
                    deposits: deposits.map((x) => x.toString()),
                }
            );
            toastWrapper.success('Deposits successfully ' + { trigger: 'triggered', verify: 'verified' }[action]);
            setCanTrigger(false);
        } catch (error) {
            toastWrapper.error(error?.message);
        } finally {
            setIsBusy(false);
            setDeposits(['00.00', '00.00']);
        }
    };

    const handleSignDocuments = async () => {
        try {
            setIsBusy(true);
            const signature = signatureRef?.current && new FormData(signatureRef.current).get('signature');
            if (!signature) {
                throw new Error('Signature is missing');
            }
            await fetchWrapper.post(
                `evv/payroll/zeal/provider/${companyID}/documents${teamId ? `?team=${teamId}` : ''}`,
                { documentKey, signature }
            );
            toastWrapper.success('Documents successfully signed');
        } catch (error) {
            toastWrapper.error(error?.message);
        } finally {
            setIsBusy(false);
            signatureRef?.current && (signatureRef.current.value = '');
        }
    };

    const handleCompleteOnboarding = async () => {
        try {
            setIsBusy(true);
            await fetchWrapper.post(
                `evv/payroll/zeal/provider/${companyID}/onboarding${teamId ? `?team=${teamId}` : ''}`
            );
            toastWrapper.success('Onboarding successfully completed');
        } catch (error) {
            toastWrapper.error(error?.message);
        } finally {
            setIsBusy(false);
        }
    };

    const handleUpdateClientWorkLocations = async () => {
        try {
            setIsBusy(true);
            const {
                found = 0,
                updated = 0,
                added = 0,
                error = 0,
            } = await fetchWrapper.post(
                `evv/payroll/zeal/provider/${companyID}/work-locations${teamId ? `?team=${teamId}` : ''}`
            );
            toastWrapper.success(
                `Found ${found} addresses, updated ${updated} addresses and added ${added} addresses. Error occurred for ${error} addresses. Please check api logs for details.`
            );
        } catch (error) {
            toastWrapper.error(error?.message);
        } finally {
            setIsBusy(false);
        }
    };

    const handleViewTaxPage = async () => {
        try {
            setIsBusy(true);
            if (taxPageWindow && !taxPageWindow.closed) {
                return taxPageWindow.focus();
            }
            const { success, url } = await fetchWrapper.get(
                `evv/payroll/zeal/provider/${companyID}/tax-requirements${teamId ? `?team=${teamId}` : ''}`
            );
            if (!success || !url) {
                throw new Error("Couldn't fetch tax page url");
            }
            setTaxPageWidow(window.open(url));
        } catch (error) {
            toastWrapper.error(error?.message);
        } finally {
            setIsBusy(false);
        }
    };

    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                gap: 12px;
                padding: 12px;
            `}
        >
            <div
                css={css`
                    display: flex;
                    gap: 12px;
                `}
            >
                <Button
                    disabled={isBusy}
                    onClick={handleUpdateClientWorkLocations}
                    className="d-flex"
                    css={css`
                        align-items: center;
                        gap: 6px;
                    `}
                >
                    <MdLocationPin />
                    <span>Update Client Work Locations</span>
                </Button>
                <Button
                    disabled={isBusy}
                    onClick={handleViewTaxPage}
                    className="d-flex"
                    css={css`
                        align-items: center;
                        gap: 6px;
                    `}
                >
                    <FaExternalLinkAlt />
                    <span>View Company Tax Page</span>
                </Button>
            </div>
            <div
                css={css`
                    border: solid 1px #ddd;
                    border-radius: 6px;
                    padding: 12px;
                `}
            >
                <h5>Bank Information</h5>
                <hr />
                <div
                    css={css`
                        display: flex;
                        gap: 12px;
                        justify-content: space-between;
                        margin: 12px 0;
                    `}
                >
                    <Form.Group
                        css={css`
                            width: 50%;
                        `}
                    >
                        <Form.Label>Account Number</Form.Label>
                        <Form.Control value={provider?.payroll?.data?.bankAccount?.account_number} readOnly />
                    </Form.Group>

                    <Form.Group
                        css={css`
                            width: 50%;
                        `}
                    >
                        <Form.Label>Routing Number</Form.Label>
                        <Form.Control value={provider?.payroll?.data?.bankAccount?.routing_number} readOnly />
                    </Form.Group>
                </div>
                <Form.Group>
                    <Form.Label>Deposits</Form.Label>
                    <div
                        css={css`
                            display: flex;
                            gap: 12px;
                            justify-content: space-between;
                        `}
                    >
                        {deposits.map((deposit, index) => (
                            <Form.Group
                                key={index}
                                css={css`
                                    width: 50%;
                                `}
                            >
                                <Form.Control
                                    pattern="^[0-9]+.[0-9]{2}$"
                                    value={deposit}
                                    disabled={!canTrigger}
                                    key={index}
                                    required
                                    isValid={checkDepositValidity(deposit)}
                                    isInvalid={!checkDepositValidity(deposit)}
                                    onChange={(event) =>
                                        setDeposits((prev) => {
                                            const clone = [...prev];
                                            clone.splice(index, 1, event.target.value);
                                            return clone;
                                        })
                                    }
                                />
                            </Form.Group>
                        ))}
                    </div>
                </Form.Group>
                <hr />
                <div
                    css={css`
                        display: flex;
                        gap: 12px;
                        justify-content: flex-end;
                    `}
                >
                    <Button disabled={isBusy || !canTrigger} onClick={() => handleDepositAction('trigger')}>
                        Trigger Micro Deposits
                    </Button>
                    <Button
                        disabled={isBusy || !deposits.every(checkDepositValidity)}
                        onClick={() => handleDepositAction('verify')}
                    >
                        Verify Micro Deposits
                    </Button>
                </div>
            </div>

            <div
                css={css`
                    border: solid 1px #ddd;
                    border-radius: 6px;
                    padding: 12px;
                `}
            >
                <h3
                    css={css`
                        font-size: large;
                    `}
                >
                    Authorization Documents
                </h3>
                <hr />
                <div
                    css={css`
                        display: flex;
                        flex-direction: column;
                        gap: 12px;
                        justify-content: space-between;
                    `}
                >
                    {documents.map((document) => (
                        <OurAccordion
                            key={document.document_key}
                            title={
                                <div
                                    css={css`
                                        display: flex;
                                        gap: 12px;
                                        align-items: center;
                                    `}
                                >
                                    <span
                                        css={css`
                                            font-size: 16px;
                                        `}
                                    >
                                        {{
                                            8655: 'Reporting Agent Authorization (8655)',
                                            8821: 'Tax Information Authorization (8821)',
                                            'direct-deposit': 'Direct Deposit',
                                            'terms-and-conditions': 'Terms and Conditions',
                                            electronic_signature: 'Electronic Signature',
                                        }[document.document_key] || document.document_key}
                                    </span>
                                    {document.signed ? (
                                        <HiOutlineCheckCircle className="text-success" size="20px" />
                                    ) : (
                                        <HiOutlineExclamationCircle className="text-danger" size="20px" />
                                    )}
                                </div>
                            }
                            defaultShow={false}
                        >
                            <div
                                css={css`
                                    overflow: scroll;
                                `}
                            >
                                <PdfRenderer uri={document.url} />
                            </div>
                        </OurAccordion>
                    ))}
                </div>
                <hr />
                {documents?.length ? (
                    <form
                        ref={signatureRef}
                        onSubmit={(e) => {
                            e.preventDefault();
                        }}
                    >
                        <Signature name="signature" data={{ required: true }} />
                    </form>
                ) : isBusy ? (
                    <div
                        className="loader"
                        key={'loading-documents'}
                        css={css`
                            height: 100%;
                            min-height: 100px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        `}
                    >
                        <VscLoading fontSize={34} className="spin" />
                    </div>
                ) : (
                    'No Documents'
                )}
                <hr />
                <div
                    css={css`
                        display: flex;
                        gap: 12px;
                        justify-content: flex-end;
                    `}
                >
                    <Button disabled={isBusy} onClick={handleSignDocuments}>
                        Sign All Documents
                    </Button>
                </div>
            </div>
            <hr />
            <div
                css={css`
                    display: flex;
                    gap: 12px;
                    justify-content: center;
                    padding: 0 12px 12px;
                `}
            >
                {provider?.payroll?.metadata?.status === 'live' || (
                    <Button disabled={isBusy} onClick={handleCompleteOnboarding}>
                        Complete Onboarding
                    </Button>
                )}
            </div>
        </div>
    );
}

export default ZealCompanyOnboarding;
