import React, { useState, useEffect, Fragment } from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { BsEye } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { Tab, Tabs } from 'react-bootstrap';
import { BsGrid, BsList } from 'react-icons/bs';
import { ToolTip } from '../../../_elements/ToolTip';

import { ActivityActionPackageStyled } from './styles/ActivityActionPackageStyled';
import NavigationBar from '../reusableComponents/NavigationBar';
import Loader from '../containers/Loader';
import { setMessage } from '../../../_redux';
import { editTheTask, postATask } from '../../../_redux/actions/ActionTasks';
import { fetchWrapper } from '../../../_helpers';
import { AdminTable } from '../reusableStyles';
import { ActionStyles } from '../reusableStyles';
import PreviewEventModal from '../Tasks/PreviewEventModal';
import EventModal from '../Tasks/EventModal';
import { getTeamIdFromURL } from '../../../_helpers';
import EventPlannerCalendar from './EventPlannerCalendar';
import EventAssignedChannel from './EventAssignedChannel';
import AssignChannelToPackageModal from './AssignChannelToPackageModal';
import ValidateUserAssignedToEntirePackageModal from './ValidateUserAssignedToEntirePackageModal';
import { getRoomsByTeam } from '../../../_redux';
import { toast } from 'react-toastify';
import { prepareEvent, areAllArraysUnorderedEqual } from '../../../_helpers/';
import { modifiedActivityPackageData } from '../../../_helpers/activityPackage';
import TeamSelectFilter from "../../Admin/TeamSelectFilter";
import TaskSelectFilter from '../TaskSelectFilter';

const ActivityActionPackage = ({
	chatroom = null,
	rooms,
	teams,
	match,
}) => {
	const teamId = getTeamIdFromURL();

	const dispatch = useDispatch();
	const history = useHistory();
	const [actionMode, setActionMode] = useState(null);
	const [packageName, setPackageName] = useState(null);
	const [selectedTeamOption, setSelectedTeamOption] = useState(null);
	const [defaultTasks, setDefaultTasks] = useState([]);
	const [selectedTasks, setSelectedTasks] = useState([]);
	const [packageData, setPackageData] = useState(null);
	const { register, handleSubmit } = useForm({
		mode: 'onBlur',
	});
	const [showModal, setShowModal] = useState(null);
	const [channel, setChannel] = useState(null);
	const { channelId } = useParams();
	const [key, setKey] = useState('event_list');

	const [globalChannel, setGlobalChannel] = useState(null);
	const [showValidateModel, setShowValidateModel] = useState(false);
	const [teamsPrivatePublic, setTeamsPrivatePublic] = useState('');
	const [intersectionOfAssignes, setIntersectionOfAssignes] = useState([]);
	const [finalizedAssignedUsers, setFinalizedAssignedUsers] = useState([]);
	const [confirmAssignSubmisstion, setConfirmAssignSubmisstion] = useState(false);

	const [formPostData, setFormPostData] = useState({});
	const [isSubmitting, setIsSubmitting] = useState(false);

	useEffect(() => {
		if (match.params.id) {
			fetchWrapper.get(`/activityPackages/${match.params.id}`).then((res) => {
				// #TODO Remove below line if backend query patched in activity package controller
				res[0].teamData = res[0].teamData[0];
				match.params.mode === 'copy'
					? setPackageData(modifiedActivityPackageData(res[0]))
					: setPackageData(res[0]);
			});
		}
		setActionMode(match.params.mode === 'edit' ? 'Edit' : match.params.mode === 'copy' ? 'Copy' : 'Create');
	}, [match.params.id, match.params.mode]);

	const handleShowEvent = (id) => {
		setShowModal(id);
	};

	const handleClose = () => {
		setShowModal(null);
		setShowValidateModel(false);
	};

	useEffect(() => {
		setSelectedTeamOption(chatroom);
	}, [chatroom]);

	useEffect(() => {
		const selectedTeams = teamId ? [teams.find((team) => team._id === teamId)] : teams;
		const teamsOptions = selectedTeams.map((team) => ({
			label: team?.name,
			value: team?._id,
		}));
		if (teamsOptions && teamsOptions.length) {
			setSelectedTeamOption(teamsOptions[0]);
		}
	}, [teams]);

	useEffect(() => {
		if (!packageData) {
			return null;
		}
		// PackageName
		const packageName = packageData?.name
			? actionMode === 'Edit'
				? `Edited ${packageData.name}`
				: actionMode === 'Copy'
					? `Copy of ${packageData.name}`
					: packageData.name
			: '';
		setPackageName(packageName);

		// teams
		const teamsOptions = packageData?.teamData
			? {
				value: packageData.teamData?.id,
				label: packageData.teamData?.name,
			}
			: null;
		setSelectedTeamOption(teamsOptions);

		// task
		const tasksOptions = (packageData?.taskData || []).map((task) => ({
			value: task._id,
			label: task.title,
			...task,
		}));
		setSelectedTasks(tasksOptions);
	}, [actionMode, packageData]);

	useEffect(() => {
		if (channelId) {
			fetchWrapper.get(`chatrooms/${channelId}`).then((res) => setChannel(res));
		}
	}, [channelId]);

	const handleEditClick = (id, newTask) => {
		return onTaskUpdateCallback(newTask, 'edit', id);
	};

	const onTaskUpdateCallback = (task, mode, id, editMessage = 'Event edited successfully') => {
		//patch allTask & selectedTasks
		if (mode !== 'edit') {
			task._id = `NEW_TASK_${Date.now()}_${Math.round(Math.random() * 100)}`;
			setDefaultTasks((prev) => [
				...prev,
				{
					...task, label: task.title, value: task._id,
					team: {
						id: selectedTeamOption?.value,
						name: selectedTeamOption?.label
					}
				},
			]);
			setSelectedTasks((prev) => [
				...prev,
				{
					...task, label: task.title, value: task._id,
					team: {
						id: selectedTeamOption?.value,
						name: selectedTeamOption?.label
					}
				},
			]);
			dispatch(setMessage({ error: false, message: 'Event created successfully' }));
		} else {
			setDefaultTasks((prev) =>
				prev.map((exTask) =>
					exTask._id === id ? { ...task, _id: id, label: task.title, value: id } : { ...exTask }
				)
			);
			setSelectedTasks((prev) =>
				prev.map((exTask) =>
					exTask._id === id ? { ...task, _id: id, label: task.title, value: id } : { ...exTask }
				)
			);
			dispatch(setMessage({ error: false, message: editMessage }));
		}
		return task;
	};

	const onAssignChannelToEvent = (_id, event) => {
		onTaskUpdateCallback(event, 'edit', _id, 'Channel assigned to event');
	};

	const onAssignChannelToAllEvents = (_, data) => {
		if (!data) {
			return null;
		}
		const { channel, assignedUsers: assignedUser } = data;
		const assignedChannel = [channel?.value];
		const assignedChannelInfo = [channel];
		setDefaultTasks((prev) => prev.map((task) => ({ ...task, assignedChannel, assignedChannelInfo, assignedUser })));
		setSelectedTasks((prev) => prev.map((task) => ({ ...task, assignedChannel, assignedChannelInfo, assignedUser })));
	};

	const onTaskSearchChange = (allSelectedTasks) => {
		setSelectedTasks((prev) => {
			const oldValues = prev.map((task) => task.value);
			const newSelectedTask = allSelectedTasks.find((task) => !oldValues.includes(task.value));
			if (newSelectedTask && (selectedTeamOption?.value || teamId) !== newSelectedTask?.team?.id) {
				toast.error('This is a borrowed event. Please assign a new channel.');
			}
			return allSelectedTasks || [];
		});
	};

	useEffect(() => {
		const completeSubmission = async (postPackageData) => {
			try {
				setIsSubmitting(true);
				const updatedPostPackageData = {
					...postPackageData,
					assignedUser: finalizedAssignedUsers,
				};

				if (actionMode === 'Edit') {
					await fetchWrapper.put(`activityPackages/${match.params.id || teamId}`, updatedPostPackageData);
					dispatch(setMessage({ error: false, message: 'Plan Edited successfully' }));
				} else {
					await fetchWrapper.post(`activityPackages`, updatedPostPackageData);
					const message = `Plan ${actionMode === 'Copy' ? 'copied' : 'created'} successfully`;
					dispatch(setMessage({ error: false, message }));
				}
				history.goBack();
			} catch (error) {
				dispatch(setMessage({ error: true, message: error.message || "Something went wrong" }));
			} finally {
				setIsSubmitting(false);
				setConfirmAssignSubmisstion(false);
			}
		};
		if (confirmAssignSubmisstion) {
			completeSubmission(formPostData);
		}
	}, [confirmAssignSubmisstion]);

	const onSubmit = async (data) => {
		setTeamsPrivatePublic('');
		if (!data) {
			return null;
		}
		try {
			const selectedTeamId = selectedTeamOption?.value;
			const postPackageData = {
				name: data.packageName,
				team: selectedTeamId || teamId,
				events: selectedTasks.map(prepareEvent),
				assignedUser: selectedTasks[0]?.assignedUser,
			};

			setFormPostData(postPackageData);
			if (!postPackageData?.events?.length) {
				throw new Error('Please select at least one event');
			}

			if (!postPackageData?.team) {
				throw new Error('Please assign team to the plan');
			}
			console.log('err');
			postPackageData.events.map((event) => {
				const room =
					event.assignedChannel &&
					rooms[postPackageData.team].rooms.find((room) => room._id === event.assignedChannel[0]);
				if (!room) {
					throw new Error(
						`Room assigned to event ${event.title} is not in team ${selectedTeamOption?.label}.`
					);
				}
			});

			const allEventAssignedUsers = postPackageData.events.map((event) => event.assignedUser);
			const { intersection } = areAllArraysUnorderedEqual(allEventAssignedUsers);
			setIntersectionOfAssignes(intersection);
			let privateChannels = [];
			let publicChannels = [];
			selectedTasks?.forEach(({ assignedChannelInfo }) => {
				const channelInfo = Array.isArray(assignedChannelInfo) ? assignedChannelInfo[0] : assignedChannelInfo;
				const channelId = channelInfo?._id || channelInfo?.id || channelInfo?.value;
				channelId && (channelInfo?.type === 'private' ? privateChannels : publicChannels).push(channelId);
			});

			setShowValidateModel(true);
			setTeamsPrivatePublic(
				`${privateChannels?.length ? `&private=${privateChannels}` : ''}${publicChannels?.length ? `&public=${publicChannels}` : ''
				}`
			);
			setTeamsPrivatePublic('');

			return;
		} catch (e) {
			console.log(e);
			dispatch(setMessage({ error: true, message: `Error ${e.message || e.error}` }));
		}
	};
	const [showGlobal, setShowGlobal] = useState(false);

	const GlobalhandleClose = () => setShowGlobal(false);
	const GlobalhandleShow = () => setShowGlobal(true);

	const [isGlobal, setIsGlobal] = useState(false);
	const handleGlobalAssign = () => {
		setIsGlobal(true);
		GlobalhandleShow();
	};

	const [fetchedTasks, setFetchedTasks] = useState([]);

	const handleFetchTask = async (id) => {
		if (!id || id.startsWith('NEW_TASK') || fetchedTasks.includes(id)) {
			return;
		}
		const response = await fetchWrapper.get(`/task/${id}`);
		setSelectedTasks(prev => prev.map(task => task._id === id ? { ...task, ...response } : task));
		setFetchedTasks(prev => [...prev, id]);
	}

	return (
		<ActivityActionPackageStyled>
			<div className="d-flex justify-content-between mb-4">
				<NavigationBar
					mainTabName="Master Event Planner"
					tab1={`${actionMode} Plan`}
					tab={packageName || ''}
				/>
			</div>
			<div className="d-flex flex-column align-items-center justify-content-between position-relative pt-5">
				<div className="top-items flex justify-content-end">
					<EventModal
						channel={channel}
						handleSubmitTask={onTaskUpdateCallback}
						teamIds={[selectedTeamOption?.value]}
					/>
				</div>

				<Form className="custom-card w-75 m-auto" onSubmit={handleSubmit(onSubmit)}>
					<Form.Row>
						<Form.Group as={Col} md={12}>
							<Form.Label>Plan Name</Form.Label>
							<Form.Control
								type="text"
								value={packageName || ''}
								onChange={(e) => setPackageName(e.target.value)}
								name="packageName"
								required
								ref={register}
							/>
							{/* <Form.Text className="text-danger">{error.name}</Form.Text> */}
						</Form.Group>
						{teamId ? null : (
							<Form.Group as={Col} md={12}>
								<Form.Label>Team</Form.Label>
								<TeamSelectFilter
									selectedTeamOption={selectedTeamOption}
									setSelectedTeamOption={setSelectedTeamOption}
									className="bolder"
									required
									name="team" />
								{/* <Form.Text className="text-danger">{error.room}</Form.Text> */}
							</Form.Group>
						)}
						<Form.Group as={Col} md={12}>
							<Form.Label>Events</Form.Label>
							<TaskSelectFilter
								selectedOption={selectedTasks}
								onTaskSelect={onTaskSearchChange}
								name='tasks'
								defaultTasks={defaultTasks}
								required
								isMulti
								teamId={teamId}
							/>
							<Tabs
								id="controlled-tab-example"
								activeKey={key}
								onSelect={(k) => setKey(k)}
								className="mb-3"
							>
								<Tab eventKey="event_list" title={<BsList />}>
									{key === 'event_list' && (
										<AdminTable className="mb-5 mt-5 w-100">
											<thead>
												<tr>
													<th>S.N.</th>
													<th>Event Name</th>
													<th>Date</th>
													<th>Channel</th>
													<th>Actions</th>
												</tr>
											</thead>
											<tbody>
												{selectedTasks?.sort(
													(a, b) => new Date(a.latestDatePointer || a.date) - new Date(b.latestDatePointer || b.date)
												)?.map((event, index) => (
													<tr key={event._id}>
														<td className="text-left p-1">{index + 1}</td>
														<td className=" text-left p-1">{event.title}</td>
														<td className="text-left p-1">
															{dayjs(event.latestDatePointer || event.date).format('MMMM D, YYYY h:mm A')}
														</td>
														<td className="text-left p-1">
															<EventAssignedChannel
																event={event}
																onAssignChanneltoEventCallback={
																	onAssignChannelToEvent
																}
																teamIds={[selectedTeamOption?.value]}
															/>
														</td>
														<td className=" text-left p-1"
															onClick={(clickedEvent) => {
																if (!['SVG', 'PATH'].includes(clickedEvent.target?.tagName?.toUpperCase())) {
																	return;
																}
																handleFetchTask(event._id)
															}}
														>
															<ActionStyles>
																<div>
																	<ToolTip
																		text={'View'}
																		side={'top'}
																		cssStyles={
																			'top:-70% !important; min-width: fit-content !important'
																		}
																	>
																		<BsEye
																			onClick={() =>
																				handleShowEvent(event._id)
																			}
																			className="icon view"
																		/>
																	</ToolTip>
																</div>
																<EventModal
																	data={event}
																	handleSubmitTask={(newTasks) =>
																		handleEditClick(event?._id, newTasks)
																	}
																	shouldEdit={true}
																	teamIds={[selectedTeamOption?.value]}
																	isPackageModal={true}
																/>
																{/* <BiLinkExternal className="icon assign" /> */}
															</ActionStyles>
														</td>
													</tr>
												))}
											</tbody>
										</AdminTable>
									)}
								</Tab>
								<Tab eventKey="event_grid" title={<BsGrid />}>
									{key === 'event_grid' && (
										<EventPlannerCalendar
											handleEditEvent={handleEditClick}
											handleCreateEvent={onTaskUpdateCallback}
											events={selectedTasks}
											eventModalProps={{ channel, teamIds: [selectedTeamOption?.value] }}
											handleShowEvent={handleShowEvent}
										/>
									)}
								</Tab>
							</Tabs>
							{showModal ? (
								<PreviewEventModal
									data={selectedTasks?.find(({ _id }) => _id === showModal)}
									handleClose={handleClose}
								/>
							) : null}
							{/* <Form.Text className="text-danger">{error.room}</Form.Text> */}
						</Form.Group>
						<Form.Group as={Col} md={12}>
							<Fragment>
								<div>
									<Button variant="primary" onClick={handleGlobalAssign}>
										Assign Channel Globally
									</Button>

									{isGlobal && (
										<AssignChannelToPackageModal
											show={!isSubmitting && showGlobal}
											handleClose={GlobalhandleClose}
											onAssignChanneltoEventCallback={onAssignChannelToAllEvents}
											isGlobal={isGlobal}
											selectedRoomOption={globalChannel}
											setSelectedRoomOption={(room) => {
												setGlobalChannel(room);
											}}
											teamIds={[selectedTeamOption?.value]}
										/>
									)}
								</div>
							</Fragment>
						</Form.Group>

						<Button variant="primary" type="submit" disabled={isSubmitting}>
							{actionMode} Plan
						</Button>
						<ValidateUserAssignedToEntirePackageModal
							showValidateModel={showValidateModel}
							show={false}
							handleClose={GlobalhandleClose}
							onAssignChanneltoEventCallback={onAssignChannelToAllEvents}
							isGlobal={isGlobal}
							selectedRoomOption={globalChannel}
							setSelectedRoomOption={(room) => {
								setGlobalChannel(room);
							}}
							teamIds={[selectedTeamOption?.value || teamId]}
							setShowValidateModel={setShowValidateModel}
							teamId={selectedTeamOption?.value || teamId}
							teamsPrivatePublic={teamsPrivatePublic}
							intersectionOfAssignes={intersectionOfAssignes}
							setFinalizedAssignedUsers={setFinalizedAssignedUsers}
							finalizedAssignedUsers={finalizedAssignedUsers}
							setConfirmAssignSubmisstion={setConfirmAssignSubmisstion}
							confirmAssignSubmisstion={confirmAssignSubmisstion}
							actionMode={actionMode}
						/>
					</Form.Row>
				</Form>
			</div>
		</ActivityActionPackageStyled>
	);
};
ActivityActionPackage.propTypes = {};

const mapStateToProps = (state) => ({
	organizations: state.organizations.organizations,
	teams: state.teams.teams,
	rooms: state.rooms.teamRooms,
});
const mapDispatchToProps = { getRoomsByTeam, setMessage, editTheTask, postATask };

export default connect(mapStateToProps, mapDispatchToProps)(ActivityActionPackage);
