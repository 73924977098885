import styled from '@emotion/styled';
import { useState, Fragment } from 'react';
import { Button } from 'react-bootstrap';
import AvatarUserPanel from '../../../Common/AvatarUserPanel';
export const StyledPeople = styled.div`
	width: 100%;
	.accordion {
		margin-bottom: 2rem;
	}
	.accordion .accordionTitle {
		color: white;
		background: transparent;
		border: none;
		outline: none;
	}
`;
export const StyledPeopleItem = styled.div`
	flex: 1;
	display: flex;
	justify-content: space-between;
	padding: 1rem 0;
	border-bottom: 1px solid #333;
	.peopleInfo {
		display: flex;
		align-items: center;
		.avatarDiv {
			position: relative;
			width: max-content;
			height: fit-content;
		}
	}
	.options {
		opacity: 0;
	}
	:hover .options {
		opacity: 1;
	}
`;

export const PeopleItem = ({ people, handleInvite, isHost, handleRemoveParticipant, handleMuteParticipant }) => {
	const [isInvited, setIsInvited] = useState(false);

	return (
		<StyledPeopleItem>
			<div className="peopleInfo">
				<div className="avatarDiv">{people && <AvatarUserPanel item={people} />}</div>

				<span className="px-2 text-light">{people.name}</span>
			</div>

			{handleInvite ? (
				<Button
					className="BlueButton"
					onClick={() => {
						handleInvite(people);
						setIsInvited(true);
					}}
				>
					{isInvited ? 'Invited' : 'Invite'}
				</Button>
			) : (
				<div className="options">
					{isHost && (
						<Fragment>
							<Button
								className="BlueButton mr-1"
								onClick={() => handleMuteParticipant(people.id || people._id)}
							>
								Mute
							</Button>
							<Button
								className="BlueButton"
								onClick={() => handleRemoveParticipant(people.id || people._id)}
							>
								Remove
							</Button>
						</Fragment>
					)}
				</div>
			)}
		</StyledPeopleItem>
	);
};
