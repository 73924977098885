
import Header from './Header/Header';
import Footer from './Footer/Footer';

function Main(props) {
	return (
		<div>
			<Header />
			{props.children}
			<Footer />
		</div>
	);
}

export default Main;
