import React, { useState } from 'react';
import styled from '@emotion/styled';
import { FiSearch } from 'react-icons/fi';
import { AiOutlineClose } from 'react-icons/ai';

export const SearchBox = styled.div`
	display: flex;
	background-color: white;
	padding: 2px;
	height: 3.7rem;
	border-radius: 12px;
	border: 1px solid #e0e6ed;
	flex-direction: row;
	justify-content: flex-start;
	width: 250px;
	align-items: center;
	color: #506690;

	.search-input {
		flex: 1;
		padding: 0 5px;
		height: 100%;
		width: auto;
		border-radius: 15px;
		border: none;
		display: hidden;
		font-size: 1.3rem;
	}

	.search-input:focus,
	.search-input:active {
		outline: none;
		border: none;
	}

	.search-area-items {
		margin: 0 2px;
		color: #0b7da8;
	}
`;

const AdminSearchBox = ({ search, onSearch, inputRef, placeholder }) => {
	const [showCross, setShowCross] = useState(false);

	const handleChange = (e) => {
		onSearch(e.target.value);
		if (e.target.value === '') return setShowCross(false);
		if (showCross) return;
		setShowCross(true);
	};

	const clearSearch = () => {
		setShowCross(false);
		onSearch('');
		if (inputRef) inputRef.current.value = '';
	};

	return (
		<SearchBox>
			<input
				onChange={handleChange}
				value={search}
				placeholder={placeholder}
				className="search-input search-area-items"
				ref={inputRef && inputRef}
			/>
			{showCross && (
				<p className="search-area-items">
					<AiOutlineClose
						size="18"
						className="mr-2 d-flex justify-content-center align-items-center pointer"
						onClick={clearSearch}
					/>
				</p>
			)}
			<p className="search-area-items">
				<FiSearch size="15" className="mr-1 d-flex justify-content-center align-items-center" />
			</p>
		</SearchBox>
	);
};

export default AdminSearchBox;
