import * as ActionTypes from '../actions/ActionTypes';

const initialState = {
	loading: false,
	errors: null,
	message: null,
	redirectNewUser: null,
};

export default function UI(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.SET_ERRORS:
			return {
				...state,
				loading: false,
				errors: action.payload,
			};
		case ActionTypes.CLEAR_ERRORS:
			return {
				...state,
				loading: false,
				errors: null,
			};
		case ActionTypes.LOADING_UI:
			return {
				...state,
				loading: true,
			};
		case ActionTypes.STOP_LOADING_UI:
			return {
				...state,
				loading: false,
			};
		case ActionTypes.SET_SHOW_MESSAGE:
			if (action.payload?.message === 'AbortError') {
				return { ...state };
			}
			return {
				...state,
				message: action.payload,
			};
		case ActionTypes.CLEAR_SHOW_MESSAGE:
			return {
				...state,
				message: null,
			};
		case ActionTypes.SET_NEW_USER_REDIRECT:
			return {
				...state,
				redirectNewUser: action.payload
			}
		default:
			return state;
	}
}
