import { css } from 'twin.macro';

const container = css`
	display: flex;
	flex-direction: column;
	/* justify-content: space-between; */
	background: #f5f5f5;
	height: 100%;
	width: 100%;

	@media (max-width: 767px) {
		width: '100%';
		height: '100%';
	}
	.name-title {
		padding: 8px;
		margin: 0;
		box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
		font-weight: bold;
		font-size: 20px;
		background-color: #50aeb0;
		color: #f7ffff;
		cursor: pointer;
		transition: color 200ms ease-in-out;
		&:hover {
			color: #e0f0ef;
		}
	}
`;

export const StyledScrollBarContainerCSS = css`
	&.height-80 {
		height: 80vh !important;
	}
	&.height-90 {
		height: 90vh !important;
	}
	&.height-95 {
		height: 95vh !important;
	}
	&.height-500 {
		max-height: 500px !important;
	}
	&.cw-90 {
		width: 90%;
		margin: 0 auto;
	}
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	overflow-y: auto;
	/* width */
	::-webkit-scrollbar {
		width: 5px;
		margin: 5px;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #c8e7e8;
		border-radius: 2.5px;
		height: 20%;
	}

	/* Track */
	::-webkit-scrollbar-track {
		padding: 0px 5px;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #50aeb0;
		cursor: grabbing;
	}
`;
export default container;
