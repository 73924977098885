import { css } from 'twin.macro';
import React, { Fragment, useState } from 'react';
import { Form } from 'react-bootstrap';
import { ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

function ParagraphEditor({ register, defaultValue }) {
	const [editorState, setEditorState] = useState(() => {
		let content;
		try {
			content = JSON.parse(defaultValue);
			return content;
		} catch (err) {
			return convertToRaw(ContentState.createFromText(defaultValue));
		}
	});
	const [convertedValue, setConvertedValue] = useState(defaultValue);
	const onContentStateChange = (editorState) => {
		setConvertedValue(JSON.stringify(editorState));
	};
	return (
		<Form.Group controlId="formDescription">
			<Form.Label>Instruction</Form.Label>
			{editorState ? (
				<Fragment>
					<Editor
						placeholder={'Aa'}
						defaultContentState={editorState}
						toolbarClassName="toolbarClassName"
						wrapperClassName="wrapperClassName"
						editorClassName="editorClassName"
						onContentStateChange={onContentStateChange}
					/>
					<Form.Control
						as="textarea"
						placeholder="instruction"
						name="instruction"
						ref={register}
						value={convertedValue}
						readOnly
						css={css`
							position: absolute;
							visibility: hidden;
							pointer-events: none;
						`}
					/>
				</Fragment>
			) : (
				<Form.Control
					as="textarea"
					placeholder="instruction"
					defaultValue={defaultValue}
					name="instruction"
					ref={register}
				/>
			)}
		</Form.Group>
	);
}

export default ParagraphEditor;
