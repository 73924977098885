import React, { useCallback, useRef } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { css } from 'twin.macro';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Nav } from 'react-bootstrap';

// helpers
import { fetchWrapper, getInitials, getRandomColor, getTeamIdFromURL, toastWrapper } from '../../../../_helpers';

// redux
import { connect } from 'react-redux';
import { GetEmployees } from '../../../../_redux/actions/ActionEVV';

// components
import StaffList from './StaffList';
import Pagination from '../../Pagination';
import CreateStaff from './CreateStaff';
import EvvSearchModalComponent from '../EvvSearchModal';

const Staff = ({ GetEmployees, isLoading, employees, teams }) => {
    const isTeamSettings = !!useLocation().pathname.match(/user-evv/);
    const teamId = getTeamIdFromURL();
    const evvBasePath = isTeamSettings ? 'user-evv' : 'evv';

    const history = useHistory();
    const queryPage = useLocation().search.match(/page=([0-9]+)/, '');
    const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [userEmployee, setUserEmployee] = useState();
    const [provider, setProvider] = useState();
    const [staffState, setStaffState] = useState('active');

    const firstNameRef = useRef();
    const lastNameRef = useRef();

    useEffect(() => {
        if (!isTeamSettings || !teamId) {
            return null;
        }
        const team = teams.find((team) => team.id === teamId);
        fetchWrapper
            .get(`evv/provider?team=${teamId}`)
            .then(({ results: [response] }) =>
                setProvider({
                    value: response?._id,
                    label: response?.agencyName,
                    providerId: response?._id,
                    teamId: team?.id,
                    agencyName: response?.agencyName,
                    team: response?._id,
                    state: response?.state,
                    payroll: response?.payroll,
                    evv: response?.evv,
                })
            )
            .catch((error) => {
                if (error.name === 'AbortError') {
                    return;
                }
                toastWrapper.error("Couldn't fetch provider data");
            });
    }, [teams, isTeamSettings]);

    const handleNameChange = (event) => {
        if (event.target.name === 'firstName') {
            setFirstName(event.target.value);
        } else if (event.target.name === 'lastName') {
            setLastName(event.target.value);
        }
    };

    const handleUserEmployeeSelect = (selectedUser) => {
        setUserEmployee(selectedUser);
    };

    const handleProviderSelect = (selectedProvider) => {
        setProvider(selectedProvider);
    };

    const handleSearch = useCallback(() => {
        GetEmployees({
            firstName,
            lastName,
            user: userEmployee?.id,
            provider: provider?.value,
            state: staffState,
            team: teamId,
        });
        history.push(`/${evvBasePath}?page=1`);
    }, [GetEmployees, firstName, lastName, userEmployee?.id, provider?.value, staffState, history]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (firstName || lastName) {
                if (firstName === firstNameRef.current.value || lastName === lastNameRef.current.value) {
                    handleSearch();
                }
            } else {
                handleSearch();
            }
        }, 500);
        return () => clearTimeout(timer);
    }, [firstName, lastName, handleSearch]);

    const handleClearAll = () => {
        setFirstName('');
        setLastName('');
        setUserEmployee(null);
        setProvider(null);
    };

    useEffect(() => {
        GetEmployees({ state: staffState, team: teamId });
    }, [GetEmployees, staffState]);

    const onPageChanged = (data) => {
        if (currentPage !== data.currentPage && data.currentPage > 0) {
            history.push(`/${evvBasePath}?page=${data.currentPage}`);
            GetEmployees({
                firstName,
                lastName,
                user: userEmployee?.id,
                provider: provider?.value,
                page: data.currentPage,
                state: staffState,
                team: teamId,
            });
        }
    };

    const employeesList = useMemo(
        () =>
            employees.employees &&
            employees.employees.map((employee) => {
                employee.initials = getInitials(`${employee.firstName} ${employee.lastName}`);
                employee.color = getRandomColor(employee.id);
                return employee;
            }),
        [employees]
    );
    return (
        <div>
            <div className="d-flex flex-row align-items-center mb-4 justify-content-between ">
                <EvvSearchModalComponent
                    type="Staff"
                    firstName={firstName}
                    firstNameRef={firstNameRef}
                    lastName={lastName}
                    lastNameRef={lastNameRef}
                    userEmployee={userEmployee}
                    provider={provider}
                    handleNameChange={handleNameChange}
                    handleUserEmployeeSelect={handleUserEmployeeSelect}
                    handleProviderSelect={handleProviderSelect}
                    handleClearAll={handleClearAll}
                    isTeamSettings={isTeamSettings}
                    team={teamId}
                />
                <Nav
                    variant="pills"
                    className="p-2"
                    css={css`
                        background: #e1e1e1;
                        border-radius: 5px;
                    `}
                >
                    <Nav.Item>
                        <Nav.Link
                            as={Button}
                            variant={staffState === 'active' ? 'primary' : 'link'}
                            onClick={() => setStaffState('active')}
                            style={{ fontSize: '1.6rem' }}
                            className={staffState === 'active' ? 'bg-primary text-white' : ''}
                        >
                            Active
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="ml-1">
                        <Nav.Link
                            as={Button}
                            variant={staffState === 'archieved' ? 'primary' : 'link'}
                            onClick={() => setStaffState('archieved')}
                            style={{ fontSize: '1.6rem' }}
                            className={staffState === 'archieved' ? 'bg-primary text-white' : ''}
                        >
                            Archived
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                <CreateStaff provider={provider} />
            </div>
            <div
                className="custom-card card-table d-flex flex-column justify-content-between"
                css={css`
                    min-height: 675px;
                `}
            >
                <StaffList
                    list={employeesList}
                    isLoading={isLoading}
                    currentPage={currentPage}
                    staffState={staffState}
                    setStaffState={setStaffState}
                    teamId={teamId}
                />
                {employees?.employees?.length > 0 && (
                    <div className="m-4">
                        <Pagination
                            pageNo={currentPage}
                            totalRecords={employees.totalEmployees}
                            pageLimit={10}
                            pageNeighbours={1}
                            onPageChanged={onPageChanged}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    isLoading: state.evv.isLoading,
    employees: state.evv.employees,
    teams: state.teams.teams,
});

export default connect(mapStateToProps, { GetEmployees })(Staff);
