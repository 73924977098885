const rules = {
    user: {
        static: ['create:team'],
        dynamic: {
            'remove:team': ({ userId, teamAdmin }) => {
                if (!userId || !teamAdmin) return false;
                return userId === teamAdmin;
            },
            'create:channal': ({ userId, teamAdmin }) => {
                if (!userId || !teamAdmin) return false;
                return userId === teamAdmin;
            },
            'update:team': ({ userId, teamAdmin }) => {
                if (!userId || !teamAdmin) return false;
                return userId === teamAdmin;
            },
            'remove:channel': ({ userId, teamAdmin }) => {
                if (!userId || !teamAdmin) return false;
                return userId === teamAdmin;
            },
            'remove:user': ({ userId, teamAdmin }) => {
                if (!userId || !teamAdmin) return false;
                return userId === teamAdmin;
            },
        },
    },
    owner: {
        static: [
            'create:team',
            'create:channal',
            'remove:team',
            'remove:user',
            'invite:user',
            'join:channal',
            'update:team',
            'settings:team',
            'update:channal',
            'delete:message',
            'edit:messagePermission',
            'view:form',
            'remove:channel',
            'assign:channalRole',
            'create:event',
            'modify:event',
            'channel:byId',
            'create:channelForm',
            'start:call',
        ],
        dynamic: {
            'invite:manager': ({ user }) => {
                if (user.role === 'manager') return true;
                return false;
            },
        },
    },
    manager: {
        static: ['create:team'],
        dynamic: {
            'remove:team': ({ userId, teamAdmin }) => {
                if (!userId || !teamAdmin) return false;
                return userId === teamAdmin;
            },
            'invite:user': ({ userId, teamAdmin }) => {
                if (!userId || !teamAdmin) return false;
                return userId === teamAdmin;
            },
            'invite:manager': ({ userId, teamAdmin }) => {
                if (!userId || !teamAdmin) return false;
                return userId === teamAdmin;
            },
            'create:channal': ({ userId, teamAdmin }) => {
                if (!userId || !teamAdmin) return false;
                return userId === teamAdmin;
            },
            'update:team': ({ userId, teamAdmin }) => {
                if (!userId || !teamAdmin) return false;
                return userId === teamAdmin;
            },
            'update:channal': ({ userId, teamAdmin }) => {
                if (!userId || !teamAdmin) return false;
                return userId === teamAdmin;
            },
            'remove:user': ({ userId, teamAdmin }) => {
                if (!userId || !teamAdmin) return false;
                return userId === teamAdmin;
            },
            'join:channal': ({ user, teamId }) => {
                let userTeamListId = user.teamList.map((team) => team.team);
                return userTeamListId.includes(teamId);
            },
        },
    },
    teamManager: {
        static: [
            'create:team',
            'create:channal',
            'invite:user',
            'remove:user',
            'join:channal',
            // 'invite:manager',
            'settings:team',
            'view:form',
            'assign:channalRole',
            'create:event',
            'modify:event',
            'update:channal',
            'edit:messagePermission',
            'delete:message',
            'update:team',
            'create:channelForm',
            'start:call',
        ],
        dynamic: {
            'update:channal': ({ userId, teamAdmin, userRole }) => {
                if (!userId || !teamAdmin) return false;
                return userId === teamAdmin || userRole === 'manager';
            },
            'remove:team': ({ userId, teamAdmin }) => {
                if (!userId || !teamAdmin) return false;
                return userId === teamAdmin;
            },
            'remove:channel': ({ userId, teamAdmin, memberTeamRole, userRole }) => {
                if (memberTeamRole === 'teamManager') {
                    return false;
                }
                if (userRole === 'manager' || userId === teamAdmin) {
                    return true;
                }
                return false;
            },
        },
    },
    admin: {
        static: [
            'create:team',
            'create:channal',
            'invite:manager',
            'remove:team',
            'remove:channel',
            'remove:user',
            'invite:user',
            'join:channal',
            'update:team',
            'update:channal',
            'delete:message',
            'edit:messagePermission',
            'view:form',
            'assign:channalRole',
            'settings:team',
            'create:event',
            'modify:event',
            'channel:byId',
            'create:channelForm',
            'start:call',
        ],
    },
    supervisor: {
        static: [
            'create:team',
            'create:channal',
            'remove:channel',
            'remove:user',
            'invite:user',
            'join:channal',
            'update:channal',
            'delete:message',
            'edit:messagePermission',
            'assign:channalRole',
            'create:event',
            'modify:event',
            'channel:byId',
            'create:channelForm',
            'start:call',
        ],
    },
};

export default rules;
