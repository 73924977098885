import React from 'react';
import { css } from 'twin.macro';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import { EditAProvider } from '../../../../_redux/actions/ActionEVV';
import { connect } from 'react-redux';
import { Body } from './styles';
import ProviderForm from './ProviderForm';

import { BsEye } from 'react-icons/bs';
import { ToolTip } from '../../../../_elements/ToolTip';

const ViewProvider = ({ provider }) => {
	const [showModal, setShowModal] = useState(false);
	const [selectedOption, setSelectedOption] = useState();
	const [selectedTeam, setSelectedTeam] = useState(null);
	const [teamAlreadyExists, setTeamAlreadyExists] = useState(provider?.team);

	const handleModalShow = () => {
		setShowModal(true);
		setSelectedOption(null);
	};

	return (
		<>
		<ToolTip text={'View'} cssStyles={"top:-70% !important; min-width: fit-content !important;"}>
			<BsEye className="icon" onClick={handleModalShow} />
		</ToolTip>
			<Modal show={showModal} backdropClassName="bg-dark" onHide={() => setShowModal(false)} centered>
				<Modal.Header closeButton>
					<div className="header">
						<h4
							css={css`
								margin: 0;
							`}
						>
							View Provider
						</h4>
					</div>
				</Modal.Header>
				<Modal.Body className="modal-body">
					<Body>
						<ProviderForm
							formType="View"
							provider={provider}
							selectedOption={selectedOption}
							setSelectedOption={setSelectedOption}
							selectedTeam={selectedTeam}
							setSelectedTeam={setSelectedTeam}
							teamAlreadyExists={teamAlreadyExists}
							setTeamAlreadyExists={setTeamAlreadyExists}
						/>
					</Body>
				</Modal.Body>
			</Modal>
		</>
	);
};
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { EditAProvider })(ViewProvider);
