import styled from '@emotion/styled';

export const DetailsStyled = styled('div')`
	display:flex;
	flex-direction:column;
	align-items:center;
	text-align:center;

	.replacementImg{
		display:flex;
		justify-content:center;
		align-items:center;
		height: 8rem;
		width: 8rem;
		border-radius: 4rem;
		color:white;
		font-size:32px;
		font-weight:lighter;
		margin-bottom:10px;
	}

	.role-badge{
		padding:0px 5px;
		border-radius: 2.5px;
		font-size: 12px;
		margin:10px 0px;
	}
	.supervisor{
		border:1px solid green;
		color:green;
	}
	.family{
		color:white;
		background-color: #F87A86;
	}
	.doctor{
		border:1px solid red;
		color:red;
	}
	.user{
		border:1px solid #50aeb0;
		color:#50aeb0;
	}

	.avatar-styles{
		border-radius:50%;
		margin-bottom:1rem;
	}

`;