import React, { useState } from 'react';
import { BsTrash } from 'react-icons/bs';
// import { toast } from 'react-toastify';


// styles
import { Modal } from 'react-bootstrap';
import { Body } from '../Teams/Styles/DeleteTeamStyles';
import { customCheckbox } from '../../../_elements/CustomCheckbox';
import { ToolTip } from '../../../_elements/ToolTip';


const DeleteForms = ({handleDelete, item, type, disabled,fetchForms}) => {

    const [deleteModal, setDeleteModal] = useState(false);
	const [agree, setAgree] = useState(false);
	const [error, setError] = useState('');

    const handelDeleteForm = () => {
        if (agree) {
            try{
                handleDelete(item._id);
                if (typeof fetchForms === 'function') {fetchForms();}
                setDeleteModal(false);
            }catch(e){
                console.log(e)
                setDeleteModal(false);
            }
			
		} else {
			setError('Please read and sign the checkbox');
            setDeleteModal(false);
		}
    }

    return (
        <>
        <ToolTip text={'Delete'} side={'top'} cssStyles={"top:-70% !important; min-width: fit-content !important"}>
			<BsTrash className={`icon ${disabled ? 'disabled' : 'delete'}`} onClick={() => setDeleteModal(true)} />
        </ToolTip>
			<Modal show={deleteModal} onHide={() => setDeleteModal(false)} centered>
				<Modal.Body className="p-0">
				<Body>
						<div className="title">
							<strong>Delete { type==="package" ? "Plan" : "Form"}</strong>
						</div>
                        <div className="content d-flex flex-column justify-content-between">
                            <div>
                                <span className="text text-dark-grey">
								Are you sure you want to delete the {type==="package" ? "form plan" : "form" } <span className="text-danger">{item.name}</span>?
								{ type==="package" && " Deleting will remove all its forms." } {' '}
                                </span>
                                <div className="d-flex flex-row align-items-center mt-4">
                                    <input type="checkbox" css={customCheckbox} id="agree" className="inline" onChange={() => setAgree(!agree)} />
                                    <label htmlFor="agree" className="d-inline m-0 ml-2 text-light-grey">
                                        I understand that the{ type==="package" ? " forms " : " form " }will be permanently removed
                                    </label>
                                </div>
                                <div className="text-danger">
                                    <small>{error}</small>
                                </div>
                            </div>
                            <div className="d-flex flex-row justify-content-end align-items-center mt-4">
                                <button
                                    className="btn text-dark button-light-green rounded"
                                    onClick={() => setDeleteModal(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    className={'btn text-white bg-danger rounded border ml-2'}
                                    onClick={() => handelDeleteForm()}
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
					</Body>
				</Modal.Body>
			</Modal>
		</>
    )
}

export default DeleteForms
