import styled from '@emotion/styled';

export const SubmittedFormStyled = styled('div')`
	padding: 1rem 2rem;

	.form-element {
		transition: all 1s ease-in;
		overflow-wrap: break-word;
	}
	.form-element:hover {
		border: 1px solid #000;
	}
	.form-element-control {
		display: none;
	}
	.form-element:hover * .form-element-control {
		display: block;
	}
	td {
		vertical-align: middle;
	}

	.action {
		display: flex;
		flex: 1 1 1;
	}
	.action-card {
		margin-left: 5px;
		padding: 5px;
		border-radius: 5px;
		box-shadow: 0px 1px 3px grey;
	}
	.view {
		color: blue;
	}
	.action-card:hover {
		transform: scale(1.2);
	}
	.customSearch_Div {
		min-width: 25%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.colorRed {
		color: red;
	}
	.deleteBtn {
		cursor: pointer;
		background-color: transparent;
		border: none;
		box-shadow: none;
	}
	.userImage {
		width: 5rem;
		margin: 0 1rem;
		border-radius: 1000px;
		height: 5rem;
		object-fit: contain;
		display:flex;
		align-items:center;
		justify-content:center;
		color: #fff;
		font-size: 14px;

	}
	.filtersDiv{
		padding: 0.5rem 1rem;
		margin: 0.5em;
		border-radius: 5px;
		border:1px solid #EA9941;
		background-color:#fef4e9;
	}
	.filterbadge {
		display: inline-block;
		margin: 0.5em 1em;
		padding: 0.6em;
	}
	.BuildAndSearchDiv {
		display: flex;
		margin-bottom: 1%;
		justify-content: space-between;
		align-items: center;
	}
`;