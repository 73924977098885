import React, { useState, useEffect } from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { BsEye } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { Tab, Tabs } from 'react-bootstrap';
import { BsGrid, BsList } from 'react-icons/bs';
import { ToolTip } from '../../../_elements/ToolTip';

import { ActivityActionPackageStyled } from './styles/ActivityActionPackageStyled';
import NavigationBar from '../reusableComponents/NavigationBar';
import Loader from '../containers/Loader';
import { setMessage } from '../../../_redux';
import { fetchWrapper, prepareEvent, sortingOptionMethod } from '../../../_helpers';
import { AdminTable } from '../reusableStyles';
import { ActionStyles } from '../reusableStyles';
import PreviewEventModal from '../Tasks/PreviewEventModal';
import EventModal from '../Tasks/EventModal';
import { getTeamIdFromURL } from '../../../_helpers';
import EventPlannerCalendar from './EventPlannerCalendar';
import TeamSelectFilter from '../TeamSelectFilter';
import TaskSelectFilter from '../TaskSelectFilter';

const getEventId = (event) =>
	`${event.title}+${event.date}+${event.dueDate}+${event.eventType?._id || event.eventType?.id || event.eventType?.toString()}`;

const ActionActivityPackageTemplate = ({ chatroom = null, organizations, teams, match, CurrUser }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [actionMode, setActionMode] = useState('Create');
	const [packageName, setPackageName] = useState(null);
	const [loading, setLoading] = useState(false);
	const [allTeams, setAllTeams] = useState([]);
	const [selectedTeamOption, setSelectedTeamOption] = useState([]);
	const [allTasks, setAllTasks] = useState([]);
	const [selectedTasks, setSelectedTasks] = useState([]);
	const [packageData, setPackageData] = useState({});
	const { register, handleSubmit } = useForm({
		mode: 'onBlur',
	});
	const [showModal, setShowModal] = useState(null);
	const [channel, setChannel] = useState(null);
	const [userRole, setUserRole] = useState(null);
	const { channelId } = useParams();
	const teamId = getTeamIdFromURL();
	const [key, setKey] = useState('event_list');

	const [isSubmitting, setIsSubmitting] = useState(false);

	useEffect(() => {
		if (allTasks.length && allTeams.length) {
			setLoading(false);
		}
	}, [allTasks, allTeams]);

	const getPackageData = async function (isPackage) {
		const response = await fetchWrapper.get(
			`/${isPackage ? 'activityPackages' : 'activityPackageTemplates'}/${match.params.id}`
		);
		const data = Array.isArray(response) ? response[0] : response;
		setPackageData(data);
	};

	useEffect(() => {
		if (match.params.id) {
			getPackageData(match.params.id && !match.params.mode ? true : false);
		}
		setActionMode(match.params.mode === 'edit' ? 'Edit' : match.params.mode === 'copy' ? 'Copy' : 'Create');
	}, [match.params.id, match.params.mode]);

	// const getAllTasksOfSomeTeams = async () => {
	// 	const { results } = await fetchWrapper.get(`task${teamId ? '?team=' + teamId + '&limit=300' : '?limit=300'}`);
	// 	const taskOptions = results?.map((task) => {
	// 		const taskTeamId = task.assignedChannelInfo[0]?.teamData?._id;
	// 		const taskTeamName = teams.find((team) => team._id === taskTeamId)?.name;
	// 		const taskOption = {
	// 			...task,
	// 			label: `${task.title}${teamId ? '' : taskTeamName ? ' - ' + taskTeamName : ''}`,
	// 			start: task.date,
	// 			end: task.dueDate,
	// 			team: taskTeamId,
	// 			value: getEventId(task),
	// 			_id: getEventId(task),
	// 			assignedChannel: undefined,
	// 		};
	// 		delete taskOption.assignedChannelInfo;
	// 		return taskOption;
	// 	});
	// 	setAllTasks(taskOptions);
	// };

	// useEffect(() => {
	// 	if (organizations.length && teams.length === 0) {
	// 		setLoading(true);
	// 	}
	// 	getAllTasksOfSomeTeams();
	// }, []);

	useEffect(() => {
		chatroom && setSelectedTeamOption([chatroom]);
	}, [chatroom]);

	useEffect(() => {
		const selectedTeams = teamId ? [teams.find((team) => team._id === teamId)] : teams;
		const teamsOptions = selectedTeams.map((team) => ({
			label: team?.name,
			value: team?._id,
		}));
		if (teamsOptions && teamsOptions.length && teamsOptions[0]) {
			setAllTeams(teamsOptions.sort(sortingOptionMethod));
		}
	}, [teams]);

	useEffect(() => {
		if (!packageData) {
			return null;
		}
		// PackageName
		const packageName = packageData?.name
			? actionMode === 'Edit'
				? `Edited ${packageData.name}`
				: actionMode === 'Copy'
					? `Copy of ${packageData.name}`
					: packageData.name
			: '';
		setPackageName(packageName);

		// teams
		const teamsOptions = (
			Array.isArray(packageData.teamData)
				? packageData.teamData
				: packageData.teamData
					? [packageData.teamData]
					: []
		).map((team) => ({
			value: team?.id,
			label: team?.name,
		}));
		setSelectedTeamOption(teamsOptions);

		// task
		const tasksOptions = (packageData?.taskData || []).map((task) => ({
			value: getEventId(task),
			_id: getEventId(task),
			label: task.title,
			...task,
		}));
		setSelectedTasks(tasksOptions);
	}, [actionMode, packageData]);

	useEffect(() => {
		if (!CurrUser) {
			return null;
		}
		let userTeam = CurrUser.teamList.filter((team) => team.team && team.team?.id === channel?.team);
		let userChannel = CurrUser.roomList.filter((room) => room.room && room.room.id === channelId);
		if (CurrUser.role === 'admin') {
			setUserRole('admin');
		} else if (userTeam.length) {
			if (userTeam[0].role === 'owner' || userTeam[0].role === 'teamManager') {
				setUserRole(userTeam[0].role);
			} else if (userChannel.length) {
				setUserRole(userChannel[0].role);
			}
		} else if (userChannel.length) {
			setUserRole(userChannel[0].role);
		} else {
			setUserRole(null);
		}
	}, [CurrUser, channel?.team, channelId]);

	useEffect(() => {
		if (channelId) {
			fetchWrapper.get(`chatrooms/${channelId}`).then((res) => setChannel(res));
		}
	}, [channelId]);

	const handleShowEvent = (id) => {
		setShowModal(id);
	};

	const handleClose = () => {
		setShowModal(null);
	};

	const handleEditClick = (id, newTask) => {
		return onTaskUpdateCallback(newTask, 'edit', id);
	};

	const onTaskUpdateCallback = (task, mode, id, editMessage = 'Event edited successfully') => {
		//patch allTask & selectedTasks
		if (mode !== 'edit') {
			task._id = `NEW_TASK_${Date.now()}_${Math.round(Math.random() * 100)}`;
			setAllTasks((prev) => [
				...prev,
				{ ...task, label: task.title, value: task._id, team: selectedTeamOption?.value },
			]);
			setSelectedTasks((prev) => [
				...prev,
				{ ...task, label: task.title, value: task._id, team: selectedTeamOption?.value },
			]);
			dispatch(setMessage({ error: false, message: 'Event created successfully' }));
		} else {
			setAllTasks((prev) =>
				prev.map((exTask) =>
					exTask._id === id ? { ...task, _id: id, label: task.title, value: id } : { ...exTask }
				)
			);
			setSelectedTasks((prev) =>
				prev.map((exTask) =>
					exTask._id === id ? { ...task, _id: id, label: task.title, value: id } : { ...exTask }
				)
			);
			dispatch(setMessage({ error: false, message: editMessage }));
		}
		return task;
	};

	const onTeamSearchChange = (newSelectedTeamOption) => {
		if (newSelectedTeamOption) {
			setSelectedTeamOption(newSelectedTeamOption);
		}
	};

	const onTaskSearchChange = (newSelectedTask) => {
		if (newSelectedTask) {
			setSelectedTasks(newSelectedTask);
		}
	};

	const onSubmit = async (data) => {
		if (!data) {
			return null;
		}
		setIsSubmitting(true);
		try {
			const selectedTeamIds = selectedTeamOption.map((team) => team?.value);
			const postPackageData = {
				name: data.packageName,
				teams: selectedTeamIds || [],
				events: selectedTasks.map((task) =>
					prepareEvent(task, [
						'title',
						'description',
						'date',
						'dueDate',
						'recurringUntil',
						'forms',
						'startReminders',
						'endReminders',
						'recurring',
						'eventType',
						'eventMode',
						'trainings',
					])
				),
			};

			if (!postPackageData.events.length) {
				throw new Error('Please select at least one event');
			}

			if (!postPackageData.teams.length) {
				throw new Error('Please select at least one team');
			}

			if (actionMode === 'Edit') {
				await fetchWrapper.put(`activityPackageTemplates/${match.params.id}`, postPackageData);
				dispatch(setMessage({ error: false, message: 'Template Edited successfully' }));
			} else {
				await fetchWrapper.post(`activityPackageTemplates`, postPackageData);
				const message = `Template ${actionMode === 'Copy' ? 'copied' : 'created'} successfully`;
				dispatch(setMessage({ error: false, message }));
			}
			history.goBack();
		} catch (e) {
			console.log(e);
			dispatch(setMessage({ error: true, message: `Error ${e.message || e.error}` }));
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<ActivityActionPackageStyled>
			<div className="d-flex justify-content-between mb-4">
				<NavigationBar mainTabName="Master Event Planner" tab1={`${actionMode} Template`} tab={packageName} />
			</div>

			{loading ? (
				<Loader size="sm" />
			) : (
				<div className="d-flex flex-column align-items-center justify-content-between position-relative pt-5">
					<div className="top-items flex justify-content-end">
						<EventModal
							channel={channel}
							handleSubmitTask={onTaskUpdateCallback}
							teamIds={[selectedTeamOption?.value]}
						/>
					</div>

					<Form className="custom-card w-75 m-auto" onSubmit={handleSubmit(onSubmit)}>
						<Form.Row>
							<Form.Group as={Col} md={12}>
								<Form.Label>Template Name</Form.Label>
								<Form.Control
									type="text"
									value={packageName || ''}
									onChange={(e) => setPackageName(e.target.value)}
									name="packageName"
									required
									ref={register}
								/>
								{/* <Form.Text className="text-danger">{error.name}</Form.Text> */}
							</Form.Group>

							{teamId ? null : (
								<Form.Group as={Col} md={12}>
									<Form.Label>Teams</Form.Label>
									<TeamSelectFilter
										selectedTeamOption={selectedTeamOption ? selectedTeamOption : []}
										setSelectedTeamOption={setSelectedTeamOption}
										className="bolder"
										required
										name="teams"
										isMulti
									/>
									{/* <Form.Text className="text-danger">{error.room}</Form.Text> */}
								</Form.Group>
							)}

							<Form.Group as={Col} md={12}>
								<Form.Label>Events</Form.Label>
								<TaskSelectFilter
									selectedOption={selectedTasks?.length ? selectedTasks : []}
									name="tasks"
									defaultTasks={allTasks}
									onTaskSelect={onTaskSearchChange}
									required
									isMulti
								/>
								<Tabs
									id="controlled-tab-example"
									activeKey={key}
									onSelect={(k) => setKey(k)}
									className="mb-3"
								>
									<Tab eventKey="event_list" title={<BsList />}>
										{key === 'event_list' && (
											<AdminTable className="mb-5 mt-5 w-100">
												<thead>
													<tr>
														<th>S.N.</th>
														<th>Event Name</th>
														<th>Date</th>
														<th>Actions</th>
													</tr>
												</thead>
												<tbody>
													{selectedTasks?.sort(
														(a, b) => new Date(a.latestDatePointer || a.date) - new Date(b.latestDatePointer || b.date)
													)?.map((event, index) => (
														<tr key={event._id}>
															<td className="text-left p-1">{index + 1}</td>
															<td className=" text-left p-1">{event.title}</td>
															<td className="text-left p-1">
																{dayjs(event.latestDatePointer || event.date).format('MMMM D, YYYY h:mm A')}
															</td>
															<td className=" text-left p-1">
																<ActionStyles>
																	<div>
																		<ToolTip
																			text={'View'}
																			cssStyles={
																				'top:-70% !important; min-width: fit-content !important'
																			}
																		>
																			<BsEye
																				onClick={() =>
																					handleShowEvent(event._id)
																				}
																				className="icon view"
																			/>
																		</ToolTip>
																	</div>
																	<EventModal
																		data={event}
																		handleSubmitTask={(newTasks) =>
																			handleEditClick(event?._id, newTasks)
																		}
																		userRole={userRole}
																	/>
																	{/* <BiLinkExternal className="icon assign" /> */}
																</ActionStyles>
															</td>
														</tr>
													))}
												</tbody>
											</AdminTable>
										)}
									</Tab>
									<Tab eventKey="event_grid" title={<BsGrid />}>
										{key === 'event_grid' && (
											<EventPlannerCalendar
												handleEditEvent={handleEditClick}
												handleCreateEvent={onTaskUpdateCallback}
												events={selectedTasks}
												eventModalProps={{ channel, teamIds: [selectedTeamOption?.value] }}
												handleShowEvent={handleShowEvent}
											/>
										)}
									</Tab>
								</Tabs>
							</Form.Group>
							{showModal ? (
								<PreviewEventModal
									data={selectedTasks?.find(({ _id }) => _id === showModal)}
									handleClose={handleClose}
								// getAllTasks={getAllTasks}
								/>
							) : null}
							{
								<Button variant="primary" type="submit" disabled={isSubmitting}>
									{actionMode} Template
								</Button>
							}
						</Form.Row>
					</Form>
				</div>
			)}
		</ActivityActionPackageStyled>
	);
};
ActionActivityPackageTemplate.propTypes = {};

const mapStateToProps = (state) => ({
	organizations: state.organizations.organizations,
	teams: state.teams.teams,
	CurrUser: state.user.user,
});
const mapDispatchToProps = { setMessage };

export default connect(mapStateToProps, mapDispatchToProps)(ActionActivityPackageTemplate);
