import moment from 'moment';
import React from 'react';
import { useEffect, useState } from 'react';

function OngoingCallTime({ startedAt, endTime }) {
	const [time, setTime] = useState();
	useEffect(() => {
		let timer;
		if (endTime) {
			let diff = moment(new Date(endTime)).diff(moment(new Date(startedAt)));

			// execution
			let f = moment.utc(diff).format('HH:mm:ss');
			setTime(f);
		} else {
			timer = setInterval(() => {
				let diff = moment(new Date()).diff(moment(new Date(startedAt)));

				// execution
				let f = moment.utc(diff).format('HH:mm:ss');
				setTime(f);
			}, 1000);
		}
		return () => {
			if (timer) clearInterval(timer);
		};
	}, [endTime, startedAt]);
	return <div>{time}</div>;
}

export default OngoingCallTime;
