import { css } from 'twin.macro';
import React, { Fragment } from 'react';

// assets
import { AiOutlineMinusCircle } from 'react-icons/ai';
import dayjs from 'dayjs';

export const SearchFilterCSS = css`
	margin-top: 1rem;
	border: 1px solid #dee2e6 !important;
	padding: 1rem !important;
	.filterRow {
		display: flex;
		justify-content: space-between;
	}
`;

const SearchFilter = ({
	firstName,
	lastName,
	userEmployee,
	provider,
	startDate,
	endDate,
	client,
	handleClientSelect,
	handleNameChange,
	handleUserEmployeeSelect,
	handleProviderSelect,
	handleStartDateClear,
	handleEndDateClear,
	handleClearAll,
	isTeamSettings,
	isChannelSettings,
	handleClearIsManual,
	handleClearVisitLabelFilter,
	visitLabelFilter,
	isManual,
}) => {
	return (
		<Fragment>
			{
			// (firstName ||
			// 	lastName  ||
			// 	userEmployee  ||
			// 	provider  ||
			// 	client  ||
			// 	startDate  ||
			// 	endDate  ||
			// 	visitLabelFilter  ||
			// 	isManual )
			(firstName !== undefined ||
				lastName  !== undefined ||
				userEmployee  !== undefined ||
				provider?._id  !== undefined ||
				client  !== undefined ||
				startDate  !== null ||
				endDate  !== null ||
				visitLabelFilter  !== null ||
				isManual  !== false)
				&&  (
				<div css={SearchFilterCSS}>
					<h4>Search Filters:</h4>
					<div>
						{firstName && (
							<div className="filterRow">
								{' '}
								First Name:
								<div>
									<b className="text-primary">{firstName}</b>
									<button
										className="btn"
										onClick={() => {
											handleNameChange({ target: { value: '', name: 'firstName' } });
										}}
										disabled={isChannelSettings}
									>
										<AiOutlineMinusCircle fontSize="24px" color="f30e00" />
									</button>
								</div>
							</div>
						)}
						{lastName && (
							<div className="filterRow">
								{' '}
								Last Name:
								<div>
									<b className="text-primary">{lastName}</b>
									<button
										className="btn"
										onClick={() => {
											handleNameChange({ target: { value: '', name: 'lastName' } });
										}}
										disabled={isChannelSettings}
									>
										<AiOutlineMinusCircle fontSize="24px" color="f30e00" />
									</button>
								</div>
							</div>
						)}
						{userEmployee && (
							<div className="filterRow">
								Employee:
								<div>
									<b className="text-primary">{userEmployee.name}</b>
									<button
										className="btn"
										onClick={() => {
											handleUserEmployeeSelect(null);
										}}
									>
										<AiOutlineMinusCircle fontSize="24px" color="f30e00" />
									</button>
								</div>
							</div>
						)}
						{client && (
							<div className="filterRow">
								Client:
								<div>
									<b className="text-primary">{client.name}</b>
									<button
										className="btn"
										onClick={() => {
											handleClientSelect(null);
										}}
										disabled={isChannelSettings}
									>
										<AiOutlineMinusCircle fontSize="24px" color="f30e00" />
									</button>
								</div>
							</div>
						)}
						{provider?.value && (
							<div className="filterRow">
								Provider:
								<div>
									<b className="text-primary">{provider.label}</b>
									{!isTeamSettings && (
										<button
											className="btn"
											onClick={() => {
												handleProviderSelect(null);
											}}
										>
											<AiOutlineMinusCircle fontSize="24px" color="f30e00" />
										</button>
									)}
								</div>
							</div>
						)}
						{visitLabelFilter && (
							<div className="filterRow">
								Label Filter:
								<div>
									<b className="text-primary text-capitalize">{visitLabelFilter}</b>
									<button
										className="btn"
										onClick={() => {
											handleClearVisitLabelFilter();
										}}
									>
										<AiOutlineMinusCircle fontSize="24px" color="f30e00" />
									</button>
								</div>
							</div>
						)}
						{isManual && (
							<div className="filterRow">
								Only manual:
								<div>
									<b className="text-primary">{isManual ? 'True' : 'False'}</b>
									<button
										className="btn"
										onClick={() => {
											handleClearIsManual();
										}}
									>
										<AiOutlineMinusCircle fontSize="24px" color="f30e00" />
									</button>
								</div>
							</div>
						)}
						{startDate && (
							<div className="filterRow">
								Start Date:
								<div>
									<b className="text-primary">{dayjs(startDate).format('MM-DD-YYYY, h:mm A')}</b>
									<button
										className="btn"
										onClick={() => {
											handleStartDateClear();
										}}
									>
										<AiOutlineMinusCircle fontSize="24px" color="f30e00" />
									</button>
								</div>
							</div>
						)}
						{endDate && (
							<div className="filterRow">
								End Date:
								<div>
									<b className="text-primary">{dayjs(endDate).format('MM-DD-YYYY, h:mm A')}</b>
									<button
										className="btn"
										onClick={() => {
											handleEndDateClear();
										}}
									>
										<AiOutlineMinusCircle fontSize="24px" color="f30e00" />
									</button>
								</div>
							</div>
						)}
					</div>
					{!isTeamSettings && (
						<button className="btn btn-secondary" onClick={handleClearAll}>
							Clear all
						</button>
					)}
				</div>
			)}
		</Fragment>
	);
};

export default SearchFilter;
