import React from 'react';
import { css } from 'twin.macro';
import { ToolTip } from '../../../../_elements/ToolTip';
import { BsTags } from 'react-icons/bs';

export const TagPreview = ({ tag }) => {
    return (
        <span css={css`
			margin-right: 8px;
			background: #e1e1f3;
			color: #39308A;
			padding: 1px 6px;
			cursor: pointer;
			border-radius: 5px;
			display: flex;
			font-weight: 700;
			text-transform: uppercase;
			font-size: 13px;
			width:fit-content;
        `}>
            {tag?.name || tag}
        </span>
    )
}

export const TagPreviewWithContainer= ({ tags = [], ...props }) => {
    return (
        <div {...props} css={css`display:flex;overflow-y:auto;flex-wrap:wrap;gap:6px;`}>
            {(tags || [])?.map((tag,tagIndex) => (<TagPreview key={tag?._id || tag?.id || tagIndex } tag={tag} />))}
        </div>
    )
}

export const AddTagsTooltip = () => (
    <React.Fragment>
        <ToolTip text={'Add tags'} cssStyles={"top:-105% !important; min-width:70px !important;"}>
            <BsTags size={20} className='icon' style={{ color: '#50aeb0' }} />
        </ToolTip>
    </React.Fragment>
)
