import * as ActionTypes from '../actions/ActionTypes';

const initialState = {
	chatRoomUserFormData: {},
	chatRoomFormTemplates: {},
	chatRoomFiles: {},
	chatRoomMedias: {},
};

export default function ChatroomData(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.SET_CHATROOM_FORMS:
			let newTempChatRoomRemplates = Object.assign({}, state.chatRoomFormTemplates);
			console.log(newTempChatRoomRemplates, 'newTempChatRoomRemplates');
			let chatRoomFormsArray = Object.assign([], newTempChatRoomRemplates[action.payload.chatRoomId]);
			chatRoomFormsArray = [...chatRoomFormsArray, ...action.payload.roomFormTemplates];
			console.log(chatRoomFormsArray, 'chatRoomFormsArray');
			newTempChatRoomRemplates[action.payload.chatRoomId] = chatRoomFormsArray;
			console.log(newTempChatRoomRemplates, 'newTempChatRoomRemplates after');
			return {
				...state,
				chatRoomFormTemplates: newTempChatRoomRemplates,
			};
		case ActionTypes.CLEAR_CHATROOM_FORMS:
			let TempChatRoomRemplates = Object.assign({}, state.chatRoomFormTemplates);
			TempChatRoomRemplates[action.payload.chatRoomId] = [];
			return {
				...state,
				chatRoomFormTemplates: TempChatRoomRemplates,
			};
		case ActionTypes.SET_CHATROOM_FILES:
			let newTempChatRoomFiles = Object.assign({}, state.chatRoomFiles);
			newTempChatRoomFiles[action.payload.chatRoomId] = action.payload.roomFiles;
			return {
				...state,
				chatRoomFiles: newTempChatRoomFiles,
			};
		case ActionTypes.SET_CHATROOM_MEDIAS:
			let newTempChatRoomMedias = Object.assign({}, state.chatRoomMedias);
			let newMedias = Object.assign([], newTempChatRoomMedias[action.payload.chatRoomId]?.medias);

			// next page values
			if (newTempChatRoomMedias[action.payload.chatRoomId]?.page < action.payload.page) {
				newMedias = [...newMedias, ...action.payload.roomMedias];
			} else {
				newMedias = action.payload.roomMedias;
			}

			let mediaObj = {
				medias: newMedias,
				page: action.payload.page,
				totalMedias: action.payload.totalMedia,
			};
			newTempChatRoomMedias[action.payload.chatRoomId] = mediaObj;
			return {
				...state,
				chatRoomMedias: newTempChatRoomMedias,
			};
		default:
			return state;
	}
}
