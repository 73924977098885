import React from 'react';
import { css } from 'twin.macro';
import Main from '../../Layouts/Main';
import NotFoundSVG from '../../assets/not_found.svg';
import { Link } from 'react-router-dom';
import { setLastSeenChannel } from '../../_redux';
import { useDispatch } from 'react-redux';

function NotFound() {
	const dispatch = useDispatch();
	const handleClearLastSeenChannel = () => {
		dispatch(setLastSeenChannel(''));
	};
	return (
		<Main>
			<div
				css={css`
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					margin-top: 2rem;
				`}
			>
				<img
					css={css`
						width: 400px;
					`}
					src={NotFoundSVG}
					alt="not found"
				/>
				<div
					css={css`
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						.title {
							font-size: 8rem;
							color: #4fa7aa;
						}
					`}
				>
					<h1 className="title">404 </h1>
					<p
						css={css`
							font-size: 3rem;
							color: #636363;
						`}
					>
						Oops! Page not found
					</p>
					<div
						css={css`
							width: 70%;
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;
							p {
								text-align: center;
								display: inline-block;
								margin-bottom: 3rem;
								color: #727272;
							}
						`}
					>
						<p>
							Sorry, but the page you are looking for is not found. Please, make sure you have typed the
							correct URL or you have the authorization.
						</p>
						<Link to="/">
							<button
								className="btn btn-link btn-primary mb-4"
								css={css`
									:hover {
										text-decoration: none;
									}
								`}
								onClick={handleClearLastSeenChannel}
							>
								Go back Home
							</button>
						</Link>
					</div>
				</div>
			</div>
		</Main>
	);
}

export default NotFound;
