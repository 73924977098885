import { css } from 'twin.macro';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { VscLoading } from 'react-icons/vsc';
import VistForm from './VistForm';

const CreateAVisit = ({ provider, client, visitAddResult, isTeamSettings, isChannelSettings, team }) => {
	const [showModal, setShowModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const handleShowLoading = () => {
		setIsLoading(true);
	};
	const handleHideLoading = () => {
		setIsLoading(false);
	};

	const handleModalShow = () => {
		setShowModal(true);
	};
	const handleModalHide = () => {
		setShowModal(false);
	};

	const visitResult = (result) => {
		visitAddResult(result);
		if (result) {
			setShowModal(false);
		}
	};
	return (
		<>
			<Button variant="primary" onClick={handleModalShow}>
				Create New Visit
			</Button>
			<Modal show={showModal} backdropClassName="bg-dark" onHide={() => setShowModal(false)} centered>
				{isLoading && (
					<div
						css={css`
							position: absolute;
							top: 0;
							left: 0;
							font-size: 50px;
							width: 100%;
							height: 100%;
							color: white;
							display: flex;
							justify-content: center;
							align-items: center;
							background-color: rgba(255, 255, 255, 0.6);
							z-index: 2;
							cursor: default;
						`}
					>
						<VscLoading className="spin" color="black" />
					</div>
				)}
				<Modal.Header closeButton>
					<Modal.Title className="modal-headers">Create New Visit</Modal.Title>
				</Modal.Header>
				<VistForm
					provider={provider}
					client={client}
					isTeamSettings={isTeamSettings}
					isChannelSettings={isChannelSettings}
					handleModalHide={handleModalHide}
					handleShowLoading={handleShowLoading}
					handleHideLoading={handleHideLoading}
					visitResult={visitResult}
					team={team}
				/>
			</Modal>
		</>
	);
};

export default CreateAVisit;
