import { css } from 'twin.macro';
import { Fragment } from 'react';
import ImageModal from '../../Teams/ImageModal';
import { SanitizeHTML } from '../../../../_helpers';
import ShareBtn from '../../../ShareBtn/ShareBtn';
import mimetypes from '../../../../_helpers/mimetypes';
import { removeMongoidAndTimestamp } from '../../../../_helpers';
import FileViewer from '../FileViewer';
export const textStyles = css`
	font-size: 14px !important;
	text-decoration: none;
	color: #212529;
	margin-bottom: 10px;

	&:hover {
		color: #525c5e;
	}
`;

export default function ImagesMessage({ images, displayMessage, className, teamDetails }) {
	return (
		<Fragment>
			<SanitizeHTML html={displayMessage} />
			<div
				css={css`
					display: grid;
					gap: 5px;
					grid-template-areas:
						'. . .'
						'. . .'
						'. . .';
					overflow: hidden;
					.images_img {
						position: relative;
						width: 8vw;
						height: 8vw;
						border-radius: 5px;
						display: flex;
						justify-content: center;
						align-items: center;
						overflow: hidden;
						max-width: 250px;
						max-height: 250px;
						div {
							width: 100%;
							height: 100%;
						}
						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
						transition: filter 50ms;
						:hover a {
							filter: brightness(0.8);
						}
						:hover .shareBtn {
							opacity: 1;
							z-index: 2;
						}
					}
				`}
			>
				{images.map((image) => {
					const ext = image?.slice(((image.lastIndexOf('.') - 1) >>> 0) + 2);
					const mimetype = mimetypes[`.${ext}`] || "application/octet-stream";
					switch (mimetype?.split("/")[0]) {
						case 'video':
							return (
								<div
									key={image}
									css={css`
										display: flex;
										flex-direction: column;
										width: 15vw;
										height: 15vw;
										display: flex;
										justify-content: center;
										align-items: center;

										position: relative;
										:hover .shareBtn {
											opacity: 1;
											z-index: 200;
										}
									`}
								>
									<ShareBtn url={image} />
									<div
										css={css`
											margin-top: 1rem;
											width: 100%;
											height: 100%;

											video {
												width: 100%;
												height: 100%;
												object-fit: cover;
											}
										`}
									>
										<video width="400" controls className={className} crossOrigin="anonymous">
											<source src={image} type={mimetype} />
											Your browser does not support HTML video.
										</video>
									</div>
								</div>
							);
						case 'audio':
							return (
								<div
									key={image}
									css={css`
										display: flex;
										flex-direction: column;
										position: relative;
										:hover .shareBtn {
											opacity: 1;
											z-index: 200;
										}
									`}
								>
									<ShareBtn url={image} />
									<div
										css={css`
											margin-top: 1rem;
										`}
									>
										<audio controls className={className}>
											<source src={image} type={mimetype} />
											Your browser does not support HTML audio.
										</audio>
									</div>
								</div>
							);
						case 'image':
							return (
								<div className="images_img" key={image}>
									<ImageModal file={image} className={className} documentSettings={teamDetails} />
								</div>
							);
						default:
							const fileName = image?.split('/')[image?.split('/').length - 1];
							return (
								<div className="images_img" key={image}>
									<FileViewer file={image} ext={ext} fileName={removeMongoidAndTimestamp(fileName)} documentSettings={teamDetails} />
								</div>
							)
					}
				})}
			</div>
		</Fragment>
	);
}
