import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Spinner } from 'react-bootstrap';
import { css } from 'twin.macro';

import ScrollToBottom from 'react-scroll-to-bottom';
import InfiniteScroll from 'react-infinite-scroller';

//redux
import { connect } from 'react-redux';
import NewMessageButton from '../../../Features/Teams/NewMessageButton';
import { getTheDate } from '../../../../_helpers';
import Message from './Message';
import { getSubChannelMessages, postLastSeenSubChannelMessage } from '../../../../_redux';
import MessageReactions from '../../../Features/Teams/MessageReactions';

const Messages = ({
	messages,
	subChannelId,
	subChannelType,
	totalMessages,
	setReply,
	lastSeen,
	userRole,
	isUploading,
	getSubChannelMessages,
	postLastSeenSubChannelMessage,
	roomData,
	isSearch,
}) => {
	const [messageId, setMessageId] = useState();

	useEffect(() => {
		var objDiv = document.querySelector('.m-c');
		objDiv.scrollTop = objDiv.scrollHeight;
	}, []);

	const groups = messages.reduce((groups, message) => {
		const date = getTheDate(new Date(message.dateAndTime));
		if (!groups[date]) {
			groups[date] = [];
		}
		groups[date].push(message);
		return groups;
	}, {});

	useEffect(() => {
		if (messages?.length > 0) {
			if (lastSeen !== messages[messages?.length - 1]?.id) {
				const lastSeenMessage = {
					user: localStorage.getItem('userId'),
					subChannel: subChannelId,
					message: messages[messages?.length - 1]?.id,
				};
				postLastSeenSubChannelMessage(lastSeenMessage);
			}
		}
	}, [lastSeen, messages, postLastSeenSubChannelMessage, subChannelId]);

	const refs = messages.reduce((acc, value) => {
		acc[value.id] = React.createRef();
		return acc;
	}, {});

	const loadFunc = (pages) => {
		getSubChannelMessages(subChannelId, pages);
	};
	const handleClose = () => {
		setMessageId(null);
	};

	const showMessageReactions = (id) => {
		setMessageId(id);
	};
	// #TODO: when user search, user should have the ability to scroll up or down with refernce to the search results date

	return (
		<Fragment>
			<ScrollToBottom
				behavior="scrollToEnd"
				className="messages"
				scrollViewClassName="m-c"
				followButtonClassName="scroll-btn"
			>
				<InfiniteScroll
					pageStart={1}
					initialLoad={false}
					loadMore={loadFunc}
					hasMore={isSearch ? false : messages.length < totalMessages}
					loader={
						<div className="loader" key={0}>
							<img
								src="/images/ball-triangle.svg"
								css={css`
									width: 2rem;
									height: 2rem;
								`}
								alt="loading"
							/>
						</div>
					}
					useWindow={false}
					isReverse={true}
					threshold={100}
				>
					{Object.keys(groups).map((date) => (
						<Message
							setReply={setReply}
							key={date}
							date={date}
							subChannelId={subChannelId}
							message={groups[date]}
							messages={messages}
							subChannelType={subChannelType}
							userRole={userRole}
							refs={refs}
							lastSeen={
								lastSeen !== messages[messages.length - 1].id &&
								messages[messages.length - 1].fromUser !== localStorage.getItem('userId')
									? lastSeen
									: ''
							}
							showMessageReactions={showMessageReactions}
							roomData={roomData}
						/>
					))}
					{isUploading && (
						<Fragment>
							<span className="small text-muted">Uploading</span>{' '}
							<Spinner animation="grow" variant="primary" size="sm" />
						</Fragment>
					)}
				</InfiniteScroll>
				<NewMessageButton />
			</ScrollToBottom>
			<Modal show={Boolean(messageId)} onHide={handleClose} centered>
				<Modal.Header closeButton>
					<h1>Message Reactions</h1>
				</Modal.Header>
				<Modal.Body>
					<MessageReactions subChannelId={subChannelId} messageId={messageId} />
				</Modal.Body>
			</Modal>
		</Fragment>
	);
};
Messages.propTypes = {
	messages: PropTypes.array.isRequired,
	subChannelId: PropTypes.string.isRequired,
	isSearch: PropTypes.bool.isRequired,
	getSubChannelMessages: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	isUploading: state.messages.isUploading,
});

export default connect(mapStateToProps, { getSubChannelMessages, postLastSeenSubChannelMessage })(Messages);
