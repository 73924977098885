import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Tooltip, OverlayTrigger, Modal, Button } from 'react-bootstrap';
import Avatar from '../../components/Admin/reusableComponents/Avatar';

function AvatarStack({ stack }) {
	const [showAll, setShowAll] = useState(false);
	const extras = stack?.length - 4;

	const handleClose = () => setShowAll(false);
	return (
		<>
			<StyledAvatarStack>
				{stack?.slice(0, 4).map((item, index) => {
					return (
						<OverlayTrigger
							key={item.id}
							placement="top"
							delay={{ show: 150, hide: 50 }}
							overlay={
								<Tooltip id="button-tooltip">
									<div>{item.username}</div>
									{item.message && <div>{item.message}</div>}
								</Tooltip>
							}
						>
							<div
								className="avatarStack-avatar"
								style={{ position: 'relative', left: `${index * -10}px` }}
							>
								<Avatar item={item} />
							</div>
						</OverlayTrigger>
					);
				})}
				{extras > 0 && (
					<OverlayTrigger
						key={'123'}
						placement="top"
						delay={{ show: 150, hide: 50 }}
						overlay={
							<Tooltip id="button-tooltip">
								<div>View all</div>
							</Tooltip>
						}
					>
						<div
							style={{ position: 'relative', left: `-40px` }}
							onClick={() => {
								setShowAll(true);
							}}
						>
							+{extras}
						</div>
					</OverlayTrigger>
				)}
			</StyledAvatarStack>

			<Modal show={showAll} onHide={handleClose} centered>
				<Modal.Header closeButton>
					<Modal.Title>All ({stack?.length})</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<StyledSeeAllContainer>
						{stack.map((item) => (
							<div key={item.id} className="d-flex mb-2">
								<div>
									<Avatar item={item} />
								</div>
								<div className="d-flex ms-2 align-items-center">
									<div>{item.username}</div>
									{item.message && <div> {item.message}</div>}
								</div>
							</div>
						))}
					</StyledSeeAllContainer>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default AvatarStack;

const StyledAvatarStack = styled.div`
	max-width: 200px;
	display: flex;
	div {
		cursor: pointer;
	}
	.avatarStack-avatar {
		:hover {
			z-index: 1;
		}
	}
`;

const StyledSeeAllContainer = styled.div`
	height: 500px;
	overflow: auto;
	margin: 10px 30px;
`;
