import { css } from 'twin.macro';
import React, { useState, Fragment, useEffect } from 'react';
import { Button, Form, Accordion } from 'react-bootstrap';
import { StyledScrollBarContainerCSS } from '../../../_elements/container';

import GPSLocationCard from './GPSLocationCard';

export default function LocationComponent({
	room,
	formLabel,
	watch,
	register,
	getValues,
	setValue,
	errors,
	location,
	setLocation,
}) {
	const [locationIndexes, setLocationIndexes] = useState(() => {
		if (Boolean(room?.addresses?.length)) {
			return room.addresses?.map((address, idx) => idx);
		}
		return [];
	});
	const [locationCounter, setLocationCounter] = useState(locationIndexes.length);

	const addLocation = () => {
		setLocationIndexes((prevLocationIndexes) => [...prevLocationIndexes, locationCounter]);
	};

	const removeLocation = (index) => {
		setLocationIndexes((prevLocationIndexes) => [...prevLocationIndexes.filter((item) => item !== index)])
	};

	useEffect(() => {
		setLocationCounter(() => (locationIndexes.length ? Math.max(...locationIndexes) : 0) + 1);
	}, [locationIndexes])

	return (
		<Fragment>
			<Form.Label className="justify-content-between d-flex align-items-center" css={formLabel}>
				Location
				<Button
					className="my-2"
					variant="info"
					onClick={addLocation}
					css={css`
						font-size: 1.2rem;
					`}
				>
					Add Location
				</Button>
			</Form.Label>
			<div
				className="height-500 cw-90 mb-4"
				css={StyledScrollBarContainerCSS}
			>
				<Accordion>
					{locationIndexes.map((index, idx) => {
						const fieldName = `addresses[${idx}]`;
						const locationName = `${fieldName}.location`;
						const latName = `${fieldName}.latitude`;
						const longName = `${fieldName}.longitude`;
						const watchLocation = watch(locationName);
						return (
							<GPSLocationCard
								key={index}
								index={index}
								fieldName={fieldName}
								locationName={locationName}
								latName={latName}
								longName={longName}
								watchLocation={watchLocation}
								register={register}
								getValues={getValues}
								setValue={setValue}
								errors={errors}
								locationIndexes={locationIndexes}
								setLocation={setLocation}
								removeLocation={() => {
									removeLocation(index)
								}}
								location={room?.addresses[index]?.location ?  {
									lat: room?.addresses[index]?.latitude,
									long: room?.addresses[index]?.longitude,
									isVerified: true,
								} : {...location} }
								address={room?.addresses[index]?.location}
								currentIndex={index}
							/>
						);
					})}
				</Accordion>
			</div>
		</Fragment>
	);
}
