import { css } from 'twin.macro';
import React, { useState, Fragment, useRef, useMemo } from 'react';
import { FacebookSelector, FacebookCounter } from '@charkour/react-reactions';
import { Dropdown } from 'react-bootstrap';

//Helpers
import { SanitizeHTML, Permission, fetchWrapper, checkPermission } from '../../../_helpers';
import { useOutsideClickListener } from '../../../_helpers/hooks/useOutsideClickListener';

//components
import MessageTime from '../Common/MessageTime';
import FileMessage from '../Common/FileMessage';
import { GoogleMapPopUP } from '../Common/GoogleMapPopUP';
import ReplyFile from '../Common/ReplyFile';
import SeenByDiv from './SeenByDiv';
import AvatarUserPanel from '../Common/AvatarUserPanel';
import PermissionMessage from './PermissionMessage';

//redux

//Styles
import { msgContainer } from './styles/MessageStyles';

// icons
import { BsThreeDotsVertical } from 'react-icons/bs';
import { HiOutlineEmojiHappy } from 'react-icons/hi';
import ImagesMessage from '../Common/Messages/ImagesMessage';
import FilesMessage from '../Common/Messages/FilesMessage';

const ReceiverMessage = ({
	rId,
	teamData,
	setReply,
	position,
	directUser,
	refs,
	roomType,
	handleClick,
	handleMessageClick,
	m,
	currUser,
	userRole,
	handleDeleteMessage,
	showMessageReactions,
	subChannelId,
	subChannelType,
	roomData,
}) => {
	const { id, file, images, files, type, userName, dateAndTime, user, seenBy, isEdited, reaction, yourReaction } = m;
	const canDownload = useMemo(() => {
		if (currUser?.role === 'admin') {
			return true;
		} else if (['owner', 'teamManager'].includes(teamData?.role)) {
			return true;
		} else {
			return false;
		}
	}, [currUser, teamData]);
	let messageFile;
	if (file) {
		messageFile = 'file';
	} else if (images && images?.length) {
		messageFile = 'images';
	} else if (files && files?.length) {
		messageFile = 'files';
	}
	const [showActions, setShowActions] = useState(false);
	const mData = m.data;
	const [showReactions, setShowReactions] = useState(false);
	const emojiWrapperRef = useRef(null);
	useOutsideClickListener(emojiWrapperRef, () => {
		setShowReactions(false);
	});

	const [reactionCounter, setReactionCounter] = useState(() => {
		if (!reaction) return [];

		let newReaction = reaction?.map((react) => ({
			emoji: react,
		}));
		let myReactionIndex = newReaction.findIndex((reaction) => reaction?.emoji === yourReaction);
		if (myReactionIndex >= 0) {
			let tempReaction = [...newReaction];
			tempReaction.splice(myReactionIndex, 1, { emoji: yourReaction, by: currUser.name });
			return tempReaction;
		} else {
			return newReaction;
		}
	});

	const setReplyMessage = (reply, name) => {
		const teampReply = { ...reply };
		if (teampReply.file || teampReply?.files?.length || teampReply?.images?.length) {
			teampReply.name = name;
			try {
				teampReply.data = teampReply.file || teampReply?.images[0] || teampReply?.files[0];
			} catch (err) {
				teampReply.data = 'shared Files';
			}
			setReply(teampReply);
		} else {
			const { text } = JSON.parse(teampReply.data);
			if (text) {
				teampReply.data = text;
				teampReply.name = name;
				setReply(teampReply);
			}
		}
	};

	let displayMessage, lat, lng, displayReply, displayReplyId, displayReplyUser, replyType;
	try {
		const { text, reply, location } = JSON.parse(mData);
		displayMessage = text;
		if (reply) {
			displayReplyId = reply.id;
			displayReply = reply.reply;
			displayReplyUser = reply.replyUser;
			replyType = reply.type;
		}

		if (location) {
			lat = location.latitude;
			lng = location.longitude;
		}
	} catch (e) {
		displayMessage = mData;
		lat = null;
		lng = null;
	}

	const handleReaction = async (emoji) => {
		setShowReactions(false);

		// setting the reaction counter
		let myReactionIndex = reactionCounter.findIndex((reaction) => reaction?.by === currUser.name);
		if (subChannelId) {
			if (myReactionIndex >= 0) {
				if (reactionCounter[myReactionIndex].emoji === emoji) {
					// remove reaction
					let newReactionCounter = [...reactionCounter];
					newReactionCounter.splice(myReactionIndex, 1);
					setReactionCounter(newReactionCounter);
					await fetchWrapper.put(`subchannels/${subChannelId}/${id}/reactions?remove=true`, {
						reaction: emoji,
					});
				} else {
					let newEmoji = {
						emoji: emoji,
						by: currUser.name,
					};
					let newReactionCounter = [...reactionCounter];
					newReactionCounter.splice(myReactionIndex, 1, newEmoji);
					setReactionCounter(newReactionCounter);
					await fetchWrapper.put(`subchannels/${subChannelId}/${id}/reactions`, { reaction: emoji });
				}
			} else {
				setReactionCounter([
					...reactionCounter,
					{
						emoji: emoji,
						by: currUser.name,
					},
				]);
				await fetchWrapper.put(`subchannels/${subChannelId}/${id}/reactions`, { reaction: emoji });
			}
		}else{
			if (myReactionIndex >= 0) {
				if (reactionCounter[myReactionIndex].emoji === emoji) {
					// remove reaction
					let newReactionCounter = [...reactionCounter];
					newReactionCounter.splice(myReactionIndex, 1);
					setReactionCounter(newReactionCounter);
					await fetchWrapper.put(`chatrooms/${rId}/${id}/reactions?remove=true`, { reaction: emoji });
				} else {
					let newEmoji = {
						emoji: emoji,
						by: currUser.name,
					};
					let newReactionCounter = [...reactionCounter];
					newReactionCounter.splice(myReactionIndex, 1, newEmoji);
					setReactionCounter(newReactionCounter);
					await fetchWrapper.put(`chatrooms/${rId}/${id}/reactions`, { reaction: emoji });
				}
			} else {
				setReactionCounter([
					...reactionCounter,
					{
						emoji: emoji,
						by: currUser.name,
					},
				]);
				await fetchWrapper.put(`chatrooms/${rId}/${id}/reactions`, { reaction: emoji });
			}
		}

	};

	return (
		<Fragment key={id}>
			<div css={msgContainer}>
				<div className="messageContainer justifyStart">
					<div
						className="msg p-2 mt-2 d-flex flex-row align-items-start  "
						ref={refs[id]}
						css={css`
							position: relative;
							.dropper {
								visibility: hidden;
								opacity: 0;
								display: flex;
								justify-content: center;
								align-items: center;
							}
							:hover .dropper {
								visibility: visible;
								opacity: 1;
							}
						`}
					>
						<div className="px-2 pt-4"> {<AvatarUserPanel item={directUser || user} />}</div>
						<div className="px-2">
							<strong className="p-0 m-0 re-rp text-muted small text-capitalize">
								{(user && user.name) || userName}
							</strong>
							<div
								className="d-flex  pointer"
								onClick={() => {
									handleMessageClick();
									setShowActions(!showActions);
								}}
								css={css`
									.received-msg {
										padding: 1rem;
									}
								`}
							>
								{type === 'message' ? (
									<div className="received-msg">
										{isEdited ? (
											<div
												css={css`
													font-size: 1.1rem;
													display: flex;
													padding: 0 5px !important;
													font-style: italic;
													justify-content: flex-start;
												`}
											>
												{' '}
												Edited
											</div>
										) : null}
										{displayReply && (
											<div
												className="received-reply-msg"
												onClick={() => handleClick(displayReplyId)}
											>
												{displayReplyUser}
												{replyType === 'message' ? (
													<div className="d-flex p-0 m-0 small">
														<SanitizeHTML html={displayReply} />
													</div>
												) : (
													<div className="d-flex p-0 m-0 small">
														<ReplyFile file={displayReply} />
													</div>
												)}
											</div>
										)}

										<div className="text-justify">
											{displayMessage === '' && lat && lng ? (
												<span>
													shared a location: <GoogleMapPopUP lat={lat} lng={lng} />
												</span>
											) : (
												<SanitizeHTML html={displayMessage} />
											)}
										</div>
									</div>
								) : (
									<div className="received-msg m-0 small text-justify ">
										{messageFile === 'file' ? (
											<FileMessage file={file} displayMessage={displayMessage} documentSettings={canDownload}/>
										) : messageFile === 'images' ? (
											<ImagesMessage
												images={images}
												displayMessage={displayMessage}
												from="other-user"
												css={css`
													color: white !important;
												`}
												teamDetails={canDownload}
											/>
										) : messageFile === 'files' ? (
											<FilesMessage
												files={files}
												displayMessage={displayMessage}
												from="other-user"
												css={css`
													color: white !important;
												`}
												canDownload={canDownload}
											/>
										) : null}
									</div>
								)}
								{roomType ? (
									<div
										css={css`
											position: relative;
											padding-left: 0.5rem;
											&.show {
												visibility: visible !important;
												opacity: 1;
											}
										`}
										className={`dropper ${showReactions ? 'show' : ''}`}
									>
										<div
											css={css`
												position: relative;
											`}
										>
											<div ref={emojiWrapperRef}>
												<HiOutlineEmojiHappy
													css={css`
														cursor: pointer;
													`}
													color="#50aeb0"
													fontSize={35}
													onClick={() => setShowReactions(!showReactions)}
												/>
												{showReactions ? (
													<div
														css={css`
															position: absolute;
															z-index: 99999;
															top: -265%;
															left: 0;
														`}
													>
														<FacebookSelector onSelect={handleReaction} />
													</div>
												) : null}
											</div>
										</div>
									</div>
								) : null}

							{subChannelType ? (
								<div
									css={css`
											position: relative;
											padding-left: 0.5rem;
											&.show {
												visibility: visible !important;
												opacity: 1;
											}
										`}
									className={`dropper ${showReactions ? 'show' : ''}`}
								>
									<div
										css={css`
											position: relative;
										`}
									>
										<div ref={emojiWrapperRef}>
											<HiOutlineEmojiHappy
												css={css`
													cursor: pointer;
												`}
												color="#50aeb0"
												fontSize={30}
												onClick={() => setShowReactions(!showReactions)}
											/>
											{showReactions ? (
												<div
													css={css`
													position: absolute;
													z-index: 99999;
													top: -265%;
													left: 0;
												`}
												>
													<FacebookSelector onSelect={handleReaction} />
												</div>
											) : null}
										</div>
									</div>
								</div>
							) : null}

								<Dropdown
									className="dropper"
									css={css`
										div {
											font-size: 1.3rem;
										}
										&.show {
											visibility: visible !important;
											opacity: 1;
										}
									`}
								>
									<Dropdown.Toggle id="dropdown-basic" className="dropdown p-1">
										<div className="dropcss-receiver">
											<BsThreeDotsVertical color="#fff" fontSize={20} />
										</div>
									</Dropdown.Toggle>
									<Dropdown.Menu className="dropdown-menu">
										<Dropdown.Item onClick={() => setReplyMessage(m, user || { name: userName })}>
											<span>Reply</span>
										</Dropdown.Item>
										{roomType && roomType === 'private' && (
											// type !== 'file' &&
											<Permission
												role={currUser.role}
												perform="edit:messagePermission"
												yes={() => (
													<PermissionMessage message={m} rId={rId} userRole={userRole} teamData={teamData} currUser={currUser} />
												)}
												no={() => {
													let userTeamRole = teamData?.role;
													let isTeamRoleAuthorized = checkPermission(
														userTeamRole,
														'edit:messagePermission'
													);
													let userChannel = currUser.roomList
														.filter((room) => room.room)
														.find((room) => room.room.id === rId);
													let userChannelRole = (userChannel || {}).role;

													let isChannelRoleAuthorized = false;
													if (userChannelRole) {
														isChannelRoleAuthorized = checkPermission(
															userChannelRole,
															'edit:messagePermission'
														);
													}
													let isEditPermissionAllowed =
														isTeamRoleAuthorized || isChannelRoleAuthorized;

													if (isEditPermissionAllowed) {
														return (
															<PermissionMessage
																message={m}
																rId={rId}
																userRole={userRole}
																teamData={teamData}
																currUser={currUser}
															/>
														);
													}
													return null;
												}}
											/>
										)}

										{subChannelType && subChannelType === 'private' && (
											<Permission
												role={currUser.role}
												perform="edit:messagePermission"
												yes={() => (
													<PermissionMessage
														message={m}
														subChannelId={subChannelId}
														userRole={userRole}
													/>
												)}
												no={() => {
													let userRoomRole = roomData?.role;
													let isRoomRoleAuthorized = checkPermission(
														userRoomRole,
														'edit:messagePermission'
													);
													let userSubChannel = currUser.subChannelList
														.filter((subChannel) => subChannel.subChannel)
														.find(
															(subChannel) => subChannel.subChannel.id === subChannelId
														);
													let userSubChannelRole = (userSubChannel || {}).role;

													let isSubChannelRoleAuthorized = false;
													if (userSubChannelRole) {
														isSubChannelRoleAuthorized = checkPermission(
															userRoomRole,
															'edit:messagePermission'
														);
													}
													let isEditPermissionAllowed =
														isRoomRoleAuthorized || isSubChannelRoleAuthorized;

													if (isEditPermissionAllowed) {
														return (
															<PermissionMessage
																message={m}
																subChannelId={subChannelId}
																userRole={userRole}
															/>
														);
													}
													return null;
												}}
											/>
										)}

										{subChannelId ? (
												<Permission
													role={currUser?.role}
													perform="delete:message"
													yes={() => (
														<Dropdown.Item onClick={() => handleDeleteMessage(m.id)}>
															<span>Delete</span>
														</Dropdown.Item>
													)}
													no={() => {
														let userRoomRole = roomData?.role;
														let isRoomRoleAuthorized = checkPermission(
															userRoomRole,
															'delete:message'
														);
														let userSubChannel = currUser.subChannelList
															.filter((subChannel) => subChannel.subChannel)
															.find((subChannel) => subChannel.subChannel.id === rId);
														let userSubChannelRole = (userSubChannel || {}).role;

														let isSubChannelRoleAuthorized = false;
														if (userSubChannelRole) {
															isSubChannelRoleAuthorized = checkPermission(
																userSubChannelRole,
																'delete:message'
															);
														}
														let isDeleteAllowed =
															isRoomRoleAuthorized || isSubChannelRoleAuthorized;

														if (isDeleteAllowed) {
															return (
																<Dropdown.Item onClick={() => handleDeleteMessage(m.id)}>
																	<span>Delete</span>
																</Dropdown.Item>
															);
														}
														return null;
													}}
												/>
											) : (
												<Permission
													role={currUser?.role}
													perform="delete:message"
													yes={() => (
														<Dropdown.Item onClick={() => handleDeleteMessage(m.id)}>
															<span>Delete</span>
														</Dropdown.Item>
													)}
													no={() => {
														let userTeamRole = teamData?.role;
														let isTeamRoleAuthorized = checkPermission(
															userTeamRole,
															'delete:message'
														);
														let userChannel = currUser.roomList
															.filter((room) => room.room)
															.find((room) => room.room.id === rId);
														let userChannelRole = (userChannel || {}).role;

														let isChannelRoleAuthorized = false;
														if (userChannelRole) {
															isChannelRoleAuthorized = checkPermission(
																userChannelRole,
																'delete:message'
															);
														}
														let isDeleteAllowed =
															isTeamRoleAuthorized || isChannelRoleAuthorized;
														if (isDeleteAllowed) {
															return <Dropdown.Item onClick={() => handleDeleteMessage(m.id)}>
															<span>Delete</span>
														</Dropdown.Item>;
														}
														return null ;
													}}
												/>
											)
										}
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div
								css={css`
									visibility: ${reactionCounter.length ? 'visible' : 'hidden'};
									display: flex;
									justify-content: flex-start;
									z-index: 10;
									> div {
										align-items: center;
										background: white;
										border: 1px solid #d8d8d8;
										padding: 0 0.5rem;
										border-radius: 50px;
										bottom: -1.2rem;
										position: absolute;
										width: max-content !important;
									}
								`}
								onClick={() => showMessageReactions(id)}
							>
								<FacebookCounter user={currUser?.name} counters={reactionCounter} />
							</div>
							{showActions && (
								<div className="d-flex py-2 lowerDetails">
									<span className="px-2 text-right text-muted">
										{lat && lng && <GoogleMapPopUP lat={lat} lng={lng} />}
									</span>

									<span className="px-2 text-right text-muted text-uppercase">
										<MessageTime dateAndTime={dateAndTime} />
									</span>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			{seenBy?.length > 0 && <SeenByDiv seenBy={seenBy} messageType="other" />}
		</Fragment>
	);
};
export default ReceiverMessage;
