import { css } from 'twin.macro';

const element = css`
	background-color:#E0F0EF;
	padding:1rem;
	list-style:none;
	color:#506690;
	cursor:pointer;
	border-bottom:1px solid lightgray;

	&:hover {
		background-color:#C3E4E2;
	}
`;

const FormPickerItem = (props) => (
	<li
		onClick={props.onClick}
		css={element}
	>
		<span className="pr-2">{props.data.icon}</span>
		{props.data.name}
	</li>
);
export default FormPickerItem;
