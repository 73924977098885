import { css } from 'twin.macro';
import { useMemo } from 'react';
import { Form } from 'react-bootstrap';
import Linkify from 'react-linkify';

export const marginColor = css`
	color: red;
	margin: 0 0.2rem;
`;

const Checkbox = ({ error, data, isPreview, elementRef }) => {
	const value = useMemo(() => {
		try {
			return JSON.parse(data.value);
		} catch (error) {
			return null;
		}
	}, [data.value]);

	return (
		<Form.Group controlId="formGridEmail">
			<Form.Label>
				<h5
					css={css`
						word-break: break-all;
					`}
				>
					{data.fieldName}
					<span css={marginColor}>{(data.hasOwnProperty('required') ? data.required : true) && '*'}</span>
				</h5>{' '}
			</Form.Label>

			{data.instruction && (
				<pre className="small text-muted warpIt preStyles">
					<Linkify
						componentDecorator={(decoratedHref, decoratedText, key) => (
							<a target="blank" href={decoratedHref} key={key}>
								{decoratedText}
							</a>
						)}
					>
						{data.instruction}
					</Linkify>
				</pre>
			)}
			{data.data.length &&
				data.data.map((option) => (
					<Form.Check
						key={option.value}
						type="checkbox"
						label={option.text}
						name={!isPreview ? `Checkbox-${data._id}` : 'Checkbox'}
						id={option.value}
						value={option.value}
						defaultChecked={value && value.includes(option.value)}
						disabled={isPreview || data.value || data.isDisabled}
						ref={
							elementRef &&
							elementRef({
								required: data.hasOwnProperty('required') ? data.required : true,
							})
						}
					/>
				))}
			<Form.Text className="text-danger">
				{error && error.type === 'required' && 'This field is required'}
			</Form.Text>
		</Form.Group>
	);
};

export default Checkbox;
