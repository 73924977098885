import { css } from 'twin.macro';
import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

//components
import SideBar from '../Layouts/SideBar/SideBar';
import ChatList from './ChatList';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

import PrivateChat from './PrivateChat';

//redux
import { connect } from 'react-redux';
import {
	getDirectMessageHistory,
	clearMessages,
	clearSearchMessages,
	getRecentChats,
	setSocketRecentChats,
	startMessageLoading,
	stopMessageLoading,
} from '../../../_redux/actions/ActionMessage';
import { getOtherUserData } from '../../../_redux/actions/ActionUser';
import MessageListener from '../Common/MessageListener';
import { layout } from './styles/ChatContainerStyles';
import { BsFillCaretLeftFill } from 'react-icons/bs';

const ChatContainer = ({
	getDirectMessageHistory,
	startMessageLoading,
	stopMessageLoading,
	clearMessages,
	clearSearchMessages,
	getRecentChats,
	history,
	getOtherUserData,
	match,
	recentChats,
	messages,
}) => {
	const [user, setUser] = useState(null);
	const [showChat, setShowChat] = useState(true);
	const [hideChatList, setHideChatList] = useState(false);

	const toggleChat = (bool) => {
		setShowChat(bool);
	};
	const [mobileSidebar, setMobileSidebar] = useState(false);

	const userId = match.params.userId;

	const fetchData = useCallback(
		async (userId) => {
			let keys = Object.keys(messages);
			startMessageLoading();
			const user = await getOtherUserData(userId);
			if (user) {
				if (!keys.includes(userId)) {
					getDirectMessageHistory(userId, 1);
				} else {
					stopMessageLoading();
				}
				setUser(user);
			}
		},
		[getDirectMessageHistory, getOtherUserData, messages]
	);

	useEffect(() => {
		if (userId) {
			fetchData(userId);
		}
	}, [userId, fetchData]);

	useEffect(() => {
		getRecentChats();
		clearSearchMessages();
	}, [getRecentChats, clearSearchMessages]);

	return (
		<div css={layout}>
			<MessageListener />
			<SwitchTransition>
				<CSSTransition key={mobileSidebar} timeout={500} classNames={'sidebar-ani-'}>
					<SideBar mobileSidebar={mobileSidebar} setMobileSidebar={setMobileSidebar} />
				</CSSTransition>
			</SwitchTransition>
			<div className={`${showChat ? `wrapper-chat` : `wrapper-list`} wrapper`}>
				<div className={`chatList ${hideChatList ? 'hide-chatList' : ''}`}>
					<button
						className={`hiddenBtn ${hideChatList ? '' : 'partialVisibleBtn'}`}
						css={css`
							position: absolute;
							right: ${hideChatList ? '-22%' : '-4%'};
							top: 32px;
							z-index: 11;
							width: 40px;
							height: 40px;
							background: #e0f0ef;
							color: #28637e;
							outline: none;
							border: none;
							display: flex;
							overflow: hidden;
							justify-content: center;
							align-items: center;
							border-radius: 1000px;
							transition: right 150ms;
							.caretIcon {
								transition: transform 150ms;
								&.turnRight {
									transform: rotateY(180deg);
								}
								&.turnLeft {
									transform: rotateY(0deg);
								}
							}
							@media (max-width: 768px) {
								display: none;
							}
						`}
						onClick={() => setHideChatList(!hideChatList)}
					>
						<BsFillCaretLeftFill className={`caretIcon ${hideChatList ? 'turnRight' : 'turnLeft'}`} />
					</button>
					<ChatList
						userId={userId}
						mobileSidebar={mobileSidebar}
						setMobileSidebar={setMobileSidebar}
						toggleChat={toggleChat}
					/>
				</div>
				<div className={`chat ${hideChatList ? 'hide-chatList' : ''}`}>
					{user ? (
						<PrivateChat user={user} userId={userId} userName={user.name} toggleChat={toggleChat} showChatInput={user.status !== 'archive'} />
					) : (
						<h1 className="h4 h-100 d-flex justify-content-center align-items-center ">
							click on user to chat
						</h1>
					)}
				</div>
			</div>
		</div>
	);
};
const mapStateToProps = (state) => ({
	CurrUser: state.user.user,
	recentChats: state.chats.recentChats,
	messages: state.messages.messages,
});

ChatContainer.propTypes = {
	getDirectMessageHistory: PropTypes.func.isRequired,
	clearMessages: PropTypes.func.isRequired,
	getRecentChats: PropTypes.func.isRequired,
	setSocketRecentChats: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
	getDirectMessageHistory,
	clearMessages,
	clearSearchMessages,
	getRecentChats,
	setSocketRecentChats,
	startMessageLoading,
	stopMessageLoading,
	getOtherUserData,
})(ChatContainer);
