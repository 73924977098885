import React from 'react';
import { connect } from 'react-redux';
import { clearInvitationForConference, startConference, setConferenceData } from '../../_redux/actions/ActionConference';
import AvatarUserPanel from '../Features/Common/AvatarUserPanel';
import { FiPhoneCall } from 'react-icons/fi';
import { MdCallEnd } from 'react-icons/md';
import { StyledConferenceInvitation } from './Styles/StyledConferenceInvitation';

// assets
import PhoneAudio from '../../assets/audio/ringtone.ogg';
import { useEffect } from 'react';

function ConferenceInvitation({ conferenceInviation, clearInvitationForConference, startConference, setConferenceData }) {
	// const RingAudio = new Audio(PhoneAudio);

	// RingAudio.play();

	// RingAudio.addEventListener('ended', () => RingAudio.play());
	// useEffect(() => {
	// 	return () => {
	// 		RingAudio.removeEventListener('ended', () => RingAudio.pause());
	// 	};
	// });
	const handleDecline = () => {
		// RingAudio.pause();
		clearInvitationForConference();
	};
	const handlePickUp = async () => {
		try {
			// RingAudio.pause();
			const roomId = conferenceInviation?.conferenceInfo?.room?._id;
			const conferenceId = conferenceInviation?.conferenceId;
			setConferenceData(roomId, conferenceId, conferenceInviation?.conferenceInfo?.room?.team);
			startConference(roomId);
			clearInvitationForConference();
		} catch (e) { }
	};
	return (
		<React.Fragment>
			<StyledConferenceInvitation>
				<AvatarUserPanel large={true} item={conferenceInviation?.user} />
				<div className="InvitationText">
					<span className="invitee">{conferenceInviation?.user?.name}</span> is calling you in
					<span className="invitee">{conferenceInviation?.conferenceInfo?.room?.title}</span>
				</div>
				<div className="ControlsContainer">
					<div className="button declineBtn" variant="secondary" onClick={handleDecline}>
						<MdCallEnd fontSize="28" />
					</div>
					<div className="button pickUpBtn" variant="primary" onClick={handlePickUp}>
						<FiPhoneCall fontSize="24" />
					</div>
				</div>
			</StyledConferenceInvitation>
		</React.Fragment>
	);
}

export default connect(null, {
	clearInvitationForConference,
	startConference,
	setConferenceData
})(ConferenceInvitation);
