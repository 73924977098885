import { css } from 'twin.macro';
import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { fetchWrapper, getInitials, getRandomColor } from '../../../_helpers';
import { Tabs, Tab, Image } from 'react-bootstrap';
// import { Image } from 'cloudinary-react';

//assets
import HahaIcon from '../../../assets/icons/emotions/haha.png';
import LikeIcon from '../../../assets/icons/emotions/like.png';
import LoveIcon from '../../../assets/icons/emotions/love.png';
import AngryIcon from '../../../assets/icons/emotions/angry.png';
import SadIcon from '../../../assets/icons/emotions/sad.png';
import WowIcon from '../../../assets/icons/emotions/wow.png';
import { VscLoading } from 'react-icons/vsc';

const HeadingTabCSS = css`
	display: flex;
	justify-content: space-around;
	align-items: center;
	min-width: 40px;
	img {
		width: 20px;
		height: 20px;
		object-fit: contain;
	}
`;

const MessageReactionRowCSS = css`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1.5rem 2rem;
	:hover {
		background: #efefef;
	}
	.ReactorsDiv {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.reactorsIcon {
		width: 50px;
		height: 50px;
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 10000px;
		color: white;
		font-size: 20px;
	}
	.reactorName {
		margin-left: 1rem;
	}
	.emoji-img {
		width: 30px;
		height: 30px;
		object-fit: contain;
	}
`;

const tabCSS = css`
	border: 1px solid #dee2e6;
	border-top: none;
	min-height: 300px;
	overflow-y: auto;
`;

const MessageReactionRow = ({ reactor }) => {
	const userList = useMemo(() => {
		reactor.initials = getInitials(reactor.name);
		reactor.color = getRandomColor(reactor.id || reactor._id);
		return reactor;
	}, [reactor]);
	return (
		<div css={MessageReactionRowCSS}>
			{console.log(reactor.avatar, 'reactor.avatar')}
			<div className="ReactorsDiv">
				<div className="ReactorIconDiv">
					{reactor && reactor.avatar ? (
						<Image
							// cloudName={reactor?.avatar?.split('/')[3]}
							// loading="lazy"
							// publicId={reactor.avatar.split('/')[reactor.avatar.split('/').length - 1]}
							src={reactor.avatar}
							className="rounded-circle reactorsIcon"
							width="50"
							style={{ objectFit: 'contain' }}
						/>
					) : (
						<div className="replacementImg avatar reactorsIcon" style={{ backgroundColor: userList.color }}>
							<span className="initials">{userList.initials}</span>
						</div>
					)}
				</div>
				<div className="reactorName">{reactor.name}</div>
			</div>
			<div>
				<img src={reactor.reactionIcon} className="emoji-img" alt="reaction" />
			</div>
		</div>
	);
};

const MessageReactions = ({ roomId, subChannelId, messageId }) => {
	const [allReactions, setAllReactions] = useState([]);
	const [hahaReactors, setHahaReactors] = useState([]);
	const [loveReactors, setLoveReactors] = useState([]);
	const [sadReactors, setSadReactors] = useState([]);
	const [likeReactors, setLikeReactors] = useState([]);
	const [angryReactors, setAngryReactors] = useState([]);
	const [wowReactors, setWowReactors] = useState([]);

	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchReactions = async () => {
			setIsLoading(true);
			let response;
			if (subChannelId) {
				response = await fetchWrapper.get(`subchannels/${subChannelId}/${messageId}/reactions`);
			} else {
				response = await fetchWrapper.get(`chatrooms/${roomId}/${messageId}/reactions`);
			}
			response.forEach((reactions) => {
				if (reactions.reaction === 'haha') {
					let tempHahaReactions = reactions.users.map((user) => {
						let tempUser = Object.assign({}, user);
						tempUser.reactionIcon = HahaIcon;
						return tempUser;
					});
					setHahaReactors(tempHahaReactions);
				} else if (reactions.reaction === 'love') {
					let tempLoveReactions = reactions.users.map((user) => {
						let tempUser = Object.assign({}, user);
						tempUser.reactionIcon = LoveIcon;
						return tempUser;
					});
					setLoveReactors(tempLoveReactions);
				} else if (reactions.reaction === 'sad') {
					let tempSadReactions = reactions.users.map((user) => {
						let tempUser = Object.assign({}, user);
						tempUser.reactionIcon = SadIcon;
						return tempUser;
					});
					setSadReactors(tempSadReactions);
				} else if (reactions.reaction === 'like') {
					let tempLikeReactions = reactions.users.map((user) => {
						let tempUser = Object.assign({}, user);
						tempUser.reactionIcon = LikeIcon;
						return tempUser;
					});
					setLikeReactors(tempLikeReactions);
				} else if (reactions.reaction === 'angry') {
					let tempAngryReactions = reactions.users.map((user) => {
						let tempUser = Object.assign({}, user);
						tempUser.reactionIcon = AngryIcon;
						return tempUser;
					});
					setAngryReactors(tempAngryReactions);
				} else if (reactions.reaction === 'wow') {
					let tempWowReactions = reactions.users.map((user) => {
						let tempUser = Object.assign({}, user);
						tempUser.reactionIcon = WowIcon;
						return tempUser;
					});
					setWowReactors(tempWowReactions);
				}
			});
			setIsLoading(false);
		};
		if ((roomId || subChannelId) && messageId) {
			fetchReactions();
		}
	}, [roomId, messageId, subChannelId]);

	useEffect(() => {
		let allReactions;
		allReactions = [
			...hahaReactors,
			...loveReactors,
			...sadReactors,
			...likeReactors,
			...angryReactors,
			...wowReactors,
		];
		setAllReactions(allReactions);
	}, [angryReactors, hahaReactors, likeReactors, loveReactors, sadReactors, wowReactors]);

	return (
		<Fragment>
			{isLoading ? (
				<div
					css={css`
						height: 300px;
						justify-content: center;
						align-items: center;
						display: flex;
					`}
				>
					<VscLoading className="spin" fontSize="28" />
				</div>
			) : (
				<Tabs defaultActiveKey="all" id="noanim-tab-example">
					<Tab eventKey="all" title={`All ${allReactions.length}`}>
						<div css={tabCSS}>
							{allReactions.map((reactor) => (
								<MessageReactionRow key={reactor._id} reactor={reactor} />
							))}
						</div>
					</Tab>
					{likeReactors.length > 0 ? (
						<Tab
							eventKey="like"
							title={
								<span css={HeadingTabCSS}>
									<img src={LikeIcon} alt="like" />
									<span>{likeReactors.length}</span>
								</span>
							}
						>
							<div css={tabCSS}>
								{likeReactors.map((reactor) => (
									<MessageReactionRow key={reactor._id} reactor={reactor} />
								))}
							</div>
						</Tab>
					) : null}
					{loveReactors.length > 0 ? (
						<Tab
							eventKey="love"
							title={
								<span css={HeadingTabCSS}>
									<img src={LoveIcon} alt="love" />
									<span>{loveReactors.length}</span>
								</span>
							}
						>
							<div css={tabCSS}>
								{loveReactors.map((reactor) => (
									<MessageReactionRow key={reactor._id} reactor={reactor} />
								))}
							</div>
						</Tab>
					) : null}
					{hahaReactors.length > 0 ? (
						<Tab
							eventKey="haha"
							title={
								<span css={HeadingTabCSS}>
									<img src={HahaIcon} alt="haha" />
									<span>{hahaReactors.length}</span>
								</span>
							}
						>
							<div css={tabCSS}>
								{hahaReactors.map((reactor) => (
									<MessageReactionRow key={reactor._id} reactor={reactor} />
								))}
							</div>
						</Tab>
					) : null}
					{wowReactors.length > 0 ? (
						<Tab
							eventKey="wow"
							title={
								<span css={HeadingTabCSS}>
									<img src={WowIcon} alt="wow" />
									<span>{wowReactors.length}</span>
								</span>
							}
						>
							<div css={tabCSS}>
								{wowReactors.map((reactor) => (
									<MessageReactionRow key={reactor._id} reactor={reactor} />
								))}
							</div>
						</Tab>
					) : null}
					{sadReactors.length > 0 ? (
						<Tab
							eventKey="sad"
							title={
								<span css={HeadingTabCSS}>
									<img src={SadIcon} alt="sad" />
									<span>{sadReactors.length}</span>
								</span>
							}
						>
							<div css={tabCSS}>
								{sadReactors.map((reactor) => (
									<MessageReactionRow key={reactor._id} reactor={reactor} />
								))}
							</div>
						</Tab>
					) : null}
					{angryReactors.length > 0 ? (
						<Tab
							eventKey="angry"
							title={
								<span css={HeadingTabCSS}>
									<img src={AngryIcon} alt="angry" />
									<span>{angryReactors.length}</span>
								</span>
							}
						>
							<div css={tabCSS}>
								{angryReactors.map((reactor) => (
									<MessageReactionRow key={reactor._id} reactor={reactor} />
								))}
							</div>
						</Tab>
					) : null}
				</Tabs>
			)}
		</Fragment>
	);
};
export default MessageReactions;
