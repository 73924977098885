import React, { useState, useEffect } from 'react';
import { css } from 'twin.macro';
import { Tab, Tabs } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { fetchWrapper } from '../../../_helpers/fetchWrapper';
import { getTeamIdFromURL, getChannelIdFromSettingsURL } from '../../../_helpers';
import { ContentContainerStyles, EVVContainerStyles } from './styles/EVVContainerStyles';
import { SET_EVVSTATE } from '../../../_redux/actions/ActionTypes';
import PayAllocation from './Payroll/PayAllocation';
import Payrolls from './Payroll/Payrolls';
import { getRole } from '../../../_helpers/roles/get-roles';
import Reports from './Payroll/Reports';
import PTO from './PTO';

const UserPayrollContainer = ({ channelDetails, currUser, teams }) => {
    const teamId = getTeamIdFromURL();
    const channelIdFromUrl = getChannelIdFromSettingsURL();
    const [selectedPage, setSelectedPage] = useState('PTO');
    const [canViewPayroll, setCanViewPayroll] = useState(false);
    const dispatch = useDispatch();

    const Content =
        {
            PayAllocation,
            Payrolls,
            Reports,
            PTO,
        }[selectedPage] || (() => <div>No Content</div>);

    useEffect(() => {
        const getEvvStateData = async () => {
            try {
                const response = await fetchWrapper.get(`/evv/state?${teamId ? `&team=${teamId}` : ''}`);
                if (response?.state) {
                    dispatch({
                        type: SET_EVVSTATE,
                        payload: response?.state,
                    });
                }
            } catch (err) {
                console.log(err);
            }
        };

        getEvvStateData();
    }, [dispatch]);

    useEffect(() => {
        let team = teams.filter((team) => team.id === teamId);
        let role = getRole(currUser, team[0]);
        if (role === 'admin' || role === 'teamManager' || role === 'owner') {
            console.log('can view');
            setCanViewPayroll(true);
        }
    }, [currUser, teams]);

    if (!canViewPayroll && channelIdFromUrl) {
        return null;
    }
    return (
        <EVVContainerStyles>
            <h1 className="mb-4">Payroll</h1>
            <div
                className="d-flex flex-column"
                css={css`
                    min-height: 800px;
                `}
            >
                <Tabs
                    id="controlled-tab-example"
                    activeKey={selectedPage}
                    onSelect={(selectedKey) => setSelectedPage(selectedKey)}
                    className="mb-0"
                >
                    <Tab eventKey="PTO" title="PTO"></Tab>
                    <Tab eventKey="PayAllocation" title="Pay Allocation"></Tab>
                    <Tab eventKey="Payrolls" title="Payrolls"></Tab>
                    <Tab eventKey="Reports" title="Reports"></Tab>
                </Tabs>
                <ContentContainerStyles>
                    <Content teamId={teamId} channelDetails={channelDetails} />
                </ContentContainerStyles>
            </div>
        </EVVContainerStyles>
    );
};

const mapStateToProps = (state) => ({
    teams: state.teams.teams,
    currUser: state.user.user,
});

export default connect(mapStateToProps, {})(UserPayrollContainer);
