import { css } from 'twin.macro';

export const topSection = css`
	width: 100%;
	background-color: #f0f0f0;
	position: sticky;
	top: 0;
	z-index: 1;
	height: auto;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
`;

export const listWrapper = css` 
	position: relative;
	display: flex;
	flex-direction: column;
	background-color: #f2f8f7;
	height: 100%;
	border: 1px solid #dddddd;
	margin-left: 8rem;
	.title{
		background-color: #50aeb0;
		color:white;
		font-size:20px;
		font-weight: bold;
		padding:10px;
	}

	@media only screen and (max-width: 767px) {
		margin: 0rem 0rem 0rem 0;
	}
`;

export const teamList = css` 
	height:calc(100vh - 50px);
	@media only screen and (max-width: 767px) {
		height: 88vh;
	}
`;

export const teamSort = css`
	height: 35px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #50aeb0;
	font-size: 18px;
	color: white;
	position: sticky;
	top: 0;
	z-index: 10;
`;

export const searchInput = css`
	width: 100%;
	background-color: #ffffff;
	font-size: 1.5rem;
	background-image: url('https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/search-24.png');
	background-position: 97% 50%;
	background-repeat: no-repeat;
	padding: 1rem 2rem 1rem 1rem;
	outline: none;
	border: none;
`;
