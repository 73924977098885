import styled from "@emotion/styled";

export const PhotoDivStyled = styled('div')`
		width: 100%;
		overflow: hidden;

		.photo div {
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				width: 100%;
				height: 300px;
				object-fit: contain;
			}
		}
	`;