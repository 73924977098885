import { css } from 'twin.macro';
import { useState, forwardRef, useEffect, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { Mention } from 'react-mentions';
import { toast } from 'react-toastify';
import './ChatInput.css';

import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';

import { connect } from 'react-redux';
import { postImageToChat, postImageToDirectChat, postImageToSubChannel } from '../../../_redux/actions/ActionMessage';

import { checkPermission, SanitizeHTML, getUserRole } from '../../../_helpers';
import ReplyFile from './ReplyFile';
// import Calendar from './Calender';
import { chatInputCss, FormStyled, MentionInputStyled } from './styles/ChatInputStyles';
import { Link } from 'react-router-dom';

import useLocalMessagePermission from '../../../_helpers/hooks/useLocalMessagePermission';
import { customCheckbox } from '../../../_elements/CustomCheckbox';

// assets
import { FaRegCalendarAlt } from 'react-icons/fa';
import { MdSend } from 'react-icons/md';
import { FaRegFileVideo, FaPlusCircle, FaRegSmile } from 'react-icons/fa';
import { IoMdCloseCircle } from 'react-icons/io';
import { IoLockClosed, IoLockOpen } from 'react-icons/io5';
import { AiOutlineFileText, AiOutlineCloseCircle } from 'react-icons/ai';
import { VscLoading } from 'react-icons/vsc';
import { SRLWrapper } from 'simple-react-lightbox';
import ChatInputForm from './ChatInputForm';
import CommandDropdown from './CommandDropdown';
import {
    FILETYPES,
    IMAGESTYPES,
    MAX_FILE_SIZE,
    MAX_IMAGE_SIZE,
    MAX_VIDEO_SIZE,
    VIDEOTYPES,
} from '../../../contants/enums';

const options = {
    settings: { disableWheelControls: true, disableKeyboardControls: true },
    buttons: { showNextButton: false, showPrevButton: false, showThumbnailsButton: false, showAutoplayButton: false },
    caption: {
        showCaption: true,
    },
    thumbnails: {
        showThumbnails: false,
    },
};

const CUSTOM_EMOJIS = [
    {
        name: 'Party Parrot',
        short_names: ['party_parrot'],
        keywords: ['party', 'parrot'],
        imageUrl: './images/parrot.gif',
    },
    {
        name: 'Octocat',
        short_names: ['octocat'],
        keywords: ['github'],
        imageUrl: 'https://github.githubassets.com/images/icons/emoji/octocat.png',
    },
    {
        name: 'Squirrel',
        short_names: ['shipit', 'squirrel'],
        keywords: ['github'],
        imageUrl: 'https://github.githubassets.com/images/icons/emoji/shipit.png',
    },
];

const CustomMenu = forwardRef(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    return (
        <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
            <ul className="list-unstyled">{children}</ul>
        </div>
    );
});

const getFileTypeSvg = (chatFileType) => {
    if (chatFileType === 'Video') {
        return <FaRegFileVideo fontSize="24" />;
    } else {
        return <AiOutlineFileText fontSize="24" />;
    }
};
function ChatInput({
    sendMessage,
    type,
    teamMembers,
    id,
    postImageToChat,
    reply,
    setReply,
    postImageToDirectChat,
    postImageToSubChannel,
    channelMembers,
    roomType,
    messageText,
    editMode,
    position,
    currUser,
    team,
    currRoom,
    subChannelType,
    subChannelId,
    subChannelMembers,
}) {
    const [message, setMessage] = useState(messageText ? messageText : '');
    const [mentions, setMentions] = useState([]);
    const InputRef = useRef();
    const [showPermission, setShowPermission] = useState(false);

    const channelRole = getUserRole(currUser, team?._id, currRoom?._id, subChannelId);

    const [messageLocalPermission, setMessageLocalPermission] = useLocalMessagePermission(
        'messagePermission',
        id,
        subChannelId,
        subChannelType !== 'public',
        channelRole,
        roomType !== 'public'
    );

    const canEditPermission = checkPermission(channelRole, 'edit:messagePermission');

    const [messagePermission, setMessagePermission] = useState(
        canEditPermission ? messageLocalPermission || ['user', 'family', 'doctor'] : [channelRole]
    );
    const [chatFileType, setChatFileType] = useState(null);
    const [files, setFiles] = useState([]);

    useEffect(() => {
        if (subChannelType) {
            if (subChannelType && subChannelType === 'public') {
                if (channelMembers.length > 0) {
                    const mentions = channelMembers.map((user) => ({ id: user._id, display: user.name }));
                    mentions.push({ id: 'all', display: 'all' });
                    setMentions(mentions);
                }
            } else if (subChannelType) {
                if (subChannelMembers.length > 0) {
                    const mentions = subChannelMembers.map((user) => ({ id: user._id, display: user.name }));
                    mentions.push({ id: 'all', display: 'all' });
                    setMentions(mentions);
                }
            }
        } else {
            if (roomType && roomType === 'public') {
                if (teamMembers.length > 0) {
                    // console.log(teamMembers, 'teamMembers')
                    const mentions = teamMembers.map((user) => ({ id: user._id, display: user.name }));
                    mentions.push({ id: 'all', display: 'all' });
                    setMentions(mentions);
                }
            } else if (roomType) {
                if (channelMembers.length > 0) {
                    // console.log(channelMembers, 'channelMembers')
                    const mentions = channelMembers.map((user) => ({ id: user._id, display: user.name }));
                    mentions.push({ id: 'all', display: 'all' });
                    setMentions(mentions);
                }
            }
        }
    }, [channelMembers, roomType, teamMembers, subChannelMembers, subChannelType]);

    const [replyedID, setReplyID] = useState();

    useEffect(() => {
        const handleReplyPermissions = () => {
            if (canEditPermission) {
                setShowPermission(true);
            }
            if (replyedID !== reply.id) {
                setMessagePermission(reply?.visibleTo);
                setReplyID(reply?.id);
            }
        };

        if (reply) {
            InputRef.current.focus();
            handleReplyPermissions();
        }
    }, [replyedID, reply, canEditPermission]);

    const CustomToggleTeamList = forwardRef(({ children, onClick }, ref) => (
        <a
            href="#0"
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
        </a>
    ));

    const addEmoji = (e) => {
        let emoji = e.native;
        setMessage(message + emoji);
    };

    const [fileLoading, setFileLoading] = useState(false);

    const handleFile = async (e, fileType) => {
        setFileLoading(true);
        let fileSizeMB = 1024 * 1024;

        let maxFileSize, validFileExtension;
        if (fileType === 'Image') {
            maxFileSize = MAX_IMAGE_SIZE;
            validFileExtension = '.jpg, .jpeg, .gif, .png';
            setChatFileType('Image');
        } else if (fileType === 'Video') {
            maxFileSize = MAX_VIDEO_SIZE;
            validFileExtension = VIDEOTYPES;
            setChatFileType('Video');
        } else if (fileType === 'File') {
            maxFileSize = MAX_FILE_SIZE;
            validFileExtension = FILETYPES;
            setChatFileType('File');
        }

        let validFiles = [];

        for (const key of Object.keys(e.target.files)) {
            let targetFile = e.target.files[key];
            let ext = targetFile.name?.slice(((targetFile.name.lastIndexOf('.') - 1) >>> 0) + 2);

            if (targetFile && targetFile.size < maxFileSize * fileSizeMB) {
                if (validFileExtension.includes(ext)) {
                    validFiles.push(targetFile);
                    targetFile = null;
                } else {
                    toast.warn(`Invalid file type, Valid Files: ${validFileExtension}. Detected ${ext}`);
                    targetFile = null;
                }
            } else {
                toast.warn(`File must be less than ${maxFileSize}mb`);
                targetFile = null;
            }
        }
        setFiles(validFiles);
        setFileLoading(false);
    };

    const handleSend = (e, message) => {
        e.preventDefault();

        if (files.length) {
            const formData = new FormData();
            files.forEach((file) => {
                formData.append('file', file);
            });

            if (type === 'team') {
                let newPermissionArr = messagePermission;
                let ValidPermissionValue = ['doctor', 'family', 'user', 'supervisor'];
                newPermissionArr = newPermissionArr.filter((permission) => ValidPermissionValue.includes(permission));
                formData.append('visibleTo', JSON.stringify(newPermissionArr));
            }
            if (type === 'subChannel') {
                let newPermissionArr = messagePermission;
                let ValidPermissionValue = ['owner', 'user'];
                newPermissionArr = newPermissionArr.filter((permission) => ValidPermissionValue.includes(permission));
                formData.append('visibleTo', JSON.stringify(newPermissionArr));
            }

            let messageObj = {};
            if (message.trim()) {
                messageObj.text = message.trim();
            }
            if (position?.coords?.longitude && position?.coords?.longitude) {
                messageObj.location = { latitude: position.coords.latitude, longitude: position.coords.longitude };
            }
            messageObj = JSON.stringify(messageObj);
            formData.append('message', messageObj);
            if (type === 'team') {
                postImageToChat(id, formData);
            } else if (type === 'subChannel') {
                postImageToSubChannel(id, formData);
            } else {
                postImageToDirectChat(id, formData);
            }
            setChatFileType(null);
            setFiles([]);
        } else {
            sendMessage(e, message.trim(), messagePermission);
        }
        if (!messageText) setMessage('');
        if (reply) clearReply();
        setMessagePermission(canEditPermission ? messageLocalPermission || [] : ['user']);
        if (type === 'team' && message?.startsWith('/quinn')) {
            setMessage('/quinn ');
        }
    };
    const clearReply = () => {
        setReply(null);
        setReplyID(null);
        setFiles([]);
        setMessagePermission(canEditPermission ? messageLocalPermission || [] : ['user']);
    };

    const handlePermissionChange = (event) => {
        let role = event.target.value;
        // if (mainRole !== 'admin' && teamRole !== 'teamManager' && userRole === role) {
        // 	return;
        // }
        const newPermissions = messagePermission.includes(role)
            ? messagePermission.filter((x) => x !== role)
            : [...messagePermission, role];

        // let roleIndex = newPermissions.indexOf(role);
        // let permissionGiven = Boolean(roleIndex >= 0);
        // if (permissionGiven) {
        // 	// remove role permission
        // 	newPermissions.splice(roleIndex, 1);
        // } else {
        // 	// add role in permission
        // 	newPermissions.push(role);
        // }

        setMessagePermission(newPermissions);
        if (!reply) {
            setMessageLocalPermission(newPermissions);
        }
    };

    const handleRemoveFile = (file) => {
        let newFiles = [...files];
        newFiles = newFiles.filter((f) => `${f?.lastModified}_${f?.name}` !== `${file?.lastModified}_${file?.name}`);
        setFiles(newFiles);
    };

    const [isDragging, setIsDragging] = useState(false);
    const handleDragIn = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
            setIsDragging(true);
        }
    };
    const handleDragOut = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(false);
    };
    const handleDrag = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };
    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(false);
        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            let e = {};
            e.target = {};
            e.target.files = event.dataTransfer.files;
            let fileDataType = event.dataTransfer.files[0].type;
            let type = '';
            if (fileDataType.startsWith('image')) {
                type = 'Image';
            } else if (fileDataType.startsWith('video') || fileDataType.startsWith('audio')) {
                type = 'Video';
            } else {
                type = 'File';
            }
            handleFile(e, type);
            event.dataTransfer.clearData();
        }
    };

    // command dropdown
    const [showCommandDropdown, setShowCommandDropdown] = useState(false);
    const [selectedCommand, setSelectedCommand] = useState(null);
    const [commandList, setCommandList] = useState([]);

    const onCommandSelected = (item) => {
        setSelectedCommand(item);
        const [_, ...rest] = message.split(' ');
        setMessage(`${item.command} ${rest.join(' ')}`);
        InputRef.current.focus();
    };

    const handleTabCompletion = () => {
        if (!commandList.length) {
            return;
        }
        const item = commandList[0];
        onCommandSelected(item);
    };

    return (
        <div css={chatInputCss}>
            <FormStyled className="message-inputarea position-relative">
                <div className="input-features">
                    {!showCommandDropdown ? null : (
                        <CommandDropdown
                            type={type}
                            onCommandSelected={onCommandSelected}
                            message={message}
                            commandList={commandList}
                            setCommandList={setCommandList}
                        />
                    )}
                    <div className="input-features-container">
                        <div className="d-none mr-0 d-md-block">
                            <Dropdown>
                                <Dropdown.Toggle
                                    id="dropdown-basic"
                                    css={css`
                                        background: none !important;
                                        color: #2e9a9c !important;
                                        border: none !important;
                                        box-shadow: none !important;
                                        padding: 0;
                                        margin: 0;
                                        height: auto;
                                    `}
                                >
                                    <FaRegSmile
                                        fontSize="24px"
                                        css={css`
                                            margin: 0 3px;
                                        `}
                                    />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <span>
                                        <Picker
                                            css={css`
                                                position: absolute;
                                                bottom: 14;
                                                left: -1;
                                            `}
                                            onSelect={addEmoji}
                                            emojiTooltip={false}
                                            showPreview={false}
                                            showSkinTones={false}
                                            native={false}
                                            costume={CUSTOM_EMOJIS}
                                            set="apple"
                                            theme="auto"
                                            emoji="point_up"
                                            exclude={['flags']}
                                            color="#50aeb0"
                                        />
                                    </span>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        {!editMode && !files.length && (
                            <Dropdown>
                                <Dropdown.Toggle as={CustomToggleTeamList} variant="success" id="dropdown-basic">
                                    <FaPlusCircle
                                        fontSize="24px"
                                        css={css`
                                            margin: 0 3px;
                                        `}
                                    />
                                </Dropdown.Toggle>
                                <Dropdown.Menu as={CustomMenu}>
                                    <Dropdown.Item as="label" aria-labelledby="uploadImage">
                                        Image
                                        <input
                                            type="file"
                                            accept={IMAGESTYPES}
                                            id="uploadImage"
                                            className="d-none"
                                            multiple
                                            onChange={(e) => handleFile(e, 'Image')}
                                        />
                                    </Dropdown.Item>
                                    <Dropdown.Item as="label" aria-labelledby="uploadVideo">
                                        Video | Audio
                                        <input
                                            type="file"
                                            accept={VIDEOTYPES}
                                            id="uploadVideo"
                                            className="d-none"
                                            // multiple
                                            onChange={(e) => handleFile(e, 'Video')}
                                        />
                                    </Dropdown.Item>
                                    <Dropdown.Item as="label" aria-labelledby="uploadFile">
                                        File
                                        <input
                                            type="file"
                                            accept={FILETYPES}
                                            id="uploadFile"
                                            className="d-none"
                                            multiple
                                            onChange={(e) => handleFile(e, 'File')}
                                        />
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        )}

                        {type === 'team' && !editMode && !message && (
                            <Fragment>
                                <Link
                                    to={`/events/${id}`}
                                    css={css`
                                        display: flex;
                                    `}
                                >
                                    <FaRegCalendarAlt
                                        fontSize="24px"
                                        css={css`
                                            margin: 0 3px;
                                        `}
                                    />
                                </Link>
                                <ChatInputForm currRoom={currRoom} team={team} channelRole={channelRole} />
                            </Fragment>
                        )}

                        {(type === 'subChannel' ? subChannelType === 'private' : roomType === 'private') &&
                            !editMode &&
                            canEditPermission && (
                                <div
                                    css={css`
                                        display: flex;
                                        cursor: pointer;
                                    `}
                                    onClick={() => {
                                        setShowPermission(!showPermission);
                                    }}
                                >
                                    {showPermission || message?.length > 0 ? (
                                        <IoLockClosed
                                            fontSize="24px"
                                            css={css`
                                                margin: 0 3px;
                                            `}
                                        />
                                    ) : (
                                        <IoLockOpen
                                            fontSize="24px"
                                            css={css`
                                                margin: 0 3px;
                                            `}
                                        />
                                    )}
                                </div>
                            )}
                    </div>
                </div>

                <div
                    css={css`
                        width: 100%;
                    `}
                >
                    {type === 'private' ? (
                        // Private Chat input
                        <Fragment>
                            <div className=" replywrapper d-flex flex-column w-100">
                                {reply && (reply.toUser === id || reply.fromUser === id) && (
                                    <div
                                        className="d-flex text-dark"
                                        css={css`
                                            background-color: #d1d1d1;
                                            padding: 10px;
                                            margin: 0px 5px;
                                            word-break: break-all;
                                        `}
                                    >
                                        <div className="h-auto overflow-hidden p-0 m-0">
                                            <strong>
                                                {reply?.name?.name}
                                                <br />
                                            </strong>
                                            {reply.file || reply.images?.length || reply.files?.length ? (
                                                <div>
                                                    <ReplyFile file={reply.file || reply.images[0] || reply.files[0]} />
                                                </div>
                                            ) : (
                                                <small>
                                                    <SanitizeHTML html={reply.data} />
                                                </small>
                                            )}
                                        </div>
                                        <a href="#0" className="ml-auto px-5" onClick={() => clearReply()}>
                                            <IoMdCloseCircle size={24} className="text-white" />
                                        </a>
                                    </div>
                                )}
                                <div className="message-input-items message-input">
                                    <MentionInputStyled
                                        inputRef={InputRef}
                                        className="mentions w-100 text-dark "
                                        placeholder="Type a message..."
                                        value={message}
                                        allowSuggestionsAboveCursor={false}
                                        onChange={({ target: { value } }) => setMessage(value)}
                                        onKeyDown={(event) => {
                                            if (event.key === 'Enter' && !event.shiftKey) {
                                                handleSend(event, message);
                                            } else {
                                                return null;
                                            }
                                        }}
                                    >
                                        {/* making mentions trigger impossible */}
                                        <Mention
                                            trigger="@"
                                            data={mentions && mentions}
                                            markup="[__id__](__display__)"
                                            appendSpaceOnAdd
                                        />
                                    </MentionInputStyled>
                                </div>
                            </div>
                        </Fragment>
                    ) : type === 'team' ? (
                        //Team CHat
                        <Fragment>
                            <div className="d-flex flex-column w-100">
                                {(message?.length > 0 || showPermission) && !editMode && roomType === 'private' && (
                                    <div
                                        className="d-flex text-dark"
                                        css={css`
                                            background-color: white;
                                            border-bottom: 1px solid #ddd;
                                            display: flex;
                                            justify-content: space-between;
                                            margin: 5px;
                                            word-break: break-all;
                                        `}
                                    >
                                        <div className="h-auto overflow-hidden p-0 m-0">
                                            <div>
                                                <div>
                                                    <input
                                                        id="chatInput_thirdparty"
                                                        type="checkbox"
                                                        css={customCheckbox}
                                                        onChange={handlePermissionChange}
                                                        value="doctor"
                                                        checked={messagePermission?.includes('doctor')}
                                                    />
                                                    <label htmlFor="chatInput_thirdparty" className="mr-4">
                                                        Third Party
                                                    </label>

                                                    <input
                                                        id="chatInput_family"
                                                        type="checkbox"
                                                        css={customCheckbox}
                                                        onChange={handlePermissionChange}
                                                        value="family"
                                                        checked={messagePermission?.includes('family')}
                                                    />
                                                    <label htmlFor="chatInput_family" className="mr-4">
                                                        Family
                                                    </label>

                                                    <input
                                                        id="chatInput_supervisor"
                                                        type="checkbox"
                                                        css={customCheckbox}
                                                        onChange={handlePermissionChange}
                                                        value="supervisor"
                                                        checked={messagePermission?.includes('supervisor')}
                                                    />
                                                    <label htmlFor="chatInput_supervisor" className="mr-4">
                                                        Supervisor
                                                    </label>

                                                    <input
                                                        id="chatInput_user"
                                                        type="checkbox"
                                                        css={customCheckbox}
                                                        onChange={handlePermissionChange}
                                                        value="user"
                                                        checked={messagePermission?.includes('user')}
                                                    />
                                                    <label htmlFor="chatInput_user" className="mr-4">
                                                        User
                                                    </label>
                                                </div>
                                                <div></div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {reply &&
                                    ((type === 'private' && (reply.toUser === id || reply.fromUser === id)) ||
                                        (type === 'team' && id === reply.roomId)) && (
                                        <div
                                            className="d-flex text-dark"
                                            css={css`
                                                background-color: #d1d1d1;
                                                padding: 10px;
                                                margin: 0px 5px;
                                                word-break: break-all;
                                                margin-top: 0.5rem;
                                            `}
                                        >
                                            <div className="h-auto overflow-hidden p-0 m-0">
                                                {type === 'team' && (
                                                    <strong>
                                                        {reply.name.name}
                                                        <br />
                                                    </strong>
                                                )}
                                                {reply.file || reply.images?.length || reply.files?.length ? (
                                                    <div>
                                                        <ReplyFile
                                                            file={reply.file || reply.images[0] || reply.files[0]}
                                                        />
                                                    </div>
                                                ) : (
                                                    <small>
                                                        <SanitizeHTML html={reply.data} />
                                                    </small>
                                                )}
                                            </div>
                                            <a href="#0" className="ml-auto px-5" onClick={() => clearReply()}>
                                                <IoMdCloseCircle size={24} className="text-white" />
                                            </a>
                                        </div>
                                    )}
                                <div className="message-input-items message-input position-relative">
                                    <div
                                        css={css`
                                            width: 100%;
                                        `}
                                        onDragEnter={handleDragIn}
                                        onDragLeave={handleDragOut}
                                        onDragOver={handleDrag}
                                        onDrop={handleDrop}
                                    >
                                        {isDragging && (
                                            <div
                                                css={css`
                                                    border: dashed grey 4px;
                                                    background-color: rgba(255;255;255;.8);
                                                    position: absolute;
                                                    top: 0;
                                                    bottom: 0;
                                                    left: 0;
                                                    right: 0;
                                                    z-index: 9999;
                                                `}
                                            >
                                                <div
                                                    css={css`
                                                        width: 100%;
                                                        text-align: center;
                                                        color: grey;
                                                        font-size: 36;
                                                    `}
                                                >
                                                    <div>Drop file here</div>
                                                </div>
                                            </div>
                                        )}
                                        <MentionInputStyled
                                            inputRef={InputRef}
                                            className="mentions w-100 text-dark "
                                            value={message}
                                            allowSuggestionsAboveCursor={true}
                                            onChange={({ target: { value } }) => {
                                                if (value.startsWith('/')) {
                                                    setShowCommandDropdown(true);
                                                } else {
                                                    setShowCommandDropdown(false);
                                                }
                                                setMessage(value);
                                            }}
                                            onKeyDown={(event) => {
                                                if (showCommandDropdown && event.key === 'Tab') {
                                                    event.preventDefault();
                                                    handleTabCompletion(selectedCommand);
                                                }
                                                if (event.key === 'Enter' && !event.shiftKey) {
                                                    handleSend(event, message);
                                                } else {
                                                    return null;
                                                }
                                            }}
                                        >
                                            <Mention
                                                trigger="@"
                                                data={mentions && mentions}
                                                markup="[__id__](__display__)"
                                                appendSpaceOnAdd
                                            />
                                        </MentionInputStyled>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    ) : (
                        //Subchannel chat
                        <Fragment>
                            <div className="d-flex flex-column w-100">
                                {(message?.length > 0 || showPermission) &&
                                    !editMode &&
                                    subChannelType === 'private' && (
                                        <div
                                            className="d-flex text-dark"
                                            css={css`
                                                background-color: white;
                                                border-bottom: 1px solid #ddd;
                                                display: flex;
                                                justify-content: space-between;
                                                margin: 5px;
                                                word-break: break-all;
                                            `}
                                        >
                                            <div className="h-auto overflow-hidden p-0 m-0">
                                                <div>
                                                    <div>
                                                        <input
                                                            id="chatInput_thirdparty"
                                                            type="checkbox"
                                                            css={customCheckbox}
                                                            onChange={handlePermissionChange}
                                                            value="owner"
                                                            checked={messagePermission?.includes('owner')}
                                                        />
                                                        <label htmlFor="chatInput_thirdparty" className="mr-4">
                                                            Owner
                                                        </label>

                                                        <input
                                                            id="chatInput_user"
                                                            type="checkbox"
                                                            css={customCheckbox}
                                                            onChange={handlePermissionChange}
                                                            value="user"
                                                            checked={messagePermission?.includes('user')}
                                                        />
                                                        <label htmlFor="chatInput_user" className="mr-4">
                                                            User
                                                        </label>
                                                    </div>
                                                    <div></div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                {reply &&
                                    ((type === 'private' && (reply.toUser === id || reply.fromUser === id)) ||
                                        (type === 'team' && id === reply.roomId) ||
                                        (type === 'subChannel' && id === reply.subChannelId)) && (
                                        <div
                                            className="d-flex text-dark"
                                            css={css`
                                                background-color: #d1d1d1;
                                                padding: 10px;
                                                margin: 0px 5px;
                                                word-break: break-all;
                                                margin-top: 0.5rem;
                                            `}
                                        >
                                            <div className="h-auto overflow-hidden p-0 m-0">
                                                {type === 'subChannel' && (
                                                    <strong>
                                                        {reply.name.name}
                                                        <br />
                                                    </strong>
                                                )}
                                                {reply.file || reply.images?.length || reply.files?.length ? (
                                                    <div>
                                                        <ReplyFile
                                                            file={reply.file || reply.images[0] || reply.files[0]}
                                                        />
                                                    </div>
                                                ) : (
                                                    <small>
                                                        <SanitizeHTML html={reply.data} />
                                                    </small>
                                                )}
                                            </div>
                                            <a href="#0" className="ml-auto px-5" onClick={() => clearReply()}>
                                                <IoMdCloseCircle size={24} className="text-white" />
                                            </a>
                                        </div>
                                    )}
                                <div className="message-input-items message-input position-relative">
                                    <div
                                        css={css`
                                            width: 100%;
                                        `}
                                        onDragEnter={handleDragIn}
                                        onDragLeave={handleDragOut}
                                        onDragOver={handleDrag}
                                        onDrop={handleDrop}
                                    >
                                        {isDragging && (
                                            <div
                                                css={css`
                                                    border: dashed grey 4px;
                                                    background-color: rgba(255;255;255;.8);
                                                    position: absolute;
                                                    top: 0;
                                                    bottom: 0;
                                                    left: 0;
                                                    right: 0;
                                                    z-index: 9999;
                                                `}
                                            >
                                                <div
                                                    css={css`
                                                        width: 100%;
                                                        text-align: center;
                                                        color: grey;
                                                        font-size: 36;
                                                    `}
                                                >
                                                    <div>Drop file here</div>
                                                </div>
                                            </div>
                                        )}
                                        <MentionInputStyled
                                            inputRef={InputRef}
                                            className="mentions w-100 text-dark "
                                            value={message}
                                            allowSuggestionsAboveCursor={true}
                                            onChange={({ target: { value } }) => setMessage(value)}
                                            onKeyDown={(event) => {
                                                if (event.key === 'Enter' && !event.shiftKey) {
                                                    handleSend(event, message);
                                                } else {
                                                    return null;
                                                }
                                            }}
                                        >
                                            <Mention
                                                trigger="@"
                                                data={mentions && mentions}
                                                markup="[__id__](__display__)"
                                                appendSpaceOnAdd
                                            />
                                        </MentionInputStyled>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    )}

                    {files.length || fileLoading ? (
                        <Fragment>
                            <div
                                css={css`
                                    display: flex;
                                    background: #c8e7e8;
                                    border-radius: 8px;
                                    padding: 0.5rem;
                                    margin: 0 0.5rem;
                                    flex-wrap: wrap;
                                    max-height: 500px;
                                    overflow-y: auto;
                                `}
                            >
                                {fileLoading ? (
                                    <div
                                        css={css`
                                            width: 100%;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            svg {
                                                font-size: 32px;
                                                color: black;
                                            }
                                        `}
                                    >
                                        <VscLoading className="spin" />
                                    </div>
                                ) : (
                                    files.map((file) => (
                                        <div
                                            key={`${file?.lastModified}_${file?.name}`}
                                            css={css`
                                                position: relative;
                                                font-size: 2rem;
                                                background: white;
                                                padding: 0.5rem;
                                                width: 200px;
                                                border-radius: 15px;
                                                display: flex;
                                                justify-content: flex-start;
                                                align-items: center;
                                                margin: 0.5rem 1rem;
                                            `}
                                        >
                                            {chatFileType !== 'Image' && (
                                                <span
                                                    css={css`
                                                        width: 32px;
                                                        height: 32px;
                                                        display: flex;
                                                        justify-content: center;
                                                        align-items: center;
                                                        background: #c8e7e8;
                                                        border-radius: 1000px;
                                                        margin-right: 0.8rem;
                                                    `}
                                                >
                                                    {getFileTypeSvg(chatFileType)}
                                                </span>
                                            )}
                                            <div
                                                css={css`
                                                    overflow: hidden;
                                                    text-overflow: ellipsis;
                                                    display: -webkit-box;
                                                    -webkit-line-clamp: 2;
                                                    -webkit-box-orient: vertical;
                                                    word-break: break-all;
                                                    width: 100%;
                                                    text-align: center;
                                                `}
                                            >
                                                {chatFileType === 'Image' ? (
                                                    <SRLWrapper options={options}>
                                                        <a href={URL.createObjectURL(file)}>
                                                            <img
                                                                css={css`
                                                                    width: 100%;
                                                                    object-fit: contain;
                                                                `}
                                                                src={URL.createObjectURL(file)}
                                                                alt={file?.name}
                                                            />
                                                        </a>
                                                    </SRLWrapper>
                                                ) : (
                                                    file?.name
                                                )}
                                            </div>
                                            <span
                                                css={css`
                                                    position: absolute;
                                                    top: -6px;
                                                    right: -6px;
                                                    display: flex;
                                                    justify-content: center;
                                                    align-items: center;
                                                    background: white;
                                                    border-radius: 1000px;
                                                    cursor: pointer;
                                                    transition: transform 150ms;
                                                    transform-origin: center;
                                                    :hover {
                                                        transform: scale(1.1);
                                                    }
                                                `}
                                                onClick={() => handleRemoveFile(file)}
                                            >
                                                <AiOutlineCloseCircle fontSize="24" />
                                            </span>
                                        </div>
                                    ))
                                )}
                            </div>
                        </Fragment>
                    ) : null}
                </div>
                <div
                    css={css`
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                    `}
                >
                    <div>
                        <a href="#0" className="send-btn message-input-items" onClick={(e) => handleSend(e, message)}>
                            <MdSend
                                css={css`
                                    color: #50aeb0;
                                `}
                            />
                        </a>
                    </div>
                </div>
            </FormStyled>
        </div>
    );
}

const mapStateToProps = (state) => ({
    currUser: state.user.user,
    mainRole: state.user.user.role,
    teamMembers: state.teams.teamMembers,
    channelMembers: state.rooms.channelMembers,
    subChannelMembers: state.subChannels.subChannelMembers,
    position: state.user.position,
});

ChatInput.propTypes = {
    sendMessage: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { postImageToChat, postImageToDirectChat, postImageToSubChannel })(ChatInput);
