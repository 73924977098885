import React from 'react';
import { css } from 'twin.macro';
import { Tab, Nav } from 'react-bootstrap';

const CustomTabs = ({ data }) => {
	return (
		<div className="evs" css={css`width: 100%;`}>
			<Tab.Container id="left-tabs-example" defaultActiveKey={data[0].eventKey}>
				<Nav variant="pills" className="flex-row w-100 justify-content-between">
					<div className="d-flex">
						{data &&
							data.map((dt) => (
								<Nav.Item key={dt?.eventKey}>
									<Nav.Link eventKey={dt?.eventKey} disabled={data?.disbaled}>
										{dt?.title}
									</Nav.Link>
								</Nav.Item>
							))}
					</div>
				</Nav>
				<Tab.Content
					css={css`
						border: 2px solid #50aeb0;
						border-radius: 0 10px 10px 10px;
					`}
				>
					{data &&
						data.map((dt) => (
							<Tab.Pane key={dt?.eventKey} eventKey={dt?.eventKey} className="p-4">
								{dt?.content}
							</Tab.Pane>
						))}
				</Tab.Content>
			</Tab.Container>
		</div>
	);
};

export default CustomTabs;
