import { useState } from 'react';

export const useLocalMessagePermission = (key, roomId, subChannelId, isSubChannelPrivate, initialValue, addInLocal) => {
	const [storedValue, setStoredValue] = useState(() => {
		let roles;
		let validPermissionValue;
		if (subChannelId) {
			validPermissionValue = ['owner', 'user'];
		} else {
			validPermissionValue = ['family', 'user', 'doctor', 'supervisor'];
		}

		if (validPermissionValue.includes(initialValue)) {
			roles = [initialValue];
		} else {
			roles = ['user'];
		}

		try {
			const item = window.localStorage.getItem(key);
			if (subChannelId) {
				if (item) {
					let parsedItem = JSON.parse(item);
					if (parsedItem[subChannelId]) {
						return parsedItem[subChannelId];
					} else {
						parsedItem[subChannelId] = roles;
						if (isSubChannelPrivate) {
							window.localStorage.setItem(key, JSON.stringify(parsedItem));
						}
						return roles;
					}
				} else {
					let parsedItem = {};
					parsedItem[subChannelId] = roles;
					if (isSubChannelPrivate) {
						window.localStorage.setItem(key, JSON.stringify(parsedItem));
					}
					return roles;
				}
			} else {
				if (item) {
					let parsedItem = JSON.parse(item);
					if (parsedItem[roomId]) {
						return parsedItem[roomId];
					} else {
						parsedItem[roomId] = roles;
						if (addInLocal) {
							window.localStorage.setItem(key, JSON.stringify(parsedItem));
						}
						return roles;
					}
				} else {
					let parsedItem = {};
					parsedItem[roomId] = roles;
					if (addInLocal) {
						window.localStorage.setItem(key, JSON.stringify(parsedItem));
					}
					return roles;
				}
			}
		} catch (error) {
			console.warn('Setting localStorage went wrong: ', error);
			return roles;
		}
	});

	const setValue = (value) => {
		try {
			setStoredValue(value);
			const newItem = window.localStorage.getItem(key);
			let parsedItem;
			if (newItem) {
				parsedItem = JSON.parse(newItem);
			} else {
				parsedItem = {};
			}
			if (subChannelId) {
				parsedItem[subChannelId] = value;
			} else {
				parsedItem[roomId] = value;
			}
			window.localStorage.setItem(key, JSON.stringify(parsedItem));
		} catch (error) {
			console.error(error);
		}
	};

	return [storedValue, setValue];
};

export default useLocalMessagePermission;
