// core imports
import { useState, Fragment } from 'react';

//redux stuff
import { connect } from 'react-redux';
import { startLoading, stopLoading } from '../../../_redux/actions/ActionAuthentication';
import { getChannelMembers } from '../../../_redux/actions/ActionOrganization';
import { getOtherUserData } from '../../../_redux/actions/ActionUser';

//styles and icons
import AppPeopleModal from './AppPeopleModal';

function AddPeople({
	role,
	roomId,
	self,
	channelMembers,
	ui,
	startLoading,
	stopLoading,
	getOtherUserData,
	getChannelMembers,
	children,
}) {
	const [show, setShow] = useState(false);

	const handleClose = () => {
		setShow(false);
	};
	const handleShow = () => {
		setShow(true);
	};

	return (
		<Fragment>
			<div onClick={handleShow}>
				{children}
			</div>
			{/* <div css={actions} onClick={handleShow}>
				<FaUserPlus />
				<span>Add</span>
			</div> */}

			{show && (
				<AppPeopleModal
					show={show}
					handleClose={handleClose}
					handleShow={handleShow}
					role={role}
					roomId={roomId}
					self={self}
					channelMembers={channelMembers}
					ui={ui}
					startLoading={startLoading}
					stopLoading={stopLoading}
					getOtherUserData={getOtherUserData}
					getChannelMembers={getChannelMembers}
				/>
			)}
		</Fragment>
	);
}

const mapStateToProps = (state) => ({
	message: state.teams.errorMessage,
	ui: state.ui,
	channelMembers: state.rooms.channelMembers,
	teamMembers: state.teams.teamMembers,
	searchUser: state.teams.searchUser,
	self: state.user.user,
});

export default connect(mapStateToProps, { startLoading, stopLoading, getOtherUserData, getChannelMembers })(AddPeople);
