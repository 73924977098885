import styled from '@emotion/styled';

export const StyledConferenceInvitation = styled.div`
	display: flex;
	flex-direction: row;
	position: absolute;
	top: 0;
	left: 50%;
	margin: auto;
	z-index: 3;
	background: white;
	padding: 1rem 2rem;
	border-radius: 15px;
	margin: 1.5rem;
	transform: translateX(-50%);

	.InvitationText {
		margin: 0 1.5rem;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.invitee {
		color: #262;
		font-weight: 600;
		font-size: 1.8rem;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
	}
	.ControlsContainer {
		display: flex;
		justify-content: center;
		align-items: center;
		.button {
			width: 52px;
			height: 52px;
			background: blue;
			border-radius: 1000px;
			color: white;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 0.5rem;
			cursor: pointer;
			transition: transform 150ms;
			:hover {
				transform: scale(1.1);
			}
		}
		.pickUpBtn {
			background-color: #2e9a9c;
		}
		.declineBtn {
			background-color: #ee4354;
		}
	}
`;
