import React, { Fragment, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';

//helpers
import { fetchWrapper, getTeamIdFromURL, debounce, toastWrapper } from '../../../_helpers';
import StateSelector from '../EVVContainer/StateSelector';
import { stateList } from '../EVVContainer/_data/states';
import { useSelector } from 'react-redux';

const getStateOption = (stateCode) => stateList.find((option) => option.value === stateCode);

export default function ServicesElement({ stateValue, options, setOptions, isMulti = true, hideStateSelector }) {
    const [selectedState, setSelectedState] = useState({});

    const teamId = getTeamIdFromURL();
    const allTeams = useSelector((state) => state.teams.teams);

    useEffect(() => {
        if (stateValue) {
            setSelectedState(getStateOption(stateValue));
        } else if (teamId) {
            const currentTeam = allTeams.find((team) => team._id === teamId);
            setSelectedState(getStateOption(currentTeam?.provider?.state));
        }
    }, [stateValue, teamId, allTeams]);

    const loadServices = async (inputValue, callback = () => {}) => {
        try {
            if (!selectedState?.value) {
                return callback([]);
            }
            const services = await fetchWrapper.get(
                `evv/service?sortBy=name:asc&state=${selectedState?.value}&limit=100&${
                    inputValue ? `&HCPCS=${inputValue}` : ''
                }${teamId ? `&team=${teamId}` : ''}`
            );
            callback(
                services?.results?.map((element) => ({
                    label: `${element.HCPCS} - ${element.Description}`,
                    value: `${JSON.stringify({
                        id: element.id,
                        Description: element.Description,
                        HCPCS: element.HCPCS,
                        Modifier1: element.Modifier1,
                        Modifier2: element.Modifier2,
                        Modifier3: element.Modifier3,
                        Modifier4: element.Modifier4,
                        PayerID: element.PayerID,
                        ProgramOffice: element.ProgramOffice,
                        sendTo: element.sendTo ? element.sendTo : 'sandata',
                    })}`,
                    id: element._id,
                }))
            );
        } catch (error) {
            if (error.name === 'AbortError') {
                return callback([]);
            }
            toastWrapper.error(error?.message || "Couldn't fetch services");
        }
    };

    const handleServiceChange = (newValue) => {
        setOptions(newValue);
    };

    return (
        <React.Fragment>
            {!hideStateSelector && (
                <Fragment>
                    <Form.Label>State</Form.Label>
                    <div className="mb-4">
                        <StateSelector
                            selectedState={selectedState}
                            setSelectedState={setSelectedState}
                            isDisabled={true}
                        />
                    </div>
                </Fragment>
            )}
            <Form.Label>Services</Form.Label>
            <div className="mb-4">
                <AsyncSelect
                    key={selectedState?.value}
                    cacheOptions
                    loadOptions={debounce(loadServices)}
                    defaultOptions
                    value={options}
                    onChange={handleServiceChange}
                    isMulti={isMulti}
                    isDisabled={!selectedState?.value}
                />
            </div>
        </React.Fragment>
    );
}
