import React from 'react';

const ReportCheckBox = ({ label, value, onChange = () => {} }) => {
    return (
        <div className="mt-4" style={{ display: 'flex', alignItems: 'center' }}>
            <input
                type="checkbox"
                className="mr-2"
                style={{ width: '20px', height: '20px', marginTop: '0' }}
                checked={value}
                onChange={(e) => onChange(e.target.checked)}
            />
            <span style={{ lineHeight: '20px', textTransform: 'capitalize' }}>{label}</span>
        </div>
    );
};

export default ReportCheckBox;
